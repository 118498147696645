import React, { Component } from 'react';
import SumaContexto  from '../../contexto/SumaContexto';
import { withRouter, Switch , Route } from "react-router-dom";
import { Button, Spin, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Constantes from '../../utilidades/Constantes'
import ServicioPersonaJuridica from '../../servicios/usuarios/ServicioPersonaJuridica'
import { withCookies } from 'react-cookie';
import AgregarEditarRepresentanteLegal from './AgregarEditarRepresentanteLegal'
import VerRepresentanteLegal from './VerRepresentanteLegal'
import ListarRepresentantesLegales from './ListarRepresentantesLegales'
import Utilidades from '../../utilidades/Utilidades';
import { HookReactRouterV6Parametros } from '../../general/hooks/HookReactRouterV6Parametros';

class RepresentantesLegales extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE ={
        CARGANDO:1,
        VER:2,
        NO_DISPONIBLE:3,
        DILIGENCIAR_DATOS_BASICOS:4
    }

    state = {
        idRepresentanteLegal: null,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        usuario: null,
    };

    constructor(props){
        super(props);
        this.historyLocal = props.history;
        this.administracion = props.administracion;

        
        this.servicioPersonaJuridica = new ServicioPersonaJuridica ( props );

        this.PATHS = {
            REPRESENTANTES:         this.administracion ? 
                                    `/persona-juridica/:idPersonaJuridica/representantes-legales` :
                                    '/persona-juridica-representantes',

            AGREGAR_REPRESENTANTE : this.administracion ? 
                                    `/persona-juridica/:idPersonaJuridica/agregar/representante-legal` : 
                                    '/persona-juridica-agregar-representante',

            EDITAR_REPRESENTANTE :  this.administracion ? 
                                    `/persona-juridica/:idPersonaJuridica/editar/representante-legal/:idRepresentanteLegal` : 
                                    '/persona-juridica-editar-representante',

            VER_REPRESENTANTE :     this.administracion ? 
                                    `/persona-juridica/:idPersonaJuridica/representante-legal/:idRepresentanteLegal` : 
                                    '/persona-juridica-ver-representante',
        }

        //console.log('RepresentantesLegales.props',props);
        
        
    }
    //const [form] = Form.useForm();
    formRef = React.createRef();


    representanteLegalGuardado( nuevoRepresentante, idRepresentanteLegal ){
        this.setState({ idRepresentanteLegal: idRepresentanteLegal });
        this.historyLocal.push(  this.administracion ?
            this.reemplazarValoresEnElPath( this.PATHS.VER_REPRESENTANTE, idRepresentanteLegal ) 
            :
            this.PATHS.VER_REPRESENTANTE + '?id=' + idRepresentanteLegal 
        );
        Modal.success({ content: "El representante legal ha sido " + (nuevoRepresentante ? "creado" : "actualizado") });
    }

    componentDidMount() {
        this.cargarDatosIniciales();
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales(){
        // Validando que el usuario es PERSONA JURIDICA
        let usr = await this.servicioPersonaJuridica.usuarioPersonaJuridicaOAdministradorValido();
        if( usr && usr !== null ){
            if( usr.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.PERSONA_JURIDICA.id ){
                if( usr.usuarioPersonaJuridica ){
                    this.setState({ componente: this.ENUM_COMPONENTE.VER });    
                }else{
                    this.setState({ componente: this.ENUM_COMPONENTE.DILIGENCIAR_DATOS_BASICOS });
                }
            }else if( usr.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.ADMINISTRADOR_SUMA.id ){
                this.setState({ componente: this.ENUM_COMPONENTE.VER });    
            }
            else{
                this.setState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
            }
        }else{
            this.setState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }

        this.setState({ usuario:usr });
    }

    clickVerRepresentanteLegal = ( e, idRepresentanteLegal ) => {
        this.setState({ idRepresentanteLegal: idRepresentanteLegal });
        
        this.historyLocal.push( this.administracion ?
            this.reemplazarValoresEnElPath( this.PATHS.VER_REPRESENTANTE, idRepresentanteLegal ) 
            :
            this.PATHS.VER_REPRESENTANTE + '?id=' + idRepresentanteLegal );
        
    }

    clickActivarRepresentanteLegal = (e,idRepresentanteLegal, representanteLegal, activar, callback ) => {
        this.setState({ cargando: true, });
        this.servicioPersonaJuridica.activarRepresentanteLegal(
            ( respuestaApi )    => { 
                switch(respuestaApi.codigo){
                    case Constantes.ENUMS_API.PERSONA_JURIDICA.OPERACION_REALIZADA.id:
                        this.setState({ cargando: false });
                        Modal.success({ content:'Representante legal ' + representanteLegal + ' ' + ( activar ? 'Activado' : 'Desactivado' ) });
                        if( callback !== undefined ){
                            callback();
                        }
                        break;
                    default:
                        Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:RL02]" });
                        break;    
                }
            },
            ( errorAPI ) => { 
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:RL03]" });
            },
            idRepresentanteLegal,
            activar,
            // Si está en modo administración del SUMA, este dato se captura de la url por medio de HookReactRouterV6Parametros
            this.props.idPersonaJuridica
        );
    };

    eliminarRepresentanteLegal = async (idRepresentanteLegal, representanteLegal ) => {
        this.setState({ cargando: true });

        let respuestaApi = await this.servicioPersonaJuridica.eliminarRepresentanteLegal( 
            idRepresentanteLegal,
            // Si está en modo administración del SUMA, este dato se captura de la url por medio de HookReactRouterV6Parametros
            this.props.idPersonaJuridica 
        );
        if( respuestaApi ){
            switch(respuestaApi.codigo){
                case Constantes.ENUMS_API.PERSONA_JURIDICA.OPERACION_REALIZADA.id:
                    this.setState({ cargando: false });
                    Modal.success({ content:'Representante legal "' + representanteLegal + '" eliminado' });
                    this.historyLocal.push( this.reemplazarValoresEnElPath(this.PATHS.REPRESENTANTES) );
                    break;
                default:
                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:RL04]" });
                    break;    
            }
        }
    };

    clickAgregarRepresentanteLegal = e => {
        this.setState({ idRepresentanteLegal: 0 });
        this.historyLocal.push( this.reemplazarValoresEnElPath(this.PATHS.AGREGAR_REPRESENTANTE,undefined) );
    }

    clickEditarRepresentanteLegal = (e,idRepresentanteLegal) => {
        this.setState({ idRepresentanteLegal: idRepresentanteLegal });
        this.historyLocal.push( this.administracion ?
            this.reemplazarValoresEnElPath( this.PATHS.EDITAR_REPRESENTANTE, idRepresentanteLegal ) 
            :
            this.PATHS.EDITAR_REPRESENTANTE + '?id=' + idRepresentanteLegal );
    }

    reemplazarValoresEnElPath  = (path, idRepresentanteLegal) => {
        return path
        // Se toma el idPersonaJuridica de la url por medio del HookReactRouterV6Parametros
        .replace(':idPersonaJuridica',this.props.idPersonaJuridica)
        .replace(':idRepresentanteLegal',idRepresentanteLegal);
    }
    
    render() {
      //let  {form} = this.props.form;
      
      const { componente, usuario } = this.state;

        return (
          <div>    
            {(componente === this.ENUM_COMPONENTE.VER) ?
                (
                    <>
                        <Switch>
                            <Route path={this.PATHS.VER_REPRESENTANTE}>
                                <VerRepresentanteLegal  callbackEditarRepresentanteLegal={ this.clickEditarRepresentanteLegal }
                                                        callbackActivarRepresentanteLegal={ this.clickActivarRepresentanteLegal }
                                                        callbackEliminarRepresentanteLegal={ this.eliminarRepresentanteLegal} 
                                                        // Indica que es el administrador del SUMA quien está accediendo al representante legal
                                                        administracion={this.administracion} 
                                                        // Se toma el idPersonaJuridica de la url por medio del HookReactRouterV6Parametros
                                                        idPersonaJuridica={this.props.idPersonaJuridica}
                                                        // Se toma el idRepresentanteLegal de la url por medio del HookReactRouterV6Parametros
                                                        idRepresentanteLegal={this.props.idRepresentanteLegal}
                                                        />
                            </Route>

                            <Route path={this.PATHS.AGREGAR_REPRESENTANTE}>
                                <AgregarEditarRepresentanteLegal    onSuccess={( nuevoRepresentante, idRepresentanteLegal) => {this.representanteLegalGuardado( nuevoRepresentante, idRepresentanteLegal )}} 
                                                                    // Indica que es el administrador del SUMA quien está accediendo al representante legal
                                                                    administracion={this.administracion} 
                                                                    // Se toma el idPersonaJuridica de la url por medio del HookReactRouterV6Parametros
                                                                    idPersonaJuridica={this.props.idPersonaJuridica}/>
                            </Route>

                            <Route path={this.PATHS.EDITAR_REPRESENTANTE}>
                                <AgregarEditarRepresentanteLegal    onSuccess={( nuevoRepresentante, idRepresentanteLegal) => {this.representanteLegalGuardado( nuevoRepresentante, idRepresentanteLegal )}} 
                                                                    // Indica que es el administrador del SUMA quien está accediendo al representante legal
                                                                    administracion={this.administracion} 
                                                                    // Se toma el idPersonaJuridica de la url por medio del HookReactRouterV6Parametros
                                                                    idPersonaJuridica={this.props.idPersonaJuridica}
                                                                    // Se toma el idRepresentanteLegal de la url por medio del HookReactRouterV6Parametros
                                                                    idRepresentanteLegal={this.props.idRepresentanteLegal}/>
                            </Route>

                            <Route path={this.PATHS.REPRESENTANTES}>
                                <>
                                    <div className="div-lista-botones">
                                        <Button type="primary" 
                                                onClick={this.clickAgregarRepresentanteLegal} 
                                                icon={<PlusOutlined />}
                                                disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_REPRESENTANTES_LEGALES.ACCIONES.AGREGAR_REPRESENTANTE_LEGAL.id)}>
                                            Agregar representante legal
                                        </Button>
                                    </div>
                                    <ListarRepresentantesLegales    callbackVerRepresentanteLegal={this.clickVerRepresentanteLegal} 
                                                                    callbackActivarRepresentanteLegal={this.clickActivarRepresentanteLegal} 
                                                                    // El idPersonaJuridica se puede tomar por el componente padre como atributo, si no llega por aquí, se toma la perona jurídica del usuario que está logueado.
                                                                    idPersonaJuridica={this.props.idPersonaJuridica}/>        
                                </>
                            </Route>
                        </Switch>
                    </>
                )
                :
                (
                    (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                    (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                    :(componente === this.ENUM_COMPONENTE.DILIGENCIAR_DATOS_BASICOS) ?
                    (<label>Para habilitar esta sección debes diligenciar y guardar primero los datos de la entidad persona jurídica.</label>)
                    :((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:RL]</label>) : (<></>))
                )
            }
            
          </div>
        );
    }
}
          
export default withRouter(withCookies(HookReactRouterV6Parametros(RepresentantesLegales)));