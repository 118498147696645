import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Modal, Spin, Row, Col, Form, Input, Select, AutoComplete } from 'antd';
import Constantes from '../utilidades/Constantes'
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import ServicioDatosConstantes from '../servicios/ServicioDatosConstantes'
import ServicioAdministracion from '../servicios/ServicioAdministracion'
import FormularioUsuarioDatoPersonal from '../zona-usuario/usuario-general/FormularioUsuarioDatoPersonal'
import queryString from 'query-string';


class AgregarEditarUsuario extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        idUsuario: undefined,
        usuario: undefined,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        roles: [],
        rol: undefined,
    };

    constructor(props) {
        super(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
        this.onSuccess = props.onSuccess;
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let idTipoUsuario;
        switch (this.props.location.pathname) {
            case this.props.paths.AGREGAR_USUARIO_ADM_SUMA:
                idTipoUsuario = Constantes.ENUMS.TIPO_USUARIO.ADMINISTRADOR_SUMA.id;
                break;
            case this.props.paths.AGREGAR_USUARIO_DP:
                idTipoUsuario = Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id;
                break;
            default:
                idTipoUsuario = undefined;
                break;
        }

        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        let idUsuario = undefined;

        if (urlparametros.id !== undefined && !isNaN(parseInt(urlparametros.id))) {
            idUsuario = parseInt(urlparametros.id);
        }
        this.setSumaState({ idUsuario: idUsuario });

        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr !== null) {
            // Valida que tenga la accion habilitada EDITAR o AGREGAR
            let habilitado = await this.servicioUsuarios.accionHabilitadaPromesa(idUsuario ? Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.EDITAR_USUARIO.id : Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.AGREGAR_USUARIO.id);
            if (habilitado && habilitado.resultado) {

                if (idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.ADMINISTRADOR_SUMA.id) {
                    let roles = await this.servicioDatosConstantes.obtenerRolesAdminsitradorSuma();
                    this.setSumaState({ roles: roles ? roles : [] });
                }

                this.setSumaState({ 
                    idTipoUsuario: idTipoUsuario,
                    esUsuarioDadep: (idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.ADMINISTRADOR_SUMA.id)
                });
                if (idUsuario) {
                    // Se consulta el usuario y se carga (Esto es en modo Edición de usuario)
                    let usuario = await this.servicioAdministracion.obtenerUsuario(idUsuario);
                    if (usuario) {
                        //console.log('usuario', usuario);
                        let _esUsuarioDadep =   (usuario.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.ADMINISTRADOR_SUMA.id) || 
                                                (   usuario.usuarioDependencia && 
                                                    usuario.usuarioDependencia.dependencia && 
                                                    usuario.usuarioDependencia.dependencia.entidad && 
                                                    usuario.usuarioDependencia.dependencia.entidad.id === Constantes.ENUMS.ENTIDAD.DEPARTAMENTO_ADMINISTRATIVO_DE_LA_DEFENSORIA_DEL_ESPACIO_PUBLICO
                                                );
                        //console.log('_esUsuarioDadep', _esUsuarioDadep);
                        this.setSumaState({
                            componente: this.ENUM_COMPONENTE.VER,
                            usuario: usuario, idTipoUsuario: usuario.tipoUsuario.id,
                            esUsuarioDadep:_esUsuarioDadep
                        });
                    } else {
                        this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
                    }
                } else {
                    this.setSumaState({ componente: this.ENUM_COMPONENTE.VER });
                }

            } else {
                this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    camposAdicionales = (usuario, idTipoUsuario, roles, cargandoRoles) => {
        // Si no existe el usuario está en modo 'Agregar nuevo usuario', de lo contrario
        // está en modo 'Edición Usuario'
        if( !usuario ){
            if (idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.PERSONA_NATURAL.id) {
                return;
            }
            if (idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.ADMINISTRADOR_SUMA.id) {
                return (
                    <Row>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Rol" name="rol"
                                rules={[{ required: true, message: 'Selecciona el rol del usuario', }]}>
                                <Select>
                                    {roles.map(rol => (
                                        <Select.Option key={rol.id}>{rol.rol}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                );
            }
            if (idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id) {
                return (
                    <Row>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Cargo" name="cargo"
                                rules={[
                                    { required: true, message: 'Ingresa el cargo', },
                                    { min: 4, message: 'No puede ser menor a 4 caracteres', },
                                    { max: 500, message: 'No puede ser mayor a 255 caracteres', },
                                ]}
                            >
                                <Input autoComplete="off" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Rol" name="rol"
                                rules={[{ required: true, message: 'Selecciona el rol del usuario', }]}
                                {...cargandoRoles ? { validateStatus: 'validating', hasFeedback: true } : {}}>
                                <Select {...(cargandoRoles ? { disabled: true } : {})}>
                                    {roles.map(rol => (
                                        <Select.Option key={rol.id}>{rol.rol}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                )
            }
        }
        return (<></>);
    }

    renderTituloItems = (titulo) => (
        <div className={"titulo-opciones"}>
            <span>{titulo}</span>
        </div>
    );

    renderItemPersonaJuridica = (id, razonSocial, nit, camaraComercio) => ({
        key: id,
        value: razonSocial + ' (Id:' + id + ')',
        label: (
            <div className={"item-personalizado"}>
                <span className={"titulo-item"}>{razonSocial} (Id:{id})</span><br />
                <span className={"sub-titulo-item"}>Nit: {nit}  -  Cámara Comercio: {camaraComercio}</span>
            </div>
        ),
    });

    renderItemDependencia = (id, dependecia, entidad, idEntidad) => ({
        key: id,
        value: dependecia,
        id_entidad: idEntidad,
        label: (
            <div className={"item-personalizado"}>
                <span className={"titulo-item"}>{dependecia}</span><br />
                <span className={"sub-titulo-item"}>Entidad: {entidad}</span>
            </div>
        ),
    });

    buscar = async (value) => {
        let opciones = [{
            label: this.renderTituloItems(
                this.state.idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.PERSONA_JURIDICA.id ?
                    'Persona jurídica'
                    : 'Dependencia'
            ),
            options: [],
        }];
        if (value) {
            if (this.state.idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.PERSONA_JURIDICA.id) {
                let personasJuridicas = await this.servicioAdministracion.buscarPersonaJuridica(value);
                for (let i in personasJuridicas) {
                    opciones[0].options.push(this.renderItemPersonaJuridica(personasJuridicas[i].id, personasJuridicas[i].razonSocial, personasJuridicas[i].nit, personasJuridicas[i].camaraComercio));
                }
            } else if (this.state.idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id) {
                let depencias = await this.servicioAdministracion.buscarDependencia(value);
                //console.log('depencias', depencias);        
                for (let i in depencias) {
                    opciones[0].options.push(this.renderItemDependencia(depencias[i].id, depencias[i].dependencia, depencias[i].entidad.razonSocial, depencias[i].entidad.id));
                }
            }
        }
        this.setSumaState({ opciones: opciones });
    };

    onSelectAutoComplete = async (item) => {
        //console.log('onSelect', item);
        let roles = [];
        // keyAutoComplete: Guarda el key de la selección hecha sobre el componente AutoComplete
        this.setSumaState({ valueAutoComplete: item.value, keyAutoComplete: item.key, rol: undefined });
        // Se trae el form del componente hijo 'FormularioUsuarioDatoPersonal'
        let form = this.refFormularioUsuarioDatoPersonal.formRef.current;
        if (form !== null) {
            form.setFieldsValue({ 'rol': '' });
        }
        if (this.state.idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id) {
            let idDependencia = item.key;
            let _esUsuarioDadep = item.id_entidad === Constantes.ENUMS.ENTIDAD.DEPARTAMENTO_ADMINISTRATIVO_DE_LA_DEFENSORIA_DEL_ESPACIO_PUBLICO
            
            if(this.state.esUsuarioDadep || _esUsuarioDadep){
                form.setFieldsValue({ 
                    'primerNombre':'', 
                    'segundoNombre':'', 
                    'primerApellido':'',
                    'segundoApellido':'',
                    'numeroDocumento':''});
            }

            this.setSumaState({ 
                cargandoRoles: true,
                esUsuarioDadep: _esUsuarioDadep
            });
            roles = await this.servicioDatosConstantes.obtenerRolesDependencia(idDependencia);
        }

        this.setSumaState({ roles: roles ? roles : [], cargandoRoles: false });
    };

    cambioAutoComplete = (value) => {
        if (!this.state.valueAutoComplete || (value.normalize() !== this.state.valueAutoComplete.normalize())) {
            // keyAutoComplete: Guarda el key de la selección hecha sobre el componente AutoComplete
            this.setSumaState({ keyAutoComplete: null });
        }
    }

    errorFormularioIncompleto = errorInfo => {
        // Si no existe el usuario está en modo 'Agregar nuevo usuario', de lo contrario
        // está en modo 'Edición Usuario'
        // keyAutoComplete: Guarda el key de la selección hecha sobre el componente AutoComplete
        if ( (!this.state.usuario && this.state.idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id) &&
             (!this.state.keyAutoComplete || this.state.keyAutoComplete === null)
        ) {
            this.setSumaState({ keyAutoComplete: null });
            Modal.warn({ content: "Por favor selecciona la dependencia." });
        } else {
            Modal.warn({ content: "Por favor diligencia la información solicitada para el usuario" });
        }
    };

    formularioCompleto = (values) => {
        return new Promise(resolve => {
            let usr = {
                usuario: values.correoElectronico,
                // Si es entidad se agrega los campos
                ...(this.state.idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id && {
                    usuarioDependencia: {
                        // keyAutoComplete: Guarda el key de la selección hecha sobre el componente AutoComplete
                        dependencia: { id: this.state.keyAutoComplete },
                        cargo: values.cargo
                    }
                }),
                datoPersonal: {
                    primerNombre: values.primerNombre,
                    segundoNombre: values.segundoNombre,
                    primerApellido: values.primerApellido,
                    segundoApellido: values.segundoApellido,
                    correoElectronico: values.correoElectronico.toLowerCase(),
                    numeroDocumento: values.numeroDocumento,
                    lugarExpedicion: values.lugarExpedicion,
                    ...(values.fechaExpedicion && {fechaExpedicionDocumento: values.fechaExpedicion.format('DD/MM/YYYY')}),
                    direccion: values.direccion,
                    celular: values.movil,
                    telefono: values.telefono,
                    ciudad: {
                        id: parseInt(values.ciudad)
                    },
                    tipoDocumento: {
                        id: parseInt(values.tipoDocumento)
                    },
                }
            };

            if (values.rol) {
                usr.rol = { id: parseInt(values.rol) };
            }
            resolve(usr);

        }).then(async (usr) => {
            let resultado = { cargando: false, limpiarFormulario: false };
            if (!this.state.idUsuario) {
                // Usuario Nuevo
                let respuestaApi = await this.servicioAdministracion.crearUsuarioAdministracion(usr);
                if (respuestaApi) {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.USUARIO.USUARIO_CREADO.id:
                            resultado.satisfactorio = true;
                            resultado.limpiarFormulario = true;
                            this.onSuccess(respuestaApi.resultado);
                            break;
                        case Constantes.ENUMS_API.USUARIO.CORREO_NO_DISPONIBLE.id:
                            Modal.error({ content: "El correo electrónico ya se encuentra registrado. Por favor intenta con uno diferente." });
                            break;
                        case Constantes.ENUMS_API.USUARIO.DOCUMENTO_IDENTIDAD_NO_DISPONIBLE.id:
                            Modal.error({content:'No fue posible crear el usuario. Ya se encuentra registrado el número de documento de identidad que fue ingresado.'});
                            break;    
                        case Constantes.ENUMS_API.USUARIO.ERROR_SERVICIO_CORREO_ELECTRONICO.id:
                            Modal.error({content:'El Usuario ha sido creado, sin embargo se ha presentado un problema en el envío del correo electrónico para iniciar el proceso de activación del mismo. Por favor valida la configuración del servicio SMTP.'});
                            break;
                        case Constantes.ENUMS_API.USUARIO.DOMINIO_DEL_DADEP.id:
                            Modal.error({content:respuestaApi.mensaje});
                            break;
                        case Constantes.ENUMS_API.USUARIO.USUARIO_NO_CREADO.id:
                        default:
                            Modal.error({ content: 'No fue posible crear el usuario dependencia. Por favor intenta mas tarde.' });
                            break;
                    }
                }
            } else {
                // Editar Usuario 
                usr.id = this.state.idUsuario;
                usr.estadoUsuario = { id: parseInt(values.estado) };
                usr.usuario = values.usuario;
                usr.correoElectronico = values.correoElectronicoUsuario;

                let respuestaApi = await this.servicioAdministracion.actualizarUsuarioAdministracion(usr);
                if (respuestaApi) {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id:
                            this.onSuccess(respuestaApi.resultado);
                            break;
                        case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_NO_REALIZADA.id:
                            Modal.error({ content: respuestaApi.mensaje });
                            break;    
                        default:
                            Modal.error({ content: 'No fue posible actualizar el usuario. Por favor intenta mas tarde.' });
                            break;
                    }
                }

            }
            return resultado;

        });

    };

    cancelarFormularioUsuario = () => {
        this.onSuccess(this.state.idUsuario, true);
    }

    render() {
        const { usuario, idUsuario, componente, roles, cargandoRoles, idTipoUsuario, opciones, esUsuarioDadep } = this.state;

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>

                            <div className="titulo-seccion">
                                <label>
                                    { !idUsuario ? 'AGREGAR ' : 'EDITAR '} 
                                    USUARIO
                                    {   idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.ADMINISTRADOR_SUMA.id ? ' ADMINISTRADOR SUMA' :
                                        (idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id ? ' DEPENDENCIA' : '')
                                    }
                                </label>
                                <div className="separador-horizontal-completo margen-bottom-10"></div>
                            </div>

                            { !idUsuario && idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id ?
                                <div className={"sub-seccion"}>
                                    <div className="sub-titulo-seccion">
                                        <label>
                                            {idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id ? 'Dependencia' : ''}
                                        </label>
                                        <div className="separador-horizontal-completo02 margen-bottom-20"></div>
                                    </div>

                                    <Row className={this.state.keyAutoComplete === null ? "row-error-autocomplete" : ""}>
                                        <Col xs={24} sm={{ span: 5, offset: 1 }}>
                                            <label>
                                                Selecciona la
                                            {idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id ?
                                                    ' dependencia' : ''}:
                                        </label>
                                        </Col>
                                        <Col xs={24} sm={{ span: 17, offset: 1 }}>
                                            <AutoComplete
                                                style={{ 'width': '100%' }}
                                                options={opciones}
                                                onSelect={(value, item) => { this.onSelectAutoComplete(item) }}
                                                onSearch={this.buscar}
                                                onChange={this.cambioAutoComplete}
                                            >
                                                <Input.Search size="large" enterButton
                                                    placeholder={idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id ?
                                                        'Buscar por nombre de la dependencia o entidad' : ''} />
                                            </AutoComplete>
                                        </Col>
                                    </Row>
                                </div> : (<></>)
                            }

                            <div className="sub-titulo-seccion">
                                <label>Datos usuario</label>
                                <div className="separador-horizontal-completo02 margen-bottom-20"></div>
                            </div>


                            <FormularioUsuarioDatoPersonal
                                formularioIncompleto={this.errorFormularioIncompleto}
                                formularioCompleto={this.formularioCompleto}
                                nombreBotonSubmit={idUsuario ? 'Actualizar datos' : 'Crear usuario'}
                                textoCargando={idUsuario ? 'Actualizando datos ...' : 'Creando usuario ...'}
                                usuario={usuario}
                                opcionCancelar={true}
                                cancelarCallback={this.cancelarFormularioUsuario}
                                camposAdicionales={() => this.camposAdicionales(usuario, idTipoUsuario, roles, cargandoRoles)}
                                childRef={ref => (this.refFormularioUsuarioDatoPersonal = ref)}
                                admin={true}
                                esUsuarioDadep={esUsuarioDadep}
                            />

                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:AEUSU]</label>) : (<></>))
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(AgregarEditarUsuario));