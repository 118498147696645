import axios from 'axios';
import Constantes from '../utilidades/Constantes'
import Utilidades from '../utilidades/Utilidades'
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import { Modal } from 'antd';

class ServicioEntidad {

    constructor(props) {
        const { cookies } = props;
        this.cookies = cookies;
        this.servicioUsuarios = new ServicioUsuarios( props );
    }


    // Método privado
    usuarioValido() {
        return new Promise(resolve => {
            this.servicioUsuarios.obtenerUsuarioLogeado(
              ( dataAPI )    => { 
                    if( dataAPI.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id ){
                        resolve( dataAPI );
                    }else{
                        Modal.error({ content: "Contenido no disponible. [Cod:SE05]" });
                        resolve( null );
                    }
                },
              ( errorAPI )   => { 
                  Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SE06]" });
                  resolve( null );},
            );
        });
    }


    // PROMESA ( obtenerOpcionSUMADependencia )
    obtenerOpcionSUMADependenciaPromise(vista) {
        return new Promise(resolve => {
            this.obtenerOpcionSUMADependencia(
                (dataAPI) => {
                    if (dataAPI !== null) {
                        resolve(dataAPI);
                    } else {
                        Modal.error({ content: "Contenido no disponible. [Cod:SE01] " + vista });
                        resolve(null);
                    }
                },
                (errorAPI) => {
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SE02] " + vista });
                    resolve(null);
                },
            );
        });
    }

    // Método privado 
    obtenerOpcionSUMADependencia(callbackComplete, callbalkError) {
        let token = this.cookies.get('sumajwt');
        const config = { headers: { 'Authorization': `Bearer ${token}` } };
        axios.get(Constantes.URL_API_SUMA + '/entidad/opcion-suma/', config)
            .then(res => {
                if (res.status === 200) {
                    callbackComplete(res.data);
                } else {
                    callbalkError();
                }
            }).catch(err => {
                if (Utilidades.servicioHTTPErrorControladoGeneral(err)) {
                    return;
                }
                console.error('[Error.ServicioEntidad.obtenerOpcionSUMADependencia]', err);
                callbalkError({ err });
            });
    }


    // PROMESA ( obtenerDependencia )
    obtenerDependenciaPromise(idDependencia, vista) {
        return new Promise(resolve => {
            this.obtenerDependencia(
                idDependencia,
                (dataAPI) => {
                    if (dataAPI !== null) {
                        resolve(dataAPI);
                    } else {
                        Modal.error({ content: "Contenido no disponible. [Cod:SE03] " + vista });
                        resolve(null);
                    }
                },
                (errorAPI) => {
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SE04] " + vista });
                    resolve(null);
                },
            );
        });
    }

    // Método privado 
    obtenerDependencia(idDependencia, callbackComplete, callbalkError) {
        let token = this.cookies.get('sumajwt');
        const config = { headers: { 'Authorization': `Bearer ${token}` } };
        axios.get(Constantes.URL_API_EXTERNO_SUMA + '/entidad/dependencia/' + idDependencia, config)
            .then(res => {
                if (res.status === 200) {
                    callbackComplete(res.data);
                } else {
                    callbalkError();
                }
            }).catch(err => {
                if (Utilidades.servicioHTTPErrorControladoGeneral(err)) {
                    return;
                }
                console.error('[Error.ServicioEntidad.obtenerDependencia]', err);
                callbalkError({ err });
            });
    }


    // Método privado
    guardarDatosDependencia ( dependencia ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };
        return new Promise(resolve =>{
            axios.put( Constantes.URL_API_SUMA + '/entidad/dependencia',dependencia,config)
            .then(res => {
                if(res.status === 200){
                    //if (res.data.codigo === Constantes.ENUMS_API.ENTIDAD.OPERACION_REALIZADA.id) {
                        resolve( res.data );
                        return;    
                    /*}else{
                        Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SE07]" });
                        resolve();
                        return;    
                    }*/
                }else{
                    throw new Error("Error guardar datos dependencia. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioEntidad.guardarDatosDependencia]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SE08]" }); 
                resolve();
            });
        });
    }


    // Método privado
    crearDependencia ( dependencia ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };
        return new Promise(resolve =>{
            axios.post( Constantes.URL_API_SUMA + '/entidad/dependencia',dependencia,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                }else{
                    throw new Error("Error crear dependencia. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioEntidad.crearDependencia]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SE17]" }); 
                resolve();
            });
        });
    }


    // Método privado
    obtenerSubUsuariosDependencia ( filtroApi ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }, params: filtroApi};

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/entidad/dependencia/sub-usuario',config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener Sub usuarios. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioEntidad.obtenerSubUsuariosDependencia]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SE09]" }); 
                resolve();
            });
        });
    }



    // Método privado
    crearSubUsuarioDependencia ( usr ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.post( Constantes.URL_API_SUMA + '/entidad/dependencia/sub-usuario',usr,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Crear Sub usuarios. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioEntidad.crearSubUsuarioDependencia]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SE10]" }); 
                resolve();
            });
        });
    }



    // Método privado
    actualizarSubUsuarioDependencia ( usr ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        return new Promise( resolve =>{
            axios.put( Constantes.URL_API_SUMA + '/entidad/dependencia/sub-usuario', usr, config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Actualizar Sub usuarios. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioEntidad.actualizarSubUsuarioDependencia]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SE13]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerUsuarioDependencia ( idUsuario ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/entidad/dependencia/sub-usuario/' + idUsuario, config)
            .then(res => {
                if(res.status === 200){
                    if (res.data.codigo === Constantes.ENUMS_API.ENTIDAD.DATOS_RETORNADOS.id) {
                        resolve( res.data.resultado );
                        return;    
                    }else{
                        Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SE11]" });
                        resolve();
                        return;    
                    }
                }else{
                    throw new Error("Error obtener sub usuario dependencia. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioEntidad.obtenerUsuarioDependencia]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SE12]" }); 
                resolve();
            });
        });
    }


    // Método privado
    enviarCorreoActivacion ( idUsuario ) {
        let datos = {};
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        return new Promise( resolve =>{
            axios.put( Constantes.URL_API_SUMA + '/entidad/dependencia/sub-usuario/correo-activacion/' + idUsuario, datos, config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Enviar correo activación sub usuario. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioEntidad.enviarCorreoActivacion]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SE14]" }); 
                resolve();
            });
        });
    }


    // Método privado
    enviarCorreoCambioContrasena ( idUsuario ) {
        let datos = {};
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        return new Promise( resolve => {
            axios.put( Constantes.URL_API_SUMA + '/entidad/dependencia/sub-usuario/correo-cambio-contrasena/' + idUsuario, datos, config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error enviar correo cambio de contraseña sub usuario. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioEntidad.enviarCorreoCambioContrasena]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SE15]" }); 
                resolve();
            });
        });
    }


    // Método privado
    activarUsuario ( idUsuario, activar ) {
        let datos = {};
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        return new Promise( resolve => {
            axios.put( Constantes.URL_API_SUMA + '/entidad/dependencia/sub-usuario/' + ( activar ? 'activar' : 'desactivar') + '/' + idUsuario, datos, config)
            .then( res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error " +( activar ? 'activar' : 'desactivar')+ " sub usuario. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioEntidad.activarUsuario]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SE16]" }); 
                resolve();
            });
        });
    }



    // Método privado
    obtenerVistaOpcionesDependencia ( idDependencia, filtroApi ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }, params: filtroApi};

        return new Promise( resolve =>{
            axios.get( Constantes.URL_API_SUMA + `/entidad/dependencia/${idDependencia ? idDependencia : 0}/vista-opcion-dependencia`, config)
            .then(res => {
                if(res.status === 200){
                    if (res.data.codigo === Constantes.ENUMS_API.ENTIDAD.DATOS_RETORNADOS.id) {
                        resolve( res.data.resultado );
                        return;    
                    }else{
                        Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SE18]" });
                        resolve();
                        return;    
                    }
                }else{
                    throw new Error("Error obtener opciones dependencia. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioEntidad.obtenerVistaOpcionesDependencia]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SE19]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerAplicacionConectada () {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/entidad/aplicacion-conectada', config)
            .then(res => {
                if(res.status === 200){
                    if (res.data.codigo === Constantes.ENUMS_API.ENTIDAD.DATOS_RETORNADOS.id) {
                        resolve( res.data.resultado );
                        return;    
                    }else{
                        Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SE20]" });
                        resolve();
                        return;    
                    }
                }else{
                    throw new Error("Error obtener aplicación conectada. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioEntidad.obtenerAplicacionConectada]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SE21]" }); 
                resolve();
            });
        });
    }


    // Método privado
    actualizarAplicacionConectada ( aplicacionConectadaDto ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        return new Promise( resolve =>{
            axios.put( Constantes.URL_API_SUMA + '/entidad/aplicacion-conectada', aplicacionConectadaDto, config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error actualizar aplicación conectada. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioEntidad.actualizarAplicacionConectada]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SE22]" }); 
                resolve();
            });
        });
    }

}

export default ServicioEntidad;