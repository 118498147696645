import React, { Component } from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

class FiltroEtiqueta extends Component {

    // PILAS, NO SE DEBEN TOMAR LAS PROPIEDADES EN EL CONSTRUCTOR O SINO
    // QUEDAN ALMACENADAS DE FORMA ESTÁTICA Y NO SE REFRESCAN AL MOMENTO
    // DE HACER CAMBIOS EN LA ETIQUETA DEL FILTRO
    //constructor(props) {super(props);}

    render() {
        return (
            <div className="etiqueta-filtro">
                <span>
                    {this.props.texto}
                </span>
                <Button shape="circle" 
                        onClick={this.props.onClose} 
                        icon={<CloseOutlined style={{ color: '#8c8c8c' }} />}
                        size="small"
                        type="text"
                         />
            </div>
        );
    }
}

export default FiltroEtiqueta;