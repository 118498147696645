import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Form, Input, Button, Row, Col, Select, DatePicker, Modal, Spin, Popconfirm } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Validadores from '../../utilidades/Validadores';
import Constantes from '../../utilidades/Constantes';
import ServicioDatosConstantes from '../../servicios/ServicioDatosConstantes';
import ServicioPersonaJuridica from '../../servicios/usuarios/ServicioPersonaJuridica';
import ServicioPersonaNatural from '../../servicios/usuarios/ServicioPersonaNatural';
import SubirArchivoForm from '../../carga-archivos/SubirArchivoForm';
import Utilidades from '../../utilidades/Utilidades';
import moment from 'moment';
import queryString from 'query-string';

const dateFormat = 'DD/MM/YYYY';

class AgregarEditarApoderado extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        tipoArhivosApoderado: [],
        tiposDocumentos: [],
        cargandoTipoDocumentos: false,
        cargando: false,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        idApoderado: 0,
    };


    constructor(props) {
        super(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.servicioPersonaJuridica = new ServicioPersonaJuridica(props);
        this.servicioPersonaNatural = new ServicioPersonaNatural(props);
        this.onSuccess = props.onSuccess;
    }

    formRef = React.createRef();

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        let idApoderado = 0;

        if (urlparametros.id !== undefined && !isNaN(parseInt(urlparametros.id))) {
            idApoderado = parseInt(urlparametros.id);
            this.setSumaState({ idApoderado: idApoderado });
        }

        // Validando que el usuario es PERSONA NATURAL
        let usr = await this.servicioPersonaNatural.usuarioValido();
        this.setSumaState({ componente: (usr !== null ? this.ENUM_COMPONENTE.VER : this.ENUM_COMPONENTE.NO_DISPONIBLE) });

        if (this.state.componente === this.ENUM_COMPONENTE.VER) {
            let form = this.formRef.current;
            if (form != null) {
                this.setSumaState({ cargando: true, cargandoTipoDocumentos: true });
                // Cargando el tipo de documentos 
                await this.obtenerTipoDocumentos();
                // Cargando tipos de archivos apoderado
                await this.obtenerTipoArchivosApoderado();

                if (idApoderado !== 0) {
                    //console.log( 'idApoderado', idApoderado );
                    let apoderado = await this.obtenerApoderado();
                    //console.log('apoderado',apoderado);
                    if (apoderado !== null) {
                        form.setFieldsValue({
                            'primerNombre': apoderado.datoPersonal.primerNombre,
                            'segundoNombre': apoderado.datoPersonal.segundoNombre,
                            'primerApellido': apoderado.datoPersonal.primerApellido,
                            'segundoApellido': apoderado.datoPersonal.segundoApellido,
                            'correoElectronico': apoderado.datoPersonal.correoElectronico,
                            'direccion': apoderado.datoPersonal.direccion,
                            'tipoDocumento': apoderado.datoPersonal.tipoDocumento.id.toString(),
                            'numeroDocumento': apoderado.datoPersonal.numeroDocumento,
                            'lugarExpedicion': apoderado.datoPersonal.lugarExpedicion,
                            'movil': apoderado.datoPersonal.celular,
                            'telefono': apoderado.datoPersonal.telefono,
                            ...(apoderado.datoPersonal.fechaExpedicionFormatoEstandar && {'fechaExpedicion': moment(apoderado.datoPersonal.fechaExpedicionFormatoEstandar, dateFormat)} ),
                        });
                    }
                }
                this.setSumaState({ cargando: false });
            }
        }
    }

    obtenerApoderado() {
        return new Promise(resolve => {
            this.servicioPersonaNatural.obtenerApoderado(
                (respuestaApi) => {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.PERSONA_NATURAL.DATOS_RETORNADOS.id:
                            resolve(respuestaApi.resultado);
                            break;
                        default:
                            Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:AEA01]" });
                            resolve(null);
                            break;
                    }
                },
                (errorAPI) => {
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:AEA02]" });
                    resolve(null);
                },
                this.state.idApoderado
            );
        });
    }

    obtenerTipoDocumentos() {
        return new Promise(resolve => {
            this.servicioDatosConstantes.obtenerTiposDocumentos(
                (tiposDocumentos) => {
                    this.setSumaState({ tiposDocumentos: tiposDocumentos, });
                    setTimeout(() => { this.setSumaState({ cargandoTipoDocumentos: false }); }, 500);
                    resolve(true);
                },
                (errorAPI) => {
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:AEA03]" });
                    resolve(true);
                },
            );
        });
    }

    obtenerTipoArchivosApoderado() {
        return new Promise(resolve => {
            this.servicioDatosConstantes.obtenerTipoArchivosApoderado(
                (tipoArhivosApoderado) => {
                    this.setSumaState({ tipoArhivosApoderado: tipoArhivosApoderado, });
                    resolve(true);
                },
                (errorAPI) => {
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:AEA04]" });
                    resolve(true);
                },
            );
        });
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    async asynFormularioCompleto(values) {

        let total = await this.servicioPersonaNatural.obtenerTotalApoderados ();
        if ( total >= Constantes.LIMITES.NUMERO_MAXIMO_APODERADOS_PERSONA_NATURAL ){
            this.setSumaState({ cargando: false });
            Modal.warning({ content: "No puedes agregar más de " + Constantes.LIMITES.NUMERO_MAXIMO_APODERADOS_PERSONA_NATURAL + " apoderados." });
            return;
        }

        let { tipoArhivosApoderado } = this.state;
        let success = true;
        let apoderadoArchivos = [];
        //console.log('onFinish values:',values );
        if (this.state.idApoderado === 0) {
            // Cargando los archivos
            for (let i = 0; i < tipoArhivosApoderado.length; i++) {
                let formItemUpload = values['form_item_' + tipoArhivosApoderado[i].nombreIdentificador];
                if (formItemUpload !== undefined) {
                    // En este punto se asume que viene un array de solo 1 archivo
                    let file = formItemUpload[0];
                    // Se cargan los archivos al API y se guardan en base de datos
                    let result = await file.cargarArchivo(file.informacion.file);
                    if (result === null || result.codigo !== 1) {
                        success = false;
                        Modal.error({ content: "Error del sistema al cargar los documentos, por favor intenta más tarde. [Cod:AEA10]" });
                        return;
                    } else {
                        apoderadoArchivos.push({ archivo: { id: result.resultado } });
                    }
                }
            }
        }

        let apoderado = {
            datoPersonal: {
                primerNombre: values.primerNombre,
                segundoNombre: values.segundoNombre,
                primerApellido: values.primerApellido,
                segundoApellido: values.segundoApellido,
                correoElectronico: values.correoElectronico.toLowerCase(),
                numeroDocumento: values.numeroDocumento,
                lugarExpedicion: values.lugarExpedicion,
                fechaExpedicionDocumento: values.fechaExpedicion.format('DD/MM/YYYY'),
                direccion: values.direccion,
                celular: values.movil,
                telefono: values.telefono,
                tipoDocumento: {
                    id: parseInt(values.tipoDocumento)
                },
            },
        };

        let nuevoIdApoderado = null;
        if (this.state.idApoderado === 0) {
            apoderado.apoderadoArchivos = apoderadoArchivos;
            nuevoIdApoderado = await this.crearApoderado(apoderado);
            //console.log('Crear idRepresentanteLegal:',idRepresentanteLegal );
        } else {
            apoderado.id = this.state.idApoderado;
            nuevoIdApoderado = await this.actualizarApoderado(apoderado);
            //console.log('Actualizar representanteLegal:',idRepresentanteLegal );
        }


        if (nuevoIdApoderado === null) {
            success = false;
        } else {
            //this.setSumaState({ idRepresentanteLegal: idRepresentanteLegal });
            //notification.success({ message: 'Operación satisfactoria', description: "El representante legal ha sido creado", duration: 6, });
        }

        if (success && nuevoIdApoderado !== -1) {
            this.onSuccess(nuevoIdApoderado);
            //return { limpiarFormulario:false, cargando:false };
        } else {
            this.setSumaState({ cargando: false });
            if ( !success && nuevoIdApoderado !== -1 ){
                Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:AEA05]" });
            }
        }
    }

    cancelarFormulario = () => {
        this.onSuccess(this.state.idApoderado, true);
    }


    crearApoderado(apoderado) {
        return new Promise(resolve => {
            this.servicioPersonaNatural.crearApoderado(
                (respuestaApi) => {
                    //console.log('respuestaApi',respuestaApi);
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.PERSONA_NATURAL.OPERACION_REALIZADA.id:
                            resolve(respuestaApi.resultado);
                            break;
                        case Constantes.ENUMS_API.PERSONA_NATURAL.OPERACION_NO_REALIZADA.id:
                            Modal.error({ content: respuestaApi.mensaje });
                            resolve(-1);
                            break;
                        default:
                            Modal.error({ content: "No fue posible agregar el apoderado, por favor intenta más tarde. [Cod:AEA06]" });
                            resolve(null);
                            break;
                    }
                },
                (errorAPI) => {
                    Modal.error({ content: "No fue posible agregar el apdoerado, por favor intenta más tarde. [Cod:AEA07]" });
                    resolve(null);
                },
                apoderado
            );
        });
    }

    actualizarApoderado(apoderado) {
        return new Promise(resolve => {
            this.servicioPersonaNatural.actualizarApoderado(
                (respuestaApi) => {
                    //console.log('respuestaApi',respuestaApi);
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.PERSONA_NATURAL.OPERACION_REALIZADA.id:
                            resolve(respuestaApi.resultado);
                            break;
                        default:
                            Modal.error({ content: "No fue posible actualizar el apoderado, por favor intenta más tarde. [Cod:AEA08]" });
                            resolve(null);
                            break;
                    }
                },
                (errorAPI) => {
                    Modal.error({ content: "No fue posible actualizar el apoderado, por favor intenta más tarde. [Cod:AEA09]" });
                    resolve(null);
                },
                apoderado
            );
        });
    }


    render() {

        const { textoCargando } = this.props;

        const { Option } = Select;
        const dateFormatList = ['DD/MM/YYYY'];
        const { tiposDocumentos, componente, cargando, idApoderado, cargandoTipoDocumentos, tipoArhivosApoderado } = this.state;

        const layout = {
            labelCol: {
                sm: { span: 22, offset: 2 },
                md: { span: 12, offset: 2 },
                lg: { span: 12, offset: 2 },
                xl: { span: 10, offset: 2 }
            },
            wrapperCol: {
                sm: { span: 22, offset: 2 },
                md: { span: 10 },
                lg: { span: 10 },
                xl: { span: 12 },
            },
        };


        const onFinish = values => {
            this.setSumaState({ cargando: true });
            this.asynFormularioCompleto(values);
        };

        const onFinishFailed = errorInfo => {
            Modal.warn({ content: "Por favor diligencia la información solicitada para apoderado" });
        };


        const normFile = e => {
            if (Array.isArray(e)) { return e; }
            return e && e.fileList;
        };

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <Spin tip={textoCargando} size="large" spinning={this.state.cargando}>
                                <Form
                                    {...layout}
                                    ref={this.formRef}
                                    name="form-representante-legal"
                                    className="formulario-suma"
                                    scrollToFirstError
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                >

                                    <h3 className="sub-titulo-separador margen-bottom">Información básica</h3>
                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Primer nombre" name="primerNombre"
                                                rules={[
                                                    { required: true, message: 'Ingresa tu primer nombre', },
                                                    { max: 100, message: 'No puede ser mayor a 100 caracteres', },
                                                ]}
                                            >
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Segundo nombre" name="segundoNombre"
                                                rules={[
                                                    { required: false, },
                                                    { max: 100, message: 'No puede ser mayor a 100 caracteres', },
                                                ]}
                                            >
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Primer apellido" name="primerApellido"
                                                rules={[
                                                    { required: true, message: 'Ingresa tu primer apellido', },
                                                    { max: 100, message: 'No puede ser mayor a 100 caracteres', },
                                                ]}
                                            >
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Segundo apellido" name="segundoApellido"
                                                rules={[
                                                    { required: false, },
                                                    { max: 100, message: 'No puede ser mayor a 100 caracteres', },
                                                ]}
                                            >
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Correo electrónico" name="correoElectronico"
                                                rules={[
                                                    { required: true, message: 'Ingresa tu correo electrónico', },
                                                    { type: 'email', message: 'Ingresa un correo electrónico válido', },
                                                    { max: 255, message: 'No puede ser mayor a 255 caracteres', },
                                                ]}
                                            >
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Dirección residencia" name="direccion"
                                                rules={[
                                                    { required: true, message: 'Ingresa tu dirección de residencia', },
                                                    { min: 5, message: 'No puede ser menor a 5 caracteres', },
                                                    { max: 250, message: 'No puede ser mayor a 250 caracteres', },
                                                ]} >
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Tipo de documento" name="tipoDocumento"
                                                rules={[{ required: true, message: 'Selecciona tu tipo de documento', },]}
                                                {...(cargandoTipoDocumentos ? { validateStatus: 'validating', hasFeedback: true } : {})}>
                                                <Select {...(cargandoTipoDocumentos ? { disabled: true } : {})}>
                                                    {tiposDocumentos.map(tipoDocumento => (
                                                        <Option key={tipoDocumento.id}>{tipoDocumento.tipoDocumento}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Número de documento" name="numeroDocumento" dependencies={['tipoDocumento']}
                                                rules={[
                                                    { required: true, message: 'Ingresa tu número de documento', },
                                                    { min: 5, message: 'No puede ser menor a 5 caracteres', },
                                                    { max: 50, message: 'No puede ser mayor a 50 caracteres', },
                                                    ({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            if (value && parseInt(getFieldValue('tipoDocumento')) === Constantes.ENUMS.TIPO_DOCUMENTO.CEDULA_CIUDADANIA.id && !Validadores.validarDigitos(value).success) {
                                                                return Promise.reject('Ingresa solo dígitos');
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Lugar de expedición" name="lugarExpedicion"
                                                rules={[
                                                    { required: true, message: 'Ingresa el lugar de expedición del documento', },
                                                    { min: 5, message: 'No puede ser menor a 5 caracteres', },
                                                    { max: 250, message: 'No puede ser mayor a 250 caracteres', },
                                                ]}
                                            >
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Fecha expedición" name="fechaExpedicion" rules={[{ required: true, message: 'Ingresa la fecha de expedición del documento', },]} >
                                                <DatePicker autoComplete="off" format={dateFormatList} style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Móvil" name="movil"
                                                rules={[
                                                    { required: true, message: 'Ingresa tu número móvil', },
                                                    { min: 7, message: 'No puede ser menor a 7 caracteres', },
                                                    { max: 20, message: 'No puede ser mayor a 20 caracteres', },
                                                    { pattern: new RegExp('^[0-9\\+\\s]*$'), message: 'Solo dígitos y puede contener el simbolo +', },
                                                ]} >
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Teléfono" name="telefono"
                                                rules={[
                                                    { required: false },
                                                    { min: 7, message: 'No puede ser menor a 7 caracteres', },
                                                    { max: 30, message: 'No puede ser mayor a 30 caracteres', },
                                                ]}>
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    {idApoderado === 0
                                        ?
                                        (
                                            <>
                                                <h3 className="sub-titulo-separador margen-bottom margen-top">Documentos</h3>
                                                <Row>
                                                    <Col xs={24} sm={20}>
                                                        {tipoArhivosApoderado.map((tipoArchivo, index) =>
                                                            <Form.Item key={index}
                                                                name={'form_item_' + tipoArchivo.nombreIdentificador}
                                                                label={tipoArchivo.nombreArchivo}
                                                                valuePropName="fileList"
                                                                getValueFromEvent={normFile}
                                                                extra={'Tipo de archivo:' + Utilidades.obtenerTipoDeArchivoPermitido(tipoArchivo.extensiones) + ' - Tamaño Max:' + Utilidades.obtenerTamanoEnString(tipoArchivo.tamano)}
                                                                rules={[
                                                                    { required: tipoArchivo.obligatorio, message: `Debes adjuntar un archivo`, },
                                                                    () => ({
                                                                        validator(rule, value) {
                                                                            if (Array.isArray(value) && value.length === 1) {
                                                                                let file = value[0];
                                                                                if (typeof file.mensajeError !== 'undefined') {
                                                                                    return Promise.reject(file.mensajeError);
                                                                                }
                                                                            }
                                                                            return Promise.resolve();
                                                                        },
                                                                    }),
                                                                ]}
                                                            >
                                                                <SubirArchivoForm props={this.props.form} tipoArchivo={tipoArchivo} />
                                                            </Form.Item>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </>
                                        ) : (<></>)}


                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" loading={cargando}>
                                            {idApoderado !== 0 ? 'Guardar datos' : 'Crear apoderado'}
                                        </Button>
                                        &nbsp;&nbsp;
                                        <Popconfirm title={'Confirma cancelar la ' + (idApoderado !== 0 ? 'edición' : 'creación') + ' del apoderado?'}
                                            okText="Si"
                                            cancelText="No"
                                            onConfirm={this.cancelarFormulario}>
                                            <Button icon={<CloseOutlined />}>Cancelar</Button>
                                        </Popconfirm>

                                    </Form.Item>



                                </Form>
                            </Spin>
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:AEA]</label>) : (<></>))
                    )
                }

            </div>
        );
    }
}

export default withRouter(withCookies(AgregarEditarApoderado));