import React, { Component } from 'react';
import SumaContexto  from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Button, Row, Col, Modal, Spin, Tooltip, Tag, Popconfirm } from 'antd';
import { FileOutlined, EditOutlined, StopOutlined, CheckOutlined, ExclamationCircleOutlined, CloseOutlined  } from '@ant-design/icons';
import Constantes from '../../utilidades/Constantes'
import ServicioDatosConstantes from '../../servicios/ServicioDatosConstantes'
import ServicioPersonaJuridica from '../../servicios/usuarios/ServicioPersonaJuridica'
import ActualizarArchivo from '../../carga-archivos/ActualizarArchivo'
import queryString from 'query-string';
import Utilidades from '../../utilidades/Utilidades'


class VerApoderadoJuridica extends Component {

    static contextType = SumaContexto;
    
    ENUM_COMPONENTE ={
        CARGANDO:1,
        VER:2,
        NO_DISPONIBLE:3
    }

    state = {
        cargando: false,
        idApoderado: 0,
        apoderado: null,
        tipoArhivosApoderado: null,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        usuario:null,
    };

    constructor(props){
        super(props);
        //console.log('VerApoderadoJuridica.props',props);

        // Indica que es el administrador del SUMA quien está accediendo al apoderado
        this.administracion = props.administracion;

        this.servicioDatosConstantes = new ServicioDatosConstantes( props );
        this.servicioPersonaJuridica = new ServicioPersonaJuridica ( props );
        this.callbackEditarApoderado = props.callbackEditarApoderado;
        this.callbackActivarApoderado = props.callbackActivarApoderado;
        this.callbackEliminarApoderado = props.callbackEliminarApoderado;
    }
   
    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales(){
        let params = this.props.location.search;
        let urlparametros = queryString.parse( params );
        let idApoderado = 0;

        if( !this.administracion && urlparametros.id !== undefined && !isNaN(parseInt( urlparametros.id ))){
            // Se toma el idApoderado como un parámetro de consulta en la url '?id=idApoderado'
            idApoderado = parseInt(urlparametros.id);
        }else if( this.administracion ){
            // Se toma el idApoderado si este viene en la url por medio del HookReactRouterV6Parametros,
            // esto funciona cuando es en modo administración y la url es: /persona-juridica/:idPersonaJuridica/apoderado/:idApoderado
            idApoderado = this.props.idApoderado;
        }
        
        if( idApoderado !== 0){
            // Validando que el usuario es PERSONA JURIDICA
            let usr = await this.servicioPersonaJuridica.usuarioPersonaJuridicaOAdministradorValido();
            this.setSumaState({ componente:(usr !== null ? this.ENUM_COMPONENTE.VER : this.ENUM_COMPONENTE.NO_DISPONIBLE)});
            if(this.state.componente === this.ENUM_COMPONENTE.VER){
                this.setSumaState({ cargando: true, idApoderado: idApoderado });
                let tipoArhivosApoderado = await this.obtenerTipoArchivosApoderado();
                let apoderado = await this.obtenerApoderado();
                
                if( apoderado !== null && tipoArhivosApoderado != null  ){
                    // Se quitan los tipos de archivos que ya tiene el apoderado
                    for( let i = 0; i < apoderado.apoderadoJuridicaArchivos.length; i++ ){
                        for( let m = 0; m < tipoArhivosApoderado.length; m++ ){
                            if( apoderado.apoderadoJuridicaArchivos[i].archivo.tipoArchivo.id === tipoArhivosApoderado[m].id ){
                                tipoArhivosApoderado.splice( m, 1 );
                                break;
                            }
                        }
                    }
                    this.setSumaState({ usuario:usr, 
                                    cargando: false, 
                                    apoderado:apoderado, 
                                    tipoArhivosApoderado: tipoArhivosApoderado, });
                }
                
            }
        }
    }

    obtenerApoderado() {
        return new Promise(resolve => {
            this.servicioPersonaJuridica.obtenerApoderado(
                ( respuestaApi ) => { 
                    switch(respuestaApi.codigo){
                        case Constantes.ENUMS_API.PERSONA_JURIDICA.DATOS_RETORNADOS.id:
                            resolve( respuestaApi.resultado );
                            break;
                        default:
                            Modal.error({ title: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VAPJ01]" });
                            resolve( null );
                            break;    
                    }
                },
                ( errorAPI )        => { 
                    Modal.error({ title: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VAPJ02]" });
                    resolve(null);
                },
                this.state.idApoderado,
                // Si está en modo administración del SUMA, este dato se captura de la url por medio de HookReactRouterV6Parametros
                this.props.idPersonaJuridica 
            );
        });
    }

    obtenerTipoArchivosApoderado () {
        return new Promise(resolve => {
            this.servicioDatosConstantes.obtenerTipoArchivosApoderadoJuridica(
                ( tipoArhivosApoderado ) => { 
                    resolve( tipoArhivosApoderado );
                },
                ( errorAPI )        => { 
                    Modal.error({ title: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VAPJ03]" });
                    resolve(null);
                },
            );
        });
    }

    async archivoApoderadoActualizado( idNuevoArchivo, apoderadoJuridicaArchivo, tipoArchivo ){
        this.setSumaState({ cargando: true });
        //console.log('apoderadoJuridicaArchivo',apoderadoJuridicaArchivo);
        let resultado = null;
        if( apoderadoJuridicaArchivo !== null ){
            apoderadoJuridicaArchivo.archivo = { id:idNuevoArchivo };
            resultado = await this.actualizarApoderadoJuridicaArchivo( apoderadoJuridicaArchivo );
        }else{
            resultado = await this.agregarApoderadoJuridicaArchivo( this.state.idApoderado, idNuevoArchivo );
        }

        if( resultado !== null ){
            this.setSumaState({ apoderado: null, tipoArhivosApoderado:null });
            this.cargarDatosIniciales();
            Modal.success({ 
                content: "El documento " + 
                tipoArchivo.nombreArchivo + 
                " fue " + ( apoderadoJuridicaArchivo === null ? 'agregado' : 'actualizado') + " satisfactoriamente." 
            });
        }
    }


    actualizarApoderadoJuridicaArchivo( apoderadoJuridicaArchivo ) {
        return new Promise(resolve => {
            this.servicioPersonaJuridica.actualizarApoderadoJuridicaArchivo(
                ( respuestaApi ) => { 
                    //console.log('respuestaApi:::::',respuestaApi);
                    switch(respuestaApi.codigo){
                        case Constantes.ENUMS_API.PERSONA_JURIDICA.OPERACION_REALIZADA.id:
                            resolve( respuestaApi.resultado );
                            break;
                        default:
                            Modal.error({ message: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VAPJ04]" });
                            resolve( null );
                            break;    
                    }
                },
                ( errorAPI )        => { 
                    Modal.error({ message: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VAPJ05]" });
                    resolve(null);
                },
                apoderadoJuridicaArchivo,
                // Si está en modo administración del SUMA, este dato se captura de la url por medio de HookReactRouterV6Parametros
                this.props.idPersonaJuridica
            );
        });
    }


    agregarApoderadoJuridicaArchivo( idApoderado, idArchivo ) {
        let apoderadoJuridicaArchivo = {
            archivo:{ id:idArchivo },
            idApoderadoJuridica:idApoderado,
        };
        return new Promise(resolve => {
            this.servicioPersonaJuridica.agregarApoderadoJuridicaArchivo(
                ( respuestaApi ) => { 
                    //console.log('respuestaApi:::::',respuestaApi);
                    switch(respuestaApi.codigo){
                        case Constantes.ENUMS_API.PERSONA_JURIDICA.OPERACION_REALIZADA.id:
                            resolve( respuestaApi.resultado );
                            break;
                        default:
                            Modal.error({ message: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VAPJ06]" });
                            resolve( null );
                            break;    
                    }
                },
                ( errorAPI )        => { 
                    Modal.error({ message: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VAPJ07]" });
                    resolve(null);
                },
                apoderadoJuridicaArchivo,
                // Si está en modo administración del SUMA, este dato se captura de la url por medio de HookReactRouterV6Parametros
                this.props.idPersonaJuridica
            );
        });
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    render() {
      
      const { apoderado, componente, cargando, idApoderado, tipoArhivosApoderado, usuario } = this.state;
      
      const wrapperCol = {
        xs: {span:21, offset: 1},  
        sm: {span:11, offset: 1},
      };

      const wrapperColDocs01 = {
        xs: {span:21, offset: 1},  
        sm: {span:7, offset: 1},
      };
      const wrapperColDocs02 = {
        xs: {span:21, offset: 1},  
        sm: {span:15, offset: 1},
      };

        return (
          <div>    

            {(componente === this.ENUM_COMPONENTE.VER) ?
                (
                    <> 
                        <Spin tip="Por favor espera..." size="large" spinning={cargando}>
                             
                            <Row>
                                <Col>
                                    <h3 className="titulo inline-block">Apoderado:</h3> &nbsp;&nbsp;&nbsp;
                                    <h3 className="inline-block">{apoderado !== null ? apoderado.datoPersonal.nombreCompleto : ""}</h3>
                                </Col>  
                            </Row>
                            <h3 className="sub-titulo-separador margen-bottom">Información básica</h3>
                            <div className="seccion-datos">
                                <Row className="fila">
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">Tipo de documento:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <span>{apoderado !== null ? apoderado.datoPersonal.tipoDocumento.tipoDocumento : ""}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">Número de documento:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <span>{apoderado !== null ? apoderado.datoPersonal.numeroDocumento : ""}</span>
                                            </Col>
                                        </Row>
                                    </Col>    
                                </Row>
                                <Row className="fila">
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">Lugar de expedición:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <span>{apoderado !== null ? apoderado.datoPersonal.lugarExpedicion : ""}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">Fecha de expedición:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <span>{apoderado !== null ? apoderado.datoPersonal.fechaExpedicionFormatoEspanol : ""}</span>
                                            </Col>
                                        </Row>
                                    </Col>    
                                </Row>
                                <Row className="fila">
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">Correo electrónico:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <span>{apoderado !== null ? apoderado.datoPersonal.correoElectronico : ""}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">Dirección residencia:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <span>{apoderado !== null ? apoderado.datoPersonal.direccion : ""}</span>
                                            </Col>
                                        </Row>
                                    </Col>    
                                </Row>
                                <Row className="fila">
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">Móvil:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <span>{apoderado !== null ? apoderado.datoPersonal.celular : ""}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">Teléfono:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <span>{apoderado !== null ? apoderado.datoPersonal.telefono : ""}</span>
                                            </Col>
                                        </Row>
                                    </Col>    
                                </Row>
                                <Row className="fila">
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">Estado:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <Tag color={
                                                        (apoderado !== null && apoderado.estadoApoderado.id === Constantes.ENUMS.ESTADO_APODERADO.ACTIVO.id)  
                                                        ? 
                                                            'green' 
                                                        : 
                                                            (apoderado !== null && apoderado.estadoApoderado.id === Constantes.ENUMS.ESTADO_APODERADO.INACTIVO.id 
                                                            ?
                                                                'volcano'
                                                            :
                                                                ''
                                                            )
                                                    } key={apoderado !== null ? apoderado.estadoApoderado.estadoApoderado : ""}>
                                                    {apoderado !== null ? apoderado.estadoApoderado.estadoApoderado.toUpperCase() : ""}
                                                </Tag>

                                            </Col>
                                        </Row>
                                    </Col>  
                                </Row>

                            </div>



                            <h3 className="sub-titulo-separador margen-bottom margen-top">Documentos</h3>
                            <div className="seccion-datos">

                                {
                                    (apoderado !== null)
                                    ?
                                    apoderado.apoderadoJuridicaArchivos.map((apoderadoJuridicaArchivo, index) => 
                                        <Row className="fila documento" key={index} >
                                            <Col  {...wrapperColDocs01}>
                                                <a className="enlace-documento tamano-25" href={apoderadoJuridicaArchivo.archivo.url} target="_blank" rel="noopener noreferrer">
                                                    <div>
                                                        <FileOutlined/> 
                                                        <label>{apoderadoJuridicaArchivo.archivo.tipoArchivo.nombreArchivo}</label>
                                                    </div>
                                                    { apoderadoJuridicaArchivo.archivo.actualizarArchivo
                                                    ? 
                                                        <Tooltip title="El documento debe ser actualizado!">
                                                            <Tag icon={<ExclamationCircleOutlined />} color="magenta" key="Cambio Documentos">ACTUALIZAR DOCUMENTO</Tag>
                                                        </Tooltip>
                                                    : 
                                                        <></>
                                                    }
                                                </a>
                                            </Col>
                                            <Col  {...wrapperColDocs02}>
                                                <ActualizarArchivo key={index}
                                                    tipoArchivo={apoderadoJuridicaArchivo.archivo.tipoArchivo} 
                                                    agregar={false}
                                                    callbackArchivoActualizado={(idNuevoArchivo) => { 
                                                                                            this.archivoApoderadoActualizado(
                                                                                                                                idNuevoArchivo,
                                                                                                                                apoderadoJuridicaArchivo,
                                                                                                                                apoderadoJuridicaArchivo.archivo.tipoArchivo
                                                                                                                            ) 
                                                                                                    }}
                                                    deshabilitar={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_APODERADOS.ACCIONES.ACTUALIZAR_DOCUMENTOS_APODERADO.id)}
                                                />
                                            </Col>    
                                        </Row>
                                    )
                                    :
                                    (<></>)
                                }

                                {
                                    (tipoArhivosApoderado !== null)
                                    ?
                                    tipoArhivosApoderado.map((tipoArchivoApoderado, index) => 
                                        <Row className="fila documento" key={index} >
                                            <Col  {...wrapperColDocs01}>
                                                <Tooltip title="No tiene asociado el documento">
                                                    <div className="enlace-documento tamano-25" >
                                                        <div>
                                                            <FileOutlined/> 
                                                            <label>{tipoArchivoApoderado.nombreArchivo}</label>
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            </Col>
                                            <Col  {...wrapperColDocs02}>
                                                <ActualizarArchivo key={index}
                                                    tipoArchivo={tipoArchivoApoderado} 
                                                    agregar={true}
                                                    callbackArchivoActualizado={(idNuevoArchivo) => { 
                                                                                            this.archivoApoderadoActualizado(
                                                                                                                                idNuevoArchivo,
                                                                                                                                null,
                                                                                                                                tipoArchivoApoderado
                                                                                                                            ) 
                                                                                                    }}
                                                    deshabilitar={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_APODERADOS.ACCIONES.AGREGAR_DOCUMENTOS_APODERADO.id)}
                                                />
                                            </Col>    
                                        </Row>
                                    )
                                    :
                                    (<></>)
                                }

                            </div>


                            <h3 className="sub-titulo-separador margen-bottom margen-top">Opciones</h3>
                            <div className="seccion-datos seccion-final">

                                {(apoderado !== null) 
                                ?
                                (<>
                                    <Button className="boton-opcion" 
                                            icon={<EditOutlined style={{ color: '#c90a00' }}/>}
                                            onClick={(e) => {this.callbackEditarApoderado(e,idApoderado)}}
                                            disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_APODERADOS.ACCIONES.EDITAR_INFORMACION_APODERADO.id)}>
                                                Editar
                                    </Button>

                                    {(apoderado.estadoApoderado.id === Constantes.ENUMS.ESTADO_APODERADO.ACTIVO.id)
                                        ?
                                            <Popconfirm title="Confirma desactivar el apoderado?" 
                                                    okText="Si"
                                                    cancelText="No" 
                                                    onConfirm={(e) => {
                                                        this.setSumaState({cargando:true});
                                                        this.callbackActivarApoderado( 
                                                            e, 
                                                            apoderado.id, 
                                                            apoderado.datoPersonal.nombreCompleto,
                                                            false,
                                                            ()=>{
                                                                this.setSumaState({cargando:false});
                                                                this.cargarDatosIniciales();
                                                            });
                                                        
                                                    }}
                                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_APODERADOS.ACCIONES.DESACTIVAR_APODERADO.id)}
                                            >
                                                <Button className="boton-opcion" 
                                                        icon={<StopOutlined style={{ color: '#c90a00' }}/>}
                                                        disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_APODERADOS.ACCIONES.DESACTIVAR_APODERADO.id)}>
                                                    Desactivar
                                                </Button>
                                            </Popconfirm>
                                        :
                                            <></>
                                    }
                                    
                                    {(apoderado.estadoApoderado.id === Constantes.ENUMS.ESTADO_APODERADO.INACTIVO.id)
                                        ?
                                            <Popconfirm title="Confirma activar el apoderado?" 
                                                        okText="Si"
                                                        cancelText="No" 
                                                        onConfirm={(e) => {
                                                            this.setSumaState({cargando:true});
                                                            this.callbackActivarApoderado( 
                                                                e,
                                                                apoderado.id,
                                                                apoderado.datoPersonal.nombreCompleto,
                                                                true,
                                                                ()=>{
                                                                    this.setSumaState({cargando:false});
                                                                    this.cargarDatosIniciales();
                                                                });
                                                            
                                                        }}
                                                        disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_APODERADOS.ACCIONES.ACTIVAR_APODERADO.id)}
                                            >
                                                <Button className="boton-opcion" 
                                                        icon={<CheckOutlined style={{ color: '#c90a00' }}/>}
                                                        disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_APODERADOS.ACCIONES.ACTIVAR_APODERADO.id)}>
                                                        Activar
                                                </Button>
                                            </Popconfirm>   
                                        :
                                            <></>
                                    }

                                    {(apoderado.estadoApoderado.id)
                                        ?
                                            <Popconfirm title="Confirma eliminar el apoderado?" 
                                                        okText="Si"
                                                        cancelText="No" 
                                                        onConfirm={(e) => {
                                                            this.setSumaState({cargando:true});
                                                            this.callbackEliminarApoderado( 
                                                                apoderado.id,
                                                                apoderado.datoPersonal.nombreCompleto);
                                                        }}
                                                        disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_APODERADOS.ACCIONES.ELIMINAR_APODERADO.id)}
                                            >
                                                <Button className="boton-opcion" 
                                                        icon={<CloseOutlined style={{ color: '#c90a00' }}/>}
                                                        disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_APODERADOS.ACCIONES.ELIMINAR_APODERADO.id)}>
                                                        Eliminar
                                                </Button>
                                            </Popconfirm>   
                                        :
                                            <></>
                                    }

                                </>)
                                : 
                                (<></>)
                                }
                            </div>

                        </Spin>
                    </>
                )
                :
                (
                    (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                    (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                    :((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:VAPJ]</label>) : (<></>))
                )
            }    
          </div>
        );
    }
}
          
export default withRouter(withCookies(VerApoderadoJuridica));