import React from 'react';
import ZonaUsuarioLogin from '../zona-usuario/ZonaUsuarioLogin'
import ZonaUsuarioLogeado from '../zona-usuario/ZonaUsuarioLogeado'
import Validadores from '../utilidades/Validadores';

function ZonaUsuarioSelector( attr ) {
  
  if( Validadores.objetoVacio(attr.usuario) )
    return <ZonaUsuarioLogin callbackUsuarioLogueado={attr.callbackUsuarioLogueado} cerrarModal={attr.cerrarModal}/>
  else
    return <ZonaUsuarioLogeado usuario={attr.usuario} />

}

export default ZonaUsuarioSelector;
