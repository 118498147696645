import axios from 'axios';
import Constantes from '../utilidades/Constantes'
import { Modal } from 'antd';


class ServicioDatosConstantes {

    constructor( props ){ 
        const { cookies } = props;
        this.cookies = cookies;
    }

    obtenerTiposDocumentos ( callbackComplete, callbalkError ) {
        const config= { headers:{ }};
        axios.get( Constantes.URL_API_SUMA + '/datos-constantes/tipo-documento',config)
        .then(res => {
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            console.error('[Error.ServicioDatosConstantes.obtenerTiposDocumentos]',err);
            callbalkError( {err} );
        });
    };


    obtenerSectores ( callbackComplete, callbalkError ) {
        const config= { headers:{ }};
        axios.get( Constantes.URL_API_SUMA + '/datos-constantes/sector',config)
        .then(res => {
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            console.error('[Error.ServicioDatosConstantes.obtenerSectores]',err);
            callbalkError( {err} );
        });
    };

    obtenerTipoArchivo ( idTipoArchivo ) {
        const config= { headers:{ }};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/datos-constantes/tipo-archivo/'+idTipoArchivo,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener Tipo de Archivo id:"+idTipoArchivo+". " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioDatosConstantes.obtenerTipoArchivo]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SDC15]" }); 
                resolve();
            });
        });
    };

    obtenerTipoArchivoRepresentanteLegal ( callbackComplete, callbalkError ) {
        const config= { headers:{ }};
        axios.get( Constantes.URL_API_SUMA + '/datos-constantes/tipo-archivo-representante-legal',config)
        .then(res => {
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            console.error('[Error.ServicioDatosConstantes.obtenerTipoArchivoRepresentanteLegal]',err);
            callbalkError( {err} );
        });
    };

    obtenerTipoArchivoPersonaJuridica ( callbackComplete, callbalkError ) {
        const config= { headers:{ }};
        axios.get( Constantes.URL_API_SUMA + '/datos-constantes/tipo-archivo-persona-juridica',config)
        .then(res => {
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            console.error('[Error.ServicioDatosConstantes.obtenerTipoArchivoPersonaJuridica]',err);
            callbalkError( {err} );
        });
    };

    obtenerTipoArchivoPersonaNatural ( callbackComplete, callbalkError ) {
        const config= { headers:{ }};
        axios.get( Constantes.URL_API_SUMA + '/datos-constantes/tipo-archivo-persona-natural',config)
        .then(res => {
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            console.error('[Error.ServicioDatosConstantes.obtenerTipoArchivoPersonaNatural]',err);
            callbalkError( {err} );
        });
    };

    obtenerTipoArchivosApoderado ( callbackComplete, callbalkError ) {
        const config= { headers:{ }};
        axios.get( Constantes.URL_API_SUMA + '/datos-constantes/tipo-archivo-apoderado',config)
        .then(res => {
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            console.error('[Error.ServicioDatosConstantes.obtenerTipoArchivosApoderado]',err);
            callbalkError( {err} );
        });
    };

    obtenerTipoArchivosApoderadoJuridica ( callbackComplete, callbalkError ) {
        const config= { headers:{ }};
        axios.get( Constantes.URL_API_SUMA + '/datos-constantes/tipo-archivo-apoderado-juridica',config)
        .then(res => {
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            console.error('[Error.ServicioDatosConstantes.obtenerTipoArchivosApoderadoJuridica]',err);
            callbalkError( {err} );
        });
    };

    obtenerTiposUsuario () {
        const config= { headers:{ }};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/datos-constantes/tipo-usuario',config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener Tipos Usuario. " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioDatosConstantes.obtenerTiposUsuario]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SDC06]" }); 
                resolve();
            });
        });
    };

    obtenerRolesPersonaJuridica ( callbackComplete, callbalkError ) {
        const config= { headers:{ }};
        axios.get( Constantes.URL_API_SUMA + '/datos-constantes/rol-persona-juridica',config)
        .then(res => {
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            console.error('[Error.ServicioDatosConstantes.obtenerRolesPersonaJuridica]',err);
            callbalkError( {err} );
        });
    };

    obtenerRolesPersonaNatural () {
        const config= { headers:{ }};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/datos-constantes/rol-persona-natural',config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener Roles Persona Natural." + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioDatosConstantes.obtenerRolesPersonaNatural]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SDC08]" }); 
                resolve();
            });
        });
    };

    


    obtenerEstadosSolicitud () {
        const config= { headers:{ }};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/datos-constantes/estado-solicitud',config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener Estados Solicitudes. " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioDatosConstantes.obtenerEstadosSolicitud]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SDC01]" }); 
                resolve();
            });
        });
    };


    obtenerEstadosRepresentanteLegal () {
        const config= { headers:{ }};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/datos-constantes/estado-representante-legal',config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener Estados Representante legal. " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioDatosConstantes.obtenerEstadosRepresentanteLegal]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SDC02]" }); 
                resolve();
            });
        });
    };


    obtenerEstadosUsuario () {
        const config= { headers:{ }};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/datos-constantes/estado-usuario',config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener Estados Usuario. " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioDatosConstantes.obtenerEstadosUsuario]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SDC03]" }); 
                resolve();
            });
        });
    };


    obtenerEstadosApoderado () {
        const config= { headers:{ }};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/datos-constantes/estado-apoderado',config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener Estados Apoderado. " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioDatosConstantes.obtenerEstadosApoderado]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SDC04]" }); 
                resolve();
            });
        });
    };

    obtenerEstadosEntidad () {
        const config= { headers:{ }};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/datos-constantes/estado-entidad',config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener Estados Entidad. " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioDatosConstantes.obtenerEstadosEntidad]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SDC09]" }); 
                resolve();
            });
        });
    };

    obtenerTipoSectores () {
        const config= { headers:{ }};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/datos-constantes/tipo-sector',config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener Secretarías. " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioDatosConstantes.obtenerTipoSectores]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SDC10]" }); 
                resolve();
            });
        });
    };

    obtenerRolesDependencia ( idDependencia ) {
        const config= { headers:{ }};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/datos-constantes/'+idDependencia+'/rol-dependencia',config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener Roles Dependencia. idDependencia:"+idDependencia + " " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioDatosConstantes.obtenerRolesDependencia]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SDC05]" }); 
                resolve();
            });
        });
    };


    obtenerRolesAdminsitradorSuma () {
        const config= { headers:{ }};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/datos-constantes/rol-administrador-suma',config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener Roles Administrador Suma. " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioDatosConstantes.obtenerRolesAdminsitradorSuma]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SDC07]" }); 
                resolve();
            });
        });
    };

    obtenerEstadosDependencia () {
        const config= { headers:{ }};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/datos-constantes/estado-dependencia',config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener Estados Dependencia. " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioDatosConstantes.obtenerEstadosDependencia]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SDC11]" }); 
                resolve();
            });
        });
    };

    obtenerTiposOpcion () {
        const config= { headers:{ }};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/datos-constantes/tipo-opcion',config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener Tipos Opcion. " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioDatosConstantes.obtenerTiposOpcion]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SDC12]" }); 
                resolve();
            });
        });
    };

    obtenerNormatividades ( callbackProgreso ) {
        const config= { headers:{ },
        onDownloadProgress(progressEvent) {
            if( callbackProgreso ){
                let total = progressEvent.total;
                let loaded = progressEvent.loaded;
                if( total === 0 ){ total = loaded };
                let percentCompleted = parseInt((loaded / total) * 100);
                callbackProgreso( percentCompleted );
            }
        }};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/datos-constantes/normatividad',config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener normatividades. " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioDatosConstantes.obtenerNormatividades]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SDC13]" }); 
                resolve();
            });
        });
    };

    obtenerPreguntasFrecuentes ( callbackProgreso ) {
        const config= { headers:{ },
        onDownloadProgress(progressEvent) {
            if( callbackProgreso ){
                let total = progressEvent.total;
                let loaded = progressEvent.loaded;
                if( total === 0 ){ total = loaded };
                let percentCompleted = parseInt((loaded / total) * 100);
                callbackProgreso( percentCompleted );
            }
        }};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/datos-constantes/pregunta-frecuente',config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener preguntas frecuentes. " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioDatosConstantes.obtenerPreguntasFrecuentes]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SDC14]" }); 
                resolve();
            });
        });
    };

    obtenerContenidoVisualPorTipo ( idTipoContenidoVisual, callbackProgreso ) {
        const config= { headers:{ },
        onDownloadProgress(progressEvent) {
            if( callbackProgreso ){
                let total = progressEvent.total;
                let loaded = progressEvent.loaded;
                if( total === 0 ){ total = loaded };
                let percentCompleted = parseInt((loaded / total) * 100);
                callbackProgreso( percentCompleted );
            }
        }};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/datos-constantes/contenido-visual/tipo-contenido-visual/'+idTipoContenidoVisual,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener contenidos visuales por tipo idTipoContenidoVisual:" + idTipoContenidoVisual +" . " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioDatosConstantes.obtenerContenidoVisualPorTipo]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SDC16]" }); 
                resolve();
            });
        });
    };

    obtenerParametroGeneralConjuntoEnlace ( callbackProgreso ) {
        const config= { headers:{ },
        onDownloadProgress(progressEvent) {
            if( callbackProgreso ){
                let total = progressEvent.total;
                let loaded = progressEvent.loaded;
                if( total === 0 ){ total = loaded };
                let percentCompleted = parseInt((loaded / total) * 100);
                callbackProgreso( percentCompleted );
            }
        }};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/datos-constantes/parametro-general-enlace',config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener parametro general conjunto ENLACE. " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioDatosConstantes.obtenerParametroGeneralConjuntoEnlace]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SDC17]" }); 
                resolve();
            });
        });
    };

    obtenerParametroGeneralConjuntoNotificacion ( callbackProgreso ) {
        const config= { headers:{ },
        onDownloadProgress(progressEvent) {
            if( callbackProgreso ){
                let total = progressEvent.total;
                let loaded = progressEvent.loaded;
                if( total === 0 ){ total = loaded };
                let percentCompleted = parseInt((loaded / total) * 100);
                callbackProgreso( percentCompleted );
            }
        }};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/datos-constantes/parametro-general-notificacion',config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener parametro general conjunto NOTIFICACION. " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioDatosConstantes.obtenerParametroGeneralConjuntoNotificacion]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SDC20]" }); 
                resolve();
            });
        });
    };

    obtenerLimites ( callbackProgreso ) {
        const config= { headers:{ },
        onDownloadProgress(progressEvent) {
            if( callbackProgreso ){
                let total = progressEvent.total;
                let loaded = progressEvent.loaded;
                if( total === 0 ){ total = loaded };
                let percentCompleted = parseInt((loaded / total) * 100);
                callbackProgreso( percentCompleted );
            }
        }};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/datos-constantes/limites',config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener limites. " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioDatosConstantes.obtenerLimites]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SDC18]" }); 
                resolve();
            });
        });
    };

    obtenerOpcionesDireccion () {
        const config= { headers:{ }};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/datos-constantes/opcion-direccion',config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener opcion dirección. " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioDatosConstantes.obtenerOpcionesDireccion]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SDC19]" }); 
                resolve();
            });
        });
    };
}

export default ServicioDatosConstantes;