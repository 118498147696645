import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Spin, Select, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import ServicioUsuarios from '../../servicios/usuarios/ServicioUsuarios'
import ServicioAdministracion from '../../servicios/ServicioAdministracion'
import { withCookies } from 'react-cookie';
import Constantes from '../../utilidades/Constantes'
import Utilidades from '../../utilidades/Utilidades';
import ReporteSolicitudes from './ReporteSolicitudes';
import ReporteDirecciones from './ReporteDirecciones';
import { ReporteSolicitudesDetallado, ReporteDireccionesDetallado } from './ReportesSolicitudesDetallados';

const { Option } = Select;

class ReportesSolicitudes extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3,
        NO_HABILITADO: 4,
    }

    state = {
        hijoCargado: false,
        componente: this.ENUM_COMPONENTE.CARGANDO,
    };

    constructor(props) {
        super(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
        // Esta variable contiene la referencia al reporte que se está viendo
        this.reporteHijo = React.createRef()
    }



    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        // Validando que el usuario esté en sesion
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr != null) {
            // Valida que tenga la accion habilitada de acceder 
            let habilitado = await this.servicioUsuarios.accionHabilitadaPromesa(Constantes.ENUMS_MODULOS.REPORTES.ACCIONES.VER_REPORTES_SOLICITUDES.id);
            if (habilitado && habilitado.resultado) {
                let anos = [];
                // Se extraen los años desde el actual hasta el 2015
                let ano = new Date().getFullYear();
                for (let i = 2015; i <= ano; i++) {
                    anos.push(i);
                }
                anos.reverse();
                let detallado = await this.servicioUsuarios.accionHabilitadaPromesa(Constantes.ENUMS_MODULOS.REPORTES.ACCIONES.REPORTE_DETALLADO_SOLICITUDES.id);
                this.setSumaState({ usuario: usr, componente: this.ENUM_COMPONENTE.VER, anos, ano, detallado: detallado.resultado });
            } else {
                this.setSumaState({ usuario: usr, componente: this.ENUM_COMPONENTE.NO_HABILITADO });
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }

    descargarReporte = e => {
        if (this.reporteHijo && this.reporteHijo.descargarReporte) {
            // Si el reporte hijo contiene la función 'descargarReporte' la ejecuta
            this.reporteHijo.descargarReporte();
        }
    }


    render() {

        const { componente, usuario, opcion, hijoCargado, ano, anos, detallado } = this.state;

        const seleccionTipoReporte = opcion => {
            this.setSumaState({ opcion, hijoCargado: false });
            this.reporteHijo = React.createRef();
        };

        const seleccionAno = ano => {
            this.setSumaState({ ano, hijoCargado: false });
            if (this.reporteHijo && this.reporteHijo.cambioAno) {
                // Si el reporte hijo contiene la función 'cambioAno' la ejecuta
                this.reporteHijo.cambioAno(ano);
            }
        };

        return (
            <>
                <div className="seccion-principal contenedor-completo seccion-flex ">
                    <div className="division-contenido seccion-flex-grow-1  bkg-white contenido-usuarios">

                        {(componente === this.ENUM_COMPONENTE.VER) ?
                            (
                                <>
                                    <div style={{ marginBottom: 20 }}>
                                        <label style={{ fontWeight: 'bold', marginRight: 10 }}>Tipo de reporte a generar</label>
                                        <Select defaultValue="solicitudes" style={{ width: 200, marginRight: 10 }}
                                            onChange={seleccionTipoReporte}
                                            disabled={!hijoCargado}
                                        >
                                            <Option value="solicitudes">Solicitudes</Option>
                                            {detallado ? <Option value="solicitudes-detallado">Solicitudes (Detallado)</Option> : null}
                                            <Option value="direcciones">Direcciones</Option>
                                            {detallado ? <Option value="direcciones-detallado">Direcciones (Detallado)</Option> : null}
                                        </Select>

                                        <label style={{ fontWeight: 'bold', marginRight: 10, marginLeft: 5 }}>Año</label>
                                        <Select defaultValue={ano} style={{ width: 100, marginRight: 20 }}
                                            onChange={seleccionAno}
                                            disabled={!hijoCargado}
                                        >
                                            {anos.map(ano => (
                                                <Select.Option key={ano}>{ano}</Select.Option>
                                            ))}

                                        </Select>

                                        <Button type="primary"
                                            icon={<DownloadOutlined />}
                                            onClick={this.descargarReporte}
                                            disabled={
                                                !Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.REPORTES.ACCIONES.DESCARGAR_REPORTE_SOLICITUDES.id)
                                                || !hijoCargado
                                            }
                                        >
                                            Descargar
                                        </Button>
                                    </div>
                                    {
                                        opcion === 'direcciones' ?
                                            <ReporteDirecciones onRef={ref => (this.reporteHijo = ref)}
                                                onCargado={cargado => (this.setSumaState({ hijoCargado: cargado }))}
                                                ano={ano}
                                            />
                                            :
                                            opcion === 'direcciones-detallado' ?
                                                <ReporteDireccionesDetallado onRef={ref => (this.reporteHijo = ref)}
                                                    onCargado={cargado => (this.setSumaState({ hijoCargado: cargado }))}
                                                    ano={ano}
                                                />
                                                :
                                                !opcion || opcion === 'solicitudes' || opcion === 'solicitudes-detallado' ?
                                                    (
                                                        !opcion || opcion === 'solicitudes' ?
                                                            <ReporteSolicitudes onRef={ref => (this.reporteHijo = ref)}
                                                                onCargado={cargado => (this.setSumaState({ hijoCargado: cargado }))}
                                                                ano={ano}
                                                            />
                                                            :
                                                            <ReporteSolicitudesDetallado onRef={ref => (this.reporteHijo = ref)}
                                                                onCargado={cargado => (this.setSumaState({ hijoCargado: cargado }))}
                                                                ano={ano}
                                                            />
                                                    )
                                                    :
                                                    null



                                    }

                                </>
                            )
                            :
                            (componente === this.ENUM_COMPONENTE.NO_HABILITADO) ?
                                <>
                                    Reportes de solicitudes no disponibles. {Constantes.MENSAJE_USUARIO_SIN_PERMISOS}
                                </> :
                                (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                                    (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                                    : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:REPORTESSOLICITUDES]</label>) : (<></>))
                        }
                    </div>
                </div>
            </>
        );

    }

}

export default withRouter(withCookies(ReportesSolicitudes));