import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Spin, Button, Modal } from 'antd';
import {
  IdcardOutlined, ProfileOutlined, FormOutlined, CommentOutlined,
  FileDoneOutlined, TeamOutlined, BankOutlined, SettingOutlined,
  BarChartOutlined, CopyOutlined
} from '@ant-design/icons';
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import { withCookies } from 'react-cookie';
import Constantes from '../utilidades/Constantes'
import Utilidades from '../utilidades/Utilidades'
import ServicioEntidad from '../servicios/ServicioEntidad'

class MisSitios extends Component {

  static contextType = SumaContexto;

  state = {
    cargando: true,
    usuario: null,
    opcionesSUMA: []
  };

  constructor(props) {
    super(props);
    this.servicioUsuarios = new ServicioUsuarios(props);
    this.servicioEntidad = new ServicioEntidad(props);
  }

  componentDidMount() {
    //console.log('ZonaUsuarioLogeado - componentDidMount',this.servicioArchivos);
    this._isMounted = true;
    this.cargarDatosIniciales();
    Utilidades.desbloquearPantalla();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setSumaState(valores) {
    if (this._isMounted) {
      this.setState(valores);
    }
  }

  // -----------------------------------------------------------------------
  // --- CARGA DATOS INICIALES ---------------------------------------------
  // -----------------------------------------------------------------------
  async cargarDatosIniciales() {
    let usr = await this.obtenerUsuarioLogeado();
    if (usr !== null) {
      if (usr.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id) {
        let opcionesSUMADependencia = await this.servicioEntidad.obtenerOpcionSUMADependenciaPromise("[MisSitios]");
        this.setSumaState({ opcionesSUMA: opcionesSUMADependencia.resultado });
      }
      this.setSumaState({ usuario: usr, cargando: (usr === null) });
    }
  }

  obtenerUsuarioLogeado() {
    return new Promise(resolve => {
      this.servicioUsuarios.obtenerUsuarioLogeado(
        (dataAPI) => {
          resolve(dataAPI);
        },
        (errorAPI) => {
          Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:MS01]" });
          resolve(null);
        },
      );
    });
  }

  clickDatosPersonaJuridica = e => { this.historyLocal.push('/persona-juridica-datos'); };
  clickDatosPersonaNatural = e => { this.historyLocal.push('/persona-natural-datos'); };
  clickRegistrarSolicitud = e => { this.historyLocal.push('/registrar-solicitud'); };
  clickMisSolicitudes = e => { this.historyLocal.push('/mis-solicitudes'); };
  clickSolicitudesDependencia = e => { this.historyLocal.push('/solicitudes-dependencia'); };
  clickSolicitudAsociada = e => { this.historyLocal.push('/solicitudes-asociadas'); };
  clickDatosEntidad = e => { this.historyLocal.push('/dependencia-datos'); };
  clickUsuarios = e => { this.historyLocal.push('/usuarios'); };
  clickReportes = e => { this.historyLocal.push('/reportes'); };
  clickEntidades = e => { this.historyLocal.push('/entidades'); };
  clickSolicitudes = e => { this.historyLocal.push('/solicitudes'); };
  clickConfiguracion = e => { this.historyLocal.push('/configuracion'); };
  clickComunicaciones = e => { this.historyLocal.push('/comunicaciones'); };
  clickDocumentos = e => { this.historyLocal.push('/documentos'); };

  clickOpcionSuma = (e, url) => {
    Utilidades.bloquearPantalla();
    window.location = url;
  };

  render() {

    const { usuario, opcionesSUMA } = this.state;
    const { history } = this.props;
    this.historyLocal = history;


    return (

      <div className="contenido-principal seccion-con-zona-usuario seccion-flex">{/* seccion-principal */}

        <div className="division-contenido seccion-flex-grow-1 padding-principal bkg-white mis-sitios">
          <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>
            <div className="titulo-seccion">
              <label>OPCIONES</label>
              <div className="separador-horizontal-completo"></div>
            </div>

            <div className="seleccion-mis-sitios">

              {(usuario !== null && usuario.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.PERSONA_NATURAL.id) ?
                (<div className={'opcion-mi-sitio ' + (Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_NATURAL.ACCIONES.ACCEDER_MODULO_PERSONA_NATURAL.id) ? '' : 'disabled')}>
                  <Button type="primary"
                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_NATURAL.ACCIONES.ACCEDER_MODULO_PERSONA_NATURAL.id)}
                    onClick={this.clickDatosPersonaNatural}
                    icon={<IdcardOutlined />} />
                  <label>DATOS PERSONA NATURAL</label>
                </div>) : (<></>)}

              {(usuario !== null && usuario.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.PERSONA_JURIDICA.id) ?
                (<div className={'opcion-mi-sitio ' + (Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA.ACCIONES.ACCEDER_MODULO_PERSONA_JURIDICA.id) ? '' : 'disabled')}>
                  <Button type="primary"
                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA.ACCIONES.ACCEDER_MODULO_PERSONA_JURIDICA.id)}
                    onClick={this.clickDatosPersonaJuridica}
                    icon={<IdcardOutlined />} />
                  <label>DATOS PERSONA JURÍDICA</label>
                </div>) : (<></>)}

              {(usuario !== null && usuario.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id) ?
                (<div className={'opcion-mi-sitio ' + (Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.ACCEDER_MODULO_DEPENDENCIA.id) ? '' : 'disabled')}>
                  <Button type="primary"
                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.ACCEDER_MODULO_DEPENDENCIA.id)}
                    onClick={this.clickDatosEntidad}
                    icon={<IdcardOutlined />} />
                  <label>DATOS DEPENDENCIA</label>
                </div>) : (<></>)}


              {(usuario !== null) ?

                ((usuario.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.PERSONA_NATURAL.id ||
                  usuario.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.PERSONA_JURIDICA.id) ?
                  (<>

                    <div className={
                      'opcion-mi-sitio ' +
                      (Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.MIS_SOLICITUDES.ACCIONES.ACCEDER_MODULO_MIS_SOLICITUDES.id)
                        ? '' : 'disabled')
                    }>
                      <Button type="primary"
                        disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.MIS_SOLICITUDES.ACCIONES.ACCEDER_MODULO_MIS_SOLICITUDES.id)}
                        onClick={this.clickMisSolicitudes}
                        icon={<ProfileOutlined />} />
                      <label>MIS SOLICITUDES</label>
                    </div>


                    <div className={
                      'opcion-mi-sitio ' +
                      (Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.REGISTRAR_SOLICITUD.ACCIONES.ACCEDER_MODULO_REGISTRAR_SOLICITUD.id)
                        ? '' : 'disabled')
                    }>

                      <Button type="primary"
                        disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.REGISTRAR_SOLICITUD.ACCIONES.ACCEDER_MODULO_REGISTRAR_SOLICITUD.id)}
                        onClick={this.clickRegistrarSolicitud}
                        icon={<FormOutlined />} />
                      <label>REGISTRAR SOLICITUD</label>
                    </div>

                  </>)
                  :
                  (
                    (usuario.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id) ?
                      (<>
                        <div className={
                          'opcion-mi-sitio ' +
                          (Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.SOLICITUDES.ACCIONES.ACCEDER_MODULO_SOLICITUDES.id)
                            ? '' : 'disabled')
                        }>
                          <Button type="primary"
                            disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.SOLICITUDES.ACCIONES.ACCEDER_MODULO_SOLICITUDES.id)}
                            onClick={this.clickSolicitudesDependencia}
                            icon={<ProfileOutlined />} />
                          <label>SOLICITUDES</label>
                        </div>


                        <div className={
                          'opcion-mi-sitio ' +
                          (Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.SOLICITUD_ASOCIADA.ACCIONES.ACCEDER_MODULO_SOLICITUD_ASOCIADA.id)
                            ? '' : 'disabled')
                        }>

                          <Button type="primary"
                            disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.SOLICITUD_ASOCIADA.ACCIONES.ACCEDER_MODULO_SOLICITUD_ASOCIADA.id)}
                            onClick={this.clickSolicitudAsociada}
                            icon={<FileDoneOutlined />} />
                          <label>SOLICITUD ASOCIADA</label>
                        </div>

                      </>)
                      :
                      (<>



                      </>)
                  )
                )

                : (<></>)}


              {usuario != null && usuario.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.ADMINISTRADOR_SUMA.id ?
                (
                  <>
                    {Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.ACCEDER_MODULO_USUARIOS.id) ?
                      (<>
                        <div className={'opcion-mi-sitio'}>
                          <Button type="primary" icon={<TeamOutlined />} onClick={this.clickUsuarios} />
                          <label>USUARIOS</label>
                        </div>
                      </>) : (<></>)}

                    {Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.ENTIDAD.ACCIONES.ACCEDER_MODULO_ENTIDAD.id) ?
                      (<>
                        <div className={'opcion-mi-sitio'}>
                          <Button type="primary" icon={<BankOutlined />} onClick={this.clickEntidades} />
                          <label>ENTIDADES</label>
                        </div>
                      </>) : (<></>)}

                      {Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.SOLICITUDES.ACCIONES.ACCEDER_MODULO_SOLICITUDES.id) ?
                      (<>
                        <div className={'opcion-mi-sitio'}>
                          <Button type="primary" icon={<ProfileOutlined />} onClick={this.clickSolicitudes} />
                          <label>SOLICITUDES</label>
                        </div>
                      </>) : (<></>)}

                    {Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.COMUNICACIONES.ACCIONES.ACCEDER_MODULO_COMUNICACIONES.id) ?
                      (<>
                        <div className={'opcion-mi-sitio'}>
                          <Button type="primary" icon={<CommentOutlined />} onClick={this.clickComunicaciones} />
                          <label>COMUNICACIONES</label>
                        </div>
                      </>) : (<></>)}

                    {Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.CONFIGURACION.ACCIONES.ACCEDER_MODULO_CONFIGURACION.id) ?
                      (<>
                        <div className={'opcion-mi-sitio'}>
                          <Button type="primary" icon={<SettingOutlined />} onClick={this.clickConfiguracion} />
                          <label>CONFIGURACIÓN</label>
                        </div>
                      </>) : (<></>)}

                    {Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.DOCUMENTOS.ACCIONES.ACCEDER_MODULO_DOCUMENTOS.id) ?
                      (<>
                        <div className={'opcion-mi-sitio'}>
                          <Button type="primary" icon={<CopyOutlined />} onClick={this.clickDocumentos} />
                          <label>DOCUMENTOS</label>
                        </div>
                      </>) : (<></>)}  

                  </>
                ) : (<></>)
              }

              {usuario != null ?
                (
                  <>

                    {Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.REPORTES.ACCIONES.ACCEDER_MODULO_REPORTES.id) ?
                      (<>
                        <div className={'opcion-mi-sitio'}>
                          <Button type="primary" icon={<BarChartOutlined />} onClick={this.clickReportes} />
                          <label>REPORTES</label>
                        </div>
                      </>) : (<></>)}

                  </>
                ) : (<></>)
              }

              {(usuario !== null) ?
                (<>
                  {opcionesSUMA.map((opcionDependencia, index) =>

                    <div key={index} className={'opcion-mi-sitio'}>
                      <Button type="primary"
                        onClick={(e) => { this.clickOpcionSuma(e, opcionDependencia.url) }}
                        icon={<Utilidades.IconoAntDesign type={opcionDependencia.icono} />} />
                      <label>{opcionDependencia.opcionDependencia}</label>
                    </div>

                  )}

                </>)
                :
                (<></>)}

            </div>
          </Spin>
        </div>
      </div>

    );
  }
}

export default withRouter(withCookies(MisSitios));