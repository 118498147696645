import React, { Component } from 'react';
import { Button, Form, Input, Checkbox, Modal } from 'antd';
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import { withCookies } from 'react-cookie';
import SumaContexto from '../contexto/SumaContexto';
import { UserOutlined, EditOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import Constantes from '../utilidades/Constantes'

class ZonaUsuarioLogin extends Component {

    static contextType = SumaContexto;
    //Variable para indicar si el componente esta montado en la vista
    _isMounted = false;

    state = {
        loading: false,
    };

    constructor(props) {
        super(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.callbackUsuarioLogueado = props.callbackUsuarioLogueado;
    }

    componentDidUpdate() {
        //console.log('componentDidUpdate ZonaUsuario',this.context);
    }

    componentDidMount() {
        this._isMounted = true;
        //console.log('componentDidMount ZonaUsuario',this.context);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    completeAPIAutenticarUsuario = (respuestaAPI, nombreUsuario, setFrontUsuario) => {
        //console.log("completeAPIAutenticarUsuario.respuestaAPI",respuestaAPI);
        if (respuestaAPI.res.status === 200) {
            // Monta el usuario en el contexto
            //setFrontUsuario( { nombre: nombreUsuario} );
            if (respuestaAPI.res.data.codigo === Constantes.ENUMS_API.USUARIO.OPERACION_NO_REALIZADA.id) {
                Modal.error({ content: respuestaAPI.res.data.mensaje });
            }
        } else {
            Modal.error({ content: 'Usuario no autenticado' });
        }
        if (this._isMounted) {
            this.setState({ loading: false });
        }
    }

    errorAPIAutenticarUsuario = (errorAPI, sumaContexto) => {
        Modal.error({ content: 'Usuario no autenticado' });
        if (this._isMounted) {
            this.setState({ loading: false });
        }
    }

    render() {
        //const { cookies, setCookie } = this.props;

        const { loading } = this.state;
        const { setFrontUsuario } = this.context;
        const { history } = this.props;
        this.historyLocal = history;

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        const tailLayout = {
            wrapperCol: { offset: 8, span: 16 },
        };

        const onFinish = values => {
            this.setState({ loading: true });
            this.servicioUsuarios.autenticarUsuario(
                values.username,
                values.password,
                (respuestaAPI) => {
                    this.completeAPIAutenticarUsuario(respuestaAPI, values.username, setFrontUsuario);
                    if (this.callbackUsuarioLogueado) {
                        this.callbackUsuarioLogueado();
                    }

                },
                (errorAPI) => { this.errorAPIAutenticarUsuario(errorAPI, this.context) },
            );
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const clickCrearCuenta = event => {
            this.historyLocal.push(Constantes.URL_PUBLICAS.CREAR_CUENTA);
            if (this.props.cerrarModal) {
                this.props.cerrarModal();
            }
        }

        const clickRecuperarContrasena = event => {
            this.historyLocal.push(Constantes.URL_PUBLICAS.RECUPERAR_CONTRASENA);
            if (this.props.cerrarModal) {
                this.props.cerrarModal();
            }
        }

        const clickActivarCuentaSuma = event => {
            this.historyLocal.push(Constantes.URL_PUBLICAS.ENVIAR_CORREO_ACTIVACION_CUENTA);
            if (this.props.cerrarModal) {
                this.props.cerrarModal();
            }
        }

        return (
            <div className="zona-usuario zona-usuario-diseno">

                <label className="titulo">ZONA USUARIOS</label>
                <div className="separador-horizontal"></div>
                <label className="sub-titulo">INGRESO</label>
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item className='wrap-label-formulario'
                        label="Usuario / Email" labelAlign="left"
                        name="username"
                        rules={[{ required: true, message: 'Por favor ingresa tu usuario!' }]}
                    >
                        <Input prefix={<UserOutlined />} placeholder="Usuario / Email" />
                    </Form.Item>

                    <Form.Item
                        label="Contraseña" labelAlign="left"
                        name="password"
                        rules={[{ required: true, message: 'Por favor ingresa tu contraseña!' }]}
                    >
                        <Input.Password prefix={<EditOutlined />} placeholder="Contraseña" />
                    </Form.Item>

                    <Form.Item className='margen-bottom-10-imp' {...tailLayout} name="remember" valuePropName="checked">
                        <Checkbox>Recordarme</Checkbox>
                    </Form.Item>

                    <Form.Item className='margen-bottom-10-imp' {...tailLayout}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Acceder
                        </Button>
                    </Form.Item>
                </Form>
                <div className="zona-usuario-crear-cuenta">
                    <div className="separador-horizontal"></div>
                    <label>¿No tienes usuario?</label>
                    <Button onClick={clickCrearCuenta} type="text" className="btn-crear-cuenta">Crea una cuenta</Button>
                    <div>
                        <Button onClick={clickRecuperarContrasena} type="text" className="btn-recuperar-contrasena">Recuperar contraseña</Button>
                    </div>
                    <div>
                        <Button onClick={clickActivarCuentaSuma} type="text" className="btn-recuperar-contrasena activar-cuenta">Activar cuenta</Button>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(withCookies(ZonaUsuarioLogin));