import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter, Switch, Route  } from "react-router-dom";
import { Tabs, Spin, Button } from 'antd';
import { BankOutlined, PlusOutlined } from '@ant-design/icons';
import ServicioDatosConstantes from '../servicios/ServicioDatosConstantes'
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import ServicioPais from '../servicios/ServicioPais'
import ServicioAdministracion from '../servicios/ServicioAdministracion'
import Constantes from '../utilidades/Constantes'
import Utilidades from '../utilidades/Utilidades'
import queryString from 'query-string';
import { withCookies } from 'react-cookie';
import DatosEntidad from './DatosEntidad'
import ListarDependencias from './dependencia/ListarDependencias'

const { TabPane } = Tabs;

class VerEntidad extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    PATHS_TABS = {
        ENTIDAD:{
            pestana: '/entidad',
            paths: ['/entidad']
        },
        DEPENDENCIA: {
            pestana: '/entidad-dependencias',
            paths: [
                '/entidad-dependencias',
            ]
        }
    };

    state = {
        cargando: true,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        entidad: undefined,
        usuario: undefined,
        tab: this.PATHS_TABS.ENTIDAD.pestana,
    };

    


    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioPais = new ServicioPais(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
        this.callbackEditarEntidad = props.callbackEditarEntidad;

        // Se extrae el PATH inicial de la URL
        this.pathInicial = props.history.location.pathname;
        this.props.history.listen(this.manejadorCambioPath);
    }


    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        let idEntidad = undefined;

        if (urlparametros.id !== undefined && !isNaN(parseInt(urlparametros.id))) {
            idEntidad = parseInt(urlparametros.id);
        }
        this.setSumaState({ idEntidad: idEntidad });
        
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr !== null) {
            this.setSumaState({ usuario: usr, componente: this.ENUM_COMPONENTE.VER, cargando: true });
            // Valida que tenga la accion habilitada VER
            let habilitado = await this.servicioUsuarios.accionHabilitadaPromesa(Constantes.ENUMS_MODULOS.ENTIDAD.ACCIONES.VER_ENTIDAD.id);
            if (habilitado && habilitado.resultado) {
                let entidad = await this.servicioAdministracion.obtenerEntidad( idEntidad );
                if ( entidad ) {
                    this.setSumaState({ cargando: false, usuario: usr, entidad: entidad });
                }
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }

    }

    componentDidMount() {
        this._isMounted = true;
        this.setSumaState({ tab: this.obtenerPestanaPorPath(this.pathInicial) });
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }


    manejadorCambioPath = (location, action) => {
        this.setSumaState({ tab: this.obtenerPestanaPorPath(location.pathname) });
    }


    obtenerPestanaPorPath = (path) => {
        let pestanas = Object.keys(this.PATHS_TABS);
        for (let index in pestanas) {
            let paths_tab = this.PATHS_TABS[pestanas[index]];
            for (let index02 in paths_tab.paths) {
                let path_tab = paths_tab.paths[index02];
                if (path === path_tab ) {
                    return paths_tab.pestana;
                }
            }
        }
    }

    manejadorClickTab = e => {
        // Se deja el tab en null para obligar a refrescar el componente hijo
        this.setSumaState({ tab: null });
        // Con el tab seleccionado tambien se cambia la URL, se deja dentro del setTimeout con el
        // fin de obligar a refrescar el componente hijo
        setTimeout(() => { this.historyLocal.push(e + '?id=' + this.state.idEntidad ); }, 100);
    };


    render() {
        const { componente, tab, usuario, idEntidad, entidad } = this.state;

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>

                            <div className="titulo-seccion">
                                    <label> <BankOutlined /> ENTIDAD {entidad ? (' - ' + entidad.razonSocial) : ''}</label>
                                <div className="separador-horizontal-completo"></div>
                            </div>

                            <div>
                                <Tabs activeKey={this.state.tab} onChange={this.manejadorClickTab}>
                                    <TabPane tab="Entidad" key={this.PATHS_TABS.ENTIDAD.pestana} />
                                    <TabPane tab="Dependencias" key={this.PATHS_TABS.DEPENDENCIA.pestana} />
                                </Tabs>

                                <Switch>
                                    <Route path={this.PATHS_TABS.ENTIDAD.paths}>
                                        {tab !== null ? <DatosEntidad callbackEditarEntidad={this.props.callbackEditarEntidad}/> : <></>}
                                    </Route>
                                    <Route path={this.PATHS_TABS.DEPENDENCIA.paths}>
                                        {tab !== null ? 
                                        <>
                                            <div className="div-lista-botones margen-bottom-5">
                                                {Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.AGREGAR_DEPENDENCIA.id) ?
                                                    <Button type="primary"
                                                        onClick={() => this.props.callbackAgregarDependencia( idEntidad )}
                                                        icon={<PlusOutlined />}>
                                                        Agregar dependencia
                                                    </Button> : (<></>)
                                                }
                                            </div>
                                            <ListarDependencias callbackVerDependencia={this.props.callbackVerDependencia}/> 
                                        </>
                                        : <></>}
                                    </Route>
                                </Switch>
                            </div>

                            </Spin>

                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:VERENT]</label>) : (<></>))
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(VerEntidad));