import React, { Component } from 'react';
import { Upload, Modal, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ServicioArchivos from '../servicios/archivos/ServicioArchivos'
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import { withCookies } from 'react-cookie';
import Constantes from '../utilidades/Constantes';
import SumaContexto from '../contexto/SumaContexto';
import Validadores from '../utilidades/Validadores';

class ZonaUsuarioLogeado extends Component {

    static contextType = SumaContexto;

    state = {
        loading: false,
        fileList: [],
    };

    constructor(props) {
        super(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioArchivos = new ServicioArchivos(props);
        this.usuario = props.usuario;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    beforeUpload = file => {
        // Valida el tipo de extension del archivo a adjuntar
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            Modal.error({ content: 'Solo puedes subir imágenes jpeg o png' });
        }
        // Valida el tamano del archivo
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            Modal.error({ content: 'La imagen debe ser menor a 2MB!' });
        }
        let success = isJpgOrPng && isLt2M;
        if (!success) {
            // Se encarga de limpiar la lista de los archivos adjuntos
            setTimeout(() => { this.setSumaState({ fileList: [] }); }, 4000);
        }
        return success;
    }

    handleChange = info => {
        let fileList = [...info.fileList];
        let limpiarLista = false;

        this.setSumaState({ fileList: fileList });

        if (info.file.status === 'uploading') {
            this.setSumaState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            Modal.success({ content: `${info.file.name} Imagen cargada satisfactoriamente` });
            limpiarLista = true;
        } else if (info.file.status === 'error') {
            Modal.error({ content: `${info.file.name} error carga del archivo.` });
            limpiarLista = true;
        }

        if (limpiarLista) {
            // Se encarga de limpiar la lista de los archivos adjuntos
            setTimeout(() => { this.setSumaState({ fileList: [] }); }, 4000);
        }
    };


    customRequest = ({ onSuccess, onError, file, onProgress }) => {
        // Se crea un objeto (reader) de tipo FileReader para cargar a nivel local el documento
        const reader = new FileReader();
        // Se agrega el evento 'loadend' con el fin de saber cuando termina de cargar el documento
        reader.addEventListener('loadend', (event) => {
            // Indica que hubo un error
            if (reader.error !== null) {
                console.error('Error lectura archivo local', reader.error);
                // Lanza un evento error al componente <Upload>
                onError();
            } else {
                // Se asume que aqui el archivo local fue leido exitosamente
                // Se carga el documento en el API
                this.servicioArchivos.cargarArchivo(
                    file,
                    Constantes.ENUMS.TIPO_ARCHIVO.USUARIO_FOTO_PERFIL.id,
                    (respuestaAPI) => { this.completeAPISubirArchivo({ onSuccess }, { onError }, { file }, respuestaAPI, reader.result) },
                    (errorAPI) => { this.errorAPISubirArchivo({ onError }, errorAPI) },
                    (porcentaje) => { this.progressAPISubirArchivo({ onProgress }, porcentaje) },
                );

            }
        });
        // Se carga en el objeto (reader) el documento a procesar
        reader.readAsDataURL(file);
    };

    completeAPISubirArchivo = ({ onSuccess }, { onError }, { file }, respuestaAPI, imageUrlBase64) => {
        if (respuestaAPI.codigo === Constantes.ENUMS_API.ARCHIVOS.ARCHIVO_GUARDADO.id) {
            onSuccess(null, file);
            //console.log('imageUrlBase64',imageUrlBase64);
            //this.setSumaState({ imageUrl: imageUrlBase64 });
            // Carga la nueva imagen del perfil dentro del SumaContexto
            this.context.setImagenPerfil_sc( imageUrlBase64 );
        } else {
            onError();
        }
        this.setSumaState({ loading: false });
    }

    errorAPISubirArchivo = ({ onError }, errorAPI) => {
        //console.log('error',errorAPI);
        onError();
        this.setSumaState({ loading: false });
    }

    progressAPISubirArchivo = ({ onProgress }, porcentaje) => {
        onProgress({ percent: porcentaje });
    }


    render() {

        const { loading } = this.state;
        // Saca la imagen del perfil que se encuentra en el contexto 
        // de la aplicación SumaContexto para cargarla
        const { imagenPerfil_sc } = this.context;

        const props = {
            name: 'file',
            accept: 'image/jpeg,image/jpg,image/png',
            showUploadList: { showPreviewIcon: true, showDownloadIcon: false, showRemoveIcon: false },
            className: 'subir-foto',
            multiple: false,
            beforeUpload: this.beforeUpload,
            onChange: this.handleChange
        };


        return (
            <div className={
                "zona-usuario-logeado zona-usuario-diseno " +
                ((!Validadores.objetoVacio(this.usuario) && this.usuario.rol.dependencia !== undefined)
                    ? "usuario-dependencia"
                    : "")
            }

            >
                <label className="titulo">DATOS PERSONALES</label>
                <div className="separador-horizontal"></div>
                <div className="seccion-superior-foto-datos">
                    <div className="seccion-superior-foto">
                        <div className="foto-usuario div-imagen" style={imagenPerfil_sc !== null ? { 'backgroundImage': `url(${imagenPerfil_sc})` } : {}} ></div>
                    </div>
                    <div className="seccion-superior-datos">
                        <label className="nombre-usuario">
                            {(!Validadores.objetoVacio(this.usuario))
                                ? (
                                    this.usuario.datoPersonal.primerNombre + ' ' +
                                    (typeof this.usuario.datoPersonal.segundoNombre !== 'undefined' ? (this.usuario.datoPersonal.segundoNombre + ' ') : '') +
                                    this.usuario.datoPersonal.primerApellido + ' ' +
                                    (typeof this.usuario.datoPersonal.segundoApellido !== 'undefined' ? (this.usuario.datoPersonal.segundoApellido) : '')
                                )
                                : ''
                            }
                        </label>
                        <Upload {...props} customRequest={this.customRequest} fileList={this.state.fileList}>
                            <Button type="primary" loading={loading}>
                                <UploadOutlined /> Cambiar
                        </Button>
                        </Upload>
                    </div>
                </div>
                <div className="seccion-media-datos datos-basicos">
                    <div className="info-datos">
                        <label>Correo electrónico:</label>
                        <span>{(!Validadores.objetoVacio(this.usuario)) ? this.usuario.datoPersonal.correoElectronico : ''}</span>
                    </div>
                    <div className="info-datos">
                        <label>Tipo de usuario:</label>
                        <span>{(!Validadores.objetoVacio(this.usuario)) ? this.usuario.tipoUsuario.tipoUsuario : ''}</span>
                    </div>
                    <div className="info-datos">
                        <label>Rol del usuario:</label>
                        <span>{(!Validadores.objetoVacio(this.usuario)) ? this.usuario.rol.rol : ''}</span>
                    </div>

                    {(!Validadores.objetoVacio(this.usuario) && this.usuario.usuarioDependencia !== undefined) ?
                        (<>
                            <div className="info-datos">
                                <label>Dependencia:</label>
                                <span>{this.usuario.usuarioDependencia.dependencia.dependencia}</span>
                            </div>
                            <div className="info-datos">
                                <label>Entidad:</label>
                                <span>{this.usuario.usuarioDependencia.dependencia.entidad.razonSocial}</span>
                            </div>
                        </>)
                        : (<></>)
                    }

                </div>
            </div>
        );
    }
}
export default withCookies(ZonaUsuarioLogeado);