import React, { Component } from 'react'
import SumaContexto from '../contexto/SumaContexto'
import { withRouter } from "react-router-dom"
import { Form, Input, Button, Row, Col, Modal, Spin, Popconfirm } from 'antd';
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import Constantes from '../utilidades/Constantes'
import Validadores from '../utilidades/Validadores'
import { withCookies } from 'react-cookie';

class Seguridad extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        cargando: true,
    };

    constructor(props) {
        super(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
    }
    //const [form] = Form.useForm();
    formRef = React.createRef();

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        this.setSumaState({ cargando: true, cargandoTipoDocumentos: true });
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr !== null) {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.VER, usuario: usr, cargando: false });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    cancelarFormulario = () => {
        //this.onSuccess(true);
        let form = this.formRef.current;
        if (form != null) {
            form.resetFields();
        }
    }


    render() {

        const { cargando, componente, usuario } = this.state;

        const layout = {
            labelCol: {
                /*span: 10, offset: 2,*/
                sm: { span: 22, offset: 2 },
                md: { span: 12, offset: 2 },
                lg: { span: 12, offset: 2 },
                xl: { span: 10, offset: 2 }
            },
            wrapperCol: {
                sm: { span: 22, offset: 2 },
                md: { span: 10 },
                lg: { span: 10 },
                xl: { span: 12 },
            },
        };

        const onFinish = values => {
            this.setSumaState({ cargando: true });
            let cambioContrasenaDto = {
                contrasenaActual: values.contrasena,
                contrasenaNueva: values.nuevaContrasenaConfirmar
            };

            this.servicioUsuarios.cambiarContrasenaUsuarioActual(
                (respuestaApi) => {
                    let form = this.formRef.current;
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.USUARIO.CONTRASENA_ASIGNADA.id:
                            Modal.success({ content: 'Se ha actualizado la contraseña satisfactoriamente' });
                            break;
                        case Constantes.ENUMS_API.USUARIO.CONTRASENA_INCORRECTA.id:
                            Modal.error({ content: 'Contraseña actual incorrecta' });
                            break;
                        case Constantes.ENUMS_API.USUARIO.CONTRASENA_NO_DISPONIBLE.id:
                            Modal.error({ content: 'Debes ingresar una nueva contraseña que no haya sido registrada con anterioridad' });
                            break;
                        case Constantes.ENUMS_API.USUARIO.ERROR_ENCRIPTACION.id:
                            Modal.error({ content: 'No fue posible cambiar la contraseña al usuario. Error de encriptación.' });
                            break;
                        default:
                            Modal.error({ content: 'No fue posible actualizar los datos. Por favor intenta mas tarde. [Cod:MCEIB02]' });
                            break;
                    }
                    if (form != null) {
                        form.resetFields();
                    }
                    //console.log('respuestaApi',respuestaApi); 
                    setTimeout(() => { this.setSumaState({ cargando: false }); }, 500);
                },
                (errorAPI) => {
                    this.setSumaState({ cargando: false });
                    Modal.error({ content: 'Error de conexión. Por favor intenta mas tarde. [Cod:MCS02]' });
                },
                cambioContrasenaDto
            );
        };

        const onFinishFailed = errorInfo => {
            Modal.warning({ content: 'Por favor diligencia la información solicitada.' });
        };

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>

                                {usuario ?
                                    usuario.autenticaContraLDAP ?
                                        (<>
                                            <p className="descripcion-seccion">
                                                El usuario se autentica contra el directorio activo del DADEP. No cuenta con la opción de modificar la contraseña desde el portal SUMA+.
                                            </p>
                                        </>)
                                        : (<>

                                            <p className="descripcion-seccion">
                                                Debe ser mínimo de 8 caracteres y tener al menos: Una letra mayúscula, una letra minúscula, un dígito y alguno de los siguientes caracteres: ! @ # $ % ^ & *
                                            </p>
                                            <Form
                                                {...layout}
                                                ref={this.formRef}
                                                name="formulario-seguridad"
                                                className="formulario-suma"
                                                scrollToFirstError
                                                onFinish={onFinish}
                                                onFinishFailed={onFinishFailed}
                                            >
                                                <Row>
                                                    <Col xs={24} sm={12}>
                                                        <Form.Item label="Contraseña actual" name="contrasena" hasFeedback
                                                            rules={[{ required: true, message: 'Ingresa tu contraseña actual', },]} >
                                                            <Input.Password />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={24} sm={12}>
                                                        <Form.Item label="Nueva contraseña" name="nuevaContrasena" hasFeedback
                                                            rules={[
                                                                { required: true, message: 'Ingresa tu nueva contraseña', },
                                                                () => ({
                                                                    validator(rule, value) {
                                                                        if (value) {
                                                                            const val = Validadores.validarContrasena(value);
                                                                            if (!val.success) {
                                                                                return Promise.reject(val.msg);
                                                                            }
                                                                        }
                                                                        return Promise.resolve();
                                                                    },
                                                                }),
                                                            ]} >
                                                            <Input.Password />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={24} sm={12}>
                                                        <Form.Item label="Confirmar nueva contraseña" name="nuevaContrasenaConfirmar"
                                                            dependencies={['nuevaContrasena']} hasFeedback
                                                            rules={[
                                                                { required: true, message: 'Confirma tu nueva contraseña', },
                                                                ({ getFieldValue }) => ({
                                                                    validator(rule, value) {
                                                                        if (!value || getFieldValue('nuevaContrasena') === value) {
                                                                            return Promise.resolve();
                                                                        }
                                                                        return Promise.reject('No coindicen las nuevas contraseñas');
                                                                    },
                                                                }),
                                                            ]}>
                                                            <Input.Password />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Form.Item>
                                                    {/*<Button type="primary" htmlType="submit" loading={ cargando }>
                                            Guardar
                                                </Button>*/}
                                                    <Popconfirm title="Confirma el cambio de contraseña?"
                                                        okText="Si"
                                                        cancelText="No"
                                                        onCancel={this.cancelarFormulario}
                                                        okButtonProps={{ form: 'formulario-seguridad', key: 'submit', htmlType: 'submit' }}
                                                    >
                                                        <Button type="primary" htmlType="submit" loading={cargando}>
                                                            Guardar
                                                        </Button>
                                                    </Popconfirm>
                                                </Form.Item>

                                            </Form>
                                        </>) :
                                    (<></>)
                                }
                            </Spin>
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:MCS]</label>) : (<></>))
                    )
                }

            </div>
        );
    }
}

export default withRouter(withCookies(Seguridad));