import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import SumaContexto from '../../contexto/SumaContexto';
import queryString from 'query-string';
import ServicioUsuarios from '../../servicios/usuarios/ServicioUsuarios'
import { Spin, message, Alert, Button, Modal, Form, Row, Col, Checkbox } from 'antd';
import Constantes from '../../utilidades/Constantes'
import Utilidades from '../../utilidades/Utilidades';
import { CheckSquareOutlined } from '@ant-design/icons';


function ValidarActivarUsuario(attr) {
    //console.log('attr',attr);
    //let urlparametros = queryString.parse( attr.urlparametros );
    //console.log('urlparametros',urlparametros);

    if (attr.cargando) {
        return (
            <>
                <div className="titulo-seccion">
                    <label>ACTIVAR USUARIO MIGRACIÓN DADEP</label>
                    <div className="separador-horizontal-completo"></div>
                </div>
                <div className="cargando-pagina">
                    <Spin tip="Por favor espera..." size="large" ></Spin>
                </div>
            </>
        )
    }

    if (!attr.activarCuenta) {
        return (
            <>
                <div>
                    <Alert message={'PETICIÓN NO DISPONIBLE. ' + attr.msgError} type="warning" />
                </div>
            </>
        );
    } else {

        return (
            <></>
        );
    }
}

class ActivarUsuarioMigracionDadep extends Component {

    static contextType = SumaContexto;

    state = {
        cargando: true,
        activarCuenta: false,
        msgError: null,
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioUsuarios = new ServicioUsuarios(props);
    }

    formRef = React.createRef();

    componentDidMount() {
        this._isMounted = true;
        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        this.enc = null;
        if (urlparametros.enc !== undefined) {
            this.enc = urlparametros.enc;
            this.servicioUsuarios.usuarioMigracion(
                this.enc,
                (respuestaApi) => {
                    let msgError = respuestaApi.mensaje;
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.USUARIO.USUARIO_MIGRACION.id:
                            this.setSumaState({
                                usuarioInformacionBasicaDto: respuestaApi.resultado,
                                cargando: false,
                                activarCuenta: true,
                                msgError: null
                            });
                            break;
                        default:
                            this.setSumaState({
                                cargando: false,
                                activarCuenta: false,
                                msgError: msgError
                            });
                            break;
                    }
                },
                (errorAPI) => { message.error('Error de conexión. Por favor intenta mas tarde.', 10) },
            );
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    render() {

        let contexto = this;
        const layout = {
            labelCol: {
                /*span: 10, offset: 2,*/
                sm: { span: 22, offset: 2 },
                md: { span: 12, offset: 2 },
                lg: { span: 12, offset: 2 },
                xl: { span: 10, offset: 2 }
            },
            wrapperCol: {
                sm: { span: 22, offset: 2 },
                md: { span: 10 },
                lg: { span: 10 },
                xl: { span: 12 },
            },
        };

        const onFinish = async (values) => {
            this.setSumaState({ cargando: true });
            // Contrasena nueva
            let respuestaApi = await this.servicioUsuarios.activarCuenta(
                {
                    aceptoTerminoscondiciones: values.terminosYCondiciones,
                },
                this.enc);

            if (respuestaApi) {
                let form = null;
                switch (respuestaApi.codigo) {
                    case Constantes.ENUMS_API.USUARIO.OPERACION_REALIZADA.id:
                        form = this.formRef.current;
                        if (form != null) {
                            form.resetFields();
                            this.setSumaState({ mostrarModal: true, });
                        }
                        break;
                    default:
                        Modal.error({ content: 'No fue posible activar la cuenta.' });
                        break;
                }

                setTimeout(() => { this.setSumaState({ cargando: false }); }, 500);
            }


        };

        const onFinishFailed = errorInfo => {
            Modal.warning({ content: 'Por favor diligencia la información solicitada.' });
        };

        return (
            <div className="seccion-principal seccion-flex">
                <div className="division-principal">
                    <label>Activar Usuario Migración Dadep</label>
                </div>
                <div className="division-contenido seccion-flex-grow-1 padding-principal bkg-white">

                    {!this.state.activarCuenta ?
                        <ValidarActivarUsuario cargando={this.state.cargando}
                            activarCuenta={this.state.activarCuenta}
                            usuarioInformacionBasicaDto={this.state.usuarioInformacionBasicaDto}
                            msgError={this.state.msgError}
                            token={this.enc}
                            contexto={contexto} />
                        : (<>
                            <Spin tip="Cargando..." size="large" spinning={this.state.cargando}>
                                <div className="titulo-seccion">
                                    <label>ACTIVAR USUARIO MIGRACIÓN DADEP</label>
                                    <div className="separador-horizontal-completo"></div>
                                    <p className="descripcion-seccion">Para activar tu cuenta dentro del portal SUMA+ haz clic en el botón 'Activar Cuenta'</p>
                                    <br />
                                </div>
                                <div className="seccion-formulario">

                                    <Modal title="Importante" closable={false} className="modal-suma icono-con-texto"
                                        visible={this.state.mostrarModal}
                                        footer={[
                                            <Button key="cerrar" type="primary" href={Constantes.URL_PUBLICAS.INICIO} >
                                                Cerrar
                                            </Button>
                                        ]}
                                    >
                                        <CheckSquareOutlined />
                                        <p>
                                            Tu usuario ha sido activado, ahora puedes ingresar al portal SUMA+ con tus credenciales del dominio del DADEP.
                                        </p>
                                    </Modal>

                                    <Form
                                        {...layout}
                                        ref={this.formRef}
                                        name="asignar-contrasena"
                                        className="formulario-suma"
                                        scrollToFirstError
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                    >

                                        {(this.state.usuarioInformacionBasicaDto && this.state.usuarioInformacionBasicaDto.debeAceptarTerminosCondiciones) ?
                                            <Row>
                                                <Col xs={24} >
                                                    <Form.Item name="terminosYCondiciones"
                                                        valuePropName="checked"
                                                        rules={[
                                                            {
                                                                validator: (_, value) =>
                                                                    value ? Promise.resolve() : Promise.reject(new Error('Debes aceptar los términos y condiciones')),
                                                            },]}
                                                        labelCol={{ span: 24, offset: 0 }}
                                                        wrapperCol={{ span: 24, offset: 0 }}>
                                                        <Checkbox>
                                                            Acepto los <a href={Utilidades.extraerEnlace(Constantes.ENUMS.PARAMETRO_GENERAL.ENLACE_TERMINOS_Y_CONDICIONES.id)}>términos y condiciones</a> y la
                                                            <a href={Utilidades.extraerEnlace(Constantes.ENUMS.PARAMETRO_GENERAL.ENLACE_PROTECCION_DATOS_PERSONALES.id)}> política de protección de datos personales</a>
                                                        </Checkbox>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            : null
                                        }

                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" loading={this.state.cargando}>
                                                Activar cuenta
                                            </Button>
                                        </Form.Item>

                                    </Form>





                                </div>

                            </Spin>

                        </>)
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(ActivarUsuarioMigracionDadep);