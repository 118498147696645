import React, { Component } from 'react';
import Utilidades from '../utilidades/Utilidades';
import Constantes from '../utilidades/Constantes';
import EnlaceCircular from './EnlaceCircular';

import icono_telefono from '../recursos/imagenes/iconos/telefono.svg'
import icono_ubicacion from '../recursos/imagenes/iconos/ubicacion.svg'
import icono_instagram from '../recursos/imagenes/iconos/instagram.png'
import icono_facebook from '../recursos/imagenes/iconos/facebook.png'
import icono_twitter from '../recursos/imagenes/iconos/twitter.png'

class PiePagina extends Component {
  render() {
    return (
      <footer>
        <div className='footer-superior padding-laterales'>
          <div className='seccion-footer contacto'>
            <img src={icono_telefono} className="img-footer" alt="SUMA" />
            <div className="separador-vertical"></div>
            <table>
              <tbody>
                <tr className='titulo'>
                  <td>PBX</td>
                  <td>Celular</td>
                </tr>
                <tr>
                  <td>(601) 382 2510, (601) 350 7062</td>
                  <td>314 473 2213</td>
                </tr>
                <tr className='titulo'>
                  <td>Línea gratuita</td>
                  <td>Línea</td>
                </tr>
                <tr>
                  <td>01 8000 127700</td>
                  <td>195</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='seccion-footer direccion'>
            <img src={icono_ubicacion} className="img-footer" alt="SUMA" />
            <div className="separador-vertical"></div>
            <table>
              <tbody>
                <tr className='titulo'>
                  <td>Av. Carrera 30 No. 25 - 90, Piso 15 - Bogotá Colombia</td>
                </tr>
                <tr>
                  <td> </td>
                </tr>
                <tr className='titulo'>
                  <td>Horario</td>
                </tr>
                <tr>
                  <td>Lunes a viernes 7:00 a.m. a 4:00 p.m.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="seccion-footer redes redes-sociales">
            <EnlaceCircular href={Utilidades.extraerEnlace(Constantes.ENUMS.PARAMETRO_GENERAL.ENLACE_FACEBOOK_DADEP.id)}
              className="red-social" 
              icono={<img src={icono_facebook} className="img-enlace" alt="SUMA" />} />  
            <EnlaceCircular
              href={Utilidades.extraerEnlace(Constantes.ENUMS.PARAMETRO_GENERAL.ENLACE_TWITTER_DADEP.id)}
              className="red-social"
              icono={<img src={icono_twitter} className="img-enlace" alt="SUMA" />} />
            <EnlaceCircular
              href={Utilidades.extraerEnlace(Constantes.ENUMS.PARAMETRO_GENERAL.ENLACE_YOUTUBE_DADEP.id)}
              className="red-social"
              icono={<img src={icono_instagram} className="img-enlace" alt="SUMA" />} />
          </div>
        </div>
        <div className='footer-inferior padding-laterales'>
          <a href={Utilidades.extraerEnlace(Constantes.ENUMS.PARAMETRO_GENERAL.ENLACE_PROTECCION_DATOS_PERSONALES.id)}>Protección de datos personales</a>
          <a href={Utilidades.extraerEnlace(Constantes.ENUMS.PARAMETRO_GENERAL.ENLACE_POLITICAS_SEGURIDAD_INFORMACION.id)}>Políticas de Seguridad de la información</a>
          <span>Copyright © 2022</span>
        </div>

      </footer>
    );
  }
}

export default PiePagina;