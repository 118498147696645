import axios from 'axios';
import Constantes from '../../utilidades/Constantes'
import Utilidades from '../../utilidades/Utilidades'
import { Modal } from 'antd';

class ServicioArchivos {

    constructor( props ){ 
        const { cookies } = props;
        this.cookies = cookies;
        //console.log('ServicioArchivos.cookies',cookies);
        //console.log(this.cookies.get('sumajwt'));
    }

    cargarArchivo = (file, idTipoArchivo, callbackComplete, callbalkError, callbackProgress ) => {
        let token = this.cookies.get('sumajwt');
        const data= new FormData()
        data.append('file', file);
        data.append('idTipoArchivo', idTipoArchivo);
        const config= {
            headers:{
                'Authorization': `Bearer ${token}`,
                "content-type": 'multipart/form-data;'
            },
            onUploadProgress: event => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                //console.log('percent',percent);
                callbackProgress( percent );
            }
        };
        axios.post( Constantes.URL_API_SUMA + '/archivo', data, config)
        .then( res => {
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }

        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioArchivos.cargarArchivo]',err);
            callbalkError( {err} );
        })

    }


    cargarArchivoPromise = (file, idTipoArchivo, callbackProgress ) => {
        let token = this.cookies.get('sumajwt');
        const data= new FormData()
        data.append('file', file);
        data.append('idTipoArchivo', idTipoArchivo);
        const config= {
            headers:{
                'Authorization': `Bearer ${token}`,
                "content-type": 'multipart/form-data;'
            },
            onUploadProgress: event => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                //console.log('percent',percent);
                callbackProgress( percent );
            }
        };


        return new Promise( resolve =>{
            axios.post( Constantes.URL_API_SUMA + '/archivo', data, config)
            .then( res => {
                if(res.status === 200){
                    resolve( res.data );
                }else{
                    throw new Error("Error cargar archivo. " + res.status );
                }

            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioArchivos.cargarArchivoPromise]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SERARCH01]" }); 
                resolve();
            })
        });

    }

    // Método privado
    obtenerTipoArchivoPersonaNatural () {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/archivo/tipo-archivo-persona-natural' ,config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    if( res.data.codigo === Constantes.ENUMS_API.ARCHIVOS.DATOS_RETORNADOS.id ){
                        resolve( res.data.resultado );
                    }else{
                        Modal.error({ content: `Código:${res.data.codigo} - Mensaje:${res.data.mensaje}. [Error.ServicioArchivos.obtenerTipoArchivoPersonaNatural] [Cod:SERARCH03]` });         
                    }
                    return;
                }else{
                    throw new Error("Error obtener tipo archivo persona natural. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioArchivos.obtenerTipoArchivoPersonaNatural]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SERARCH02]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerTipoArchivoPersonaJuridica () {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/archivo/tipo-archivo-persona-juridica' ,config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    if( res.data.codigo === Constantes.ENUMS_API.ARCHIVOS.DATOS_RETORNADOS.id ){
                        resolve( res.data.resultado );
                    }else{
                        Modal.error({ content: `Código:${res.data.codigo} - Mensaje:${res.data.mensaje}. [Error.ServicioArchivos.obtenerTipoArchivoPersonaJuridica] [Cod:SERARCH05]` });         
                    }
                    return;
                }else{
                    throw new Error("Error obtener tipo archivo persona jurídica. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioArchivos.obtenerTipoArchivoPersonaJuridica]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SERARCH04]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerTipoSolicitudArchivo ( idTipoSolicitud ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + `/archivo/tipo-solicitud-archivo/${idTipoSolicitud}` ,config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    if( res.data.codigo === Constantes.ENUMS_API.ARCHIVOS.DATOS_RETORNADOS.id ){
                        resolve( res.data.resultado );
                    }else{
                        Modal.error({ 
                            content: 
                            `Código:${res.data.codigo} - Mensaje:${res.data.mensaje}. 
                            [Error.ServicioArchivos.obtenerTipoSolicitudArchivo] [Cod:SERARCH07]` 
                        });         
                    }
                    return;
                }else{
                    throw new Error("Error obtener tipo solicitud archivo. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioArchivos.obtenerTipoSolicitudArchivo]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SERARCH06]" }); 
                resolve();
            });
        });
    }

    // Método privado
    guardarTipoSolicitudArchivos ( tipoSolicitudArchivos ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.post( Constantes.URL_API_SUMA + `/archivo/tipo-solicitud-archivo` ,tipoSolicitudArchivos,config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    if( res.data.codigo === Constantes.ENUMS_API.ARCHIVOS.OPERACION_REALIZADA.id ){
                        resolve( true );
                    }else{
                        Modal.error({ 
                            content: 
                            `Código:${res.data.codigo} - Mensaje:${res.data.mensaje}. 
                            [Error.ServicioArchivos.guardarTipoSolicitudArchivos] [Cod:SERARCH08]` 
                        });         
                    }
                    return;
                }else{
                    throw new Error("Error al guardar los tipo solicitud archivos. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioArchivos.guardarTipoSolicitudArchivos]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SERARCH09]" }); 
                resolve();
            });
        });
    }

}

export default ServicioArchivos;