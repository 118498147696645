import React, { Component } from 'react';
import SumaContexto from '../../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import {
    Modal, Spin, Row, Col, Form, Input, Button, AutoComplete,
    Popconfirm, Tooltip, Checkbox, Transfer, Select
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import * as AntDesignIconos from '@ant-design/icons';
import ServicioUsuarios from '../../../servicios/usuarios/ServicioUsuarios'
import ServicioDatosConstantes from '../../../servicios/ServicioDatosConstantes'
import ServicioAdministracion from '../../../servicios/ServicioAdministracion'
import ServicioEntidad from '../../../servicios/ServicioEntidad'
import ServicioSolicitud from '../../../servicios/ServicioSolicitud';
import queryString from 'query-string';
import { ChromePicker } from 'react-color';
import Utilidades from '../../../utilidades/Utilidades';
import Constantes from '../../../utilidades/Constantes';
import Validadores from '../../../utilidades/Validadores';

const { Option } = Select;


class AdministrarOpcionDependenciaSolicitud extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        selectorColor: false,
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
        this.servicioEntidad = new ServicioEntidad(props);
        this.servicioSolicitud = new ServicioSolicitud(props);

        this.onSuccess = props.onSuccess;
        this.textAreaUrlRef = React.createRef();
    }

    formRef = React.createRef();


    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        let idOpcDependencia;
        let idDependencia;
        let idDependenciaTipoSol;

        if (urlparametros.id !== undefined && !isNaN(parseInt(urlparametros.id))) {
            idOpcDependencia = parseInt(urlparametros.id);
        }
        if (urlparametros.idDep !== undefined && !isNaN(parseInt(urlparametros.idDep))) {
            idDependencia = parseInt(urlparametros.idDep);
            idDependenciaTipoSol = idDependencia;
        }

        //let usr = await this.servicioAdministracion.usuarioValido();
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        //console.log('usr',usr);
        if( usr.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id ){
            if( usr.usuarioDependencia && usr.usuarioDependencia.dependencia && usr.usuarioDependencia.dependencia.id ){
                idDependencia = usr.usuarioDependencia.dependencia.id;
                idDependenciaTipoSol = idDependencia;
            }
        }

        if (usr !== null && (idOpcDependencia || idDependencia)) {

            let iconosAntD = [];
            for (const property in AntDesignIconos) {
                if (property.includes('Outlined') || property.includes('Filled')) {
                    iconosAntD.push(property);
                }
            }

            this.setSumaState({
                idDependencia: idDependencia,
                idOpcDependencia: idOpcDependencia,
                iconosAntD: iconosAntD,
                tiposSolicitud: []
            });

            let opcionDependencia;
            if (idOpcDependencia) {
                // Se consulta la opción dependencia y se carga (Esto es en modo Edición opción dependencia)
                opcionDependencia = await this.servicioAdministracion.obtenerOpcionDependencia(idOpcDependencia);
                if (opcionDependencia && opcionDependencia.tipoOpcion.id === Constantes.ENUMS.TIPO_OPCION.SOLICITUD.id) {
                    this.setSumaState({ 
                        componente: this.ENUM_COMPONENTE.VER, 
                        opcionDependencia, 
                        dependencia: opcionDependencia.dependencia,
                        valueAutoComplete: opcionDependencia.dependencia.dependencia, 
                        keyAutoComplete: opcionDependencia.dependencia.id

                    });
                    //console.log('opcionDependencia',opcionDependencia);
                    this.formRef.current.setFieldsValue({
                        'opcionDependencia': opcionDependencia.opcionDependencia,
                        'color': opcionDependencia.color,
                        'url': opcionDependencia.url,
                        'verOpcionEnLista': opcionDependencia.verOpcionEnLista,
                        'tipoSolicitud': opcionDependencia.tipoSolicitud.id.toString(),
                    });

                    this.setSumaState({
                        icono: opcionDependencia.icono,
                        rgbColor: opcionDependencia.color,
                        rolesSeleccionados: opcionDependencia.roles,
                        color: Utilidades.rgbToColor(opcionDependencia.color)
                    });
                    idDependencia = opcionDependencia.dependencia.id;
                    idDependenciaTipoSol = opcionDependencia.tipoSolicitud.dependencia.id;

                } else {
                    this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
                }
            } else {
                if (idDependencia) {
                    let respuestaApi = await this.servicioEntidad.obtenerDependenciaPromise(idDependencia);
                    let dependencia = respuestaApi.resultado;
                    if (dependencia) {
                        //console.log('dependencia',dependencia);
                        this.setSumaState({ dependencia, 
                                            valueAutoComplete: dependencia.dependencia, 
                                            keyAutoComplete: dependencia.id });
                    }
                }

                

                this.setSumaState({ componente: this.ENUM_COMPONENTE.VER });
            }


            if (idDependencia) {
                let rolesPjPn = await this.servicioAdministracion.obtenerRolesTipoUsuarioPersonaNaturalJuridica();
                let rolesDep = await this.servicioDatosConstantes.obtenerRolesDependencia(idDependencia);
                let roles = rolesPjPn.concat( rolesDep );

                let tiposSol = await this.servicioSolicitud.obtenerTipoDeSolicitudesDependencia( idDependenciaTipoSol );
                let tiposSolicitud = [];
                if (tiposSol) {
                    for (let tipo of tiposSol) {
                        tiposSolicitud.push({ label: tipo.solicitud, value: tipo.id });
                    }
                }
                this.setSumaState({ roles, rolesPjPn, tiposSolicitud });
            }


            this.setSumaState({ cargando: false });

        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }

        this.setSumaState({ idDependenciaTipoSol: idDependenciaTipoSol });
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    irListadoOpcionesDependencia(contexto) {
        let url = !this.props.urlListadoOpciones 
        ? ( '/dependencia-opciones?id=' + contexto.state.idDependencia ) 
        : this.props.urlListadoOpciones;

        contexto.historyLocal.push(url);
    }


    errorFormularioIncompleto = errorInfo => {
        // keyAutoComplete: Guarda el key de la selección hecha sobre el componente AutoComplete
        if (!this.state.keyAutoComplete || this.state.keyAutoComplete === null) {
            this.setSumaState({ keyAutoComplete: null });
            Modal.warn({ content: "Por favor selecciona la dependencia." });
        } else {
            Modal.warn({ content: "Por favor diligencia la información solicitada para la opción dependencia" });
        }

    };

    formularioCompleto = (values) => {

        // keyAutoComplete: Guarda el key de la selección hecha sobre el componente AutoComplete
        if (!this.state.keyAutoComplete || this.state.keyAutoComplete === null) {
            this.setSumaState({ keyAutoComplete: null });
            Modal.warn({ content: "Por favor selecciona la dependencia." });
            return;
        }

        return new Promise(resolve => {
            //console.log('values',values);
            let opcionDependencia = {
                opcionDependencia: values.opcionDependencia.trim(),
                url: values.url.trim(),
                ...(values.color && { color: values.color }),
                ...(values.verOpcionEnLista && { verOpcionEnLista: values.verOpcionEnLista }),
                ...(this.state.icono && { icono: this.state.icono }),
                tipoSolicitud: { id: parseInt(values.tipoSolicitud) },
                dependencia: { id: this.state.keyAutoComplete }
            };

            //console.log('opcionDependencia',opcionDependencia);
            //console.log('roles',this.state.rolesSeleccionados);

            opcionDependencia.roles = this.state.rolesSeleccionados;
            resolve(opcionDependencia);
        }).then(async (opcionDependencia) => {

            this.setSumaState({ cargando: true });

            

            if (!this.state.opcionDependencia) {
                // Opción Dependencia Nueva
                opcionDependencia.tipoOpcion = { id: Constantes.ENUMS.TIPO_OPCION.SOLICITUD.id };
                
                let respuestaApi = await this.servicioAdministracion.crearOpcionDependencia(opcionDependencia);
                if (respuestaApi) {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id:
                            this.irListadoOpcionesDependencia(this);
                            Modal.success({ content: 'Opción dependencia creada satisfactoriamente.' });
                            break;
                        default:
                            Modal.error({ content: 'No fue posible crear la opción dependencia. Por favor intenta mas tarde.' });
                            break;
                    }
                }
            } else {
                // Editar Opción Dependencia 
                opcionDependencia.id = this.state.idOpcDependencia;

                let respuestaApi = await this.servicioAdministracion.actualizarOpcionDependencia(opcionDependencia);
                if (respuestaApi) {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id:
                            Modal.success({ content: 'Opción dependencia actualizada satisfactoriamente.' });
                            break;
                        default:
                            Modal.error({ content: 'No fue posible actualizar la opción dependencia. Por favor intenta mas tarde.' });
                            break;
                    }
                }

            }

            this.setSumaState({ cargando: false });

        });

    };

    cancelarFormularioDependencia = () => {
        this.onSuccess(this.state.idDependencia, this.state.idOpcDependencia, true);
    }

    guardarCursorTextArea = (event) => {
        this.setSumaState({ cursorInicio: event.target.selectionStart, cursorFin: event.target.selectionEnd });
    }

    agregarValorUrl = (event, valor) => {
        let url = this.textAreaUrlRef.current.resizableTextArea.props.value;
        let start = this.state.cursorInicio;
        let end = this.state.cursorFin;
        if (url === undefined)
            url = '';
        url = url.substr(0, start) + valor + url.substr(end);
        this.formRef.current.setFieldsValue({ 'url': url });
    }

    renderItemRol = item => {
        const customLabel = (
            <div className="item-transfer-rol">
                <span>Id:{item.id} - {item.rol}</span>
                <span>{item.tipoUsuario.tipoUsuario ? item.tipoUsuario.tipoUsuario : item.tipoUsuario}</span>
                {item.dependencia ? <><span>{item.dependencia.dependencia ? item.dependencia.dependencia : item.dependencia }</span></> : null}
            </div>
        );

        return {
            label: customLabel, // for displayed item
            value: item.id, // for title and filter matching
        };
    };


    buscar = async (value) => {
        this.setSumaState({ roles:[], rolesSeleccionados: [], cargandoRoles: true });
        
        let opciones = [{
            label: this.renderTituloItems('Dependencia'),
            options: [],
        }];
        if (value) {
            let depencias = await this.servicioAdministracion.buscarDependencia(value);
            for (let i in depencias) {
                opciones[0].options.push(this.renderItemDependencia(depencias[i].id, depencias[i].dependencia, depencias[i].entidad.razonSocial));
            }
        }
        this.setSumaState({ opciones: opciones });
    };

    renderItemDependencia = (id, dependecia, entidad) => ({
        key: id,
        value: dependecia,
        label: (
            <div className={"item-personalizado"}>
                <span className={"titulo-item"}>{dependecia}</span><br />
                <span className={"sub-titulo-item"}>Entidad: {entidad}</span>
            </div>
        ),
    });

    cambioAutoComplete = (value) => {
        if (!this.state.valueAutoComplete || (value.normalize() !== this.state.valueAutoComplete.normalize())) {
            // keyAutoComplete: Guarda el key de la selección hecha sobre el componente AutoComplete
            this.setSumaState({ keyAutoComplete: null });
        }
    }

    onSelectAutoComplete = async (item) => {
        //console.log('onSelect', item);
        let rolesDep = [];
        // keyAutoComplete: Guarda el key de la selección hecha sobre el componente AutoComplete
        this.setSumaState({ valueAutoComplete: item.value, keyAutoComplete: item.key });
        
        let idDependencia = item.key;
        this.setSumaState({ cargandoRoles: true });
        rolesDep = await this.servicioDatosConstantes.obtenerRolesDependencia(idDependencia);

        let roles = this.state.rolesPjPn.concat(rolesDep ? rolesDep : []);

        //console.log('roles',roles);
        this.setSumaState({ roles, cargandoRoles: false });
    };

    renderTituloItems = (titulo) => (
        <div className={"titulo-opciones"}>
            <span>{titulo}</span>
        </div>
    );

    render() {
        const { dependencia, opcionDependencia, componente, cargando, tiposSolicitud, opciones, idDependenciaTipoSol } = this.state;


        let contexto = this;

        const layout = {
            labelCol: { // Este maneja el grid de los label
                sm: { span: 23, offset: 1 },
                lg: { span: 7, offset: 1 },
            },
            wrapperCol: { // Este maneja el grid de los componentes
                sm: { span: 23, offset: 1 },
                lg: { span: 15, offset: 1 },
            },
        };

        const labelPrimerCol = { // Este maneja de forma especial el grid de las primeras columnas
            sm: { span: 23, offset: 1 },
            lg: { span: 8, offset: 0 },
        }

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>

                            <div className="titulo-seccion">
                                <label>
                                    {!opcionDependencia ? 'AGREGAR ' : ''} OPCIÓN DEPENDENCIA   ( Solicitud )
                                </label>
                                <div className="separador-horizontal-completo margen-bottom-10"></div>
                            </div>


                            <Spin tip={'por favor espera...'} size="large" spinning={cargando}>



                                <Form
                                    {...layout}
                                    ref={this.formRef}
                                    name="datos-dependencia"
                                    className="formulario-suma"
                                    scrollToFirstError
                                    onFinish={this.formularioCompleto}
                                    onFinishFailed={this.errorFormularioIncompleto}
                                >

                                    <div className="sub-titulo-seccion">
                                        <label>Opcion dependencia {dependencia ? (' - ' + dependencia.dependencia) : ''}</label>
                                        <div className="separador-horizontal-completo02 margen-bottom-20"></div>
                                    </div>

                                    <Form.Item label="id" name="id" noStyle>
                                        <Input type="hidden" />
                                    </Form.Item>

                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Opción dependencia" name="opcionDependencia"
                                                rules={[
                                                    { required: true, message: 'Ingresa el nombre de la opción dependencia', },
                                                    { min: 3, message: 'No puede ser menor a 3 caracteres', },
                                                    { max: 255, message: 'No puede ser mayor a 255 caracteres', },
                                                    ({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            if (value && !Validadores.validarAlfaNumercioConEspacio( value ).success ) {
                                                                return Promise.reject('Ingresa un texto alfanumérico');
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    }),
                                                ]}
                                                labelCol={labelPrimerCol}
                                            >
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Row>
                                                <Col sm={18}>
                                                    <Form.Item label="Color" name="color">
                                                        <Input autoComplete="off" disabled={true} />
                                                    </Form.Item>
                                                </Col>
                                                <Col sm={6}>
                                                    <div className={'div-color'}
                                                        style={{ background: Utilidades.colorToRgb(this.state.color) }}
                                                        disabled={true}
                                                        onClick={() => { this.setSumaState({ selectorColor: !this.state.selectorColor }); }}>
                                                    </div>


                                                    {this.state.selectorColor ?
                                                        <div className={'div-selector-color'}>
                                                            <div className={'div-selector-color-cover'} onClick={() => { this.setSumaState({ selectorColor: false }); }} />
                                                            <ChromePicker color={this.state.color}
                                                                onChange={(color) => {
                                                                    this.setSumaState({ color: color.rgb, rgbColor: Utilidades.colorToRgb(color.rgb) });
                                                                    this.formRef.current.setFieldsValue({ 'color': Utilidades.colorToRgb(color.rgb) });
                                                                    //console.log('form',this.formRef);
                                                                }} />
                                                        </div> : null
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>


                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Row>
                                                <Col sm={8}>
                                                    <label>Icono:</label>
                                                </Col>
                                                <Col sm={{ span: 15, offset: 1 }}>
                                                    <Button type="default" className={"icono-seleccionado"} style={{ color: this.state.rgbColor }}
                                                        icon={<Utilidades.IconoAntDesign type={this.state.icono} />}
                                                        onClick={() => { this.setSumaState({ selectorIcono: true }); }} />
                                                    <Button type="link" onClick={() => { this.setSumaState({ selectorIcono: true }); }}>Seleccionar</Button>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col xs={24} sm={12}>
                                            <Row>
                                                <Col sm={18}>
                                                    <Form.Item name="verOpcionEnLista" valuePropName="checked">
                                                        <Checkbox>Ver opción en el listado de solicitudes</Checkbox>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Tipo solicitud" name="tipoSolicitud"
                                                rules={[{ required: true, message: 'Selecciona el tipo de solicitud', },]}
                                                labelCol={labelPrimerCol}
                                            >
                                                <Select placeholder="Tipo de solicitud">
                                                    {tiposSolicitud.map(tipoSolicitud => (
                                                        <Option key={tipoSolicitud.value}>{tipoSolicitud.label}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>


                                    <Row>
                                        <Col xs={24}>
                                            <Form.Item label="Url" name="url" className="text-area-etiquetas-entrada"
                                                labelCol={{ span: 24, offset: 0 }}
                                                wrapperCol={{ span: 24, offset: 0 }}
                                                rules={[
                                                    { required: true, message: 'Ingresa la url de redirección hacia el aplicativo web externo', },
                                                    { min: 7, message: 'No puede ser menor a 7 caracteres', },
                                                    { max: 2000, message: 'No puede ser mayor a 2000 caracteres', },
                                                ]}>

                                                <Input.TextArea ref={this.textAreaUrlRef} rows={4}
                                                    onChange={this.guardarCursorTextArea}
                                                    onClick={this.guardarCursorTextArea}
                                                    onKeyUp={this.guardarCursorTextArea}
                                                    onBlur={this.guardarCursorTextArea} />

                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Row className="text-area-etiquetas">
                                                <Button onClick={(event) => { this.agregarValorUrl(event, 'https://') }} size="small">https://</Button>
                                                <Button onClick={(event) => { this.agregarValorUrl(event, 'http://') }} size="small">http://</Button>
                                                <Button onClick={(event) => { this.agregarValorUrl(event, 'www.') }} size="small">www.</Button>
                                                <Button onClick={(event) => { this.agregarValorUrl(event, '[CODIGO_ACCESO_SUMA+]') }} size="small">Código de acceso SUMA+</Button>
                                                <Button onClick={(event) => { this.agregarValorUrl(event, '[CODIGO_SOLICITUD]') }} size="small">Código de la solicitud</Button>
                                                <Button onClick={(event) => { this.agregarValorUrl(event, '.com') }} size="small">.com</Button>
                                                <Button onClick={(event) => { this.agregarValorUrl(event, '.co') }} size="small">.co</Button>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <div className="sub-titulo-seccion">
                                        <label>Dependencia</label>
                                        <div className="separador-horizontal-completo02 margen-bottom-20"></div>
                                    </div>

                                    <Row className={'margen-bottom-20 ' + (this.state.keyAutoComplete === null ? "row-error-autocomplete" : "")}>
                                        <Col xs={24} sm={{ span: 6, offset: 0 }}>
                                            <label>Selecciona la dependencia</label>
                                        </Col>
                                        <Col xs={24} sm={{ span: 18, offset: 0 }}>
                                            <AutoComplete
                                                style={{ 'width': '100%' }}
                                                options={opciones}
                                                onSelect={(value, item) => { this.onSelectAutoComplete(item) }}
                                                onSearch={this.buscar}
                                                onChange={this.cambioAutoComplete}
                                                defaultValue={dependencia.dependencia}
                                            >
                                                <Input.Search size="large" enterButton placeholder={'Buscar por nombre de la dependencia o entidad'} />
                                            </AutoComplete>
                                        </Col>
                                    </Row>

                                    <div className="sub-titulo-seccion">
                                        <label>Asignación de roles</label>
                                        <div className="separador-horizontal-completo02 margen-bottom-20"></div>
                                    </div>



                                    <Transfer
                                        dataSource={this.state.roles}
                                        className={"transfer-lista"}
                                        showSearch
                                        targetKeys={this.state.rolesSeleccionados}
                                        render={this.renderItemRol}
                                        onChange={(targetKeys, direction, moveKeys) => {
                                            this.setState({ rolesSeleccionados: targetKeys });
                                        }}
                                        filterOption={(inputValue, item) => {
                                            let val = inputValue.toLocaleLowerCase();
                                            let rol = item.rol ? item.rol.toLocaleLowerCase().indexOf(val) !== -1 : false;
                                            let tipoUsuario = item.tipoUsuario ? item.tipoUsuario.toLocaleLowerCase().indexOf(val) !== -1 : false;
                                            let dependencia = item.dependencia ? item.dependencia.toLocaleLowerCase().indexOf(val) !== -1 : false;
                                            return rol || tipoUsuario || dependencia;
                                        }}
                                        disabled={this.state.cargandoRoles}
                                    />
                                    <br /><br />

                                    <Row>
                                        <Col xs={24}>
                                            <Form.Item wrapperCol={{ span: 24, offset: 0 }}>
                                                <Button type="primary"
                                                    htmlType="submit"
                                                    loading={cargando}>
                                                    Guardar
                                                </Button>
                                                {!opcionDependencia ?
                                                    <>
                                                        &nbsp;&nbsp;
                                                        <Popconfirm title={'Confirma cancelar la ' + (opcionDependencia ? 'edición' : 'creación') + ' de la opción dependencia?'}
                                                            okText="Si"
                                                            cancelText="No"
                                                            onConfirm={() => { this.irListadoOpcionesDependencia(contexto) }}>
                                                            <Button icon={<CloseOutlined />}>Cancelar</Button>
                                                        </Popconfirm>
                                                    </> : 
                                                    <>
                                                        &nbsp;&nbsp;
                                                        <Button className="boton-opcion"
                                                            icon={<CloseOutlined style={{ color: '#c90a00' }} />}
                                                            onClick={(e) => {
                                                                this.setSumaState({ cargando: true });
                                                                this.props.callbackValidarEliminarOpcionDependencia( 
                                                                    opcionDependencia.id,
                                                                    idDependenciaTipoSol,
                                                                    () => {
                                                                        this.setSumaState({ cargando: false });
                                                                    });
                                                            }}>
                                                            Eliminar Opción
                                                        </Button>
                                                    </> }
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Spin>

                            <Modal title="Selecciona el ícono a usar" visible={this.state.selectorIcono} className={"modal-selector-icono"}
                                onCancel={() => { this.setSumaState({ selectorIcono: false }); }}
                                footer={[<Button key="cancel" onClick={() => { this.setSumaState({ selectorIcono: false }); }}>Cancelar</Button>]}>
                                {this.state.iconosAntD.map((icono, index) => (
                                    <Tooltip key={index} title={icono}>
                                        <Button type="default" className={"boton-icono-lista"}
                                            icon={<Utilidades.IconoAntDesign type={icono} />}
                                            onClick={() => { this.setSumaState({ icono: icono, selectorIcono: false }); }} />
                                    </Tooltip>
                                ))}
                            </Modal>
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:ADMOPCDEPSOL]</label>) : (<></>))
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(AdministrarOpcionDependenciaSolicitud));