import React, { Component } from 'react';
import SumaContexto from '../../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Modal, Spin, Row, Col, Form, Input, Select, Button, 
        Popconfirm, Tooltip, Transfer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import * as AntDesignIconos from '@ant-design/icons';
import ServicioUsuarios from '../../../servicios/usuarios/ServicioUsuarios'
import ServicioDatosConstantes from '../../../servicios/ServicioDatosConstantes'
import ServicioAdministracion from '../../../servicios/ServicioAdministracion'
import ServicioEntidad from '../../../servicios/ServicioEntidad'
import queryString from 'query-string';
import Utilidades from '../../../utilidades/Utilidades';
import Constantes from '../../../utilidades/Constantes';
import Validadores from '../../../utilidades/Validadores';



class AdministrarOpcionDependenciaRegistrarSolicitud extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        tiposSolicitud: []
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
        this.servicioEntidad = new ServicioEntidad(props);
        this.onSuccess = props.onSuccess;
        this.textAreaUrlRef = React.createRef();
    }

    formRef = React.createRef();
    

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        let idOpcDependencia;
        let idDependencia;

        if (urlparametros.id !== undefined && !isNaN(parseInt(urlparametros.id))) {
            idOpcDependencia = parseInt(urlparametros.id);
        }
        if (urlparametros.idDep !== undefined && !isNaN(parseInt(urlparametros.idDep))) {
            idDependencia = parseInt(urlparametros.idDep);
        }

        //let usr = await this.servicioAdministracion.usuarioValido();
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        //console.log('usr',usr);
        if( usr.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id ){
            if( usr.usuarioDependencia && usr.usuarioDependencia.dependencia && usr.usuarioDependencia.dependencia.id ){
                idDependencia = usr.usuarioDependencia.dependencia.id;
            }
        }

        if (usr !== null && ( idOpcDependencia || idDependencia ) ) {
            
            let iconosAntD = [];            
            for (const property in AntDesignIconos) {
                if( property.includes('Outlined') || property.includes('Filled') ){
                    iconosAntD.push( property );
                }
            }

            this.setSumaState({ idDependencia: idDependencia, 
                                idOpcDependencia:idOpcDependencia,
                                iconosAntD: iconosAntD});

            let opcionDependencia;
            if ( idOpcDependencia ) {
                // Se consulta la opción dependencia y se carga (Esto es en modo Edición opción dependencia)
                opcionDependencia = await this.servicioAdministracion.obtenerOpcionDependencia( idOpcDependencia );
                if ( opcionDependencia && opcionDependencia.tipoOpcion.id === Constantes.ENUMS.TIPO_OPCION.REGISTRAR_SOLICITUD.id ) {
                    this.setSumaState({componente: this.ENUM_COMPONENTE.VER, opcionDependencia, dependencia: opcionDependencia.dependencia});
                    //console.log('opcionDependencia',opcionDependencia);
                    this.formRef.current.setFieldsValue({
                        'opcionDependencia' :   opcionDependencia.opcionDependencia,
                        'url':                  opcionDependencia.url,
                        'tipoSolicitud':  opcionDependencia.tipoSolicitud.id.toString(),
                        
                    });

                    idDependencia = opcionDependencia.dependencia.id;

                    this.setSumaState({ idDependencia: idDependencia,
                        rolesSeleccionados: opcionDependencia.roles,});
                    

                } else {
                    this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
                }
            } else {
                if( idDependencia ){
                    let respuestaApi = await this.servicioEntidad.obtenerDependenciaPromise( idDependencia );
                    let dependencia = respuestaApi.resultado;
                    if ( dependencia ) {
                        this.setSumaState({ dependencia });
                    }
                }
                this.setSumaState({ componente: this.ENUM_COMPONENTE.VER });
            }

            if(idDependencia){
                let tiposSolicitud = await this.servicioAdministracion.obtenerTipoSolicitudes( idDependencia );
                let roles = await this.servicioAdministracion.obtenerRolesTipoUsuarioPersonaNaturalJuridica();
                this.setSumaState({ tiposSolicitud, roles });
            }

            this.setSumaState({ cargando: false }); 

        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    irListadoOpcionesDependencia( contexto ) {
        let url = !this.props.urlListadoOpciones 
        ? ( '/dependencia-opciones?id=' + contexto.state.idDependencia ) 
        : this.props.urlListadoOpciones;

        contexto.historyLocal.push(url);
    }


    errorFormularioIncompleto = errorInfo => {
        Modal.warn({ content: "Por favor diligencia la información solicitada para la opción dependencia" });
    };

    formularioCompleto = (values) => {
        return new Promise(resolve => {
            
            let opcionDependencia = {
                opcionDependencia: values.opcionDependencia.trim(),
                tipoSolicitud: { id: parseInt(values.tipoSolicitud) },
                url: values.url.trim(),
                dependencia: { id: this.state.idDependencia },
                tipoOpcion: { id: Constantes.ENUMS.TIPO_OPCION.REGISTRAR_SOLICITUD.id }
            };

            //console.log('opcionDependencia',opcionDependencia);
            //console.log('roles',this.state.rolesSeleccionados);

            opcionDependencia.roles = this.state.rolesSeleccionados;
            resolve(opcionDependencia);
        }).then(async ( opcionDependencia ) => {
            
            this.setSumaState({cargando: true});

            if ( !this.state.opcionDependencia ) {
                // Opción Dependencia Nueva
                //opcionDependencia.dependencia = { id: this.state.idDependencia };
                //opcionDependencia.tipoOpcion = { id: Constantes.ENUMS.TIPO_OPCION.REGISTRAR_SOLICITUD.id };


                let respuestaApi = await this.servicioAdministracion.crearOpcionDependencia( opcionDependencia );
                if (respuestaApi) {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id:
                            this.irListadoOpcionesDependencia(this);
                            Modal.success({ content: 'Opción dependencia creada satisfactoriamente.' });
                            break;
                        default:
                            Modal.error({ content: 'No fue posible crear la opción dependencia. Por favor intenta mas tarde.' });
                            break;
                    }
                }
            } else {
                // Editar Opción Dependencia 
                opcionDependencia.id = this.state.idOpcDependencia;

                let respuestaApi = await this.servicioAdministracion.actualizarOpcionDependencia( opcionDependencia );
                if (respuestaApi) {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id:
                            Modal.success({ content: 'Opción dependencia actualizada satisfactoriamente.' });
                            break;   
                        default:
                            Modal.error({ content: 'No fue posible actualizar la opción dependencia. Por favor intenta mas tarde.' });
                            break;
                    }
                }

            }

            this.setSumaState({cargando: false});
            
        });

    };

    cancelarFormularioDependencia = () => {
        this.onSuccess(this.state.idDependencia, this.state.idOpcDependencia, true);
    }

    guardarCursorTextArea = (event) =>{
        this.setSumaState({ cursorInicio:event.target.selectionStart, cursorFin: event.target.selectionEnd }); 
    }

    agregarValorUrl = (event, valor) => {
        let url = this.textAreaUrlRef.current.resizableTextArea.props.value;
        let start = this.state.cursorInicio;
        let end = this.state.cursorFin;
        if( url === undefined )
            url = '';
        url = url.substr(0,start) + valor + url.substr(end);
        this.formRef.current.setFieldsValue( {'url' :  url} );
    }

    renderItemRol = item => {
        const customLabel = (
          <div className="item-transfer-rol">
            <span>Id:{item.id} - {item.rol}</span>
            <span>{item.tipoUsuario}</span>
            { item.dependencia ?<><span>{item.dependencia}</span></> : null }
          </div>
        );
    
        return {
          label: customLabel, // for displayed item
          value: item.id, // for title and filter matching
        };
    };

    
    
    render() {
        const { dependencia, opcionDependencia, componente, tiposSolicitud, cargando } = this.state;
        const { Option } = Select;

        let contexto = this;

        const layout = {
            labelCol: { // Este maneja el grid de los label
                sm: { span: 23, offset: 1 },
                lg: { span: 7, offset: 1 },
            },
            wrapperCol: { // Este maneja el grid de los componentes
                sm: { span: 23, offset: 1 },
                lg: { span: 15, offset: 1 },
            },
        };

        const labelPrimerCol ={ // Este maneja de forma especial el grid de las primeras columnas
                sm: { span: 23, offset: 1 },
                lg: { span: 8, offset: 0 },
        }

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>

                            <div className="titulo-seccion">
                                <label>
                                    { !opcionDependencia ? 'AGREGAR ' : ''} OPCIÓN DEPENDENCIA   ( Registrar solicitud )
                                </label>
                                <div className="separador-horizontal-completo margen-bottom-10"></div>
                            </div>

                            
                            <Spin tip={'por favor espera...'} size="large" spinning={cargando}>

                                

                                <Form
                                    {...layout}
                                    ref={this.formRef}
                                    name="datos-dependencia"
                                    className="formulario-suma"
                                    scrollToFirstError
                                    onFinish={ this.formularioCompleto }
                                    onFinishFailed={ this.errorFormularioIncompleto }
                                >

                                    <div className="sub-titulo-seccion">
                                        <label>Opcion dependencia {dependencia ? (' - ' + dependencia.dependencia) : ''}</label>
                                        <div className="separador-horizontal-completo02 margen-bottom-20"></div>
                                    </div>

                                    <Form.Item label="id" name="id" noStyle>
                                        <Input type="hidden" />
                                    </Form.Item>

                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Opción dependencia" name="opcionDependencia"
                                                rules={[
                                                    { required: true, message: 'Ingresa el nombre de la opción dependencia', },
                                                    { min: 3, message: 'No puede ser menor a 3 caracteres', },
                                                    { max: 255, message: 'No puede ser mayor a 255 caracteres', },
                                                    ({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            if (value && !Validadores.validarAlfaNumercioConEspacio( value ).success ) {
                                                                return Promise.reject('Ingresa un texto alfanumérico');
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    }),
                                                ]}
                                                labelCol={labelPrimerCol}
                                            >
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item  label="Tipo de solicitud" name="tipoSolicitud" 
                                                        rules=  {[{ required: true, message: 'Selecciona el estado',},]}>
                                                <Select>
                                                    {tiposSolicitud.map( tipoSolicitud => (
                                                        <Option key={ tipoSolicitud.id }>{ tipoSolicitud.solicitud }</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col xs={24}>
                                            <Form.Item label="Url" name="url" className="text-area-etiquetas-entrada"
                                                labelCol={{ span: 24, offset: 0 }}
                                                wrapperCol={{ span: 24, offset: 0 }}
                                                rules={[
                                                    { required: true, message: 'Ingresa la url de redirección hacia el aplicativo web externo', },
                                                    { min: 7, message: 'No puede ser menor a 7 caracteres', },
                                                    { max: 2000, message: 'No puede ser mayor a 2000 caracteres', },
                                                ]}>

                                                <Input.TextArea ref={this.textAreaUrlRef} rows={4} 
                                                                onChange={this.guardarCursorTextArea}
                                                                onClick={this.guardarCursorTextArea}
                                                                onKeyUp = {this.guardarCursorTextArea}
                                                                onBlur={this.guardarCursorTextArea}/>

                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Row className="text-area-etiquetas">
                                                <Button onClick={(event)=>{ this.agregarValorUrl(event,'https://')}} size="small">https://</Button>
                                                <Button onClick={(event)=>{ this.agregarValorUrl(event,'http://')}} size="small">http://</Button>
                                                <Button onClick={(event)=>{ this.agregarValorUrl(event,'www.')}} size="small">www.</Button>
                                                <Button onClick={(event)=>{ this.agregarValorUrl(event,'[CODIGO_ACCESO_SUMA+]')}} size="small">Código de acceso SUMA+</Button>
                                                <Button onClick={(event)=>{ this.agregarValorUrl(event,'.com')}} size="small">.com</Button>
                                                <Button onClick={(event)=>{ this.agregarValorUrl(event,'.co')}} size="small">.co</Button>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <div className="sub-titulo-seccion">
                                        <label>Asignación de roles</label>
                                        <div className="separador-horizontal-completo02 margen-bottom-20"></div>
                                    </div>



                                    <Transfer
                                        dataSource={this.state.roles}
                                        className={"transfer-lista"}
                                        showSearch
                                        targetKeys={this.state.rolesSeleccionados}
                                        render={this.renderItemRol}
                                        onChange={(targetKeys, direction, moveKeys) => {
                                            this.setState({ rolesSeleccionados:targetKeys });
                                        }}
                                        filterOption={(inputValue, item) =>{
                                            let val = inputValue.toLocaleLowerCase();
                                            let rol = item.rol ? item.rol.toLocaleLowerCase().indexOf(val) !== -1 : false;
                                            let tipoUsuario = item.tipoUsuario ? item.tipoUsuario.toLocaleLowerCase().indexOf(val) !== -1 : false;
                                            let dependencia = item.dependencia ? item.dependencia.toLocaleLowerCase().indexOf(val) !== -1 : false;
                                            return  rol || tipoUsuario || dependencia;
                                        }}
                                    />
                                    <br/><br/>
                                    
                                    <Row>
                                        <Col xs={24}>
                                            <Form.Item wrapperCol={{ span: 24, offset: 0 }}>
                                                <Button type="primary"
                                                    htmlType="submit"
                                                    loading={cargando}>
                                                    Guardar
                                                </Button>
                                                {!opcionDependencia ?
                                                <>
                                                    &nbsp;&nbsp;
                                                    <Popconfirm title={'Confirma cancelar la ' + (opcionDependencia ? 'edición' : 'creación') + ' de la opción dependencia?'} 
                                                                okText="Si"
                                                                cancelText="No" 
                                                                onConfirm={() => {this.irListadoOpcionesDependencia(contexto)}}>
                                                        <Button icon={<CloseOutlined/>}>Cancelar</Button>
                                                    </Popconfirm>
                                                </> : 
                                                <>
                                                    &nbsp;&nbsp;
                                                    <Button className="boton-opcion"
                                                        icon={<CloseOutlined style={{ color: '#c90a00' }} />}
                                                        onClick={(e) => {
                                                            this.setSumaState({ cargando: true });
                                                            this.props.callbackValidarEliminarOpcionDependencia( 
                                                                opcionDependencia.id,
                                                                dependencia.id,
                                                                () => {
                                                                    this.setSumaState({ cargando: false });
                                                                });
                                                        }}>
                                                        Eliminar Opción
                                                    </Button>
                                                </> }
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Spin>
                            
                            <Modal  title="Selecciona el ícono a usar" visible={this.state.selectorIcono} className={"modal-selector-icono"}
                                    onCancel={()=>{this.setSumaState({ selectorIcono: false});}} 
                                    footer={[<Button key="cancel" onClick={()=>{this.setSumaState({ selectorIcono: false});}}>Cancelar</Button>]}>
                                {this.state.iconosAntD.map((icono,index) => (
                                    <Tooltip key={index} title={icono}>
                                        <Button type="default" className={"boton-icono-lista"} 
                                                icon={<Utilidades.IconoAntDesign type={icono} />} 
                                                onClick={()=>{this.setSumaState({ icono:icono, selectorIcono: false});}}/>
                                    </Tooltip>
                                ))}
                            </Modal>
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:ADMOPCDEPREGSOL]</label>) : (<></>))
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(AdministrarOpcionDependenciaRegistrarSolicitud));