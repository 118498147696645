import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Spin, Form, Col, Row, Checkbox, Button, Select, Popconfirm, Modal } from 'antd';
import ServicioArchivos from '../servicios/archivos/ServicioArchivos'
import ServicioSolicitud from '../servicios/ServicioSolicitud'
import { withCookies } from 'react-cookie';
import { CloseOutlined } from '@ant-design/icons';


const { Option } = Select;

class RelacionTipoSolicitudDocumento extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        cargando: true,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        contenidoVisual: undefined
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioArchivos = new ServicioArchivos(props);
        this.servicioSolicitud = new ServicioSolicitud(props);
    }

    formRef = React.createRef();

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let tiposArchivosPN = await this.servicioArchivos.obtenerTipoArchivoPersonaNatural();
        let tiposArchivosPJ = await this.servicioArchivos.obtenerTipoArchivoPersonaJuridica();
        let tiposSolicitud = await this.servicioSolicitud.obtenerTiposDeSolicitudPromise();
        console.log('tiposArchivosPN', tiposArchivosPN);
        console.log('tiposArchivosPJ', tiposArchivosPJ);
        console.log('tiposSolicitud', tiposSolicitud);

        this.setSumaState({
            componente: this.ENUM_COMPONENTE.VER, cargando: false,
            tiposArchivosPN, tiposArchivosPJ, tiposSolicitud
        });

    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    cambioSeleccionTipoSolicitud = async (idTipoSolicitud) => {
        this.setSumaState({ cargando: true });
        let tipos_archivos = [];
        let tipoSolicitudArchivos = undefined;
        let form = this.formRef.current;

        if (idTipoSolicitud) {
            tipoSolicitudArchivos = await this.servicioArchivos.obtenerTipoSolicitudArchivo(idTipoSolicitud);
            for (let tsa of tipoSolicitudArchivos) {
                if( tsa.obligatorio ){
                    tipos_archivos.push(tsa.idTipoArchivo);
                }
            }
        }

        form.setFieldsValue({
            'tipos-archivos': tipos_archivos,
        });
        this.setSumaState({ cargando: false, tipoSolicitudArchivos, idTipoSolicitud });
    }

    cancelar = () => {
        let form = this.formRef.current;
        form.setFieldsValue({
            'tipo-solicitud': undefined,
        });
        this.cambioSeleccionTipoSolicitud();
    }

    formularioCompleto = (values) => {
        return new Promise(resolve => {
            let tipoSolicitudArchivos = this.state.tipoSolicitudArchivos;
            let tiposArchivos = [...values['tipos-archivos']];

            console.log('values', values);
            console.log('tipoSolicitudArchivos', tipoSolicitudArchivos);
            console.log('tiposArchivos', tiposArchivos);

            for( let tipoSolArchi of tipoSolicitudArchivos ){
                tipoSolArchi.obligatorio = false;
                for( let i = 0;  i < tiposArchivos.length; i++){
                    let idTipoArchivo = tiposArchivos[i];
                    if( idTipoArchivo === tipoSolArchi.idTipoArchivo ){
                        tipoSolArchi.obligatorio = true;
                        tiposArchivos.splice(i,1);
                        i--;
                    }
                }
            }

            // Crea los registros que faltan
            for( let i = 0;  i < tiposArchivos.length; i++){
                let idTipoArchivo = tiposArchivos[i];
                tipoSolicitudArchivos.push({ idTipoArchivo, idTipoSolicitud:this.state.idTipoSolicitud, obligatorio: true});
            }

            console.log('---------------------------');
            console.log('tipoSolicitudArchivos', tipoSolicitudArchivos);

            resolve(tipoSolicitudArchivos);
        }).then(async (tipoSolicitudArchivos) => {

            this.setSumaState({ cargando: true });

            
            let satisfactorio = await this.servicioArchivos.guardarTipoSolicitudArchivos( tipoSolicitudArchivos );
            if( satisfactorio ){
                // Se vuelven a cargar las opciones
                this.cambioSeleccionTipoSolicitud( this.state.idTipoSolicitud );
                Modal.success({ content: 'Datos guardados satisfactoriamente' });
            }else{
                Modal.error({ content: 'No fue posible guardar los datos en relación del tipo de solicitud con el archivo.' });
            }
            
            this.setSumaState({ cargando: false });

        });

    };


    render() {
        const { componente, tiposArchivosPN, tiposArchivosPJ, tiposSolicitud } = this.state;

        const layout = {
            labelCol: { // Este maneja el grid de los label
                sm: { span: 23, offset: 1 },
                lg: { span: 7, offset: 1 },
            },
            wrapperCol: { // Este maneja el grid de los componentes
                sm: { span: 23, offset: 1 },
                lg: { span: 15, offset: 1 },
            },
        };

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>
                            <div className="relacion-tipo-solicitud-documento">
                                <Form
                                    {...layout}
                                    ref={this.formRef}
                                    name="formulario-relacion-tipo-sol-archivo"
                                    className="formulario-suma"
                                    scrollToFirstError
                                    onFinish={this.formularioCompleto}
                                >


                                    {/*<Form.Item label="id" name="id" noStyle>
                                        <Input type="hidden" />
                                    </Form.Item>*/}

                                    <Form.Item
                                        name="tipo-solicitud"
                                        label="Tipo de solicitud"
                                        rules={[{ required: true, message: 'Primero debes seleccionar el tipo de solicitud' },]}
                                    >
                                        <Select
                                            placeholder="Selecciona un tipo de solicitud"
                                            onChange={this.cambioSeleccionTipoSolicitud}

                                        >
                                            {tiposSolicitud.map(tipoSolicitud => (
                                                <Option key={tipoSolicitud.id} value={tipoSolicitud.id}>{tipoSolicitud.solicitud}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item name="tipos-archivos"
                                        labelCol={{ span: 24, offset: 0 }}
                                        wrapperCol={{ span: 24, offset: 0 }}>

                                        <Checkbox.Group style={{ width: '100%' }}>

                                            <React.Fragment>
                                                <div className="sub-titulo-seccion">
                                                    <label>Persona natural</label>
                                                    <div className="separador-horizontal-completo02 margen-bottom-5"></div>
                                                </div>
                                                <Row className="margen-bottom-10">
                                                    <Col xs={9} sm={4} lg={3} className="alinear-centro">
                                                        <label className="bold">Obligatorio</label>
                                                    </Col>
                                                    <Col xs={15} sm={18} lg={12}>
                                                        <label className="bold">Archivo</label>
                                                    </Col>
                                                </Row>
                                                {tiposArchivosPN.map(tipoArchivo => (
                                                    <Row key={tipoArchivo.id}>
                                                        <Col xs={9} sm={4} lg={3} className="alinear-centro">
                                                            <Checkbox value={tipoArchivo.id} />
                                                        </Col>
                                                        <Col xs={15} sm={18} lg={12}>
                                                            {tipoArchivo.nombreArchivo}
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <br />
                                            </React.Fragment>

                                            <React.Fragment>
                                                <div className="sub-titulo-seccion">
                                                    <label>Persona juridica</label>
                                                    <div className="separador-horizontal-completo02 margen-bottom-5"></div>
                                                </div>
                                                <Row className="margen-bottom-10">
                                                    <Col xs={9} sm={4} lg={3} className="alinear-centro">
                                                        <label className="bold">Obligatorio</label>
                                                    </Col>
                                                    <Col xs={15} sm={18} lg={12}>
                                                        <label className="bold">Archivo</label>
                                                    </Col>
                                                </Row>
                                                {tiposArchivosPJ.map(tipoArchivo => (
                                                    <Row key={tipoArchivo.id}>
                                                        <Col xs={9} sm={4} lg={3} className="alinear-centro">
                                                            <Checkbox value={tipoArchivo.id} />
                                                        </Col>
                                                        <Col xs={15} sm={18} lg={12}>
                                                            {tipoArchivo.nombreArchivo}
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <br />
                                            </React.Fragment>

                                        </Checkbox.Group>
                                    </Form.Item>


                                    <br /><br />

                                    <Row>
                                        <Col xs={24}>
                                            <Form.Item wrapperCol={{ span: 24, offset: 0 }}>

                                                <Popconfirm title={'Confirma guardar los cambios efectuados?'}
                                                    okText="Si"
                                                    cancelText="No"
                                                    okButtonProps={{ form: 'formulario-relacion-tipo-sol-archivo', key: 'submit', htmlType: 'submit' }}>
                                                    <Button type="primary" htmlType="submit">
                                                        Guardar
                                                    </Button>
                                                </Popconfirm>

                                                &nbsp;&nbsp;
                                                <Popconfirm title={'Confirma cancelar la edición?'}
                                                    okText="Si"
                                                    cancelText="No"
                                                    onConfirm={this.cancelar}>
                                                    <Button icon={<CloseOutlined />}>
                                                        Cancelar
                                                    </Button>
                                                </Popconfirm>

                                            </Form.Item>

                                        </Col>
                                    </Row>
                                </Form>

                            </div>
                        </Spin>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:RelacionTipoSolicitudDocumento]</label>) : (<></>))
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(RelacionTipoSolicitudDocumento));