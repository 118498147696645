import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Button, Row, Col, Spin, Tooltip, Tag, Popconfirm } from 'antd';
import { MailOutlined, EditOutlined, StopOutlined, CheckOutlined, ReloadOutlined, UserSwitchOutlined, UnlockOutlined } from '@ant-design/icons';
import Constantes from '../utilidades/Constantes'
import ServicioDatosConstantes from '../servicios/ServicioDatosConstantes'
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import ServicioPais from '../servicios/ServicioPais'
import ServicioAdministracion from '../servicios/ServicioAdministracion'
import ServicioPersonaJuridica from '../servicios/usuarios/ServicioPersonaJuridica'
import queryString from 'query-string';
import Utilidades from '../utilidades/Utilidades'
import Validadores from '../utilidades/Validadores';


class VerUsuario extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        cargando: true,
        idUsuario: undefined,

        componente: this.ENUM_COMPONENTE.CARGANDO,
        usuario: undefined,
        verUsuario: undefined,
    };

    constructor(props) {
        super(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioPais = new ServicioPais(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
        this.servicioPersonaJuridica = new ServicioPersonaJuridica(props);

        this.callbackEditarUsuario = props.callbackEditarUsuario;
        this.callbackActivarUsuario = props.callbackActivarUsuario;
        this.callbackEnviarCorreoActivacion = props.callbackEnviarCorreoActivacion;
        this.callbackEnviarCorreoCambioContrasena = props.callbackEnviarCorreoCambioContrasena;
        this.callbackContrasenaTemporalSUMAv1 = props.callbackContrasenaTemporalSUMAv1;
        this.callbackDesbloquearIntentosFallidos = props.callbackDesbloquearIntentosFallidos;
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        let idUsuario = undefined;

        if (urlparametros.id !== undefined && !isNaN(parseInt(urlparametros.id))) {
            idUsuario = parseInt(urlparametros.id);
        }
        this.setSumaState({ idUsuario: idUsuario });

        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr !== null) {
            this.setSumaState({ usuario: usr, componente: this.ENUM_COMPONENTE.VER, cargando: true });
            // Valida que tenga la accion habilitada VER
            let habilitado = await this.servicioUsuarios.accionHabilitadaPromesa(Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.VER_USUARIO.id);
            if (habilitado && habilitado.resultado) {
                let verUsuario = await this.servicioAdministracion.obtenerUsuario(idUsuario);
                if (verUsuario) {
                    let pais = verUsuario.datoPersonal.ciudad ? await this.servicioPais.obtenerPaisPromesa(verUsuario.datoPersonal.ciudad.idPais) : 0;
                    let departamento = verUsuario.datoPersonal.ciudad ? await this.servicioPais.obtenerDepartamentoPromesa(verUsuario.datoPersonal.ciudad.idDepartamento) : 0;
                    if (pais && departamento) {
                        verUsuario.pais = pais.pais;
                        verUsuario.departamento = departamento.departamento;
                    }
                    if (verUsuario.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.PERSONA_JURIDICA.id && verUsuario.usuarioPersonaJuridica) {
                        let personaJuridica = await this.servicioPersonaJuridica.obtenerPersonaJuridica(verUsuario.usuarioPersonaJuridica.idPersonaJuridica);
                        verUsuario.usuarioPersonaJuridica.personaJuridica = personaJuridica;
                    }

                    //console.log('verUsuario', verUsuario);
                    this.setSumaState({ cargando: false, verUsuario: verUsuario, idTipoUsuario: verUsuario.tipoUsuario.id });
                }
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    render() {
        const { verUsuario, componente, usuario, idTipoUsuario } = this.state;

        const wrapperCol = {
            xs: { span: 21, offset: 1 },
            sm: { span: 11, offset: 1 },
        };

        const wrapperCol01 = {
            sm: { span: 22, offset: 1 },
            xs: { span: 22, offset: 1 }
        };

        const wrapperCol02 = {
            sm: { span: 6 },
            xs: { span: 24 }
        };

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>
                                <Row>
                                    <Col>
                                        <h3 className="titulo inline-block">Usuario:</h3> &nbsp;&nbsp;&nbsp;
                                        <h3 className="inline-block">{verUsuario ? verUsuario.datoPersonal.nombreCompleto : ""}</h3>
                                    </Col>
                                </Row>

                                <h3 className="sub-titulo-separador margen-bottom">Información usuario</h3>
                                <div className="seccion-datos">
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Usuario:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.usuario : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Id:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.id : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Tipo de usuario:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.tipoUsuario.tipoUsuario : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Estado:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <Tag color={
                                                        (verUsuario && verUsuario.estadoUsuario.id === Constantes.ENUMS.ESTADO_USUARIO.ACTIVO.id)
                                                            ?
                                                            'green'
                                                            :
                                                            (verUsuario && verUsuario.estadoUsuario.id === Constantes.ENUMS.ESTADO_USUARIO.INACTIVO.id
                                                                ?
                                                                'volcano'
                                                                :
                                                                (verUsuario && verUsuario.estadoUsuario.id === Constantes.ENUMS.ESTADO_USUARIO.CREADO.id
                                                                    ?
                                                                    'geekblue'
                                                                    :
                                                                    ''
                                                                )
                                                            )
                                                    } key={verUsuario ? verUsuario.estadoUsuario.estadoUsuario : ""}>
                                                        {verUsuario ? verUsuario.estadoUsuario.estadoUsuario.toUpperCase() : ""}
                                                    </Tag>

                                                    {verUsuario && verUsuario.cambioContrasena
                                                        ?
                                                        <Tooltip title="El usuario tiene pendiente un cambio de contraseña">
                                                            <Tag color="purple" key="Cambio Contraseña">CONTRASEÑA</Tag>
                                                        </Tooltip>
                                                        :
                                                        <></>
                                                    }

                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Rol:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.rol.rol : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Correo electrónico:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.correoElectronico : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Último inicio sesión:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.fechaUltimoLoginFormato : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Fecha de creación:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.fechaCreacionFormato : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Fecha de modificación:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.fechaModificoFormato : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Alias SUMAv1:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.aliasSumaV1 : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Aceptó terminos:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario && verUsuario.aceptoTerminoscondiciones ? "Si" : "No"}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Fecha aceptación términos:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.fechaTerminosCondicionesFormato : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Intentos fallidos login:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.intentosFallidosLogin : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Fecha usuario bloqueado:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.fechaUsuarioBloqueadoFormato : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Fecha sesión finalizada:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.fechaSesionFinalizadaFormato : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    { // --- SECCIÓN PERSONA JURÍDICA
                                        (verUsuario && verUsuario.usuarioPersonaJuridica && (verUsuario.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.PERSONA_JURIDICA.id)) ?
                                            <>
                                                <h3 className="sub-titulo-separador margen-bottom">Información persona jurídica</h3>
                                                <div className="seccion-datos">
                                                    <Row className="fila">
                                                        <Col {...wrapperCol01} >
                                                            <Row>
                                                                <Col {...wrapperCol02}>
                                                                    <label className="titulo-etiqueta">Razón social:</label>
                                                                </Col>
                                                                <Col>
                                                                    <span>{verUsuario.usuarioPersonaJuridica.personaJuridica.razonSocial}</span>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row className="fila">
                                                        <Col  {...wrapperCol}>
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <label className="titulo-etiqueta">Nit:</label>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <span>{verUsuario.usuarioPersonaJuridica.personaJuridica.nit}-{verUsuario.usuarioPersonaJuridica.personaJuridica.digitoVerificacion}</span>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col  {...wrapperCol}>
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <label className="titulo-etiqueta">Cámara de comercio:</label>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <span>{verUsuario.usuarioPersonaJuridica.personaJuridica.camaraComercio}</span>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </> : (<></>)
                                    }

                                    { // --- SECCIÓN DE LA DEPENDENCIA
                                        (verUsuario && verUsuario.usuarioDependencia && (verUsuario.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id)) ?
                                            <>
                                                <h3 className="sub-titulo-separador margen-bottom">Información dependencia</h3>
                                                <div className="seccion-datos">
                                                    <Row className="fila">
                                                        <Col {...wrapperCol01} >
                                                            <Row>
                                                                <Col {...wrapperCol02}>
                                                                    <label className="titulo-etiqueta">Dependencia:</label>
                                                                </Col>
                                                                <Col>
                                                                    <span>{verUsuario.usuarioDependencia.dependencia.dependencia}</span>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row className="fila">
                                                        <Col {...wrapperCol01} >
                                                            <Row>
                                                                <Col {...wrapperCol02}>
                                                                    <label className="titulo-etiqueta">Entidad:</label>
                                                                </Col>
                                                                <Col>
                                                                    <span>{verUsuario.usuarioDependencia.dependencia.entidad.razonSocial}</span>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row className="fila">
                                                        <Col  {...wrapperCol}>
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <label className="titulo-etiqueta">Cargo usuario:</label>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <span>{verUsuario.usuarioDependencia.cargo}</span>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </> : (<></>)
                                    }
                                </div>

                                <h3 className="sub-titulo-separador margen-bottom">Datos personales</h3>
                                <div className="seccion-datos">

                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Tipo de documento:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.datoPersonal.tipoDocumento.tipoDocumento : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Número de documento:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.datoPersonal.numeroDocumento : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Lugar de expedición:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.datoPersonal.lugarExpedicion : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Fecha de expedición:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.datoPersonal.fechaExpedicionFormatoEspanol : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Correo electrónico:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.datoPersonal.correoElectronico : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Móvil:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.datoPersonal.celular : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Teléfono:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.datoPersonal.telefono : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">País:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.pais : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>


                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Región:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.departamento : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Ciudad:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario && verUsuario.datoPersonal.ciudad ? verUsuario.datoPersonal.ciudad.ciudad : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Dirección residencia:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{verUsuario ? verUsuario.datoPersonal.direccion : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </div>


                                <h3 className="sub-titulo-separador margen-bottom margen-top">Opciones</h3>
                                <div className="seccion-datos seccion-final">

                                    {(verUsuario)
                                        ?
                                        (<>
                                            {idTipoUsuario !== Constantes.ENUMS.TIPO_USUARIO.APLICACION_CONECTADA.id ?
                                                <Button className="boton-opcion"
                                                    icon={<EditOutlined style={{ color: '#c90a00' }} />}
                                                    onClick={(e) => { this.callbackEditarUsuario(e, this.state.idUsuario) }}
                                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.EDITAR_USUARIO.id)}>
                                                    Editar
                                                </Button>
                                                : <></>}

                                            {(verUsuario.estadoUsuario.id === Constantes.ENUMS.ESTADO_USUARIO.ACTIVO.id)
                                                ?
                                                <Popconfirm title="Confirma desactivar el usuario?"
                                                    okText="Si"
                                                    cancelText="No"
                                                    onConfirm={(e) => {
                                                        this.setSumaState({ cargando: true });
                                                        this.callbackActivarUsuario(
                                                            e,
                                                            verUsuario.id,
                                                            verUsuario.datoPersonal.nombreCompleto,
                                                            false,
                                                            () => {
                                                                this.setSumaState({ cargando: false });
                                                                this.cargarDatosIniciales();
                                                            });

                                                    }}
                                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.DESACTIVAR_USUARIO.id)}
                                                >
                                                    <Button className="boton-opcion"
                                                        icon={<StopOutlined style={{ color: '#c90a00' }} />}
                                                        disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.DESACTIVAR_USUARIO.id)}>
                                                        Desactivar
                                                    </Button>
                                                </Popconfirm>
                                                :
                                                <></>
                                            }

                                            {(verUsuario.estadoUsuario.id === Constantes.ENUMS.ESTADO_USUARIO.INACTIVO.id)
                                                ?
                                                <Popconfirm title="Confirma activar el sub usuario?"
                                                    okText="Si"
                                                    cancelText="No"
                                                    onConfirm={(e) => {
                                                        this.setSumaState({ cargando: true });
                                                        this.callbackActivarUsuario(
                                                            e,
                                                            verUsuario.id,
                                                            verUsuario.datoPersonal.nombreCompleto,
                                                            true,
                                                            () => {
                                                                this.setSumaState({ cargando: false });
                                                                this.cargarDatosIniciales();
                                                            });

                                                    }}
                                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.ACTIVAR_USUARIO.id)}
                                                >
                                                    <Button className="boton-opcion"
                                                        icon={<CheckOutlined style={{ color: '#c90a00' }} />}
                                                        disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.ACTIVAR_USUARIO.id)}>
                                                        Activar
                                                    </Button>
                                                </Popconfirm>
                                                :
                                                <></>
                                            }

                                            {(verUsuario.estadoUsuario.id === Constantes.ENUMS.ESTADO_USUARIO.BLOQUEADO_INTENTOS_LOGIN.id)
                                                ?
                                                <Popconfirm title="Confirma desbloquear intentos fallidos?"
                                                    okText="Si"
                                                    cancelText="No"
                                                    onConfirm={(e) => {
                                                        this.setSumaState({ cargando: true });
                                                        this.callbackDesbloquearIntentosFallidos(
                                                            e,
                                                            verUsuario.id,
                                                            verUsuario.datoPersonal.nombreCompleto,
                                                            () => {
                                                                this.setSumaState({ cargando: false });
                                                                this.cargarDatosIniciales();
                                                            });

                                                    }}
                                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.DESBLOQUEAR_INTENTOS_FALLIDOS_INICIO_SESION.id)}
                                                >
                                                    <Button className="boton-opcion"
                                                        icon={<UnlockOutlined style={{ color: '#c90a00' }} />}
                                                        disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.DESBLOQUEAR_INTENTOS_FALLIDOS_INICIO_SESION.id)}>
                                                        Desbloquear intentos fallidos
                                                    </Button>
                                                </Popconfirm>
                                                :
                                                <></>
                                            }

                                            {(verUsuario.estadoUsuario.id === Constantes.ENUMS.ESTADO_USUARIO.CREADO.id &&
                                                idTipoUsuario !== Constantes.ENUMS.TIPO_USUARIO.APLICACION_CONECTADA.id
                                            )
                                                ?
                                                (<Popconfirm title="Confirma enviar el correo de activación?"
                                                    okText="Si"
                                                    cancelText="No"
                                                    onConfirm={(e) => {
                                                        this.setSumaState({ cargando: true });
                                                        this.callbackEnviarCorreoActivacion(
                                                            e,
                                                            verUsuario.id,
                                                            verUsuario.datoPersonal.nombreCompleto,
                                                            () => {
                                                                this.setSumaState({ cargando: false });
                                                                this.cargarDatosIniciales();
                                                            });
                                                    }}
                                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.ENVIAR_CORREO_ACTIVACION_USUARIO.id)}
                                                >
                                                    <Button className="boton-opcion"
                                                        icon={<MailOutlined style={{ color: '#c90a00' }} />}
                                                        disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.ENVIAR_CORREO_ACTIVACION_USUARIO.id)}>
                                                        Enviar correo activación
                                                    </Button>
                                                </Popconfirm>)
                                                :
                                                (
                                                    (
                                                        idTipoUsuario !== Constantes.ENUMS.TIPO_USUARIO.APLICACION_CONECTADA.id &&
                                                        !Validadores.usuarioSUMAv1( verUsuario ) &&
                                                        !verUsuario.autenticaContraLDAP
                                                    ) ?
                                                        (
                                                            <Popconfirm title="Confirma enviar el correo para cambio de contraseña?"
                                                                okText="Si"
                                                                cancelText="No"
                                                                onConfirm={(e) => {
                                                                    this.setSumaState({ cargando: true });
                                                                    this.callbackEnviarCorreoCambioContrasena(
                                                                        e,
                                                                        verUsuario.id,
                                                                        verUsuario.datoPersonal.nombreCompleto,
                                                                        () => {
                                                                            this.setSumaState({ cargando: false });
                                                                            this.cargarDatosIniciales();
                                                                        });
                                                                }}
                                                                disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.ENVIAR_CORREO_CAMBIO_CONTRASENA_USUARIO.id)}
                                                            >
                                                                <Button className="boton-opcion"
                                                                    icon={<ReloadOutlined style={{ color: '#c90a00' }} />}
                                                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.ENVIAR_CORREO_CAMBIO_CONTRASENA_USUARIO.id)}>
                                                                    Cambiar contraseña
                                                                </Button>
                                                            </Popconfirm>
                                                        ) : (<></>)
                                                )
                                            }
                                            {Validadores.usuarioSUMAv1( verUsuario ) ?
                                                <Popconfirm title="¿Confirma asignar una contraseña temporal al usuario?"
                                                    okText="Si"
                                                    cancelText="No"
                                                    onConfirm={(e) => {
                                                        this.setSumaState({ cargando: true });
                                                        this.callbackContrasenaTemporalSUMAv1(
                                                            e,
                                                            verUsuario.id,
                                                            verUsuario.datoPersonal.nombreCompleto,
                                                            () => {
                                                                this.setSumaState({ cargando: false });
                                                                this.cargarDatosIniciales();
                                                            });
                                                    }}
                                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.CONTRASENA_TEMPORAL_USUARIO_SUMAV1.id)}
                                                >
                                                    <Button className="boton-opcion"
                                                        icon={<UserSwitchOutlined style={{ color: '#c90a00' }} />}
                                                        disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.CONTRASENA_TEMPORAL_USUARIO_SUMAV1.id)}>
                                                        Contraseña temporal SUMAv1
                                                    </Button>
                                                </Popconfirm> : null}

                                            {verUsuario.autenticaContraLDAP ?
                                             (<p><br/>
                                                 Nota: El método de autenticación del usuario es contra el directorio activo del DADEP.</p>) 
                                             : (<></>)
                                            }
                                            
                                            
                                        </>)
                                        :
                                        (<></>)
                                    }
                                </div>

                            </Spin>
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:VUSU]</label>) : (<></>))
                    )
                }

            </div>
        );
    }
}

export default withRouter(withCookies(VerUsuario));