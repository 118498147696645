import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import SumaContexto  from '../../contexto/SumaContexto';
import Contrasena from '../crear-cuenta/Contrasena'
import queryString from 'query-string';
import ServicioUsuarios from '../../servicios/usuarios/ServicioUsuarios'
import { Spin, Modal, Alert } from 'antd';
import Constantes from '../../utilidades/Constantes'


function ValidarActivarCuenta( attr ){
    //console.log('attr',attr);
    //let urlparametros = queryString.parse( attr.urlparametros );
    //console.log('urlparametros',urlparametros);

    if( attr.cargando ){
        return (
            <>
                <div className="titulo-seccion">
                    <label>CAMBIAR CONTRASEÑA</label>
                    <div className="separador-horizontal-completo"></div>
                </div>
                <div className="cargando-pagina">
                    <Spin tip="Por favor espera..." size="large" ></Spin>
                </div>
            </>
        )
    }

    if( !attr.cambiarContrasena ){
        return( 
            <>
                <div>
                    <Alert message={attr.msgError} type="warning" />
                </div>
            </>
        );
    }else{

        return( 
            <>
                <div className="titulo-seccion">
                    <label>CAMBIAR CONTRASEÑA</label>
                    <div className="separador-horizontal-completo"></div>
                    <p className="descripcion-seccion">Puedes asignar tu contraseña con la cual podrás ingresar al portal. </p>
                    Debe ser mínimo de 8 caracteres y tener al menos:
                    <ul>
                        <li>Una letra mayúscula</li>
                        <li>Una letra minúscula</li>
                        <li>Un dígito</li>
                        <li>Alguno de los siguientes caracteres: ! @ # $ % ^ & *</li>
                    </ul>
                    
                    <br/>
                </div>
                <div className="seccion-formulario">
                    <Contrasena idUsuario={attr.idUsuario} cambioContrasena={true} token={attr.token}/>
                </div>
            </>
        );
    }
}

class CambioContrasena extends Component {

    static contextType = SumaContexto;

    state = {
        cargando:true,
        cambiarContrasena:false,
        idUsuario:0,
        msgError: null
    };

    constructor(props){
        super(props);
        this.servicioUsuarios = new ServicioUsuarios( props );
    }

    componentDidMount() {
        this._isMounted = true;
        let params = this.props.location.search;
        let urlparametros = queryString.parse( params );
        let msgError = null;
        this.enc = null;
        if(urlparametros.enc !== undefined){
            this.enc = urlparametros.enc;
            this.servicioUsuarios.usuarioConCambioDeContrasena(
                urlparametros.enc,
                ( respuestaApi )      => { 
                    switch(respuestaApi.codigo){
                        case Constantes.ENUMS_API.USUARIO.OPERACION_REALIZADA.id:
                            this.setSumaState({ idUsuario:parseInt( respuestaApi.resultado ), cargando:false, cambiarContrasena: true, msgError:null });
                            break;
                        case Constantes.ENUMS_API.USUARIO.TOKEN_EXPIRADO.id:
                            msgError = 'El enlace no se encuentra activo para cambiar la contraseña. Debes solicitar o generar un nuevo correo para cambiar la contraseña.';
                            Modal.warning({content: msgError});
                            this.setSumaState({ cargando:false, cambiarContrasena: false, msgError:msgError});
                            break;
                        case Constantes.ENUMS_API.USUARIO.SIN_OPCION_CAMBIAR_CONTRASENA.id:
                            msgError = 'El usuario ya modificó su contraseña por medio del enlace.';
                            Modal.warning({content: msgError})
                            this.setSumaState({ cargando:false, cambiarContrasena: false, msgError:msgError });
                            break;    
                        default:
                            this.setSumaState({ cargando:false, cambiarContrasena: false, msgError:'PETICIÓN NO DISPONIBLE' });
                            break;
                    }
                },
                ( errorAPI )    => { Modal.error({content:'Error de conexión. Por favor intenta mas tarde. [Cod:CC01]'}) },
            );
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

  render() {
    
    //const { usuario } = this.context

    return (
        <div className="seccion-principal seccion-flex">
          <div className="division-principal">
            <label>Activar Cuenta</label>
          </div>
          <div className="division-contenido seccion-flex-grow-1 padding-principal bkg-white">
                <ValidarActivarCuenta   cargando={this.state.cargando} 
                                        cambiarContrasena={this.state.cambiarContrasena}  
                                        idUsuario={this.state.idUsuario}
                                        msgError={this.state.msgError}
                                        token={this.enc}/>
            </div>
        </div>
      );
  }
}

export default withRouter(CambioContrasena);