import React, { Component } from 'react';
import { Menu, Avatar } from 'antd';
import {
  HomeOutlined, UsergroupDeleteOutlined, EyeOutlined,
  QuestionOutlined, UserOutlined, AppstoreOutlined,
  FormOutlined, ProfileOutlined, IdcardOutlined,
  FileDoneOutlined, InsertRowLeftOutlined, TeamOutlined,
  BankOutlined, SettingOutlined, CommentOutlined, BarChartOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import ZonaUsuarioPopUp from '../zona-usuario/ZonaUsuarioPopUp'
import SumaContexto from '../contexto/SumaContexto';
import Constantes from '../utilidades/Constantes'
import Utilidades from '../utilidades/Utilidades'

const { SubMenu } = Menu;


function MenuNormal(props) {
  return (
    <Menu onClick={props.manejadorClick} selectedKeys={[props.opcionActual]} className="menu-suma menu-opciones" mode="horizontal">
      <Menu.Item key="hogar" icon={<HomeOutlined className='icono-suma' />} />
      <Menu.Item key="que-es-suma" icon={<UsergroupDeleteOutlined className='icono-suma' />}>
        ¿Qué es SUMA?
      </Menu.Item>
      <Menu.Item key="normatividad" icon={<EyeOutlined className='icono-suma' />}>
        Normatividad
      </Menu.Item>
      <Menu.Item key="preguntas" icon={<QuestionOutlined className='icono-suma' />}>
        Preguntas frecuentes
      </Menu.Item>
      <Menu.Item key="zona-usuarios" className="menu-item-zona-usuarios" icon={<UserOutlined className='icono-suma' />}>
        Zona Usuarios
      </Menu.Item>
    </Menu>
  );
}


function MenuUsuario(props) {
  return (
    <>
      <Menu onClick={props.manejadorClick} selectedKeys={[props.opcionActual]} className="menu-suma menu-opciones" mode="horizontal">
        <SubMenu icon={<HomeOutlined className='icono-suma' />} title="SUMA" key="submenu-suma" >
          <Menu.Item key="hogar" icon={<HomeOutlined className='icono-suma' />}>
            Inicio
          </Menu.Item>
          <Menu.Item key="que-es-suma" icon={<UsergroupDeleteOutlined className='icono-suma' />}>
            ¿Qué es SUMA?
          </Menu.Item>
          <Menu.Item key="normatividad" icon={<EyeOutlined className='icono-suma' />}>
            Normatividad
          </Menu.Item>
          <Menu.Item key="preguntas" icon={<QuestionOutlined className='icono-suma' />}>
            Preguntas frecuentes
          </Menu.Item>
        </SubMenu>

        <Menu.Item key="opciones" icon={<AppstoreOutlined className='icono-suma' />}>
          Opciones
        </Menu.Item>

        {
          (props.usuario.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.PERSONA_NATURAL.id ||
            props.usuario.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.PERSONA_JURIDICA.id) ?
            (<>

              {
                (props.usuario.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.PERSONA_NATURAL.id) ?
                  (
                    <Menu.Item key="persona-natural-datos"
                      icon={<IdcardOutlined className='icono-suma' />}
                      disabled={!Utilidades.accionPermitida(props.usuario, Constantes.ENUMS_MODULOS.PERSONA_NATURAL.ACCIONES.ACCEDER_MODULO_PERSONA_NATURAL.id)}
                    >
                      Datos persona natural
                    </Menu.Item>
                  ) :
                  (
                    (props.usuario.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.PERSONA_JURIDICA.id) ?
                      (
                        <Menu.Item key="persona-juridica-datos"
                          icon={<IdcardOutlined className='icono-suma' />}
                          disabled={!Utilidades.accionPermitida(props.usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA.ACCIONES.ACCEDER_MODULO_PERSONA_JURIDICA.id)}
                        >
                          Datos persona jurídica
                        </Menu.Item>
                      ) :
                      (<></>)
                  )
              }

              <Menu.Item key="mis-solicitudes"
                icon={<ProfileOutlined className='icono-suma' />}
                disabled={!Utilidades.accionPermitida(props.usuario, Constantes.ENUMS_MODULOS.MIS_SOLICITUDES.ACCIONES.ACCEDER_MODULO_MIS_SOLICITUDES.id)}
              >
                Mis solicitudes
              </Menu.Item>

              <Menu.Item key="registrar-solicitud"
                icon={<FormOutlined className='icono-suma' />}
                disabled={!Utilidades.accionPermitida(props.usuario, Constantes.ENUMS_MODULOS.REGISTRAR_SOLICITUD.ACCIONES.ACCEDER_MODULO_REGISTRAR_SOLICITUD.id)}
              >
                Registrar solicitud
              </Menu.Item>

            </>)
            :
            (
              (props.usuario.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id) ?
                (<>
                  <Menu.Item key="dependencia-datos"
                    icon={<IdcardOutlined className='icono-suma' />}
                    disabled={!Utilidades.accionPermitida(props.usuario, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.ACCEDER_MODULO_DEPENDENCIA.id)}>
                    Datos dependencia
                  </Menu.Item>
                  <Menu.Item key="solicitudes-dependencia"
                    icon={<ProfileOutlined className='icono-suma' />}
                    disabled={!Utilidades.accionPermitida(props.usuario, Constantes.ENUMS_MODULOS.SOLICITUDES.ACCIONES.ACCEDER_MODULO_SOLICITUDES.id)}>
                    Solicitudes
                  </Menu.Item>
                  <Menu.Item key="solicitudes-asociadas"
                    icon={<FileDoneOutlined className='icono-suma' />}
                    disabled={!Utilidades.accionPermitida(props.usuario, Constantes.ENUMS_MODULOS.SOLICITUD_ASOCIADA.ACCIONES.ACCEDER_MODULO_SOLICITUD_ASOCIADA.id)}>
                    Solicitud asociada
                  </Menu.Item>

                  {(props.usuario.opcionesSUMADependencia !== undefined && props.usuario.opcionesSUMADependencia.length > 0) ?
                    (<>
                      <SubMenu icon={<InsertRowLeftOutlined className='icono-suma' />} title={"Otros"} key="submenu-otros">
                        {props.usuario.opcionesSUMADependencia.map((opcionDependencia, index) =>
                          <Menu.Item key={"NO_-_ACTIVAR_" + opcionDependencia.id}
                            icon={<Utilidades.IconoAntDesign type={opcionDependencia.icono} className='icono-suma' />}
                            onClick={(e) => { clickOpcionSuma(e, opcionDependencia.url) }} >
                            {opcionDependencia.opcionDependencia}
                          </Menu.Item>
                        )}
                      </SubMenu>
                    </>)
                    : (<></>)}
                </>)
                :
                (<></>)
            )
        }

        {props.usuario.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.ADMINISTRADOR_SUMA.id ?
          (
            <>
              {Utilidades.accionPermitida(props.usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.ACCEDER_MODULO_USUARIOS.id) ?
                (<>
                  <Menu.Item key="usuarios" icon={<TeamOutlined className='icono-suma' />}>
                    Usuarios
                  </Menu.Item>
                </>) : (<></>)}

              {Utilidades.accionPermitida(props.usuario, Constantes.ENUMS_MODULOS.ENTIDAD.ACCIONES.ACCEDER_MODULO_ENTIDAD.id) ?
                (<>
                  <Menu.Item key="entidades" icon={<BankOutlined className='icono-suma' />}>
                    Entidades
                  </Menu.Item>
                </>) : (<></>)}

              {Utilidades.accionPermitida(props.usuario, Constantes.ENUMS_MODULOS.SOLICITUDES.ACCIONES.ACCEDER_MODULO_SOLICITUDES.id) ?
                (<>
                  <Menu.Item key="solicitudes" icon={<ProfileOutlined className='icono-suma' />}>
                    Solicitudes
                  </Menu.Item>
                </>) : (<></>)}

              {Utilidades.accionPermitida(props.usuario, Constantes.ENUMS_MODULOS.COMUNICACIONES.ACCIONES.ACCEDER_MODULO_COMUNICACIONES.id) ?
                (<>
                  <Menu.Item key="comunicaciones" icon={<CommentOutlined className='icono-suma' />}>
                    Comunicaciones
                  </Menu.Item>
                </>) : (<></>)}

              {Utilidades.accionPermitida(props.usuario, Constantes.ENUMS_MODULOS.CONFIGURACION.ACCIONES.ACCEDER_MODULO_CONFIGURACION.id) ?
                (<>
                  <Menu.Item key="configuracion" icon={<SettingOutlined className='icono-suma' />}>
                    Configuración
                  </Menu.Item>
                </>) : (<></>)}

              {Utilidades.accionPermitida(props.usuario, Constantes.ENUMS_MODULOS.DOCUMENTOS.ACCIONES.ACCEDER_MODULO_DOCUMENTOS.id) ?
                (<>
                  <Menu.Item key="documentos" icon={<CopyOutlined className='icono-suma' />}>
                    Documentos
                  </Menu.Item>
                </>) : (<></>)}  

            </>
          ) : (<></>)
        }


        {Utilidades.accionPermitida(props.usuario, Constantes.ENUMS_MODULOS.REPORTES.ACCIONES.ACCEDER_MODULO_REPORTES.id) ?
          (<>
            <Menu.Item key="reportes" icon={<BarChartOutlined className='icono-suma' />}>
              Reportes
            </Menu.Item>
          </>) : (<></>)}


        {/*<SubMenu className="ant-menu-usuario" icon={<UserOutlined className='icono-suma' /> }
          title={<><Avatar icon={<UserOutlined />} src={props.imagenPerfil_sc} /> &nbsp; {props.usuario.datoPersonal.nombreCorto}</>} key="submenu-usuario">
          <Menu.Item key="mi-cuenta-inicio">Mi cuenta</Menu.Item>
          <Menu.Item className="ant-menu-salir" key="salir">Salir</Menu.Item>
        </SubMenu>*/}

        <Menu.Item key="zona-usuarios" className="menu-item-zona-usuarios" icon={<UserOutlined className='icono-suma' />}>
          Zona Usuarios
        </Menu.Item>

      </Menu>
    </>
  );
}

function MenuCuentaUsuario(props) {
  return (
    <>
      <Menu onClick={props.manejadorClick} selectedKeys={[props.opcionActual]} className="menu-suma menu-cuenta-usuario" mode="horizontal">

        <SubMenu className="ant-menu-usuario"
          key="submenu-usuario"
          icon={<UserOutlined className='icono-suma' />}
          title={
            <>
              <Avatar icon={<UserOutlined />} src={props.imagenPerfil_sc} />
              <span className="titulo-usuario">
                &nbsp;
                {props.usuario.datoPersonal.nombreCorto}
              </span>
            </>
          }
        >
          <Menu.Item key="mi-cuenta-inicio">Mi cuenta</Menu.Item>
          <Menu.Item className="ant-menu-salir" key="salir">Salir</Menu.Item>
        </SubMenu>

      </Menu>
    </>
  );
}

function clickOpcionSuma(e, url) {
  Utilidades.bloquearPantalla();
  window.location = url;
};

class MenuSuma extends Component {


  static contextType = SumaContexto;

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.zonaUsuarioPopUp = React.createRef();
    this.historyLocal = props.history;
    this.location = props.location;
    this.callbackCerrarSesion = props.callbackCerrarSesion;
    this.props.history.listen(this.manejadorCambioPath);
  }

  state = {
    current: 'hogar'
  };

  componentDidMount() {
    this._isMounted = true;
    //console.log('aqui de nuevo: ' + this.location.pathname);
    this.setSumaState({ current: this.location.pathname.substring(1) });
    Utilidades.desbloquearPantalla();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setSumaState(valores) {
    if (this._isMounted) {
      this.setState(valores);
    }
  }

  manejadorCambioPath = (location, action) => {
    if (this._isMounted) {
      //this.setSumaState({ tab: this.obtenerPestanaPorPath(location.pathname) });
      this.setSumaState({ current: location.pathname.substring(1) });
    }
  }

  irAlaRuta = (url) => {
    this.historyLocal.push('/' + url);
  }

  handleClick = e => {
    //console.log('click ', e);
    // Si es de tipo NO_-_ACTIVAR_ no se hace nada en el cambio de path
    if (Utilidades.esString(e.key) && e.key.includes("NO_-_ACTIVAR_")) {
      return;
    }

    this.setSumaState({ current: e.key });
    if (e.key === 'zona-usuarios') {
      this.zonaUsuarioPopUp.current.showModal();
    } else {
      switch (e.key) {
        case 'salir':
          this.callbackCerrarSesion();
          break;
        default:
          this.irAlaRuta(e.key);
          break;
      }
    }
  };

  render() {

    const { current } = this.state;
    const { imagenPerfil_sc } = this.context;

    //this.historyLocal = history;

    return (
      <>
        {(this.props.usuario !== null)
          ?
          <>
            <MenuUsuario manejadorClick={this.handleClick} opcionActual={current} usuario={this.props.usuario} imagenPerfil_sc={imagenPerfil_sc} />
            <MenuCuentaUsuario manejadorClick={this.handleClick} opcionActual={current} usuario={this.props.usuario} imagenPerfil_sc={imagenPerfil_sc} />
          </>
          : <MenuNormal manejadorClick={this.handleClick} opcionActual={current} />
        }
        <ZonaUsuarioPopUp ref={this.zonaUsuarioPopUp}
          callbackUsuarioLogueado={this.props.callbackUsuarioLogueado}
          usuario={this.props.usuario} />
      </>
    );
  }

}

export default withRouter(MenuSuma);