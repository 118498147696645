import { Input, Space, Button, DatePicker, Checkbox } from 'antd';
//import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import Constantes from '../../utilidades/Constantes';


const { RangePicker } = DatePicker;


class FiltroColumna extends Component {

    state = {
        filtroEtiquetas: []
    };

    constructor(props, setSumaTableState) {
        super(props);
        this.setSumaTableState = setSumaTableState;
    }

    handleSearch = (selectedKeys, confirm, dataIndex, cargarDatosTabla, clearFilters, textoEtiquetaFiltro) => {
        // Se llama el método cargarDatosTabla para volver a cargar la información de acuerdo
        // al filtro establecido
        //console.log('selectedKeys',selectedKeys);
        //console.log('dataIndex',dataIndex);
        let indexfiltroEtiqueta = -1;
        let filtrosEtiquetas = this.state.filtroEtiquetas;
        for( let i = 0; i < filtrosEtiquetas.length; i++ ){
            if( filtrosEtiquetas[i].dataIndex === dataIndex ){
                indexfiltroEtiqueta = i;
                // Si viene vacio 'selectedKeys', indica que no se está filtrando ningún valor, por ende
                // se procede a eliminar la etiqueta del filtro
                if( selectedKeys.length === 0 ){
                    filtrosEtiquetas.splice(i, 1); 
                }
                break;
            }
        }
        // Si viene lleno el campo 'selectedKeys', indica que se está filtrando un valor
        if( selectedKeys.length > 0 ){
            let contexto = this;
            if( indexfiltroEtiqueta === -1 ){ // Si la etiqueta filtro no existía se crea
                let filtroEtiqueta = { 
                    dataIndex: dataIndex,
                    texto: textoEtiquetaFiltro,
                    borrarFiltro: () => { 
                        contexto.handleReset(clearFilters, dataIndex, cargarDatosTabla);
                    } 
                };
                filtrosEtiquetas.push( filtroEtiqueta );
            }else{ // Si ya existía la etiqueta del filtro, se procede a modificar los valores
                filtrosEtiquetas[indexfiltroEtiqueta].texto = textoEtiquetaFiltro;
            }
        }

        this.setSumaTableState({filtroEtiquetas: filtrosEtiquetas});
        cargarDatosTabla(undefined, confirm, dataIndex, selectedKeys);        
    };


    handleReset = (clearFilters, dataIndex, cargarDatosTabla) => {
        // Se obtiene todos las etiquetas de los filtros y se busca la que 
        // corresponde al 'dataIndex' para ser removida
        let filtrosEtiquetas = this.state.filtroEtiquetas;
        for( let i = 0; i < filtrosEtiquetas.length; i++ ){
            if( filtrosEtiquetas[i].dataIndex === dataIndex ){
                // se procede a eliminar la etiqueta del filtro
                filtrosEtiquetas.splice(i, 1); 
                break;
            }
        }
        this.setSumaTableState({filtroEtiquetas: filtrosEtiquetas});
        cargarDatosTabla(clearFilters, undefined, dataIndex, []);
        //clearFilters();
    };


    busquedaTexto = (labelColumna, dataIndex, cargarDatosTabla) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Buscar ${labelColumna}`}
                    value={selectedKeys[0]}
                    //Cuando cambia el valor, se carga la variable 'selectedKeys' por medio del método 'setSelectedKeys'
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={
                        () => {
                            const textoEtiquetaFiltro = labelColumna + ": " + selectedKeys[0];
                            this.handleSearch(selectedKeys, confirm, dataIndex, cargarDatosTabla, clearFilters, textoEtiquetaFiltro);
                        }
                    }
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={
                            () => {
                                const textoEtiquetaFiltro = labelColumna + ": " + selectedKeys[0];
                                this.handleSearch(selectedKeys, confirm, dataIndex, cargarDatosTabla, clearFilters, textoEtiquetaFiltro);
                            }
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters, dataIndex, cargarDatosTabla)} size="small" style={{ width: 90 }}>
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#C90A00' : undefined }} />,
        //Esta función hace el filtrado de los registros desde el front-end
        onFilter: ((value, record) => {
                //record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''
                return true}),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        /*render: ((text) =>{
            console.log('text',text);
            return true ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={['4']}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )}),*/
    });



    busquedaFecha = (labelColumna, dataIndex, cargarDatosTabla) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <RangePicker
                    ref={node => { this.rangePickerInput = node; }}
                    onChange={
                        rango => //Cuando cambia el valor, se carga la variable 'selectedKeys' por medio del método 'setSelectedKeys'
                            setSelectedKeys(rango !== null ?
                                [rango[0].format(Constantes.FORMATO_FECHA_ESTANDAR), rango[1].format(Constantes.FORMATO_FECHA_ESTANDAR)]
                                : []
                            )
                    }
                    format={Constantes.FORMATO_FECHA_MES}
                    locale={locale}
                    style={{ marginBottom: 8 }}
                    value={
                        //Como valor se deja lo que hay en 'selectedKeys'
                        selectedKeys !== undefined && selectedKeys !== null && selectedKeys.length !== 0
                            ?
                            [moment(selectedKeys[0], Constantes.FORMATO_FECHA_ESTANDAR), moment(selectedKeys[1], Constantes.FORMATO_FECHA_ESTANDAR)]
                            : []
                    }
                />
                <br />
                <Space>
                    <Button type="primary" size="small" style={{ width: 90 }} icon={<SearchOutlined />}
                        onClick={
                            () => {
                                const textoEtiquetaFiltro = labelColumna 
                                                            + ': ' 
                                                            + moment(selectedKeys[0], Constantes.FORMATO_FECHA_ESTANDAR).format(Constantes.FORMATO_FECHA_MES)
                                                            + '   -   ' 
                                                            + moment(selectedKeys[1], Constantes.FORMATO_FECHA_ESTANDAR).format(Constantes.FORMATO_FECHA_MES);
                                this.handleSearch(selectedKeys, confirm, dataIndex, cargarDatosTabla, clearFilters, textoEtiquetaFiltro);
                            }
                            }>
                        Buscar
                    </Button>
                    <Button size="small" style={{ width: 90 }}
                        onClick={() => { this.handleReset(clearFilters, dataIndex, cargarDatosTabla); }} >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#C90A00' : undefined }} />,
        onFilter: (value, record) => true
            /*record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : ''*/,
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.rangePickerInput.focus(), 100);
            }
        },
        /*render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),*/
    });



    busquedaOpcionesConTexto = (labelColumna, dataIndex, cargarDatosTabla, opciones) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Checkbox.Group
                    onChange={
                        opc => //Cuando cambia el valor, se carga la variable 'selectedKeys' por medio del método 'setSelectedKeys'
                        {
                            if (selectedKeys[0] === undefined)
                                selectedKeys[0] = {};
                            // Carga las opciones seleccionadas dentro del atributo 'opc' del objeto selectedKeys
                            selectedKeys[0].opc = opc.length > 0 ? opc : undefined;
                            // Si no hay una opcion seleccionada del checkbox y tampoco hay texto ingresado en el input, se limpoa las palabras claves seleccionadas
                            if( !selectedKeys[0].opc && !selectedKeys[0].texto){
                                selectedKeys = [];
                            }
                            setSelectedKeys(selectedKeys);
                        }
                    }
                    style={{ marginBottom: 8 }}
                    className={'checkbox-vertical'}
                    options={opciones}
                    value={(selectedKeys[0] && selectedKeys[0].opc) ? selectedKeys[0].opc : null}
                />
                <br />
                <Input
                    placeholder={`Buscar ${labelColumna}`}
                    value={(selectedKeys[0] && selectedKeys[0].texto) ? selectedKeys[0].texto : null}
                    //Cuando cambia el valor, se carga la variable 'selectedKeys' por medio del método 'setSelectedKeys'
                    onChange={e => 
                        {
                            if (selectedKeys[0] === undefined)
                                selectedKeys[0] = {};
                            // Carga el texto ingresado dentro del atributo 'opc' del objeto selectedKeys
                            selectedKeys[0].texto = e.target.value ? e.target.value : undefined;
                            // Si no hay una opcion seleccionada del checkbox y tampoco hay texto ingresado en el input, se limpoa las palabras claves seleccionadas
                            if( !selectedKeys[0].opc && !selectedKeys[0].texto){
                                selectedKeys = [];
                            }
                            setSelectedKeys(selectedKeys)
                        }
                    }
                    onPressEnter={
                        () => {
                            let textoEtiquetaFiltro = labelColumna; 
                            this.handleSearch(selectedKeys, confirm, dataIndex, cargarDatosTabla, clearFilters, textoEtiquetaFiltro);
                        }
                    }
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button type="primary" size="small" style={{ width: 90 }} icon={<SearchOutlined />}
                        onClick={
                            () => {
                                let textoEtiquetaFiltro = []; 
                                if( selectedKeys[0] ){
                                    if( selectedKeys[0].opc ){ // Se mira si se tiene seleccionada alguna opcion
                                        for( let o of selectedKeys[0].opc ){
                                            for( let opcion of opciones){
                                                if( o === opcion.value ){
                                                    textoEtiquetaFiltro.push(opcion.label); // se toma el label de la opcion seleccionada
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                    if( selectedKeys[0].texto ){ // Se carga el texto
                                        textoEtiquetaFiltro.push(selectedKeys[0].texto);
                                    }
                                }
                                textoEtiquetaFiltro = labelColumna + ": " + textoEtiquetaFiltro.join(", ");
                                this.handleSearch(selectedKeys, confirm, dataIndex, cargarDatosTabla, clearFilters, textoEtiquetaFiltro);
                            }
                        }>
                        Buscar
                    </Button>
                    <Button size="small" style={{ width: 90 }}
                        onClick={() => { this.handleReset(clearFilters, dataIndex, cargarDatosTabla); }} >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#C90A00' : undefined }} />,
        onFilter: ((value, record) => {
            /*if (record[dataIndex]) {
                // Se busca primero por las opciones
                if (value.opc !== undefined) {
                    for (let val of value.opc) {
                        for (let opcion of opciones) {
                            if (opcion.value === val) {
                                if (record[dataIndex].toString().toLowerCase().includes(opcion.label.toLowerCase())) {
                                    return true;
                                }
                            }
                        }
                    }
                }
                // Se busca por el texto ingresado
                if (value.texto !== undefined) {
                    console.log(record['estadoDependencia']);
                    if (record[dataIndex].toString().toLowerCase().includes(value.texto.toLowerCase())) {
                        return true;
                    }
                }

            }
            return false;*/
            return true;
        }
        ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                //setTimeout(() => this.checkbox.focus(), 100);
            }
        },
        /*render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),*/
    });


    busquedaOpciones = (labelColumna, dataIndex, cargarDatosTabla, opciones) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Checkbox.Group
                    onChange={
                        opc => //Cuando cambia el valor, se carga la variable 'selectedKeys' por medio del método 'setSelectedKeys'
                        {
                            if (selectedKeys[0] === undefined)
                                selectedKeys[0] = {};
                            // Carga las opciones seleccionadas dentro del atributo 'opc' del objeto selectedKeys
                            selectedKeys[0].opc = opc.length > 0 ? opc : undefined;
                            // Si no hay una opcion seleccionada del checkbox, se limpoa las palabras claves seleccionadas
                            if( !selectedKeys[0].opc ){
                                selectedKeys = [];
                            }
                            setSelectedKeys(selectedKeys);
                        }
                    }
                    style={{ marginBottom: 8 }}
                    className={'checkbox-vertical'}
                    options={opciones}
                    value={(selectedKeys[0] && selectedKeys[0].opc) ? selectedKeys[0].opc : null}
                />
                <br />
                <Space>
                    <Button type="primary" size="small" style={{ width: 90 }} icon={<SearchOutlined />}
                        onClick={
                            () => {
                                let textoEtiquetaFiltro = []; 
                                if( selectedKeys[0] ){
                                    if( selectedKeys[0].opc ){ // Se mira si se tiene seleccionada alguna opcion
                                        for( let o of selectedKeys[0].opc ){
                                            for( let opcion of opciones){
                                                if( o === opcion.value ){
                                                    textoEtiquetaFiltro.push(opcion.label); // se toma el label de la opcion seleccionada
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                }
                                textoEtiquetaFiltro = labelColumna + ": " + textoEtiquetaFiltro.join(", ");
                                this.handleSearch(selectedKeys, confirm, dataIndex, cargarDatosTabla, clearFilters, textoEtiquetaFiltro);
                            }
                        }>
                        Buscar
                    </Button>
                    <Button size="small" style={{ width: 90 }}
                        onClick={() => { this.handleReset(clearFilters, dataIndex, cargarDatosTabla); }} >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#C90A00' : undefined }} />,
        onFilter: ((value, record) => { return true; }
        ),
    });



}

export default FiltroColumna;