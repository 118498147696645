import React, {   } from 'react';
import { Spin } from 'antd';
import EstilosGlobales from '../src/utilidades/EstilosGlobales'


import './App.less';
import ContenedorApp from './general/ContenedorApp'
//import SumaContexto from './contexto/SumaContexto';

function App() {

  //const user = useContext(SumaContexto);

  // Aqui se deben nombrar las cookies que se van a usar
  //const [cookies, setCookie] = useCookies(['sumajwt']);

  return (
    <div className="App">
      <EstilosGlobales.PRINCIPAL />
      <ContenedorApp />
      <div id="bloqueo-ui">
        <Spin size="large" />
      </div>
    </div>
  );
}

export default App;