import React from 'react';
import { Spin } from 'antd';

function BloqueoPantalla(props) {
    if( props.cargando ){
        return (
            <div className="bloqueo-pantalla">
                <Spin tip="Por favor espera..." className="" size="large" />
            </div>
        );
    }else{
        return (<></>);
    }
}

export default BloqueoPantalla;