import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Modal, Spin, Row, Col, Form, Input, Select, Button, Popconfirm } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Constantes from '../../utilidades/Constantes'
import ServicioUsuarios from '../../servicios/usuarios/ServicioUsuarios'
import ServicioDatosConstantes from '../../servicios/ServicioDatosConstantes'
import ServicioAdministracion from '../../servicios/ServicioAdministracion'
import ServicioEntidad from '../../servicios/ServicioEntidad'
import queryString from 'query-string';



class AgregarEditarDependencia extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        idEntidad: undefined,
        usuario: undefined,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        estados: []
    };

    constructor(props) {
        super(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
        this.servicioEntidad = new ServicioEntidad(props);
        this.onSuccess = props.onSuccess;
    }

    formRef = React.createRef();

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        let idEntidad;
        let idDependencia;

        

        if (urlparametros.id !== undefined && !isNaN(parseInt(urlparametros.id))) {
            idDependencia = parseInt(urlparametros.id);
        }
        if (urlparametros.idEnt !== undefined && !isNaN(parseInt(urlparametros.idEnt))) {
            idEntidad = parseInt(urlparametros.idEnt);
        }

        this.setSumaState({ idDependencia: idDependencia, idEntidad:idEntidad });

        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr !== null) {
            // Valida que tenga la accion habilitada EDITAR o AGREGAR
            let habilitado = await this.servicioUsuarios.accionHabilitadaPromesa(idDependencia ? Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.ACTUALIZAR_INFORMACION_DEPENDENCIA.id : Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.AGREGAR_DEPENDENCIA.id);
            if (habilitado && habilitado.resultado) {
                
                let estados = await this.servicioDatosConstantes.obtenerEstadosDependencia();
                
                let entidad;
                if( idEntidad ){
                    entidad = await this.servicioAdministracion.obtenerEntidad( idEntidad );
                }

                this.setSumaState({ estados: estados ? estados : [], entidad:entidad });

                let dependencia;
                if (idDependencia) {
                    // Se consulta la entidad y se carga (Esto es en modo Edición de entidad)
                    let respuestaApi = await this.servicioEntidad.obtenerDependenciaPromise( idDependencia,"[AgregarEditarDependencia]" );
                    dependencia = respuestaApi.resultado;
                    if ( dependencia ) {
                        this.setSumaState({componente: this.ENUM_COMPONENTE.VER, dependencia:dependencia});
                    } else {
                        this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
                    }
                } else {
                    this.setSumaState({ componente: this.ENUM_COMPONENTE.VER });
                }

                this.setSumaState({ cargando: false }); 

                if( dependencia ){
                    this.formRef.current.setFieldsValue({
                        'dependencia' :         dependencia.dependencia,
                        'sigla' :               dependencia.sigla,
                        'estado':               dependencia.estadoDependencia.id.toString() ,
                        'direccion' :           dependencia.direccion,
                        'movil' :               dependencia.celular,
                        'telefono' :            dependencia.telefono, 
                        'correoElectronico':    dependencia.correo,
                        'descripcion' :         dependencia.descripcion
                    });
                }

            } else {
                this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }


    errorFormularioIncompleto = errorInfo => {
        Modal.warn({ content: "Por favor diligencia la información solicitada para la dependencia" });
    };

    formularioCompleto = (values) => {
        return new Promise(resolve => {
            let dependencia = {
                dependencia: values.dependencia.trim(),
                sigla: values.sigla,
                estadoDependencia: { id: parseInt(values.estado) },
                direccion: values.direccion.trim(),
                telefono: values.telefono,
                celular: values.movil,
                correo: values.correoElectronico.toLowerCase().trim(),
                descripcion: values.descripcion.trim(),
            };
            resolve(dependencia);
        }).then(async ( dependencia ) => {
            let resultado = { cargando: false, limpiarFormulario: false };
            
            if (!this.state.dependencia) {
                // Dependencia Nueva
                dependencia.entidad = { id: this.state.entidad.id };


                let respuestaApi = await this.servicioEntidad.crearDependencia( dependencia );
                if (respuestaApi) {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.ENTIDAD.OPERACION_REALIZADA.id:
                            resultado.satisfactorio = true;
                            resultado.limpiarFormulario = true;
                            this.onSuccess(respuestaApi.resultado);
                            break;
                        case Constantes.ENUMS_API.ENTIDAD.DEPENDENCIA_YA_REGISTRADA.id:
                            Modal.error({ content: respuestaApi.mensaje });
                            break;
                        default:
                            Modal.error({ content: 'No fue posible crear la dependenciar. Por favor intenta mas tarde.' });
                            break;
                    }
                }
            } else {
                // Editar Dependencia 
                dependencia.id = this.state.idDependencia;
                let respuestaApi = await this.servicioEntidad.guardarDatosDependencia( dependencia );
                if (respuestaApi) {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.ENTIDAD.OPERACION_REALIZADA.id:
                            this.onSuccess(respuestaApi.resultado);
                            break;
                        case Constantes.ENUMS_API.ENTIDAD.DEPENDENCIA_YA_REGISTRADA.id:
                            Modal.error({ content: respuestaApi.mensaje });
                            break;    
                        default:
                            Modal.error({ content: 'No fue posible actualizar la dependencia. Por favor intenta mas tarde.' });
                            break;
                    }
                }

            }
            return resultado;

        });

    };

    cancelarFormularioDependencia = () => {
        this.onSuccess(this.state.idDependencia, this.state.idEntidad, true);
    }

    
    render() {
        const { dependencia, componente, estados, cargando } = this.state;
        const { Option } = Select;

        const layout = {
            labelCol: { // Este maneja el grid de los label
                sm: { span: 23, offset: 1 },
                lg: { span: 7, offset: 1 },
            },
            wrapperCol: { // Este maneja el grid de los componentes
                sm: { span: 23, offset: 1 },
                lg: { span: 15, offset: 1 },
            },
        };

        const labelPrimerCol ={ // Este maneja de forma especial el grid de las primeras columnas
                sm: { span: 23, offset: 1 },
                lg: { span: 8, offset: 0 },
        }

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>

                            <div className="titulo-seccion">
                                <label>
                                    { !dependencia ? 'AGREGAR ' : 'EDITAR '} DEPENDENCIA
                                </label>
                                <div className="separador-horizontal-completo margen-bottom-10"></div>
                            </div>

                            <div className="sub-titulo-seccion">
                                <label>Datos dependencia</label>
                                <div className="separador-horizontal-completo02 margen-bottom-20"></div>
                            </div>
                            <Spin tip={'por favor espera...'} size="large" spinning={cargando}>

                                <Form
                                    {...layout}
                                    ref={this.formRef}
                                    name="datos-dependencia"
                                    className="formulario-suma"
                                    scrollToFirstError
                                    onFinish={ this.formularioCompleto }
                                    onFinishFailed={ this.errorFormularioIncompleto }
                                >

                                    <Form.Item label="id" name="id" noStyle>
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Dependencia" name="dependencia"
                                                rules={[
                                                    { required: true, message: 'Ingresa el nombre de la dependencia', },
                                                    { min: 4, message: 'No puede ser menor a 4 caracteres', },
                                                    { max: 500, message: 'No puede ser mayor a 500 caracteres', },
                                                ]}
                                                labelCol={labelPrimerCol}
                                            >
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Sigla" name="sigla"
                                                rules={[
                                                    { max: 100, message: 'No puede ser mayor a 100 caracteres', },
                                                ]}
                                            >
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item  label="Estado" name="estado" 
                                                        rules=  {[{ required: true, message: 'Selecciona el estado',},]}
                                                        labelCol={labelPrimerCol}>
                                                <Select>
                                                    {estados.map( estado => (
                                                        <Option key={ estado.id }>{ estado.estadoDependencia }</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Dirección dependencia" name="direccion"
                                                rules={[
                                                    { required: true, message: 'Ingresa la dirección', },
                                                    { max: 500, message: 'No puede ser mayor a 500 caracteres', },
                                                ]}>
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>     

                                    </Row>

                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item  label="Teléfono" name="telefono"
                                                        labelCol={labelPrimerCol}
                                                rules={[
                                                    { required: false },
                                                    { min: 7, message: 'No puede ser menor a 7 caracteres', },
                                                    { max: 30, message: 'No puede ser mayor a 30 caracteres', },
                                                ]}>
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Móvil" name="movil"
                                                rules={[
                                                    { min: 7, message: 'No puede ser menor a 7 caracteres', },
                                                    { max: 20, message: 'No puede ser mayor a 20 caracteres', },
                                                    { pattern: new RegExp('^[0-9\\+\\s]*$'), message: 'Solo dígitos y puede contener el simbolo +', },
                                                ]}>
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item  label="Correo electrónico" name="correoElectronico"
                                                        labelCol={labelPrimerCol}
                                                rules={[
                                                    { required: true, message: 'Ingresa el correo electrónico de la dependencia', },
                                                    { type: 'email', message: 'Ingresa un correo electrónico válido', },
                                                    { max: 255, message: 'No puede ser mayor a 255 caracteres', },
                                                ]}
                                            >
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={24}>
                                            <Form.Item label="Descripcion" name="descripcion"
                                                rules={[
                                                    { required: true, message: 'Ingresa una descripción de la dependencia', },
                                                    { min: 7, message: 'No puede ser menor a 10 caracteres', },
                                                    { max: 4000, message: 'No puede ser mayor a 4000 caracteres', },
                                                ]}
                                                labelCol={{ span: 24, offset: 0 }}
                                                wrapperCol={{ span: 24, offset: 0 }}

                                            >
                                                <Input.TextArea rows={4} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col xs={24}>
                                            <Form.Item wrapperCol={{ span: 24, offset: 0 }}>
                                                <Button type="primary"
                                                    htmlType="submit"
                                                    loading={cargando}>
                                                    Guardar
                                                </Button>
                                                &nbsp;&nbsp;
                                                <Popconfirm title={'Confirma cancelar la ' + (dependencia ? 'edición' : 'creación') + ' de la dependencia?'} 
                                                            okText="Si"
                                                            cancelText="No" 
                                                            onConfirm={this.cancelarFormularioDependencia}>
                                                    <Button icon={<CloseOutlined/>}>Cancelar</Button>
                                                </Popconfirm>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Spin>
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:AEDEP]</label>) : (<></>))
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(AgregarEditarDependencia));