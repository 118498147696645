import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Modal, Spin, Row, Col, Form, Input, Button, 
        Popconfirm } from 'antd';
import { CloseOutlined, LockOutlined } from '@ant-design/icons';
import ServicioUsuarios from '../../servicios/usuarios/ServicioUsuarios'
import ServicioDatosConstantes from '../../servicios/ServicioDatosConstantes'
import ServicioAdministracion from '../../servicios/ServicioAdministracion'
import ServicioEntidad from '../../servicios/ServicioEntidad'
import Constantes from '../../utilidades/Constantes';
import Utilidades from '../../utilidades/Utilidades';
import Validadores from '../../utilidades/Validadores'


class AplicacionConectada extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3,
        NO_HABILITADO: 4
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        tiposSolicitud: [],
        agregarContrasena:false,
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
        this.servicioEntidad = new ServicioEntidad(props);
        this.onSuccess = props.onSuccess;
        this.textAreaUrlRef = React.createRef();
    }

    formRef = React.createRef();
    

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let usr = await this.servicioEntidad.usuarioValido();
        if (usr !== null ) {
            
            let habilitado = Utilidades.accionPermitida(usr, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.ADMINISTRAR_APLICACION_CONECTADA.id);
            if( habilitado ){

                // Se consulta la aplicación conectada y se carga
                let aplicacionConectada = await this.servicioEntidad.obtenerAplicacionConectada();
                this.setSumaState({componente: this.ENUM_COMPONENTE.VER, aplicacionConectada });
                if ( aplicacionConectada ) {
                    if( aplicacionConectada.tieneContrasena ){
                        this.formRef.current.setFieldsValue({ 'contrasena' : '********' });
                    }
                }
                this.setSumaState({ cargando: false }); 
            }else{
                this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_HABILITADO });    
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }


    errorFormularioIncompleto = errorInfo => {
        Modal.warn({ content: "Por favor diligencia la información solicitada para la aplicación conectada" });
    };

    formularioCompleto = (values) => {
        return new Promise(resolve => {
            
            let aplicacionConectada = {
                contrasena: values.contrasena,
            };

            //console.log('aplicacionConectada',aplicacionConectada);
            resolve(aplicacionConectada);
        }).then(async ( aplicacionConectada ) => {
            
            this.setSumaState({cargando: true});
            let respuestaApi = await this.servicioEntidad.actualizarAplicacionConectada( aplicacionConectada );
            if (respuestaApi) {
                switch (respuestaApi.codigo) {
                    case Constantes.ENUMS_API.ENTIDAD.OPERACION_REALIZADA.id:
                        this.formRef.current.resetFields();
                        this.formRef.current.setFieldsValue({ 'contrasena' : '********' });
                        this.setSumaState({agregarContrasena:false});
                        Modal.success({ content: 'Aplicación conectada actualizada satisfactoriamente.' });
                        break;
                    case Constantes.ENUMS_API.ENTIDAD.CONTRASENA_NO_DISPONIBLE.id:
                        Modal.error({ content: 'Debes ingresar una nueva contraseña que no haya sido registrada con anterioridad' });
                        break;
                    default:
                        Modal.error({ content: 'No fue posible actualizar la aplicación conectada. Por favor intenta mas tarde.' });
                        break;
                }
            }
            this.setSumaState({cargando: false});
        });

    };

    cancelarFormularioDependencia = () => {
        this.onSuccess(this.state.idDependencia, this.state.idOpcDependencia, true);
    }

    guardarCursorTextArea = (event) =>{
        this.setSumaState({ cursorInicio:event.target.selectionStart, cursorFin: event.target.selectionEnd }); 
    }
    
    render() {
        const { aplicacionConectada, componente, cargando, agregarContrasena } = this.state;
        
        const layout = {
            labelCol: { // Este maneja el grid de los label
                sm: { span: 23, offset: 1 },
                lg: { span: 7, offset: 1 },
            },
            wrapperCol: { // Este maneja el grid de los componentes
                sm: { span: 23, offset: 1 },
                lg: { span: 15, offset: 1 },
            },
        };

        const labelPrimerCol ={ // Este maneja de forma especial el grid de las primeras columnas
                sm: { span: 23, offset: 1 },
                lg: { span: 8, offset: 0 },
        }

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            
                            <Spin tip={'por favor espera...'} size="large" spinning={cargando}>

                                <Form
                                    {...layout}
                                    ref={this.formRef}
                                    name="datos-dependencia"
                                    className="formulario-suma"
                                    scrollToFirstError
                                    onFinish={ this.formularioCompleto }
                                    onFinishFailed={ this.errorFormularioIncompleto }
                                >

                                    <Form.Item label="id" name="id" noStyle>
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Row>
                                                <Col {...labelPrimerCol}>
                                                    <label className="bold">Token:</label>
                                                </Col>
                                                <Col {...layout.wrapperCol}>
                                                    {aplicacionConectada ? aplicacionConectada.token : '-' }
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Row>
                                                <Col {...layout.labelCol}>
                                                <label className="bold">Estado:</label>
                                                </Col>
                                                <Col {...layout.wrapperCol}>
                                                    {aplicacionConectada ? aplicacionConectada.estado : '-' }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item  label="Contraseña" name="contrasena" hasFeedback
                                                        rules={[ 
                                                            { required: true, message: 'Ingresa la contraseña', }, 
                                                            () => ({
                                                                validator(rule, value) {
                                                                if ( value ) {
                                                                    const val = Validadores.validarContrasena( value );
                                                                    if( !val.success ){
                                                                        return Promise.reject( val.msg );  
                                                                    }
                                                                }
                                                                return Promise.resolve();
                                                                },
                                                            }),
                                                        ]}
                                                        labelCol={labelPrimerCol}>
                                                <Input.Password disabled={!agregarContrasena}/>
                                            </Form.Item>
                                        </Col>
                                        { agregarContrasena ?
                                            <Col xs={24} sm={12}>
                                                <Form.Item  label="Confirmar contraseña" name="contrasenaConfirmar" 
                                                            dependencies={['contrasena']} hasFeedback
                                                            rules={[ 
                                                                { required: true, message: 'Confirma la contraseña', }, 
                                                                ({ getFieldValue }) => ({
                                                                    validator(rule, value) {
                                                                    if (!value || getFieldValue('contrasena') === value) {
                                                                        return Promise.resolve();
                                                                    }
                                                                    return Promise.reject('No coinciden las contraseñas');
                                                                    },
                                                                }),
                                                            ]}>
                                                    <Input.Password />
                                                </Form.Item>
                                            </Col>
                                            : null }
                                    </Row>

                                    { agregarContrasena ?
                                        <Row>
                                            <Col xs={24}>
                                                <Col>
                                                    <label className="bold">Nota: </label>
                                                    Debe ser mínimo de 8 caracteres y tener al menos: Una letra mayúscula, una letra minúscula, un dígito y alguno de los siguientes caracteres: ! @ # $ % ^ & *
                                                    <br/><br/>
                                                </Col>
                                            </Col>
                                        </Row> : null }

                                    
                                    <Row>
                                        <Col xs={24}>
                                            <Form.Item wrapperCol={{ span: 24, offset: 0 }}>
                                                { agregarContrasena ?
                                                    <>
                                                        <Button type="primary"
                                                            htmlType="submit"
                                                            loading={cargando}>
                                                            Guardar
                                                        </Button>
                                                        &nbsp;&nbsp;
                                                        <Popconfirm title={'Confirma cancelar la asignación de contraseña?'} 
                                                                    okText="Si"
                                                                    cancelText="No" 
                                                                    onConfirm={() => {
                                                                        this.formRef.current.resetFields();
                                                                        this.formRef.current.setFieldsValue({ 'contrasena':
                                                                        aplicacionConectada.tieneContrasena ? '********' : '' });
                                                                        this.setSumaState({agregarContrasena:false});
                                                                    }}>
                                                            <Button icon={<CloseOutlined/>}>Cancelar</Button>
                                                        </Popconfirm>
                                                    </> :  null
                                                }
                                                
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                                    { !agregarContrasena ?
                                        <Button icon={<LockOutlined />}
                                            loading={cargando}
                                            onClick={() => {
                                                this.formRef.current.setFieldsValue({ 'contrasena':'','contrasenaConfirmar':'' });
                                                this.setSumaState({agregarContrasena:true});
                                                return false;
                                            }}
                                            >
                                            Asignar contraseña
                                        </Button> : null
                                    }


                            </Spin>
                            
                            
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : (
                                (componente === this.ENUM_COMPONENTE.NO_HABILITADO ? 
                                <label>Administación de aplicación conectada no disponible. {Constantes.MENSAJE_USUARIO_SIN_PERMISOS}</label> :
                                (componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:APLCON]</label>) : (<></>))
                            )
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(AplicacionConectada));