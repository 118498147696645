import axios from 'axios';
import Constantes from '../utilidades/Constantes'
import Utilidades from '../utilidades/Utilidades'
import { Modal } from 'antd';


class ServicioSolicitud {

    constructor( props ){ 
        const { cookies } = props;
        this.cookies = cookies;
    }


    // Método privado
    obtenerSolicitudesDependencia ( filtroApi, callbackComplete, callbalkError  ) {
        let token = this.cookies.get('sumajwt');
        filtroApi = filtroApi ? filtroApi : {};
        const config= { headers:{ 'Authorization': `Bearer ${token}` }, params: filtroApi };
        
        axios.get( Constantes.URL_API_SUMA + '/solicitud/solicitudes-dependencia' ,config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioSolicitud.obtenerSolicitudesDependencia]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    obtenerSolicitudesAsociadas ( filtroApi ) {
        let token = this.cookies.get('sumajwt');
        filtroApi = filtroApi ? filtroApi : {};
        const config= { headers:{ 'Authorization': `Bearer ${token}` }, params: filtroApi};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/solicitud/solicitudes-asociadas',config)
            .then(res => {
                if(res.status === 200){
                    if (res.data.codigo === Constantes.ENUMS_API.SOLICITUDES.DATOS_RETORNADOS.id) {
                        resolve( res.data.resultado );
                        return;    
                    }else{
                        Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SS03]" });
                        resolve();
                        return;    
                    }
                }else{
                    throw new Error("Error Obtener Solicitudes Asociadas. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioSolicitud.obtenerSolicitudesAsociadas]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SS04]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerMisSolicitudes ( filtroApi ) {
        let token = this.cookies.get('sumajwt');
        filtroApi = filtroApi ? filtroApi : {};
        const config= { headers:{ 'Authorization': `Bearer ${token}` }, params: filtroApi};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/solicitud/mis-solicitudes',config)
            .then(res => {
                if(res.status === 200){
                    if (res.data.codigo === Constantes.ENUMS_API.SOLICITUDES.DATOS_RETORNADOS.id) {
                        resolve( res.data.resultado );
                        return;    
                    }else{
                        Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SS02]" });
                        resolve();
                        return;    
                    }
                }else{
                    throw new Error("Error Obtener Mis Solicitudes. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioSolicitud.obtenerMisSolicitudes]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SS01]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerSolicitud ( callbackComplete, callbalkError, codigoSolicitud ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        axios.get( Constantes.URL_API_EXTERNO_SUMA + '/solicitud/' + codigoSolicitud, config )
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioSolicitud.obtenerSolicitud]',err);
            callbalkError( {err} );
        });
    }

    obtenerTiposDeSolicitudPromise() {
        return new Promise(resolve => {
            this.obtenerTipoDeSolicitudes(
                (dataAPI) => {
                    resolve(dataAPI);
                },
                (errorAPI) => {
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SS08]" });
                    resolve(null);
                },
            );
        });
    };

    // Método privado
    obtenerTipoDeSolicitudes ( callbackComplete, callbalkError ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        axios.get( Constantes.URL_API_SUMA + '/solicitud/tipo-solicitud',config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioSolicitud.obtenerTipoDeSolicitudes]',err);
            callbalkError( {err} );
        });
    }


    // Método privado
    obtenerTipoDeSolicitudesDependencia ( idDependencia ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + `/solicitud/tipo-solicitud/${idDependencia ? idDependencia : 0}`,config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SS09]" });
                    resolve();
                    return; 
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioSolicitud.obtenerTipoDeSolicitudesDependencia]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SS10]" }); 
                resolve();
            });
        });
    }

    

    // Método privado
    obtenerAccesoRegistroSolicitud ( callbackComplete, callbalkError, idTipoSolicitud ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        axios.get( Constantes.URL_API_SUMA + '/solicitud/acceso-registro-solicitud/' + idTipoSolicitud, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioSolicitud.obtenerAccesoRegistroSolicitud]',err);
            callbalkError( {err} );
        });
    }


    // Método privado
    obtenerUrlOpcionDependencia ( idSolicitud, idOpcionDependencia ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };
        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/solicitud/url-opcion-dependencia/'+idSolicitud+'/'+idOpcionDependencia,config)
            .then(res => {
                if(res.status === 200){
                    if (res.data.codigo === Constantes.ENUMS_API.SOLICITUDES.DATOS_RETORNADOS.id) {
                        resolve( res.data.resultado );
                        return;    
                    }else if(res.data.codigo === Constantes.ENUMS_API.SOLICITUDES.OPCION_DEPENDENCIA_SOLICITUD_NO_RELACIONADA.id) {
                        Modal.error({ content: "La opción ya no se encuentra disponible. Te invitamos a refrescar la página. [Cod:SS07]." });
                        resolve();
                        return;    
                    }
                    else{
                        Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SS05]" });
                        resolve();
                        return;    
                    }
                }else{
                    throw new Error("Error obtener opción dependencia. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioSolicitud.obtenerUrlOpcionDependencia]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SS06]" }); 
                resolve();
            });
        });
    }


}

export default ServicioSolicitud;