import React, { Component } from 'react';
 
class EnlaceCircular extends Component {
  render() {
    return (
      <div className={this.props.className + ' enlace-circular'}>
          {this.props.icono}
          <a href={this.props.href}>.</a>
      </div>
    );
  }
}
 
export default EnlaceCircular;