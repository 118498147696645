import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Form, Input, Button, Row, Col, Modal, Spin } from 'antd';
import Constantes from '../utilidades/Constantes'
import { withCookies } from 'react-cookie';
import Utilidades from '../utilidades/Utilidades';
import ServicioEntidad from '../servicios/ServicioEntidad'


class InformacionDependencia extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        cargando: true,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        usuario: undefined,
        dependencia: undefined,
        deshabilitar: true,
    };

    constructor(props) {
        super(props);
        this.servicioEntidad = new ServicioEntidad(props);
    }
    //const [form] = Form.useForm();
    formRef = React.createRef();


    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        let usr = await this.servicioEntidad.usuarioValido();
        if (usr !== null) {
            let dependenciaAPI = await this.servicioEntidad.obtenerDependenciaPromise(usr.usuarioDependencia.dependencia.id, "[InformacionDependencia]");
            if (dependenciaAPI.codigo === Constantes.ENUMS_API.ENTIDAD.DATOS_RETORNADOS.id) {
                let dependencia = dependenciaAPI.resultado;
                usr.usuarioDependencia.dependencia = dependencia;
                this.setSumaState({ usuario: usr, dependencia:dependencia, componente: this.ENUM_COMPONENTE.VER });

                let form = this.formRef.current;
                if (form != null) {
                    form.setFieldsValue({
                        'id':                   dependencia.id,
                        'dependencia' :         dependencia.dependencia,
                        'sigla' :               dependencia.sigla,
                        'direccion' :           dependencia.direccion,
                        'telefono' :            dependencia.telefono,
                        'movil' :               dependencia.celular,
                        'correoElectronico' :   dependencia.correo,
                        'descripcion' :         dependencia.descripcion
                    });
                    this.setSumaState({
                        cargando: false,
                        deshabilitar: !Utilidades.accionPermitida(usr, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.ACTUALIZAR_INFORMACION_DEPENDENCIA.id)
                    });
                }
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    render() {

        const { cargando, componente, deshabilitar } = this.state;

        const layout = {
            labelCol: { // Este maneja el grid de los label
                sm: { span: 23, offset: 1 },
                lg: { span: 7, offset: 1 },
            },
            wrapperCol: { // Este maneja el grid de los componentes
                sm: { span: 23, offset: 1 },
                lg: { span: 15, offset: 1 },
            },
        };

        const labelPrimerCol ={ // Este maneja de forma especial el grid de las primeras columnas
                sm: { span: 23, offset: 1 },
                lg: { span: 8, offset: 0 },
        }


        const onFinish =  async (values) => {
            this.setSumaState({ cargando: true });
            let dependencia = {
                id:                   values.id,
                dependencia :         values.dependencia,
                sigla :               values.sigla,
                direccion :           values.direccion,
                telefono :            values.telefono,
                celular :             values.movil,
                correo :              values.correoElectronico.toLowerCase(),
                descripcion :         values.descripcion
            };
    
            let respuestaApi = await this.servicioEntidad.guardarDatosDependencia( dependencia );
            if (respuestaApi) {
                switch (respuestaApi.codigo) {
                    case Constantes.ENUMS_API.ENTIDAD.OPERACION_REALIZADA.id:
                        Modal.success({content:'Datos de la dependencia guardados.'});
                        break;
                    case Constantes.ENUMS_API.ENTIDAD.DEPENDENCIA_YA_REGISTRADA.id:
                        Modal.error({ content: respuestaApi.mensaje });
                        break;    
                    default:
                        Modal.error({ content: 'No fue posible guardar los datos de la dependencia. Por favor intenta mas tarde.' });
                        break;
                }
            }
            
            setTimeout(() => {this.setSumaState({ cargando: false });},500); 

        };

        const onFinishFailed = errorInfo => {
            Modal.warning({ content: 'Por favor diligencia la información solicitada.' });
        };

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>
                                <Form
                                    {...layout}
                                    ref={this.formRef}
                                    name="datos-dependencia"
                                    className="formulario-suma"
                                    scrollToFirstError
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                >

                                    <Form.Item label="id" name="id" noStyle>
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Dependencia" name="dependencia"
                                                rules={[
                                                    { required: true, message: 'Ingresa el nombre de la dependencia', },
                                                    { min: 4, message: 'No puede ser menor a 4 caracteres', },
                                                    { max: 500, message: 'No puede ser mayor a 500 caracteres', },
                                                ]}
                                                labelCol={labelPrimerCol}
                                            >
                                                <Input autoComplete="off" disabled={deshabilitar} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Sigla" name="sigla"
                                                rules={[
                                                    { max: 100, message: 'No puede ser mayor a 100 caracteres', },
                                                ]}
                                            >
                                                <Input autoComplete="off" disabled={deshabilitar} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Dirección dependencia" name="direccion"
                                                rules={[
                                                    { required: true, message: 'Ingresa la dirección', },
                                                    { max: 500, message: 'No puede ser mayor a 500 caracteres', },
                                                ]}
                                                labelCol={labelPrimerCol}
                                            >
                                                <Input autoComplete="off" disabled={deshabilitar} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Teléfono" name="telefono"
                                                rules={[
                                                    { required: false },
                                                    { min: 7, message: 'No puede ser menor a 7 caracteres', },
                                                    { max: 30, message: 'No puede ser mayor a 30 caracteres', },
                                                ]}>
                                                <Input autoComplete="off" disabled={deshabilitar} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Móvil" name="movil"
                                                rules={[
                                                    { min: 7, message: 'No puede ser menor a 7 caracteres', },
                                                    { max: 20, message: 'No puede ser mayor a 20 caracteres', },
                                                    { pattern: new RegExp('^[0-9\\+\\s]*$'), message: 'Solo dígitos y puede contener el simbolo +', },
                                                ]} 
                                                labelCol={labelPrimerCol}
                                            >
                                                <Input autoComplete="off" disabled={deshabilitar} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Correo electrónico" name="correoElectronico"
                                                rules={[
                                                    { required: true, message: 'Ingresa el correo electrónico de la entidad', },
                                                    { type: 'email', message: 'Ingresa un correo electrónico válido', },
                                                    { max: 255, message: 'No puede ser mayor a 255 caracteres', },
                                                ]}
                                            >
                                                <Input autoComplete="off" disabled={deshabilitar} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24}>
                                            <Form.Item label="Descripcion" name="descripcion"
                                                rules={[
                                                    { required: true, message: 'Ingresa una descripción de la entidad', },
                                                    { min: 7, message: 'No puede ser menor a 10 caracteres', },
                                                    { max: 4000, message: 'No puede ser mayor a 4000 caracteres', },
                                                ]}
                                                labelCol={{ span: 24, offset: 0 }}
                                                wrapperCol={{ span: 24, offset: 0 }}

                                            >
                                                <Input.TextArea rows={4} disabled={deshabilitar}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col xs={24}>
                                            <Form.Item wrapperCol={{ span: 24, offset: 0 }}>
                                                <Button type="primary"
                                                    htmlType="submit"
                                                    loading={cargando}
                                                    disabled={deshabilitar}>
                                                    Guardar
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>

                            </Spin>

                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:INFODEP]</label>) : (<></>))
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(InformacionDependencia));