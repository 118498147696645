import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter, Switch, Route } from "react-router-dom";
import { Button, Spin, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Constantes from '../../utilidades/Constantes'
import ServicioEntidad from '../../servicios/ServicioEntidad'
import { withCookies } from 'react-cookie';
import ListarSubUsuariosDependencia from './ListarSubUsuariosDependencia'
import Utilidades from '../../utilidades/Utilidades';
import AgregarEditarSubUsuarioDependencia from './AgregarEditarSubUsuarioDependencia'
import VerSubUsuarioDependencia from './VerSubUsuarioDependencia'


class SubUsuariosDependencia extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    PATHS = {
        USUARIOS: '/dependencia-usuarios',
        AGREGAR_USUARIO: '/dependencia-agregar-usuario',
        EDITAR_USUARIO: '/dependencia-editar-usuario',
        VER_USUARIO: '/dependencia-ver-usuario',
    }

    state = {
        idUsuario: null,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        usuario: null,
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioEntidad = new ServicioEntidad(props);
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        // Validando que el usuario se ENTIDAD
        let usr = await this.servicioEntidad.usuarioValido();
        this.setState({ usuario: usr, componente: (usr !== null ? this.ENUM_COMPONENTE.VER : this.ENUM_COMPONENTE.NO_DISPONIBLE) });
    }

    subUsuarioGuardado(nuevoUsuario, idUsuario, cancelar) {
        if (typeof cancelar === 'boolean' && cancelar) {
            this.setState({ idUsuario: (nuevoUsuario ? 0 : idUsuario) });
            if (nuevoUsuario)
                this.historyLocal.push(this.PATHS.USUARIOS);
            else
                this.historyLocal.push(this.PATHS.VER_USUARIO + '?id=' + idUsuario);
            return;
        }
        this.setState({ idUsuario: idUsuario });
        Modal.success({ content: "El usuario ha sido " + (nuevoUsuario ? "creado. Se ha enviado un correo electrónico al usuario para activar su cuenta." : "actualizado") });
        this.historyLocal.push(this.PATHS.VER_USUARIO + '?id=' + idUsuario);
    }

    clickVerUsuario = (e, idUsuario) => {
        this.setState({ idUsuario: idUsuario });
        this.historyLocal.push(this.PATHS.VER_USUARIO + '?id=' + idUsuario);
    }

    clickActivarUsuario = async (e, idUsuario, usuarioNombre, activar, callback) => {
        let respuestaApi = await this.servicioEntidad.activarUsuario(idUsuario,activar);
        if( respuestaApi ) {
            switch (respuestaApi.codigo) {
                case Constantes.ENUMS_API.ENTIDAD.OPERACION_REALIZADA.id:
                    Modal.success({ content: 'Usuario ' + usuarioNombre + ' ' + (activar ? 'Activado' : 'Desactivado') });
                    if (callback !== undefined) {
                        callback();
                    }
                    break;
                default:
                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SUD02]" });
                    break;
            }
        }
    };

    clickEnviarCorreoActivacion = async (e, idUsuario, usuarioNombre, callback) => {
        let respuestaApi = await this.servicioEntidad.enviarCorreoActivacion( idUsuario );
        if( respuestaApi ){
            switch (respuestaApi.codigo) {
                case Constantes.ENUMS_API.ENTIDAD.OPERACION_REALIZADA.id:
                    Modal.success({ content: 'Correo de activación de cuenta enviado al usuario ' + usuarioNombre });
                    if (callback !== undefined) {
                        callback();
                    }
                    break;
                case Constantes.ENUMS_API.ENTIDAD.SUB_USUARIO_CON_CUENTA_ACTIVA.id:
                    Modal.warning({ content: respuestaApi.mensaje });
                    if (callback !== undefined) {
                        callback();
                    }
                    break;
                case Constantes.ENUMS_API.USUARIO.ERROR_SERVICIO_CORREO_ELECTRONICO.id:
                    Modal.error({content:respuestaApi.mensaje});
                    break;
                default:
                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SUD04]" });
                    break;
            }
        }
    }

    enviarCorreoCambioContrasena = async (e, idUsuario, usuarioNombre, callback) => {
        let respuestaApi = await this.servicioEntidad.enviarCorreoCambioContrasena( idUsuario );
        if( respuestaApi ){
            switch (respuestaApi.codigo) {
                case Constantes.ENUMS_API.ENTIDAD.OPERACION_REALIZADA.id:
                    Modal.success({ content: 'Correo de cambio de contraseña enviado al usuario ' + usuarioNombre });
                    if (callback !== undefined) {
                        callback();
                    }
                    break;
                case Constantes.ENUMS_API.USUARIO.ERROR_SERVICIO_CORREO_ELECTRONICO.id:
                    Modal.error({content:respuestaApi.mensaje});
                    break;    
                default:
                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SUD06]" });
                    break;
            }
        }
    }

    clickAgregarUsuario = e => {
        this.setState({ idUsuario: 0 });
        this.historyLocal.push(this.PATHS.AGREGAR_USUARIO);
    }

    clickEditarUsuario = (e, idUsuario) => {
        this.setState({ idUsuario: idUsuario });
        this.historyLocal.push(this.PATHS.EDITAR_USUARIO + '?id=' + idUsuario);
    }


    render() {
        const { componente, usuario } = this.state;

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <Switch>
                                <Route path={this.PATHS.VER_USUARIO}>
                                    {Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.VER_DETALLE_SUB_USUARIO_DEPENDENCIA.id) ?
                                        <VerSubUsuarioDependencia   callbackEditarUsuario={ this.clickEditarUsuario }
                                                                    callbackActivarUsuario={ this.clickActivarUsuario }
                                                                    callbackEnviarCorreoActivacion={this.clickEnviarCorreoActivacion}
                                                                    callbackEnviarCorreoCambioContrasena={this.enviarCorreoCambioContrasena}/>
                                        :
                                        (<>
                                            <label>Ver usuario no disponible.{Constantes.MENSAJE_USUARIO_SIN_PERMISOS}</label>
                                        </>)
                                    }
                                </Route>
                                <Route path={this.PATHS.AGREGAR_USUARIO}>
                                    {Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.AGREGAR_SUB_USUARIO_DEPENDENCIA.id) ?
                                        <AgregarEditarSubUsuarioDependencia onSuccess={(idUsuario, cancelar) => { this.subUsuarioGuardado(true, idUsuario, cancelar) }} />
                                        :
                                        (<>
                                            <label>Agregar usuario no disponible.{Constantes.MENSAJE_USUARIO_SIN_PERMISOS}</label>
                                        </>)
                                    }
                                </Route>
                                <Route path={this.PATHS.EDITAR_USUARIO}>
                                    {Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.EDITAR_INFORMACION_SUB_USUARIO_DEPENDENCIA.id) ?
                                        <AgregarEditarSubUsuarioDependencia onSuccess={( idUsuario, cancelar ) => {this.subUsuarioGuardado( false, idUsuario, cancelar )}} />
                                        :
                                        (<>
                                            <label>Editar usuario no disponible.{Constantes.MENSAJE_USUARIO_SIN_PERMISOS}</label>
                                        </>)
                                    }
                                </Route>
                                <Route path={this.PATHS.USUARIOS}>
                                    <>
                                        <div className="div-lista-botones">
                                            <Button type="primary"
                                                onClick={this.clickAgregarUsuario}
                                                icon={<PlusOutlined />}
                                                disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.AGREGAR_SUB_USUARIO_DEPENDENCIA.id)}>
                                                Agregar usuario
                                        </Button>
                                        </div>
                                        {Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.LISTAR_SUB_USUARIOS_DEPENDENCIA.id) ?
                                            < ListarSubUsuariosDependencia clickVerUsuario={this.clickVerUsuario}
                                                callbackActivarUsuario={this.clickActivarUsuario}
                                                callbackEnviarCorreoActivacion={this.clickEnviarCorreoActivacion} />
                                            :
                                            (<>
                                                <label>Lista de usuarios no disponible.{Constantes.MENSAJE_USUARIO_SIN_PERMISOS}</label>
                                            </>)
                                        }

                                    </>
                                </Route>
                            </Switch>
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:SUD]</label>) : (<></>))
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(SubUsuariosDependencia));