import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter, Switch, Route } from "react-router-dom";
import { BankOutlined } from '@ant-design/icons';
import { Tabs, Spin } from 'antd';
import InformacionBasicaEntidad from '../../persona-juridica/InformacionBasicaEntidad'
import RepresentantesLegales from '../../persona-juridica/representantes-legales/RepresentantesLegales'
import SubUsuarios from '../../persona-juridica/sub-usuarios/SubUsuarios'
import PersonaJuridicaDocumentos from '../../persona-juridica/PersonaJuridicaDocumentos'
import ServicioPersonaJuridica from '../../servicios/usuarios/ServicioPersonaJuridica'
import ServicioUsuarios from '../../servicios/usuarios/ServicioUsuarios';
import { withCookies } from 'react-cookie';
import ApoderadosJuridica from '../../persona-juridica/apoderados-juridica/ApoderadosJuridica';
import Constantes from '../../utilidades/Constantes';
import { HookReactRouterV6Parametros } from '../../general/hooks/HookReactRouterV6Parametros';

const { TabPane } = Tabs;

class PersonaJuridica extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
    };


    constructor(props) {
        super(props);
        //console.log('PersonaJuridica.props', props);
        this.historyLocal = props.history;
        // Se toma el idPersonaJuridica de la url por medio del HookReactRouterV6Parametros
        this.idPersonaJuridica = props.idPersonaJuridica;
        
        this.PATHS_TABS = {
            DATOS: {
                pestana: `/datos`,
                paths: [`/persona-juridica/:idPersonaJuridica`]
            },
            REPRESENTANTES: {
                pestana: `/representantes-legales`,
                paths: [
                    `/persona-juridica/:idPersonaJuridica/representantes-legales`,
                    `/persona-juridica/:idPersonaJuridica/representante-legal/:idRepresentanteLegal`,
                    `/persona-juridica/:idPersonaJuridica/agregar/representante-legal`,
                    `/persona-juridica/:idPersonaJuridica/editar/representante-legal/:idRepresentanteLegal`
                ]
            },
            APODERADOS: {
                pestana: '/apoderados',
                paths: [
                    `/persona-juridica/:idPersonaJuridica/apoderados`,
                    `/persona-juridica/:idPersonaJuridica/apoderado/:idApoderado`,
                    `/persona-juridica/:idPersonaJuridica/agregar/apoderado`,
                    `/persona-juridica/:idPersonaJuridica/editar/apoderado/:idApoderado`
                ]
            },
            USUARIOS: {
                pestana: '/usuarios',
                paths: [
                    `/persona-juridica/:idPersonaJuridica/usuarios`,
                    `/persona-juridica/:idPersonaJuridica/usuario/:idUsuario`,
                    `/persona-juridica/:idPersonaJuridica/agregar/usuario`,
                    `/persona-juridica/:idPersonaJuridica/editar/usuario/:idUsuario`
                ]
            },
            DOCUMENTOS: {
                pestana: '/documentos',
                paths: [
                    `/persona-juridica/:idPersonaJuridica/documentos`,
                ]
            },
        };

        this.servicioPersonaJuridica = new ServicioPersonaJuridica(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        // Se extrae el PATH inicial de la URL
        this.pathInicial = props.history.location.pathname;
        this.props.history.listen(this.manejadorCambioPath);
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        // Validando que el usuario esté en sesion
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr != null) {
            // Valida que tenga la accion habilitada de acceder 
            let habilitado = await this.servicioUsuarios.accionHabilitadaPromesa(Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.ACCEDER_MODULO_USUARIOS.id);
            if (habilitado && habilitado.resultado) {
                this.setSumaState({ 
                    usuario: usr, 
                    componente: this.ENUM_COMPONENTE.VER,
                    tab: this.obtenerPestanaPorPath(this.pathInicial)
                 });
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }

    manejadorCambioPath = (location, action) => {
        //console.log('PersonaJuridica.manejadorCambioPath location.pathname',location.pathname);
        //console.log('PersonaJuridica.manejadorCambioPath tab',this.obtenerPestanaPorPath(location.pathname));
        this.setSumaState({ tab: this.obtenerPestanaPorPath(location.pathname) });
    }


    obtenerPestanaPorPath = (path) => {
        if(path.includes('/representantes-legales') || path.includes('/representante-legal')){
            return this.PATHS_TABS.REPRESENTANTES.pestana;
        }else if(path.includes('/apoderado')){
            return this.PATHS_TABS.APODERADOS.pestana;
        }else if(path.includes('/usuario')){
            return this.PATHS_TABS.USUARIOS.pestana;
        }else if(path.includes('/documentos')){
            return this.PATHS_TABS.DOCUMENTOS.pestana;
        }else{
            return this.PATHS_TABS.DATOS.pestana;
        }
    }

    manejadorClickTab = tab => {
        //console.log('manejadorClickTab tab',tab);
        // Se deja el tab en null para obligar a refrescar el componente hijo
        this.setSumaState({ tab: null });

        let atributos = Object.keys(this.PATHS_TABS);
        for (let index in atributos) {
            let paths_tab = this.PATHS_TABS[atributos[index]];
            
            if( tab === paths_tab.pestana ){
                let path = this.reemplazarValoresEnElPath( paths_tab.paths[0] );
                // Con el tab seleccionado tambien se cambia la URL, se deja dentro del setTimeout con el
                // fin de obligar a refrescar el componente hijo
                setTimeout(() => { this.historyLocal.push(path); }, 100);
                break;
            }                
        }
    };

    reemplazarValoresEnElPath  = (path) => {
        return path
        // Se toma el idPersonaJuridica de la url por medio del HookReactRouterV6Parametros
        .replace(':idPersonaJuridica',this.props.idPersonaJuridica)
        // Se toma el idRepresentanteLegal de la url por medio del HookReactRouterV6Parametros
        .replace(':idRepresentanteLegal',this.props.idRepresentanteLegal)
        // Se toma el idApoderado de la url por medio del HookReactRouterV6Parametros
        .replace(':idApoderado',this.props.idApoderado)
        // Se toma el idUsuario de la url por medio del HookReactRouterV6Parametros
        .replace(':idUsuario',this.props.idUsuario);
    }
    

    render() {

        const { componente, tab } = this.state;

        return (


            <div className="division-contenido seccion-flex-grow-1 bkg-white">

                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>

                            <div className="titulo-seccion">
                                <label> <BankOutlined /> PERSONA JURÍDICA</label>
                                <div className="separador-horizontal-completo"></div>
                            </div>

                            <div>
                                <Tabs activeKey={this.state.tab} onChange={this.manejadorClickTab}>
                                    <TabPane tab="Datos" key={this.PATHS_TABS.DATOS.pestana} />
                                    <TabPane tab="Representantes legales" key={this.PATHS_TABS.REPRESENTANTES.pestana} />
                                    <TabPane tab="Apoderados" key={this.PATHS_TABS.APODERADOS.pestana} />
                                    <TabPane tab="Usuarios" key={this.PATHS_TABS.USUARIOS.pestana} />
                                    <TabPane tab="Documentos" key={this.PATHS_TABS.DOCUMENTOS.pestana} />
                                </Tabs>

                                <Switch>
                                    <Route path={this.PATHS_TABS.REPRESENTANTES.paths}>
                                        {tab !== null ?
                                            <RepresentantesLegales 
                                                // Indica que es el administrador del SUMA quien está accediendo a los representantes legales
                                                administracion={true} 
                                                // Se toma el idPersonaJuridica de la url por medio del HookReactRouterV6Parametros
                                                idPersonaJuridica={this.props.idPersonaJuridica}
                                                // Se toma el idRepresentanteLegal de la url por medio del HookReactRouterV6Parametros
                                                idRepresentanteLegal={this.props.idRepresentanteLegal}/> 
                                                : <></>}
                                    </Route>

                                    <Route path={this.PATHS_TABS.APODERADOS.paths}>
                                        {tab !== null ? 
                                            <ApoderadosJuridica
                                                // Indica que es el administrador del SUMA quien está accediendo a los representantes legales
                                                administracion={true} 
                                                // Se toma el idPersonaJuridica de la url por medio del HookReactRouterV6Parametros
                                                idPersonaJuridica={this.props.idPersonaJuridica}
                                                // Se toma el idApoderado de la url por medio del HookReactRouterV6Parametros
                                                idApoderado={this.props.idApoderado}/> : <></>}
                                    </Route>
                                    <Route path={this.PATHS_TABS.USUARIOS.paths}>
                                        {tab !== null ? 
                                            <SubUsuarios 
                                                // Indica que es el administrador del SUMA quien está accediendo a los representantes legales
                                                administracion={true} 
                                                // Se toma el idPersonaJuridica de la url por medio del HookReactRouterV6Parametros
                                                idPersonaJuridica={this.props.idPersonaJuridica}
                                                // Se toma el idUsuario de la url por medio del HookReactRouterV6Parametros
                                                idUsuario={this.props.idUsuario}/> : <></>}
                                    </Route>
                                    <Route path={this.PATHS_TABS.DOCUMENTOS.paths}>
                                        {tab !== null ? 
                                            <PersonaJuridicaDocumentos 
                                                // Indica que es el administrador del SUMA quien está accediendo a los representantes legales
                                                administracion={true} 
                                                // Se toma el idPersonaJuridica de la url por medio del HookReactRouterV6Parametros
                                                idPersonaJuridica={this.props.idPersonaJuridica}/> : <></>}
                                    </Route>

                                    {/* El path de datos básicos debe ir al final o sino hace match de una con la url sin reisar el resto del path*/}        
                                    <Route exac path={this.PATHS_TABS.DATOS.paths}>
                                        {tab !== null ? <InformacionBasicaEntidad idPersonaJuridica={this.idPersonaJuridica} /> : <></>}
                                    </Route>
                                </Switch>
                            </div>


                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:PERJUR]</label>) : (<></>))
                    )
                }
            </div>

        );
    }
}

export default withRouter(withCookies(HookReactRouterV6Parametros(PersonaJuridica)));