import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Spin, Input, Button, Popconfirm, Modal } from 'antd';
import { EditOutlined,CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import ServicioAdministracion from '../servicios/ServicioAdministracion'
import { withCookies } from 'react-cookie';
import Constantes from '../utilidades/Constantes'
import Validadores from '../utilidades/Validadores'
import Utilidades from '../utilidades/Utilidades'

class PreguntasFrecuentes extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        cargando: true,
        componente: this.ENUM_COMPONENTE.CARGANDO
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioAdministracion = new ServicioAdministracion(props);

        this.inputsDisabled = {};
    }


    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {


        let preguntasFrecuentes = await this.servicioAdministracion.obtenerPreguntasFrecuentes();
        if( preguntasFrecuentes ){
            preguntasFrecuentes.sort((a, b) => a.id > b.id ? 1 : -1);

            // Se procede a crear las referencias dinámicas para manejar los input, y los botones
            for( let pregunta of preguntasFrecuentes ){
                this.crearReferencias( pregunta.id );
            }
            
            this.setSumaState({ componente: this.ENUM_COMPONENTE.VER, cargando: false, 
                                inputsDisabled:this.inputsDisabled, preguntasFrecuentes });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    crearReferencias = ( index ) => {
        this["refInput_pre_" + index] = React.createRef();
        this["refInput_res_" + index] = React.createRef();
        this["refBtnEdit_" + index] = React.createRef();
        this["refBtnSave_" + index] = React.createRef();
        this["refBtnCancel_" + index] = React.createRef();
        this["refBtnEliminar_" + index] = React.createRef();
        this.inputsDisabled["refInput_pre_" + index] = { disabled: index !== 0 };
        this.inputsDisabled["refInput_res_" + index] = { disabled: index !== 0 };
    }

    clicEditar = ( id ) => {
        let inputsDisabled = this.state.inputsDisabled;
        
        for( let pregunta of this.state.preguntasFrecuentes ){
            if( pregunta.id !== id ){
                // Se deshabilitan los elementos que no se van a trabajar
                if( this["refBtnEdit_" + pregunta.id] ){
                    this["refBtnEdit_" + pregunta.id].current.disabled = true;                
                    this["refBtnEdit_" + pregunta.id].current.hidden = false;
                }
                if( this["refBtnSave_" + pregunta.id] ){             
                    this["refBtnSave_" + pregunta.id].current.hidden = true;
                }
                if( this["refBtnCancel_" + pregunta.id] ){             
                    this["refBtnCancel_" + pregunta.id].current.hidden = true;
                }
                if( this["refBtnEliminar_" + pregunta.id] ){        
                    this["refBtnEliminar_" + pregunta.id].current.disabled = true;        
                    this["refBtnEliminar_" + pregunta.id].current.hidden = false;
                }
                if( this["refInput_pre_" + pregunta.id] ){             
                    inputsDisabled["refInput_pre_" + pregunta.id].disabled = true;
                }
                if( this["refInput_res_" + pregunta.id] ){             
                    inputsDisabled["refInput_res_" + pregunta.id].disabled = true;
                }
            }else{
                // Se habilita el elemento que se desea trabajar
                this["refBtnEdit_" + id].current.hidden = true;
                this["refBtnEliminar_" + id].current.hidden = true;
                this["refBtnSave_" + id].current.hidden = false;
                this["refBtnCancel_" + id].current.hidden = false;
                inputsDisabled["refInput_pre_" + id].disabled = false;
                inputsDisabled["refInput_res_" + id].disabled = false;
            }
        }
        
        this.setSumaState({ inputsDisabled });
    }

    clicCancelar = ( id ) => {
        let inputsDisabled = this.state.inputsDisabled;
        let preguntasFrecuentes = this.state.preguntasFrecuentes;
        for( let pregunta of preguntasFrecuentes ){
            if( pregunta.id !== id ){
                // Se deshabilitan los elementos que no se van a trabajar
                if( this["refBtnEdit_" + pregunta.id] ){
                    this["refBtnEdit_" + pregunta.id].current.disabled = false;                
                    this["refBtnEdit_" + pregunta.id].current.hidden = false;
                }
                if( this["refBtnSave_" + pregunta.id] ){             
                    this["refBtnSave_" + pregunta.id].current.hidden = true;
                }
                if( this["refBtnCancel_" + pregunta.id] ){             
                    this["refBtnCancel_" + pregunta.id].current.hidden = true;
                }
                if( this["refBtnEliminar_" + pregunta.id] ){    
                    this["refBtnEliminar_" + pregunta.id].current.disabled = false;                         
                    this["refBtnEliminar_" + pregunta.id].current.hidden = false;
                }
                if( this["refInput_pre_" + pregunta.id] ){             
                    inputsDisabled["refInput_pre_" + pregunta.id].disabled = true;
                }
                if( this["refInput_res_" + pregunta.id] ){             
                    inputsDisabled["refInput_res_" + pregunta.id].disabled = true;
                }
            }else{
                // Se habilita el elemento que se desea trabajar
                this["refBtnEdit_" + id].current.hidden = false;
                this["refBtnEliminar_" + id].current.hidden = false;
                this["refBtnSave_" + id].current.hidden = true;
                this["refBtnCancel_" + id].current.hidden = true;
                inputsDisabled["refInput_pre_" + id].disabled = true;
                inputsDisabled["refInput_res_" + id].disabled = true;
            }
        }

        if( id === 0 ){
            // Remueve el último item el cual se supone que es la nueva pregunta que ya no se va a agregar
            preguntasFrecuentes.pop(); 
            this.setSumaState({ preguntasFrecuentes, bloquearAgregar:false });    
        }
        
        this.setSumaState({ inputsDisabled });
    }

    clicGuardar = async ( id ) => {
        
        let pregunta = this["refInput_pre_" + id].current.resizableTextArea.props.value;
        let respuesta = this["refInput_res_" + id].current.resizableTextArea.props.value;

        if( !Validadores.stringValido(pregunta) ){
            Modal.error({ content: 'Debes ingresar la pregunta.' });
            return;
        }else if( !Validadores.validarTamano(pregunta, 1, 4000 ) ){
            Modal.error({ content: 'La pregunta no puede contener más de 4000 caracteres.' });
            return;
        }
        
        if( !Validadores.stringValido(respuesta) ){
            Modal.error({ content: 'Debes ingresar la respuesta.' });
            return;
        }else if( !Validadores.validarTamano(respuesta, 1, 4000 ) ){
            Modal.error({ content: 'La respuesta no puede contener más de 4000 caracteres.' });
            return;
        }

        this.setSumaState({ cargando: true });
        let preguntaFrecuente = { id, pregunta, respuesta };
        let respuestaApi = id === 0 ?
                await this.servicioAdministracion.agregarPreguntaFrecuente( preguntaFrecuente ) :
                await this.servicioAdministracion.actualizarPreguntaFrecuente( preguntaFrecuente );

        if (respuestaApi) {
            switch (respuestaApi.codigo) {
                case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id:
                    let inputsDisabled = this.state.inputsDisabled;
                    let preguntasFrecuentes = JSON.parse(JSON.stringify(this.state.preguntasFrecuentes ));
                    for( let i = 0; i < preguntasFrecuentes.length; i++ ){
                        let preguntaFrecuente = preguntasFrecuentes[i];
                        if( preguntaFrecuente.id !== id ){
                            // Se deshabilitan los elementos que no se van a trabajar
                            if( this["refBtnEdit_" + preguntaFrecuente.id] ){
                                this["refBtnEdit_" + preguntaFrecuente.id].current.disabled = false;                
                            }
                            if( this["refBtnEliminar_" + preguntaFrecuente.id] ){
                                this["refBtnEliminar_" + preguntaFrecuente.id].current.disabled = false;                
                            }
                        }else{
                            // Se habilita el elemento que se desea trabajar
                            this["refBtnEdit_" + id].current.hidden = false;
                            this["refBtnEliminar_" + id].current.hidden = false;
                            this["refBtnSave_" + id].current.hidden = true;
                            this["refBtnCancel_" + id].current.hidden = true;
                            inputsDisabled["refInput_pre_" + id].disabled = true;
                            inputsDisabled["refInput_res_" + id].disabled = true;
                            preguntaFrecuente.pregunta = pregunta;
                            preguntaFrecuente.respuesta = respuesta;

                            if( id === 0 ){
                                preguntaFrecuente.id = parseInt(respuestaApi.resultado);
                                this.crearReferencias( preguntaFrecuente.id );
                                this.setSumaState({ bloquearAgregar:false }); 
                            }
                        }
                    }

                    this.setSumaState({ preguntasFrecuentes, inputsDisabled, cargando: false });
                    Modal.success({ content: 'Pregunta frecuente actualizado satisfactoriamente.' });
                    break;   
                default:
                    Modal.error({ content: 'No fue posible actualizar la pregunta frecuente. Por favor intenta mas tarde.' });
                    break;
            }
        }
    }

    clicAgregarPreguntaFrecuente = () => {
        let preguntasFrecuentes = this.state.preguntasFrecuentes;
        this.crearReferencias( 0 );

        for( let pregunta of this.state.preguntasFrecuentes ){
            // Se deshabilitan los elementos que no se van a trabajar
            if( this["refBtnEdit_" + pregunta.id] ){
                this["refBtnEdit_" + pregunta.id].current.disabled = true;                
                this["refBtnEdit_" + pregunta.id].current.hidden = false;
            }
            if( this["refBtnSave_" + pregunta.id] ){             
                this["refBtnSave_" + pregunta.id].current.hidden = true;
            }
            if( this["refBtnCancel_" + pregunta.id] ){             
                this["refBtnCancel_" + pregunta.id].current.hidden = true;
            }
            if( this["refBtnEliminar_" + pregunta.id] ){
                this["refBtnEliminar_" + pregunta.id].current.disabled = true;                
                this["refBtnEliminar_" + pregunta.id].current.hidden = false;
            }
            if( this["refInput_pre_" + pregunta.id] ){             
                this.inputsDisabled["refInput_pre_" + pregunta.id].disabled = true;
            }
            if( this["refInput_res_" + pregunta.id] ){             
                this.inputsDisabled["refInput_res_" + pregunta.id].disabled = true;
            }
        }
        preguntasFrecuentes.push({id:0,pregunta:"",respuesta:""});
        this.setSumaState({ preguntasFrecuentes, bloquearAgregar:true });
    }

    clicEliminar = async ( id ) => {

        this.setSumaState({ cargando: true });
        let respuestaApi = await this.servicioAdministracion.eliminarPreguntaFrecuente( id )

        if (respuestaApi) {
            switch (respuestaApi.codigo) {
                case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id:
                    let inputsDisabled = this.state.inputsDisabled;
                    let preguntasFrecuentes = JSON.parse(JSON.stringify(this.state.preguntasFrecuentes ));
                    for( let i = 0; i < preguntasFrecuentes.length; i++ ){
                        let preguntaFrecuente = preguntasFrecuentes[i];   
                        if( preguntaFrecuente.id === id ){
                            preguntasFrecuentes.splice(i,1);
                            break;
                        }
                    }

                    this["refInput_pre_" + id] = undefined;
                    this["refInput_res_" + id] = undefined;
                    this["refBtnEdit_" + id] = undefined;
                    this["refBtnSave_" + id] = undefined;
                    this["refBtnCancel_" + id] = undefined;
                    this["refBtnEliminar_" + id] = undefined;
                    this.inputsDisabled["refInput_pre_" + id] = undefined;
                    this.inputsDisabled["refInput_res_" + id] = undefined;

                    delete this["refInput_pre_" + id];
                    delete this["refInput_res_" + id];
                    delete this["refBtnEdit_" + id];
                    delete this["refBtnSave_" + id];
                    delete this["refBtnCancel_" + id];
                    delete this["refBtnEliminar_" + id];
                    delete this.inputsDisabled["refInput_pre_" + id];
                    delete this.inputsDisabled["refInput_res_" + id];


                    this.setSumaState({ preguntasFrecuentes, inputsDisabled, cargando: false });
                    Modal.success({ content: 'Pregunta frecuente eliminada satisfactoriamente.' });
                    break;   
                default:
                    Modal.error({ content: 'No fue posible eliminar la pregunta frecuente. Por favor intenta mas tarde.' });
                    break;
            }
        }

    }

    


    render() {
        const { componente, inputsDisabled, preguntasFrecuentes, bloquearAgregar } = this.state;
        const { TextArea } = Input;

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>
                                
                                {preguntasFrecuentes ? 
                                <>
                                    {preguntasFrecuentes.map( (preguntaFrecuente, index) => (
                                    <div key={index} className="comunicaciones">
                                        <div className="pregunta">
                                            {inputsDisabled["refInput_pre_" + preguntaFrecuente.id].disabled ?
                                                <div>
                                                    <span>Pregunta:&nbsp;</span>{preguntaFrecuente.pregunta}
                                                </div> :
                                                <div className="texto-entrada">
                                                    <label>Pregunta:</label>
                                                    <TextArea ref={this["refInput_pre_" + preguntaFrecuente.id]} defaultValue={preguntaFrecuente.pregunta}/>
                                                </div>
                                            }
                                            {Utilidades.accionPermitida(this.props.usuario, Constantes.ENUMS_MODULOS.COMUNICACIONES.ACCIONES.EDITAR_PREGUNTA_FRECUENTE.id) ?
                                                <Button ref={this["refBtnEdit_" + preguntaFrecuente.id]} onClick={(e) => {this.clicEditar(preguntaFrecuente.id);}}  icon={<EditOutlined />} hidden={preguntaFrecuente.id === 0} /> : null
                                            }
                                            {Utilidades.accionPermitida(this.props.usuario, Constantes.ENUMS_MODULOS.COMUNICACIONES.ACCIONES.ELIMINAR_PREGUNTA_FRECUENTE.id) ?
                                                <Popconfirm title={'Confirma eliminar la pregunta frecuente?'} 
                                                    okText="Si"
                                                    cancelText="No" 
                                                    onConfirm={(e) => {this.clicEliminar( preguntaFrecuente.id )}}>
                                                        <Button ref={this["refBtnEliminar_" + preguntaFrecuente.id]} className={"btn-rigth"} icon={<CloseOutlined />} type="primary" hidden={preguntaFrecuente.id === 0}/>
                                                </Popconfirm> : null
                                            }

                                            <Popconfirm title={'Confirma editar la pregunta frecuente?'} 
                                                        okText="Si"
                                                        cancelText="No" 
                                                        onConfirm={(e) => {this.clicGuardar( preguntaFrecuente.id )}}>
                                                <Button ref={this["refBtnSave_" + preguntaFrecuente.id]}  type="primary" icon={<CheckOutlined />}  hidden={preguntaFrecuente.id !== 0}/>
                                            </Popconfirm>
                                            <Button ref={this["refBtnCancel_" + preguntaFrecuente.id]} className={"btn-rigth"} onClick={(e) => {this.clicCancelar(preguntaFrecuente.id);}} icon={<CloseOutlined />} hidden={preguntaFrecuente.id !== 0}/>
                                        </div>
                                        <div className="respuesta">
                                            {inputsDisabled["refInput_res_" + preguntaFrecuente.id].disabled ?
                                                <div><span>Respuesta:&nbsp;</span>{preguntaFrecuente.respuesta}</div> :
                                                <div className="texto-entrada">
                                                    <label>Descripción:</label>
                                                    <TextArea ref={this["refInput_res_" + preguntaFrecuente.id]} rows={4} defaultValue={preguntaFrecuente.respuesta}/>
                                                </div>
                                            }
                                        </div>
                                        
                                    </div> 
                                    ))}

                                    {Utilidades.accionPermitida(this.props.usuario, Constantes.ENUMS_MODULOS.COMUNICACIONES.ACCIONES.AGREGAR_PREGUNTA_FRECUENTE.id) ?
                                        <Button type="primary" onClick={(e) => {this.clicAgregarPreguntaFrecuente();}}  icon={<PlusOutlined />} disabled={bloquearAgregar}>
                                            Agregar pregunta frecuente
                                        </Button> : null }

                                </>
                                : null }

                            </Spin>

                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:PRGFREC]</label>) : (<></>))
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(PreguntasFrecuentes));