import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Spin, Table, Button, Tooltip, Tag } from 'antd';
import { withCookies } from 'react-cookie';
import { BankOutlined, SearchOutlined } from '@ant-design/icons';
import ServicioDatosConstantes from '../servicios/ServicioDatosConstantes'
import Constantes from '../utilidades/Constantes'
import Utilidades from '../utilidades/Utilidades'
import FiltroColumna from '../general/tabla/FiltroColumna'
import FiltroEtiqueta from '../general/tabla/FiltroEtiqueta'
import ServicioAdministracion from '../servicios/ServicioAdministracion'
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import EstilosGlobales from '../utilidades/EstilosGlobales';

class ListarEntidades extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        cargando: false,        
        filtro: {},
        filtroEtiquetas: [],
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.callbackVerEntidad = props.callbackVerEntidad;
        
        this.servicioAdministracion = new ServicioAdministracion(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        let contexto = this;
        this.filtroColumna = new FiltroColumna(props,( valores ) => {contexto.setSumaState(valores, contexto)});
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores, contexto) {
        if( !contexto )
            contexto = this;
            
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        this.setSumaState({ cargando: true, });
        // Validando que el usuario esté en sesion
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr !== null) {
            // Valida que tenga la accion habilitada de listar
            let habilitado = await this.servicioUsuarios.accionHabilitadaPromesa(Constantes.ENUMS_MODULOS.ENTIDAD.ACCIONES.LISTAR_ENTIDADES.id);
            if( habilitado && habilitado.resultado ){

                const estados = await this.servicioDatosConstantes.obtenerEstadosEntidad();
                let estadosEntidad = [];
                if( estados ){
                    for( let estado of estados ){
                        estadosEntidad.push({ label: estado.estadoEntidad, value: estado.id });
                    }
                }
                
                await this.cargarDatosTabla(this);
                this.setSumaState({
                    componente: this.ENUM_COMPONENTE.VER,cargando: false,
                    estadosEntidad: estadosEntidad, usuario: usr,
                });
            }
        } else {
            this.setSumaState({componente:this.ENUM_COMPONENTE.NO_DISPONIBLE,cargando: false,usuario: null});
        }
    }

    

    // 'limpiarFiltro': Método (clearFilters) que es propio de la tabla antDesign para limpiar los filtros
    // 'confirmarFiltro': Método (confirm) que es própio de la tabla antDesign para aplicar el filtro
    // 'dataIndex': Retorna el campo que se está filtrando
    // 'selectedKeys': Retorna el valor a filtrar;
    async cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) {
        let filtro = contexto.state.filtro;
        if (dataIndex !== undefined) { // Si el filtro es aplicado, se agrega la columna filtrada
            filtro[dataIndex] = selectedKeys;
        }        
        contexto.setSumaState({ cargando: true, filtro: filtro });

        // Se construye el filtroApi a partir de lo que fue filtrado en la tabla
        let filtroApi = {
            ...(filtro.id && filtro.id.length > 0 && {id:filtro.id[0]} ),
            ...(filtro.razonSocial && filtro.razonSocial.length > 0 && {razonSocial:filtro.razonSocial[0]} ),
            ...(filtro.sigla && filtro.sigla.length > 0 && {sigla:filtro.sigla[0]} ),
            ...(filtro.nit && filtro.nit.length > 0 && {nit:filtro.nit[0]} ),
            ...(filtro.estado && filtro.estado.length > 0 && filtro.estado[0].opc && {estado:filtro.estado[0].opc.join()} ),
            ...(filtro.tipoSector && filtro.tipoSector.length > 0 && {tipoSector:filtro.tipoSector[0]} )
        };
        
        await this.servicioAdministracion.obtenerEntidades(filtroApi)
            .then((respuestaApi) => {
                if (respuestaApi) {
                    let entidades = [];
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.ADMINISTRACION.DATOS_RETORNADOS.id:
                            entidades = respuestaApi.resultado;
                            break;
                        default: break;
                    }
                    contexto.setSumaState({ entidades: entidades, cargando: false });
                }
            }).then(() => {
                if (limpiarFiltro !== undefined) { limpiarFiltro(); }
                if (confirmarFiltro !== undefined) { confirmarFiltro(); }
            });
    }



    render() {

        const { entidades, componente, usuario, estadosEntidad } = this.state;
        let { filtro } = this.state;
        filtro = filtro || {};
        let contexto = this;

        const columns = [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                with:100,
                sorter: (a, b) => a.id - b.id,
                filteredValue: filtro.id || null,
                ...this.filtroColumna.busquedaTexto(
                    'Identificador',
                    'id',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            {
                title: 'Razón social',
                dataIndex: 'razonSocial',
                key: 'razonSocial',
                sorter: (a, b) => a.razonSocial ? a.razonSocial.localeCompare(b.razonSocial) : 1,
                filteredValue: filtro.razonSocial || null,
                ...this.filtroColumna.busquedaTexto(
                    'Razón social',
                    'razonSocial',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            {
                title: 'Sigla',
                dataIndex: 'sigla',
                key: 'sigla',
                sorter: (a, b) => a.sigla ? a.sigla.localeCompare(b.sigla) : 1,
                filteredValue: filtro.sigla || null,
                ...this.filtroColumna.busquedaTexto(
                    'Sigla',
                    'sigla',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                responsive: ['md'], /* visible en pantalla con ancho ≥ 768px*/
            },
            {
                title: 'Nit',
                dataIndex: 'nit',
                key: 'nit',
                sorter: (a, b) => a.nit ? a.nit.localeCompare(b.nit) : 1,
                filteredValue: filtro.nit || null,
                ...this.filtroColumna.busquedaTexto(
                    'Nit',
                    'nit',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                responsive: ['md'], /* visible en pantalla con ancho ≥ 768px*/
            },
            {
                title: 'Tipo Sector',
                dataIndex: 'tipoSector',
                key: 'tipoSector',
                sorter: (a, b) => a.tipoSector ? a.tipoSector.localeCompare(b.tipoSector) : 1,
                filteredValue: filtro.tipoSector || null,
                ...this.filtroColumna.busquedaTexto(
                    'Tipo Sector',
                    'tipoSector',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                responsive: ['md'], /* visible en pantalla con ancho ≥ 768px*/
            },
            {
                title: 'Estado',
                key: 'estado',
                align: 'center',
                dataIndex: 'estado',
                sorter: (a, b) => a.estado ? a.estado.localeCompare(b.estado) : 1,
                filteredValue: filtro.estado || null,
                ...this.filtroColumna.busquedaOpciones(
                    'Estado',
                    'estado',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) },
                    estadosEntidad
                ),
                render: (text, record) => (
                    <>
                        <Tag key={text} color={
                            (record.idEstado === Constantes.ENUMS.ESTADO_ENTIDAD.ACTIVO.id)
                                ?
                                'green'
                                :
                                (record.idEstado === Constantes.ENUMS.ESTADO_ENTIDAD.INACTIVO.id
                                    ?
                                    'volcano'
                                    :
                                    ''
                                )
                        }>
                            {text.toUpperCase()}
                        </Tag>

                    </>
                ),
                responsive: ['sm'], /* visible en pantalla con ancho ≥ 576px*/
            },
            {
                title: 'Opciones',
                key: 'opciones',
                align: 'center',
                className: 'columna-opciones',
                width: 112,
                render: (text, record) => (
                    <>
                        <Tooltip title="Ver entidad">
                            <Button shape="circle" disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.ENTIDAD.ACCIONES.VER_ENTIDAD.id)}
                                icon={<BankOutlined style={{ 'color': '#c90a00' }} />}
                                onClick={(e) => { this.callbackVerEntidad(e, record.id) }} />
                        </Tooltip>
                    </>
                ),
            },
        ];

        return (
            <>
                <EstilosGlobales.CONTENEDOR_MEDIO />
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>            
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>

                                {
                                    this.state.filtroEtiquetas.length > 0 ?
                                        (<div className="seccion-filtros-etiquetas">
                                            <div className="titulo-filtro-etiquetas">
                                                <label>Filtros <SearchOutlined /> :</label>
                                            </div>

                                            {
                                                this.state.filtroEtiquetas.map((filtroEtiqueta, i) => {
                                                    return (
                                                        <FiltroEtiqueta key={i}
                                                            dataIndex={filtroEtiqueta.dataIndex}
                                                            texto={filtroEtiqueta.texto}
                                                            onClose={filtroEtiqueta.borrarFiltro} />
                                                    )
                                                })
                                            }
                                        </div>)
                                        :
                                        (<></>)
                                }

                                <Table columns={columns}
                                    dataSource={entidades}
                                    rowKey={"id"} />
                            </Spin>
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:LISENT]</label>) : (<></>))
                    )
                }
            </>
        );
    }
}

export default withRouter(withCookies(ListarEntidades));