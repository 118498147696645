import React, { Component } from 'react';
import { withRouter, Switch, Route } from "react-router-dom";
import { Modal } from 'antd';
import { withCookies } from 'react-cookie';
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import Carrusel from '../principal/Carrusel'
import QueEsSuma from '../principal/QueEsSuma'
import Normatividad from '../principal/Normatividad'
import PreguntasFrecuentes from '../principal/PreguntasFrecuentes'
import ZonaUsuarioSelector from '../zona-usuario/ZonaUsuarioSelector'
import { TransitionGroup, /*CSSTransition*/ } from "react-transition-group";
import SumaContexto from '../contexto/SumaContexto';
import SeleccionTipoUsuario from '../zona-usuario/crear-cuenta/SeleccionTipoUsuario'
import CrearUsuarioPersonaJuridica from '../persona-juridica/CrearUsuarioPersonaJuridica'
import CrearUsuarioPersonaNatural from '../persona-natural/CrearUsuarioPersonaNatural'
import ActivarCuenta from '../zona-usuario/crear-cuenta/ActivarCuenta'
import ActivarDadepCuenta from '../zona-usuario/crear-cuenta/ActivarDadepCuenta'
import ActivarUsuarioSumaV1 from '../zona-usuario/crear-cuenta/ActivarUsuarioSumaV1'
import ActivarUsuarioMigracion from '../zona-usuario/crear-cuenta/ActivarUsuarioMigracion'
import ActivarUsuarioMigracionDadep from '../zona-usuario/crear-cuenta/ActivarUsuarioMigracionDadep'
import CambioContrasena from '../zona-usuario/crear-cuenta/CambioContrasena'
import DesbloquearUsuario from '../zona-usuario/crear-cuenta/DesbloquearUsuario'
import MisSitios from '../mis-sitios/MisSitios'
import DatosDeLaEntidad from '../persona-juridica/DatosDeLaEntidad'
import DatosPersonaNatural from '../persona-natural/DatosPersonaNatural'
import MiCuenta from '../mi-cuenta/MiCuenta'
import Constantes from '../utilidades/Constantes'
import RegistrarSolicitud from '../registrar-solicitud/RegistrarSolicitud'
import RecuperarContrasena from '../zona-usuario/crear-cuenta/RecuperarContrasena'
import EnviarCorreoActivacionCuenta from '../zona-usuario/crear-cuenta/EnviarCorreoActivacionCuenta'
import MisSolicitudes from '../mis-solicitudes/MisSolicitudes'
import SolicitudesDependencia from '../solicitudes-dependencia/SolicitudesDependencia'
import SolicitudesAsociadas from '../solicitudes-asociadas/SolicitudesAsociadas'
import CambioContrasenaTemporal from '../zona-usuario/crear-cuenta/CambioContrasenaTemporal'
import DatosDependencia from '../dependencia/DatosDependencia'
import Usuarios from '../usuarios/Usuarios'
import Entidades from '../entidad/Entidades'
import Configuracion from '../configuracion/Configuracion'
import AsignarUsuarioCorreoElectronico from '../zona-usuario/crear-cuenta/AsignarUsuarioCorreoElectronico'
import Comunicaciones from '../comunicaciones/Comunicaciones';
import Reportes from '../reportes/Reportes';
import { HookNotificacion } from '../general/hooks/HookNotificacion';
import OpcionesSuma from '../dependencia/opciones-suma/OpcionesSuma';
import RolesDependencia from '../dependencia/roles/RolesDependencia';
import Solicitudes from '../solicitudes/Solicitudes';
import Documentos from '../documentos/Documentos';


function Vista_Home() {
  return (
    <>
      <HookNotificacion />
      <Carrusel />
    </>);
}
function Vista_QueEsSuma() { return <QueEsSuma />; }
function Vista_Normatividad() { return <Normatividad />; }
function Vista_Preguntas() { return <PreguntasFrecuentes />; }
function Vista_Activar_Cuenta() { return <ActivarCuenta />; }
function Vista_Activar_Dadep_Cuenta() { return <ActivarDadepCuenta />; }
function Vista_Activar_Usuario_SumaV1() { return <ActivarUsuarioSumaV1 />; }
function Vista_Activar_Usuario_Migracion() { return <ActivarUsuarioMigracion />; }
function Vista_Activar_Usuario_Migracion_Dadep() { return <ActivarUsuarioMigracionDadep />; }
function Vista_Cambiar_Contrasena() { return <CambioContrasena />; }
function Vista_Desbloquear_Usuario() { return <DesbloquearUsuario />; }
function Vista_Crear_Cuenta() { return <SeleccionTipoUsuario />; }
function Vista_Crear_Persona_Juridica() { return <CrearUsuarioPersonaJuridica />; }
function Vista_Crear_Persona_Natural() { return <CrearUsuarioPersonaNatural />; }
function Vista_Mis_Sitios() { return < MisSitios /> };
function Vista_Datos_Persona_Juridica() { return <DatosDeLaEntidad /> };
function Vista_Datos_Persona_Natural() { return <DatosPersonaNatural /> };
function Vista_Datos_Dependencia() { return <DatosDependencia /> };
function Vista_Mi_Cuenta() { return <MiCuenta /> }
function Vista_Registrar_Solicitud() { return <RegistrarSolicitud /> }
function Vista_Recuperar_Contrasena() { return <RecuperarContrasena /> }
function Vista_Enviar_Correo_Activacion_Cuenta() { return <EnviarCorreoActivacionCuenta /> }
function Vista_Mis_Solicitudes() { return <MisSolicitudes /> }
function Vista_Solicitudes_Dependencia() { return <SolicitudesDependencia /> }
function Vista_Solicitudes_Asociadas() { return <SolicitudesAsociadas /> }
function Vista_Usuarios() { return <Usuarios /> }
function Vista_Entidades() { return <Entidades /> }
function Vista_Solicitudes() { return <Solicitudes /> }
function Vista_Configuracion() { return <Configuracion /> }
function Vista_Comunicaciones() { return <Comunicaciones /> }
function Vista_Reportes() { return <Reportes /> }
function Vista_Documentos() { return <Documentos /> }

class Principal extends Component {

  static contextType = SumaContexto;

  constructor(props) {
    super(props);
    const { cookies, history } = props;
    this.servicioUsuarios = new ServicioUsuarios(props);
    this.cookies = cookies;
    this.cerrarSesionUsuario = props.cerrarSesionUsuario;
    this.history = history;
  }

  render() {

    return (
      <div className="principal">

        {(this.props.usuario !== null && this.props.usuario.contrasenaTemporal) ?
          (
            /**
             * AQUI SE BUSCA VALIDAR SI EL USUARIO TIENE QUE CAMBIAR SU CONTRASEÑA TEMPORAL
             * CON EL FIN DE OBLIGARLO A MODIFICAR SU CONTRASEÑA MANTENIENDO SOLO LA VISTA
             * DE CambioContrasenaTemporal.js
             * */
            <CambioContrasenaTemporal callbackCambioContrasenaTemporalSatisfactorio={
              () => {
                Modal.success({ content: "La contraseña ha sido actualizada satisfactoriamente." });
                this.props.usuario.contrasenaTemporal = false;
                this.history.push(Constantes.URL_PRIVADAS.OPCIONES);
              }
            } />

          )
          :
          (this.props.usuario !== null && this.props.usuario.usuarioSumaV1) ?
            (<>
              <AsignarUsuarioCorreoElectronico />
            </>)
            :
            (
              <Switch>
                <Route exact path={[Constantes.URL_PUBLICAS.INICIO,
                Constantes.URL_PUBLICAS.HOGAR,
                Constantes.URL_PUBLICAS.QUE_ES_SUMA,
                Constantes.URL_PUBLICAS.NORMATIVIDIDAD,
                Constantes.URL_PUBLICAS.PREGUNTAS,
                Constantes.URL_PRIVADAS.OPCIONES,
                ]} >

                  <div className="seccion-principal principal-home flex">
                    <TransitionGroup className="principal-transition-group">
                      <Route exact path={[Constantes.URL_PUBLICAS.INICIO, Constantes.URL_PUBLICAS.HOGAR]} component={Vista_Home} />
                      <Route path={Constantes.URL_PUBLICAS.QUE_ES_SUMA} component={Vista_QueEsSuma} />
                      <Route path={Constantes.URL_PUBLICAS.NORMATIVIDIDAD} component={Vista_Normatividad} />
                      <Route path={Constantes.URL_PUBLICAS.PREGUNTAS} component={Vista_Preguntas} />
                      <Route path={Constantes.URL_PRIVADAS.OPCIONES} component={Vista_Mis_Sitios} />
                    </TransitionGroup>
                    <div className="zona-usuario-estatico">
                      <ZonaUsuarioSelector usuario={this.props.usuario} callbackUsuarioLogueado={this.props.callbackUsuarioLogueado} />
                    </div>
                  </div>
                </Route>
                <Route path={Constantes.URL_PUBLICAS.CREAR_CUENTA} component={Vista_Crear_Cuenta} />
                <Route path={Constantes.URL_PUBLICAS.CREAR_CUENTA_PERSONA_JURIDICA} component={Vista_Crear_Persona_Juridica} />
                <Route path={Constantes.URL_PUBLICAS.CREAR_CUENTA_PERSONA_NATURAL} component={Vista_Crear_Persona_Natural} />
                <Route path={Constantes.URL_PUBLICAS.ACTIVAR_CUENTA} component={Vista_Activar_Cuenta} />
                <Route path={Constantes.URL_PUBLICAS.ACTIVAR_DADEP_CUENTA} component={Vista_Activar_Dadep_Cuenta} />
                <Route path={Constantes.URL_PUBLICAS.ACTIVAR_USUARIO_SUMAv1} component={Vista_Activar_Usuario_SumaV1} />
                <Route path={Constantes.URL_PUBLICAS.ACTIVAR_USUARIO_MIGRACION} component={Vista_Activar_Usuario_Migracion} />
                <Route path={Constantes.URL_PUBLICAS.ACTIVAR_USUARIO_MIGRACION_DADEP} component={Vista_Activar_Usuario_Migracion_Dadep} />
                <Route path={Constantes.URL_PUBLICAS.CAMBIO_CONTRASENA} component={Vista_Cambiar_Contrasena} />
                <Route path={Constantes.URL_PUBLICAS.DESBLOQUEAR_USUARIO} component={Vista_Desbloquear_Usuario} />
                <Route path={Constantes.URL_PUBLICAS.RECUPERAR_CONTRASENA} component={Vista_Recuperar_Contrasena} />
                <Route path={Constantes.URL_PUBLICAS.ENVIAR_CORREO_ACTIVACION_CUENTA} component={Vista_Enviar_Correo_Activacion_Cuenta} />

                <Route component={Vista_Mi_Cuenta}
                  path={[
                    "/mi-cuenta-inicio",
                    "/mi-cuenta-informacion-basica",
                    "/mi-cuenta-editar-informacion",
                    "/mi-cuenta-seguridad",
                  ]} />

                <Route component={Vista_Datos_Persona_Juridica}
                  path={[
                    "/persona-juridica-datos",
                    "/persona-juridica-representantes",
                    "/persona-juridica-ver-representante",
                    "/persona-juridica-agregar-representante",
                    "/persona-juridica-editar-representante",
                    "/persona-juridica-documentos",
                    "/persona-juridica-usuarios",
                    "/persona-juridica-ver-usuario",
                    "/persona-juridica-agregar-usuario",
                    "/persona-juridica-editar-usuario",
                    "/persona-juridica-apoderados",
                    "/persona-juridica-ver-apoderado",
                    "/persona-juridica-agregar-apoderado",
                    "/persona-juridica-editar-apoderado"
                  ]}
                />

                <Route component={Vista_Datos_Persona_Natural}
                  path={[
                    "/persona-natural-datos",
                    "/persona-natural-apoderados",
                    "/persona-natural-ver-apoderado",
                    "/persona-natural-agregar-apoderado",
                    "/persona-natural-editar-apoderado",
                    "/persona-natural-documentos",
                  ]} />

                <Route component={Vista_Datos_Dependencia}
                  path={[
                    "/dependencia-entidad",
                    "/dependencia-datos",
                    "/dependencia-usuarios",
                    "/dependencia-ver-usuario",
                    "/dependencia-agregar-usuario",
                    "/dependencia-editar-usuario",
                    "/aplicacion-conectada",
                    OpcionesSuma.PATHS.DEPENDENCIA_OPCION_SUMA,
                    OpcionesSuma.PATHS.DEPENDENCIA_SELECCION_OPC_TIPO,
                    OpcionesSuma.PATHS.DEPENDENCIA_OPC_TIPO_SOLICITUD,
                    OpcionesSuma.PATHS.DEPENDENCIA_OPC_TIPO_SUMA,
                    OpcionesSuma.PATHS.DEPENDENCIA_OPC_TIPO_REGISTRAR_SOLICITUD,
                    RolesDependencia.PATHS.DEPENDENCIA_ROLES_SUMA,
                    RolesDependencia.PATHS.DEPENDENCIA_ROL_SUMA,
                  ]}
                />

                <Route path="/registrar-solicitud" component={Vista_Registrar_Solicitud} />

                <Route path="/mis-solicitudes" component={Vista_Mis_Solicitudes} />

                <Route path="/solicitudes-dependencia" component={Vista_Solicitudes_Dependencia} />

                <Route path="/solicitudes-asociadas" component={Vista_Solicitudes_Asociadas} />

                <Route component={Vista_Usuarios}
                  path={[
                    "/usuarios",
                    "/roles",
                    "/rol",
                    "/usuario",
                    "/tipo-usuario",
                    "/agregar-usuario-adm-suma",
                    "/agregar-usuario-dp",
                    "/editar-usuario",
                    "/personas-juridicas",
                    "/persona-juridica/*"
                  ]}
                />

                <Route component={Vista_Entidades}
                  path={[
                    "/entidades",
                    "/entidad",
                    "/agregar-entidad",
                    "/agregar-dependencia",
                    "/editar-entidad",
                    "/editar-dependencia",
                    "/entidad-dependencias",
                    "/entidad-dependencia",
                    "/dependencia-opciones",
                    "/seleccion-tipo-opcion-dependecia",
                    "/opcion-dependencia-solicitud",
                    "/opcion-dependencia-suma",
                    "/opcion-dependencia-registrar-solicitud",
                    "/dependencia-roles",
                    "/dependencia-rol",
                    "/dependencia-aplicacion-conectada"
                  ]}
                />

                <Route path="/solicitudes" component={Vista_Solicitudes} />

                <Route component={Vista_Configuracion}
                  path={[
                    "/configuracion",
                  ]}
                />

                <Route component={Vista_Comunicaciones}
                  path={[
                    "/comunicaciones",
                  ]}
                />

                <Route component={Vista_Documentos}
                  path={[
                    "/documentos",
                  ]}
                />

                <Route component={Vista_Reportes}
                  path={[
                    "/reportes",
                    "/reporte_usuarios",
                    "/reporte_solicitudes",
                  ]}
                />

              </Switch>
            )
        }



      </div>
    );
  }
}

export default withRouter(withCookies(Principal));


