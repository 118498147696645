import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Spin, Table, Button, Tooltip, Tag, Popconfirm } from 'antd';
import { withCookies } from 'react-cookie';
import { UserOutlined, StopOutlined, CheckOutlined, MailOutlined, SearchOutlined } from '@ant-design/icons';
import ServicioDatosConstantes from '../servicios/ServicioDatosConstantes'
import Constantes from '../utilidades/Constantes'
import Utilidades from '../utilidades/Utilidades'
import FiltroColumna from '../general/tabla/FiltroColumna'
import FiltroEtiqueta from '../general/tabla/FiltroEtiqueta'
//import moment from 'moment';
import ServicioAdministracion from '../servicios/ServicioAdministracion'
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import EstilosGlobales from '../utilidades/EstilosGlobales';
import _ from 'lodash';

class ListarUsuarios extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        vistaUsuarios: null,
        cargando: false,        

        usuarios: [],
        filtro: {},
        filtroEtiquetas: [],
        filtroApi : {},
        paginacion: {
            current: 1,
            pageSize: 10,
        },
        cargandoTabla: true
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.clickVerUsuario = props.clickVerUsuario;
        this.callbackActivarUsuario = props.callbackActivarUsuario;
        this.callbackEnviarCorreoActivacion = props.callbackEnviarCorreoActivacion;
        

        this.servicioAdministracion = new ServicioAdministracion(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        let contexto = this;
        this.filtroColumna = new FiltroColumna(props,( valores ) => {contexto.setSumaState(valores, contexto)});
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores, contexto) {
        if( !contexto )
            contexto = this;
            
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        this.setSumaState({ cargando: true, });
        // Validando que el usuario esté en sesion
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr !== null) {
            // Valida que tenga la accion habilitada de listar
            let habilitado = await this.servicioUsuarios.accionHabilitadaPromesa(Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.LISTAR_USUARIOS.id);
            if( habilitado && habilitado.resultado ){
                const estados = await this.servicioDatosConstantes.obtenerEstadosUsuario();
                const tipos = await this.servicioDatosConstantes.obtenerTiposUsuario();
                let estadosUsuario = [];
                let tiposUsuario = [];
                if( estados ){
                    for( let estado of estados ){
                        estadosUsuario.push({ label: estado.estadoUsuario, value: estado.id });
                    }
                }
                if( tipos ){
                    for( let tipo of tipos ){
                        tiposUsuario.push({ label: tipo.tipoUsuario, value: tipo.id });
                    }
                }
                await this.cargarDatosTabla(this);
                this.setSumaState({
                    componente: this.ENUM_COMPONENTE.VER,cargando: false,
                    estadosUsuario: estadosUsuario, usuario: usr, tiposUsuario:tiposUsuario
                });
            }
        } else {
            this.setSumaState({componente:this.ENUM_COMPONENTE.NO_DISPONIBLE,cargando: false,usuario: null});
        }
    }

    

    // 'limpiarFiltro': Método (clearFilters) que es propio de la tabla antDesign para limpiar los filtros
    // 'confirmarFiltro': Método (confirm) que es própio de la tabla antDesign para aplicar el filtro
    // 'dataIndex': Retorna el campo que se está filtrando
    // 'selectedKeys': Retorna el valor a filtrar;
    async cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) {
        let filtro = contexto.state.filtro;
        if (dataIndex !== undefined) { // Si el filtro es aplicado, se agrega la columna filtrada
            filtro[dataIndex] = selectedKeys;
        }        
        contexto.setSumaState({ filtro: filtro });

        // Se construye el filtroApi a partir de lo que fue filtrado en la tabla
        let filtroApi = {
            ...(filtro.id && filtro.id.length > 0 && {id:filtro.id[0]} ),
            ...(filtro.usuario && filtro.usuario.length > 0 && {usuario:filtro.usuario[0]} ),
            ...(filtro.nombre && filtro.nombre.length > 0 && {nombre:filtro.nombre[0]} ),
            ...(filtro.rol && filtro.rol.length > 0 && {rol:filtro.rol[0]} ),
            ...(filtro.estado && filtro.estado.length > 0 && filtro.estado[0].opc && {estado:filtro.estado[0].opc.join()} ),
            ...(filtro.tipoUsuario && filtro.tipoUsuario.length > 0 && filtro.tipoUsuario[0].opc && {tipoUsuario:filtro.tipoUsuario[0].opc.join()} ),
            ...(filtro.datosAdicionales && filtro.datosAdicionales.length > 0 && {datosAdicionales:filtro.datosAdicionales[0]} ),
            ...(filtro.fechaUltimoLoginFormato && filtro.fechaUltimoLoginFormato.length > 0 && {fechasUltimoLogin:filtro.fechaUltimoLoginFormato[0] + '-' +filtro.fechaUltimoLoginFormato[1]} ),
        };

        const paginacion = this.state.paginacion;
        let paginacionApi = {
            paginaActual:this.state.paginacion.current,
            paginaTamano:this.state.paginacion.pageSize,
            ordenarColumna:this.state.paginacion.ordenarColumna,
            orden: this.state.paginacion.orden
        }

        let cambio = false;
        // Primero valida si hubo cambio a nivel de filtro
        if( !_.isEqual(this.state.filtroApi, filtroApi) ){
            paginacion.current = 1;
            paginacionApi.paginaActual = 1;
            cambio = true;
        }

        // Segundo valida si hubo cambio a nivel de paginación
        if( !cambio && !_.isEqual(this.state.paginacionApi, paginacionApi) ){
            cambio = true;
        }

        if( !cambio ){
            return; // Si no hubo cambio no se hace nada
        }

        contexto.setSumaState({  
            cargandoTabla: true, 
            filtro, 
            filtroApi, 
            paginacionApi, 
            paginacion
        });
        
        await this.servicioAdministracion.obtenerUsuarios(
            {   
                ...filtroApi,
                ...paginacionApi
            } 
        )
            .then((respuestaApi) => {
                if (respuestaApi) {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.ADMINISTRACION.DATOS_RETORNADOS.id:
                            let tablaPaginada = respuestaApi.resultado;
                            const paginacion = this.state.paginacion;
                            paginacion.total = tablaPaginada.total;

                            contexto.setSumaState({
                                usuarios: tablaPaginada.registros,
                                cargandoTabla: false,
                                paginacion
                            });

                            break;
                        default: break;
                    }
                }
            }).then(() => {
                if (limpiarFiltro !== undefined) { limpiarFiltro(); }
                if (confirmarFiltro !== undefined) { confirmarFiltro(); }
            });
    }

    handleTableChange = async (pagination, filters, sorter) => {
        const paginacion = this.state.paginacion;
        paginacion.current = pagination.current;
        paginacion.pageSize = pagination.pageSize;
        paginacion.ordenarColumna = sorter.field;
        paginacion.orden = sorter.order;
        this.setSumaState({  paginacion });
        await this.cargarDatosTabla(this);
    };


    render() {

        const { usuarios, componente, usuario, estadosUsuario, tiposUsuario } = this.state;
        let { filtro } = this.state;
        filtro = filtro || {};
        let contexto = this;

        const columns = [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                with:100,
                sorter: (a, b) => 0, //a.id - b.id,
                filteredValue: filtro.id || null,
                ...this.filtroColumna.busquedaTexto(
                    'Identificador',
                    'id',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            {
                title: 'Usuario',
                dataIndex: 'usuario',
                key: 'usuario',
                sorter: (a, b) => 0, //a.usuario ? a.usuario.localeCompare(b.usuario) : 1,
                filteredValue: filtro.usuario || null,
                ...this.filtroColumna.busquedaTexto(
                    'Usuario',
                    'usuario',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                render: (text, record) => (
                    <span>{text}</span>
                ),
                className:"td-usuario-alias"
            },
            {
                title: 'Datos usuario',
                dataIndex: 'nombre',
                key: 'nombre',
                sorter: (a, b) => 0, //a.nombre ? a.nombre.localeCompare(b.nombre) : 1,
                filteredValue: filtro.nombre || null,
                ...this.filtroColumna.busquedaTexto(
                    'Datos usuario',
                    'nombre',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                render: (text, record) => (
                    <>
                        <span className="span-bloque lineh16 texto-gris-claro">Nombre</span>
                        <span>{text}</span>
                        <span className="span-bloque lineh16 texto-gris-claro">Documento</span>
                        <span>{record.numeroDocumento}</span>
                        { record.usuarioCorreoElectronico ?
                            <>
                                <span className="span-bloque lineh16 texto-gris-claro">Correo</span>
                                <span>{record.usuarioCorreoElectronico}</span>
                            </>
                            :<></>    
                        }
                    </>
                ),
                responsive: ['md'], /* visible en pantalla con ancho ≥ 768px*/
            },
            {
                title: 'Rol',
                dataIndex: 'rol',
                key: 'rol',
                sorter: (a, b) => 0, //a.rol ? a.rol.localeCompare(b.rol) : 1,
                filteredValue: filtro.rol || null,
                ...this.filtroColumna.busquedaTexto(
                    'Rol',
                    'rol',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                responsive: ['lg'], /* visible en pantalla con ancho ≥ 992px*/
            },
            {
                title: 'Tipo',
                key: 'tipoUsuario',
                dataIndex: 'tipoUsuario',
                sorter: (a, b) => 0, //a.tipoUsuario ? a.tipoUsuario.localeCompare(b.tipoUsuario) : 1,
                filteredValue: filtro.tipoUsuario || null,
                ...this.filtroColumna.busquedaOpciones(
                    'Tipo',
                    'tipoUsuario',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) },
                    tiposUsuario
                ),
                render: (text, record) => (
                    <>
                        <span key={text} className={ 'bold ' + (
                            (record.idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.ADMINISTRADOR_SUMA.id)
                                ?
                                'fuente-green'
                                :
                                (record.idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.PERSONA_NATURAL.id
                                    ?
                                    'fuente-volcano'
                                    :
                                    (record.idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.PERSONA_JURIDICA.id
                                        ?
                                        'fuente-geekblue'
                                        :
                                        (record.idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id 
                                            ?
                                            'fuente-purple'
                                            :
                                            ''
                                        )
                                    )
                                )
                        )}>
                            {text.toUpperCase()}
                        </span>
                    </>
                ),
                responsive: ['sm'], /* visible en pantalla con ancho ≥ 576px*/
            },
            {
                title: 'Datos adicionales',
                dataIndex: 'datosAdicionales',
                key: 'datosAdicionales',
                filteredValue: filtro.datosAdicionales || null,
                ...this.filtroColumna.busquedaTexto(
                    'Datos adicionales',
                    'datosAdicionales',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                render: (text, record) => (
                    <>
                        { record.error ? 
                            <span className="span-bloque lineh16 texto-error">{record.error}</span>
                            :<></>    
                        }
                        { record.datoAdicional01 ?
                            <span className="span-bloque lineh16 texto-gris-claro">{record.datoAdicional01}</span>            
                            :<></>    
                        }
                        { record.datoAdicional02 ?     
                            <span className="span-bloque lineh16">{record.datoAdicional02}</span>
                            :<></>    
                        }
                    </>
                ),
                responsive: ['xl'], /* visible en pantalla con ancho ≥ 1200px*/
            },
            {
                title: 'Fecha Login',
                align: 'center',
                dataIndex: 'fechaUltimoLoginFormato',
                key: 'fechaUltimoLoginFormato',
                width: 100, 
                sorter: (a, b) => 0, /*{
                    let fecha_a = moment(a.fechaUltimoLoginEstandar, Constantes.FORMATO_FECHA_TIEMPO_ESTANDAR);
                    let fecha_b = moment(b.fechaUltimoLoginEstandar, Constantes.FORMATO_FECHA_TIEMPO_ESTANDAR);
                    if( !isNaN(fecha_a) && !isNaN(fecha_b) )
                        return fecha_a - fecha_b;
                    else if( isNaN(fecha_a) && isNaN(fecha_b) )
                        return 0;
                    else if( !isNaN(fecha_a) && isNaN(fecha_b) )
                        return 1;   
                    else 
                        return -1;        
                },*/
                filteredValue: filtro.fechaUltimoLoginFormato || null,
                ...this.filtroColumna.busquedaFecha(
                    'Fecha Último Login',
                    'fechaUltimoLoginFormato',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                responsive: ['lg'], /* visible en pantalla con ancho ≥ 992px*/
            },
            {
                title: 'Estado',
                key: 'estado',
                align: 'center',
                dataIndex: 'estado',
                sorter: (a, b) => 0, //a.estado ? a.estado.localeCompare(b.estado) : 1,
                filteredValue: filtro.estado || null,
                ...this.filtroColumna.busquedaOpciones(
                    'Estado',
                    'estado',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) },
                    estadosUsuario
                ),
                render: (text, record) => (
                    <>
                        <Tag key={text} color={
                            (record.idEstado === Constantes.ENUMS.ESTADO_USUARIO.ACTIVO.id)
                                ?
                                'green'
                                :
                                (record.idEstado === Constantes.ENUMS.ESTADO_USUARIO.INACTIVO.id
                                    ?
                                    'volcano'
                                    :
                                    (record.idEstado === Constantes.ENUMS.ESTADO_USUARIO.CREADO.id
                                        ?
                                        'geekblue'
                                        :
                                        ''
                                    )
                                )
                        }>
                            {text.toUpperCase()}
                        </Tag>

                        { record.cambioContrasena
                            ?
                            <Tooltip title="El usuario tiene pendiente un cambio de contraseña">
                                <br />
                                <Tag color="purple" key="Cambio Contraseña">CONTRASEÑA</Tag>
                            </Tooltip>
                            :
                            <></>
                        }

                    </>
                ),
                responsive: ['sm'], /* visible en pantalla con ancho ≥ 576px*/
            },
            {
                title: 'Opciones',
                key: 'opciones',
                align: 'center',
                className: 'columna-opciones',
                width: 112,
                render: (text, record) => (
                    <>
                        <Tooltip title="Ver usuario">
                            <Button shape="circle" disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.VER_USUARIO.id)}
                                icon={<UserOutlined style={{ 'color': '#c90a00' }} />}
                                onClick={(e) => { this.clickVerUsuario(e, record.id) }} />
                        </Tooltip>


                        {record.idEstado === Constantes.ENUMS.ESTADO_USUARIO.ACTIVO.id ?
                            (
                                <Popconfirm title="Confirma desactivar el usuario?"
                                    okText="Si"
                                    cancelText="No"
                                    onConfirm={(e) => {
                                        this.setSumaState({ cargando: true });
                                        this.callbackActivarUsuario(
                                            e,
                                            record.id,
                                            record.nombre,
                                            false,
                                            () => {
                                                this.setSumaState({ cargando: false });
                                                this.cargarDatosIniciales();
                                            });
                                    }}
                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.DESACTIVAR_USUARIO.id)}
                                >
                                    <Tooltip title="Desactivar usuario">
                                        <Button shape="circle"
                                            icon={<StopOutlined style={{ 'color': '#c90a00' }} />}
                                            disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.DESACTIVAR_USUARIO.id)} />
                                    </Tooltip>
                                </Popconfirm>
                            ) : (<></>)
                        }

                        {record.idEstado === Constantes.ENUMS.ESTADO_USUARIO.INACTIVO.id ?
                            (
                                <Popconfirm title="Confirma activar el usuario?"
                                    okText="Si"
                                    cancelText="No"
                                    onConfirm={(e) => {
                                        this.setSumaState({ cargando: true });
                                        this.callbackActivarUsuario(
                                            e,
                                            record.id,
                                            record.nombre,
                                            true,
                                            () => {
                                                this.setSumaState({ cargando: false });
                                                this.cargarDatosIniciales();
                                            });
                                    }}
                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.ACTIVAR_USUARIO.id)}
                                >
                                    <Tooltip title="Activar usuario">
                                        <Button shape="circle"
                                            icon={<CheckOutlined style={{ 'color': '#c90a00' }} />}
                                            disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.ACTIVAR_USUARIO.id)} />
                                    </Tooltip>
                                </Popconfirm>
                            ) : (<></>)
                        }

                        {   record.idEstado === Constantes.ENUMS.ESTADO_USUARIO.CREADO.id &&
                            record.idTipoUsuario !== Constantes.ENUMS.TIPO_USUARIO.APLICACION_CONECTADA.id  ?
                            (
                                <Popconfirm title="Confirma enviar el correo de activación?"
                                    okText="Si"
                                    cancelText="No"
                                    onConfirm={(e) => {
                                        this.setSumaState({ cargando: true });
                                        this.callbackEnviarCorreoActivacion(
                                            e,
                                            record.id,
                                            record.nombre,
                                            () => {
                                                this.setSumaState({ cargando: false });
                                                this.cargarDatosIniciales();
                                            });
                                    }}
                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.ENVIAR_CORREO_ACTIVACION_USUARIO.id)}
                                >
                                    <Tooltip title="Enviar correo de activación">
                                        <Button shape="circle"
                                            icon={<MailOutlined style={{ 'color': '#c90a00' }} />}
                                            disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.ENVIAR_CORREO_ACTIVACION_USUARIO.id)} />
                                    </Tooltip>
                                </Popconfirm>
                            ) : (<></>)
                        }

                    </>
                ),
            },
        ];

        return (
            <>
                <EstilosGlobales.CONTENEDOR_COMPLETO />
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>            
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>

                                {
                                    this.state.filtroEtiquetas.length > 0 ?
                                        (<div className="seccion-filtros-etiquetas">
                                            <div className="titulo-filtro-etiquetas">
                                                <label>Filtros <SearchOutlined /> :</label>
                                            </div>

                                            {
                                                this.state.filtroEtiquetas.map((filtroEtiqueta, i) => {
                                                    return (
                                                        <FiltroEtiqueta key={i}
                                                            dataIndex={filtroEtiqueta.dataIndex}
                                                            texto={filtroEtiqueta.texto}
                                                            onClose={filtroEtiqueta.borrarFiltro} />
                                                    )
                                                })
                                            }
                                        </div>)
                                        :
                                        (<></>)
                                }

                                <Table columns={columns}
                                    dataSource={usuarios}
                                    rowKey={"id"} 
                                    onChange={this.handleTableChange}
                                    pagination={this.state.paginacion}
                                    loading={this.state.cargandoTabla}
                                    />
                            </Spin>
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:LISUSU]</label>) : (<></>))
                    )
                }
            </>
        );
    }
}

export default withRouter(withCookies(ListarUsuarios));