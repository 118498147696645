import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter, Switch, Route } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Spin, Button, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ServicioAdministracion from '../../servicios/ServicioAdministracion'
import ServicioEntidad from '../../servicios/ServicioEntidad'
import Constantes from '../../utilidades/Constantes';
import Utilidades from '../../utilidades/Utilidades';
import ListaOpcionDependencia from '../../entidad/dependencia/opcion/ListaOpcionDependencia';
import SeleccionTipoOpcion from '../../entidad/dependencia/opcion/SeleccionTipoOpcion';
import AdministrarOpcionDependenciaSolicitud from '../../entidad/dependencia/opcion/AdministrarOpcionDependenciaSolicitud';
import AdministrarOpcionDependenciaSUMA from '../../entidad/dependencia/opcion/AdministrarOpcionDependenciaSUMA';
import AdministrarOpcionDependenciaRegistrarSolicitud from '../../entidad/dependencia/opcion/AdministrarOpcionDependenciaRegistrarSolicitud';


class OpcionesSuma extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3,
        NO_HABILITADO: 4
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
    };

    static PATHS = {
        DEPENDENCIA_OPCION_SUMA: '/dependencia-opcion-suma',
        DEPENDENCIA_SELECCION_OPC_TIPO: '/dependencia-seleccion-opc-tipo',
        DEPENDENCIA_OPC_TIPO_SOLICITUD: '/dependencia-opc-tipo-solicitud',
        DEPENDENCIA_OPC_TIPO_SUMA: '/dependencia-opc-tipo-suma',
        DEPENDENCIA_OPC_TIPO_REGISTRAR_SOLICITUD: '/dependencia-opc-tipo-registrar-solicitud',

    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;

        this.servicioAdministracion = new ServicioAdministracion(props);
        this.servicioEntidad = new ServicioEntidad(props);

    }


    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let usr = await this.servicioEntidad.usuarioValido();
        if (usr !== null) {

            let habilitado = Utilidades.accionPermitida(usr, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.ADMINISTRAR_OPCIONES_DEPENDENCIA.id);
            if (habilitado) {

                // Se consulta la aplicación conectada y se carga
                this.setSumaState({ componente: this.ENUM_COMPONENTE.VER, cargando: false });
            } else {
                this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_HABILITADO });
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    clickSeleccionTipoOpcionDependencia = (  ) => {
        this.historyLocal.push(OpcionesSuma.PATHS.DEPENDENCIA_SELECCION_OPC_TIPO);
    }

    clickAdministrarOpcionDependencia = (tipoOpcion, idDependencia, idOpcDependencia) => {
        let query = (idOpcDependencia ? ('?id=' + idOpcDependencia) : '');

        if (tipoOpcion === Constantes.ENUMS.TIPO_OPCION.SOLICITUD.id)
            this.historyLocal.push(OpcionesSuma.PATHS.DEPENDENCIA_OPC_TIPO_SOLICITUD + query);
        if (tipoOpcion === Constantes.ENUMS.TIPO_OPCION.OPCION_SUMA.id)
            this.historyLocal.push(OpcionesSuma.PATHS.DEPENDENCIA_OPC_TIPO_SUMA + query);
        if (tipoOpcion === Constantes.ENUMS.TIPO_OPCION.REGISTRAR_SOLICITUD.id)
            this.historyLocal.push(OpcionesSuma.PATHS.DEPENDENCIA_OPC_TIPO_REGISTRAR_SOLICITUD + query);
    }


    validarEliminarOpcionDependencia = async ( idOpcionDependencia, idDependencia, callback ) => {
        let respuestaApi = await this.servicioAdministracion.validarEliminarOpcionDependencia( idOpcionDependencia );
        if(respuestaApi){
            switch(respuestaApi.codigo){
                case Constantes.ENUMS_API.ADMINISTRACION.DATO_VALIDO_PARA_ELIMINAR.id:
                    Modal.confirm({
                        content:respuestaApi.mensaje,
                        cancelText: 'Cancelar',
                        okText: 'Confirmar',
                        onOk:( async(close) =>{
                            respuestaApi = await this.servicioAdministracion.eliminarOpcionDependencia( idOpcionDependencia );
                            switch(respuestaApi.codigo){
                                case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id:
                                    Modal.success({ content: respuestaApi.mensaje});
                                    this.historyLocal.push(OpcionesSuma.PATHS.DEPENDENCIA_OPCION_SUMA);
                                    break;
                                case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_NO_REALIZADA.id:
                                    Modal.error({ content: respuestaApi.mensaje});
                                    break;
                                default:
                                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:OPCSUMA01]" });
                                    break;
                            }
                            close();
                        })
                    });
                    break;
                case Constantes.ENUMS_API.ADMINISTRACION.DATO_NO_VALIDO_PARA_ELIMINAR.id:
                    Modal.error({content:respuestaApi.mensaje});
                    break;
                default:
                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:OPCSUMA02]" });
                    break;    
            }
        }
        callback();
    }

    render() {
        const { componente, cargando } = this.state;


        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>

                            <Spin tip={'por favor espera...'} size="large" spinning={cargando}>

                                <Switch>
                                    <Route path={OpcionesSuma.PATHS.DEPENDENCIA_OPCION_SUMA}>
                                        <>
                                            <div className="div-lista-botones margen-bottom-5">
                                                <Button type="primary"
                                                    onClick={() => this.clickSeleccionTipoOpcionDependencia()}
                                                    icon={<PlusOutlined />}>
                                                    Agregar opción
                                                </Button>
                                            </div>
                                            <ListaOpcionDependencia callbackAdministrarOpcionDependencia={this.clickAdministrarOpcionDependencia} />
                                        </>
                                    </Route>
                                    <Route path={OpcionesSuma.PATHS.DEPENDENCIA_SELECCION_OPC_TIPO}>
                                        <SeleccionTipoOpcion callbackAdministrarOpcionDependencia={this.clickAdministrarOpcionDependencia} />
                                    </Route>

                                    <Route path={OpcionesSuma.PATHS.DEPENDENCIA_OPC_TIPO_SOLICITUD}>
                                        <AdministrarOpcionDependenciaSolicitud  urlListadoOpciones={OpcionesSuma.PATHS.DEPENDENCIA_OPCION_SUMA}
                                                                                callbackValidarEliminarOpcionDependencia={this.validarEliminarOpcionDependencia} />
                                    </Route>

                                    <Route path={OpcionesSuma.PATHS.DEPENDENCIA_OPC_TIPO_SUMA}>
                                        <AdministrarOpcionDependenciaSUMA   urlListadoOpciones={OpcionesSuma.PATHS.DEPENDENCIA_OPCION_SUMA}
                                                                            callbackValidarEliminarOpcionDependencia={this.validarEliminarOpcionDependencia}/>
                                    </Route>

                                    <Route path={OpcionesSuma.PATHS.DEPENDENCIA_OPC_TIPO_REGISTRAR_SOLICITUD}>
                                        <AdministrarOpcionDependenciaRegistrarSolicitud urlListadoOpciones={OpcionesSuma.PATHS.DEPENDENCIA_OPCION_SUMA}
                                                                                        callbackValidarEliminarOpcionDependencia={this.validarEliminarOpcionDependencia}/>
                                    </Route>
                                </Switch>



                            </Spin>


                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : (
                                (componente === this.ENUM_COMPONENTE.NO_HABILITADO ?
                                    <label>Opciones dependencia no disponible. {Constantes.MENSAJE_USUARIO_SIN_PERMISOS}</label> :
                                    (componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:OPCDEPSUM]</label>) : (<></>))
                            )
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(OpcionesSuma));