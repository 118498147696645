import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Button, Row, Col, Modal, Spin, Tooltip, Tag, Popconfirm } from 'antd';
import { FileOutlined, EditOutlined, StopOutlined, CheckOutlined, ExclamationCircleOutlined, CloseOutlined } from '@ant-design/icons';
import Constantes from '../../utilidades/Constantes'
import ServicioDatosConstantes from '../../servicios/ServicioDatosConstantes'
import ServicioPersonaJuridica from '../../servicios/usuarios/ServicioPersonaJuridica'
import ActualizarArchivo from '../../carga-archivos/ActualizarArchivo'
import queryString from 'query-string';
import Utilidades from '../../utilidades/Utilidades'
import { HookReactRouterV6Parametros } from '../../general/hooks/HookReactRouterV6Parametros';

class VerRepresentanteLegal extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        cargando: true,
        idRepresentanteLegal: 0,
        representanteLegal: null,
        tipoArhivosRepresentanteLegal: null,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        usuario: null,
    };

    constructor(props) {
        super(props);
        //console.log('VerRepresentanteLegal.props',props);

        // Indica que es el administrador del SUMA quien está accediendo al representante legal
        this.administracion = props.administracion;

        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.servicioPersonaJuridica = new ServicioPersonaJuridica(props);
        this.callbackEditarRepresentanteLegal = props.callbackEditarRepresentanteLegal;
        this.callbackActivarRepresentanteLegal = props.callbackActivarRepresentanteLegal;
        this.callbackEliminarRepresentanteLegal = props.callbackEliminarRepresentanteLegal;
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        let idRepresentanteLegal = 0;

        if (!this.administracion && urlparametros.id !== undefined && !isNaN(parseInt(urlparametros.id))) {
            // Se toma el idRepresentanteLegal como un parámetro de consulta en la url '?id=idRepresentanteLegal'
            idRepresentanteLegal = parseInt(urlparametros.id);
        }else if( this.administracion ){
            // Se toma el idRepresentanteLegal si este viene en la url por medio del HookReactRouterV6Parametros,
            // esto funciona cuando es en modo administración y la url es: /persona-juridica/:idPersonaJuridica/representante-legal/:idRepresentanteLegal
            idRepresentanteLegal = this.props.idRepresentanteLegal;
        }


        if (idRepresentanteLegal !== 0) {
            // Validando que el usuario es PERSONA JURIDICA
            let usr = await this.servicioPersonaJuridica.usuarioPersonaJuridicaOAdministradorValido();
            this.setSumaState({ componente: (usr !== null ? this.ENUM_COMPONENTE.VER : this.ENUM_COMPONENTE.NO_DISPONIBLE) });

            if (this.state.componente === this.ENUM_COMPONENTE.VER) {
                this.setSumaState({ cargando: true, idRepresentanteLegal: idRepresentanteLegal });

                let tipoArhivosRepresentanteLegal = await this.obtenerTipoArchivosRepresentanteLegal();
                let representanteLegal = await this.obtenerRepresentanteLegal();

                if (representanteLegal !== null && tipoArhivosRepresentanteLegal != null) {
                    // Se quitan los tipos de archivos que ya tiene el representante registrados
                    for (let i = 0; i < representanteLegal.representanteLegalArchivos.length; i++) {
                        for (let m = 0; m < tipoArhivosRepresentanteLegal.length; m++) {
                            if (representanteLegal.representanteLegalArchivos[i].archivo.tipoArchivo.id === tipoArhivosRepresentanteLegal[m].id) {
                                tipoArhivosRepresentanteLegal.splice(m, 1);
                                break;
                            }
                        }
                    }
                    this.setSumaState({
                        usuario: usr,
                        cargando: false,
                        representanteLegal: representanteLegal,
                        tipoArhivosRepresentanteLegal: tipoArhivosRepresentanteLegal,
                    });
                }

            }
        }
    }

    obtenerRepresentanteLegal() {
        return new Promise(resolve => {
            this.servicioPersonaJuridica.obtenerRepresentanteLegal(
                (respuestaApi) => {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.PERSONA_JURIDICA.DATOS_RETORNADOS.id:
                            resolve(respuestaApi.resultado);
                            break;
                        default:
                            Modal.error({ title: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VRL02]" });
                            resolve(null);
                            break;
                    }
                },
                (errorAPI) => {
                    Modal.error({ title: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VRL01]" });
                    resolve(null);
                },
                this.state.idRepresentanteLegal,
                // Si está en modo administración del SUMA, este dato se captura de la url por medio de HookReactRouterV6Parametros
                this.props.idPersonaJuridica 
            );
        });
    }

    obtenerTipoArchivosRepresentanteLegal() {
        return new Promise(resolve => {
            this.servicioDatosConstantes.obtenerTipoArchivoRepresentanteLegal(
                (tipoArhivosRepresentanteLegal) => {
                    resolve(tipoArhivosRepresentanteLegal);
                },
                (errorAPI) => {
                    Modal.error({ title: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VRL05]" });
                    resolve(null);
                },
            );
        });
    }

    actualizarRepresentanteLegalArchivo(representanteLegalArchivo) {
        return new Promise(resolve => {
            this.servicioPersonaJuridica.actualizarRepresentanteLegalArchivo(
                (respuestaApi) => {
                    //console.log('respuestaApi:::::',respuestaApi);
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.PERSONA_JURIDICA.OPERACION_REALIZADA.id:
                            resolve(respuestaApi.resultado);
                            break;
                        default:
                            Modal.error({ message: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VRL04]" });
                            resolve(null);
                            break;
                    }
                },
                (errorAPI) => {
                    Modal.error({ message: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VRL03]" });
                    resolve(null);
                },
                representanteLegalArchivo,
                // Si está en modo administración del SUMA, este dato se captura de la url por medio de HookReactRouterV6Parametros
                this.props.idPersonaJuridica
            );
        });
    }


    agregarRepresentanteLegalArchivo(idRepresentanteLegal, idArchivo) {
        let representanteLegalArchivo = {
            archivo: { id: idArchivo },
            idRepresentanteLegal: idRepresentanteLegal,
        };
        return new Promise(resolve => {
            this.servicioPersonaJuridica.agregarRepresentanteLegalArchivo(
                (respuestaApi) => {
                    //console.log('respuestaApi:::::',respuestaApi);
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.PERSONA_JURIDICA.OPERACION_REALIZADA.id:
                            resolve(respuestaApi.resultado);
                            break;
                        default:
                            Modal.error({ message: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VRL06]" });
                            resolve(null);
                            break;
                    }
                },
                (errorAPI) => {
                    Modal.error({ message: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VRL07]" });
                    resolve(null);
                },
                representanteLegalArchivo,
                // Si está en modo administración del SUMA, este dato se captura de la url por medio de HookReactRouterV6Parametros
                this.props.idPersonaJuridica
            );
        });
    }


    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }


    async archivoRepresentanteLegalActualizado(idNuevoArchivo, representanteLegalArchivo, tipoArchivo) {
        this.setSumaState({ cargando: true });
        //console.log('representanteLegalArchivo',representanteLegalArchivo);
        let resultado = null;
        if (representanteLegalArchivo !== null) {
            representanteLegalArchivo.archivo = { id: idNuevoArchivo };
            resultado = await this.actualizarRepresentanteLegalArchivo(representanteLegalArchivo);
        } else {
            resultado = await this.agregarRepresentanteLegalArchivo(this.state.idRepresentanteLegal, idNuevoArchivo);
        }

        if (resultado !== null) {
            this.setSumaState({ representanteLegal: null, tipoArhivosRepresentanteLegal: null });
            this.cargarDatosIniciales();
            Modal.success({
                content: "El documento " +
                    tipoArchivo.nombreArchivo +
                    " fue " + (representanteLegalArchivo === null ? 'agregado' : 'actualizado') + " satisfactoriamente."
            });
        }
    }


    render() {
        //const { usuario } = this.context;
        const { idRepresentanteLegal, representanteLegal, tipoArhivosRepresentanteLegal, componente, usuario } = this.state;

        const wrapperCol = {
            xs: { span: 21, offset: 1 },
            sm: { span: 11, offset: 1 },
        };

        const wrapperColDocs01 = {
            xs: { span: 21, offset: 1 },
            sm: { span: 7, offset: 1 },
        };
        const wrapperColDocs02 = {
            xs: { span: 21, offset: 1 },
            sm: { span: 15, offset: 1 },
        };

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>

                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>
                                <Row>
                                    <Col>
                                        <h3 className="titulo inline-block">Representante legal:</h3> &nbsp;&nbsp;&nbsp;
                                        <h3 className="inline-block">{representanteLegal !== null ? representanteLegal.datoPersonal.nombreCompleto : ""}</h3>
                                    </Col>
                                </Row>
                                <h3 className="sub-titulo-separador margen-bottom">Información básica</h3>
                                <div className="seccion-datos">
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Tipo de documento:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{representanteLegal !== null ? representanteLegal.datoPersonal.tipoDocumento.tipoDocumento : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Número de documento:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{representanteLegal !== null ? representanteLegal.datoPersonal.numeroDocumento : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Lugar de expedición:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{representanteLegal !== null ? representanteLegal.datoPersonal.lugarExpedicion : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Fecha de expedición:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{representanteLegal !== null ? representanteLegal.datoPersonal.fechaExpedicionFormatoEspanol : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Correo electrónico:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{representanteLegal !== null ? representanteLegal.datoPersonal.correoElectronico : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Dirección residencia:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{representanteLegal !== null ? representanteLegal.datoPersonal.direccion : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Móvil:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{representanteLegal !== null ? representanteLegal.datoPersonal.celular : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Teléfono:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{representanteLegal !== null ? representanteLegal.datoPersonal.telefono : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Estado:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <Tag color={
                                                        (representanteLegal !== null && representanteLegal.estadoRepresentanteLegal.id === Constantes.ENUMS.ESTADO_REPRESENTANTE_LEGAL.ACTIVO.id)
                                                            ?
                                                            'green'
                                                            :
                                                            'volcano'
                                                    } key={representanteLegal !== null ? representanteLegal.estadoRepresentanteLegal.estadoRepresentanteLegal : ""}>
                                                        {representanteLegal !== null ? representanteLegal.estadoRepresentanteLegal.estadoRepresentanteLegal.toUpperCase() : ""}
                                                    </Tag>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>

                                <h3 className="sub-titulo-separador margen-bottom margen-top">Documentos</h3>
                                <div className="seccion-datos">

                                    {
                                        (representanteLegal !== null)
                                            ?
                                            representanteLegal.representanteLegalArchivos.map((representanteLegalArchivo, index) =>
                                                <Row className="fila documento" key={index} >
                                                    <Col  {...wrapperColDocs01}>
                                                        <a className="enlace-documento tamano-25" href={representanteLegalArchivo.archivo.url} target="_blank" rel="noopener noreferrer">
                                                            <div>
                                                                <FileOutlined />
                                                                <label>{representanteLegalArchivo.archivo.tipoArchivo.nombreArchivo}</label>
                                                            </div>
                                                            {representanteLegalArchivo.archivo.actualizarArchivo
                                                                ?
                                                                <Tooltip title="El documento debe ser actualizado!">
                                                                    <Tag icon={<ExclamationCircleOutlined />} color="magenta" key="Cambio Documento">ACTUALIZAR DOCUMENTO</Tag>
                                                                </Tooltip>
                                                                :
                                                                <></>
                                                            }
                                                        </a>
                                                    </Col>
                                                    <Col  {...wrapperColDocs02}>
                                                        <ActualizarArchivo key={index}
                                                            tipoArchivo={representanteLegalArchivo.archivo.tipoArchivo}
                                                            agregar={false}
                                                            callbackArchivoActualizado={(idNuevoArchivo) => {
                                                                this.archivoRepresentanteLegalActualizado(
                                                                    idNuevoArchivo,
                                                                    representanteLegalArchivo,
                                                                    representanteLegalArchivo.archivo.tipoArchivo
                                                                )
                                                            }}
                                                            deshabilitar={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_REPRESENTANTES_LEGALES.ACCIONES.ACTUALIZAR_DOCUMENTOS_REPRESENTANTE_LEGAL.id)}
                                                        />
                                                    </Col>
                                                </Row>
                                            )
                                            :
                                            (<></>)
                                    }

                                    {
                                        (tipoArhivosRepresentanteLegal !== null)
                                            ?
                                            tipoArhivosRepresentanteLegal.map((tipoArchivoRepresentanteLegal, index) =>
                                                <Row className="fila documento" key={index} >
                                                    <Col  {...wrapperColDocs01}>
                                                        <Tooltip title="No tiene asociado el documento">
                                                            <div className="enlace-documento tamano-25" >
                                                                <div>
                                                                    <FileOutlined />
                                                                    <label>{tipoArchivoRepresentanteLegal.nombreArchivo}</label>
                                                                </div>
                                                            </div>
                                                        </Tooltip>
                                                    </Col>
                                                    <Col  {...wrapperColDocs02}>
                                                        <ActualizarArchivo key={index}
                                                            tipoArchivo={tipoArchivoRepresentanteLegal}
                                                            agregar={true}
                                                            callbackArchivoActualizado={(idNuevoArchivo) => {
                                                                this.archivoRepresentanteLegalActualizado(
                                                                    idNuevoArchivo,
                                                                    null/*representanteLegalArchivo*/,
                                                                    tipoArchivoRepresentanteLegal
                                                                )
                                                            }}
                                                            deshabilitar={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_REPRESENTANTES_LEGALES.ACCIONES.AGREGAR_DOCUMENTOS_REPRESENTANTE_LEGAL.id)}
                                                        />
                                                    </Col>
                                                </Row>
                                            )
                                            :
                                            (<></>)
                                    }

                                </div>

                                <h3 className="sub-titulo-separador margen-bottom margen-top">Opciones</h3>
                                <div className="seccion-datos seccion-final">

                                    {(representanteLegal !== null)
                                        ?
                                        (<>
                                            <Button className="boton-opcion"
                                                icon={<EditOutlined style={{ color: '#c90a00' }} />}
                                                onClick={(e) => { this.callbackEditarRepresentanteLegal(e, idRepresentanteLegal) }}
                                                disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_REPRESENTANTES_LEGALES.ACCIONES.EDITAR_INFORMACION_REPRESENTANTE_LEGAL.id)}>
                                                Editar
                                            </Button>

                                            {(representanteLegal.estadoRepresentanteLegal.id === Constantes.ENUMS.ESTADO_REPRESENTANTE_LEGAL.ACTIVO.id)
                                                ?

                                                <Popconfirm title="Confirma desactivar el representante legal?"
                                                    okText="Si"
                                                    cancelText="No"
                                                    onConfirm={(e) => {
                                                        this.setSumaState({ cargando: true });
                                                        this.callbackActivarRepresentanteLegal(
                                                            e,
                                                            representanteLegal.id,
                                                            representanteLegal.datoPersonal.nombreCompleto,
                                                            false,
                                                            () => {
                                                                this.setSumaState({ cargando: false });
                                                                this.cargarDatosIniciales();
                                                            });
                                                    }}
                                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_REPRESENTANTES_LEGALES.ACCIONES.DESACTIVAR_REPRESENTANTE_LEGAL.id)}
                                                >
                                                    <Button className="boton-opcion"
                                                        icon={<StopOutlined style={{ color: '#c90a00' }} />}
                                                        disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_REPRESENTANTES_LEGALES.ACCIONES.DESACTIVAR_REPRESENTANTE_LEGAL.id)}>
                                                        Desactivar</Button>
                                                </Popconfirm>


                                                :
                                                <></>
                                            }

                                            {(representanteLegal.estadoRepresentanteLegal.id === Constantes.ENUMS.ESTADO_REPRESENTANTE_LEGAL.INACTIVO.id)
                                                ?

                                                <Popconfirm title="Confirma activar el representante legal?"
                                                    okText="Si"
                                                    cancelText="No"
                                                    onConfirm={(e) => {
                                                        this.setSumaState({ cargando: true });
                                                        this.callbackActivarRepresentanteLegal(
                                                            e,
                                                            representanteLegal.id,
                                                            representanteLegal.datoPersonal.nombreCompleto,
                                                            true,
                                                            () => {
                                                                this.setSumaState({ cargando: false });
                                                                this.cargarDatosIniciales();
                                                            });
                                                    }}
                                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_REPRESENTANTES_LEGALES.ACCIONES.ACTIVAR_REPRESENTANTE_LEGAL.id)}
                                                >
                                                    <Button className="boton-opcion"
                                                        icon={<CheckOutlined style={{ color: '#c90a00' }} />}
                                                        disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_REPRESENTANTES_LEGALES.ACCIONES.ACTIVAR_REPRESENTANTE_LEGAL.id)}>
                                                        Activar
                                                    </Button>
                                                </Popconfirm>



                                                :
                                                <></>
                                            }

                                            {(representanteLegal.estadoRepresentanteLegal.id) ?
                                                <Popconfirm title="Confirma eliminar el representante legal?"
                                                    okText="Si"
                                                    cancelText="No"
                                                    onConfirm={(e) => {
                                                        this.setSumaState({ cargando: true });
                                                        this.callbackEliminarRepresentanteLegal(
                                                            representanteLegal.id,
                                                            representanteLegal.datoPersonal.nombreCompleto);
                                                    }}
                                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_REPRESENTANTES_LEGALES.ACCIONES.ELIMINAR_REPRESENTANTE_LEGAL.id)}
                                                >
                                                    <Button className="boton-opcion"
                                                        icon={<CloseOutlined style={{ color: '#c90a00' }} />}
                                                        disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_REPRESENTANTES_LEGALES.ACCIONES.ELIMINAR_REPRESENTANTE_LEGAL.id)}>
                                                        Eliminar
                                                    </Button>
                                                </Popconfirm> : <></>
                                            }

                                        </>) : (<></>)
                                    }
                                </div>

                            </Spin>
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:VRL]</label>) : (<></>))
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(HookReactRouterV6Parametros(VerRepresentanteLegal)));