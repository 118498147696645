import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import SumaContexto  from '../../contexto/SumaContexto';
import Contrasena from '../crear-cuenta/Contrasena'
import queryString from 'query-string';
import ServicioUsuarios from '../../servicios/usuarios/ServicioUsuarios'
import { Spin, message, Alert } from 'antd';
import Constantes from '../../utilidades/Constantes'


function ValidarActivarCuenta( attr ){
    //console.log('attr',attr);
    //let urlparametros = queryString.parse( attr.urlparametros );
    //console.log('urlparametros',urlparametros);

    if( attr.cargando ){
        return (
            <>
                <div className="titulo-seccion">
                    <label>ACTIVAR CUENTA</label>
                    <div className="separador-horizontal-completo"></div>
                </div>
                <div className="cargando-pagina">
                    <Spin tip="Por favor espera..." size="large" ></Spin>
                </div>
            </>
        )
    }

    if( !attr.activarCuenta ){
        return( 
            <>
                <div>
                    <Alert message={'PETICIÓN NO DISPONIBLE. ' + attr.msgError} type="warning" />
                </div>
            </>
        );
    }else{

        return( 
            <>
                <div className="titulo-seccion">
                    <label>ACTIVAR CUENTA</label>
                    <div className="separador-horizontal-completo"></div>
                    <p className="descripcion-seccion">Para activar tu cuenta debes crear tu contraseña con la cual podrás ingresar al portal. </p>
                    
                    Debe ser mínimo de 8 caracteres y tener al menos:
                    <ul>
                        <li>Una letra mayúscula</li>
                        <li>Una letra minúscula</li>
                        <li>Un dígito</li>
                        <li>Alguno de los siguientes caracteres: ! @ # $ % ^ & *</li>
                    </ul>
                    
                    <br/>
                </div>
                <div className="seccion-formulario">
                    <Contrasena usuarioInformacionBasicaDto={attr.usuarioInformacionBasicaDto} 
                                token={attr.token}/>
                </div>
            </>
        );
    }
}

class ActivarCuenta extends Component {

    static contextType = SumaContexto;

    state = {
        cargando:true,
        activarCuenta:false,
        usuarioInformacionBasicaDto:undefined,
        msgError:null,
    };

    constructor(props){
        super(props);
        this.servicioUsuarios = new ServicioUsuarios( props );
    }

    componentDidMount() {
        this._isMounted = true;
        let params = this.props.location.search;
        let urlparametros = queryString.parse( params );
        this.enc = null;
        if(urlparametros.enc !== undefined){
            this.enc = urlparametros.enc;
            this.servicioUsuarios.usuarioNuevo(
                this.enc,
                ( respuestaApi )      => { 
                    //console.log('respuestaApi: ', respuestaApi);
                    let msgError = respuestaApi.mensaje;
                    switch(respuestaApi.codigo){
                        case Constantes.ENUMS_API.USUARIO.USUARIO_NUEVO.id:
                            this.setSumaState({ 
                                usuarioInformacionBasicaDto: respuestaApi.resultado , 
                                cargando:false, 
                                activarCuenta: true,
                                msgError:null });
                            break;
                        default:
                            this.setSumaState({ 
                                cargando:false, 
                                activarCuenta: false,
                                msgError: msgError
                            });
                            break;
                    }
                },
                ( errorAPI )    => { message.error('Error de conexión. Por favor intenta mas tarde.', 10 ) },
            );
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

  render() {
    
    return (
        <div className="seccion-principal seccion-flex">
          <div className="division-principal">
            <label>Activar Cuenta</label>
          </div>
          <div className="division-contenido seccion-flex-grow-1 padding-principal bkg-white">
                <ValidarActivarCuenta   cargando={this.state.cargando} 
                                        activarCuenta={this.state.activarCuenta}  
                                        usuarioInformacionBasicaDto={this.state.usuarioInformacionBasicaDto}
                                        msgError={this.state.msgError}
                                        token={this.enc}/>
            </div>
        </div>
      );
  }
}

export default withRouter(ActivarCuenta);