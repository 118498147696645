import axios from 'axios';
import Constantes from '../utilidades/Constantes'
import Utilidades from '../utilidades/Utilidades'
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import { Modal } from 'antd';


class ServicioAdministracion {

    constructor( props ){ 
        const { cookies } = props;
        this.cookies = cookies;
        this.servicioUsuarios = new ServicioUsuarios( props );
    }


    // Método privado
    usuarioValido() {
        return new Promise(resolve => {
            this.servicioUsuarios.obtenerUsuarioLogeado(
              ( dataAPI )    => { 
                    if( dataAPI.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.ADMINISTRADOR_SUMA.id ){
                        resolve( dataAPI );
                    }else{
                        Modal.error({ content: "Contenido no disponible. [Cod:SA16]" });
                        resolve( null );
                    }
                },
              ( errorAPI )   => { 
                  Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SA17]" });
                  resolve( null );},
            );
        });
    }

    // Método privado
    obtenerUsuarios ( filtroApi ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }, params: filtroApi};

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/usuarios',config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener usuarios. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerUsuarios]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA01]" }); 
                resolve();
            });
        });
    }


    // Método privado
    crearUsuarioAdministracion ( usr ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.post( Constantes.URL_API_SUMA + '/admin/usuario',usr,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Crear usuario. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.crearUsuarioAdministracion]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA04]" }); 
                resolve();
            });
        });
    }

    // Método privado
    actualizarUsuarioAdministracion ( usr ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        return new Promise( resolve =>{
            axios.put( Constantes.URL_API_SUMA + '/admin/usuario', usr, config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Actualizar usuario. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.actualizarUsuarioAdministracion]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA07]" }); 
                resolve();
            });
        });
    }

    // Método privado
    activarUsuario ( idUsuario, activar ) {
        let datos = {};
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        return new Promise( resolve => {
            axios.put( Constantes.URL_API_SUMA + '/admin/usuario/' + ( activar ? 'activar' : 'desactivar') + '/' + idUsuario, datos, config)
            .then( res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error " +( activar ? 'activar' : 'desactivar')+ " usuario. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.activarUsuario]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA08]" }); 
                resolve();
            });
        });
    }

    // Método privado
    desbloquearIntentosFallidosUsuario ( idUsuario ) {
        let datos = {};
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        return new Promise( resolve => {
            axios.put( Constantes.URL_API_SUMA + '/admin/usuario/desbloquear-intentos-fallidos/' + idUsuario, datos, config)
            .then( res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error desbloqueando intentos fallidos del usuario usuario. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.desbloquearIntentosFallidosUsuario]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA55]" }); 
                resolve();
            });
        });
    }

    // Método privado
    enviarCorreoActivacion ( idUsuario ) {
        let datos = {};
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        return new Promise( resolve =>{
            axios.put( Constantes.URL_API_SUMA + '/admin/usuario/correo-activacion/' + idUsuario, datos, config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Enviar correo activación usuario. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.enviarCorreoActivacion]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA09]" }); 
                resolve();
            });
        });
    }


    // Método privado
    enviarCorreoCambioContrasena ( idUsuario ) {
        let datos = {};
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        return new Promise( resolve => {
            axios.put( Constantes.URL_API_SUMA + '/admin/usuario/correo-cambio-contrasena/' + idUsuario, datos, config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error enviar correo cambio de contraseña usuario. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.enviarCorreoCambioContrasena]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA10]" }); 
                resolve();
            });
        });
    }

    // Método privado
    asignarContrasenaTemporalSUMAv1 ( idUsuario ) {
        let datos = {};
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        return new Promise( resolve => {
            axios.put( Constantes.URL_API_SUMA + '/admin/usuario/asignar-contrasena-temporal-sumav1/' + idUsuario, datos, config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error al enviar el correo al usuario con la contraseña temporal. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.enviarCorreoContrasenaTemporalSUMAv1]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA54]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerUsuario ( idUsuario ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/usuario/' + idUsuario, config)
            .then(res => {
                if(res.status === 200){
                    if (res.data.codigo === Constantes.ENUMS_API.ADMINISTRACION.DATOS_RETORNADOS.id) {
                        resolve( res.data.resultado );
                        return;    
                    }else{
                        Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SA05]" });
                        resolve();
                        return;    
                    }
                }else{
                    throw new Error("Error obtener usuario. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerUsuario]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA06]" }); 
                resolve();
            });
        });
    }


    // Método privado
    obtenerEntidades ( filtroApi ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }, params: filtroApi};

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/entidades',config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener usuarios. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerEntidades]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA11]" }); 
                resolve();
            });
        });
    }



    // Método privado
    obtenerEntidad ( idEntidad ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/entidad/' + idEntidad, config)
            .then(res => {
                if(res.status === 200){
                    if (res.data.codigo === Constantes.ENUMS_API.ADMINISTRACION.DATOS_RETORNADOS.id) {
                        resolve( res.data.resultado );
                        return;    
                    }else{
                        Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SA12]" });
                        resolve();
                        return;    
                    }
                }else{
                    throw new Error("Error obtener usuario. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerEntidad]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA13]" }); 
                resolve();
            });
        });
    }



    // Método privado
    buscarPersonaJuridica ( filtro ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/buscar-persona-juridica/'+filtro,config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error buscar persona jurídica. filtro: " +filtro + " - " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.buscarPersonaJuridica]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA02]" }); 
                resolve();
            });
        });
    }


    // Método privado
    obtenerDependencias () {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/dependencia' ,config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener dependencias. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerDependencias]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA37]" }); 
                resolve();
            });
        });
    }


    // Método privado
    buscarDependencia ( filtro ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/buscar-dependencia/'+filtro,config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error buscar dependencia. filtro: " +filtro + " - " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.buscarDependencia]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA03]" }); 
                resolve();
            });
        });
    }


    // Método privado
    crearEntidadAdministracion ( entidad ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.post( Constantes.URL_API_SUMA + '/admin/entidad',entidad,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Crear entidad. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.crearEntidadAdministracion]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA14]" }); 
                resolve();
            });
        });
    }

    // Método privado
    actualizarEntidadAdministracion ( entidad ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        return new Promise( resolve =>{
            axios.put( Constantes.URL_API_SUMA + '/admin/entidad', entidad, config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Actualizar entidad. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.actualizarEntidadAdministracion]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA15]" }); 
                resolve();
            });
        });
    }


    // Método privado
    obtenerRoles ( filtroApi ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }, params: filtroApi };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/roles',config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener roles. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerRoles]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA18]" }); 
                resolve();
            });
        });
    }


    // Método privado
    obtenerRolesDependenciaTipoUsuarioEntidadPersonaNaturalJuridica ( idDependencia ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/roles/dependencia/'+idDependencia+'/usuario/entidad/persona-natural-juridica',config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener roles dependencia tipo usuario (Entidad - PN - PJ). " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerRolesDependenciaTipoUsuarioEntidadPersonaNaturalJuridica]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA18]" }); 
                resolve();
            });
        });
    }


    // Método privado
    obtenerRolesTipoUsuarioEntidadPersonaNaturalJuridica () {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/roles/usuario/entidad/persona-natural-juridica',config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener roles tipo usuario (Entidad - PN - PJ). " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerRolesTipoUsuarioEntidadPersonaNaturalJuridica]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA71]" }); 
                resolve();
            });
        });
    }


    // Método privado
    obtenerRolesDependenciaTipoUsuarioEntidad ( idDependencia ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/roles/dependencia/'+idDependencia+'/usuario/entidad',config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener roles dependencia tipo usuario (Entidad). " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerRolesDependenciaTipoUsuarioEntidad]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA24]" }); 
                resolve();
            });
        });
    }


    // Método privado
    obtenerRolesTipoUsuarioPersonaNaturalJuridica () {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/roles/usuario/persona-natural-juridica',config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener roles tipo usuario (PN y PJ). " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerRolesTipoUsuarioPersonaNaturalJuridica]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA25]" }); 
                resolve();
            });
        });
    }


    // Método privado
    obtenerRolesDependencia ( idDependencia, filtroApi ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }, params: filtroApi };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + `/admin/roles/dependencia/${idDependencia ? idDependencia : 0}`, config )
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener roles dependencia. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerRolesDependencia]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA26]" }); 
                resolve();
            });
        });
    }


    // Método privado
    crearOpcionDependencia ( opcionDependencia ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.post( Constantes.URL_API_SUMA + '/admin/opcion-dependencia',opcionDependencia,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error crear opción dependencia. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.crearOpcionDependencia]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA19]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerOpcionDependencia ( idOpcionDependencia ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/opcion-dependencia/' + idOpcionDependencia, config)
            .then(res => {
                if(res.status === 200){
                    if (res.data.codigo === Constantes.ENUMS_API.ADMINISTRACION.DATOS_RETORNADOS.id) {
                        resolve( res.data.resultado );
                        return;    
                    }else{
                        Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SA20]" });
                        resolve();
                        return;    
                    }
                }else{
                    throw new Error("Error obtener usuario. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerOpcionDependencia]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA21]" }); 
                resolve();
            });
        });
    }

    // Método privado
    actualizarOpcionDependencia ( opcionDependencia ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.put( Constantes.URL_API_SUMA + '/admin/opcion-dependencia',opcionDependencia,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error actualizar opción dependencia. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.actualizarOpcionDependencia]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA22]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerTipoSolicitudes ( idDependencia ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/tipo-solicitud/dependencia/'+idDependencia,config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener tipos solicitudes. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerTipoSolicitudes]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA23]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerModulos () {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/modulo', config )
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener módulos. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerModulos]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA36]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerModulo ( idModulo ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/modulo/' + idModulo, config )
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener módulo. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerModulo]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA27]" }); 
                resolve();
            });
        });
    }


    // Método privado
    crearRol ( rol ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.post( Constantes.URL_API_SUMA + '/admin/rol',rol,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error crear rol. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.crearRol]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA28]" }); 
                resolve();
            });
        });
    }

    // Método privado
    actualizarRol ( rol ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.put( Constantes.URL_API_SUMA + '/admin/rol',rol,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error actualizar rol. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.actualizarRol]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA29]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerRol ( idRol ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/rol/' + idRol, config)
            .then(res => {
                if(res.status === 200){
                    if (res.data.codigo === Constantes.ENUMS_API.ADMINISTRACION.DATOS_RETORNADOS.id) {
                        resolve( res.data.resultado );
                        return;    
                    }else{
                        Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SA30]" });
                        resolve();
                        return;    
                    }
                }else{
                    throw new Error("Error obtener rol. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerRol]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA31]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerAplicacionConectada ( idDependencia ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/aplicacion-conectada/' + idDependencia, config)
            .then(res => {
                if(res.status === 200){
                    if (res.data.codigo === Constantes.ENUMS_API.ADMINISTRACION.DATOS_RETORNADOS.id) {
                        resolve( res.data.resultado );
                        return;    
                    }else{
                        Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SA32]" });
                        resolve();
                        return;    
                    }
                }else{
                    throw new Error("Error obtener aplicación conectada. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerAplicacionConectada]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA33]" }); 
                resolve();
            });
        });
    }

    // Método privado
    actualizarAplicacionConectada ( aplicacionConectada ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.put( Constantes.URL_API_SUMA + '/admin/aplicacion-conectada',aplicacionConectada,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error actualizar aplicacion conectada. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.actualizarAplicacionConectada]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA34]" }); 
                resolve();
            });
        });
    }

    // Método privado
    crearAplicacionConectada ( aplicacionConectada ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.post( Constantes.URL_API_SUMA + '/admin/aplicacion-conectada', aplicacionConectada,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error crear rol. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.crearAplicacionConectada]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA35]" }); 
                resolve();
            });
        });
    }


    // Método privado
    obtenerParametrosGenerales () {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/parametro-general' ,config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener parametros generales. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerParametrosGenerales]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA38]" }); 
                resolve();
            });
        });
    }

    // Método privado
    actualizarParametroGeneral ( parametroGeneral ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.put( Constantes.URL_API_SUMA + '/admin/parametro-general',parametroGeneral,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error actualizar parametro general. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.actualizarParametroGeneral]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA39]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerPreguntasFrecuentes () {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/pregunta-frecuente' ,config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener preguntas frecuentes. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerPreguntasFrecuentes]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA40]" }); 
                resolve();
            });
        });
    }

    // Método privado
    actualizarPreguntaFrecuente ( preguntaFrecuente ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.put( Constantes.URL_API_SUMA + '/admin/pregunta-frecuente',preguntaFrecuente,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error actualizar pregunta frecuente. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.actualizarPreguntaFrecuente]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA41]" }); 
                resolve();
            });
        });
    }

    // Método privado
    agregarPreguntaFrecuente ( preguntaFrecuente ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.post( Constantes.URL_API_SUMA + '/admin/pregunta-frecuente',preguntaFrecuente,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error agregar pregunta frecuente. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.agregarPreguntaFrecuente]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA42]" }); 
                resolve();
            });
        });
    }

    // Método privado
    eliminarPreguntaFrecuente ( id ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.delete( Constantes.URL_API_SUMA + '/admin/pregunta-frecuente/'+id,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error eliminar pregunta frecuente. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.eliminarPreguntaFrecuente]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA43]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerNormatividades () {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/normatividad' ,config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener normatividades. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerNormatividades]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA44]" }); 
                resolve();
            });
        });
    }

    // Método privado
    actualizarNormatividad ( normatividad ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.put( Constantes.URL_API_SUMA + '/admin/normatividad',normatividad,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error actualizar normatividad. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.actualizarNormatividad]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA45]" }); 
                resolve();
            });
        });
    }

    // Método privado
    agregarNormatividad ( normatividad ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.post( Constantes.URL_API_SUMA + '/admin/normatividad',normatividad,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error agregar normatividad. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.agregarNormatividad]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA46]" }); 
                resolve();
            });
        });
    }

    // Método privado
    eliminarNormatividad ( id ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.delete( Constantes.URL_API_SUMA + '/admin/normatividad/'+id,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error eliminar normatividad. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.eliminarNormatividad]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA47]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerContenidosVisuales () {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/contenido-visual' ,config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener contenidos visuales. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerContenidosVisuales]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA48]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerContenidoVisual ( id ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/contenido-visual/'+id ,config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener contenido visual. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerContenidoVisual]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA50]" }); 
                resolve();
            });
        });
    }

    // Método privado
    actualizarContenidoVisual ( contenidoVisual ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.put( Constantes.URL_API_SUMA + '/admin/contenido-visual',contenidoVisual,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error actualizar contenido visual. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.actualizarContenidoVisual]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA49]" }); 
                resolve();
            });
        });
    }

    // Método privado
    smtpProbar ( correoElectronicoDto ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.post( Constantes.URL_API_SUMA + '/admin/smtp/probar',correoElectronicoDto,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error SMTP Probar. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.smtpProbar]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA51]" }); 
                resolve();
            });
        });
    }

    // Método privado
    validarEliminarRol ( idRol ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/rol/' + idRol + '/validar/eliminar' ,config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error validar eliminar rol. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.validarEliminarRol]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA52]" }); 
                resolve();
            });
        });
    }

    // Método privado
    eliminarRol ( idRol ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.delete( Constantes.URL_API_SUMA + '/admin/rol/' + idRol  ,config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error eliminar rol. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.eliminarRol]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA53]" }); 
                resolve();
            });
        });
    }

    // Método privado
    validarEliminarOpcionDependencia ( idOpcionDependencia ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/opcion-dependencia/' + idOpcionDependencia + '/validar/eliminar' ,config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error validar eliminar opción dependencia. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.validarEliminarOpcionDependencia]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA72]" }); 
                resolve();
            });
        });
    }

    // Método privado
    eliminarOpcionDependencia ( idOpcionDependencia ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` } };

        return new Promise(resolve =>{
            axios.delete( Constantes.URL_API_SUMA + '/admin/opcion-dependencia/' + idOpcionDependencia  ,config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error eliminar opción dependencia. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.eliminarOpcionDependencia]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA73]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerEstadisticasUsuarios () {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/estadisticas-usuarios',config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener estadísticas usuarios. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerEstadisticasUsuarios]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA56]" }); 
                resolve();
            });
        });
    }

    // Método privado
    descargarReporteUsuarios ( filtroApi, callbackProgreso ) {
        let token = this.cookies.get('sumajwt');
        const config= { 
            headers:{ 'Authorization': `Bearer ${token}` }, 
            params: filtroApi,
            responseType: 'blob',
            onDownloadProgress(progressEvent) {
                if( callbackProgreso ){
                    let total = progressEvent.total;
                    let loaded = progressEvent.loaded;
                    if( total === 0 ){ total = loaded };
                    let percentCompleted = parseInt((loaded / total) * 100);
                    callbackProgreso( percentCompleted );
                }
            }
        };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/reporte-usuarios',config)
            .then(res => {
                resolve( res );
                return;
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.descargarReporteUsuarios] [Cod:SA57]',err);
                resolve( {...err} );
            });
        });
    }


    // Método privado
    obtenerPersonasJuridicas ( filtroApi ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }, params: filtroApi};

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/personas-juridicas',config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener personas juridicas. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerPersonasJuridicas]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA58]" }); 
                resolve();
            });
        });
    }

    // Método privado
    descargarReportePersonasJuridicas ( filtroApi, callbackProgreso ) {
        let token = this.cookies.get('sumajwt');
        const config= { 
            headers:{ 'Authorization': `Bearer ${token}` }, 
            params: filtroApi,
            responseType: 'blob',
            onDownloadProgress(progressEvent) {
                if( callbackProgreso ){
                    let total = progressEvent.total;
                    let loaded = progressEvent.loaded;
                    if( total === 0 ){ total = loaded };
                    let percentCompleted = parseInt((loaded / total) * 100);
                    callbackProgreso( percentCompleted );
                }
            }
        };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/reporte-personas-juridicas',config)
            .then(res => {
                resolve( res );
                return;
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.descargarReportePersonasJuridicas] [Cod:SA59]',err);
                resolve( {...err} );
            });
        });
    }

    // Método privado
    obtenerPersonasNaturales ( filtroApi ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }, params: filtroApi};

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/personas-naturales',config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener personas naturales. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerPersonasNaturales]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA60]" }); 
                resolve();
            });
        });
    }

    // Método privado
    descargarReportePersonasNaturales ( filtroApi, callbackProgreso ) {
        let token = this.cookies.get('sumajwt');
        const config= { 
            headers:{ 'Authorization': `Bearer ${token}` }, 
            params: filtroApi,
            responseType: 'blob',
            onDownloadProgress(progressEvent) {
                if( callbackProgreso ){
                    let total = progressEvent.total;
                    let loaded = progressEvent.loaded;
                    if( total === 0 ){ total = loaded };
                    let percentCompleted = parseInt((loaded / total) * 100);
                    callbackProgreso( percentCompleted );
                }
            }
        };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/reporte-personas-naturales',config)
            .then(res => {
                resolve( res );
                return;
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.descargarReportePersonasNaturales] [Cod:SA61]',err);
                resolve( {...err} );
            });
        });
    }

    // Método privado
    obtenerApoderadosSuma ( filtroApi ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }, params: filtroApi};

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/apoderados-suma',config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener apoderados suma. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerApoderadosSuma]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA62]" }); 
                resolve();
            });
        });
    }

    // Método privado
    descargarReporteApoderadosSuma ( filtroApi, callbackProgreso ) {
        let token = this.cookies.get('sumajwt');
        const config= { 
            headers:{ 'Authorization': `Bearer ${token}` }, 
            params: filtroApi,
            responseType: 'blob',
            onDownloadProgress(progressEvent) {
                if( callbackProgreso ){
                    let total = progressEvent.total;
                    let loaded = progressEvent.loaded;
                    if( total === 0 ){ total = loaded };
                    let percentCompleted = parseInt((loaded / total) * 100);
                    callbackProgreso( percentCompleted );
                }
            }
        };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/reporte-apoderados-suma',config)
            .then(res => {
                resolve( res );
                return;
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.descargarReporteApoderadosSuma] [Cod:SA63]',err);
                resolve( {...err} );
            });
        });
    }


    // Método privado
    obtenerRepresentantesLegales ( filtroApi ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }, params: filtroApi};

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/representante-legal',config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener representantes legales. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerRepresentantesLegales]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA64]" }); 
                resolve();
            });
        });
    }

    // Método privado
    descargarReporteRepresentantesLegales ( filtroApi, callbackProgreso ) {
        let token = this.cookies.get('sumajwt');
        const config= { 
            headers:{ 'Authorization': `Bearer ${token}` }, 
            params: filtroApi,
            responseType: 'blob',
            onDownloadProgress(progressEvent) {
                if( callbackProgreso ){
                    let total = progressEvent.total;
                    let loaded = progressEvent.loaded;
                    if( total === 0 ){ total = loaded };
                    let percentCompleted = parseInt((loaded / total) * 100);
                    callbackProgreso( percentCompleted );
                }
            }
        };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/reporte-representante-legal',config)
            .then(res => {
                resolve( res );
                return;
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.descargarReporteRepresentantesLegales] [Cod:SA65]',err);
                resolve( {...err} );
            });
        });
    }

    // Método privado
    obtenerEstadisticasSolicitudes () {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/estadisticas-solicitudes',config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener estadísticas solicitudes. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerEstadisticasSolicitudes]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA66]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerSolicitudes ( filtroApi ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }, params: filtroApi};

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/solicitudes',config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener solicitudes. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerSolicitudes]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA67]" }); 
                resolve();
            });
        });
    }

    // Método privado
    descargarReporteSolicitudes ( filtroApi, callbackProgreso ) {
        let token = this.cookies.get('sumajwt');
        const config= { 
            headers:{ 'Authorization': `Bearer ${token}` }, 
            params: filtroApi,
            responseType: 'blob',
            onDownloadProgress(progressEvent) {
                if( callbackProgreso ){
                    let total = progressEvent.total;
                    let loaded = progressEvent.loaded;
                    if( total === 0 ){ total = loaded };
                    let percentCompleted = parseInt((loaded / total) * 100);
                    callbackProgreso( percentCompleted );
                }
            }
        };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/reporte-solicitudes',config)
            .then(res => {
                resolve( res );
                return;
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.descargarReporteSolicitudes] [Cod:SA68]',err);
                resolve( {...err} );
            });
        });
    }

    // Método privado
    obtenerDirecciones ( filtroApi ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }, params: filtroApi};

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/direcciones',config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener direcciones. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerDirecciones]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA69]" }); 
                resolve();
            });
        });
    }

    // Método privado
    descargarReporteDirecciones ( filtroApi, callbackProgreso ) {
        let token = this.cookies.get('sumajwt');
        const config= { 
            headers:{ 'Authorization': `Bearer ${token}` }, 
            params: filtroApi,
            responseType: 'blob',
            onDownloadProgress(progressEvent) {
                if( callbackProgreso ){
                    let total = progressEvent.total;
                    let loaded = progressEvent.loaded;
                    if( total === 0 ){ total = loaded };
                    let percentCompleted = parseInt((loaded / total) * 100);
                    callbackProgreso( percentCompleted );
                }
            }
        };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/reporte-direcciones',config)
            .then(res => {
                resolve( res );
                return;
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.descargarReporteDirecciones] [Cod:SA70]',err);
                resolve( {...err} );
            });
        });
    }

    // Método privado
    obtenerUsuarioLdap ( _usuario ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }, params: { usuario: _usuario}};

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/usuario-ldap',config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener Usuario Ldap. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerUsuarioLdap]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA74]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerListaDeRegistros (  ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }, params: {}};

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/admin/registro',config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener Lista de Registros. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerListaDeRegistros]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA75]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerRegistro ( nombreRegistro ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }, params: {}};

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + `/admin/registro/${nombreRegistro}`,config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener Registro. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.obtenerRegistro]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SA76]" }); 
                resolve();
            });
        });
    }

    // Método privado
    descargarRegistro ( nombreRegistro, callbackProgreso ) {
        let token = this.cookies.get('sumajwt');
        const config= { 
            headers:{ 'Authorization': `Bearer ${token}` }, 
            params: {},
            responseType: 'blob',
            onDownloadProgress(progressEvent) {
                if( callbackProgreso ){
                    let total = progressEvent.total;
                    let loaded = progressEvent.loaded;
                    if( total === 0 ){ total = loaded };
                    let percentCompleted = parseInt((loaded / total) * 100);
                    callbackProgreso( percentCompleted );
                }
            }
        };

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + `/admin/registro/${nombreRegistro}/descargar`,config)
            .then(res => {
                resolve( res );
                return;
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioAdministracion.descargarRegistro] [Cod:SA77]',err);
                resolve( {...err} );
            });
        });
    }

}

export default ServicioAdministracion;