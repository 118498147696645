import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import SumaContexto from '../../contexto/SumaContexto';
import queryString from 'query-string';
import ServicioUsuarios from '../../servicios/usuarios/ServicioUsuarios'
import { Spin, Modal, Alert, Button } from 'antd';
import Constantes from '../../utilidades/Constantes'


function ValidarDesbloquearUsuario(attr) {
    //console.log('attr',attr);
    //let urlparametros = queryString.parse( attr.urlparametros );
    //console.log('urlparametros',urlparametros);

    if (attr.cargando) {
        return (
            <>
                <div className="titulo-seccion">
                    <label>DESBLOQUEAR USUARIO</label>
                    <div className="separador-horizontal-completo"></div>
                </div>
                <div className="cargando-pagina">
                    <Spin tip="Por favor espera..." size="large" ></Spin>
                </div>
            </>
        )
    }

    if (attr.msgError) {
        return (
            <>
                <div>
                    <Alert message={attr.msgError} type="warning" />
                </div>
            </>
        );
    } else {

        return (
            <>
                <div className="titulo-seccion">
                    <label>DESBLOQUEAR USUARIO</label>
                    <div className="separador-horizontal-completo"></div>
                    <p className="descripcion-seccion">
                        {attr.msg}
                    </p>
                    <Button type="primary" href={Constantes.URL_PUBLICAS.INICIO}>Iniciar sesión</Button>
                </div>
            </>
        );
    }
}

class DesbloquearUsuario extends Component {

    static contextType = SumaContexto;

    state = {
        cargando: true,
        idUsuario: 0,
        msgError: null
    };

    constructor(props) {
        super(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
    }

    async componentDidMount() {
        this._isMounted = true;
        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        let msg, msgError = undefined;

        this.enc = null;
        if (urlparametros.enc !== undefined) {
            this.enc = urlparametros.enc;
            let respuestaApi = await this.servicioUsuarios.desbloquearUsuario(urlparametros.enc);
            
            if (respuestaApi) {
                switch (respuestaApi.codigo) {
                    case Constantes.ENUMS_API.USUARIO.OPERACION_REALIZADA.id:
                        msg = respuestaApi.mensaje;
                        this.setSumaState({ idUsuario: parseInt(respuestaApi.resultado) });
                        break;
                    case Constantes.ENUMS_API.USUARIO.TOKEN_EXPIRADO.id:
                        msgError = 'El enlace no se encuentra activo para desbloquear el usuario. Debes solicitar o generar un nuevo correo para desbloquear tu usuario.';
                        Modal.warning({ content: msgError });
                        break;
                    case Constantes.ENUMS_API.USUARIO.OPERACION_NO_REALIZADA.id:
                        msgError = respuestaApi.mensaje;
                        Modal.warning({ content: msgError })
                        break;
                    default:
                        msgError = 'PETICIÓN NO DISPONIBLE';
                        break;
                }
                this.setSumaState({ cargando: false, msgError, msg });
            }

        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    render() {

        //const { usuario } = this.context

        return (
            <div className="seccion-principal seccion-flex">
                <div className="division-principal">
                    <label>Desbloquear Usuario</label>
                </div>
                <div className="division-contenido seccion-flex-grow-1 padding-principal bkg-white">
                    <ValidarDesbloquearUsuario cargando={this.state.cargando}
                        idUsuario={this.state.idUsuario}
                        msgError={this.state.msgError}
                        token={this.enc} 
                        msg={this.state.msg}/>
                </div>
            </div>
        );
    }
}

export default withRouter(DesbloquearUsuario);