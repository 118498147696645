import React, { Component } from 'react';
import SumaContexto  from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Form, Input, Button, Row, Col, Select, DatePicker, Popover, Modal, Spin } from 'antd';
import Validadores from '../utilidades/Validadores'
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import Constantes from '../utilidades/Constantes'
import ServicioDatosConstantes from '../servicios/ServicioDatosConstantes'
import ServicioPersonaNatural from '../servicios/usuarios/ServicioPersonaNatural'
import { withCookies } from 'react-cookie';
import moment from 'moment';
import Utilidades from '../utilidades/Utilidades';

const dateFormat = 'DD/MM/YYYY';

class InformacionBasicaPersonaNatural extends Component {

    static contextType = SumaContexto;
    
    ENUM_COMPONENTE ={
        CARGANDO:1,
        VER:2,
        NO_DISPONIBLE:3
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        cargando: true,
        tiposDocumentos:[],
        cargandoTipoDocumentos: false,
        usuario: null,
        deshabilitar:false,
    };

    constructor(props){
        super(props);
        this.historyLocal = props.history;
        this.servicioUsuarios = new ServicioUsuarios( props );
        this.servicioDatosConstantes = new ServicioDatosConstantes( props );
        this.servicioPersonaNatural = new ServicioPersonaNatural( props );
    }
    //const [form] = Form.useForm();
    formRef = React.createRef();

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales(){
        this.setSumaState({ cargando:true, cargandoTipoDocumentos:true });
        let usr = await this.servicioPersonaNatural.usuarioValido();
        if( usr !== null ){
            this.setSumaState({ componente: this.ENUM_COMPONENTE.VER });
            let personaNatural = await this.obtenerDatosPersonaNatural();
            if( personaNatural !== null ){
                //console.log('usr',usr);
                let form = this.formRef.current;
                if( form != null ){
                    await this.obtenerTipoDocumentos();
                    form.setFieldsValue({
                        'primerNombre' : usr.datoPersonal.primerNombre,
                        'segundoNombre' : usr.datoPersonal.segundoNombre,
                        'primerApellido' : usr.datoPersonal.primerApellido,
                        'segundoApellido' : usr.datoPersonal.segundoApellido,
                        'tipoDocumento': usr.datoPersonal.tipoDocumento.id.toString(),
                        'numeroDocumento': usr.datoPersonal.numeroDocumento,
                        'lugarExpedicion': usr.datoPersonal.lugarExpedicion,
                        'rut' : personaNatural.rut,
                        'digitoVerificacion' : personaNatural.digitoVerificacion,
                        'correoElectronico' : usr.datoPersonal.correoElectronico,
                        'movil' : usr.datoPersonal.celular,
                        'telefono' : usr.datoPersonal.telefono,
                        ...(usr.datoPersonal.fechaExpedicionFormatoEstandar && {'fechaExpedicion': moment(usr.datoPersonal.fechaExpedicionFormatoEstandar, dateFormat)} ),
                    });
                    this.setSumaState({ 
                        cargando: false, 
                        usuario: usr,
                        deshabilitar: !Utilidades.accionPermitida(usr, Constantes.ENUMS_MODULOS.PERSONA_NATURAL_INFORMACION_BASICA.ACCIONES.ACTUALIZAR_INFORMACION_PERSONA_NATURAL.id)
                     });
                }
            }
        }
    }

    obtenerTipoDocumentos () {
        return new Promise(resolve => {
            this.servicioDatosConstantes.obtenerTiposDocumentos(
                ( tiposDocumentos ) => { 
                    this.setSumaState({ tiposDocumentos: tiposDocumentos, }); 
                    setTimeout(() => {
                        this.setSumaState({ cargandoTipoDocumentos: false });
                        resolve(true);
                    },500); 
                },
                ( errorAPI )        => { 
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:IBPN02]" });
                    resolve(true);
                },
            );
        });
    }

    obtenerDatosPersonaNatural () {
        return new Promise(resolve => {
            this.servicioPersonaNatural.obtenerDatos(
                ( personaNatural ) => { 
                    //console.log('personaNatural', personaNatural);
                    if( personaNatural.codigo === Constantes.ENUMS_API.PERSONA_NATURAL.DATOS_RETORNADOS.id){
                        resolve( personaNatural.resultado );
                    }else{
                        resolve(true);
                        Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:IBPN06]" });
                    }
                },
                ( errorAPI )        => { 
                    resolve(true);
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:IBPN05]" });
                },
            );
        });
    }

    componentDidMount() {
        //console.log('componentDidMount InformacionBasicaEntidad',this.context);
        this._isMounted = true;
        this.cargarDatosIniciales();
    }
  
    componentWillUnmount() {
        this._isMounted = false;
    }
    
    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    render() {
      //let  {form} = this.props.form;
      const { Option } = Select;
      const { cargando, componente, cargandoTipoDocumentos, tiposDocumentos, deshabilitar } = this.state;
      const dateFormatList = ['DD/MM/YYYY'];

      const layout = {
        labelCol: {
          /*span: 10, offset: 2,*/
          sm: {span: 22, offset: 2},
          md: {span: 12, offset: 2},
          lg: {span: 12, offset: 2},
          xl: {span: 10, offset: 2}
        },
        wrapperCol: {
          sm: {span: 22, offset: 2},
          md: {span: 10},
          lg: {span: 10},  
          xl: {span: 12},
        },
      };

      const onFinish = values => {
        this.setSumaState({ cargando: true });
        let usrPersonaNatural = {
            datoPersonal:{
                primerNombre : values.primerNombre,
                segundoNombre : values.segundoNombre,
                primerApellido : values.primerApellido,
                segundoApellido : values.segundoApellido,
                numeroDocumento : values.numeroDocumento,
                lugarExpedicion : values.lugarExpedicion,
                fechaExpedicionDocumento: values.fechaExpedicion.format('DD/MM/YYYY'),
                celular : values.movil,
                telefono : values.telefono,
                tipoDocumento : {
                    id: parseInt(values.tipoDocumento)
                }, 
            },
            personaNatural:{
                rut : values.rut,
                digitoVerificacion : values.digitoVerificacion,
            }
        };

        this.servicioPersonaNatural.guardarDatos(
            ( respuestaApi )   =>  {  
                switch(respuestaApi.codigo){
                    case Constantes.ENUMS_API.PERSONA_NATURAL.DATOS_ENTIDAD_ACTUALIZADOS.id:
                        Modal.success({content:'Datos guardados.'});
                        const path = this.props.location.pathname;
                        this.historyLocal.push( path + "?cargar=1" );
                        break;
                    default:
                        Modal.error({content:'No fue posible guardar los datos. Por favor intenta mas tarde. [Cod:IBPN03]'});
                        break;
                }

                //console.log('respuestaApi',respuestaApi); 
                setTimeout(() => {
                    this.setSumaState({ cargando: false });
                },500); 
            },
            ( errorAPI )        => { 
                this.setSumaState({ cargando: false });
                Modal.error({content:'Error de conexión. Por favor intenta mas tarde. [Cod:IBPN04]'});
            },
            usrPersonaNatural
        );
      };
    
      const onFinishFailed = errorInfo => {
        Modal.warning({content:'Por favor diligencia la información solicitada.'});
      };
  
        return (
          <div>    
            {(componente === this.ENUM_COMPONENTE.VER) ?
                (
                    <>
                        <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>
                            <Form
                                {...layout}
                                ref={this.formRef}
                                name="formulario-persona-natural"
                                className="formulario-suma"
                                scrollToFirstError
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                >
                                <Row>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Primer nombre" name="primerNombre" 
                                            rules=  {[
                                                        { required: true, message: 'Ingresa tu primer nombre',},
                                                        { max:100, message: 'No puede ser mayor a 100 caracteres',},
                                                    ]} 
                                        >
                                            <Input autoComplete="off" disabled={deshabilitar} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Segundo nombre" name="segundoNombre" 
                                            rules=  {[
                                                        { required: false, },
                                                        { max:100, message: 'No puede ser mayor a 100 caracteres',},
                                                    ]} 
                                        >
                                            <Input autoComplete="off" disabled={deshabilitar}/>
                                        </Form.Item>
                                    </Col>    
                                </Row>
                                <Row>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Primer apellido" name="primerApellido" 
                                            rules=  {[
                                                        { required: true, message: 'Ingresa tu primer apellido',},
                                                        { max:100, message: 'No puede ser mayor a 100 caracteres',},
                                                    ]} 
                                        >
                                            <Input autoComplete="off" disabled={deshabilitar}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Segundo apellido" name="segundoApellido" 
                                            rules=  {[
                                                        { required: false, },
                                                        { max:100, message: 'No puede ser mayor a 100 caracteres',},
                                                    ]} 
                                        >
                                            <Input autoComplete="off" disabled={deshabilitar}/>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={24} sm={12}>
                                        <Form.Item  label="Tipo de documento" name="tipoDocumento" 
                                                    rules=  {[{ required: true, message: 'Selecciona tu tipo de documento',},]}
                                                    {...(cargandoTipoDocumentos ? {validateStatus:'validating', hasFeedback:true } : {})}>
                                            <Select {...(cargandoTipoDocumentos ? {disabled:true } : {})} disabled={deshabilitar}>
                                                {tiposDocumentos.map( tipoDocumento => (
                                                    <Option key={ tipoDocumento.id }>{ tipoDocumento.tipoDocumento }</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Número de documento" name="numeroDocumento" dependencies={['tipoDocumento']}
                                            rules=  {[
                                                        { required: true, message: 'Ingresa tu número de documento',},
                                                        { min:5, message: 'No puede ser menor a 5 caracteres',},
                                                        { max:50, message: 'No puede ser mayor a 50 caracteres',},
                                                        ({ getFieldValue }) => ({
                                                            validator(rule, value) {
                                                                if (value && parseInt(getFieldValue('tipoDocumento')) === Constantes.ENUMS.TIPO_DOCUMENTO.CEDULA_CIUDADANIA.id && !Validadores.validarDigitos( value ).success ) {
                                                                    return Promise.reject('Ingresa solo dígitos');
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        }),
                                                    ]} 
                                        >
                                            <Input autoComplete="off" disabled={deshabilitar} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Lugar de expedición" name="lugarExpedicion" 
                                            rules=  {[
                                                        { required: true, message: 'Ingresa el lugar de expedición del documento',},
                                                        { min:4, message: 'No puede ser menor a 4 caracteres',},
                                                        { max:250, message: 'No puede ser mayor a 250 caracteres',},
                                                    ]} 
                                        >
                                            <Input autoComplete="off" disabled={deshabilitar}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Fecha expedición" name="fechaExpedicion" rules={[{ required: true, message: 'Ingresa la fecha de expedición del documento',},]} >
                                            <DatePicker autoComplete="off" disabled={deshabilitar} format={dateFormatList} style={{ width: '100%' }}/>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="RUT" style={{ marginBottom: 0 }}>
                                            <Form.Item name="rut" 
                                                style={{ display: 'inline-block', width: 'calc(80% - 8px)' }}
                                                rules=  {[
                                                            { required: true, message: 'Ingresa el RUT',},
                                                            { min:5, message: 'No puede ser menor a 5 caracteres',},
                                                            { max:50, message: 'No puede ser mayor a 50 caracteres',},
                                                            ({ getFieldValue }) => ({
                                                                validator(rule, value) {
                                                                    if ( value && !Validadores.validarDigitos( value ).success ) {
                                                                        return Promise.reject('Ingresa solo dígitos');
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            }),
                                                        ]} 
                                            >
                                                <Input autoComplete="off" disabled={deshabilitar}/>
                                            </Form.Item>
                                            <Popover content={(<div>Ingresa sólo un dígito</div>)} title="Digito Verificación" trigger="focus">
                                                <Form.Item name="digitoVerificacion" 
                                                    style={{ display: 'inline-block', width: 'calc(20% - 0px)', margin: '0 0 0 8px' }}
                                                    rules=  {[
                                                                { required: true, message: 'Dígito',},
                                                                { max:5, message: '*',},
                                                                () => ({
                                                                    validator(rule, value) {
                                                                        if ( value && !Validadores.validarDigitos( value ).success ) {
                                                                            return Promise.reject('*');
                                                                        }
                                                                        return Promise.resolve();
                                                                    },
                                                                }),
                                                            ]} 
                                                >       
                                                    <Input autoComplete="off" disabled={deshabilitar}/>
                                                </Form.Item>
                                            </Popover>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Correo electrónico" name="correoElectronico" >
                                            <Input autoComplete="off" disabled={true} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={24} sm={12}>
                                        <Form.Item  label="Móvil" name="movil" 
                                                    rules=  {[
                                                        { required: true, message: 'Ingresa tu móvil',},
                                                        { min:7, message: 'No puede ser menor a 7 caracteres',},
                                                        { max:20, message: 'No puede ser mayor a 20 caracteres',},
                                                        { pattern: new RegExp('^[0-9\\+\\s]*$'), message: 'Solo dígitos y puede contener el simbolo +',},
                                                    ]} >
                                            <Input autoComplete="off" disabled={deshabilitar}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Teléfono" name="telefono" 
                                                        rules=  {[
                                                            { required: false},
                                                            { min:7, message: 'No puede ser menor a 7 caracteres',},
                                                            { max:30, message: 'No puede ser mayor a 30 caracteres',},
                                                        ]}>
                                            <Input autoComplete="off" disabled={deshabilitar}/>
                                        </Form.Item>
                                    </Col>
                                </Row>


                                <Form.Item>
                                    <Button type="primary" htmlType="submit" 
                                            loading={ cargando || cargandoTipoDocumentos }
                                            disabled={deshabilitar}>
                                        Guardar
                                    </Button>
                                </Form.Item>

                            </Form>
                        </Spin>
                    </>
                )
                :
                (
                    (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                    (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                    :((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:IBPN]</label>) : (<></>))
                )
            }

          </div>
        );
    }
}
          
export default withRouter(withCookies(InformacionBasicaPersonaNatural));