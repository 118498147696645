import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Form, Input, Button, Row, Col, Spin, Modal } from 'antd';
import ServicioUsuarios from '../../servicios/usuarios/ServicioUsuarios'
import { withCookies } from 'react-cookie';
import Constantes from '../../utilidades/Constantes';

class AsignarUsuarioCorreoElectronico extends Component {

  static contextType = SumaContexto;

  state = {
    cargando: true,
    usuario: null,
    opcionesSUMA: []
  };

  constructor(props) {
    super(props);
    this.servicioUsuarios = new ServicioUsuarios(props);
    const { cookies } = props;
    this.cookies = cookies;
    this.token = this.cookies.get('sumajwt');
  }

  componentDidMount() {
    this._isMounted = true;
    this.cargarDatosIniciales();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setSumaState(valores) {
    if (this._isMounted) {
      this.setState(valores);
    }
  }

  // -----------------------------------------------------------------------
  // --- CARGA DATOS INICIALES ---------------------------------------------
  // -----------------------------------------------------------------------
  async cargarDatosIniciales() {
    let usr = await this.obtenerUsuarioLogeado();
    if (usr !== null) {
      this.setSumaState({ usuario: usr, cargando: (usr === null) });
    }
  }

  obtenerUsuarioLogeado() {
    return new Promise(resolve => {
      this.servicioUsuarios.obtenerUsuarioLogeado(
        (dataAPI) => {
          resolve(dataAPI);
        },
        (errorAPI) => {
          Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:AUCE01]" });
          resolve(null);
        },
      );
    });
  }

  cambioContrasenaSatisfactorio = () => { 
    
  };

  render() {

    const { usuario } = this.state;
    const { history } = this.props;
    this.historyLocal = history;


    const layout = {
      labelCol: {
        /*span: 10, offset: 2,*/
        sm: {span: 22, offset: 2},
        md: {span: 12, offset: 2},
        lg: {span: 12, offset: 2},
        xl: {span: 10, offset: 2}
      },
      wrapperCol: {
        sm: {span: 22, offset: 2},
        md: {span: 10},
        lg: {span: 10},  
        xl: {span: 12},
      },
    };

    const onFinish = async values => {
      this.setSumaState({ cargando: true });
      let respuestaApi = await this.servicioUsuarios.asignarCorreoElectronicoUsuario( {correoElectronico : values.correoElectronico} );
      //console.log('respuestaApi',respuestaApi);
      if( respuestaApi ){
        switch( respuestaApi.codigo ){
          case Constantes.ENUMS_API.USUARIO.OPERACION_REALIZADA.id:
            Modal.success({content:respuestaApi.mensaje, onOk:() =>{ window.location.reload(); }});
            break;
          case Constantes.ENUMS_API.USUARIO.CORREO_NO_DISPONIBLE.id:
          case Constantes.ENUMS_API.USUARIO.DOMINIO_DEL_DADEP.id:
            Modal.error({ content:respuestaApi.mensaje });
            this.setSumaState({ cargando: false });
            break;
          default:
            Modal.error({ content: 'No fue posible asignar el correo electrónico al usuario. Por favor intenta mas tarde.' });
            this.setSumaState({ cargando: false });
            break;
        }
      }
      
    };
  
    const onFinishFailed = errorInfo => {
      Modal.warning({content:'Por favor diligencia la información solicitada.'});
    };

    return (

      <div className="seccion-principal contenedor-completo seccion-flex">{/* seccion-principal */}

        <div className="division-principal">
            <label>Asignar correo electrónico</label>
        </div>
        <div className="division-contenido seccion-flex-grow-1 padding-principal bkg-white">

          <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>
            <div className="titulo-seccion">
              <label>ASIGNAR CORREO ELECTRÓNICO</label>
              <div className="separador-horizontal-completo"></div>
            </div>

            <div className="">

              {(usuario !== null) ?
                (
                    <>
                      <p className="descripcion-seccion">Debes ingresar un correo electrónico al cual enviaremos un enlace para activar tu cuenta. Una vez activada tu cuenta, podrás iniciar sesión dentro del portal SUMA+ con el correo electrónico que ingreses. </p>
                      
                      <br/>
                    
                      <div className="seccion-formulario">
                          
                        <Form
                            {...layout}
                            ref={this.formRef}
                            name="asignar-contrasena"
                            className="formulario-suma"
                            scrollToFirstError
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            >
                            
                            <Row>
                                <Col xs={24} sm={12}>
                                  <Form.Item label="Correo electrónico" name="correoElectronico" 
                                      rules=  {[
                                                  { required: true, message: 'Ingresa tu correo electrónico',},
                                                  { type: 'email',  message: 'Ingresa un correo electrónico válido',},
                                                  { max:255, message: 'No puede ser mayor a 255 caracteres',},
                                              ]} 
                                  >
                                      <Input autoComplete="off" />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                  <Form.Item label="Confirmar correo electrónico" name="correoElectronicoConfirmar" 
                                      dependencies={['correoElectronico']} hasFeedback
                                      rules=  {[
                                                  { required: true, message: 'Confirma tu correo electrónico',},
                                                  { type: 'email',  message: 'Ingresa un correo electrónico válido',},
                                                  { max:255, message: 'No puede ser mayor a 255 caracteres',},
                                                  ({ getFieldValue }) => ({
                                                      validator(rule, value) {
                                                        if (!value || getFieldValue('correoElectronico') === value) {
                                                          return Promise.resolve();
                                                        }
                                                        return Promise.reject('No coindicen los correos electrónicos');
                                                      },
                                                  }),
                                              ]} 
                                  >
                                      <Input autoComplete="off" />
                                  </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Guardar Correo Electrónico
                                </Button>
                            </Form.Item>

                        </Form>

                      </div>
                    </>
                )
                : (<></>)}

            </div>
          </Spin>
        </div>
      </div>

    );
  }
}

export default withRouter(withCookies(AsignarUsuarioCorreoElectronico));