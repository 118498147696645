import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter, Switch, Route } from "react-router-dom";
import { BankOutlined } from '@ant-design/icons';
import { Tabs, Spin } from 'antd';
import InformacionBasicaEntidad from '../persona-juridica/InformacionBasicaEntidad'
import RepresentantesLegales from '../persona-juridica/representantes-legales/RepresentantesLegales'
import SubUsuarios from '../persona-juridica/sub-usuarios/SubUsuarios'
import PersonaJuridicaDocumentos from '../persona-juridica/PersonaJuridicaDocumentos'
import ServicioPersonaJuridica from '../servicios/usuarios/ServicioPersonaJuridica'
import { withCookies } from 'react-cookie';
import ApoderadosJuridica from './apoderados-juridica/ApoderadosJuridica';

const { TabPane } = Tabs;

class DatosDeLaEntidad extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    PATHS_TABS = {
        DATOS: {
            pestana: '/persona-juridica-datos',
            paths: ['/persona-juridica-datos']
        },
        REPRESENTANTES: {
            pestana: '/persona-juridica-representantes',
            paths: [
                '/persona-juridica-representantes',
                '/persona-juridica-ver-representante',
                '/persona-juridica-agregar-representante',
                '/persona-juridica-editar-representante'
            ]
        },
        APODERADOS: {
            pestana: '/persona-juridica-apoderados',
            paths: [
                '/persona-juridica-apoderados',
                '/persona-juridica-ver-apoderado',
                '/persona-juridica-agregar-apoderado',
                '/persona-juridica-editar-apoderado'
            ]
        },
        USUARIOS: {
            pestana: '/persona-juridica-usuarios',
            paths: [
                '/persona-juridica-usuarios',
                '/persona-juridica-ver-usuario',
                '/persona-juridica-agregar-usuario',
                '/persona-juridica-editar-usuario'
            ]
        },
        DOCUMENTOS: {
            pestana: '/persona-juridica-documentos',
            paths: ['/persona-juridica-documentos']
        },
    };

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        tab: this.PATHS_TABS.DATOS.pestana,
    };


    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioPersonaJuridica = new ServicioPersonaJuridica(props);
        // Se extrae el PATH inicial de la URL
        this.pathInicial = props.history.location.pathname;
        this.props.history.listen(this.manejadorCambioPath);
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ tab: this.obtenerPestanaPorPath(this.pathInicial) });
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        // Validando que el usuario es PERSONA JURIDICA
        let usr = await this.servicioPersonaJuridica.usuarioValido();
        this.setState({ componente: (usr !== null ? this.ENUM_COMPONENTE.VER : this.ENUM_COMPONENTE.NO_DISPONIBLE) });
    }

    manejadorCambioPath = (location, action) => {
        if (this._isMounted) {
            this.setState({ tab: this.obtenerPestanaPorPath(location.pathname) });
        }
    }


    obtenerPestanaPorPath = (path) => {
        let pestanas = Object.keys(this.PATHS_TABS);
        for (let index in pestanas) {
            let paths_tab = this.PATHS_TABS[pestanas[index]];

            for (let index02 in paths_tab.paths) {
                let path_tab = paths_tab.paths[index02];
                if (path.includes(path_tab)) {
                    return paths_tab.pestana;
                }
            }
        }
    }

    manejadorClickTab = e => {
        // Se deja el tab en null para obligar a refrescar el componente hijo
        this.setState({ tab: null });

        // Con el tab seleccionado tambien se cambia la URL, se deja dentro del setTimeout con el
        // fin de obligar a refrescar el componente hijo
        setTimeout(() => { this.historyLocal.push(e); }, 100);
    };

    render() {

        const { componente, tab } = this.state;

        return (
            <div className="seccion-principal contenedor-completo seccion-flex ">

                <div className="division-principal">
                    <label>Mis sitios / Datos de la entidad</label>
                </div>
                <div className="division-contenido seccion-flex-grow-1 padding-principal bkg-white">

                    {(componente === this.ENUM_COMPONENTE.VER) ?
                        (
                            <>

                                <div className="titulo-seccion">
                                    <label> <BankOutlined /> DATOS DE LA ENTIDAD</label>
                                    <div className="separador-horizontal-completo"></div>
                                </div>

                                <div>
                                    <Tabs activeKey={this.state.tab} onChange={this.manejadorClickTab}>
                                        <TabPane tab="Datos" key={this.PATHS_TABS.DATOS.pestana} />
                                        <TabPane tab="Representantes legales" key={this.PATHS_TABS.REPRESENTANTES.pestana} />
                                        <TabPane tab="Apoderados" key={this.PATHS_TABS.APODERADOS.pestana} />
                                        <TabPane tab="Usuarios" key={this.PATHS_TABS.USUARIOS.pestana} />
                                        <TabPane tab="Documentos" key={this.PATHS_TABS.DOCUMENTOS.pestana} />
                                    </Tabs>

                                    <Switch>
                                        <Route path={this.PATHS_TABS.DATOS.paths}>
                                            {tab !== null ? <InformacionBasicaEntidad /> : <></>}
                                        </Route>
                                        <Route path={this.PATHS_TABS.REPRESENTANTES.paths}>
                                            {tab !== null ? <RepresentantesLegales /> : <></>}
                                        </Route>
                                        <Route path={this.PATHS_TABS.APODERADOS.paths}>
                                            {tab !== null ? <ApoderadosJuridica /> : <></>}
                                        </Route>
                                        <Route path={this.PATHS_TABS.USUARIOS.paths}>
                                            {tab !== null ? <SubUsuarios /> : <></>}
                                        </Route>
                                        <Route path={this.PATHS_TABS.DOCUMENTOS.paths}>
                                            {tab !== null ? <PersonaJuridicaDocumentos /> : <></>}
                                        </Route>
                                    </Switch>
                                </div>


                            </>
                        )
                        :
                        (
                            (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                                (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                                : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:DDLE]</label>) : (<></>))
                        )
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(withCookies(DatosDeLaEntidad));