import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Popconfirm, Button, Table, Tag, Tooltip, Spin } from 'antd';
import { UserOutlined, StopOutlined, CheckOutlined, MailOutlined, SearchOutlined } from '@ant-design/icons';
import Constantes from '../../utilidades/Constantes'
import ServicioPersonaJuridica from '../../servicios/usuarios/ServicioPersonaJuridica'
import ServicioDatosConstantes from '../../servicios/ServicioDatosConstantes'
import Utilidades from '../../utilidades/Utilidades';
import FiltroColumna from '../../general/tabla/FiltroColumna'
import FiltroEtiqueta from '../../general/tabla/FiltroEtiqueta'


class ListarSubUsuarios extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        usuarios: [],
        cargando: false,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        usuario: null,

        filtro: {},
        filtroEtiquetas: [],

    };

    constructor(props) {
        super(props);
        this.servicioPersonaJuridica = new ServicioPersonaJuridica(props);
        this.clickVerUsuario = props.clickVerUsuario;
        this.callbackActivarUsuario = props.callbackActivarUsuario;
        this.callbackEnviarCorreoActivacion = props.callbackEnviarCorreoActivacion;

        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        let contexto = this;
        this.filtroColumna = new FiltroColumna(props, (valores) => { contexto.setSumaState(valores, contexto) });
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        // Validando que el usuario sea de tipo Persona Jurídica o Adminsitrador
        let usr = await this.servicioPersonaJuridica.usuarioPersonaJuridicaOAdministradorValido();
        this.setSumaState({ componente: (usr !== null ? this.ENUM_COMPONENTE.VER : this.ENUM_COMPONENTE.NO_DISPONIBLE) });
        if (usr !== null) {
            this.setSumaState({ cargando: true, });
            const estados = await this.servicioDatosConstantes.obtenerEstadosUsuario();
            let estadosUsuario = [];
            if (estados) {
                for (let estado of estados) {
                    estadosUsuario.push({ label: estado.estadoUsuario, value: estado.id });
                }
            }
            await this.cargarDatosTabla(this);
            this.setSumaState({ cargando: false, usuario: usr, estadosUsuario: estadosUsuario });
        }
    }


    // 'limpiarFiltro': Método (clearFilters) que es propio de la tabla antDesign para limpiar los filtros
    // 'confirmarFiltro': Método (confirm) que es própio de la tabla antDesign para aplicar el filtro
    // 'dataIndex': Retorna el campo que se está filtrando
    // 'selectedKeys': Retorna el valor a filtrar;
    async cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) {
        let filtro = contexto.state.filtro;
        if (dataIndex !== undefined) { // Si el filtro es aplicado, se agrega la columna filtrada
            filtro[dataIndex] = selectedKeys;
        }        
        contexto.setSumaState({ cargando: true, filtro: filtro });

        // Se construye el filtroApi a partir de lo que fue filtrado en la tabla
        let filtroApi = {
            ...(filtro.usuario && filtro.usuario.length > 0 && {usuario:filtro.usuario[0]} ),
            ...(filtro.nombre && filtro.nombre.length > 0 && {nombre:filtro.nombre[0]} ),
            ...(filtro.rol && filtro.rol.length > 0 && {rol:filtro.rol[0]} ),
            ...(filtro.estado && filtro.estado.length > 0 && filtro.estado[0].opc && {estado:filtro.estado[0].opc.join()} ),
        };

        await this.servicioPersonaJuridica.obtenerSubUsuarios(
            filtroApi,
            // El idPersonaJuridica se puede tomar por el componente padre como atributo, si no llega por aquí, se toma la perona jurídica del usuario que está logueado.
            this.props.idPersonaJuridica
            ).then((respuestaApi) => {
                if (respuestaApi) {
                    let usuarios = [];
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.PERSONA_JURIDICA.DATOS_RETORNADOS.id:
                            usuarios = respuestaApi.resultado;
                            break;
                        default: break;
                    }
                    contexto.setSumaState({ usuarios: usuarios, cargando: false });
                }
            }).then(() => {
                if (limpiarFiltro !== undefined) { limpiarFiltro(); }
                if (confirmarFiltro !== undefined) { confirmarFiltro(); }
            });
    }



    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    render() {

        const { usuarios, componente, usuario, estadosUsuario } = this.state;
        let { filtro } = this.state;
        filtro = filtro || {};
        let contexto = this;

        const columns = [
            {
                title: 'Usuario',
                dataIndex: 'usuario',
                key: 'usuario',
                sorter: (a, b) => a.usuario ? a.usuario.localeCompare(b.usuario) : 1,
                filteredValue: filtro.usuario || null,
                ...this.filtroColumna.busquedaTexto(
                    'Usuario',
                    'usuario',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                render: (text, record) => (
                    <span>{text}</span>
                ),
                className:"td-usuario-alias"
            },
            {
                title: 'Nombre',
                dataIndex: 'nombre',
                key: 'nombre',
                sorter: (a, b) => a.nombre ? a.nombre.localeCompare(b.nombre) : 1,
                filteredValue: filtro.nombre || null,
                ...this.filtroColumna.busquedaTexto(
                    'Nombre',
                    'nombre',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                responsive: ['md'], /* visible en pantalla con ancho ≥ 768px*/
            },
            {
                title: 'Rol',
                dataIndex: 'rol',
                key: 'rol',
                sorter: (a, b) => a.rol ? a.rol.localeCompare(b.rol) : 1,
                filteredValue: filtro.rol || null,
                ...this.filtroColumna.busquedaTexto(
                    'Rol',
                    'rol',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                responsive: ['md'], /* visible en pantalla con ancho ≥ 768px*/
            },
            {
                title: 'Estado',
                key: 'estado',
                align: 'center',
                dataIndex: 'estado',
                sorter: (a, b) => a.estado ? a.estado.localeCompare(b.estado) : 1,
                filteredValue: filtro.estado || null,
                ...this.filtroColumna.busquedaOpciones(
                    'Estado',
                    'estado',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) },
                    estadosUsuario
                ),
                responsive: ['sm'], /* visible en pantalla con ancho ≥ 576px*/
                render: (text, record) => (
                    <>
                        <Tag key={text} color={
                            (record.idEstado === Constantes.ENUMS.ESTADO_USUARIO.ACTIVO.id)
                                ?
                                'green'
                                :
                                (record.idEstado === Constantes.ENUMS.ESTADO_USUARIO.INACTIVO.id
                                    ?
                                    'volcano'
                                    :
                                    (record.idEstado === Constantes.ENUMS.ESTADO_USUARIO.CREADO.id
                                        ?
                                        'geekblue'
                                        :
                                        ''
                                    )
                                )
                        }>
                            {text.toUpperCase()}
                        </Tag>

                        { record.cambioContrasena
                            ?
                            <Tooltip title="El usuario tiene pendiente un cambio de contraseña">
                                <br />
                                <Tag color="purple" key="Cambio Contraseña">CONTRASEÑA</Tag>
                            </Tooltip>
                            :
                            <></>
                        }

                    </>
                ),
            },
            {
                title: 'Opciones',
                key: 'opciones',
                align: 'center',
                className: 'columna-opciones',
                width: 112,
                render: (text, record) => (
                    <>
                        <Tooltip title="Ver usuario">
                            <Button shape="circle" disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_USUARIOS.ACCIONES.VER_DETALLE_SUB_USUARIO.id)}
                                icon={<UserOutlined style={{ 'color': '#c90a00' }} />}
                                onClick={(e) => { this.clickVerUsuario(e, record.id) }} />
                        </Tooltip>


                        {record.idEstado === Constantes.ENUMS.ESTADO_USUARIO.ACTIVO.id ?
                            (
                                <Popconfirm title="Confirma desactivar el usuario?"
                                    okText="Si"
                                    cancelText="No"
                                    onConfirm={(e) => {
                                        this.setSumaState({ cargando: true });
                                        this.callbackActivarUsuario(
                                            e,
                                            record.id,
                                            record.nombre,
                                            false,
                                            () => {
                                                this.setSumaState({ cargando: false });
                                                this.cargarDatosIniciales();
                                            });
                                    }}
                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_USUARIOS.ACCIONES.DESACTIVAR_SUB_USUARIO.id)}
                                >
                                    <Tooltip title="Desactivar usuario">
                                        <Button shape="circle"
                                            icon={<StopOutlined style={{ 'color': '#c90a00' }} />}
                                            disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_USUARIOS.ACCIONES.DESACTIVAR_SUB_USUARIO.id)} />
                                    </Tooltip>
                                </Popconfirm>
                            ) : (<></>)
                        }

                        {record.idEstado === Constantes.ENUMS.ESTADO_USUARIO.INACTIVO.id ?
                            (
                                <Popconfirm title="Confirma activar el usuario?"
                                    okText="Si"
                                    cancelText="No"
                                    onConfirm={(e) => {
                                        this.setSumaState({ cargando: true });
                                        this.callbackActivarUsuario(
                                            e,
                                            record.id,
                                            record.nombre,
                                            true,
                                            () => {
                                                this.setSumaState({ cargando: false });
                                                this.cargarDatosIniciales();
                                            });
                                    }}
                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_USUARIOS.ACCIONES.ACTIVAR_SUB_USUARIO.id)}
                                >
                                    <Tooltip title="Activar usuario">
                                        <Button shape="circle"
                                            icon={<CheckOutlined style={{ 'color': '#c90a00' }} />}
                                            disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_USUARIOS.ACCIONES.ACTIVAR_SUB_USUARIO.id)} />
                                    </Tooltip>
                                </Popconfirm>
                            ) : (<></>)
                        }

                        {record.idEstado === Constantes.ENUMS.ESTADO_USUARIO.CREADO.id ?
                            (
                                <Popconfirm title="Confirma enviar el correo de activación?"
                                    okText="Si"
                                    cancelText="No"
                                    onConfirm={(e) => {
                                        this.setSumaState({ cargando: true });
                                        this.callbackEnviarCorreoActivacion(
                                            e,
                                            record.id,
                                            record.nombre,
                                            () => {
                                                this.setSumaState({ cargando: false });
                                                this.cargarDatosIniciales();
                                            });
                                    }}
                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_USUARIOS.ACCIONES.ENVIAR_CORREO_ACTIVACION_SUB_USUARIO.id)}
                                >
                                    <Tooltip title="Enviar correo de activación">
                                        <Button shape="circle"
                                            icon={<MailOutlined style={{ 'color': '#c90a00' }} />}
                                            disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_USUARIOS.ACCIONES.ENVIAR_CORREO_ACTIVACION_SUB_USUARIO.id)} />
                                    </Tooltip>
                                </Popconfirm>
                            ) : (<></>)
                        }

                    </>
                ),
            },
        ];

        return (
            <>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>

                                {
                                    this.state.filtroEtiquetas.length > 0 ?
                                        (<div className="seccion-filtros-etiquetas">
                                            <div className="titulo-filtro-etiquetas">
                                                <label>Filtros <SearchOutlined /> :</label>
                                            </div>

                                            {
                                                this.state.filtroEtiquetas.map((filtroEtiqueta, i) => {
                                                    return (
                                                        <FiltroEtiqueta key={i}
                                                            dataIndex={filtroEtiqueta.dataIndex}
                                                            texto={filtroEtiqueta.texto}
                                                            onClose={filtroEtiqueta.borrarFiltro} />
                                                    )
                                                })
                                            }
                                        </div>)
                                        :
                                        (<></>)
                                }

                                <Table columns={columns}
                                    dataSource={usuarios}
                                    rowKey={"id"} />
                            </Spin>
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:SU]</label>) : (<></>))
                    )
                }
            </>
        );
    }
}

export default withRouter(withCookies(ListarSubUsuarios));