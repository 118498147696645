import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Tabs, Spin } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import ServicioDatosConstantes from '../servicios/ServicioDatosConstantes'
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import ServicioEntidad from '../servicios/ServicioEntidad'
import ServicioAdministracion from '../servicios/ServicioAdministracion'
import Constantes from '../utilidades/Constantes'
import Utilidades from '../utilidades/Utilidades'
import { withCookies } from 'react-cookie';
import ParametrosGenerales from './ParametrosGenerales'
import SMTP from './SMTP';
import GestorFicheros from './GestorFicheros';
import Depuracion from './Depuracion';
import Notificacion from './Notificacion';
import Registros from './Registros';

const { TabPane } = Tabs;

class Configuracion extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3,
        NO_HABILITADO: 4,
    }

    PATHS_TABS = {
        PARAMETROS_GENERALES:{
            pestana: '/parametros-generales'
        },
        SMTP: {
            pestana: '/smtp'
        },
        GESTOR_FICHEROS: {
            pestana: '/gestor-ficheros'
        },
        DEPURACION: {
            pestana: '/depuracion'
        },
        NOTIFICACION: {
            pestana: '/notificacion'
        },
        REGISTROS: {
            pestana: '/registros'
        },
    };

    state = {
        cargando: true,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        tab: this.PATHS_TABS.PARAMETROS_GENERALES.pestana,
    };

    //Contiene el id de los parámetros que son contraseñas
    idContrasenas = [
        15,//TOKEN - TOKEN_SECRET_KEY
        25,//GESTOR_FICHEROS_DMDEP - CONTRASEÑA
        31,//SMTP - CONTRASEÑA
        51,//LDAP - CONTRASEÑA
    ];

    //Contiene el id de los parámetros que son booleanos
    idBooleanos = [
        10,	//DEPURACION -	WARN
        12,	//DEPURACION -	EXCEPTION
        11,	//DEPURACION -	ERROR
        9,	//DEPURACION -	INFO
        8,	//DEPURACION -	DEBUG_EXCEPTION
        7,	//DEPURACION -	DEBUG_DEEP
        6,	//DEPURACION -	DEBUG
        3,	//SMTP -	MODO_PRUEBA
        43, //LIFERAY - ACTIVAR
        46, //SUMA+ - DESACTIVAR_AUTENTICACION
    ]

    idDigitos = [
        5,  //SUMA+	- TIMEOUT_SESION
        17, //TOKEN - TOKEN_EXPIRACION_SESION
        18, //TOKEN	- TOKEN_CAMBIO_CONTRASENA_TIEMPO_EXP
        20, //TOKEN	- TOKEN_ACTIVAR_CUENTA_TIEMPO_EXP
        28, //TOKEN	- TOKEN_EXPIRACION_DESCARGAR_ARCHIVO
        32,	//SMTP	- PUERTO
        42,	//LIFERAY - ID_COMPANIA
        45,  //SUMA+ - INTENTOS_LOGIN
        48,  //LDAP - PUERTO
        52,  //SUMA+ - ID_ENTIDAD_DADEP
    ]

    idFechaTiempo = [
        54,	//NOTIFICACION - FECHA_INICIAL
        55,	//NOTIFICACION - FECHA_FINAL
    ]

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioEntidad = new ServicioEntidad(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
    }
    


    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        
        let usuario = await this.servicioUsuarios.usuarioEnSesion();
        if ( usuario !== null) {
            let habilitado = Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.CONFIGURACION.ACCIONES.ACCEDER_MODULO_CONFIGURACION.id);
            if( habilitado ){
                this.setSumaState({ componente: this.ENUM_COMPONENTE.VER, cargando: true });
                let parametrosGenerales = await this.servicioAdministracion.obtenerParametrosGenerales();
                parametrosGenerales.sort((a, b) => a.conjunto ? a.conjunto.localeCompare( b.conjunto ) : 1 );
                let conjuntos = new Set();
                for( let parametroGeneral of parametrosGenerales ){
                    conjuntos.add( parametroGeneral.conjunto );
                }
                let parametros = {};
                for (let conjunto of conjuntos){
                    parametros[conjunto] = [];
                    for( let parametroGeneral of parametrosGenerales ){
                        if( parametroGeneral.conjunto === conjunto ){
                            parametros[conjunto].push(parametroGeneral);
                        }
                    }
                    parametros[conjunto].sort((a, b) => a.id > b.id ? 1 : -1);
                }
                
                //console.log('parametros',parametros);

                this.setSumaState({ cargando: false, usuario, parametros });
            }else{
                this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_HABILITADO }); 
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }

    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    manejadorClickTab = e => {
        // Aqui se maneja de forma distintita, dado que no se procede a cambiar la 
        // url por pestaña consultada
        this.setSumaState({ tab: e });
    };


    render() {
        const { componente, tab, parametros, usuario } = this.state;

        return (
            <div className="seccion-principal contenedor-completo seccion-flex ">
                <div className="division-principal">
                    <label>Configuración</label>
                </div>
                <div className="division-contenido seccion-flex-grow-1 padding-principal bkg-white contenido-usuarios">
                    {(componente === this.ENUM_COMPONENTE.VER) ?
                        (
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>

                                <div className="titulo-seccion">
                                        <label> <SettingOutlined /> CONFIGURACIÓN</label>
                                    <div className="separador-horizontal-completo"></div>
                                </div>

                                <div>
                                    <Tabs activeKey={this.state.tab} onChange={this.manejadorClickTab}>
                                        <TabPane tab="Parámetros generales" key={this.PATHS_TABS.PARAMETROS_GENERALES.pestana} />
                                        <TabPane tab="SMTP" key={this.PATHS_TABS.SMTP.pestana} />
                                        <TabPane tab="Gestor ficheros" key={this.PATHS_TABS.GESTOR_FICHEROS.pestana} />
                                        <TabPane tab="Depuración" key={this.PATHS_TABS.DEPURACION.pestana} />
                                        <TabPane tab="Notificación" key={this.PATHS_TABS.NOTIFICACION.pestana} />
                                        <TabPane tab="Registros" key={this.PATHS_TABS.REGISTROS.pestana} />
                                    </Tabs>

                                    {parametros ? 
                                    <>
                                        {tab === this.PATHS_TABS.PARAMETROS_GENERALES.pestana ? 
                                        <>
                                            <ParametrosGenerales parametros={parametros} usuario={usuario} idContrasenas={this.idContrasenas} idBooleanos={this.idBooleanos} idDigitos={this.idDigitos}/>
                                        </>
                                        : 
                                        tab === this.PATHS_TABS.SMTP.pestana ?
                                        <>
                                            <SMTP parametros={parametros} usuario={usuario} idContrasenas={this.idContrasenas} idBooleanos={this.idBooleanos} idDigitos={this.idDigitos}/>
                                        </>
                                        :
                                        tab === this.PATHS_TABS.GESTOR_FICHEROS.pestana ?
                                        <>
                                            <GestorFicheros parametros={parametros} usuario={usuario} idContrasenas={this.idContrasenas} idBooleanos={this.idBooleanos} idDigitos={this.idDigitos}/>
                                        </>
                                        :
                                        tab === this.PATHS_TABS.DEPURACION.pestana ?
                                        <>
                                            <Depuracion parametros={parametros} usuario={usuario} idContrasenas={this.idContrasenas} idBooleanos={this.idBooleanos} idDigitos={this.idDigitos}/>
                                        </>
                                        :
                                        tab === this.PATHS_TABS.NOTIFICACION.pestana ?
                                        <>
                                            <Notificacion parametros={parametros} usuario={usuario} idContrasenas={this.idContrasenas} idBooleanos={this.idBooleanos} idDigitos={this.idDigitos} idFechaTiempo={this.idFechaTiempo}/>
                                        </>
                                        :
                                        tab === this.PATHS_TABS.REGISTROS.pestana ?
                                        <>
                                            <Registros usuario={usuario} />
                                        </>
                                        :
                                        <></>}
                                    </>
                                    : null}


                                    


                                    
                                </div>

                            </Spin>
                        )
                        :
                        (
                            (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : (
                                (componente === this.ENUM_COMPONENTE.NO_HABILITADO ? 
                                <label>Configuración no disponible. {Constantes.MENSAJE_USUARIO_SIN_PERMISOS}</label> :
                                (componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:CONFIG]</label>) : (<></>))
                            )
                        )
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(withCookies(Configuracion));