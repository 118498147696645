import React, { Component } from 'react';
import SumaContexto  from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Button, Upload, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Utilidades from '../utilidades/Utilidades'
import ServicioArchivos from '../servicios/archivos/ServicioArchivos'

class SubirArchivoForm extends Component {

    static contextType = SumaContexto;
    
    state = {
        fileList: [],
    };

    constructor(props){
        super(props);
        this.tipoArchivo = props.tipoArchivo;
        this.servicioArchivos = new ServicioArchivos( props );
    }

    componentDidMount() {
    }

    beforeUpload = (file,fileList) => {
        let success = true;
        // Limpia la lista
        this.setState({ fileList: [] });
        // Valida el tipo del archivo 
        let tiposPermitidos = this.tipoArchivo.extensiones.toLowerCase().split(',');
        if( !tiposPermitidos.includes(file.type.toLowerCase()) ){
            let msg = 'El archivo ' + this.tipoArchivo.nombreArchivo + ' debe ser de tipo ' + Utilidades.obtenerTipoDeArchivoPermitido(this.tipoArchivo.extensiones) + '!';
            notification.error({ message: 'Error', description: msg, duration: 6, });
            // Se establece un mensaje personal para identificar el error 
            file.mensajeError = 'Error: Tipo de archivo no permitido!';
            success = false;
        }

        // Valida el tamano del archivo
        let tamanoPermitido = file.size <= this.tipoArchivo.tamano;
        if ( !tamanoPermitido ) {
            let msg = 'El archivo ' + this.tipoArchivo.nombreArchivo + ' debe ser menor a ' + Utilidades.obtenerTamanoEnString(this.tipoArchivo.tamano) + '!';
            notification.error({ message: 'Error', description: msg, duration: 6, });
            // Se establece un mensaje personal para identificar el error 
            file.mensajeError = 'Error: El archivo excede el tamaño permitido!';
            success = false;
        }
        
        if( !success ){
            // Si el archivo no cumple las condiciones para ser cargado, se marca el estado como 'error'
            file.status = 'error';
        }else{
            file.status = 'done';
        }
        return false;
    }

    handleChange = info => {
        console.log('info',info);
        let fileList = [...info.fileList];
        
        // Remueve el primer elemento para dejar siempre el ultimo
        if( fileList.length > 1)
            fileList.shift();
        // Se asume que la información completa y principal del archivo viene el arreglo fileList
        let archivo =  fileList[0];
        // Si no viene archivo dentro del arreglo de fileList, se toma el file que viene en el info
        if( !archivo )
            archivo = info.file;
        
        this.setState({ fileList: fileList });

        //console.log('handleChange fileList',fileList)
        this.props.onChange( fileList );

        if (archivo.status === 'uploading') {
          this.setState({ loading: true });
          return true;
        }
        if (archivo.status === 'removed') {
        }
        if (archivo.status === 'done') {
            // Asumiendo que se maneja solo un archivo por componente UPLOAD
            fileList[0].cargarArchivo = this.cargarArchivo;
            fileList[0].informacion = info;
        }
        else if (archivo.status === 'error') {
            info.file = {
                uid: archivo.uid,
                name: archivo.name,
                status: archivo.status
            };
            fileList = [ info.file ];
            this.setState({ fileList: fileList });
        }
    };

    cargarArchivo = ( file ) => {
        return new Promise(resolve => {
            // Se crea un objeto (reader) de tipo FileReader para cargar a nivel local el documento
            const reader = new FileReader();
            
            // Se agrega el evento 'loadend' con el fin de saber cuando termina de cargar el documento
            reader.addEventListener('loadend', (event) => {
                // Indica que hubo un error
                if( reader.error !== null ){
                    console.error( 'Error lectura archivo local' , reader.error );
                    resolve( null );
                }else{
                    // Se asume que aqui el archivo local fue leido exitosamente
                    // Se carga el documento en el API
                    this.servicioArchivos.cargarArchivo(
                        file,
                        this.tipoArchivo.id,
                        ( respuestaAPI )    => { 
                            //this.completeAPISubirArchivo( {onSuccess}, {onError}, {file}, respuestaAPI, reader.result ) 
                            resolve( respuestaAPI );
                        },
                        ( errorAPI )        => { resolve( null ); },
                        ( porcentaje )      => {  },
                    );
                }            
            });
            // Se carga en el objeto (reader) el documento a procesar
            reader.readAsDataURL(file);
        });
    }

    
    render() {
 
        const { fileList } = this.state;
        const { tipoArchivo } = this.props;  

        const propsUpload = {
            accept: tipoArchivo.extensiones,
            showUploadList:{ showPreviewIcon: true, showDownloadIcon: false, showRemoveIcon: true },
            className:'subir-archivo',
            multiple:false,
            beforeUpload: this.beforeUpload,
            onChange: this.handleChange,
            fileList
        };

        return (
          <div>    
            <Upload {...propsUpload} name={tipoArchivo.nombreIdentificador} > 
                <Button icon={<UploadOutlined />}>Seleccionar archivo</Button>
            </Upload>
          </div>
        );

    }
}
          
export default withRouter(withCookies(SubirArchivoForm));