import React from 'react';
import ReporteUsuarios from './ReporteUsuarios';
import ReportePersonasJuridicas from './ReportePersonasJuridicas'
import ReportePersonasNaturales from './ReportePersonasNaturales';
import ReporteRepresentantesLegales from './ReporteRepresentantesLegales';
import ReporteApoderados from './ReporteApoderados';

export const ReporteUsuariosDetallado = (props) => {
    return (
        <ReporteUsuarios {...props} detallado={true}/>
    );
};

export const ReportePersonasJuridicasDetallado = (props) => {
    return (
        <ReportePersonasJuridicas {...props} detallado={true}/>
    );
};

export const ReportePersonasNaturalesDetallado = (props) => {
    return (
        <ReportePersonasNaturales {...props} detallado={true}/>
    );
};

export const ReporteRepresentantesLegalesDetallado = (props) => {
    return (
        <ReporteRepresentantesLegales {...props} detallado={true}/>
    );
};

export const ReporteApoderadosDetallado = (props) => {
    return (
        <ReporteApoderados {...props} detallado={true}/>
    );
};

