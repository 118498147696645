const Constantes = {

    /* MEDIDAS ANT-DESIGN
    xs - screen < 576px
    sm - screen ≥ 576px
    md - screen ≥ 768px
    lg - screen ≥ 992px
    xl - screen ≥ 1200px
    xxl - screen ≥ 1600px
    */

    FORMATO_FECHA_MES: 'DD-MMM-YYYY',
    FORMATO_FECHA_ESTANDAR: 'DD/MM/YYYY',
    FORMATO_FECHA_TIEMPO_ESTANDAR: 'DD/MM/YYYY HH:mm',
    FORMATO_FECHA_TIEMPO_ESTANDAR_A: 'DD/MM/YYYY hh:mm A',

    MENSAJE_USUARIO_SIN_PERMISOS: ' [Usuario no habilitado]',

    URL_PUBLICAS: {
        INICIO: '/',
        HOGAR: '/hogar',
        QUE_ES_SUMA: '/que-es-suma',
        NORMATIVIDIDAD: '/normatividad',
        PREGUNTAS: '/preguntas',
        RECUPERAR_CONTRASENA: '/recuperar-contrasena',
        CAMBIO_CONTRASENA: '/cambio-contrasena',
        DESBLOQUEAR_USUARIO: '/desbloquear-usuario',
        CREAR_CUENTA: '/crear-cuenta',
        CREAR_CUENTA_PERSONA_JURIDICA: '/crear-cuenta-persona-juridica',
        CREAR_CUENTA_PERSONA_NATURAL: '/crear-cuenta-persona-natural',
        ACTIVAR_CUENTA: '/activar-cuenta',
        ACTIVAR_DADEP_CUENTA: '/activar-dadep-cuenta',
        ACTIVAR_USUARIO_MIGRACION: '/activar-usuario-migracion',
        ACTIVAR_USUARIO_MIGRACION_DADEP: '/activar-usuario-migracion-dadep',
        ACTIVAR_USUARIO_SUMAv1:'/activar-usuario-sumav1',
        CERRAR_SESION: '/cerrar-sesion',
        ENVIAR_CORREO_ACTIVACION_CUENTA:'/enviar-correo-activacion-cuenta',
    },

    URL_PRIVADAS: {
        OPCIONES: '/opciones',
        CAMBIO_CONTRASENA_TEMPORAL: '/cambio-contrasena-temporal'
    },

    CODIGOS_HTTP: {
        SIN_CONEXION: -1,     // Sin conexión con el servidor
        USUARIO_CERRO_SESION: 0,      // Usuario cerró la sesión 
        NO_AUTORIZADO: 401,    // Método no autorizado a consumir
        PETICION_MALA: 400,    // Indica que la petición hecha es incorrecta
        ENTIDAD_DEMASIADO_PESADA_413: 413,    // Request Entity Too Large (Archivo demasiado grande para cargar al servidor)
    },

    // ---------------------------------------------------------------------------------
    // --- API SUMA --------------------------------------------------------------------
    // ---------------------------------------------------------------------------------
    URL_API_SUMA: '/suma/api',
    URL_API_EXTERNO_SUMA: '/suma/servicio',
    // _________________________________________________________________________________

    CONSTANTES_CARGADAS:false,
    PARAMETROS_GENERALES_ENLACES:undefined,
    PARAMETROS_GENERALES_NOTIFICACION:undefined,
    CONTENIDOS_VISUALES:undefined,
    NORMATIVIDADES:undefined,
    PREGUNTAS_FRECUENTES:undefined,
    LIMITES:{},

    COLORES: [
        "rgba(1, 177, 244, 0.75)",
        "rgba(63, 237, 4, 0.75)",
        "rgba(222, 36, 37, 0.75)",
        "rgba(234, 186, 0, 0.75)",
        "rgba(11, 178, 126, 0.75)",
        "rgba(137, 28, 135, 0.75)",
        "rgba(236, 4, 113, 0.75)",
        "rgba(26, 95, 222, 0.75)",
        "rgba(42, 147, 33, 0.75)",
        "rgba(226, 117, 43, 0.75)",
        "rgba(20, 214, 212, 0.75)",
        "rgba(198, 145, 75, 0.75)"
    ],

    PANTALLA:{
        ANCHO: {
            PEQUEÑO: { max: 640 },
            MEDIANO: { min: 641, max: 1007},
            GRANDE:  { min: 1008 },
        }
    },

    // ---------------------------------------------------------------------------------
    // --- ENUMS CONTANTES (TABLAS BASE DE DATOS) --------------------------------------
    // ---------------------------------------------------------------------------------
    ENUMS: {

        TIPO_USUARIO: {
            ADMINISTRADOR_SUMA: { id: 1, tipo_usuario: 'Administrador del SUMA' },
            PERSONA_NATURAL: { id: 2, tipo_usuario: 'Usuario de tipo persona natural' },
            PERSONA_JURIDICA: { id: 3, tipo_usuario: 'Usuario de tipo persona jurídica' },
            ENTIDAD: { id: 4, tipo_usuario: 'Usuario de tipo entidad' },
            APLICACION_CONECTADA: { id: 5, tipo_usuario: 'Aplicación conectada' },
        },

        TIPO_DOCUMENTO: {
            CEDULA_CIUDADANIA: { id: 1, tipo_documento: 'Cédula de ciudadanía' }
        },

        PAIS: {
            COLOMBIA: { id: 1, pais: 'Colombia' }
        },

        ESTADO_REPRESENTANTE_LEGAL: {
            ACTIVO: { id: 1, estado_representantel_legal: 'Activo' },
            INACTIVO: { id: 2, estado_representantel_legal: 'Inactivo' },
        },

        ESTADO_USUARIO: {
            ACTIVO: { id: 1, estado_usuario: 'Activo' },
            INACTIVO: { id: 2, estado_usuario: 'Inactivo' },
            CREADO: { id: 3, estado_usuario: 'Creado' },
            ACTIVO_SUMAV1:{ id:4, estado_usuario: 'Usuario Activo SUMAv1'},
            INACTIVO_SUMAV1:{ id:5, estado_usuario: 'Usuario Inactivo SUMAv1'},
            SUSPENDIDO_SUMAV1:{ id:6, estado_usuario: 'Usuario Suspendido SUMAv1'},
            BLOQUEADO_SUMAV1:{ id:7, estado_usuario: 'Usuario Bloqueado SUMAv1'},
            BLOQUEADO_INTENTOS_LOGIN:{ id:8, estado_usuario: 'Bloqueado intentos login'},
        },

        ESTADO_APODERADO: {
            ACTIVO: { id: 1, estado_apoderado: 'Activo' },
            INACTIVO: { id: 2, estado_apoderado: 'Inactivo' },
        },

        TIPO_ARCHIVO: {
            USUARIO_FOTO_PERFIL: { id: 9, tipo_archivo: 'Foto de perfíl' },
            IMAGEN_CONTENIDO_VISUAL: { id: 11, tipo_archivo: 'Imagen contenido visual' }
        },

        ESTADO_ENTIDAD: {
            ACTIVO: { id: 1, estado_entidad: 'Activo' },
            INACTIVO: { id: 2, estado_entidad: 'Inactivo' },
        },

        ESTADO_DEPENDENCIA: {
            ACTIVO: { id: 1, estado_entidad: 'Activo' },
            INACTIVO: { id: 2, estado_entidad: 'Inactivo' },
        },

        TIPO_SECTOR:{
            ENTIDAD_SECTOR_CENTRAL: { id: 1 },
            ENTIDAD_ADSCRITA_DEL_SECTOR: { id: 2 },
            DESCENTRALIZADO: { id: 3 },
            ENTIDAD_VINCULADA_DEL_SECTOR: { id: 4 },
            ALCALDIA_LOCAL: { id: 5 },
            EMPRESA_SOCIAL_DEL_ESTADO: { id: 6 }
        },

        TIPO_OPCION:{
            SOLICITUD: { id: 1 },
            REGISTRAR_SOLICITUD: { id: 2 },
            OPCION_SUMA: { id: 3 },
        },

        TIPO_CONTENIDO_VISUAL:{
            INICIO: { id: 1 },
        },

        CONTENIDO_VISUAL:{
            SLIDER01: { id: 1 },
            SLIDER02: { id: 2 },
            SLIDER03: { id: 3 },
            SLIDER04: { id: 4 },
            QUE_ES_SUMA: { id: 5 },
        },

        PARAMETRO_GENERAL:{
            ENLACE_PROTECCION_DATOS_PERSONALES: { id: 33 },
            ENLACE_POLITICAS_SEGURIDAD_INFORMACION: { id: 34 },
            ENLACE_YOUTUBE_DADEP: { id: 35 },
            ENLACE_TWITTER_DADEP: { id: 36 },
            ENLACE_FACEBOOK_DADEP: { id: 37 },
            ENLACE_RECURSOS_ESTATICOS: { id: 38 },
            ENLACE_TERMINOS_Y_CONDICIONES: { id: 44 },
        },

        ENTIDAD:{
            DEPARTAMENTO_ADMINISTRATIVO_DE_LA_DEFENSORIA_DEL_ESPACIO_PUBLICO: 4
        }

    },
    // _________________________________________________________________________________


    // ---------------------------------------------------------------------------------
    // --- CODIGOS DE RESPUESTA DEL API ------------------------------------------------
    // ---------------------------------------------------------------------------------
    ENUMS_API: {

        PERSONA_JURIDICA: {
            DATOS_INCORRECTOS: { id: 0 },
            DATOS_ENTIDAD_GUARDADOS: { id: 1 },
            DATOS_ENTIDAD_ACTUALIZADOS: { id: 2 },
            NIT_YA_REGISTRADO: { id: 3 },
            DATOS_RETORNADOS: { id: 4 },
            DATOS_NO_REGISTRADOS: { id: 5 },
            OPERACION_REALIZADA: { id: 6 },
            SUB_USUARIO_CON_CUENTA_ACTIVA: { id: 7 },
            OPERACION_NO_REALIZADA: { id: 8},
        },

        PERSONA_NATURAL: {
            DATOS_INCORRECTOS: { id: 0 },
            DATOS_ENTIDAD_GUARDADOS: { id: 1 },
            DATOS_ENTIDAD_ACTUALIZADOS: { id: 2 },
            DATOS_RETORNADOS: { id: 4 },
            DATOS_NO_REGISTRADOS: { id: 5 },
            OPERACION_REALIZADA: { id: 6 },
            OPERACION_NO_REALIZADA: { id: 8},
        },

        USUARIO: {
            DATOS_INCORRECTOS: { id: 0 },
            USUARIO_CREADO: { id: 1 },
            USUARIO_NO_CREADO: { id: 2 },
            CORREO_NO_DISPONIBLE: { id: 3 },
            USUARIO_NO_REGISTRADO: { id: 4 },
            USUARIO_NUEVO: { id: 5 },
            USUARIO_ANTIGUO: { id: 6 },
            CONTRASENA_ASIGNADA: { id: 7 },
            OPERACION_REALIZADA: { id: 8 },
            OPERACION_NO_REALIZADA: { id: 9 },
            CONTRASENA_NO_DISPONIBLE: { id: 10 },
            CONTRASENA_INCORRECTA: { id: 11 },
            SIN_PERMISOS: { id: 12 },
            SIN_SESION: { id: 13 },
            TOKEN_EXPIRADO: { id: 14 },
            SIN_OPCION_CAMBIAR_CONTRASENA: { id: 15 },
            ERROR_ENCRIPTACION: { id: 16 },
            TOKEN_NO_ENCONTRADO: { id: 17 },
            USUARIO_MIGRACION: { id: 18 },
            CORREO_ELECTRONICO_DIFERENTE: { id: 19 },
            DOCUMENTO_IDENTIDAD_NO_DISPONIBLE: { id: 20 },
            ERROR_SERVICIO_CORREO_ELECTRONICO: { id: 21 },
            USUARIO_CON_ESTADO_NO_PERMITIDO: { id: 22 },
            ROL_VALIDO_PARA_ELIMINAR: { id: 23 },
            ROL_NO_VALIDO_PARA_ELIMINAR: { id: 24 },
            NO_SE_PUEDE_RECUPERAR_CONTRASENA: { id: 25 },
            DOMINIO_DEL_DADEP: { id: 26 },
        },

        ARCHIVOS: {
            DATOS_INCORRECTOS: { id: 0 },
            ARCHIVO_GUARDADO: { id: 1 },
            ARCHIVO_DESCARGADO: { id: 2 },
            TOKEN_EXPIRADO: { id: 3 },
            SESION_FINALIZADA: { id: 4 },
            DATOS_RETORNADOS: { id: 5 },
            OPERACION_REALIZADA: { id: 6 },
        },

        SOLICITUDES: {
            DATOS_INCORRECTOS: { id: 0 },
            OPERACION_REALIZADA: { id: 1 },
            DATOS_RETORNADOS: { id: 2 },
            OPERACION_NO_REALIZADA: { id: 3 },
            OPCION_DEPENDENCIA_SOLICITUD_NO_RELACIONADA: { id: 4 },
        },

        ENTIDAD: {
            DATOS_INCORRECTOS: { id: 0 },
            DATOS_RETORNADOS: { id: 1 },
            USUARIO_SIN_SESION: { id: 2 },
            USUARIO_SIN_CODIGO_ACCESO: { id: 3 },
            ERROR_CONEXION_APLICACION_CONECTADA: { id: 4 },
            OPERACION_REALIZADA: { id: 5 },
            SUB_USUARIO_CON_CUENTA_ACTIVA: { id: 6 },
            RAZON_SOCIAL_YA_REGISTRADA: { id: 7},
            NIT_YA_REGISTRADO: { id: 8},
            DEPENDENCIA_YA_REGISTRADA: { id: 9},
            CONTRASENA_NO_DISPONIBLE: { id: 10},
            ERROR_ENCRIPTACION: { id: 11},
        },

        ADMINISTRACION: {
            DATOS_INCORRECTOS: { id: 0 },
            DATOS_RETORNADOS: { id: 1 },
            OPERACION_REALIZADA: { id: 2 },
            OPERACION_NO_REALIZADA: { id: 3 },
            USUARIO_CON_CUENTA_ACTIVA: { id: 4 },
            DATO_VALIDO_PARA_ELIMINAR: { id: 5 },
            DATO_NO_VALIDO_PARA_ELIMINAR: { id: 6 },
        },

    },
    // _________________________________________________________________________________



    // ---------------------------------------------------------------------------------
    // --- ENUMS MODULOS ---------------------------------------------------------------
    // ---------------------------------------------------------------------------------
    ENUMS_MODULOS: {
        PERSONA_NATURAL: {
            id: 1,
            ACCIONES: {
                ACCEDER_MODULO_PERSONA_NATURAL: { id: 1 },
            }
        },
        PERSONA_JURIDICA: {
            id: 2,
            ACCIONES: {
                ACCEDER_MODULO_PERSONA_JURIDICA: { id: 12 },
            }
        },
        MIS_SOLICITUDES: {
            id: 3,
            ACCIONES: {
                ACCEDER_MODULO_MIS_SOLICITUDES: { id: 31 },
            }
        },
        REGISTRAR_SOLICITUD: {
            id: 4,
            ACCIONES: {
                ACCEDER_MODULO_REGISTRAR_SOLICITUD: { id: 30 },
            }
        },
        PERSONA_NATURAL_INFORMACION_BASICA: {
            id: 5,
            ACCIONES: {
                ACTUALIZAR_INFORMACION_PERSONA_NATURAL: { id: 2 },
            }
        },
        PERSONA_NATURAL_APODERADOS: {
            id: 6,
            ACCIONES: {
                AGREGAR_APODERADO: { id: 3 },
                VER_DETALLE_APODERADO: { id: 4 },
                ACTIVAR_APODERADO: { id: 5 },
                DESACTIVAR_APODERADO: { id: 6 },
                EDITAR_INFORMACION_APODERADO: { id: 7 },
                ACTUALIZAR_DOCUMENTOS_APODERADO: { id: 8 },
                AGREGAR_DOCUMENTOS_APODERADO: { id: 9 },
                ELIMINAR_APODERADO: { id: 85 },
            }
        },
        PERSONA_NATURAL_DOCUMENTOS: {
            id: 7,
            ACCIONES: {
                AGREGAR_DOCUMENTOS_PERSONA_NATURAL: { id: 10 },
                ACTUALIZAR_DOCUMENTOS_PERSONA_NATURAL: { id: 11 },
            }
        },
        PERSONA_JURIDICA_INFORMACION_BASICA: {
            id: 8,
            ACCIONES: {
                ACTUALIZAR_INFORMACION_PERSONA_JURIDICA: { id: 13 }
            }
        },
        PERSONA_JURIDICA_REPRESENTANTES_LEGALES: {
            id: 9,
            ACCIONES: {
                AGREGAR_REPRESENTANTE_LEGAL: { id: 14 },
                VER_DETALLE_REPRESENTANTE_LEGAL: { id: 15 },
                ACTIVAR_REPRESENTANTE_LEGAL: { id: 16 },
                DESACTIVAR_REPRESENTANTE_LEGAL: { id: 17 },
                EDITAR_INFORMACION_REPRESENTANTE_LEGAL: { id: 18 },
                ACTUALIZAR_DOCUMENTOS_REPRESENTANTE_LEGAL: { id: 19 },
                AGREGAR_DOCUMENTOS_REPRESENTANTE_LEGAL: { id: 20 },
                ELIMINAR_REPRESENTANTE_LEGAL: { id: 83 },
            }
        },
        PERSONA_JURIDICA_USUARIOS: {
            id: 10,
            ACCIONES: {
                AGREGAR_SUB_USUARIO: { id: 21 },
                VER_DETALLE_SUB_USUARIO: { id: 22 },
                ACTIVAR_SUB_USUARIO: { id: 23 },
                DESACTIVAR_SUB_USUARIO: { id: 24 },
                ENVIAR_CORREO_ACTIVACION_SUB_USUARIO: { id: 25 },
                ENVIAR_CORREO_CAMBIO_CONTRASENA_SUB_USUARIO: { id: 26 },
                EDITAR_INFORMACION_SUB_USUARIO: { id: 27 },
            }
        },
        PERSONA_JURIDICA_DOCUMENTOS: {
            id: 11,
            ACCIONES: {
                AGREGAR_DOCUMENTOS_PERSONA_JURIDICA: { id: 28 },
                ACTUALIZAR_DOCUMENTOS_PERSONA_JURIDICA: { id: 29 },
            }
        },
        SOLICITUDES: {
            id: 12,
            ACCIONES: {
                ACCEDER_MODULO_SOLICITUDES: { id: 32 }
            }
        },
        SOLICITUD_ASOCIADA: {
            id: 13,
            ACCIONES: {
                ACCEDER_MODULO_SOLICITUD_ASOCIADA: { id: 33 }
            }
        },
        DEPENDENCIA: {
            id: 14,
            ACCIONES: {
                ACCEDER_MODULO_DEPENDENCIA: { id: 34 },
                ACTUALIZAR_INFORMACION_DEPENDENCIA: { id: 35 },
                AGREGAR_SUB_USUARIO_DEPENDENCIA: { id: 36 },
                LISTAR_SUB_USUARIOS_DEPENDENCIA: { id: 37 },
                VER_DETALLE_SUB_USUARIO_DEPENDENCIA: { id: 38 },
                ACTIVAR_SUB_USUARIO_DEPENDENCIA: { id: 39 },
                DESACTIVAR_SUB_USUARIO_DEPENDENCIA: { id: 40 },
                ENVIAR_CORREO_ACTIVACION_SUB_USUARIO_DEPENDENCIA: { id: 41 },
                EDITAR_INFORMACION_SUB_USUARIO_DEPENDENCIA: { id: 42 },
                ENVIAR_CORREO_CAMBIO_CONTRASENA_SUB_USUARIO_DEPENDENCIA: { id: 43 },
                VER_DEPENDENCIA: { id: 58 },
                AGREGAR_DEPENDENCIA: { id: 59 },
                ADMINISTRAR_APLICACION_CONECTADA: { id: 60 },
                ADMINISTRAR_OPCIONES_DEPENDENCIA: { id: 95 },
                LISTAR_ROLES: { id: 96 },
                VER_ROL: { id: 97 },
                EDITAR_ROL: { id: 98 },
                ELIMINAR_ROL: { id: 99 },
                AGREGAR_ROL: { id: 100 },
            }
        },
        USUARIOS:{
            id: 15,
            ACCIONES: {
                ACCEDER_MODULO_USUARIOS: { id: 44 },
                LISTAR_USUARIOS: { id: 45 },
                AGREGAR_USUARIO: { id: 46 },
                EDITAR_USUARIO: { id: 47 },
                VER_USUARIO: { id: 48 },
                DESACTIVAR_USUARIO: { id: 49 },
                ACTIVAR_USUARIO: { id: 50 },
                ENVIAR_CORREO_ACTIVACION_USUARIO: { id: 51 },
                ENVIAR_CORREO_CAMBIO_CONTRASENA_USUARIO: { id: 52 },
                AGREGAR_ROL: { id: 61 },
                LISTAR_ROLES: { id: 62 },
                VER_ROL: { id: 63 },
                EDITAR_ROL: { id: 65 },
                ELIMINAR_ROL: { id: 75 },
                CONTRASENA_TEMPORAL_USUARIO_SUMAV1: { id: 86 },
                DESBLOQUEAR_INTENTOS_FALLIDOS_INICIO_SESION: { id: 87 },
            }
        },

        ENTIDAD:{
            id: 16,
            ACCIONES:{
                ACCEDER_MODULO_ENTIDAD: { id: 53 },
                AGREGAR_ENTIDAD: { id: 54 },
                VER_ENTIDAD: { id: 55 },
                EDITAR_ENTIDAD: { id: 56 },
                LISTAR_ENTIDADES: { id: 57 },
            }
        },

        CONFIGURACION:{
            id: 17,
            ACCIONES:{
                ACCEDER_MODULO_CONFIGURACION: { id: 64 },
                EDITAR_PARAMETRO_GENERAL: { id: 66 },
                ACCEDER_REGISTROS: { id: 104 },
            }
        },

        COMUNICACIONES:{
            id: 18,
            ACCIONES: {
                ACCEDER_MODULO_COMUNICACIONES:{ id: 67 },
                EDITAR_PREGUNTA_FRECUENTE:{ id: 68 },
                AGREGAR_PREGUNTA_FRECUENTE:{ id: 69 },
                ELIMINAR_PREGUNTA_FRECUENTE:{ id: 70 },
                EDITAR_NORMATIVIDAD:{ id: 71 },
                AGREGAR_NORMATIVIDAD:{ id: 72 },
                ELIMINAR_NORMATIVIDAD:{ id: 73 },
                EDITAR_CONTENIDO_VISUAL:{ id: 74 },
            }
        },

        PERSONA_JURIDICA_APODERADOS: {
            id: 19,
            ACCIONES: {
                AGREGAR_APODERADO: { id: 76 },
                VER_DETALLE_APODERADO: { id: 77 },
                ACTIVAR_APODERADO: { id: 78 },
                DESACTIVAR_APODERADO: { id: 79 },
                EDITAR_INFORMACION_APODERADO: { id: 80 },
                ACTUALIZAR_DOCUMENTOS_APODERADO: { id: 81 },
                AGREGAR_DOCUMENTOS_APODERADO: { id: 82 },
                ELIMINAR_APODERADO: { id: 84 },
            }
        },

        REPORTES:{
            id: 20,
            ACCIONES: {
                ACCEDER_MODULO_REPORTES: { id: 88 },
                VER_ESTADISTICAS_USUARIOS: { id: 89 },
                VER_REPORTES_USUARIOS: { id: 90 },
                DESCARGAR_REPORTE_USUARIOS: { id: 91 },
                VER_ESTADISTICAS_SOLICITUDES: { id: 92 },
                VER_REPORTES_SOLICITUDES: { id: 93 },
                DESCARGAR_REPORTE_SOLICITUDES: { id: 94 },
                REPORTE_DETALLADO_USUARIOS: { id: 101 },
                REPORTE_DETALLADO_SOLICITUDES: { id: 102 },
            }
        },

        DOCUMENTOS:{
            id: 21,
            ACCIONES: {
                ACCEDER_MODULO_DOCUMENTOS: { id:103 },
            }
        }

    }
    // _________________________________________________________________________________

}

export default Constantes;
