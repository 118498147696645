import React, { Component } from 'react';
import SumaContexto  from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Button, Upload, notification, Popconfirm } from 'antd';
import { UploadOutlined, ReloadOutlined, PlusOutlined } from '@ant-design/icons';
import Utilidades from '../utilidades/Utilidades'
import ServicioArchivos from '../servicios/archivos/ServicioArchivos'
import Constantes from '../utilidades/Constantes'

class ActualizarArchivo extends Component {

    static contextType = SumaContexto;
    
    state = {
        fileList: [],
        actualizar: false,
        verCancelar: true,
    };

    constructor(props){
        super(props);
        this.tipoArchivo = props.tipoArchivo;
        this.callbackArchivoActualizado = props.callbackArchivoActualizado;
        this.servicioArchivos = new ServicioArchivos( props );
        this.agregar = props.agregar;
        this.deshabilitar = props.deshabilitar;
        //console.log('cnst Archivo - ' + this.tipoArchivo.nombreArchivo + ' - ' + this.agregar);
        //console.log('this.tipoArchivo',this.tipoArchivo);
    }

    componentDidMount() {
        this.setState({ fileList: [], actualizar: false, verCancelar: true, });
    }

    beforeUpload = (file,fileList) => {
        let success = true;
        // Limpia la lista
        this.setState({ fileList: [] });
        // Valida el tipo del archivo 
        let tiposPermitidos = this.tipoArchivo.extensiones.toLowerCase().split(',');
        if( !tiposPermitidos.includes(file.type.toLowerCase()) ){
            let msg = 'El archivo ' + this.tipoArchivo.nombreArchivo + ' debe ser de tipo ' + Utilidades.obtenerTipoDeArchivoPermitido(this.tipoArchivo.extensiones) + '!';
            notification.error({ message: 'Error', description: msg, duration: 6, });
            // Se establece un mensaje personal para identificar el error 
            file.mensajeError = 'Error: Tipo de archivo no permitido!';
            success = false;
        }

        // Valida el tamano del archivo
        let tamanoPermitido = file.size <= this.tipoArchivo.tamano;
        if ( !tamanoPermitido ) {
            let msg = 'El archivo ' + this.tipoArchivo.nombreArchivo + ' debe ser menor a ' + Utilidades.obtenerTamanoEnString(this.tipoArchivo.tamano) + '!';
            notification.error({ message: 'Error', description: msg, duration: 6, });
            // Se establece un mensaje personal para identificar el error 
            file.mensajeError = 'Error: El archivo excede el tamaño permitido!';
            success = false;
        }
        
        if( !success ){
            // Si el archivo no cumple las condiciones para ser cargado, se marca el estado como 'error'
            file.status = 'error';
        }else{
            file.status = 'done';
        }
        return success;
    }

    handleChange = info => {
        let fileList = [...info.fileList];
        //console.log('handleChange status:',info.file.status);
        
        // Remueve el primer elemento para dejar siempre el ultimo
        if( fileList.length > 1)
            fileList.shift();
        
        this.setState({ fileList: fileList });

        if (info.file.status === 'uploading') {
          this.setState({ loading: true, verCancelar:false });
          return true;
        }
        if (info.file.status === 'removed') {
            this.setState({ verCancelar:true });
        }
        if (info.file.status === 'done') {
            // Asumiendo que se maneja solo un archivo por componente UPLOAD
            fileList[0].cargarArchivo = this.cargarArchivo;
            fileList[0].informacion = info;
            this.setState({ verCancelar:false });
        }
        else if (info.file.status === 'error') {
            if( info.file.mensajeError === undefined )
                info.file.mensajeError = 'Error cargando el archivo!'

            info.file = {
                uid: info.file.uid,
                name: info.file.name + ' ' + info.file.mensajeError,
                status: info.file.status
            };
            fileList = [ info.file ];
            this.setState({ fileList: fileList, verCancelar:true });
        }
    };

    customRequest = ({ onSuccess, onError, file, onProgress  }) => {
        // Se crea un objeto (reader) de tipo FileReader para cargar a nivel local el documento
        const reader = new FileReader();
        // Se agrega el evento 'loadend' con el fin de saber cuando termina de cargar el documento
        reader.addEventListener('loadend', (event) => {
            // Indica que hubo un error
            if( reader.error !== null ){
                console.error( 'Error lectura archivo local' , reader.error );
                // Lanza un evento error al componente <Upload>
                onError();
            }else{
                // Se asume que aqui el archivo local fue leido exitosamente
                // Se carga el documento en el API
                this.servicioArchivos.cargarArchivo(
                    file,
                    this.tipoArchivo.id,
                    ( respuestaAPI )    => { this.completeAPISubirArchivo( {onSuccess}, {onError}, {file}, respuestaAPI ) },
                    ( errorAPI )        => { this.errorAPISubirArchivo( {onError} , errorAPI ) },
                    ( porcentaje )      => { this.progressAPISubirArchivo( {onProgress} , porcentaje ) },
                );
                
            }            
        });
        // Se carga en el objeto (reader) el documento a procesar
        reader.readAsDataURL(file);
    };

    completeAPISubirArchivo = ( {onSuccess}, {onError}, {file}, respuestaAPI ) => {
        if( Constantes.ENUMS_API.ARCHIVOS.ARCHIVO_GUARDADO.id === respuestaAPI.codigo){
            onSuccess(null, file);
            //console.log('tipoArchivo',this.tipoArchivo);
            this.setState({ fileList: [], actualizar: false, verCancelar: true, });
            this.callbackArchivoActualizado( respuestaAPI.resultado );
        }else{
            onError();
        }
        this.setState({loading: false});
    }
     
    errorAPISubirArchivo = ( {onError}, errorAPI ) => {
        //console.log('error',errorAPI);
        onError();
        this.setState({loading: false});
    }

    progressAPISubirArchivo = ( {onProgress}, porcentaje ) => {
        onProgress({ percent: porcentaje });
    }


    clickActualizarDocumento = (e) => {
        //console.log('tipoArchivo',this.tipoArchivo);
        this.setState({ actualizar: true });
    }
    clickCancelarActualizarDocumento = (e) => {
        this.setState({ actualizar: false, fileList:[] });
    }
    
    render() {
 
        const { fileList, actualizar, verCancelar } = this.state;
        const { tipoArchivo, agregar } = this.props;  

        const propsUpload = {
            accept: tipoArchivo.extensiones,
            showUploadList:{ showPreviewIcon: true, showDownloadIcon: false, showRemoveIcon: true },
            className:'subir-archivo',
            multiple:false,
            beforeUpload: this.beforeUpload,
            onChange: this.handleChange,
            fileList
        };

        return (
            <div>   
                { actualizar 
                    ? 
                    (
                        <>
                            {(verCancelar) 
                                ? 
                                (<Button className="boton-cancelar-actualizar" type="link" onClick={this.clickCancelarActualizarDocumento}>Cancelar</Button>)
                                :
                                (<></>)
                            }
                            <Upload {...propsUpload} name={tipoArchivo.nombreIdentificador} customRequest={this.customRequest} > 
                                <Button icon={<UploadOutlined />}>Seleccionar archivo</Button>
                                <br/>
                                <label>{'Tipo de archivo:' + Utilidades.obtenerTipoDeArchivoPermitido(tipoArchivo.extensiones) + ' - Tamaño Max:' + Utilidades.obtenerTamanoEnString(tipoArchivo.tamano)}</label>
                            </Upload>
                        </>
                    ) 
                    : 
                    (
                        <Popconfirm title={ 'Desea ' + (agregar ? 'agregar' : 'actualizar') + ' el documento?' }
                                    okText="Si"
                                    cancelText="No" 
                                    onConfirm={(e) => {this.clickActualizarDocumento( e );}}
                                    disabled={this.deshabilitar}>
                            <Button className="boton-agregar-actualizar-doc" 
                                    icon={ agregar ? <PlusOutlined /> : <ReloadOutlined />}
                                    disabled={this.deshabilitar}>
                                        {agregar ? 'Agregar' : 'Actualizar'}
                            </Button>
                        </Popconfirm>
                    )
                }
            </div>
        );

    }
}
          
export default withRouter(withCookies(ActualizarArchivo));