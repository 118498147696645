import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import ServicioEntidad from '../servicios/ServicioEntidad'
import ServicioDatosConstantes from '../servicios/ServicioDatosConstantes'
import BloqueoPantalla from '../general/BloqueoPantalla'
import Encabezado from './Encabezado';
import PiePagina from './PiePagina';
import Contenedor from './Contenedor';
import PropTypes from "prop-types";
import Constantes from '../utilidades/Constantes'
import Utilidades from '../utilidades/Utilidades'
import axios from 'axios';
import { Modal, Progress } from 'antd';
import queryString from 'query-string';
import SumaContexto from '../contexto/SumaContexto';
import logo_suma from '../recursos/imagenes/logo_suma+.svg'

class ContenedorApp extends Component {

  static contextType = SumaContexto;

  ENUM_COMPONENTE = {
    CARGANDO: 1,
    VER: 2,
    NO_DISPONIBLE: 3,
    DESCARGANDO_RECURSOS: 4
  }

  state = {
    usuario: null,
    componente: this.ENUM_COMPONENTE.NO_DISPONIBLE,
    sesionFinalizada: false,
    errorConexion: false,
    cerrarSesionUsuario: false,
    totalDescarga: 0,
  };

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.servicioDatosConstantes = new ServicioDatosConstantes(props);
    this.servicioUsuarios = new ServicioUsuarios(props);
    this.servicioEntidad = new ServicioEntidad(props);
    this.cookies = cookies;
    this.historyLocal = props.history;
    this.props.history.listen(this.manejadorCambioPath);

    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    // Este método es transversal para todas las peticiones hechas con el componente
    // axios. Principalmente se filtra si la respuesta retornada por el servidor fue 
    // un código 401 o si no hay conexión con el servidor con el fin de sacar de sesión 
    // al usuario desde el front-end
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const contexto = this;
    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      // Indica que el usuario fue quien cerró la sesión, por ende se cambia la respuesta 
      // de error por la respuesta personalizada
      if (contexto.state.cerrarSesionUsuario) {
        console.log("ADVERTENCIA --- EL USUARIO CERRÓ LA SESIÓN");
        error.response = { status: Constantes.CODIGOS_HTTP.USUARIO_CERRO_SESION };
        contexto.setSumaState({ cerrarSesionUsuario: false });
      }
      // Indica que no hay conexión con el servidor
      else if (error.response === undefined) {
        console.log("ERROR --- NO HAY CONEXIÓN CON EL SERVIDOR");
        error.response = { status: Constantes.CODIGOS_HTTP.SIN_CONEXION };
        if (!contexto.state.errorConexion) {
          contexto.setSumaState({ errorConexion: true });
          contexto.cerrarSesionFrontEnd();
          Modal.error({
            content: "Error de conexión con el servidor. Valida tu conexión a internet o comunícate con el administrador.",
            onOk: () => {
              contexto.setSumaState({ errorConexion: false });
            }
          });
        }
      }
      // Indica que el usuario no está autorizado para consumir el servicio
      else if (Constantes.CODIGOS_HTTP.NO_AUTORIZADO === error.response.status) {
        console.log('ERROR ' + Constantes.CODIGOS_HTTP.NO_AUTORIZADO + '  --- NO AUTORIZADO');
        console.log('sesionFinalizada', contexto.state.sesionFinalizada)
        if (!contexto.state.sesionFinalizada) {
          contexto.setSumaState({ sesionFinalizada: true });
          contexto.cerrarSesionFrontEnd();
          console.log('Sesión finalizada');
          contexto.setSumaState({ sesionFinalizada: false });
          /*Modal.warning({
            content: "Sesión finalizada",
            onOk: () => {
              
            }
          });*/
        }
      }
      else if (Constantes.CODIGOS_HTTP.PETICION_MALA === error.response.status) {
        console.log('ERROR ' + Constantes.CODIGOS_HTTP.PETICION_MALA + '  --- PETICION MALA');
        console.log('ERROR ', error.response);
        Modal.error({ content: error.response.data.mensaje + '. Código: ' + error.response.data.codigo, });
      }
      else if (Constantes.CODIGOS_HTTP.ENTIDAD_DEMASIADO_PESADA_413 === error.response.status) {
        Modal.error({ content: `Recurso demasiado pesado para cargar en el servidor, por favor comunícate con el administrador del sistema a través de la mesa de ayuda del DADEP ( mesadeayuda@dadep.gov.co ). Código:${error.response.status} - Msg:${error.response.statusText}`, });
      }
      return Promise.reject(error);
    });
    // ________________________________________________________________________________
    // ________________________________________________________________________________




    if (this.props.location.pathname === Constantes.URL_PUBLICAS.CERRAR_SESION) {

    }

  }

  manejadorCambioPath = (location, action) => {
    if (this._isMounted) {
      const path = this.props.location.pathname;
      let params = this.props.location.search;
      let urlparametros = queryString.parse(params);
      // Si la url lleva como parámetro 'cargar=1' se recarga el contenedor
      if (urlparametros.cargar !== undefined) {
        console.log("Volver a Cargar la página " + urlparametros.cargar);
        // Se remueve el parámetro 'cargar=1' de la url
        this.props.history.replace(path.replace("cargar=1", ""));
        this.cargarDatosIniciales();
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
    const path = this.props.location.pathname;
    let params = this.props.location.search;
    let urlparametros = queryString.parse(params);

    if (this.props.location.pathname === Constantes.URL_PUBLICAS.CERRAR_SESION) {
      // --------------------------------------------------------------------------------
      // Este procedimiento identifica si se solicitó cerrar sesión por medio de URL al
      // realizar la petición '/cerrar-sesion' con el fin de establecer
      // --------------------------------------------------------------------------------
      this.setSumaState({ cerrarSesionUsuario: true, componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
      this.cerrarSesion(this);
      this.historyLocal.push('/');
      // ________________________________________________________________________________

    } else {

      // --------------------------------------------------------------------------------
      // En este punto se extrae los parámetros globales que vengan dentro de la URL
      // --------------------------------------------------------------------------------
      if (urlparametros.msj_info) {
        this.props.history.replace(path.replace("msj_info=" + urlparametros.msj_info, ""));
        Modal.info({ content: Utilidades.convertirCaracteresHexUrl(urlparametros.msj_info) });
      }
      if (urlparametros.msj_error) {
        this.props.history.replace(path.replace("msj_error=" + urlparametros.msj_error, ""));
        Modal.error({ content: Utilidades.convertirCaracteresHexUrl(urlparametros.msj_error) });
      }
      if (urlparametros.msj_advertencia) {
        this.props.history.replace(path.replace("msj_advertencia=" + urlparametros.msj_advertencia, ""));
        Modal.warn({ content: Utilidades.convertirCaracteresHexUrl(urlparametros.msj_advertencia) });
      }
      // ________________________________________________________________________________

      this.cargarDatosIniciales();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setSumaState(valores) {
    if (this._isMounted) {
      this.setState(valores);
    }
  }

  // La cantidad de items que almacena este arreglo, es la cantidad de peticiones web que se van a 
  // realizar, en cada método 'porcentajeDescarga' se debe indicar el indice.
  peticiones = [
    /*0*/  0, // Parametros generales enlace
    /*1*/  0, // Contenidos visuales
    /*2*/  0, // Normatividades
    /*3*/  0, // Preguntas frecuentes
    /*4*/  0, // Obtenere limites
    /* Total imágenes a descargar (totalImg) 5 */
    /*5*/  0, // Imagen Slider 01
    /*6*/  0, // Imagen Slider 02
    /*7*/  0, // Imagen Slider 03
    /*8*/  0, // Imagen Slider 04
    /*9*/  0, // Imagen Que es SUMA
    /*10*/  0, // Parametros generales notificacion
  ]; 
  totalImg = 5;
  porcentajeDescarga(indice, porcentaje) {
    this.peticiones[indice] = (porcentaje / this.peticiones.length);
    let total = 0;
    for (const valor of this.peticiones) {
      total += valor;
    }
    total = parseInt(total);
    this.setState({ totalDescarga: total });
    if( total === 100 ){
      setTimeout(() => {
        Constantes.CONSTANTES_CARGADAS = true;
        this.cargarDatosIniciales();
        //this.setSumaState({ componente: this.ENUM_COMPONENTE.VER });
      }, 100);
      
    }
  }

  // -----------------------------------------------------------------------
  // --- CARGA DATOS INICIALES ---------------------------------------------
  // -----------------------------------------------------------------------
  async cargarDatosIniciales() {

    // Aqui se proceden a cargar todas las variables que son constantes
    // desde el lado del front-end.
    if (!Constantes.CONSTANTES_CARGADAS) {
      this.setSumaState({ componente: this.ENUM_COMPONENTE.DESCARGANDO_RECURSOS });

      Constantes.PARAMETROS_GENERALES_ENLACES = await this.servicioDatosConstantes.obtenerParametroGeneralConjuntoEnlace((porcentaje) => { this.porcentajeDescarga(0, porcentaje) });
      Constantes.CONTENIDOS_VISUALES = await this.servicioDatosConstantes.obtenerContenidoVisualPorTipo(Constantes.ENUMS.TIPO_CONTENIDO_VISUAL.INICIO.id, (porcentaje) => { this.porcentajeDescarga(1, porcentaje) });
      Constantes.NORMATIVIDADES = await this.servicioDatosConstantes.obtenerNormatividades((porcentaje) => { this.porcentajeDescarga(2, porcentaje) });
      Constantes.PREGUNTAS_FRECUENTES = await this.servicioDatosConstantes.obtenerPreguntasFrecuentes((porcentaje) => { this.porcentajeDescarga(3, porcentaje) });
      let limites = await this.servicioDatosConstantes.obtenerLimites((porcentaje) => { this.porcentajeDescarga(4, porcentaje) });
      Constantes.LIMITES = limites;
      if( Constantes.CONTENIDOS_VISUALES ){
        for (let i = 0; i < Constantes.CONTENIDOS_VISUALES.length; i++) {
          // Aqui se asume que son 5 recursos de imagen que se van a descargar
          let indice = i + this.totalImg;
          //if (Constantes.CONTENIDOS_VISUALES[i].archivo && Constantes.CONTENIDOS_VISUALES[i].archivo.urlPublica) {
            //let base64 = await Utilidades.obtenerBase64DesdeUrlConAxios(Constantes.CONTENIDOS_VISUALES[i].archivo.urlPublica, (porcentaje) => { this.porcentajeDescarga(indice, porcentaje) });
            //Constantes.CONTENIDOS_VISUALES[i].archivo.base64 = base64;
          //}else{
            this.porcentajeDescarga(indice,100);  
          //}
        }
      }
      // Si no existen contenidos visuales o si son menor a 5, se procede
      // a completar el restante de las peticiones
      let totalCV = (typeof Constantes.CONTENIDOS_VISUALES !== 'undefined') ? Constantes.CONTENIDOS_VISUALES.length : 0;
      if( totalCV < this.totalImg ){
        for( let i = totalCV; i < this.totalImg ; i++){
          this.porcentajeDescarga(this.totalImg+i,100);  
        }
      }
      Constantes.PARAMETROS_GENERALES_NOTIFICACION = await this.servicioDatosConstantes.obtenerParametroGeneralConjuntoNotificacion((porcentaje) => { this.porcentajeDescarga(10, porcentaje) });
    }



    const path = this.props.location.pathname;

    let token = this.cookies.get('sumajwt');
    let irAlHome = false;
    if (token !== null && token !== undefined) {

      this.setSumaState({ componente: this.ENUM_COMPONENTE.CARGANDO });
      let usr = await this.obtenerUsuarioLogeado();
      if (usr === null) {
        irAlHome = true;
      } else {
        if (usr.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id) {
          let opcionesSUMADependencia = await this.servicioEntidad.obtenerOpcionSUMADependenciaPromise("[ContenedorApp]");
          usr.opcionesSUMADependencia = opcionesSUMADependencia.resultado;
          if (usr.usuarioDependencia !== undefined) {
            let dependenciaAPI = await this.servicioEntidad.obtenerDependenciaPromise(usr.usuarioDependencia.dependencia.id, "[ContenedorApp]");
            if (dependenciaAPI.codigo === Constantes.ENUMS_API.ENTIDAD.DATOS_RETORNADOS.id)
              usr.usuarioDependencia.dependencia = dependenciaAPI.resultado;
          }
        }

        // Se identifica si tiene asociada una foto de perfil y si dentro del contexto
        // SumaContexto no se encuentra cargada la imagen de perfil con el fin de solo 
        // cargarla una vez se encuentra logueado dentro del contexto SumaContexto
        if (usr.fotoPerfil !== undefined) {
          if (this.context.imagenPerfil_sc === null) {
            let imagenPerfil_sc = await Utilidades.obtenerBase64DesdeUrl(usr.fotoPerfil.url);
            this.context.setImagenPerfil_sc(imagenPerfil_sc);
          }
        } else {
          this.context.setImagenPerfil_sc(null);
        }

      }
      //console.log("usr", usr);
      this.setSumaState({ usuario: usr });
    } else {
      irAlHome = true;
      this.setSumaState({ usuario: null });
    }

    

    const publicUrl = [];
    for (const property in Constantes.URL_PUBLICAS) {
      publicUrl.push(Constantes.URL_PUBLICAS[property]);
    }

    // Si no está logueado el usuario se redirige al HOME 
    // a no ser que se encuentre en una página de tipo pública
    if (irAlHome) {
      // Se elimina la cookie
      this.cookies.remove("sumajwt");
      if (!publicUrl.includes(path)) {
        this.historyLocal.push('/');
      }
    } else {
      // Si el usuario está logueado y se encuentra en una página
      // de tipo pública, se redirige a OPCIONES
      if (publicUrl.includes(path)){
        this.historyLocal.push('/opciones');
      }
    }

    if( Constantes.CONSTANTES_CARGADAS ){
      this.setSumaState({ componente: this.ENUM_COMPONENTE.VER });
    }
  }

  obtenerUsuarioLogeado() {
    return new Promise(resolve => {
      this.servicioUsuarios.obtenerUsuarioLogeado(
        (dataAPI) => { resolve(dataAPI); },
        (errorAPI) => { resolve(null); },
      );
    });
  }

  usuarioLogueado(contexto) {
    contexto.cargarDatosIniciales();
  }

  cerrarSesion(contexto) {
    this.setSumaState({ cerrarSesionUsuario: true });
    this.servicioUsuarios.cerrarSesion(
      (dataAPI) => {
        contexto.cerrarSesionFrontEnd();
      },
      (errorAPI) => { },
    );
  }

  cerrarSesionFrontEnd() {
    this.cookies.remove("sumajwt");
    this.setSumaState({ usuario: null, componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
    this.cargarDatosIniciales();
    this.context.limpiarSumaContexto();
  }

  render() {

    const { usuario, componente, cerrarSesionUsuario, totalDescarga } = this.state;

    return (
      <>

        {(componente === this.ENUM_COMPONENTE.VER) ?
          (
            <div>
              <Encabezado usuario={usuario}
                callbackUsuarioLogueado={() => { this.usuarioLogueado(this); }}
                callbackCerrarSesion={() => { this.cerrarSesion(this); }} />
              <Contenedor usuario={usuario}
                callbackUsuarioLogueado={() => { this.usuarioLogueado(this); }}
                cerrarSesionUsuario={cerrarSesionUsuario} />
              <PiePagina />
            </div>
          )
          :
          (
            (componente === this.ENUM_COMPONENTE.CARGANDO) ?
              (<BloqueoPantalla cargando="true" />)
              :
              (componente === this.ENUM_COMPONENTE.DESCARGANDO_RECURSOS ?
                (
                  <div className="vista-cargando-pagina">
                    <img src={logo_suma} className="logo-suma img-banner" alt="SUMA" />
                    <Progress type="circle" percent={totalDescarga} strokeColor="#C90A00" strokeWidth="4" width={70} />
                  </div>
                ) :
                (<></>)
              )
          )
        }
      </>
    );
  }
}

export default withRouter(withCookies(ContenedorApp));


