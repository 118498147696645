import React, { Component } from 'react';
import SumaContexto  from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Form, Input, Button, Row, Col, Select, Popover, Modal, Spin } from 'antd';
import Validadores from '../utilidades/Validadores'
import ServicioPais from '../servicios/ServicioPais'
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import Constantes from '../utilidades/Constantes'
import ServicioDatosConstantes from '../servicios/ServicioDatosConstantes'
import ServicioPersonaJuridica from '../servicios/usuarios/ServicioPersonaJuridica'
import { withCookies } from 'react-cookie';
import Utilidades from '../utilidades/Utilidades';
import { HookReactRouterV6Parametros } from '../general/hooks/HookReactRouterV6Parametros';


class InformacionBasicaEntidad extends Component {

    static contextType = SumaContexto;
    
    ENUM_COMPONENTE ={
        CARGANDO:1,
        VER:2,
        NO_DISPONIBLE:3
    }

    state = {
        paises:[],
        departamentos:[],
        ciudades:[],
        sectores:[],
        cargandoPais: false,
        cargandoSector: false,
        cargando: true,
        correoElectronico: null,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        usuario: null,
        deshabilitar:false,
    };

    constructor(props){
        super(props);
        //console.log('InformacionBasicaEntidad.props',props);
        // El idPersonaJuridica se puede tomar por el componente padre como atributo, si no llega por aquí, 
        // se toma la perona jurídica del usuario que está logueado.
        this.idPersonaJuridica = props.idPersonaJuridica;
        this.servicioPais = new ServicioPais( props );
        this.servicioUsuarios = new ServicioUsuarios( props );
        this.servicioDatosConstantes = new ServicioDatosConstantes( props );
        this.servicioPersonaJuridica = new ServicioPersonaJuridica ( props );
    }
    
    formRef = React.createRef();


    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales(){
        let usr = await this.servicioPersonaJuridica.usuarioPersonaJuridicaOAdministradorValido();
        this.setSumaState({ componente:(usr !== null ? this.ENUM_COMPONENTE.VER : this.ENUM_COMPONENTE.NO_DISPONIBLE)});
        if( usr !== null ){
            let form = this.formRef.current;
            if( form != null ){
                this.setSumaState({ cargandoPais: true, cargandoSector:true  });
                // Cargando sectores
                await this.obtenerSectores();
                // Cargando paises
                await this.obtenerPaises();
                // Cargando datos entidad
                let datosEntidad = await this.obtenerDatosEntidades();
                let idPais = Constantes.ENUMS.PAIS.COLOMBIA.id;
                let idRegion = null;

                if( datosEntidad !== null ){
                    idPais = datosEntidad.ciudad.idPais;
                    idRegion = datosEntidad.ciudad.idDepartamento;
                }
                await this.obtenerDepartamentos( idPais );
                if( idRegion !== null ){
                    await this.obtenerCiudades( idRegion );
                }

                if( datosEntidad !== null ){
                    form.setFieldsValue({
                        'razonSocial' :         datosEntidad.razonSocial,
                        'nit' :                 datosEntidad.nit,
                        'digitoVerificacion' :  datosEntidad.digitoVerificacion,
                        'camaraComercio' :      datosEntidad.camaraComercio,
                        'sector' :              datosEntidad.sector.id.toString(),
                        'correoElectronico' :   datosEntidad.correoElectronico,
                        'telefono' :            datosEntidad.telefono,
                        'movil' :               datosEntidad.celular,
                        'pais' :                datosEntidad.ciudad.idPais.toString(),
                        'region' :              datosEntidad.ciudad.idDepartamento.toString(),
                        'ciudad' :              datosEntidad.ciudad.id.toString(),
                        'direccion' :           datosEntidad.direccion,
                    });
                }
                //console.log('datosEntidad', datosEntidad);
                this.setSumaState({ 
                    cargando: false,
                    usuario: usr,
                    deshabilitar: !Utilidades.accionPermitida(usr, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_INFORMACION_BASICA.ACCIONES.ACTUALIZAR_INFORMACION_PERSONA_JURIDICA.id) 
                });
            }
        }
    }

    obtenerSectores () {
        return new Promise(resolve => {
            this.servicioDatosConstantes.obtenerSectores(
                ( sectores )    => { 
                    this.setSumaState({ sectores: sectores, });
                    setTimeout(() => {this.setSumaState({ cargandoSector: false });},500); 
                    resolve(true);
                },
                ( errorAPI )    => { 
                    Modal.error({content:'Error de conexión. Por favor intenta mas tarde. [Cod:IBE01]'});
                    resolve(true);
                },
            );
        });
    }

    obtenerDatosEntidades () {
        return new Promise(resolve => {
            this.servicioPersonaJuridica.obtenerDatosEntidad(
                // Si el idPersonaJuridica no es indefinido, se valida que el usuario sea de tipo Administrador
                // Si por el contrario es indefinido, desde el backend se toma el id de la persona jurídica del usuario logueado
                this.idPersonaJuridica,
                ( respuestaApi )    => { 
                    switch(respuestaApi.codigo){
                        case Constantes.ENUMS_API.PERSONA_JURIDICA.DATOS_RETORNADOS.id:
                            resolve(respuestaApi.resultado);
                            break;
                        default:
                            break;    
                    }
                    resolve(null);
                },
                ( errorAPI )        => { 
                    resolve(null); 
                    Modal.error({content:'Error de conexión. Por favor intenta mas tarde. [Cod:IBE02]'});
                },
            );
        });
    }


    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    obtenerPaises (){
        return new Promise(resolve => {
            this.servicioPais.obtenerPaises(
                ( paises )      => { 
                    this.setSumaState({ paises: paises, }); 
                    resolve(true);
                },
                ( errorAPI )    => { 
                    Modal.error({content:'Error de conexión. Por favor intenta mas tarde. [Cod:IBE03]'});
                    resolve(true);
                },
            );
        });
    }

    obtenerDepartamentos = idPais =>{
        return new Promise(resolve => {
            let form = this.formRef.current;
            this.setSumaState({ cargandoPais: true });
            this.servicioPais.obtenerDepartamentosDelPais(
                idPais,
                ( departamentos )   => { 
                                            setTimeout(() => {
                                                if( form != null ){
                                                    this.setSumaState({ cargandoPais: false, departamentos: departamentos, });
                                                    form.setFieldsValue({'region':'', 'ciudad':''});
                                                }
                                                resolve(true);
                                            },500); 
                                        },
                ( errorAPI )        => { 
                    Modal.error({content:'Error de conexión. Por favor intenta mas tarde. [Cod:IBE04]'});
                    resolve(true);
                },
            );
        });
    }

    obtenerCiudades = idDepartamento =>{
        return new Promise(resolve => {
            let form = this.formRef.current;
            this.setSumaState({ cargandoPais: true });
            this.servicioPais.obtenerCiudadesDelDepartamento(
                idDepartamento,
                ( ciudades )   =>   { 
                                        setTimeout(() => {
                                            if( form != null ){
                                                this.setSumaState({ cargandoPais: false, ciudades: ciudades, });
                                                form.setFieldsValue({'ciudad':''});
                                            }
                                            resolve(true);
                                        },500);
                                    },
                ( errorAPI )   =>   { 
                    Modal.error({content:'Error de conexión. Por favor intenta mas tarde. [Cod:IBE05]'});
                    resolve(true); 
                },
            );
        });
    }
  
    handlePaisChange = value => {
        this.obtenerDepartamentos( value );
    };
    handleDepartamentoChange = value => {
        this.obtenerCiudades( value );
    }

    render() {
      //let  {form} = this.props.form;
      const { deshabilitar } = this.state;
      const { Option } = Select;
      
      const { 
          paises, departamentos, 
          ciudades, sectores, 
          cargandoPais, cargando, cargandoSector, componente 
        } = this.state;

      const layout = {
        labelCol: {
          /*span: 10, offset: 2,*/
          sm: {span: 22, offset: 2},
          md: {span: 12, offset: 2},
          lg: {span: 12, offset: 2},
          xl: {span: 10, offset: 2}
        },
        wrapperCol: {
          sm: {span: 22, offset: 2},
          md: {span: 10},
          lg: {span: 10},  
          xl: {span: 12},
        },
      };

      const onFinish = values => {
        this.setSumaState({ cargando: true });
        let datosEntidad = {
            razonSocial:                values.razonSocial,
            nit:                        values.nit,
            digitoVerificacion:         values.digitoVerificacion,
            camaraComercio:             values.camaraComercio,
            direccion:                  values.direccion,
            correoElectronico:          values.correoElectronico.toLowerCase(),
            celular:                    values.movil,
            telefono:                   values.telefono,
            ciudad:{
                    id: parseInt(values.ciudad)
            },
            sector:{
                    id: parseInt(values.sector)
            },
        };

        this.servicioPersonaJuridica.guardarDatosEntidad(
            // Si el idPersonaJuridica no es indefinido, se valida que el usuario sea de tipo Administrador
            // Si por el contrario es indefinido, desde el backend se toma el id de la persona jurídica del usuario logueado
            this.idPersonaJuridica,
            ( respuestaApi )   =>  {  
                switch(respuestaApi.codigo){
                    case Constantes.ENUMS_API.PERSONA_JURIDICA.DATOS_ENTIDAD_GUARDADOS.id:
                    case Constantes.ENUMS_API.PERSONA_JURIDICA.DATOS_ENTIDAD_ACTUALIZADOS.id:
                        Modal.success({content:'Datos de la entidad guardados.'});
                        break;
                    case Constantes.ENUMS_API.PERSONA_JURIDICA.NIT_YA_REGISTRADO.id:
                        Modal.error({content:'El Nit ' + datosEntidad.nit + ' ya se encuentra registrado, por favor ingresa uno distinto'});
                        break;  
                    default:
                        Modal.error({content:'No fue posible guardar los datos de la entidad. Por favor intenta mas tarde.'});
                        break;
                }

                //console.log('respuestaApi',respuestaApi); 
                setTimeout(() => {this.setSumaState({ cargando: false });},500); 
            },
            ( errorAPI )        => { 
                this.setSumaState({ cargando: false }); 
                Modal.error({content:'Error de conexión. Por favor intenta mas tarde. [Cod:IBE06]'});
            },
            datosEntidad
        );
      };
    
      const onFinishFailed = errorInfo => {
        Modal.warning({content:'Por favor diligencia la información solicitada.'});
      };
  
        return (
          <div>  
              {(componente === this.ENUM_COMPONENTE.VER) ?
                (
                    <>
                        <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>
                            <Form
                                {...layout}
                                ref={this.formRef}
                                name="datos-entidad"
                                className="formulario-suma"
                                scrollToFirstError
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                >
                                <Row>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Razón Social" name="razonSocial" 
                                            rules=  {[
                                                        { required: true, message: 'Ingresa la razón social',},
                                                        { min:5, message: 'No puede ser menor a 5 caracteres',},
                                                        { max:500, message: 'No puede ser mayor a 500 caracteres',},
                                                    ]} 
                                        >
                                            <Input autoComplete="off" disabled={deshabilitar}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="NIT" style={{ marginBottom: 0 }}>
                                            <Form.Item name="nit" 
                                                style={{ display: 'inline-block', width: 'calc(80% - 8px)' }}
                                                rules=  {[
                                                            { required: true, message: 'Ingresa el NIT',},
                                                            { min:5, message: 'No puede ser menor a 5 caracteres',},
                                                            { max:50, message: 'No puede ser mayor a 50 caracteres',},
                                                            ({ getFieldValue }) => ({
                                                                validator(rule, value) {
                                                                    if ( value && !Validadores.validarDigitos( value ).success ) {
                                                                        return Promise.reject('Ingresa solo dígitos');
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            }),
                                                        ]} 
                                            >
                                                <Input autoComplete="off" disabled={deshabilitar}/>
                                            </Form.Item>
                                            <Popover content={(<div>Ingresa sólo un dígito</div>)} title="Digito Verificación" trigger="focus">
                                            <Form.Item name="digitoVerificacion" 
                                                style={{ display: 'inline-block', width: 'calc(20% - 0px)', margin: '0 0 0 8px' }}
                                                rules=  {[
                                                            { required: true, message: 'Dígito',},
                                                            { max:5, message: '*',},
                                                            () => ({
                                                                validator(rule, value) {
                                                                    if ( value && !Validadores.validarDigitos( value ).success ) {
                                                                        return Promise.reject('*');
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            }),
                                                        ]} 
                                            >       
                                                <Input autoComplete="off" disabled={deshabilitar}/>
                                            </Form.Item>
                                            </Popover>
                                        </Form.Item>
                                    </Col>    
                                </Row>
                                <Row>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Cámara de comercio" name="camaraComercio" 
                                            rules=  {[
                                                        { required: true, message: 'Ingresa la cámara de comercio',},
                                                        { max:50, message: 'No puede ser mayor a 50 caracteres',},
                                                    ]} 
                                        >
                                            <Input autoComplete="off" disabled={deshabilitar}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item  label="Sector" name="sector" 
                                                    rules=  {[{ required: true, message: 'Selecciona el sector al que perteneces',},]}
                                                    {...(cargandoSector ? {validateStatus:'validating', hasFeedback:true } : {})}>
                                            <Select {...(cargandoSector ? {disabled:true } : {})} disabled={deshabilitar}>
                                                {sectores.map( sector => (
                                                    <Option key={ sector.id }>{ sector.sector }</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Correo electrónico" name="correoElectronico" 
                                            rules=  {[
                                                        { required: true, message: 'Ingresa el correo electrónico de la entidad',},
                                                        { type: 'email',  message: 'Ingresa un correo electrónico válido',},
                                                        { max:255, message: 'No puede ser mayor a 255 caracteres',},
                                                    ]} 
                                        >
                                            <Input autoComplete="off" disabled={deshabilitar}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Teléfono" name="telefono" 
                                                        rules=  {[
                                                            { required: false},
                                                            { min:7, message: 'No puede ser menor a 7 caracteres',},
                                                            { max:30, message: 'No puede ser mayor a 30 caracteres',},
                                                        ]}>
                                            <Input autoComplete="off" disabled={deshabilitar}/>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={24} sm={12}>
                                        <Form.Item  label="Móvil" name="movil" 
                                                    rules=  {[
                                                        { min:7, message: 'No puede ser menor a 7 caracteres',},
                                                        { max:20, message: 'No puede ser mayor a 20 caracteres',},
                                                        { pattern: new RegExp('^[0-9\\+\\s]*$'), message: 'Solo dígitos y puede contener el simbolo +',},
                                                    ]} >
                                            <Input autoComplete="off" disabled={deshabilitar}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item  label="Pais" name="pais" initialValue={`${Constantes.ENUMS.PAIS.COLOMBIA.id}`} 
                                                    rules={[{ required: true, message:'Selecciona tu pais'},]}
                                                    {...(cargandoPais ? {validateStatus:'validating', hasFeedback:true } : {})}>
                                            <Select {...(cargandoPais ? {disabled:true } : {})} onChange={this.handlePaisChange} disabled={deshabilitar}>
                                                {paises.map( pais => (
                                                    <Option key={ pais.id }>{ pais.pais }</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={24} sm={12}>
                                        <Form.Item  label="Región" name="region" 
                                                    rules={[{ required: true, message:'Selecciona tu región'},]}
                                                    {...(cargandoPais ? {validateStatus:'validating', hasFeedback:true } : {})}>
                                            <Select {...(cargandoPais ? {disabled:true } : {})} onChange={this.handleDepartamentoChange} disabled={deshabilitar}>
                                                {departamentos.map( departamento => (
                                                    <Option key={ departamento.id }>{ departamento.departamento }</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item  label="Ciudad" name="ciudad" 
                                                    rules={[{ required: true, message: 'Selecciona tu ciudad',},]}
                                                    {...(cargandoPais ? {validateStatus:'validating', hasFeedback:true } : {})}>
                                            <Select {...(cargandoPais ? {disabled:true } : {})} disabled={deshabilitar}>
                                                {ciudades.map( ciudad => (
                                                    <Option key={ ciudad.id }>{ ciudad.ciudad }</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={24} sm={12}>
                                        <Form.Item  label="Dirección empresa" name="direccion" 
                                                    rules=  {[
                                                        { required: true, message: 'Ingresa la dirección de la entidad',},
                                                        { min:5, message: 'No puede ser menor a 5 caracteres',},
                                                        { max:250, message: 'No puede ser mayor a 250 caracteres',},
                                                    ]} >
                                            <Input autoComplete="off" disabled={deshabilitar}/>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item>
                                    <Button type="primary" 
                                            htmlType="submit" 
                                            loading={cargandoPais || cargando }
                                            disabled={deshabilitar}>
                                        Guardar
                                    </Button>
                                </Form.Item>

                            </Form>
                        </Spin>
                        
                    </>
                )
                :
                (
                    (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                    (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                    :((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:IBE]</label>) : (<></>))
                )
            }
          </div>
        );
    }
}
          
export default withRouter(withCookies(HookReactRouterV6Parametros(InformacionBasicaEntidad)));