import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { SearchOutlined } from '@ant-design/icons';
import { Spin, Table, Modal, Progress } from 'antd';
import ServicioUsuarios from '../../servicios/usuarios/ServicioUsuarios'
import ServicioAdministracion from '../../servicios/ServicioAdministracion'
import { withCookies } from 'react-cookie';
import Constantes from '../../utilidades/Constantes'
import Utilidades from '../../utilidades/Utilidades'
import ServicioDatosConstantes from '../../servicios/ServicioDatosConstantes';
import FiltroColumna from '../../general/tabla/FiltroColumna'
import FiltroEtiqueta from '../../general/tabla/FiltroEtiqueta'
import _ from 'lodash';



class ReporteDirecciones extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3,
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        descargando: false,

        filtro: {},
        filtroEtiquetas: [],
        filtroApi : {},
        paginacion: {
            current: 1,
            pageSize: 10,
        },
        cargandoTabla: true
    };

    constructor(props) {
        super(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);

        let contexto = this;
        this.ano = this.props.ano;
        this.filtroColumna = new FiltroColumna(props, (valores) => { contexto.setSumaState(valores, contexto) });
    }

    componentDidMount() {
        this._isMounted = true;
        this.props.onRef(this)
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.onRef(undefined)
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        // Validando que el usuario esté en sesion
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr != null) {
            await this.cargarDatosTabla(this);
            this.setSumaState({componente: this.ENUM_COMPONENTE.VER, cargando: false,usuario: usr});

        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
        // Esto le avisa al contenedor padre (ReportesUsuarios.js) que ya cargó lo datos
        this.props.onCargado(true);
    }

    async cambioAno( ano ){
        this.ano = ano;
        await this.cargarDatosTabla(this);
        // Indica al contenedor padre (ReportesUsuarios.js) que ya cargó lo datos
        this.props.onCargado(true);
    }

    // 'limpiarFiltro': Método (clearFilters) que es propio de la tabla antDesign para limpiar los filtros
    // 'confirmarFiltro': Método (confirm) que es própio de la tabla antDesign para aplicar el filtro
    // 'dataIndex': Retorna el campo que se está filtrando
    // 'selectedKeys': Retorna el valor a filtrar;
    async cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) {
        let filtro = contexto.state.filtro;
        if (dataIndex !== undefined) { // Si el filtro es aplicado, se agrega la columna filtrada
            filtro[dataIndex] = selectedKeys;
        }
        contexto.setSumaState({ filtro: filtro });

        // Se construye el filtroApi a partir de lo que fue filtrado en la tabla
        let filtroApi = {
            ...(filtro.codigoSolicitud && filtro.codigoSolicitud.length > 0 && { codigo: filtro.codigoSolicitud[0] }),
            ...(filtro.direccion && filtro.direccion.length > 0 && { direccion: filtro.direccion[0] }),
            ...(filtro.unidad && filtro.unidad.length > 0 && { unidad: filtro.unidad[0] }),
            ...(filtro.tipoEspacioPublico && filtro.tipoEspacioPublico.length > 0 && { tipoEspacioPublico: filtro.tipoEspacioPublico[0] }),
            ...(filtro.predioPublico && filtro.predioPublico.length > 0 && { predioPublico: filtro.predioPublico[0] }),
            ano: this.ano,
            detallado:this.props.detallado
        };

        const paginacion = this.state.paginacion;
        let paginacionApi = {
            paginaActual:this.state.paginacion.current,
            paginaTamano:this.state.paginacion.pageSize,
            ordenarColumna:this.state.paginacion.ordenarColumna,
            orden: this.state.paginacion.orden
        }

        let cambio = false;
        // Primero valida si hubo cambio a nivel de filtro
        if( !_.isEqual(this.state.filtroApi, filtroApi) ){
            //console.log("Cambio filtro");
            paginacion.current = 1;
            paginacionApi.paginaActual = 1;
            cambio = true;
        }

        // Segundo valida si hubo cambio a nivel de paginación
        if( !cambio && !_.isEqual(this.state.paginacionApi, paginacionApi) ){
            //console.log("Cambio paginación");
            //console.log("paginacionApi",paginacionApi);
            cambio = true;
        }
        
        if( !cambio ){
            // Si no hubo cambio no se hace nada
            return;
        }

        contexto.setSumaState({  
            cargandoTabla: true, 
            filtro, 
            filtroApi, 
            paginacionApi, 
            paginacion
        });

        await this.servicioAdministracion.obtenerDirecciones(
                {
                    ...filtroApi,
                    ...paginacionApi
                }
            )
            .then((respuestaApi) => {
                if (respuestaApi) {
                    
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.ADMINISTRACION.DATOS_RETORNADOS.id:
                            let tablaPaginada = respuestaApi.resultado;
                            const paginacion = this.state.paginacion;
                            paginacion.total = tablaPaginada.total;

                            contexto.setSumaState({
                                direcciones: tablaPaginada.registros,
                                cargandoTabla: false,
                                paginacion
                            });

                            break;
                        default: break;
                    }
                    
                }
            }).then(() => {
                if (limpiarFiltro !== undefined) { limpiarFiltro(); }
                if (confirmarFiltro !== undefined) { confirmarFiltro(); }
            });
    }

    async descargarReporte() {
        let contexto = this;
        contexto.setSumaState({ descargando: true, porcentaje: 0 });
        await this.servicioAdministracion.descargarReporteDirecciones(
            {
                ...this.state.filtroApi,
                ...this.state.paginacionApi,
                paginaActual : 1,
                paginaTamano : -1
            },
            (percentCompleted) => {
                contexto.setSumaState({ porcentaje: percentCompleted });
            }
        )
            .then((respuesta) => {
                if (respuesta) {
                    switch (respuesta.status) {
                        case 200:
                            const type = respuesta.headers['content-type'];
                            const nombre = respuesta.headers['filename'];
                            const blob = new Blob([respuesta.data], { type: type });
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = url;
                            link.download = nombre;
                            link.click();
                            link.remove();
                            this.setSumaState({ descargando: false });
                            break;
                        default:
                            this.setSumaState({ descargando: false });
                            Modal.error({ content: "No fue posible generar el reporte de direcciones. [Cod:RD01]" });
                            break;
                    }
                }
            });
    }

    handleTableChange = async (pagination, filters, sorter) => {
        const paginacion = this.state.paginacion;
        paginacion.current = pagination.current;
        paginacion.pageSize = pagination.pageSize;
        paginacion.ordenarColumna = sorter.field;
        paginacion.orden = sorter.order;
        this.setSumaState({  paginacion });
        await this.cargarDatosTabla(this);
    };


    render() {

        const { direcciones, componente, descargando, porcentaje } = this.state;
        let { filtro } = this.state;
        filtro = filtro || {};
        let contexto = this;

        const columns = [
            {
                title: 'Código Solicitud',
                align: 'center',
                dataIndex: 'codigoSolicitud',
                key: 'codigoSolicitud',
                width: 80,
                sorter: (a, b) => 0, //a.codigoSolicitud - b.codigoSolicitud,
                filteredValue: filtro.codigoSolicitud || null,
                ...this.filtroColumna.busquedaTexto(
                    'Código',
                    'codigoSolicitud',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            {
                title: 'Dirección',
                align: 'left',
                dataIndex: 'direccion',
                key: 'direccion',
                sorter: (a, b) => 0, //a.direccion ? a.direccion.localeCompare(b.direccion) : 1,
                filteredValue: filtro.direccion || null,
                ...this.filtroColumna.busquedaTexto(
                    'Dirección',
                    'direccion',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            {
                title: 'Unidad',
                align: 'left',
                dataIndex: 'unidad',
                key: 'unidad',
                sorter: (a, b) => 0, //a.unidad ? a.unidad.localeCompare(b.unidad) : 1,
                filteredValue: filtro.unidad || null,
                ...this.filtroColumna.busquedaTexto(
                    'Unidad',
                    'unidad',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            {
                title: 'Tipo Espacio Público',
                align: 'left',
                dataIndex: 'tipoEspacioPublico',
                key: 'tipoEspacioPublico',
                sorter: (a, b) => 0, //a.tipoEspacioPublico ? a.tipoEspacioPublico.localeCompare(b.tipoEspacioPublico) : 1,
                filteredValue: filtro.tipoEspacioPublico || null,
                ...this.filtroColumna.busquedaTexto(
                    'Tipo Espacio Público',
                    'tipoEspacioPublico',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                render(text, record) {
                    return (
                        <>
                            {Utilidades.dividirTexto(text, ' - ')}
                        </>
                    )
                },
            },
            {
                title: 'Predio Público',
                align: 'left',
                dataIndex: 'predioPublico',
                key: 'predioPublico',
                sorter: (a, b) => 0, //a.predioPublico ? a.predioPublico.localeCompare(b.predioPublico) : 1,
                filteredValue: filtro.predioPublico || null,
                ...this.filtroColumna.busquedaTexto(
                    'Predio Público',
                    'predioPublico',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            

        ];

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>

                            <Modal
                                visible={descargando}
                                closable={false}
                                footer={null}
                                width={320}
                                style={{ textAlign: 'center' }}
                            >
                                <label style={{ marginBottom: 13, display: 'block' }}>
                                    Generando reporte...
                                </label>
                                <Progress type="circle" percent={porcentaje} width={80} />
                            </Modal>

                            {
                                this.state.filtroEtiquetas.length > 0 ?
                                    (<div className="seccion-filtros-etiquetas">
                                        <div className="titulo-filtro-etiquetas">
                                            <label>Filtros <SearchOutlined /> :</label>
                                        </div>

                                        {
                                            this.state.filtroEtiquetas.map((filtroEtiqueta, i) => {
                                                return (
                                                    <FiltroEtiqueta key={i}
                                                        dataIndex={filtroEtiqueta.dataIndex}
                                                        texto={filtroEtiqueta.texto}
                                                        onClose={filtroEtiqueta.borrarFiltro} />
                                                )
                                            })
                                        }
                                    </div>)
                                    :
                                    (<></>)
                            }

                            <Table columns={columns}
                                dataSource={direcciones}
                                rowKey={"id"} 
                                onChange={this.handleTableChange}
                                pagination={this.state.paginacion}
                                loading={this.state.cargandoTabla}/>
                        </Spin>
                    )
                    :
                    (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                        (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                        : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:REPDIRECCIONES]</label>) : (<></>))
                }
            </div>

        );

    }

}

export default withRouter(withCookies(ReporteDirecciones));