import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter, Switch, Route } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Spin, Button} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ServicioAdministracion from '../../servicios/ServicioAdministracion'
import ServicioEntidad from '../../servicios/ServicioEntidad'
import Constantes from '../../utilidades/Constantes';
import Utilidades from '../../utilidades/Utilidades';
import ListaRolesDependencia from '../../entidad/dependencia/roles/ListaRolesDependencia';
import AdministrarRolDependencia from '../../entidad/dependencia/roles/AdministrarRolDependencia';

class RolesDependencia extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3,
        NO_HABILITADO: 4
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
    };

    static PATHS = {
        DEPENDENCIA_ROLES_SUMA: '/dependencia-roles-suma',
        DEPENDENCIA_ROL_SUMA: '/dependencia-rol-suma',
    };


    constructor(props) {
        super(props);
        this.historyLocal = props.history;

        this.servicioAdministracion = new ServicioAdministracion(props);
        this.servicioEntidad = new ServicioEntidad(props);

    }


    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let usr = await this.servicioEntidad.usuarioValido();
        if (usr !== null) {

            this.setSumaState({ usuario: usr });
            let habilitado = Utilidades.accionPermitida(usr, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.LISTAR_ROLES.id);
            if (habilitado) {

                // Se consulta la aplicación conectada y se carga
                this.setSumaState({ componente: this.ENUM_COMPONENTE.VER, cargando: false });
            } else {
                this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_HABILITADO });
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    clickAgregarRolDependencia = (  ) => {
        this.historyLocal.push(RolesDependencia.PATHS.DEPENDENCIA_ROL_SUMA);
    }

    clickAdministrarRol = ( idDependencia, idRol) => {
        let query = ( idRol ? ('?id=' + idRol) : '');
        this.historyLocal.push(RolesDependencia.PATHS.DEPENDENCIA_ROL_SUMA + query );    
    }


    render() {
        const { componente, cargando, usuario } = this.state;


        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>

                            <Spin tip={'por favor espera...'} size="large" spinning={cargando}>

                                <Switch>
                                    <Route path={RolesDependencia.PATHS.DEPENDENCIA_ROLES_SUMA}>
                                        <>
                                            <div className="div-lista-botones margen-bottom-5">
                                                <Button type="primary"
                                                    onClick={() => this.clickAgregarRolDependencia()}
                                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.AGREGAR_ROL.id)}
                                                    icon={<PlusOutlined />}>
                                                    Agregar Rol
                                                </Button>
                                            </div>
                                            <ListaRolesDependencia callbackAdministrarRol={this.clickAdministrarRol} />
                                        </>
                                    </Route>
                                    <Route path={RolesDependencia.PATHS.DEPENDENCIA_ROL_SUMA}>
                                        <AdministrarRolDependencia  urlListadoRoles={RolesDependencia.PATHS.DEPENDENCIA_ROLES_SUMA}/>
                                    </Route>

                                    
                                </Switch>



                            </Spin>


                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : (
                                (componente === this.ENUM_COMPONENTE.NO_HABILITADO ?
                                    <label>Roles dependencia no disponible. {Constantes.MENSAJE_USUARIO_SIN_PERMISOS}</label> :
                                    (componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:ROLESDEP]</label>) : (<></>))
                            )
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(RolesDependencia));