import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Modal, Spin, Row, Col, Form, Input, Button, Select, Popconfirm, Checkbox } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import ServicioUsuarios from '../../servicios/usuarios/ServicioUsuarios'
import ServicioDatosConstantes from '../../servicios/ServicioDatosConstantes'
import ServicioAdministracion from '../../servicios/ServicioAdministracion'
import ServicioEntidad from '../../servicios/ServicioEntidad'
import queryString from 'query-string';
import Constantes from '../../utilidades/Constantes';
import Utilidades from '../../utilidades/Utilidades'




class AdministrarRol extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3,
        NO_HABILITADO: 4,
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        tiposSolicitud: []
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
        this.servicioEntidad = new ServicioEntidad(props);
        this.onSuccess = props.onSuccess;
        this.textAreaUrlRef = React.createRef();
    }

    formRef = React.createRef();
    
    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        let idRol;

        if (urlparametros.id !== undefined && !isNaN(parseInt(urlparametros.id))) {
            idRol = parseInt(urlparametros.id);
        }
        
        let usuario = await this.servicioAdministracion.usuarioValido();
        if ( usuario !== null ) {
            let habilitado = Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.VER_ROL.id);
            if( habilitado ){
                let modulos = await this.servicioAdministracion.obtenerModulos();
                modulos.sort((a, b) => a.id > b.id ? 1 : -1);
                for( let modulo of modulos ){
                    modulo.acciones.sort((a, b) => a.id > b.id ? 1 : -1);
                }
                
                this.setSumaState({ modulos, idRol });
                
                let rol;
                if ( idRol ) {
                    // Se consulta el rol y se carga (Esto es en modo Edición Rol)
                    rol = await this.servicioAdministracion.obtenerRol( idRol );
                    if ( rol ) {
                        this.setSumaState({
                            componente: this.ENUM_COMPONENTE.VER,
                            rol, 
                            dependencia: rol.dependencia,
                            tipoUsuario: rol.tipoUsuario });
                        
                        this.formRef.current.setFieldsValue({
                            'rol' :           rol.rol,
                            'descripcion':    rol.descripcion,
                            'activo':         rol.activo,
                            'acciones':       rol.acciones,
                        });
                        
                    } else {
                        this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
                    }
                } else {
                    const tiposUsuario = await this.servicioDatosConstantes.obtenerTiposUsuario();
                    const dependencias = await this.servicioAdministracion.obtenerDependencias();
                    this.setSumaState({ componente: this.ENUM_COMPONENTE.VER, tiposUsuario, dependencias });
                }

                this.setSumaState({ cargando: false, usuario }); 
            }else{
                this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_HABILITADO }); 
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    irListadoRoles( contexto ) {
        contexto.historyLocal.push( '/roles' );
    }

    verRol( contexto, id ) {
        contexto.historyLocal.push('/rol?id=' + id );
        this.cargarDatosIniciales();
    }

    errorFormularioIncompleto = errorInfo => {
        Modal.warn({ content: "Por favor diligencia la información solicitada para el Rol" });
    };

    formularioCompleto = (values) => {
        return new Promise(resolve => {
            
            let rol = {
                rol: values.rol.trim(),
                descripcion: values.descripcion.trim(),
                ...(values.activo && {activo: values.activo}),
                acciones: values.acciones,
                ...(values.dependencia && {dependencia: { id: parseInt(values.dependencia) }}),
                ...(values.tipoUsuario && {tipoUsuario: { id: parseInt(values.tipoUsuario) }}),
            };

            resolve(rol);
        }).then(async ( rol ) => {
            
            this.setSumaState({cargando: true});

            if ( !this.state.rol ) {
                // Rol Nuevo
                let respuestaApi = await this.servicioAdministracion.crearRol( rol );
                if (respuestaApi) {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id:
                            this.verRol(this, respuestaApi.resultado);
                            Modal.success({ content: 'Rol creado satisfactoriamente.' });
                            break;
                        default:
                            Modal.error({ content: 'No fue posible crear el rol. Por favor intenta mas tarde.' });
                            break;
                    }
                }
            } else {
                // Editar Rol
                rol.id = this.state.idRol;
                let respuestaApi = await this.servicioAdministracion.actualizarRol( rol );
                if (respuestaApi) {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id:
                            Modal.success({ content: 'Rol actualizado satisfactoriamente.' });
                            break;   
                        default:
                            Modal.error({ content: 'No fue posible actualizar el rol. Por favor intenta mas tarde.' });
                            break;
                    }
                }

            }

            this.setSumaState({cargando: false});
            
        });

    };

    cancelarFormularioDependencia = () => {
        this.onSuccess(this.state.idDependencia, this.state.idOpcDependencia, true);
    }

    guardarCursorTextArea = (event) =>{
        this.setSumaState({ cursorInicio:event.target.selectionStart, cursorFin: event.target.selectionEnd }); 
    }

    onChangeTipoUsuario = (valor) => {
        let obligatorioDependencia = false;
        if( parseInt(valor) === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id ){
            obligatorioDependencia = true;
        }
        this.setSumaState({ obligatorioDependencia });
        let form = this.formRef.current;
        if( !obligatorioDependencia ){
            form.setFieldsValue({'dependencia': '' });
        }
    }
    
    render() {
        const { dependencia, dependencias, rol, componente, modulos, usuario,
                cargando, tipoUsuario, tiposUsuario, obligatorioDependencia  } = this.state;
        let contexto = this;

        const layout = {
            labelCol: { // Este maneja el grid de los label
                sm: { span: 23, offset: 1 },
                lg: { span: 7, offset: 1 },
            },
            wrapperCol: { // Este maneja el grid de los componentes
                sm: { span: 23, offset: 1 },
                lg: { span: 15, offset: 1 },
            },
        };

        const labelPrimerCol ={ // Este maneja de forma especial el grid de las primeras columnas
                sm: { span: 23, offset: 1 },
                lg: { span: 8, offset: 0 },
        }

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <div className="titulo-seccion">
                                <label>
                                    { !rol ? 'AGREGAR ' : ''} ROL
                                </label>
                                <div className="separador-horizontal-completo margen-bottom-10"></div>
                            </div>  
                            <Spin tip={'por favor espera...'} size="large" spinning={cargando}>

                                <Form
                                    {...layout}
                                    ref={this.formRef}
                                    name="datos-dependencia"
                                    className="formulario-suma"
                                    scrollToFirstError
                                    onFinish={ this.formularioCompleto }
                                    onFinishFailed={ this.errorFormularioIncompleto }
                                >

                                    <div className="sub-titulo-seccion">
                                        <label>Información</label>
                                        <div className="separador-horizontal-completo02 margen-bottom-20"></div>
                                    </div>

                                    <Form.Item label="id" name="id" noStyle>
                                        <Input type="hidden" />
                                    </Form.Item>
                                    {rol ?
                                        <>
                                            <Row>
                                                <Col xs={24} sm={12}>
                                                    <Row>
                                                        <Col {...labelPrimerCol}>
                                                            <label className="bold">Tipo de usuario:</label>
                                                        </Col>
                                                        <Col {...layout.wrapperCol}>
                                                            {tipoUsuario.tipoUsuario}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                {dependencia ? 
                                                <Col xs={24} sm={12}>
                                                    <Row>
                                                        <Col {...layout.labelCol}>
                                                        <label className="bold">Dependencia:</label>
                                                        </Col>
                                                        <Col {...layout.wrapperCol}>
                                                            {dependencia.dependencia}
                                                        </Col>
                                                    </Row>
                                                </Col> : null }
                                            </Row>
                                            <br/>
                                        </> : 
                                        <>
                                            <Row>
                                                <Col xs={24} sm={12}>
                                                    <Form.Item label="Tipo de usuario" name="tipoUsuario"
                                                        rules={[{ required: true, message: 'Selecciona el tipo de usuario', }]}
                                                        labelCol={labelPrimerCol}>
                                                        <Select onChange={this.onChangeTipoUsuario}>
                                                            {tiposUsuario.map( tipoUsuario => (
                                                                <Select.Option key={tipoUsuario.id}>{tipoUsuario.tipoUsuario}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={12}>
                                                    <Form.Item label="Dependencia" name="dependencia"
                                                        rules={[{ required: obligatorioDependencia, message: 'Selecciona la dependencia', }]}>
                                                        <Select {...(obligatorioDependencia ? {} : { disabled: true })}>
                                                            {dependencias.map( dependencia => (
                                                                <Select.Option key={dependencia.id}>{dependencia.dependencia}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Rol" name="rol"
                                                rules={[
                                                    { required: true, message: 'Ingresa el nombre del rol', },
                                                    { min: 3, message: 'No puede ser menor a 3 caracteres', },
                                                    { max: 255, message: 'No puede ser mayor a 255 caracteres', },
                                                ]}
                                                labelCol={labelPrimerCol}
                                            >
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Row>
                                                <Col sm={18}>
                                                    <Form.Item name="activo" valuePropName="checked">
                                                        <Checkbox>Activo</Checkbox>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        
                                    </Row>
                                    
                                    <Row>
                                        <Col xs={24}>
                                            <Form.Item label="Descripción" name="descripcion"
                                                labelCol={{ span: 24, offset: 0 }}
                                                wrapperCol={{ span: 24, offset: 0 }}
                                                rules={[
                                                    { required: true, message: 'Ingresa la descripción del rol', },
                                                    { min: 5, message: 'No puede ser menor a 5 caracteres', },
                                                    { max: 500, message: 'No puede ser mayor a 500 caracteres', },
                                                ]}>

                                                <Input.TextArea rows={2} />

                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    

                                    <Form.Item  name="acciones" 
                                                labelCol={{ span: 24, offset: 0 }}
                                                wrapperCol={{ span: 24, offset: 0 }}>

                                        <Checkbox.Group style={{ width: '100%' }}>
                                            {modulos.map(modulo => (
                                                <React.Fragment key={modulo.id}>
                                                    <div className="sub-titulo-seccion">
                                                        <label>Módulo - {modulo.modulo}</label>
                                                        <div className="separador-horizontal-completo02 margen-bottom-5"></div>
                                                    </div>
                                                    <Row className="margen-bottom-10">
                                                        <Col xs={9} sm={4} lg={3} className="alinear-centro">
                                                            <label className="bold">Selección</label>
                                                        </Col>
                                                        <Col xs={15} sm={18} lg={12}>
                                                            <label className="bold">Acción</label>
                                                        </Col>
                                                    </Row>
                                                    {modulo.acciones.map(accion => (
                                                        <Row key={accion.id}>
                                                            <Col xs={9} sm={4} lg={3} className="alinear-centro">
                                                                <Checkbox value={accion.id} />
                                                            </Col>
                                                            <Col xs={15} sm={18} lg={12}>
                                                                {accion.accion}
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                    <br/>
                                                </React.Fragment>
                                            ))}    
                                        </Checkbox.Group>
                                    </Form.Item>
                                        
                                    
                                    <br/><br/>
                                    
                                    <Row>
                                        <Col xs={24}>
                                            <Form.Item wrapperCol={{ span: 24, offset: 0 }}>
                                                { ((rol && Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.EDITAR_ROL.id)) ||
                                                  (!rol && Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.AGREGAR_ROL.id))) ? 
                                                <Button type="primary"
                                                    htmlType="submit"
                                                    loading={cargando}>
                                                    Guardar
                                                </Button> : null }
                                                {!rol ?
                                                <>
                                                    &nbsp;&nbsp;
                                                    <Popconfirm title={'Confirma cancelar la ' + (rol ? 'edición' : 'creación') + ' del rol?'} 
                                                                okText="Si"
                                                                cancelText="No" 
                                                                onConfirm={() => {this.irListadoRoles(contexto)}}>
                                                        <Button icon={<CloseOutlined/>}>Cancelar</Button>
                                                    </Popconfirm>
                                                </> : null }
                                            </Form.Item>

                                            {rol ?
                                            <Button className="boton-opcion"
                                                icon={<CloseOutlined style={{ color: '#c90a00' }} />}
                                                disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.ELIMINAR_ROL.id)}
                                                onClick={(e) => {
                                                    this.setSumaState({ cargando: true });
                                                    this.props.callbackValidarEliminarRol( 
                                                        rol.id,
                                                        () => {
                                                            this.setSumaState({ cargando: false });
                                                        });
                                                }}>
                                                Eliminar rol
                                            </Button>: null }

                                        </Col>
                                    </Row>
                                </Form>
                            </Spin>
                            
                            
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?

                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : (
                                (componente === this.ENUM_COMPONENTE.NO_HABILITADO ? 
                                <label>Ver rol no disponible. {Constantes.MENSAJE_USUARIO_SIN_PERMISOS}</label> :
                                (componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:ADMROL]</label>) : (<></>))
                            )
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(AdministrarRol));