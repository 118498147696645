import React, { Component } from 'react';
import SumaContexto  from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Row, Col, Modal, Spin, Tooltip, Tag } from 'antd';
import { FileOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Constantes from '../utilidades/Constantes'
import ServicioDatosConstantes from '../servicios/ServicioDatosConstantes'
import ServicioPersonaNatural from '../servicios/usuarios/ServicioPersonaNatural'
import ActualizarArchivo from '../carga-archivos/ActualizarArchivo'
import Utilidades from '../utilidades/Utilidades'


class PersonaNaturalDocumentos extends Component {

    static contextType = SumaContexto;
    
    ENUM_COMPONENTE ={
        CARGANDO:1,
        VER:2,
        NO_DISPONIBLE:3
    }

    state = {
        cargando: true,
        tipoArhivosPersonaNatural: null,
        personaNaturalArchivos: null,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        usuario:null,
    };

    constructor(props){
        super(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes( props );
        this.servicioPersonaNatural = new ServicioPersonaNatural( props );
    }
   
    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales(){
        // Validando que el usuario es PERSONA NATURAL
        let usr = await this.servicioPersonaNatural.usuarioValido();
        this.setState({ componente:(usr !== null ? this.ENUM_COMPONENTE.VER : this.ENUM_COMPONENTE.NO_DISPONIBLE)});
        if(this.state.componente === this.ENUM_COMPONENTE.VER){
            this.setState({ cargando: true });
            let tipoArhivosPersonaNatural = await this.obtenerTipoArhivosPersonaNatural();
            let personaNaturalArchivos = await this.obtenerArchivos();
            if( personaNaturalArchivos !== null && tipoArhivosPersonaNatural != null ){
                // Se quitan los tipos de archivos que ya tiene el representante registrados
                for( let i = 0; i < personaNaturalArchivos.length; i++ ){
                    for( let m = 0; m < tipoArhivosPersonaNatural.length; m++ ){
                        if( personaNaturalArchivos[i].archivo.tipoArchivo.id === tipoArhivosPersonaNatural[m].id ){
                            tipoArhivosPersonaNatural.splice( m, 1 );
                            break;
                        }
                    }
                }
                this.setState({ 
                                usuario: usr,
                                cargando: false, 
                                personaNaturalArchivos:personaNaturalArchivos,
                                tipoArhivosPersonaNatural: tipoArhivosPersonaNatural,
                            });
            }
            //console.log('tipoArhivosPersonaNatural',tipoArhivosPersonaNatural);
        }
        
    }

    obtenerArchivos() {
        return new Promise(resolve => {
            this.servicioPersonaNatural.obtenerArchivos(
                ( respuestaApi ) => { 
                    switch(respuestaApi.codigo){
                        case Constantes.ENUMS_API.PERSONA_NATURAL.DATOS_RETORNADOS.id:
                            resolve( respuestaApi.resultado );
                            break;
                        default:
                            Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:PND02]" });
                            resolve( null );
                            break;    
                    }
                },
                ( errorAPI )        => { 
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:PND03]" });
                    resolve(null);
                }
            );
        });
    }

    obtenerTipoArhivosPersonaNatural () {
        return new Promise(resolve => {
            this.servicioDatosConstantes.obtenerTipoArchivoPersonaNatural(
                ( tipoArhivosPersonaNatural ) => { 
                    resolve( tipoArhivosPersonaNatural );
                },
                ( errorAPI )        => { 
                    Modal.error({content: "Error del sistema, por favor intenta más tarde. [Cod:PND01]" });
                    resolve(null);
                },
            );
        });
    }

    actualizarPersonaNaturalArchivo( personaNaturalArchivo ) {
        return new Promise(resolve => {
            this.servicioPersonaNatural.actualizarPersonaNaturalArchivo(
                ( respuestaApi ) => { 
                    //console.log('respuestaApi:::::',respuestaApi);
                    switch(respuestaApi.codigo){
                        case Constantes.ENUMS_API.PERSONA_NATURAL.OPERACION_REALIZADA.id:
                            resolve( respuestaApi.resultado );
                            break;
                        default:
                            Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:PND06]" });
                            resolve( null );
                            break;    
                    }
                },
                ( errorAPI )        => { 
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:PND07]" });
                    resolve(null);
                },
                personaNaturalArchivo
            );
        });
    }


    agregarPersonaNaturalArchivo( idArchivo ) {
        let personaNaturalArchivo = {
            archivo:{ id:idArchivo },
        };
        return new Promise(resolve => {
            this.servicioPersonaNatural.agregarPersonaNaturalArchivo(
                ( respuestaApi ) => { 
                    //console.log('respuestaApi:::::',respuestaApi);
                    switch(respuestaApi.codigo){
                        case Constantes.ENUMS_API.PERSONA_NATURAL.OPERACION_REALIZADA.id:
                            resolve( respuestaApi.resultado );
                            break;
                        default:
                            Modal.error({ message: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:PND04]" });
                            resolve( null );
                            break;    
                    }
                },
                ( errorAPI )        => { 
                    Modal.error({ message: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:PND05]" });
                    resolve(null);
                },
                personaNaturalArchivo
            );
        });
    }

   
    componentDidMount() {
        this.cargarDatosIniciales();
    }


    async archivoPersonaNaturalActualizado( idNuevoArchivo, personaNaturalArchivo, tipoArchivo ){
        this.setState({ cargando: true });
        //console.log('personaNaturalArchivo',personaNaturalArchivo);
        let resultado = null;
        if( personaNaturalArchivo !== null ){
            personaNaturalArchivo.archivo = { id:idNuevoArchivo };
            resultado = await this.actualizarPersonaNaturalArchivo( personaNaturalArchivo );
        }else{
            resultado = await this.agregarPersonaNaturalArchivo( idNuevoArchivo );
        }

        if( resultado !== null ){
            this.setState({ personaNaturalArchivos: null, tipoArhivosPersonaNatural:null });
            this.cargarDatosIniciales();
            Modal.success({ 
                content: "El documento " + 
                tipoArchivo.nombreArchivo + 
                " fue " + ( personaNaturalArchivo === null ? 'agregado' : 'actualizado') + " satisfactoriamente." 
            });
        }
    }


    render() {
      //const { usuario } = this.context;
      const { personaNaturalArchivos, tipoArhivosPersonaNatural, componente, usuario } = this.state;
      
      const wrapperColDocs01 = {
        xs: {span:21, offset: 1},  
        sm: {span:7, offset: 1},
      };
      const wrapperColDocs02 = {
        xs: {span:21, offset: 1},  
        sm: {span:15, offset: 1},
      };

        return (
          <div>    

            {(componente === this.ENUM_COMPONENTE.VER) ?
                (
                    <> 
                        <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>
                            
                            <h3 className="sub-titulo-separador margen-bottom margen-top">Documentos persona natural</h3>
                            <div className="seccion-datos">

                                {
                                    (personaNaturalArchivos !== null)
                                    ?
                                    personaNaturalArchivos.map((personaNaturalArchivo, index) => 
                                        <Row className="fila documento" key={index} >
                                            <Col  {...wrapperColDocs01}>
                                                <a className="enlace-documento tamano-25" href={personaNaturalArchivo.archivo.url} target="_blank" rel="noopener noreferrer">
                                                    <div>
                                                        <FileOutlined/> 
                                                        <div>
                                                            <label className="bold">{personaNaturalArchivo.archivo.tipoArchivo.nombreArchivo}</label>
                                                            <br/>
                                                            <label>Ver documento</label>
                                                        </div>    
                                                    </div>
                                                    { personaNaturalArchivo.archivo.actualizarArchivo
                                                    ? 
                                                        <Tooltip title="El documento debe ser actualizado!">
                                                            <Tag icon={<ExclamationCircleOutlined />} color="magenta" key="Cambio Contraseña">ACTUALIZAR DOCUMENTO</Tag>
                                                        </Tooltip>
                                                    : 
                                                        <></>
                                                    }
                                                    
                                                </a>
                                            </Col>
                                            <Col  {...wrapperColDocs02}>
                                                <ActualizarArchivo key={index}
                                                    tipoArchivo={personaNaturalArchivo.archivo.tipoArchivo} 
                                                    agregar={false}
                                                    callbackArchivoActualizado={(idNuevoArchivo) => { 
                                                                                            this.archivoPersonaNaturalActualizado(
                                                                                                                                        idNuevoArchivo,
                                                                                                                                        personaNaturalArchivo,
                                                                                                                                        personaNaturalArchivo.archivo.tipoArchivo
                                                                                                                                    ) 
                                                                                                    }}
                                                    deshabilitar={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_NATURAL_DOCUMENTOS.ACCIONES.ACTUALIZAR_DOCUMENTOS_PERSONA_NATURAL.id)}
                                                />
                                            </Col>    
                                        </Row>
                                    )
                                    :
                                    (<></>)
                                }

                                {
                                    (tipoArhivosPersonaNatural !== null)
                                    ?
                                    tipoArhivosPersonaNatural.map((tipoArhivoPersonaNatural, index) => 
                                        <Row className="fila documento" key={index} >
                                            <Col  {...wrapperColDocs01}>
                                                <Tooltip title="No tiene asociado el documento">
                                                    <div className="enlace-documento tamano-25" >
                                                        <div>
                                                            <FileOutlined/>
                                                            <div> 
                                                                <label className="bold">{tipoArhivoPersonaNatural.nombreArchivo}</label>
                                                                <br/>
                                                                <label>(El documento no ha sido cargado)</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            </Col>
                                            <Col  {...wrapperColDocs02}>
                                                <ActualizarArchivo key={index}
                                                    tipoArchivo={tipoArhivoPersonaNatural} 
                                                    agregar={true}
                                                    callbackArchivoActualizado={(idNuevoArchivo) => { 
                                                                                            this.archivoPersonaNaturalActualizado(
                                                                                                                                        idNuevoArchivo,
                                                                                                                                        null,
                                                                                                                                        tipoArhivoPersonaNatural
                                                                                                                                    ) 
                                                                                                    }}
                                                    deshabilitar={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_NATURAL_DOCUMENTOS.ACCIONES.AGREGAR_DOCUMENTOS_PERSONA_NATURAL.id)}
                                                />
                                            </Col>    
                                        </Row>
                                    )
                                    :
                                    (<></>)
                                }

                            </div>

                        </Spin>
                    </>
                )
                :
                (
                    (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                    (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                    :((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:PND]</label>) : (<></>))
                )
            }   
          </div>
        );
    }
}
          
export default withRouter(withCookies(PersonaNaturalDocumentos));