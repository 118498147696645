import React, { Component } from 'react';
import SumaContexto  from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Form, Input, Button, Row, Col, Select, DatePicker, Modal, Spin, Popconfirm, Popover } from 'antd';
import { CloseOutlined, SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import Validadores from '../../utilidades/Validadores'
import ServicioPais from '../../servicios/ServicioPais'
import Constantes from '../../utilidades/Constantes'
import Utilidades from '../../utilidades/Utilidades'
import ServicioDatosConstantes from '../../servicios/ServicioDatosConstantes'
import ServicioPersonaJuridica from '../../servicios/usuarios/ServicioPersonaJuridica'
import ServicioAdministracion from '../../servicios/ServicioAdministracion';
import moment from 'moment';

const dateFormat = 'DD/MM/YYYY';

class FormularioUsuarioDatoPersonal extends Component {

    static contextType = SumaContexto;
    
    state = {
        paises:[],
        departamentos:[],
        ciudades:[],
        tiposDocumentos:[],
        roles:[],
        cargandoPais: false,
        cargandoTipoDocumentos: false,
        cargandoRoles: false,
        cargando: false,
        correoElectronico: null,
        textoCargandoTemp:null,
        esUsuarioDadep:undefined,
    };

    formularioCompleto;
    formularioIncompleto;

    constructor(props){
        super(props);
        this.servicioPais = new ServicioPais( props );
        this.servicioDatosConstantes = new ServicioDatosConstantes( props );
        this.servicioPersonaJuridica = new ServicioPersonaJuridica ( props );
        this.servicioAdministracion = new ServicioAdministracion ( props );
        this.formularioCompleto = props.formularioCompleto;
        this.formularioIncompleto = props.formularioIncompleto;
        this.rol = props.rol !== undefined ? props.rol : false;
        this.opcionCancelar = props.opcionCancelar !== undefined ? props.opcionCancelar : false;
        this.usuario = props.usuario !== undefined ? props.usuario : null;
        this.idTipoUsuario = this.usuario !== null ? this.usuario.tipoUsuario.id : undefined;
        this.cancelarCallback = props.cancelarCallback !== undefined ? props.cancelarCallback : null;
        this.personaNatural = props.personaNatural !== undefined ? props.personaNatural : false;   
    }
    
    formRef = React.createRef();


    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales(){
        let form = this.formRef.current;
        this.setSumaState({ esUsuarioDadep:this.props.esUsuarioDadep });
        if( form  != null ){
            this.setSumaState({ textoCargandoTemp:'Por favor espera ...', cargando:true, cargandoPais: true, cargandoTipoDocumentos:true, cargandoRoles:true  });
            let idPais = Constantes.ENUMS.PAIS.COLOMBIA.id;
            if( this.usuario !== null ){
                if( this.usuario.datoPersonal.ciudad ){
                    idPais = this.usuario.datoPersonal.ciudad.idPais;
                }else{
                    Modal.error({ content: `Error. El Usuario con id:${this.usuario.id} no tiene asociado el país` }); 
                    return;
                }
            }
            await this.obtenerPaises();
            await this.obtenerDepartamentos( idPais );
            if( this.usuario !== null ){
                await this.obtenerCiudades( this.usuario.datoPersonal.ciudad.idDepartamento );
            }
            await this.obtenerTipoDocumentos();
            if( this.rol ){
                await this.obtenerRolesPersonaJuridica();
            }

            if( this.props.admin ){
                let estados = await this.servicioDatosConstantes.obtenerEstadosUsuario();
                this.setSumaState({ estados: estados });

                let roles = [];
                if (this.idTipoUsuario && this.idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.ADMINISTRADOR_SUMA.id) {
                    roles = await this.servicioDatosConstantes.obtenerRolesAdminsitradorSuma();
                }
                if (this.idTipoUsuario && this.idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id ) {
                    let idDependencia = this.usuario !== null && this.usuario.usuarioDependencia ? this.usuario.usuarioDependencia.dependencia.id : 0;
                    roles = await this.servicioDatosConstantes.obtenerRolesDependencia(idDependencia);
                }
                if (this.idTipoUsuario && this.idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.PERSONA_JURIDICA.id ) {
                    roles = await this.obtenerRolesPersonaJuridica();
                }
                if (this.idTipoUsuario && this.idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.PERSONA_NATURAL.id ) {
                    roles = await this.servicioDatosConstantes.obtenerRolesPersonaNatural();
                }
                this.setSumaState({ roles: roles ? roles : [] });
            }

            
            if( this.usuario !== null ){
                form.setFieldsValue({
                    'primerNombre' :                this.usuario.datoPersonal.primerNombre,
                    'segundoNombre' :               this.usuario.datoPersonal.segundoNombre,
                    'primerApellido' :              this.usuario.datoPersonal.primerApellido,
                    'segundoApellido' :             this.usuario.datoPersonal.segundoApellido,
                    'correoElectronico' :           this.usuario.datoPersonal.correoElectronico,
                    'correoElectronicoConfirmar':   this.usuario.datoPersonal.correoElectronico,
                    'direccion' :                   this.usuario.datoPersonal.direccion,
                    'tipoDocumento' :               this.usuario.datoPersonal.tipoDocumento.id.toString(),
                    'numeroDocumento' :             this.usuario.datoPersonal.numeroDocumento,
                    'lugarExpedicion' :             this.usuario.datoPersonal.lugarExpedicion,
                    'movil' :                       this.usuario.datoPersonal.celular,
                    'telefono' :                    this.usuario.datoPersonal.telefono, 
                    'pais':                         this.usuario.datoPersonal.ciudad.idPais.toString(), 
                    'region':                       this.usuario.datoPersonal.ciudad.idDepartamento.toString(),  
                    'ciudad':                       this.usuario.datoPersonal.ciudad.id.toString(),  
                    'rol':                          this.usuario.rol.id.toString(),
                    ...(this.usuario.datoPersonal.fechaExpedicionFormatoEstandar && {'fechaExpedicion' : moment(this.usuario.datoPersonal.fechaExpedicionFormatoEstandar, dateFormat)} ),
                    // Si es un usuario de tipo entidad, se verifica si tiene la propiedad usuarioDependencia para extraer el cargo
                    ...(this.usuario.usuarioDependencia && {'cargo':this.usuario.usuarioDependencia.cargo} ),
                    ...(this.props.admin && {'usuario':this.usuario.usuario} ),
                    ...(this.props.admin && {'correoElectronicoUsuario':this.usuario.correoElectronico} ),
                    ...(this.props.admin && {'estado':this.usuario.estadoUsuario.id.toString()} ),
                });

            }
            this.setSumaState({ textoCargandoTemp:null, cargando:false });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        const { childRef } = this.props;
        if( childRef ){
            childRef(this);
        }
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
        const { childRef } = this.props;
        if( childRef ){
            childRef(undefined);
        }
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    obtenerRolesPersonaJuridica () {
        return new Promise(resolve => {
            this.servicioDatosConstantes.obtenerRolesPersonaJuridica(
                ( roles ) => { 
                    this.setSumaState({ roles: roles, }); 
                    setTimeout(() => {
                        this.setSumaState({ cargandoRoles: false });
                        resolve(roles);
                    },500); 
                },
                ( errorAPI ) => { 
                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:FUDP03]" }); 
                    resolve(true);
                },
            );
        });
    }

    obtenerTipoDocumentos () {
        return new Promise(resolve => {
            this.servicioDatosConstantes.obtenerTiposDocumentos(
                ( tiposDocumentos ) => { 
                    this.setSumaState({ tiposDocumentos: tiposDocumentos, }); 
                    setTimeout(() => {
                        this.setSumaState({ cargandoTipoDocumentos: false });
                        resolve(true);
                    },500); 
                },
                ( errorAPI )        => { 
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:AERL03]" });
                    resolve(true);
                },
            );
        });
    }

    obtenerPaises () {
        return new Promise(resolve => {
            this.servicioPais.obtenerPaises(
                ( paises )      => { 
                    this.setSumaState({ paises: paises, }); 
                    resolve(true);
                },
                ( errorAPI )    => { 
                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:FUDP01]" }); 
                    resolve(true);
                },
            );
        });
    }

    obtenerDepartamentos = idPais =>{
        let form = this.formRef.current;
        this.setSumaState({ cargandoPais: true });
        return new Promise(resolve => {
            this.servicioPais.obtenerDepartamentosDelPais(
                idPais,
                ( departamentos )   => { 
                                            setTimeout(() => {
                                                if( form != null ){
                                                    this.setSumaState({ cargandoPais: false, departamentos: departamentos, });
                                                    form.setFieldsValue({'region':'', 'ciudad':''});
                                                }
                                                resolve(true);
                                            }, 500); 
                                        },
                ( errorAPI )        => { 
                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:FUDP04]" }); 
                    resolve(true);
                },
            );
        });
    }

    obtenerCiudades = idDepartamento =>{
        let form = this.formRef.current;
        this.setSumaState({ cargandoPais: true });
        return new Promise(resolve => {
            this.servicioPais.obtenerCiudadesDelDepartamento(
                idDepartamento,
                ( ciudades )   =>   { 
                                        setTimeout(() => {
                                            if( form != null ){
                                                this.setSumaState({ cargandoPais: false, ciudades: ciudades, });
                                                form.setFieldsValue({'ciudad':''});
                                            }
                                            resolve(true);
                                        },500);
                                    },
                ( errorAPI )   => { 
                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:FUDP05]" }); 
                    resolve(true);
                },
            );
        });
    }
  
    handlePaisChange = value => {
        //console.log('PaisCambio ', value );
        this.obtenerDepartamentos( value );
    };
    handleDepartamentoChange = value => {
        //console.log('DepartamentoCambio ', value );
        this.obtenerCiudades( value );
    }

    async asynFormularioCompleto( values ){
        let res = await this.formularioCompleto( values );
        return res;
    }

    onChangeCorreoElectronico = ( e ) => {
        if(this.props.esUsuarioDadep){
            let form = this.formRef.current;
            form.setFieldsValue({ 
                'primerNombre':'', 
                'segundoNombre':'', 
                'primerApellido':'',
                'segundoApellido':'',
                'numeroDocumento':'',
                'correoElectronicoConfirmar':'', 
                'usuario': '',
                'correoElectronicoUsuario': ''
                });
        }
    }

    buscarUsuarioLDAP = async () => {
        let form = this.formRef.current;
        let correoElectronico = form.getFieldValue('correoElectronico');
        if( !Validadores.stringValido(correoElectronico) ){
            Modal.warning({ content: 'Por favor ingresa un correo electrónico' }); 
            return;
        }
        this.setSumaState({ cargando:true, textoCargandoTemp: 'Consultando usuario en el directorio activo...' });
        
        let respuestaApi = await this.servicioAdministracion.obtenerUsuarioLdap( correoElectronico );
        //console.log('respuestaApi', respuestaApi);
        if( respuestaApi.codigo === Constantes.ENUMS_API.ADMINISTRACION.DATOS_RETORNADOS.id ){
            let usuarioLdap = respuestaApi.resultado;
            form.setFieldsValue({
                'primerNombre' :                usuarioLdap.givenName.split(/\s/)[0].trim(),
                'segundoNombre' :               (usuarioLdap.givenName.split(/\s/).length > 1 ? usuarioLdap.givenName.substring(usuarioLdap.givenName.indexOf(' ') + 1) : '').trim(),
                'primerApellido' :              usuarioLdap.sn.split(/\s/)[0].trim(),
                'segundoApellido' :             (usuarioLdap.sn.split(/\s/).length > 1 ? usuarioLdap.sn.substring(usuarioLdap.sn.indexOf(' ') + 1) : '').trim(),
                'correoElectronico' :           usuarioLdap.userPrincipalName.trim(),
                'correoElectronicoConfirmar':   usuarioLdap.userPrincipalName.trim(),
                'usuario':                      usuarioLdap.userPrincipalName.trim(),
                'correoElectronicoUsuario':     usuarioLdap.userPrincipalName.trim(),
                'numeroDocumento' :             usuarioLdap.description.trim(),
            });
        }else{
            Modal.error({ content: Utilidades.saltoDeLinea(respuestaApi.mensaje + '. [Cod:FUDP06]') }); 
        }
        this.setSumaState({ cargando: false, textoCargandoTemp: null});
        
    };

    
    render() {
      const {   itemsAdicionales, textoCargando, nombreBotonSubmit, 
                opcionCancelar, usuario, camposAdicionales, 
                admin, /* La propiedad 'admin' indica si el formulario lo está viendo un administrador del SUMA para habilitar todos los campos*/
                esUsuarioDadep } = this.props;        
      const { Option } = Select;
      const dateFormatList = ['DD/MM/YYYY'];
      const { 
          paises, departamentos, textoCargandoTemp,
          ciudades, tiposDocumentos, roles, estados,
          cargandoPais, cargando, cargandoTipoDocumentos , cargandoRoles
        } = this.state;

      const layout = {
        labelCol: {
          sm: {span: 22, offset: 2},
          md: {span: 12, offset: 2},
          lg: {span: 12, offset: 2},
          xl: {span: 10, offset: 2}
        },
        wrapperCol: {
          sm: {span: 22, offset: 2},
          md: {span: 10},
          lg: {span: 10},  
          xl: {span: 12},
        },
      };

      
      const onFinish = values => {
        this.setSumaState({ cargando: true });
        this.asynFormularioCompleto( values )
        .then(
            resultado => {
                if( resultado !== null ){
                    if( resultado.limpiarFormulario ){
                        let form = this.formRef.current;
                        if( form  != null ){
                            form.resetFields();
                        }
                    }
                    this.setSumaState({ cargando: resultado.cargando });
                }
            }
        );
      };
    
      const onFinishFailed = errorInfo => {
        this.formularioIncompleto( errorInfo );
      };
  
        return (
          <div>    
            <Spin tip={ textoCargandoTemp !== null ? textoCargandoTemp : textoCargando} size="large" spinning={this.state.cargando}>
                <Form
                    {...layout}
                    ref={this.formRef}
                    name="crear-persona-juridica"
                    className="formulario-suma"
                    scrollToFirstError
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    >


                    {(usuario && usuario !== null && admin) ?
                        <>
                            <Row>
                                <Col xs={24} sm={12}>
                                    <Form.Item label="Usuario" name="usuario" 
                                        rules=  {[
                                                    { required: true, message: 'Ingresa el usuario',},
                                                    { max:255, message: 'No puede ser mayor a 255 caracteres',},
                                                ]} 
                                    >
                                        <Input autoComplete="off" disabled={esUsuarioDadep}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item label="Correo electrónico usuario" name="correoElectronicoUsuario" 
                                        rules=  {[
                                                    { type: 'email',  message: 'Ingresa un correo electrónico válido',},
                                                    { max:500, message: 'No puede ser mayor a 500 caracteres',}
                                                ]} 
                                    >
                                        <Input autoComplete="off" disabled={esUsuarioDadep}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={12}>
                                    <Form.Item  label="Rol" name="rol" rules=  {[{ required: true, message: 'Selecciona el rol del usuario',},]}>
                                        <Select>
                                            {roles.map( rol => (
                                                <Option key={ rol.id }>{ rol.rol }</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item  label="Estado" name="estado" rules=  {[{ required: true, message: 'Selecciona el rol del usuario',},]}>
                                        <Select>
                                            {estados ? estados.map( estado => (
                                                <Option key={ estado.id }>{ estado.estadoUsuario }</Option>
                                            )) : (<></>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            {this.idTipoUsuario && this.idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id ? 
                            (<>
                                <Row>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Cargo" name="cargo"
                                            rules={[
                                                { required: true, message: 'Ingresa el cargo', },
                                                { min: 4, message: 'No puede ser menor a 4 caracteres', },
                                                { max: 255, message: 'No puede ser mayor a 255 caracteres', },
                                            ]}
                                        >
                                            <Input autoComplete="off" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>):(<></>)
                            }

                        </> : (<></>)
                    }

                    <Row>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Correo electrónico" name="correoElectronico" 
                                rules=  {[
                                            { required: true, message: 'Ingresa tu correo electrónico',},
                                            { type: 'email',  message: 'Ingresa un correo electrónico válido',},
                                            { max:255, message: 'No puede ser mayor a 255 caracteres',},
                                        ]} 
                            >
                                <Input  autoComplete="off" 
                                        disabled={(usuario !== undefined && usuario !== null && !admin)} 
                                        onChange={this.onChangeCorreoElectronico}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            { !esUsuarioDadep ?
                                <Form.Item label="Confirmar correo electrónico" name="correoElectronicoConfirmar" 
                                    dependencies={['correoElectronico']} hasFeedback
                                    rules=  {[
                                                { required: true, message: 'Confirma tu correo electrónico',},
                                                { type: 'email',  message: 'Ingresa un correo electrónico válido',},
                                                { max:255, message: 'No puede ser mayor a 255 caracteres',},
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                    if (!value || getFieldValue('correoElectronico') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject('No coindicen los correos electrónicos');
                                                    },
                                                }),
                                            ]} 
                                >
                                    <Input autoComplete="off" disabled={(usuario !== undefined && usuario !== null && !admin)} />
                                </Form.Item>
                            :
                                <Form.Item label="Directorio activo DADEP">
                                    <Button type="primary" 
                                            icon={ usuario && usuario !== null ? <ReloadOutlined /> : <SearchOutlined />} 
                                            style={{ width: '100%' }}
                                            onClick={this.buscarUsuarioLDAP}>
                                                {usuario && usuario !== null ? 'Actualizar usuario' : 'Buscar usuario'}
                                    </Button>
                                </Form.Item>
                            }
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Primer nombre" name="primerNombre" 
                                rules=  {[
                                            { required: true, message: 'Ingresa tu primer nombre',},
                                            { max:100, message: 'No puede ser mayor a 100 caracteres',},
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (value && !Validadores.validarAlfa( value ).success ) {
                                                        return Promise.reject('Ingresa solo texto');
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]} 
                            >
                                <Input autoComplete="off" disabled={esUsuarioDadep} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Segundo nombre" name="segundoNombre" 
                                rules=  {[
                                            { required: false, },
                                            { max:100, message: 'No puede ser mayor a 100 caracteres',},
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (value && !Validadores.validarAlfaConEspacio( value ).success ) {
                                                        return Promise.reject('Ingresa solo texto');
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]} 
                            >
                                <Input autoComplete="off" disabled={esUsuarioDadep} />
                            </Form.Item>
                        </Col>    
                    </Row>
                    <Row>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Primer apellido" name="primerApellido" 
                                rules=  {[
                                            { required: true, message: 'Ingresa tu primer apellido',},
                                            { max:100, message: 'No puede ser mayor a 100 caracteres',},
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (value && !Validadores.validarAlfa( value ).success ) {
                                                        return Promise.reject('Ingresa solo texto');
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]} 
                            >
                                <Input autoComplete="off" disabled={esUsuarioDadep} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Segundo apellido" name="segundoApellido" 
                                rules=  {[
                                            { required: false, },
                                            { max:100, message: 'No puede ser mayor a 100 caracteres',},
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (value && !Validadores.validarAlfaConEspacio( value ).success ) {
                                                        return Promise.reject('Ingresa solo texto');
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]} 
                            >
                                <Input autoComplete="off" disabled={esUsuarioDadep} />
                            </Form.Item>
                        </Col>
                    </Row>
                    {camposAdicionales ? camposAdicionales() : (<></>)}
                    { this.rol ?
                        (<Row>
                            <Col xs={24} sm={12}>
                                <Form.Item  label="Rol" name="rol" 
                                            rules=  {[{ required: true, message: 'Selecciona el rol del usuario',},]}
                                            {...(cargandoRoles ? {validateStatus:'validating', hasFeedback:true } : {})}>
                                    <Select {...(cargandoRoles ? {disabled:true } : {})}>
                                        {roles.map( rol => (
                                            <Option key={ rol.id }>{ rol.rol }</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                                
                            </Col>
                        </Row>) : (<></>)
                    }

                    <Row>
                        <Col xs={24} sm={12}>
                            <Form.Item  label="Tipo de documento" name="tipoDocumento" 
                                        rules=  {[{ required: true, message: 'Selecciona tu tipo de documento',},]}
                                        {...(cargandoTipoDocumentos ? {validateStatus:'validating', hasFeedback:true } : {})}>
                                <Select {...(cargandoTipoDocumentos ? {disabled:true } : {})}>
                                    {tiposDocumentos.map( tipoDocumento => (
                                        <Option key={ tipoDocumento.id }>{ tipoDocumento.tipoDocumento }</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Número de documento" name="numeroDocumento" dependencies={['tipoDocumento']}
                                rules=  {[
                                            { required: true, message: 'Ingresa tu número de documento',},
                                            { min:5, message: 'No puede ser menor a 5 caracteres',},
                                            { max:50, message: 'No puede ser mayor a 50 caracteres',},
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (value && parseInt(getFieldValue('tipoDocumento')) === Constantes.ENUMS.TIPO_DOCUMENTO.CEDULA_CIUDADANIA.id && !Validadores.validarDigitos( value ).success ) {
                                                        return Promise.reject('Ingresa solo dígitos');
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]} 
                            >
                                <Input autoComplete="off" disabled={esUsuarioDadep} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Lugar de expedición" name="lugarExpedicion" 
                                rules=  {[
                                            { required: !admin, message: 'Ingresa el lugar de expedición del documento',},
                                            { min:4, message: 'No puede ser menor a 4 caracteres',},
                                            { max:250, message: 'No puede ser mayor a 250 caracteres',},
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (value && !Validadores.validarEspecial01Alfa( value ).success ) {
                                                        return Promise.reject('Ingresa solo texto');
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]} 
                            >
                                <Input autoComplete="off" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Fecha expedición" name="fechaExpedicion" rules={[{ required: !admin, message: 'Ingresa la fecha de expedición del documento',},]} >
                                <DatePicker autoComplete="off" 
                                            format={dateFormatList} 
                                            style={{ width: '100%' }}
                                            disabledDate={d => !d || d.isAfter(moment())}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    {( this.personaNatural ) ?
                        <Row>
                            <Col xs={24} sm={12}>
                            <Form.Item  style={{ marginBottom: 0 }}
                                        label={<label><span style={{color:'red'}}>*</span> RUT</label>}
                            >
                                <Form.Item name="rut" 
                                    style={{ display: 'inline-block', width: 'calc(80% - 8px)' }}
                                    rules=  {[
                                                { required: true, message: 'Ingresa el RUT',},
                                                { min:5, message: 'No puede ser menor a 5 caracteres',},
                                                { max:50, message: 'No puede ser mayor a 50 caracteres',},
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                        if ( value && !Validadores.validarDigitos( value ).success ) {
                                                            return Promise.reject('Ingresa solo dígitos');
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]} 
                                >
                                    <Input autoComplete="off" />
                                </Form.Item>
                                <Popover content={(<div>Ingresa sólo un dígito</div>)} title="Digito Verificación" trigger="focus">
                                    <Form.Item name="digitoVerificacion" 
                                        style={{ display: 'inline-block', width: 'calc(20% - 0px)', margin: '0 0 0 8px' }}
                                        rules=  {[
                                                    { required: true, message: 'Dígito',},
                                                    { max:5, message: '*',},
                                                    () => ({
                                                        validator(rule, value) {
                                                            if ( value && !Validadores.validarDigitos( value ).success ) {
                                                                return Promise.reject('*');
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    }),
                                                ]} 
                                    >       
                                        <Input autoComplete="off" />
                                    </Form.Item>
                                </Popover>
                            </Form.Item>
                            </Col>
                        </Row>
                    : <></>}


                    <Row>
                        <Col xs={24} sm={12}>
                            <Form.Item  label="Pais" name="pais" initialValue={`${Constantes.ENUMS.PAIS.COLOMBIA.id}`} 
                                        rules={[{ required: true, message:'Selecciona tu pais'},]}
                                        {...(cargandoPais ? {validateStatus:'validating', hasFeedback:true } : {})}>
                                <Select {...(cargandoPais ? {disabled:true } : {})} onChange={this.handlePaisChange} >
                                    {paises.map( pais => (
                                        <Option key={ pais.id }>{ pais.pais }</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item  label="Departamento" name="region" 
                                        rules={[{ required: true, message:'Selecciona tu departamento'},]}
                                        {...(cargandoPais ? {validateStatus:'validating', hasFeedback:true } : {})}>
                                <Select {...(cargandoPais ? {disabled:true } : {})} onChange={this.handleDepartamentoChange}>
                                    {departamentos.map( departamento => (
                                        <Option key={ departamento.id }>{ departamento.departamento }</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24} sm={12}>
                            <Form.Item  label="Ciudad" name="ciudad" 
                                        rules={[{ required: true, message: 'Selecciona tu ciudad',},]}
                                        {...(cargandoPais ? {validateStatus:'validating', hasFeedback:true } : {})}>
                                <Select {...(cargandoPais ? {disabled:true } : {})} >
                                    {ciudades.map( ciudad => (
                                        <Option key={ ciudad.id }>{ ciudad.ciudad }</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item  label="Dirección residencia" name="direccion" 
                                        rules=  {[
                                            { required: !admin, message: 'Ingresa tu dirección de residencia',},
                                            { min:5, message: 'No puede ser menor a 5 caracteres',},
                                            { max:250, message: 'No puede ser mayor a 250 caracteres',},
                                        ]} >
                                <Input autoComplete="off" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24} sm={12}>
                            <Form.Item  label="Móvil" name="movil" 
                                        rules=  {[
                                            { required: !admin, message: 'Ingresa tu número móvil',},
                                            { min:7, message: 'No puede ser menor a 7 caracteres',},
                                            { max:20, message: 'No puede ser mayor a 20 caracteres',},
                                            { pattern: new RegExp('^[0-9\\+\\s]*$'), message: 'Solo dígitos y puede contener el simbolo +',},
                                        ]} >
                                <Input autoComplete="off" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Teléfono fijo" name="telefono" 
                                            rules=  {[
                                                { required: !admin},
                                                { min:7, message: 'No puede ser menor a 7 caracteres',},
                                                { max:30, message: 'No puede ser mayor a 30 caracteres',},
                                                { pattern: new RegExp('^[0-9\\+\\s]*$'), message: 'Ingresar solo dígitos',}
                                            ]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                        </Col>
                    </Row>

                    { itemsAdicionales !== undefined ? itemsAdicionales() : <></>}

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={cargandoPais || cargando }>
                            {nombreBotonSubmit}
                        </Button>
                        
                        { opcionCancelar ?
                            (
                            <> &nbsp;&nbsp;
                                <Popconfirm title={'Confirma cancelar la ' + (usuario !== null ? 'edición' : 'creación') + ' del usuario?'} 
                                            okText="Si"
                                            cancelText="No" 
                                            onConfirm={(e) => { if(this.cancelarCallback !== null ){this.cancelarCallback();} }}>
                                    <Button icon={<CloseOutlined/>}>Cancelar</Button>
                                </Popconfirm>
                            </>    
                            ) : (<></>)
                        }

                    </Form.Item>

                </Form>
            </Spin>
          </div>
        );
    }
}
          
export default withRouter(withCookies(FormularioUsuarioDatoPersonal));
