import React, { Component } from 'react';
import SumaContexto from '../../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Modal, Spin, Row, Col, Form, Input, Button, Popconfirm, Checkbox } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import ServicioUsuarios from '../../../servicios/usuarios/ServicioUsuarios'
import ServicioDatosConstantes from '../../../servicios/ServicioDatosConstantes'
import ServicioAdministracion from '../../../servicios/ServicioAdministracion'
import ServicioEntidad from '../../../servicios/ServicioEntidad'
import queryString from 'query-string';
import Constantes from '../../../utilidades/Constantes';
import Utilidades from '../../../utilidades/Utilidades';




class AdministrarRolDependencia extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        tiposSolicitud: []
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
        this.servicioEntidad = new ServicioEntidad(props);
        this.onSuccess = props.onSuccess;
        this.textAreaUrlRef = React.createRef();
    }

    formRef = React.createRef();
    
    idAcciones = [
        34, //ACCEDER_MODULO_DEPENDENCIA
        35, //ACTUALIZAR_INFORMACION_DEPENDENCIA
        36, //AGREGAR_SUB_USUARIO_DEPENDENCIA
        37, //LISTAR_SUB_USUARIOS_DEPENDENCIA
        38, //VER_DETALLE_SUB_USUARIO_DEPENDENCIA
        39, //ACTIVAR_SUB_USUARIO_DEPENDENCIA
        40, //DESACTIVAR_SUB_USUARIO_DEPENDENCIA
        41, //ENVIAR_CORREO_ACTIVACION_SUB_USUARIO_DEPENDENCIA
        42, //EDITAR_INFORMACION_SUB_USUARIO_DEPENDENCIA
        43, //ENVIAR_CORREO_CAMBIO_CONTRASENA_SUB_USUARIO_DEPENDENCIA
        60, //ADMINISTRAR_APLICACION_CONECTADA
        95, //ADMINISTRAR_OPCIONES_DEPENDENCIA
        96, //DEPENDENCIA_LISTAR_ROLES
        97, //DEPENDENCIA_VER_ROL
        98, //DEPENDENCIA_EDITAR_ROL
        99, //DEPENDENCIA_ELIMINAR_ROL
        100, //DEPENDENCIA_AGREGAR_ROL

    ];

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        let idRol;
        let idDependencia;

        if (urlparametros.id !== undefined && !isNaN(parseInt(urlparametros.id))) {
            idRol = parseInt(urlparametros.id);
        }
        if (urlparametros.idDep !== undefined && !isNaN(parseInt(urlparametros.idDep))) {
            idDependencia = parseInt(urlparametros.idDep);
        }

        //let usr = await this.servicioAdministracion.usuarioValido();
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        //console.log('usr',usr);
        if( usr.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id ){
            if( usr.usuarioDependencia && usr.usuarioDependencia.dependencia && usr.usuarioDependencia.dependencia.id ){
                idDependencia = usr.usuarioDependencia.dependencia.id;
            }
        }

        if (usr !== null && ( idRol || idDependencia ) ) {
            
            this.setSumaState({ usuario: usr, idDependencia, idRol});

            let modulo = await this.servicioAdministracion.obtenerModulo(Constantes.ENUMS_MODULOS.DEPENDENCIA.id);
            let acciones = [];
            if( modulo && modulo.acciones ){
                for( let accion of modulo.acciones ){
                    if( this.idAcciones.includes(accion.id) ){
                        acciones.push(accion);
                    }
                }
            }
            acciones.sort((a, b) => a.id > b.id ? 1 : -1);
            this.setSumaState({ acciones });
            
            let rol;
            if ( idRol ) {
                // Se consulta el rol y se carga (Esto es en modo Edición Rol)
                rol = await this.servicioAdministracion.obtenerRol( idRol );
                if ( rol && rol.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id ) {
                    
                    this.setSumaState({
                        componente: this.ENUM_COMPONENTE.VER,
                         rol, 
                         dependencia:rol.dependencia,
                         idDependencia:rol.dependencia.id });
                    //console.log('rol',rol);
                    
                    this.formRef.current.setFieldsValue({
                        'rol' :           rol.rol,
                        'descripcion':    rol.descripcion,
                        'activo':         rol.activo,
                        'acciones':       rol.acciones,
                    });
                    
                } else {
                    this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
                }
            } else {

                if( idDependencia ){
                    let respuestaApi = await this.servicioEntidad.obtenerDependenciaPromise( idDependencia );
                    let dependencia = respuestaApi.resultado;
                    if ( dependencia ) {
                        this.setSumaState({ dependencia });
                    }
                }

                this.setSumaState({ componente: this.ENUM_COMPONENTE.VER });
            }

            this.setSumaState({ cargando: false }); 

        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    irListadoRolesDependencia( contexto ) {
        let url = !this.props.urlListadoRoles 
        ? ( '/dependencia-roles?id=' + contexto.state.idDependencia ) 
        : this.props.urlListadoRoles;

        contexto.historyLocal.push( url );
    }


    errorFormularioIncompleto = errorInfo => {
        Modal.warn({ content: "Por favor diligencia la información solicitada para el Rol" });
    };

    formularioCompleto = (values) => {
        return new Promise(resolve => {
            
            let rol = {
                rol: values.rol.trim(),
                descripcion: values.descripcion.trim(),
                ...(values.activo && {activo: values.activo}),
                acciones: values.acciones
            };

            resolve(rol);
        }).then(async ( rol ) => {
            
            this.setSumaState({cargando: true});

            if ( !this.state.rol ) {
                // Rol Nuevo
                rol.dependencia = { id: this.state.idDependencia };
                rol.tipoUsuario = { id: Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id };
                //console.log('rol(A)',rol);
                let respuestaApi = await this.servicioAdministracion.crearRol( rol );
                if (respuestaApi) {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id:
                            this.irListadoRolesDependencia(this);
                            Modal.success({ content: 'Rol creado satisfactoriamente.' });
                            break;
                        default:
                            Modal.error({ content: 'No fue posible crear el rol. Por favor intenta mas tarde.' });
                            break;
                    }
                }
            } else {
                // Editar Rol
                rol.id = this.state.idRol;
                //console.log('rol(B)',rol);
                let respuestaApi = await this.servicioAdministracion.actualizarRol( rol );
                if (respuestaApi) {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id:
                            Modal.success({ content: 'Rol actualizado satisfactoriamente.' });
                            break;   
                        default:
                            Modal.error({ content: 'No fue posible actualizar el rol. Por favor intenta mas tarde.' });
                            break;
                    }
                }

            }

            this.setSumaState({cargando: false});
            
        });

    };

    cancelarFormularioDependencia = () => {
        this.onSuccess(this.state.idDependencia, this.state.idOpcDependencia, true);
    }

    guardarCursorTextArea = (event) =>{
        this.setSumaState({ cursorInicio:event.target.selectionStart, cursorFin: event.target.selectionEnd }); 
    }

    validarEliminarRol = async ( idRol, callback ) => {
        let respuestaApi = await this.servicioAdministracion.validarEliminarRol( idRol );
        if(respuestaApi){
            switch(respuestaApi.codigo){
                case Constantes.ENUMS_API.USUARIO.ROL_VALIDO_PARA_ELIMINAR.id:
                    Modal.confirm({
                        content:respuestaApi.mensaje,
                        cancelText: 'Cancelar',
                        okText: 'Confirmar',
                        onOk:( async(close) =>{
                            respuestaApi = await this.servicioAdministracion.eliminarRol( idRol );
                            switch(respuestaApi.codigo){
                                case Constantes.ENUMS_API.USUARIO.OPERACION_REALIZADA.id:
                                    Modal.success({ content: respuestaApi.mensaje});
                                    this.irListadoRolesDependencia( this );
                                    break;
                                case Constantes.ENUMS_API.USUARIO.OPERACION_NO_REALIZADA.id:
                                    Modal.error({ content: respuestaApi.mensaje});
                                    break;
                                default:
                                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:ADMROLDEP01]" });
                                    break;
                            }
                            close();
                        })
                    });
                    break;
                case Constantes.ENUMS_API.USUARIO.ROL_NO_VALIDO_PARA_ELIMINAR.id:
                    Modal.error({content:respuestaApi.mensaje});
                    break;
                default:
                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:ADMROLDEP02]" });
                    break;    
            }
        }
        callback();
    }
    
    render() {
        const { usuario, dependencia, rol, componente, acciones, cargando } = this.state;
        let contexto = this;

        const layout = {
            labelCol: { // Este maneja el grid de los label
                sm: { span: 23, offset: 1 },
                lg: { span: 7, offset: 1 },
            },
            wrapperCol: { // Este maneja el grid de los componentes
                sm: { span: 23, offset: 1 },
                lg: { span: 15, offset: 1 },
            },
        };

        const labelPrimerCol ={ // Este maneja de forma especial el grid de las primeras columnas
                sm: { span: 23, offset: 1 },
                lg: { span: 8, offset: 0 },
        }

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>

                            <div className="titulo-seccion">
                                <label>
                                    { !rol ? 'AGREGAR ' : ''} ROL
                                </label>
                                <div className="separador-horizontal-completo margen-bottom-10"></div>
                            </div>

                            
                            <Spin tip={'por favor espera...'} size="large" spinning={cargando}>

                                

                                <Form
                                    {...layout}
                                    ref={this.formRef}
                                    name="datos-dependencia"
                                    className="formulario-suma"
                                    scrollToFirstError
                                    onFinish={ this.formularioCompleto }
                                    onFinishFailed={ this.errorFormularioIncompleto }
                                >

                                    <div className="sub-titulo-seccion">
                                        <label>Información Rol {dependencia ? (' - ' + dependencia.dependencia) : ''}</label>
                                        <div className="separador-horizontal-completo02 margen-bottom-20"></div>
                                    </div>

                                    <Form.Item label="id" name="id" noStyle>
                                        <Input type="hidden" />
                                    </Form.Item>

                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Rol" name="rol"
                                                rules={[
                                                    { required: true, message: 'Ingresa el nombre del rol', },
                                                    { min: 3, message: 'No puede ser menor a 3 caracteres', },
                                                    { max: 255, message: 'No puede ser mayor a 255 caracteres', },
                                                ]}
                                                labelCol={labelPrimerCol}
                                            >
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Row>
                                                <Col sm={18}>
                                                    <Form.Item name="activo" valuePropName="checked">
                                                        <Checkbox>Activo</Checkbox>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        
                                    </Row>
                                    
                                    <Row>
                                        <Col xs={24}>
                                            <Form.Item label="Descripción" name="descripcion"
                                                labelCol={{ span: 24, offset: 0 }}
                                                wrapperCol={{ span: 24, offset: 0 }}
                                                rules={[
                                                    { required: true, message: 'Ingresa la descripción del rol', },
                                                    { min: 5, message: 'No puede ser menor a 5 caracteres', },
                                                    { max: 500, message: 'No puede ser mayor a 500 caracteres', },
                                                ]}>

                                                <Input.TextArea rows={2} />

                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <div className="sub-titulo-seccion">
                                        <label>Asignación de acciones - Módulo dependencia</label>
                                        <div className="separador-horizontal-completo02 margen-bottom-20"></div>
                                    </div>

                                    <Form.Item  name="acciones" 
                                                labelCol={{ span: 24, offset: 0 }}
                                                wrapperCol={{ span: 24, offset: 0 }}>

                                        <Checkbox.Group style={{ width: '100%' }}>
                                            <Row className="margen-bottom-10">
                                                <Col xs={9} sm={4} lg={3} className="alinear-centro">
                                                    <label className="bold">Selección</label>
                                                </Col>
                                                <Col xs={15} sm={18} lg={12}>
                                                    <label className="bold">Acción</label>
                                                </Col>
                                            </Row>
                                            {acciones.map(accion => (
                                                <Row key={accion.id}>
                                                    <Col xs={9} sm={4} lg={3} className="alinear-centro">
                                                        <Checkbox value={accion.id} />
                                                    </Col>
                                                    <Col xs={15} sm={18} lg={12}>
                                                        {accion.accion}
                                                    </Col>
                                                </Row>
                                            ))}
                                        </Checkbox.Group>
                                    </Form.Item>
                                        
                                    
                                    <br/><br/>
                                    
                                    <Row>
                                        <Col xs={24}>
                                            <Form.Item wrapperCol={{ span: 24, offset: 0 }}>
                                                
                                                <Button type="primary"
                                                    htmlType="submit"
                                                    disabled={
                                                        !((rol && Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.EDITAR_ROL.id)) ||
                                                        (!rol && Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.AGREGAR_ROL.id)))
                                                    }
                                                    loading={cargando}>
                                                    Guardar
                                                </Button>

                                                {!rol ?
                                                <>
                                                    &nbsp;&nbsp;
                                                    <Popconfirm title={'Confirma cancelar la ' + (rol ? 'edición' : 'creación') + ' del rol?'} 
                                                                okText="Si"
                                                                cancelText="No" 
                                                                onConfirm={() => {this.irListadoRolesDependencia(contexto)}}>
                                                        <Button icon={<CloseOutlined/>}>Cancelar</Button>
                                                    </Popconfirm>
                                                </> : null }

                                                {rol ?
                                                <>
                                                    &nbsp;&nbsp;
                                                    <Button className="boton-opcion"
                                                        icon={<CloseOutlined style={{ color: '#c90a00' }} />}
                                                        disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.ELIMINAR_ROL.id)}
                                                        onClick={(e) => {
                                                            this.setSumaState({ cargando: true });
                                                            this.validarEliminarRol( 
                                                                rol.id,
                                                                () => {
                                                                    this.setSumaState({ cargando: false });
                                                                });
                                                        }}>
                                                        Eliminar rol
                                                    </Button>
                                                </> : null }

                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Spin>
                            
                            
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:ADMROLDEP]</label>) : (<></>))
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(AdministrarRolDependencia));