import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Button, Row, Col, Modal, Spin, Tooltip, Tag, Popconfirm } from 'antd';
import { MailOutlined, EditOutlined, StopOutlined, CheckOutlined, ReloadOutlined } from '@ant-design/icons';
import Constantes from '../../utilidades/Constantes'
import ServicioDatosConstantes from '../../servicios/ServicioDatosConstantes'
import ServicioPersonaJuridica from '../../servicios/usuarios/ServicioPersonaJuridica'
import ServicioPais from '../../servicios/ServicioPais'
import queryString from 'query-string';
import Utilidades from '../../utilidades/Utilidades'
import Validadores from '../../utilidades/Validadores';


class VerSubUsuario extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        cargando: true,
        idUsuario: 0,
        subUsuario: null,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        usuario: null
    };

    constructor(props) {
        super(props);
        //console.log('VerSubUsuario.props',props);

        // Indica que es el administrador del SUMA quien está accediendo al apoderado
        this.administracion = props.administracion;

        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.servicioPersonaJuridica = new ServicioPersonaJuridica(props);
        this.servicioPais = new ServicioPais(props);
        this.callbackEditarUsuario = props.callbackEditarUsuario;
        this.callbackActivarUsuario = props.callbackActivarUsuario;
        this.callbackEnviarCorreoActivacion = props.callbackEnviarCorreoActivacion;
        this.callbackEnviarCorreoCambioContrasena = props.callbackEnviarCorreoCambioContrasena;
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        let idUsuario = 0;

        if ( !this.administracion && urlparametros.id !== undefined && !isNaN(parseInt(urlparametros.id))) {
            // Se toma el idUsuario como un parámetro de consulta en la url '?id=idUsuario'
            idUsuario = parseInt(urlparametros.id);
        }else if( this.administracion ){
            // Se toma el idUsuario si este viene en la url por medio del HookReactRouterV6Parametros,
            // esto funciona cuando es en modo administración y la url es: /persona-juridica/:idPersonaJuridica/usuario/:idUsuario
            idUsuario = this.props.idUsuario;
        }

        // Validando que el usuario es PERSONA JURIDICA
        let usr = await this.servicioPersonaJuridica.usuarioPersonaJuridicaOAdministradorValido();
        this.setSumaState({ componente: (usr !== null ? this.ENUM_COMPONENTE.VER : this.ENUM_COMPONENTE.NO_DISPONIBLE) });

        if (usr !== null && idUsuario !== 0) {
            this.setSumaState({ cargando: true, idUsuario: idUsuario });
            let subUsuario = await this.obtenerUsuario();
            //console.log('subUsuario',subUsuario);
            if (subUsuario !== null) {
                let pais = await this.obtenerPais(subUsuario.datoPersonal.ciudad.idPais);
                let departamento = await this.obtenerDepartamento(subUsuario.datoPersonal.ciudad.idDepartamento);
                if (pais !== null && departamento !== null) {
                    subUsuario.pais = pais;
                    subUsuario.departamento = departamento;

                    this.setSumaState({
                        usuario: usr,
                        cargando: false,
                        subUsuario: subUsuario,
                    });
                }
            }

        }
    }

    obtenerUsuario() {
        return new Promise(resolve => {
            this.servicioPersonaJuridica.obtenerUsuario(
                (respuestaApi) => {
                    //console.log('respuestaApi', respuestaApi);
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.PERSONA_JURIDICA.DATOS_RETORNADOS.id:
                            resolve(respuestaApi.resultado);
                            break;
                        default:
                            Modal.error({ title: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VSU02]" });
                            resolve(null);
                            break;
                    }
                },
                (errorAPI) => {
                    Modal.error({ title: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VSU01]" });
                    resolve(null);
                },
                this.state.idUsuario,
                // Si está en modo administración del SUMA, este dato se captura de la url por medio de HookReactRouterV6Parametros
                this.props.idPersonaJuridica 
            );
        });
    }

    obtenerPais(idPais) {
        return new Promise(resolve => {
            this.servicioPais.obtenerPais(
                idPais,
                (respuestaApi) => {
                    resolve(respuestaApi.pais);
                },
                (errorAPI) => {
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:VSU03]" });
                    resolve(null);
                }
            );
        });
    }

    obtenerDepartamento(idDepartamento) {
        return new Promise(resolve => {
            this.servicioPais.obtenerDepartamento(
                idDepartamento,
                (respuestaApi) => {
                    resolve(respuestaApi.departamento);
                },
                (errorAPI) => {
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:VSU04]" });
                    resolve(null);
                }
            );
        });
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    render() {
        //const { usuario } = this.context;
        const { subUsuario, componente, usuario } = this.state;

        const wrapperCol = {
            xs: { span: 21, offset: 1 },
            sm: { span: 11, offset: 1 },
        };

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>
                                <Row>
                                    <Col>
                                        <h3 className="titulo inline-block">Usuario:</h3> &nbsp;&nbsp;&nbsp;
                                        <h3 className="inline-block">{subUsuario !== null ? subUsuario.datoPersonal.nombreCompleto : ""}</h3>
                                    </Col>
                                </Row>
                                <h3 className="sub-titulo-separador margen-bottom">Información básica</h3>
                                <div className="seccion-datos">
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Tipo de documento:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{subUsuario !== null ? subUsuario.datoPersonal.tipoDocumento.tipoDocumento : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Número de documento:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{subUsuario !== null ? subUsuario.datoPersonal.numeroDocumento : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Lugar de expedición:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{subUsuario !== null ? subUsuario.datoPersonal.lugarExpedicion : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Fecha de expedición:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{subUsuario !== null ? subUsuario.datoPersonal.fechaExpedicionFormatoEspanol : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Correo electrónico:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{subUsuario !== null ? subUsuario.datoPersonal.correoElectronico : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Dirección residencia:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{subUsuario !== null ? subUsuario.datoPersonal.direccion : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Móvil:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{subUsuario !== null ? subUsuario.datoPersonal.celular : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Teléfono:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{subUsuario !== null ? subUsuario.datoPersonal.telefono : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Rol:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{subUsuario !== null ? subUsuario.rol.rol : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Estado:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <Tag color={
                                                        (subUsuario !== null && subUsuario.estadoUsuario.id === Constantes.ENUMS.ESTADO_USUARIO.ACTIVO.id)
                                                            ?
                                                            'green'
                                                            :
                                                            (subUsuario !== null && subUsuario.estadoUsuario.id === Constantes.ENUMS.ESTADO_USUARIO.INACTIVO.id
                                                                ?
                                                                'volcano'
                                                                :
                                                                (subUsuario !== null && subUsuario.estadoUsuario.id === Constantes.ENUMS.ESTADO_USUARIO.CREADO.id
                                                                    ?
                                                                    'geekblue'
                                                                    :
                                                                    ''
                                                                )
                                                            )
                                                    } key={subUsuario !== null ? subUsuario.estadoUsuario.estadoUsuario : ""}>
                                                        {subUsuario !== null ? subUsuario.estadoUsuario.estadoUsuario.toUpperCase() : ""}
                                                    </Tag>

                                                    {subUsuario !== null && subUsuario.cambioContrasena
                                                        ?
                                                        <Tooltip title="El usuario tiene pendiente un cambio de contraseña">
                                                            <Tag color="purple" key="Cambio Contraseña">CONTRASEÑA</Tag>
                                                        </Tooltip>
                                                        :
                                                        <></>
                                                    }

                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">País:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{subUsuario !== null ? subUsuario.pais : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Región:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{subUsuario !== null ? subUsuario.departamento : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Ciudad:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{subUsuario !== null ? subUsuario.datoPersonal.ciudad.ciudad : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Dirección residencia:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{subUsuario !== null ? subUsuario.datoPersonal.direccion : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </div>


                                <h3 className="sub-titulo-separador margen-bottom margen-top">Opciones</h3>
                                <div className="seccion-datos seccion-final">

                                    {(subUsuario !== null)
                                        ?
                                        (<>
                                            <Button className="boton-opcion"
                                                icon={<EditOutlined style={{ color: '#c90a00' }} />}
                                                onClick={(e) => { this.callbackEditarUsuario(e, this.state.idUsuario) }}
                                                disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_USUARIOS.ACCIONES.EDITAR_INFORMACION_SUB_USUARIO.id)}>
                                                Editar
                                            </Button>

                                            {(subUsuario.estadoUsuario.id === Constantes.ENUMS.ESTADO_USUARIO.ACTIVO.id)
                                                ?
                                                <Popconfirm title="Confirma desactivar el usuario?"
                                                    okText="Si"
                                                    cancelText="No"
                                                    onConfirm={(e) => {
                                                        this.setSumaState({ cargando: true });
                                                        this.callbackActivarUsuario(
                                                            e,
                                                            subUsuario.id,
                                                            subUsuario.datoPersonal.nombreCompleto,
                                                            false,
                                                            () => {
                                                                this.setSumaState({ cargando: false });
                                                                this.cargarDatosIniciales();
                                                            });

                                                    }}
                                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_USUARIOS.ACCIONES.DESACTIVAR_SUB_USUARIO.id)}
                                                >
                                                    <Button className="boton-opcion"
                                                        icon={<StopOutlined style={{ color: '#c90a00' }} />}
                                                        disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_USUARIOS.ACCIONES.DESACTIVAR_SUB_USUARIO.id)}>
                                                        Desactivar
                                                    </Button>
                                                </Popconfirm>
                                                :
                                                <></>
                                            }

                                            {(subUsuario.estadoUsuario.id === Constantes.ENUMS.ESTADO_USUARIO.INACTIVO.id)
                                                ?
                                                <Popconfirm title="Confirma activar el sub usuario?"
                                                    okText="Si"
                                                    cancelText="No"
                                                    onConfirm={(e) => {
                                                        this.setSumaState({ cargando: true });
                                                        this.callbackActivarUsuario(
                                                            e,
                                                            subUsuario.id,
                                                            subUsuario.datoPersonal.nombreCompleto,
                                                            true,
                                                            () => {
                                                                this.setSumaState({ cargando: false });
                                                                this.cargarDatosIniciales();
                                                            });

                                                    }}
                                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_USUARIOS.ACCIONES.ACTIVAR_SUB_USUARIO.id)}
                                                >
                                                    <Button className="boton-opcion"
                                                        icon={<CheckOutlined style={{ color: '#c90a00' }} />}
                                                        disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_USUARIOS.ACCIONES.ACTIVAR_SUB_USUARIO.id)}>
                                                        Activar
                                                    </Button>
                                                </Popconfirm>
                                                :
                                                <></>
                                            }


                                            {(subUsuario.estadoUsuario.id === Constantes.ENUMS.ESTADO_USUARIO.CREADO.id)
                                                ?
                                                (<Popconfirm title="Confirma enviar el correo de activación?"
                                                    okText="Si"
                                                    cancelText="No"
                                                    onConfirm={(e) => {
                                                        this.setSumaState({ cargando: true });
                                                        this.callbackEnviarCorreoActivacion(
                                                            e,
                                                            subUsuario.id,
                                                            subUsuario.datoPersonal.nombreCompleto,
                                                            () => {
                                                                this.setSumaState({ cargando: false });
                                                                this.cargarDatosIniciales();
                                                            });
                                                    }}
                                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_USUARIOS.ACCIONES.ENVIAR_CORREO_ACTIVACION_SUB_USUARIO.id)}
                                                >
                                                    <Button className="boton-opcion"
                                                        icon={<MailOutlined style={{ color: '#c90a00' }} />}
                                                        disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_USUARIOS.ACCIONES.ENVIAR_CORREO_ACTIVACION_SUB_USUARIO.id)}>
                                                        Enviar correo activación
                                                    </Button>
                                                </Popconfirm>)
                                                :
                                                (
                                                    (
                                                        !Validadores.usuarioSUMAv1(subUsuario) &&
                                                        !subUsuario.autenticaContraLDAP
                                                    ) ?
                                                        (<Popconfirm title="Confirma enviar el correo para cambio de contraseña?"
                                                            okText="Si"
                                                            cancelText="No"
                                                            onConfirm={(e) => {
                                                                this.setSumaState({ cargando: true });
                                                                this.callbackEnviarCorreoCambioContrasena(
                                                                    e,
                                                                    subUsuario.id,
                                                                    subUsuario.datoPersonal.nombreCompleto,
                                                                    () => {
                                                                        this.setSumaState({ cargando: false });
                                                                        this.cargarDatosIniciales();
                                                                    });
                                                            }}
                                                            disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_USUARIOS.ACCIONES.ENVIAR_CORREO_CAMBIO_CONTRASENA_SUB_USUARIO.id)}
                                                        >
                                                            <Button className="boton-opcion"
                                                                icon={<ReloadOutlined style={{ color: '#c90a00' }} />}
                                                                disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_USUARIOS.ACCIONES.ENVIAR_CORREO_CAMBIO_CONTRASENA_SUB_USUARIO.id)}>
                                                                Cambiar contraseña
                                                            </Button>
                                                        </Popconfirm>) : (<></>)
                                                )
                                            }

                                            {subUsuario.autenticaContraLDAP ?
                                                (<p><br />
                                                    Nota: El método de autenticación del usuario es contra el directorio activo del DADEP.</p>)
                                                : (<></>)
                                            }

                                        </>)
                                        :
                                        (<></>)
                                    }
                                </div>

                            </Spin>
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:VSU]</label>) : (<></>))
                    )
                }

            </div>
        );
    }
}

export default withRouter(withCookies(VerSubUsuario));