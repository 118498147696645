import React, { Component } from 'react';
import SumaContexto  from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Form, Input, Button, Row, Col, Spin, Modal, Checkbox } from 'antd';
import { CheckSquareOutlined } from '@ant-design/icons';
import ServicioUsuarios from '../../servicios/usuarios/ServicioUsuarios'
import Constantes from '../../utilidades/Constantes'
import Utilidades from '../../utilidades/Utilidades';
import Validadores from '../../utilidades/Validadores'

class Contrasena extends Component {

    static contextType = SumaContexto;
    
    state = {
        cargando: false,
        mostrarModal: false,
    };

    constructor(props){
        super(props);
        this.servicioUsuarios = new ServicioUsuarios( props );
        this.cambioContrasena = props.cambioContrasena !== undefined ? props.cambioContrasena : false;
        this.historyLocal = props.history;
        this.callbackSatisfactorioContrasena = props.callbackSatisfactorioContrasena !== undefined ? props.callbackSatisfactorioContrasena : null;
    }
    
    formRef = React.createRef();

    componentDidMount() {
        this._isMounted = true;
        //let usuarioInformacionBasicaDto = this.props.usuarioInformacionBasicaDto;
        //console.log("usuarioInformacionBasicaDto",usuarioInformacionBasicaDto);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    handleCerrarModal = e => {
        this.setSumaState({ mostrarModal: false, });
        if( this._isMounted )
            this.historyLocal.push('/');
    };

    
    render() {
      //let  {form} = this.props.form;
      //const { usuario } = this.context
      
      const { cargando } = this.state;

      const layout = {
        labelCol: {
          /*span: 10, offset: 2,*/
          sm: {span: 22, offset: 2},
          md: {span: 12, offset: 2},
          lg: {span: 12, offset: 2},
          xl: {span: 10, offset: 2}
        },
        wrapperCol: {
          sm: {span: 22, offset: 2},
          md: {span: 10},
          lg: {span: 10},  
          xl: {span: 12},
        },
      };

      const onFinish = values => {
        this.setSumaState({ cargando: true });

        if( !this.cambioContrasena ){
            // Contrasena nueva
            this.servicioUsuarios.asignarContrasena(
                { 
                    contrasena:                 values.contrasena,
                    aceptoTerminoscondiciones:  values.terminosYCondiciones,
                    activarUsuario:             this.props.activarUsuario
                },
                this.props.token,
                ( respuestaApi )   =>  {  
                    let form = null;
                    switch(respuestaApi.codigo){
                        case Constantes.ENUMS_API.USUARIO.CONTRASENA_ASIGNADA.id:
                            form = this.formRef.current;
                            if( form != null ){
                                form.resetFields();
                                if( this.callbackSatisfactorioContrasena !== null ){
                                    this.callbackSatisfactorioContrasena();
                                }else{
                                    this.setSumaState({ mostrarModal: true, });
                                }
                            }
                            break;
                        case Constantes.ENUMS_API.USUARIO.CONTRASENA_NO_DISPONIBLE.id:
                            Modal.warning({content:'Debes ingresar una contraseña que no hayas registrado en anteriores ocasiones.'});
                            form = this.formRef.current;
                            if( form != null ){
                                form.resetFields();
                            }
                            break;     
                        case Constantes.ENUMS_API.USUARIO.ERROR_ENCRIPTACION.id:
                            Modal.error({content:'No fue posible asignar la contraseña al usuario. Error de encriptación.'});
                            break;    
                        default:
                            Modal.error({content:'No fue posible asignar la contraseña al usuario.'});
                            break;
                    }

                    //console.log('respuestaApi',respuestaApi); 
                    setTimeout(() => {this.setSumaState({ cargando: false });},500); 
                },
                ( errorAPI )        => { this.setSumaState({ cargando: false }); Modal.error({content:'Error de conexión. Por favor intenta mas tarde. [Cod:C01]'}) }
            );
        }else{
            // cambio de contraseña
            this.servicioUsuarios.cambioContrasena(
                { contrasena: values.contrasena},
                this.props.token,
                ( respuestaApi )   =>  {  
                    let form = null;
                    switch(respuestaApi.codigo){
                        case Constantes.ENUMS_API.USUARIO.CONTRASENA_ASIGNADA.id:
                            Modal.success({content:'Tu cambio de contraseña fue satisfcatorio, ahora podrás ingresar al portal SUMA.'});
                            form = this.formRef.current;
                            if( form != null ){
                                form.resetFields();
                            }
                            if( this._isMounted ){
                                this.historyLocal.push('/');
                            }
                            if( this.callbackSatisfactorioContrasena !== null ){
                                this.callbackSatisfactorioContrasena();
                            }
                            break;
                        case Constantes.ENUMS_API.USUARIO.CONTRASENA_NO_DISPONIBLE.id:
                            Modal.warning({content:'Debes ingresar una contraseña que no hayas registrado en anteriores ocasiones.'});
                            form = this.formRef.current;
                            if( form != null ){
                                form.resetFields();
                            }
                            break; 
                        case Constantes.ENUMS_API.USUARIO.SIN_OPCION_CAMBIAR_CONTRASENA.id:
                            Modal.error({content:'El usuario ya modificó previamente su contraseña, para asignar una nueva contraseña debes seleccionar la opcíon "Recuperar contraseña".'});
                            break;
                        case Constantes.ENUMS_API.USUARIO.ERROR_ENCRIPTACION.id:
                            Modal.error({content:'No fue posible cambiar la contraseña al usuario. Error de encriptación.'});
                            break;       
                        default:
                            Modal.error({content:'No fue posible cambiar la contraseña al usuario.'});
                            break;
                    }

                    //console.log('respuestaApi',respuestaApi); 
                    setTimeout(() => {this.setSumaState({ cargando: false });},500); 
                },
                ( errorAPI )        => { this.setSumaState({ cargando: false }); Modal.error({content:'Error de conexión. Por favor intenta mas tarde. [Cod:C02]'}) }
            );
        }

      };
    
      const onFinishFailed = errorInfo => {
        Modal.warning({content:'Por favor diligencia la información solicitada.'});
      };
  
        return (
          <div>    
            <Spin tip="Cargando..." size="large" spinning={this.state.cargando}>
                <Modal  title="Importante" closable={false} className="modal-suma icono-con-texto"
                        visible={this.state.mostrarModal} 
                        footer={[
                            <Button key="cerrar" type="primary" onClick={this.handleCerrarModal} >
                              Cerrar
                            </Button>
                        ]}
                >
                    <CheckSquareOutlined />
                    <p>
                        Tu usuario ha sido activado
                    </p>
                </Modal>
                <Form
                    {...layout}
                    ref={this.formRef}
                    name="asignar-contrasena"
                    className="formulario-suma"
                    scrollToFirstError
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    >
                    
                    <Row>
                        <Col xs={24} sm={12}>
                            <Form.Item  label="Contraseña" name="contrasena" hasFeedback
                                        rules={[ 
                                            { required: true, message: 'Ingresa tu contraseña', },
                                            () => ({
                                                validator(rule, value) {
                                                  if ( value ) {
                                                    const val = Validadores.validarContrasena( value );
                                                    if( !val.success ){
                                                        return Promise.reject( val.msg );  
                                                    }
                                                  }
                                                  return Promise.resolve();
                                                },
                                            }),
                                        ]} >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item  label="Confirmar contraseña" name="contrasenaConfirmar" 
                                        dependencies={['contrasena']} hasFeedback
                                        rules={[ 
                                            { required: true, message: 'Confirma tu contraseña', }, 
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                  if (!value || getFieldValue('contrasena') === value) {
                                                    return Promise.resolve();
                                                  }
                                                  return Promise.reject('No coindicen las contraseñas');
                                                },
                                            }),
                                        ]}>
                                <Input.Password />
                            </Form.Item>
                        </Col>
                    </Row>

                    { (this.props.usuarioInformacionBasicaDto && this.props.usuarioInformacionBasicaDto.debeAceptarTerminosCondiciones) ?
                        <Row>
                            <Col xs={24} >
                                <Form.Item  name="terminosYCondiciones" 
                                            valuePropName="checked" 
                                            rules={[
                                                {
                                                validator: (_, value) =>
                                                    value ? Promise.resolve() : Promise.reject(new Error('Debes aceptar los términos y condiciones')),
                                                },]}
                                                labelCol={{ span: 24, offset: 0 }}
                                                wrapperCol={{ span: 24, offset: 0 }}>
                                    <Checkbox>
                                        Acepto los <a href={Utilidades.extraerEnlace(Constantes.ENUMS.PARAMETRO_GENERAL.ENLACE_TERMINOS_Y_CONDICIONES.id)}>términos y condiciones</a> y la 
                                        <a href={Utilidades.extraerEnlace(Constantes.ENUMS.PARAMETRO_GENERAL.ENLACE_PROTECCION_DATOS_PERSONALES.id)}> política de protección de datos personales</a>
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        : null
                    }

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={ cargando }>
                            Guardar Contraseña
                        </Button>
                    </Form.Item>

                </Form>
            </Spin>
          </div>
        );
    }
}
          
export default withRouter(Contrasena);