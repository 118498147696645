import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { SearchOutlined } from '@ant-design/icons';
import { Spin, Table, Modal, Progress } from 'antd';
import ServicioUsuarios from '../../servicios/usuarios/ServicioUsuarios'
import ServicioAdministracion from '../../servicios/ServicioAdministracion'
import { withCookies } from 'react-cookie';
import Constantes from '../../utilidades/Constantes'
import ServicioDatosConstantes from '../../servicios/ServicioDatosConstantes';
import FiltroColumna from '../../general/tabla/FiltroColumna'
import FiltroEtiqueta from '../../general/tabla/FiltroEtiqueta'
//import moment from 'moment';
import _ from 'lodash';



class ReporteUsuarios extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3,
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        descargando: false,

        filtro: {},
        filtroEtiquetas: [],
        filtroApi : {},
        paginacion: {
            current: 1,
            pageSize: 10,
        },
        cargandoTabla: true
    };

    constructor(props) {
        super(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);

        let contexto = this;
        this.filtroColumna = new FiltroColumna(props, (valores) => { contexto.setSumaState(valores, contexto) });
    }

    componentDidMount() {
        this._isMounted = true;
        this.props.onRef(this)
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.onRef(undefined)
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        // Validando que el usuario esté en sesion
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr != null) {
            const estados = await this.servicioDatosConstantes.obtenerEstadosUsuario();
            const tipos = await this.servicioDatosConstantes.obtenerTiposUsuario();
            let estadosUsuario = [];
            let tiposUsuario = [];
            if (estados) {
                for (let estado of estados) {
                    estadosUsuario.push({ label: estado.estadoUsuario, value: estado.id });
                }
            }
            if (tipos) {
                for (let tipo of tipos) {
                    tiposUsuario.push({ label: tipo.tipoUsuario, value: tipo.id });
                }
            }
            await this.cargarDatosTabla(this);
            this.setSumaState({
                componente: this.ENUM_COMPONENTE.VER, cargando: false,
                estadosUsuario: estadosUsuario, usuario: usr, tiposUsuario: tiposUsuario
            });

        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
        // Esto le avisa al contenedor padre (ReportesUsuarios.js) que ya cargó lo datos
        this.props.onCargado(true);
    }

    // 'limpiarFiltro': Método (clearFilters) que es propio de la tabla antDesign para limpiar los filtros
    // 'confirmarFiltro': Método (confirm) que es própio de la tabla antDesign para aplicar el filtro
    // 'dataIndex': Retorna el campo que se está filtrando
    // 'selectedKeys': Retorna el valor a filtrar;
    async cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) {
        let filtro = contexto.state.filtro;
        if (dataIndex !== undefined) { // Si el filtro es aplicado, se agrega la columna filtrada
            filtro[dataIndex] = selectedKeys;
        }
        contexto.setSumaState({ filtro: filtro });

        // Se construye el filtroApi a partir de lo que fue filtrado en la tabla
        let filtroApi = {
            ...(filtro.id && filtro.id.length > 0 && { id: filtro.id[0] }),
            ...(filtro.usuario && filtro.usuario.length > 0 && { usuario: filtro.usuario[0] }),
            ...(filtro.nombre && filtro.nombre.length > 0 && { nombre: filtro.nombre[0] }),
            ...(filtro.rol && filtro.rol.length > 0 && { rol: filtro.rol[0] }),
            ...(filtro.estado && filtro.estado.length > 0 && filtro.estado[0].opc && { estado: filtro.estado[0].opc.join() }),
            ...(filtro.tipoUsuario && filtro.tipoUsuario.length > 0 && filtro.tipoUsuario[0].opc && { tipoUsuario: filtro.tipoUsuario[0].opc.join() }),
            ...(filtro.datosAdicionales && filtro.datosAdicionales.length > 0 && { datosAdicionales: filtro.datosAdicionales[0] }),
            ...(filtro.fechaUltimoLoginFormato && filtro.fechaUltimoLoginFormato.length > 0 && { fechasUltimoLogin: filtro.fechaUltimoLoginFormato[0] + '-' + filtro.fechaUltimoLoginFormato[1] }),
            ...(filtro.fechaCreacionFormato && filtro.fechaCreacionFormato.length > 0 && { fechasCreacion: filtro.fechaCreacionFormato[0] + '-' + filtro.fechaCreacionFormato[1] }),
            detallado:this.props.detallado
        };

        const paginacion = this.state.paginacion;
        let paginacionApi = {
            paginaActual:this.state.paginacion.current,
            paginaTamano:this.state.paginacion.pageSize,
            ordenarColumna:this.state.paginacion.ordenarColumna,
            orden: this.state.paginacion.orden
        }

        let cambio = false;
        // Primero valida si hubo cambio a nivel de filtro
        if( !_.isEqual(this.state.filtroApi, filtroApi) ){
            paginacion.current = 1;
            paginacionApi.paginaActual = 1;
            cambio = true;
        }

        // Segundo valida si hubo cambio a nivel de paginación
        if( !cambio && !_.isEqual(this.state.paginacionApi, paginacionApi) ){
            cambio = true;
        }
        
        if( !cambio ){
            // Si no hubo cambio no se hace nada
            return;
        }

        contexto.setSumaState({  
            cargandoTabla: true, 
            filtro, 
            filtroApi, 
            paginacionApi, 
            paginacion
        });

        await this.servicioAdministracion.obtenerUsuarios(
                {
                    ...filtroApi,
                    ...paginacionApi
                }
            )
            .then((respuestaApi) => {
                if (respuestaApi) {
                    
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.ADMINISTRACION.DATOS_RETORNADOS.id:
                            let tablaPaginada = respuestaApi.resultado;
                            const paginacion = this.state.paginacion;
                            paginacion.total = tablaPaginada.total;

                            contexto.setSumaState({
                                usuarios: tablaPaginada.registros,
                                cargandoTabla: false,
                                paginacion
                            });
                            
                            break;
                        default: break;
                    }
                    
                }
            }).then(() => {
                if (limpiarFiltro !== undefined) { limpiarFiltro(); }
                if (confirmarFiltro !== undefined) { confirmarFiltro(); }
            });
    }

    async descargarReporte() {
        let contexto = this;
        contexto.setSumaState({descargando:true,porcentaje:0});
        await this.servicioAdministracion.descargarReporteUsuarios(
            {
                ...this.state.filtroApi,
                ...this.state.paginacionApi,
                paginaActual : 1,
                paginaTamano : -1
            },
            ( percentCompleted ) => { 
                contexto.setSumaState({porcentaje:percentCompleted});
            }
            )
            .then((respuesta) => {
                if (respuesta) {
                    switch (respuesta.status) {
                        case 200:
                            const type = respuesta.headers['content-type'];
                            const nombre = respuesta.headers['filename'];
                            const blob = new Blob([respuesta.data], { type: type });
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = url;
                            link.download = nombre;
                            link.click();
                            link.remove();
                            this.setSumaState({descargando:false});
                            break;
                        default:
                            this.setSumaState({descargando:false});
                            Modal.error({ content: "No fue posible generar el reporte de usuarios. [Cod:RU01]" });
                            break;
                    }
                }
            });
    }

    handleTableChange = async (pagination, filters, sorter) => {
        const paginacion = this.state.paginacion;
        paginacion.current = pagination.current;
        paginacion.pageSize = pagination.pageSize;
        paginacion.ordenarColumna = sorter.field;
        paginacion.orden = sorter.order;
        this.setSumaState({  paginacion });
        await this.cargarDatosTabla(this);
    };

    render() {

        const { usuarios, componente, estadosUsuario, tiposUsuario, descargando,porcentaje } = this.state;
        const { detallado } = this.props;
        let { filtro } = this.state;
        filtro = filtro || {};
        let contexto = this;

        const columns = [
            ...(detallado ? 
            [{
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                with: 100,
                sorter: (a, b) => 0, //a.id - b.id,
                filteredValue: filtro.id || null,
                ...this.filtroColumna.busquedaTexto(
                    'Identificador',
                    'id',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            }]:[]),
            {
                title: 'Usuario',
                dataIndex: 'usuario',
                key: 'usuario',
                sorter: (a, b) => 0, //a.usuario ? a.usuario.localeCompare(b.usuario) : 1,
                filteredValue: filtro.usuario || null,
                ...this.filtroColumna.busquedaTexto(
                    'Usuario',
                    'usuario',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            {
                title: 'Nombre',
                dataIndex: 'nombre',
                key: 'nombre',
                sorter: (a, b) => 0, //a.nombre ? a.nombre.localeCompare(b.nombre) : 1,
                filteredValue: filtro.nombre || null,
                ...this.filtroColumna.busquedaTexto(
                    'Nombre',
                    'nombre',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            {
                title: 'Rol',
                dataIndex: 'rol',
                key: 'rol',
                sorter: (a, b) => 0, //a.rol ? a.rol.localeCompare(b.rol) : 1,
                filteredValue: filtro.rol || null,
                ...this.filtroColumna.busquedaTexto(
                    'Rol',
                    'rol',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            {
                title: 'Tipo',
                key: 'tipoUsuario',
                dataIndex: 'tipoUsuario',
                sorter: (a, b) => 0, //a.tipoUsuario ? a.tipoUsuario.localeCompare(b.tipoUsuario) : 1,
                filteredValue: filtro.tipoUsuario || null,
                ...this.filtroColumna.busquedaOpciones(
                    'Tipo',
                    'tipoUsuario',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) },
                    tiposUsuario
                ),
            },
            {
                title: 'Datos adicionales',
                dataIndex: 'datosAdicionales',
                key: 'datosAdicionales',
                filteredValue: filtro.datosAdicionales || null,
                ...this.filtroColumna.busquedaTexto(
                    'Datos adicionales',
                    'datosAdicionales',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                render: (text, record) => (
                    <>
                        {record.error ?
                            <span className="span-bloque lineh16 texto-error">{record.error}</span>
                            : <></>
                        }
                        {record.datoAdicional01 ?
                            <span className="span-bloque lineh16 texto-gris-claro">{record.datoAdicional01}</span>
                            : <></>
                        }
                        {record.datoAdicional02 ?
                            <span className="span-bloque lineh16">{record.datoAdicional02}</span>
                            : <></>
                        }
                    </>
                ),
            },
            {
                title: 'Fecha Creación',
                align: 'center',
                dataIndex: 'fechaCreacionFormato',
                key: 'fechaCreacionFormato',
                width: 100,
                sorter: (a, b) => 0, //moment(a.fechaCreacionEstandar, Constantes.FORMATO_FECHA_TIEMPO_ESTANDAR) - moment(b.fechaCreacionEstandar, Constantes.FORMATO_FECHA_TIEMPO_ESTANDAR),
                filteredValue: filtro.fechaCreacionFormato || null,
                ...this.filtroColumna.busquedaFecha(
                    'Fecha Creación',
                    'fechaCreacionFormato',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                )
            },
            {
                title: 'Fecha Login',
                align: 'center',
                dataIndex: 'fechaUltimoLoginFormato',
                key: 'fechaUltimoLoginFormato',
                width: 100,
                sorter: (a, b) => 0, /*{
                    let fecha_a = moment(a.fechaUltimoLoginEstandar, Constantes.FORMATO_FECHA_TIEMPO_ESTANDAR);
                    let fecha_b = moment(b.fechaUltimoLoginEstandar, Constantes.FORMATO_FECHA_TIEMPO_ESTANDAR);
                    if (!isNaN(fecha_a) && !isNaN(fecha_b))
                        return fecha_a - fecha_b;
                    else if (isNaN(fecha_a) && isNaN(fecha_b))
                        return 0;
                    else if (!isNaN(fecha_a) && isNaN(fecha_b))
                        return 1;
                    else
                        return -1;
                },*/
                filteredValue: filtro.fechaUltimoLoginFormato || null,
                ...this.filtroColumna.busquedaFecha(
                    'Fecha Último Login',
                    'fechaUltimoLoginFormato',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                )
            },
            {
                title: 'Estado',
                key: 'estado',
                align: 'center',
                dataIndex: 'estado',
                sorter: (a, b) => 0, //a.estado ? a.estado.localeCompare(b.estado) : 1,
                filteredValue: filtro.estado || null,
                ...this.filtroColumna.busquedaOpciones(
                    'Estado',
                    'estado',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) },
                    estadosUsuario
                ),
            },
        ];

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>

                            <Modal
                                visible={descargando}
                                closable={false}
                                footer={null}
                                width={320}
                                style={{textAlign:'center'}}
                            >
                                <label style={{marginBottom:13,display:'block'}}>
                                    Generando reporte...
                                </label>
                                <Progress type="circle" percent={porcentaje} width={80} />
                            </Modal>

                            {
                                this.state.filtroEtiquetas.length > 0 ?
                                    (<div className="seccion-filtros-etiquetas">
                                        <div className="titulo-filtro-etiquetas">
                                            <label>Filtros <SearchOutlined /> :</label>
                                        </div>

                                        {
                                            this.state.filtroEtiquetas.map((filtroEtiqueta, i) => {
                                                return (
                                                    <FiltroEtiqueta key={i}
                                                        dataIndex={filtroEtiqueta.dataIndex}
                                                        texto={filtroEtiqueta.texto}
                                                        onClose={filtroEtiqueta.borrarFiltro} />
                                                )
                                            })
                                        }
                                    </div>)
                                    :
                                    (<></>)
                            }

                            <Table columns={columns}
                                dataSource={usuarios}
                                rowKey={"id"} 
                                onChange={this.handleTableChange}
                                pagination={this.state.paginacion}
                                loading={this.state.cargandoTabla}/>
                        </Spin>
                    )
                    :
                    (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                        (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                        : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:REPUSUARIOS]</label>) : (<></>))
                }
            </div>

        );

    }

}

export default withRouter(withCookies(ReporteUsuarios));