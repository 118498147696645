import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Modal, Spin, Row, Col, Form, Input, Select } from 'antd';
import Constantes from '../../utilidades/Constantes'
import ServicioEntidad from '../../servicios/ServicioEntidad'
import ServicioDatosConstantes from '../../servicios/ServicioDatosConstantes'
import FormularioUsuarioDatoPersonal from '../../zona-usuario/usuario-general/FormularioUsuarioDatoPersonal'
import queryString from 'query-string';

class AgregarEditarSubUsuarioDependencia extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        idUsuario: 0,
        usuario: null,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        roles:[]
    };

    constructor(props) {
        super(props);
        this.servicioEntidad = new ServicioEntidad(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes( props );
        this.onSuccess = props.onSuccess;
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        let idUsuario = 0;

        if (urlparametros.id !== undefined && !isNaN(parseInt(urlparametros.id))) {
            idUsuario = parseInt(urlparametros.id);
            this.setSumaState({ idUsuario: idUsuario });
        }

        let usr = await this.servicioEntidad.usuarioValido();
        if (usr !== null) {
            if( usr.usuarioDependencia && usr.usuarioDependencia.dependencia && usr.usuarioDependencia.dependencia.entidad &&
                usr.usuarioDependencia.dependencia.entidad.id === Constantes.ENUMS.ENTIDAD.DEPARTAMENTO_ADMINISTRATIVO_DE_LA_DEFENSORIA_DEL_ESPACIO_PUBLICO 
            ){
                this.setSumaState({ esUsuarioDadep: true });
            }
            let roles = await this.servicioDatosConstantes.obtenerRolesDependencia( usr.usuarioDependencia.dependencia.id );
            if( roles ){
                this.setSumaState({ componente: this.ENUM_COMPONENTE.VER, roles:roles });
                if (idUsuario !== 0) {
                    let usuario = await this.servicioEntidad.obtenerUsuarioDependencia( idUsuario );
                    this.setSumaState({ usuario: usuario });
                }
            }else{
                this.setSumaState({ componente:this.ENUM_COMPONENTE.NO_DISPONIBLE });
            }
        }else{
            this.setSumaState({ componente:this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    camposAdicionales = (roles) => {
        return (
            <Row>
                <Col xs={24} sm={12}>
                    <Form.Item label="Cargo" name="cargo"
                        rules={[
                            { required: true, message: 'Ingresa el cargo', },
                            { min: 4, message: 'No puede ser menor a 4 caracteres', },
                            { max: 500, message: 'No puede ser mayor a 255 caracteres', },
                        ]}
                    >
                        <Input autoComplete="off" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                    <Form.Item label="Rol" name="rol"
                        rules={[{ required: true, message: 'Selecciona el rol del usuario', }]}>
                        <Select>
                            {roles.map(rol => (
                                <Select.Option key={rol.id}>{rol.rol}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        )

    }

    formularioCompleto = (values) => {
        return new Promise(resolve => {
            let usr = {
                usuario: values.correoElectronico,
                usuarioDependencia: {
                    cargo: values.cargo
                },
                datoPersonal: {
                    primerNombre: values.primerNombre,
                    segundoNombre: values.segundoNombre,
                    primerApellido: values.primerApellido,
                    segundoApellido: values.segundoApellido,
                    correoElectronico: values.correoElectronico.toLowerCase(),
                    numeroDocumento: values.numeroDocumento,
                    lugarExpedicion: values.lugarExpedicion,
                    fechaExpedicionDocumento: values.fechaExpedicion.format('DD/MM/YYYY'),
                    direccion: values.direccion,
                    celular: values.movil,
                    telefono: values.telefono,
                    ciudad: {
                        id: parseInt(values.ciudad)
                    },
                    tipoDocumento: {
                        id: parseInt(values.tipoDocumento)
                    },
                }
            };

            if (values.rol !== undefined) {
                usr.rol = { id: parseInt(values.rol) };
            }
            resolve( usr );

        }).then( async (usr) =>{
            let resultado = { cargando: false, limpiarFormulario: false };
            if (this.state.idUsuario === 0) {
                // Usuario Nuevo
                let respuestaApi = await this.servicioEntidad.crearSubUsuarioDependencia( usr );
                if( respuestaApi ){
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.USUARIO.USUARIO_CREADO.id:
                            resultado.satisfactorio = true;
                            resultado.limpiarFormulario = true;
                            this.onSuccess(respuestaApi.resultado);
                            break;
                        case Constantes.ENUMS_API.USUARIO.CORREO_NO_DISPONIBLE.id:
                            Modal.error({ content: "El correo electrónico ya se encuentra registrado. Por favor intenta con uno diferente." });
                            break;
                        case Constantes.ENUMS_API.USUARIO.DOCUMENTO_IDENTIDAD_NO_DISPONIBLE.id:
                            Modal.error({content:'No fue posible crear el usuario. Ya se encuentra registrado el número de documento de identidad que fue ingresado.'});
                            break; 
                        case Constantes.ENUMS_API.USUARIO.ERROR_SERVICIO_CORREO_ELECTRONICO.id:
                        case Constantes.ENUMS_API.USUARIO.DOMINIO_DEL_DADEP.id:
                            Modal.error({content:respuestaApi.mensaje});
                            break;       
                        case Constantes.ENUMS_API.USUARIO.USUARIO_NO_CREADO.id:
                        default:
                            Modal.error({ content: 'No fue posible crear el usuario dependencia. Por favor intenta mas tarde.' });
                            break;
                    }
                }
            } else {
                // Editar Usuario 
                usr.id = this.state.idUsuario;
                let  respuestaApi = await this.servicioEntidad.actualizarSubUsuarioDependencia( usr );
                if( respuestaApi ){
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.ENTIDAD.OPERACION_REALIZADA.id:
                            this.onSuccess(respuestaApi.resultado);
                            break;
                        case Constantes.ENUMS_API.USUARIO.DOCUMENTO_IDENTIDAD_NO_DISPONIBLE.id:
                            Modal.error({content:'No fue posible actualizar los datos. Ya se encuentra registrado el número de documento de identidad que fue ingresado.'});
                            break;
                        default:
                            Modal.error({ content: 'No fue posible actualizar el usuario dependencia. Por favor intenta mas tarde.' });
                            break;
                    }
                }
                
            }
            return resultado;

        });

           

        
    };

    errorFormularioIncompleto = errorInfo => {
        Modal.warn({ content: "Por favor diligencia la información solicitada para el usuario" });
    };

    cancelarFormularioUsuario = () => {
        this.onSuccess(this.state.idUsuario, true);
    }

    render() {

        const { usuario, idUsuario, componente, roles, esUsuarioDadep } = this.state;


        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            { ((idUsuario === 0) || (idUsuario !== 0 && usuario !== null))
                                ?
                                <FormularioUsuarioDatoPersonal
                                    formularioIncompleto={this.errorFormularioIncompleto}
                                    formularioCompleto={this.formularioCompleto}
                                    nombreBotonSubmit={idUsuario === 0 ? 'Crear usuario' : 'Actualizar datos'}
                                    textoCargando={idUsuario === 0 ? 'Creando usuario ...' : 'Actualizando datos ...'}
                                    usuario={usuario}
                                    opcionCancelar={true}
                                    cancelarCallback={this.cancelarFormularioUsuario}
                                    camposAdicionales={()=>this.camposAdicionales(roles)}
                                    esUsuarioDadep={esUsuarioDadep}
                                />
                                :
                                <Spin tip="Por favor espera ..." size="large" spinning={true}>
                                    <div style={{ width: '100%', height: '200px' }}></div>
                                </Spin>
                            }
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:AESUD]</label>) : (<></>))
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(AgregarEditarSubUsuarioDependencia));