import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Tag, Spin, Tooltip, Button, Table } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import ServicioDatosConstantes from '../../servicios/ServicioDatosConstantes'
import ServicioUsuarios from '../../servicios/usuarios/ServicioUsuarios'
import ServicioAdministracion from '../../servicios/ServicioAdministracion'
import Constantes from '../../utilidades/Constantes'
import Utilidades from '../../utilidades/Utilidades'
import queryString from 'query-string';
import { withCookies } from 'react-cookie';


class ListarDependencias extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        cargando: true,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        entidad: undefined,
        usuario: undefined,
    };


    constructor(props) {
        super(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
    }


    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        let idEntidad = undefined;

        if (urlparametros.id !== undefined && !isNaN(parseInt(urlparametros.id))) {
            idEntidad = parseInt(urlparametros.id);
        }
        this.setSumaState({ idEntidad: idEntidad });
        
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr !== null) {
            this.setSumaState({ usuario: usr, componente: this.ENUM_COMPONENTE.VER, cargando: true });
            // Valida que tenga la accion habilitada VER
            let habilitado = await this.servicioUsuarios.accionHabilitadaPromesa(Constantes.ENUMS_MODULOS.ENTIDAD.ACCIONES.VER_ENTIDAD.id);
            if (habilitado && habilitado.resultado) {
                let entidad = await this.servicioAdministracion.obtenerEntidad( idEntidad );
                if ( entidad ) {
                    this.setSumaState({ cargando: false, usuario: usr, entidad: entidad });
                }
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }

    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }


    render() {
        const { componente, usuario, entidad } = this.state;

        const columns = [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                with:100,
            },
            {
                title: 'Dependencia',
                dataIndex: 'dependencia',
                key: 'dependencia',
            },
            {
                title: 'Sigla',
                dataIndex: 'sigla',
                key: 'sigla',
                responsive: ['sm'], /* visible en pantalla con ancho ≥ 576px*/
            },
            {
                title: 'Estado',
                key: 'estado',
                align: 'center',
                dataIndex: 'estado',
                render: (text, record) => (
                    <>
                        <Tag key={text} color={
                            (record.estadoDependencia.id === Constantes.ENUMS.ESTADO_DEPENDENCIA.ACTIVO.id)
                                ?
                                'green'
                                :
                                (record.estadoDependencia.id === Constantes.ENUMS.ESTADO_DEPENDENCIA.INACTIVO.id
                                    ?
                                    'volcano'
                                    :
                                    ''
                                )
                        }>
                            {record.estadoDependencia.estadoDependencia.toUpperCase()}
                        </Tag>
                    </>
                ),
                responsive: ['sm'], /* visible en pantalla con ancho ≥ 576px*/
            },
            {
                title: 'Opciones',
                key: 'opciones',
                align: 'center',
                className: 'columna-opciones',
                width: 112,
                render: (text, record) => (
                    <>
                        <Tooltip title="Ver dependencia">
                            <Button shape="circle" disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.VER_DEPENDENCIA.id)}
                                icon={<EyeOutlined style={{ 'color': '#c90a00' }} />}
                                onClick={(e) => { this.props.callbackVerDependencia(e, record.id) }} />
                        </Tooltip>
                    </>
                ),
            },
        ];

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>

                                <h3 className="sub-titulo-separador">Dependencias de la entidad</h3>
                                <div className="seccion-datos seccion-final">
                                    <Table  columns={columns}
                                            dataSource={ entidad && entidad.dependencias ? entidad.dependencias : []  }
                                            rowKey={"id"} />
                                </div>

                            </Spin>

                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:LSTDEP]</label>) : (<></>))
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(ListarDependencias));