import React, { Component } from 'react';
import SumaContexto from '../../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Modal, Spin, Row, Col, Form, Input, Select, Button, 
        Popconfirm } from 'antd';
import { CloseOutlined, SyncOutlined } from '@ant-design/icons';
import ServicioUsuarios from '../../../servicios/usuarios/ServicioUsuarios'
import ServicioDatosConstantes from '../../../servicios/ServicioDatosConstantes'
import ServicioAdministracion from '../../../servicios/ServicioAdministracion'
import ServicioEntidad from '../../../servicios/ServicioEntidad'
import queryString from 'query-string';
import Constantes from '../../../utilidades/Constantes';
import Utilidades from '../../../utilidades/Utilidades';




class AdministrarAplicacionConectada extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        tiposSolicitud: []
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
        this.servicioEntidad = new ServicioEntidad(props);
        this.onSuccess = props.onSuccess;
        this.textAreaUrlRef = React.createRef();
    }

    formRef = React.createRef();
    

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        let idDependencia;
        
        if (urlparametros.id !== undefined && !isNaN(parseInt(urlparametros.id))) {
            idDependencia = parseInt(urlparametros.id);
        }
        
        let usr = await this.servicioAdministracion.usuarioValido();
        if (usr !== null &&  idDependencia  ) {
            
            this.setSumaState({ idDependencia });
            
            // Se consulta la aplicación conectada y se carga
            let aplicacionConectada = await this.servicioAdministracion.obtenerAplicacionConectada( idDependencia );
            
            //console.log('aplicacionConectada',aplicacionConectada);

            this.setSumaState({componente: this.ENUM_COMPONENTE.VER, aplicacionConectada });
            if ( aplicacionConectada ) {
                this.setSumaState({ idAplicacionConectada: aplicacionConectada.id }); 
                this.formRef.current.setFieldsValue({
                    'token' :         aplicacionConectada.token,
                    'estado':         aplicacionConectada.idEstado.toString(),
                });
            } 
            
            this.setSumaState({ cargando: false }); 

        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }


    errorFormularioIncompleto = errorInfo => {
        Modal.warn({ content: "Por favor diligencia la información solicitada para la aplicación conectada" });
    };

    formularioCompleto = (values) => {
        return new Promise(resolve => {
            
            let aplicacionConectada = {
                token: values.token.trim(),
                idEstado: parseInt(values.estado) 
            };

            //console.log('aplicacionConectada',aplicacionConectada);
            resolve(aplicacionConectada);
        }).then(async ( aplicacionConectada ) => {
            
            this.setSumaState({cargando: true});

            if ( !this.state.aplicacionConectada ) {
                // Rol Nuevo
                aplicacionConectada.idDependencia = this.state.idDependencia;

                let respuestaApi = await this.servicioAdministracion.crearAplicacionConectada( aplicacionConectada );
                if (respuestaApi) {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id:
                            Modal.success({ content: 'Aplicación conectada creada satisfactoriamente.' });
                            break;
                        default:
                            Modal.error({ content: 'No fue posible crear la aplicación conectada. Por favor intenta mas tarde.' });
                            break;
                    }
                }
            } else {
                // Editar Aplicacion conectada
                aplicacionConectada.id = this.state.idAplicacionConectada;

                let respuestaApi = await this.servicioAdministracion.actualizarAplicacionConectada( aplicacionConectada );
                if (respuestaApi) {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id:
                            Modal.success({ content: 'Aplicación conectada actualizado satisfactoriamente.' });
                            break;   
                        default:
                            Modal.error({ content: 'No fue posible actualizar la aplicación conectada. Por favor intenta mas tarde.' });
                            break;
                    }
                }

            }

            this.setSumaState({cargando: false});
            
        });

    };

    cancelarFormularioDependencia = () => {
        this.onSuccess(this.state.idDependencia, this.state.idOpcDependencia, true);
    }

    guardarCursorTextArea = (event) =>{
        this.setSumaState({ cursorInicio:event.target.selectionStart, cursorFin: event.target.selectionEnd }); 
    }

    irAlaDependencia( contexto ) {
        contexto.historyLocal.push('/entidad-dependencia?id=' + contexto.state.idDependencia );
    }

    
    render() {
        const { aplicacionConectada, componente, cargando } = this.state;
        let contexto = this;
        const { Option } = Select;

        const layout = {
            labelCol: { // Este maneja el grid de los label
                sm: { span: 23, offset: 1 },
                lg: { span: 7, offset: 1 },
            },
            wrapperCol: { // Este maneja el grid de los componentes
                sm: { span: 23, offset: 1 },
                lg: { span: 15, offset: 1 },
            },
        };

        const labelPrimerCol ={ // Este maneja de forma especial el grid de las primeras columnas
                sm: { span: 23, offset: 1 },
                lg: { span: 8, offset: 0 },
        }

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            
                            <Spin tip={'por favor espera...'} size="large" spinning={cargando}>

                                <Form
                                    {...layout}
                                    ref={this.formRef}
                                    name="datos-dependencia"
                                    className="formulario-suma"
                                    scrollToFirstError
                                    onFinish={ this.formularioCompleto }
                                    onFinishFailed={ this.errorFormularioIncompleto }
                                >

                                    <Form.Item label="id" name="id" noStyle>
                                        <Input type="hidden" />
                                    </Form.Item>

                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="token" name="token"
                                                rules={[
                                                    { required: true, message: 'Ingresa el nombre del rol', },
                                                    { min: 5, message: 'No puede ser menor a 5 caracteres', },
                                                    { max: 255, message: 'No puede ser mayor a 255 caracteres', },
                                                ]}
                                                labelCol={labelPrimerCol}
                                            >
                                                <Input autoComplete="off" disabled={true} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item  label="Estado" name="estado" 
                                                        rules=  {[{ required: true, message: 'Selecciona el estado',},]}>
                                                <Select>
                                                    <Option key={1}>Activo</Option>
                                                    <Option key={2}>Inactivo</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        
                                    </Row>

                                    
                                    <Row>
                                        <Col xs={24}>
                                            <Form.Item wrapperCol={{ span: 24, offset: 0 }}>
                                                <Button type="primary"
                                                    htmlType="submit"
                                                    loading={cargando}>
                                                    Guardar
                                                </Button>
                                                &nbsp;&nbsp;
                                                <Button icon={<SyncOutlined />}
                                                    loading={cargando}
                                                    onClick={() => {
                                                        let aleatorio = Utilidades.generarAlfanumerico( 20 );
                                                        this.formRef.current.setFieldsValue({ 'token' : aleatorio });
                                                    }}
                                                    >
                                                    Generar Token
                                                </Button>
                                                {!aplicacionConectada ?
                                                <>
                                                    &nbsp;&nbsp;
                                                    <Popconfirm title={'Confirma cancelar la ' + (aplicacionConectada ? 'edición' : 'creación') + ' de la aplicación conectada?'} 
                                                                okText="Si"
                                                                cancelText="No" 
                                                                onConfirm={() => {this.irAlaDependencia(contexto)}}>
                                                        <Button icon={<CloseOutlined/>}>Cancelar</Button>
                                                    </Popconfirm>
                                                </> : null }
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Spin>
                            
                            
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:ADMAPLCON]</label>) : (<></>))
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(AdministrarAplicacionConectada));