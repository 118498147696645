import { createGlobalStyle } from 'styled-components';

// Create a `GlobalStyles` component.
// I usually already have this, to include a CSS
// reset, set border-box, and other global concerns.
const EstilosGlobales = 
{ 
    PRINCIPAL: createGlobalStyle`
        :root{
            --padding-lateral:12vw;
            --height-banner-superior:90px;
            --height-banner-menu:42px;
            --height-pie-pagina:100px;
            --height-contenedor:calc(calc(calc(100vh - var(--height-banner-superior)) - var(--height-banner-menu)) - var(--height-pie-pagina));
            
            --height-modal-solicitud-header:40px;
            --height-modal-solicitud-footer:50px;
            --height-modal-solicitud-body:calc(calc(92vh - var(--height-modal-solicitud-header)) - var(--height-modal-solicitud-footer));
        }
        
        /*Cuando el footer se reacomoda, se quita el alto en el cálculo del contenedor*/
        @media only screen and (max-width: 768px) {
            :root{
                --height-contenedor:calc(calc(calc(100vh - var(--height-banner-superior)) - var(--height-banner-menu)) - 0px);
            }
        }
    `,

    SOLICITUDES: createGlobalStyle`
        :root{
            --padding-lateral:6vw;
        }
    `,

    MIS_SOLICITUDES: createGlobalStyle`
        :root{
            --padding-lateral:8vw;
        }
    `,

    SOLICITUDES_DEPENDENCIA: createGlobalStyle`
        :root{
            --padding-lateral:1vw;
        }
    `,

    CONTENEDOR_COMPLETO: createGlobalStyle`
        :root{
            --padding-lateral:1vw;
        }
    `,

    CONTENEDOR_MEDIO: createGlobalStyle`
        :root{
            --padding-lateral:5vw;
        }
    `,
}



export default EstilosGlobales;