import { useParams } from "react-router-dom";
/**
 * Este Hook se encarga de extraer el patrón de parámetro que viene en la URL,
 * por ejemplo: 
 * http://localhost:3000/suma/ejemplo/3
 * 
 * Esta url tiene el patrón 
 * http://localhost:3000/suma/ejemplo/:idPrueba
 * 
 * Por lo tango extrae el valor 'idPrueba'
 * @param {*} Component 
 * @returns 
 */
export const HookReactRouterV6Parametros = (Component) => {
    const Wrapper = (props) => {
        let reactRouterParams = useParams();
        const { idPersonaJuridica } = useParams();
        const { idRepresentanteLegal } = useParams();
        const { idApoderado } = useParams();
        const { idUsuario } = useParams();
        
        return (
            <Component
            reactRouterParams={reactRouterParams}
                {...props}
                {...(idPersonaJuridica ? {idPersonaJuridica:idPersonaJuridica} : {})}
                {...(idRepresentanteLegal ? {idRepresentanteLegal:idRepresentanteLegal} : {})}
                {...(idApoderado ? {idApoderado:idApoderado} : {})}
                {...(idUsuario ? {idUsuario:idUsuario} : {})}
            />
        );
    };

    return Wrapper;
};