import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter, Switch, Route } from "react-router-dom";
import { IdcardOutlined } from '@ant-design/icons';
import { Tabs, Spin } from 'antd';
import ServicioEntidad from '../servicios/ServicioEntidad'
import { withCookies } from 'react-cookie';
import InformacionDependencia from '../dependencia/InformacionDependencia'
import InformacionEntidad from '../entidad/InformacionEntidad'
import Constantes from '../utilidades/Constantes'
import SubUsuariosDependencia from './sub-usuarios/SubUsuariosDependencia'
import AplicacionConectada from './aplicacion-conectada/AplicacionConectada'
import OpcionesSuma from './opciones-suma/OpcionesSuma';
import RolesDependencia from './roles/RolesDependencia';



const { TabPane } = Tabs;

class DatosDependencia extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    static PATHS_TABS = {
        ENTIDAD:{
            pestana: '/dependencia-entidad',
            paths: ['/dependencia-entidad']
        },
        DATOS: {
            pestana: '/dependencia-datos',
            paths: ['/dependencia-datos']
        },
        USUARIOS: {
            pestana: '/dependencia-usuarios',
            paths: [
                '/dependencia-usuarios',
                '/dependencia-ver-usuario',
                '/dependencia-agregar-usuario',
                '/dependencia-editar-usuario'
            ]
        },
        ROLES: {
            pestana: RolesDependencia.PATHS.DEPENDENCIA_ROLES_SUMA,
            paths: [
                RolesDependencia.PATHS.DEPENDENCIA_ROLES_SUMA,
                RolesDependencia.PATHS.DEPENDENCIA_ROL_SUMA,
            ]
        },
        OPCIONES_DEPENDENCIA: {
            pestana: OpcionesSuma.PATHS.DEPENDENCIA_OPCION_SUMA,
            paths: [
                OpcionesSuma.PATHS.DEPENDENCIA_OPCION_SUMA,
                OpcionesSuma.PATHS.DEPENDENCIA_SELECCION_OPC_TIPO,
                OpcionesSuma.PATHS.DEPENDENCIA_OPC_TIPO_SOLICITUD,
                OpcionesSuma.PATHS.DEPENDENCIA_OPC_TIPO_SUMA,
                OpcionesSuma.PATHS.DEPENDENCIA_OPC_TIPO_REGISTRAR_SOLICITUD,
            ]
        },
        APLICACION_CONECTADA : {
            pestana: '/aplicacion-conectada',
            paths: ['/aplicacion-conectada']
        }
    };

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        tab: DatosDependencia.PATHS_TABS.DATOS.pestana,
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioEntidad = new ServicioEntidad(props);
        // Se extrae el PATH inicial de la URL
        this.pathInicial = props.history.location.pathname;
        this.props.history.listen(this.manejadorCambioPath);
    }

    componentDidMount() {
        this._isMounted = true;
        this.setSumaState({ tab: this.obtenerPestanaPorPath(this.pathInicial) });
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        // Validando que el usuario sea de tipo ENTIDAD
        let usr = await this.servicioEntidad.usuarioValido();
        if( usr != null ){
            let dependenciaAPI = await this.servicioEntidad.obtenerDependenciaPromise(usr.usuarioDependencia.dependencia.id,"[DatosDependencia]");
            if( dependenciaAPI.codigo === Constantes.ENUMS_API.ENTIDAD.DATOS_RETORNADOS.id ){
                usr.usuarioDependencia.dependencia = dependenciaAPI.resultado;
                let entidad = usr.usuarioDependencia.dependencia.entidad;
                this.setState({usuario: usr, entidad:entidad ,componente: this.ENUM_COMPONENTE.VER});
            }
        }else{
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }

    manejadorCambioPath = (location, action) => {
        this.setSumaState({ tab: this.obtenerPestanaPorPath(location.pathname) });
    }


    obtenerPestanaPorPath = (path) => {
        let pestanas = Object.keys(DatosDependencia.PATHS_TABS);
        for (let index in pestanas) {
            let paths_tab = DatosDependencia.PATHS_TABS[pestanas[index]];
            for (let index02 in paths_tab.paths) {
                let path_tab = paths_tab.paths[index02];
                if (path.includes(path_tab)) {
                    return paths_tab.pestana;
                }
            }
        }
    }

    manejadorClickTab = e => {
        // Se deja el tab en null para obligar a refrescar el componente hijo
        this.setSumaState({ tab: null });
        // Con el tab seleccionado tambien se cambia la URL, se deja dentro del setTimeout con el
        // fin de obligar a refrescar el componente hijo
        setTimeout(() => { this.historyLocal.push(e); }, 100);
    };

    render() {

        const { componente, tab, entidad } = this.state;

        return (
            <div className="seccion-principal contenedor-completo seccion-flex ">

                <div className="division-principal">
                    <label>Mis sitios / Datos de la dependencia</label>
                </div>
                <div className="division-contenido seccion-flex-grow-1 padding-principal bkg-white">

                    {(componente === this.ENUM_COMPONENTE.VER) ?
                        (
                            <>

                                <div className="titulo-seccion">
                                    <label> <IdcardOutlined /> DATOS DE LA DEPENDENCIA</label>
                                    <div className="separador-horizontal-completo"></div>
                                </div>

                                <div>
                                    <Tabs activeKey={this.state.tab} onChange={this.manejadorClickTab}>
                                        <TabPane tab="Entidad" key={DatosDependencia.PATHS_TABS.ENTIDAD.pestana} />
                                        <TabPane tab="Datos" key={DatosDependencia.PATHS_TABS.DATOS.pestana} />
                                        <TabPane tab="Usuarios" key={DatosDependencia.PATHS_TABS.USUARIOS.pestana} />
                                        <TabPane tab="Roles" key={DatosDependencia.PATHS_TABS.ROLES.pestana} />
                                        <TabPane tab="Opciones dependencia" key={DatosDependencia.PATHS_TABS.OPCIONES_DEPENDENCIA.pestana} />
                                        <TabPane tab="Aplicación conectada" key={DatosDependencia.PATHS_TABS.APLICACION_CONECTADA.pestana} />
                                    </Tabs>

                                    <Switch>
                                        <Route path={DatosDependencia.PATHS_TABS.ENTIDAD.paths}>
                                            {tab !== null ? <InformacionEntidad entidad={entidad} /> : <></>}
                                        </Route>
                                        <Route path={DatosDependencia.PATHS_TABS.DATOS.paths}>
                                            {tab !== null ? <InformacionDependencia /> : <></>}
                                        </Route>
                                        <Route path={DatosDependencia.PATHS_TABS.USUARIOS.paths}>
                                            {tab !== null ? <SubUsuariosDependencia /> : <></>}
                                        </Route>
                                        <Route path={DatosDependencia.PATHS_TABS.ROLES.paths}>
                                            {tab !== null ? <RolesDependencia /> : <></>}
                                        </Route>
                                        <Route path={DatosDependencia.PATHS_TABS.OPCIONES_DEPENDENCIA.paths}>
                                            {tab !== null ? <OpcionesSuma /> : <></>}
                                        </Route>
                                        <Route path={DatosDependencia.PATHS_TABS.APLICACION_CONECTADA.paths}>
                                            {tab !== null ? <AplicacionConectada /> : <></>}
                                        </Route>
                                    </Switch>
                                </div>


                            </>
                        )
                        :
                        (
                            (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                                (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                                : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:DD]</label>) : (<></>))
                        )
                    }
                </div>
            </div>
        );
    }

}

export default withRouter(withCookies(DatosDependencia));