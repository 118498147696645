import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { CookiesProvider } from 'react-cookie'
import { BrowserRouter as Router } from "react-router-dom";
import {SumaProvider} from './contexto/SumaContexto'

ReactDOM.render(
  <CookiesProvider>
    <SumaProvider>
      <Router>
        <App />
      </Router>
    </SumaProvider>
  </CookiesProvider>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
