import React, { Component } from 'react';

import Principal from '../principal/Principal'


class Contenedor extends Component {


  render() {
    return (
      <div className="contenedor padding-laterales">
          <Principal  usuario={this.props.usuario} 
                      callbackUsuarioLogueado={this.props.callbackUsuarioLogueado}
                      cerrarSesionUsuario={this.props.cerrarSesionUsuario}/>
      </div>
    );
  }
}


export default Contenedor;