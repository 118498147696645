import React, { Component  } from 'react';
import SumaContexto  from '../contexto/SumaContexto';
import { withRouter, Switch , Route } from "react-router-dom";
import {  UserOutlined } from '@ant-design/icons';
import { Tabs, Spin, Modal } from 'antd';
import { withCookies } from 'react-cookie';
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import EditarInformacionBasica from './EditarInformacionBasica'
import VerInformacionBasica from './VerInformacionBasica'
import Seguridad from './Seguridad'


const { TabPane } = Tabs;

class MiCuenta extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE ={
        CARGANDO:1,
        VER:2,
        NO_DISPONIBLE:3
    }

    PATHS = {
        SEGURIDAD :     '/mi-cuenta-seguridad',
        EDITAR_INFO :   '/mi-cuenta-editar-informacion',
        VER_INFO :      '/mi-cuenta-informacion-basica',
        INICIO :        '/mi-cuenta-inicio'
    }

    PATHS_TABS = {
        INFO:{ 
            pestana: '/mi-cuenta-informacion-basica',
            paths:  [   '/mi-cuenta-inicio',
                        '/mi-cuenta-informacion-basica',
                        '/mi-cuenta-editar-informacion'
                    ]
        },
        SEGURIDAD:{ 
            pestana: '/mi-cuenta-seguridad',
            paths:  ['/mi-cuenta-seguridad',]
        },
    };

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        tab:this.PATHS_TABS.INFO.pestana,
    };

    constructor(props){
        super(props);
        this.historyLocal = props.history;
        this.servicioUsuarios = new ServicioUsuarios( props );
        // Se extrae el PATH inicial de la URL
        this.pathInicial = props.history.location.pathname;
        this.props.history.listen( this.manejadorCambioPath );
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ tab:this.obtenerPestanaPorPath(this.pathInicial) });
        this.cargarDatosIniciales();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales(){
        // Validando que el usuario esté en sesion
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        this.setState({ componente:(usr !== null ? this.ENUM_COMPONENTE.VER : this.ENUM_COMPONENTE.NO_DISPONIBLE)});
    }

    manejadorCambioPath = (location, action) =>{
        if( this._isMounted ){
            this.setState({ tab: this.obtenerPestanaPorPath(location.pathname) });
        }
    }

    
    obtenerPestanaPorPath = (path) => {
        let pestanas = Object.keys(this.PATHS_TABS);
        for( let index in pestanas){
            let paths_tab = this.PATHS_TABS[ pestanas[index] ];

            for( let index02 in paths_tab.paths){
                let path_tab = paths_tab.paths[index02];
                if(path.includes( path_tab )){
                    return paths_tab.pestana;
                }
            }
        }
    }

    manejadorClickTab = e => {
        // Se deja el tab en null para obligar a refrescar el componente hijo
        this.setState({ tab: null });

        // Con el tab seleccionado tambien se cambia la URL, se deja dentro del setTimeout con el
        // fin de obligar a refrescar el componente hijo
        setTimeout(()=>{this.historyLocal.push( e );},100);
    };

    clickEditarInformacionBasica = ( e, idUsuario ) => {
        this.historyLocal.push( this.PATHS.EDITAR_INFO );
    }

    informacionBasicaGuardada( cancelar ){
        if( typeof cancelar === 'boolean' && cancelar ){
            this.historyLocal.push( this.PATHS.VER_INFO );
            return;
        }
        // Se agrega el parámetro 'cargar=1' con el fin 
        // de obligar a recargar el ContenedorApp y así
        // actualizar la info del usuario
        this.historyLocal.push( this.PATHS.VER_INFO + "?cargar=1" );
        Modal.success({ content: "Información básica actualizada" });
    }

    render() {
      
      const { componente, tab } = this.state;
      
        return (
          <div className="seccion-principal contenedor-completo seccion-flex ">
            <div className="division-principal">
              <label>Mi cuenta</label>
            </div>
            <div className="division-contenido seccion-flex-grow-1 padding-principal bkg-white">
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <div className="titulo-seccion">
                                <label> <UserOutlined /> MI CUENTA</label>
                                <div className="separador-horizontal-completo"></div>
                            </div>
                            <div>
                                <Tabs activeKey={this.state.tab} onChange={this.manejadorClickTab}>
                                    <TabPane tab="Información básica"   key={this.PATHS_TABS.INFO.pestana} />
                                    <TabPane tab="Seguridad"            key={this.PATHS_TABS.SEGURIDAD.pestana} />
                                </Tabs>

                                <Switch>
                                    <Route path={[ this.PATHS.INICIO, this.PATHS.VER_INFO ]}>
                                        { tab !== null  ? <VerInformacionBasica callbackEditarInformacionBasica={ this.clickEditarInformacionBasica }/> : <></> }
                                    </Route>
                                    <Route path={this.PATHS.EDITAR_INFO}>
                                        { tab !== null  ? <EditarInformacionBasica onSuccess= {( cancelar ) => {this.informacionBasicaGuardada( cancelar );}}/> : <></> }
                                    </Route>
                                    <Route path={this.PATHS.SEGURIDAD}>
                                        { tab !== null  ? <Seguridad /> : <></> }
                                    </Route>
                                </Switch>
                            </div>
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                        (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                        :((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:MC]</label>) : (<></>))
                    )
                }
              </div>
          </div>
        );
    }
}
export default withRouter(withCookies(MiCuenta));