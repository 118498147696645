import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Button, Row, Col, Modal, Spin, Tag } from 'antd';
import Constantes from '../utilidades/Constantes'
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import ServicioSolicitud from '../servicios/ServicioSolicitud'
import Utilidades from '../utilidades/Utilidades'



class VerSolicitud extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        cargando: true,
        solicitud: null,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        usuario: null,
    };

    constructor(props) {
        super(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioSolicitud = new ServicioSolicitud(props);
        this.codigoSolicitud = props.codigoSolicitud;
        this.callbackVerSolicitud = props.callbackVerSolicitud;
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        if (this.codigoSolicitud !== null && this.codigoSolicitud !== 0) {
            // Validando que el usuario este en sesión
            this.setSumaState({ cargando: true });
            let usr = await this.servicioUsuarios.usuarioEnSesion();
            if (usr !== null) {
                let solicitud = await this.obtenerSolicitud();
                //console.log('solicitud', solicitud);
                if (solicitud !== null) {
                    this.setSumaState({
                        componente: this.ENUM_COMPONENTE.VER,
                        usuario: usr,
                        cargando: false,
                        solicitud: solicitud,
                    });
                }

            } else {
                this.setSumaState({
                    cargando: false,
                    componente: this.ENUM_COMPONENTE.NO_DISPONIBLE
                });
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    obtenerSolicitud() {
        return new Promise(resolve => {
            this.servicioSolicitud.obtenerSolicitud(
                (respuestaApi) => {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.SOLICITUDES.DATOS_RETORNADOS.id:
                            resolve(respuestaApi.resultado);
                            break;
                        default:
                            Modal.error({ title: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VRS02]" });
                            resolve(null);
                            break;
                    }
                },
                (errorAPI) => {
                    Modal.error({ title: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VRS01]" });
                    resolve(null);
                },
                this.codigoSolicitud
            );
        });
    }


    render() {
        //const { usuario } = this.context;
        const { solicitud, componente } = this.state;
        let contexto = this;

        const wrapperCol = {
            xs: { span: 22, offset: 0 },
            sm: { span: 12, offset: 0 },
        };

        return (
            <Modal title={"Solicitud Código No. " + (solicitud !== null ? solicitud.codigoSolicitud : "")}
                visible={true}
                className="modal-solicitud"
                closable={true}
                destroyOnClose={true}
                centered
                footer={[
                    <Button key="submit" type="primary" onClick={(e) => { this.callbackVerSolicitud(e, null) }}>
                        Ok
                    </Button>
                ]}
                onCancel={(e) => { this.callbackVerSolicitud(e, null) }} >

                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>

                                {(solicitud !== null) ?
                                    <>
                                        <div className="encabezado-ver-solicitud">
                                            <img src={`../imagenes/iconos/tipo-solicitud/${solicitud.tipoSolicitud.icono}`} width="60" height="60" alt="" />
                                            <div className="texto-encabezado">
                                                <label><strong>Tipo de actividad: </strong>{solicitud.tipoSolicitud.solicitud}</label>
                                                <label><strong>Dependencia: </strong>{solicitud.tipoSolicitud.dependencia.dependencia}</label>
                                                <label>
                                                    <strong>Entidad: </strong>
                                                    {solicitud.tipoSolicitud.dependencia.entidad.razonSocial}
                                                    {
                                                        solicitud.tipoSolicitud.dependencia.entidad.sigla !== undefined ?
                                                            (' - ' + solicitud.tipoSolicitud.dependencia.entidad.sigla) : ''
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="seccion-datos">
                                            <Row className="margen-bottom-2">
                                                <Col  {...wrapperCol}>
                                                    <Row>
                                                        <Col sm={8}>
                                                            <label className="titulo-etiqueta">Fecha de registro:</label>
                                                        </Col>
                                                        <Col sm={16}>
                                                            <span>{solicitud.fechaRegistroFormato}</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col  {...wrapperCol}>
                                                    <Row>
                                                        <Col sm={9}>
                                                            <label className="titulo-etiqueta">Fecha de modificación:</label>
                                                        </Col>
                                                        <Col sm={15}>
                                                            <span>{solicitud.fechaModificoFormato}</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            {/*<Row className="margen-bottom-2">
                                                <Col span={6}>
                                                    <label className="titulo-etiqueta">Tipo de actividad:</label>
                                                </Col>
                                                <Col span={18}>
                                                    <span>{solicitud.tipoSolicitud.solicitud}</span>
                                                </Col>
                                            </Row>*/}
                                            <Row className="margen-bottom-2">
                                                <Col  {...wrapperCol}>
                                                    <Row>
                                                        <Col sm={8}>
                                                            <label className="titulo-etiqueta">Estado solicitud:</label>
                                                        </Col>
                                                        <Col sm={16}>
                                                            <span>{solicitud.estadoSolicitud.estadoSolicitud}</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col  {...wrapperCol}>
                                                    <Row>
                                                        <Col sm={9}>
                                                            <label className="titulo-etiqueta">Estado dependencia:</label>
                                                        </Col>
                                                        <Col sm={15}>
                                                            <span>{solicitud.solicitudDependencia.estadoDependencia}</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="margen-bottom-2">
                                                <Col  {...wrapperCol}>
                                                    <Row>
                                                        <Col sm={8}>
                                                            <label className="titulo-etiqueta">Código solicitud:</label>
                                                        </Col>
                                                        <Col sm={16}>
                                                            <span>{solicitud.codigoSolicitud}</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col  {...wrapperCol}>
                                                    <Row>
                                                        <Col sm={9}>
                                                            <label className="titulo-etiqueta">Código dependencia:</label>
                                                        </Col>
                                                        <Col sm={15}>
                                                            <span>{solicitud.solicitudDependencia.codigoSolicitudDependencia}</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>

                                            <Row className="margen-bottom-2">
                                                <Col  {...wrapperCol}>
                                                    <Row>
                                                        <Col sm={8}>
                                                            <label className="titulo-etiqueta">Localidad:</label>
                                                        </Col>
                                                        <Col sm={16}>
                                                            <span>{solicitud.localidad ? solicitud.localidad.localidad : ''}</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>

                                            <label className="titulo-etiqueta titulo-separador titulo-ver-solicitud">Fechas ocupación:</label>
                                            {solicitud.fechasOcupacion.map((fechaOcupacion, index) =>
                                                <Row className="margen-bottom-2" key={index} >
                                                    <Col span={12}>
                                                        <span style={{ 'color': fechaOcupacion.opcional ? '#f7c034' : '' }}>
                                                            {fechaOcupacion.fechaIncialFormato}
                                                        </span>
                                                    </Col>
                                                    <Col span={12}>
                                                        <span style={{ 'color': fechaOcupacion.opcional ? '#f7c034' : '' }}>
                                                            {fechaOcupacion.fechaFinalFormato}{fechaOcupacion.opcional ? ' (Opcional)' : ''}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}

                                            <label className="titulo-etiqueta titulo-separador titulo-ver-solicitud">Direcciones de ocupación:</label>
                                            {solicitud.direccionesSolicitud.map((direccionSolicitud, index) =>
                                                <Row className="margen-bottom-12" key={index} >
                                                    <Col span={24}>
                                                        <div>
                                                            <label className="titulo-etiqueta">Dirección: </label>
                                                            <span className="lineh16">{direccionSolicitud.direccionCompleta}
                                                                {direccionSolicitud.direccionRango.length > 0 ?
                                                                    <> hasta {direccionSolicitud.direccionRango}</> :
                                                                    <></>
                                                                }
                                                            </span>
                                                        </div>
                                                        {direccionSolicitud.tipoEspacioPublico ?
                                                            <div>
                                                                <label className="titulo-etiqueta">Tipo de espacio público: </label>
                                                                <span>{direccionSolicitud.tipoEspacioPublico.tipoEspacioPublico}</span>
                                                            </div> : null}
                                                        {direccionSolicitud.predioPublico ?
                                                            <div>
                                                                <label className="titulo-etiqueta">Predio público: </label>
                                                                <span>{direccionSolicitud.predioPublico.predioPublico}</span>
                                                                {direccionSolicitud.predioPublico.predioTiposEspaciosPublicos ? 
                                                                    <div>
                                                                        <label className="titulo-etiqueta">Tipos de espacios públicos: </label>
                                                                        {direccionSolicitud.predioPublico.predioTiposEspaciosPublicos.map((tipoEspacioPublico, index02) =>
                                                                            <React.Fragment key={index02}>
                                                                                {direccionSolicitud.predioPublico.predioTiposEspaciosPublicos.length > 1 ? <br/> : null}
                                                                                <span>{tipoEspacioPublico.tipoEspacioPublico.tipoEspacioPublico}</span>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </div>
                                                                : null}
                                                            </div> : null}
                                                    </Col>
                                                    {/*<Col span={12}>
                                                        <span className="span-bloque lineh16">{direccionSolicitud.espacioPublico.conjunto} - {direccionSolicitud.espacioPublico.espacio_publico}</span>
                                                    </Col>
                                                    <Col span={12}>
                                                        <span className="span-bloque lineh16">{direccionSolicitud.direccionCompleta}
                                                            {direccionSolicitud.direccionRango.length > 0 ?
                                                                <><br />hasta {direccionSolicitud.direccionRango}</> :
                                                                <></>
                                                            }
                                                        </span>
                                                    </Col>*/}
                                                </Row>
                                            )}

                                            <label className="titulo-etiqueta titulo-separador titulo-ver-solicitud">Detalles adicionales:</label>
                                            <Row className="margen-bottom-2">
                                                <Col span={24}>
                                                    <span>{Utilidades.interpretadorHTML(solicitud.solicitudDependencia.detalles)}</span>
                                                </Col>
                                            </Row>

                                            {(solicitud.etiquetas && solicitud.etiquetas.length > 0) ?
                                                (<>
                                                    <label className="titulo-etiqueta titulo-separador titulo-ver-solicitud">Etiquetas:</label>
                                                    <Row className="margen-bottom-12">
                                                        <Col span={12}>
                                                            {solicitud.etiquetas.map((etiqueta, index) =>
                                                                <Tag key={index} color={etiqueta.color}>
                                                                    {etiqueta.etiqueta}
                                                                </Tag>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </>)
                                                : (<></>)}
                                        </div>

                                        {(solicitud.opcionesDependenciaSolicitud && solicitud.opcionesDependenciaSolicitud.length > 0) ?
                                            (<>
                                                <div className="seccion-datos">
                                                    <label className="titulo-etiqueta titulo-separador titulo-ver-solicitud">Opciones:</label>
                                                    {solicitud.opcionesDependenciaSolicitud.map((opcionDependenciaSolicitud, index) =>
                                                        opcionDependenciaSolicitud.opcionDependencia ? (
                                                            <Button className="boton-opcion"
                                                                key={index}
                                                                icon={opcionDependenciaSolicitud.opcionDependencia.icono !== undefined
                                                                    ?
                                                                    <Utilidades.IconoAntDesign type={opcionDependenciaSolicitud.opcionDependencia.icono}
                                                                        style={{ 'color': opcionDependenciaSolicitud.opcionDependencia.color }} />
                                                                    : <></>
                                                                }
                                                                style={{ 'borderColor': opcionDependenciaSolicitud.opcionDependencia.color, color: opcionDependenciaSolicitud.opcionDependencia.color }}
                                                                onClick={(e) => {
                                                                    Utilidades.onClickRedireccionOpcionDependencia(contexto.servicioSolicitud, solicitud.id, opcionDependenciaSolicitud.opcionDependencia.id);
                                                                }}>
                                                                {opcionDependenciaSolicitud.opcionDependencia.opcionDependencia}
                                                            </Button>
                                                        ) : (<React.Fragment key={index}></React.Fragment>)
                                                    )}
                                                </div>
                                            </>)
                                            : (<></>)}
                                    </>
                                    :
                                    <></>
                                }
                            </Spin>
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:VRS]</label>) : (<></>))
                    )
                }
            </Modal>
        );
    }
}

export default withRouter(withCookies(VerSolicitud));