import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Spin, Modal } from 'antd';
import ServicioUsuarios from '../../servicios/usuarios/ServicioUsuarios'
import { withCookies } from 'react-cookie';
import Contrasena from '../crear-cuenta/Contrasena'

class CambioContrasenaTemporal extends Component {

  static contextType = SumaContexto;

  state = {
    cargando: true,
    usuario: null,
    opcionesSUMA: []
  };

  constructor(props) {
    super(props);
    this.servicioUsuarios = new ServicioUsuarios(props);
    const { cookies } = props;
    this.cookies = cookies;
    this.token = this.cookies.get('sumajwt');
    this.callbackCambioContrasenaTemporalSatisfactorio = props.callbackCambioContrasenaTemporalSatisfactorio;
  }

  componentDidMount() {
    this._isMounted = true;
    this.cargarDatosIniciales();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setSumaState(valores) {
    if (this._isMounted) {
      this.setState(valores);
    }
  }

  // -----------------------------------------------------------------------
  // --- CARGA DATOS INICIALES ---------------------------------------------
  // -----------------------------------------------------------------------
  async cargarDatosIniciales() {
    let usr = await this.obtenerUsuarioLogeado();
    if (usr !== null) {
      this.setSumaState({ usuario: usr, cargando: (usr === null) });
    }
  }

  obtenerUsuarioLogeado() {
    return new Promise(resolve => {
      this.servicioUsuarios.obtenerUsuarioLogeado(
        (dataAPI) => {
          resolve(dataAPI);
        },
        (errorAPI) => {
          Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:CCT01]" });
          resolve(null);
        },
      );
    });
  }

  cambioContrasenaSatisfactorio = () => { 
    this.callbackCambioContrasenaTemporalSatisfactorio(); 
  };

  render() {

    const { usuario } = this.state;
    const { history } = this.props;
    this.historyLocal = history;


    return (

      <div className="seccion-principal contenedor-completo seccion-flex">{/* seccion-principal */}

        <div className="division-principal">
            <label>Asignar nueva contraseña</label>
        </div>
        <div className="division-contenido seccion-flex-grow-1 padding-principal bkg-white">

          <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>
            <div className="titulo-seccion">
              <label>ASIGNAR NUEVA CONTRASEÑA</label>
              <div className="separador-horizontal-completo"></div>
            </div>

            <div className="">

              {(usuario !== null) ?
                (
                    <>
                        <p className="descripcion-seccion">Debes crear una nueva contraseña con la cual podrás ingresar al portal. </p>
                        
                        Debe ser mínimo de 8 caracteres y tener al menos:
                        <ul>
                            <li>Una letra mayúscula</li>
                            <li>Una letra minúscula</li>
                            <li>Un dígito</li>
                            <li>Alguno de los siguientes caracteres: ! @ # $ % ^ & *</li>
                        </ul>
                        
                        <br/>
                    
                    <div className="seccion-formulario">
                        <Contrasena idUsuario={usuario.id} 
                                    token={this.token} 
                                    callbackSatisfactorioContrasena={this.cambioContrasenaSatisfactorio}
                                    />
                    </div>
                    </>
                )
                : (<></>)}

            </div>
          </Spin>
        </div>
      </div>

    );
  }
}

export default withRouter(withCookies(CambioContrasenaTemporal));