import React, { Component } from 'react';
import SumaContexto  from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Form, Button, Checkbox, Modal } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import Constantes from '../../utilidades/Constantes'
import Utilidades from '../../utilidades/Utilidades';
import ServicioPersonaJuridica from '../../servicios/usuarios/ServicioPersonaJuridica'
import ServicioPersonaNatural  from '../../servicios/usuarios/ServicioPersonaNatural'
import FormularioUsuarioDatoPersonal from '../usuario-general/FormularioUsuarioDatoPersonal'



class CrearUsuario extends Component {

    static contextType = SumaContexto;
    
    state = {
        mostrarModal: false,
        correoElectronico: null,
    };

    constructor(props){
        super(props);
        this.personaNatural = props.personaNatural !== undefined ? props.personaNatural : false;
        this.servicioPersonaJuridica = new ServicioPersonaJuridica ( props );
        this.servicioPersonaNatural = new ServicioPersonaNatural( props );
    }
    componentDidMount() {
        this._isMounted = true;
        // -----------------------------------------------------------------------
        // --- CARGA DATOS INICIALES ---------------------------------------------
        // -----------------------------------------------------------------------
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    handleCerrarModal = e => {
        this.setSumaState({ mostrarModal: false, });
    };

    


    render() {
      //const { usuario } = this.context
      
      const formularioCompleto = values => {
        return new Promise(resolve => {
            let resultado = { cargando: false, limpiarFormulario: false };

            let usr = {
                usuario:                    values.correoElectronico,
                aceptoTerminoscondiciones:  values.terminosYCondiciones,
                datoPersonal:{
                    primerNombre:               values.primerNombre,
                    segundoNombre:              values.segundoNombre,
                    primerApellido:             values.primerApellido,
                    segundoApellido:            values.segundoApellido,
                    correoElectronico:          values.correoElectronico.toLowerCase(),
                    numeroDocumento:            values.numeroDocumento,
                    lugarExpedicion:            values.lugarExpedicion,
                    fechaExpedicionDocumento:   values.fechaExpedicion.format('DD/MM/YYYY'),
                    direccion:                  values.direccion,
                    celular:                    values.movil,
                    telefono:                   values.telefono,
                    ciudad:{
                            id: parseInt(values.ciudad)
                    },
                    tipoDocumento:{
                            id: parseInt(values.tipoDocumento)
                    },
                }
            };

            if( this.personaNatural ){
                usr.personaNatural = { 
                    rut:values.rut, 
                    digitoVerificacion:values.digitoVerificacion
                };

                this.servicioPersonaNatural.crearUsuario(
                    ( respuestaApi )   =>  {  
                        switch(respuestaApi.codigo){
                            case Constantes.ENUMS_API.USUARIO.USUARIO_CREADO.id:
                                resultado.limpiarFormulario = true;
                                this.setSumaState({ correoElectronico:values.correoElectronico, mostrarModal: true, });
                                break;
                            case Constantes.ENUMS_API.USUARIO.CORREO_NO_DISPONIBLE.id:
                                resultado.satisfactorio = true;
                                Modal.error({content:'El correo electrónico ya se encuentra registrado. Por favor intenta con uno diferente o ve a la opción recuperar contraseña.'});
                                break;
                            case Constantes.ENUMS_API.USUARIO.DOCUMENTO_IDENTIDAD_NO_DISPONIBLE.id:
                                resultado.satisfactorio = true;
                                Modal.error({content:'No fue posible crear el usuario. Ya se encuentra registrado el número de documento de identidad que fue ingresado.'});
                                break;
                            case Constantes.ENUMS_API.USUARIO.ERROR_SERVICIO_CORREO_ELECTRONICO.id:
                            case Constantes.ENUMS_API.USUARIO.DOMINIO_DEL_DADEP.id: 
                                resultado.satisfactorio = true;
                                Modal.error({content:respuestaApi.mensaje});
                                break;    
                            case Constantes.ENUMS_API.USUARIO.USUARIO_NO_CREADO.id:
                            default:
                                Modal.error({content:'No fue posible crear el usuario. Por favor intenta mas tarde. [Cod:CU01]'});
                                break;
                        }
                        resolve(resultado);
                    },
                    ( errorAPI ) => { Modal.error({content:'Error de conexión. Por favor intenta mas tarde. [Cod:CU02]'});resolve(resultado);},
                    usr
                );
            }
            else{
                this.servicioPersonaJuridica.crearUsuario(
                    ( respuestaApi )   =>  {  
                        switch(respuestaApi.codigo){
                            case Constantes.ENUMS_API.USUARIO.USUARIO_CREADO.id:
                                resultado.limpiarFormulario = true;
                                this.setSumaState({ correoElectronico:values.correoElectronico, mostrarModal: true, });
                                break;
                            case Constantes.ENUMS_API.USUARIO.CORREO_NO_DISPONIBLE.id:
                                resultado.satisfactorio = true;
                                Modal.error({content:'El correo electrónico ya se encuentra registrado. Por favor intenta con uno diferente o ve a la opción recuperar contraseña.'});
                                break;
                            case Constantes.ENUMS_API.USUARIO.DOCUMENTO_IDENTIDAD_NO_DISPONIBLE.id:
                                resultado.satisfactorio = true;
                                Modal.error({content:'No fue posible crear el usuario. Ya se encuentra registrado el número de documento de identidad que fue ingresado.'});
                                break;
                            case Constantes.ENUMS_API.USUARIO.ERROR_SERVICIO_CORREO_ELECTRONICO.id:
                            case Constantes.ENUMS_API.USUARIO.DOMINIO_DEL_DADEP.id:    
                                resultado.satisfactorio = true;
                                Modal.error({content:respuestaApi.mensaje});
                                break;    
                            case Constantes.ENUMS_API.USUARIO.USUARIO_NO_CREADO.id:
                            default:
                                Modal.error({content:'No fue posible crear el usuario. Por favor intenta mas tarde. [Cod:CU03]'});
                                break;
                        }
                        resolve(resultado);
                    },
                    ( errorAPI ) => { Modal.error({content:'Error de conexión. Por favor intenta mas tarde. [Cod:CU04]'});resolve(resultado);},
                    usr
                );
            }
        });
      };
    
      const errorFormularioIncompleto = errorInfo => {
        Modal.warning({content:'Por favor diligencia la información solicitada para crear tu cuenta'});
      };

      const itemsAdicionales = () => {
          return (
          <>
            <Form.Item  name="terminosYCondiciones" 
                        valuePropName="checked" 
                        rules={[
                            {
                              validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject(new Error('Debes aceptar los términos y condiciones')),
                            },]}>
                <Checkbox>
                    Acepto los <a href={Utilidades.extraerEnlace(Constantes.ENUMS.PARAMETRO_GENERAL.ENLACE_TERMINOS_Y_CONDICIONES.id)}>términos y condiciones</a> y la 
                    <a href={Utilidades.extraerEnlace(Constantes.ENUMS.PARAMETRO_GENERAL.ENLACE_PROTECCION_DATOS_PERSONALES.id)}> política de protección de datos personales</a>
                </Checkbox>
            </Form.Item>
          </>
          )
      }
  
        return (
          <div>    
                <Modal  title="Importante" closable={false} className="modal-suma icono-con-texto"
                        visible={this.state.mostrarModal} 
                        footer={[
                            <Button key="cerrar" type="primary" onClick={this.handleCerrarModal} >
                              Cerrar
                            </Button>
                        ]}
                >
                    <MailOutlined />
                    <p>
                        Un mail acaba de ser enviado a tu cuenta de correo {this.state.correoElectronico}. <br/>
                        Por favor revisa tu bandeja de entrada para activar tu usuario.
                    </p>
                </Modal>
                <FormularioUsuarioDatoPersonal 
                                formularioIncompleto={ errorFormularioIncompleto } 
                                formularioCompleto={ formularioCompleto }
                                itemsAdicionales = { itemsAdicionales }
                                nombreBotonSubmit = 'Crear cuenta'
                                textoCargando = 'Creando cuenta ...'
                                personaNatural = {this.personaNatural}
                />
          </div>
        );
    }
}
          
export default withRouter(CrearUsuario);