import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter, Switch, Route } from "react-router-dom";
import { PlusOutlined, TeamOutlined, ContactsOutlined, BankOutlined } from '@ant-design/icons';
import { Spin, Button, Modal, Tabs } from 'antd';
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import ServicioAdministracion from '../servicios/ServicioAdministracion'
import { withCookies } from 'react-cookie';
import Constantes from '../utilidades/Constantes'
import Utilidades from '../utilidades/Utilidades';
import ListarUsuarios from './ListarUsuarios'
import TipoUsuario from './TipoUsuario'
import AgregarEditarUsuario from './AgregarEditarUsuario'
import VerUsuario from './VerUsuario'
import ListarRoles from './roles/ListarRoles';
import AdministrarRol from './roles/AdministrarRol'
import ListarPersonasJuridicas from './personas-juridicas/ListarPersonasJuridicas';
import PersonaJuridica from './personas-juridicas/PersonaJuridica';
import { HookReactRouterV6Parametros } from '../general/hooks/HookReactRouterV6Parametros';

const { TabPane } = Tabs;

class Usuarios extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    PATHS = {
        USUARIOS: '/usuarios',
        ROLES: '/roles',

        PERSONAS_JURIDICAS: '/personas-juridicas',
        PERSONA_JURIDICA: '/persona-juridica/:idPersonaJuridica',
        PERSONA_JURIDICA_EXTENDIDO: '/persona-juridica/:idPersonaJuridica/*',
        
        ADMINISTAR_ROL: '/rol',
        TIPO_USUARIO: '/tipo-usuario',
        AGREGAR_USUARIO_ADM_SUMA: '/agregar-usuario-adm-suma',
        AGREGAR_USUARIO_DP: '/agregar-usuario-dp',
        EDITAR_USUARIO: '/editar-usuario',
        USUARIO: '/usuario',
    };

    PATHS_TABS = {
        USUARIOS: {
            pestana: '/usuarios',
            paths: ['/usuarios']
        },
        ROLES: {
            pestana: '/roles',
            paths: ['/roles']
        },
        PERSONAS_JURIDICAS: {
            pestana: '/personas-juridicas',
            paths: ['/personas-juridicas']
        }
    };

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioAdministracion = new ServicioAdministracion(props);

        this.pathInicial = props.history.location.pathname;
        this.props.history.listen(this.manejadorCambioPath);
    }

    componentDidMount() {
        this._isMounted = true;
        this.setSumaState({ tab: this.obtenerPestanaPorPath(this.pathInicial) });
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        // Validando que el usuario esté en sesion
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr != null) {
            // Valida que tenga la accion habilitada de acceder 
            let habilitado = await this.servicioUsuarios.accionHabilitadaPromesa(Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.ACCEDER_MODULO_USUARIOS.id);
            if (habilitado && habilitado.resultado) {
                this.setSumaState({ usuario: usr, componente: this.ENUM_COMPONENTE.VER });
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }

    clickVerUsuario = (e, idUsuario) => {
        this.historyLocal.push(this.PATHS.USUARIO + '?id=' + idUsuario);
    }

    clickSeleccionarUsuario = e => {
        this.historyLocal.push(this.PATHS.TIPO_USUARIO);
    }

    clickAgregarRol = e => {
        this.historyLocal.push(this.PATHS.ADMINISTAR_ROL);
    }

    clickAgregarUsuario = (tipoUsuario) => {
        switch (tipoUsuario) {
            case Constantes.ENUMS.TIPO_USUARIO.ADMINISTRADOR_SUMA.id:
                this.historyLocal.push(this.PATHS.AGREGAR_USUARIO_ADM_SUMA);
                break;
            case Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id:
                this.historyLocal.push(this.PATHS.AGREGAR_USUARIO_DP);
                break;
            default:
                break;
        }
    }

    clickEditarUsuario = (e, idUsuario) => {
        this.historyLocal.push(this.PATHS.EDITAR_USUARIO + '?id=' + idUsuario);
    }

    usuarioGuardado(nuevoUsuario, idUsuario, cancelar) {
        if (typeof cancelar === 'boolean' && cancelar) {
            if (nuevoUsuario)
                this.historyLocal.push(this.PATHS.USUARIOS);
            else
                this.historyLocal.push(this.PATHS.USUARIO + '?id=' + idUsuario);
            return;
        }

        Modal.success({ content: "El usuario ha sido " + (nuevoUsuario ? "creado. Se ha enviado un correo electrónico al usuario para activar su cuenta." : "actualizado") });
        this.historyLocal.push(this.PATHS.USUARIO + '?id=' + idUsuario);
    }


    clickActivarUsuario = async (e, idUsuario, usuarioNombre, activar, callback) => {
        let respuestaApi = await this.servicioAdministracion.activarUsuario(idUsuario, activar);
        if (respuestaApi) {
            switch (respuestaApi.codigo) {
                case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id:
                    Modal.success({ content: 'Usuario ' + usuarioNombre + ' ' + (activar ? 'Activado' : 'Desactivado') });
                    if (callback !== undefined) {
                        callback();
                    }
                    break;
                default:
                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:USU01]" });
                    break;
            }
        }
    };


    clickEnviarCorreoActivacion = async (e, idUsuario, usuarioNombre, callback) => {
        let respuestaApi = await this.servicioAdministracion.enviarCorreoActivacion(idUsuario);
        if (respuestaApi) {
            switch (respuestaApi.codigo) {
                case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id:
                    Modal.success({ content: 'Correo de activación de cuenta enviado al usuario ' + usuarioNombre });
                    if (callback !== undefined) {
                        callback();
                    }
                    break;
                case Constantes.ENUMS_API.ADMINISTRACION.USUARIO_CON_CUENTA_ACTIVA.id:
                    Modal.warning({ content: respuestaApi.mensaje });
                    if (callback !== undefined) {
                        callback();
                    }
                    break;
                case Constantes.ENUMS_API.USUARIO.ERROR_SERVICIO_CORREO_ELECTRONICO.id:
                    Modal.error({ content: respuestaApi.mensaje });
                    break;
                default:
                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:USU02]" });
                    break;
            }
        }
    }

    enviarCorreoCambioContrasena = async (e, idUsuario, usuarioNombre, callback) => {
        let respuestaApi = await this.servicioAdministracion.enviarCorreoCambioContrasena(idUsuario);
        if (respuestaApi) {
            switch (respuestaApi.codigo) {
                case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id:
                    Modal.success({ content: 'Correo de cambio de contraseña enviado al usuario ' + usuarioNombre });
                    if (callback !== undefined) {
                        callback();
                    }
                    break;
                case Constantes.ENUMS_API.USUARIO.ERROR_SERVICIO_CORREO_ELECTRONICO.id:
                    Modal.error({ content: respuestaApi.mensaje });
                    break;
                default:
                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:USU03]" });
                    break;
            }
        }
    }

    contrasenaTemporalSUMAv1 = async (e, idUsuario, usuarioNombre, callback) => {
        let respuestaApi = await this.servicioAdministracion.asignarContrasenaTemporalSUMAv1(idUsuario);
        if (respuestaApi) {
            switch (respuestaApi.codigo) {
                case Constantes.ENUMS_API.USUARIO.OPERACION_REALIZADA.id:
                    Modal.success({ content: respuestaApi.mensaje });
                    if (callback !== undefined) {
                        callback();
                    }
                    break;
                case Constantes.ENUMS_API.USUARIO.OPERACION_NO_REALIZADA.id:
                    Modal.error({ content: respuestaApi.mensaje });
                    break;
                default:
                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:USU06]" });
                    break;
            }
        }
    }

    clickDesbloquearIntentosFallidos = async (e, idUsuario, usuarioNombre, callback) => {
        let respuestaApi = await this.servicioAdministracion.desbloquearIntentosFallidosUsuario(idUsuario);
        if (respuestaApi) {
            switch (respuestaApi.codigo) {
                case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id:
                    Modal.success({ content: 'Intentos fallidos del usuario ' + usuarioNombre + ' desbloqueados' });
                    if (callback !== undefined) {
                        callback();
                    }
                    break;
                default:
                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:USU07]" });
                    break;
            }
        }
    };

    validarEliminarRol = async (idRol, callback) => {
        let respuestaApi = await this.servicioAdministracion.validarEliminarRol(idRol);
        if (respuestaApi) {
            switch (respuestaApi.codigo) {
                case Constantes.ENUMS_API.USUARIO.ROL_VALIDO_PARA_ELIMINAR.id:
                    Modal.confirm({
                        content: respuestaApi.mensaje,
                        cancelText: 'Cancelar',
                        okText: 'Confirmar',
                        onOk: (async (close) => {
                            respuestaApi = await this.servicioAdministracion.eliminarRol(idRol);
                            switch (respuestaApi.codigo) {
                                case Constantes.ENUMS_API.USUARIO.OPERACION_REALIZADA.id:
                                    Modal.success({ content: respuestaApi.mensaje });
                                    this.historyLocal.push(this.PATHS.ROLES);
                                    break;
                                case Constantes.ENUMS_API.USUARIO.OPERACION_NO_REALIZADA.id:
                                    Modal.error({ content: respuestaApi.mensaje });
                                    break;
                                default:
                                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:USU05]" });
                                    break;
                            }
                            close();
                        })
                    });
                    break;
                case Constantes.ENUMS_API.USUARIO.ROL_NO_VALIDO_PARA_ELIMINAR.id:
                    Modal.error({ content: respuestaApi.mensaje });
                    break;
                default:
                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:USU04]" });
                    break;
            }
        }
        callback();
    }

    clickVerPersonaJuridica = (e, idPersonaJuridica) => {
        this.historyLocal.push(`${this.PATHS.PERSONA_JURIDICA.replace(':idPersonaJuridica',idPersonaJuridica)}`);
    }

    manejadorCambioPath = (location, action) => {
        this.setSumaState({ tab: this.obtenerPestanaPorPath(location.pathname) });
    }


    obtenerPestanaPorPath = (path) => {
        let pestanas = Object.keys(this.PATHS_TABS);
        for (let index in pestanas) {
            let paths_tab = this.PATHS_TABS[pestanas[index]];
            for (let index02 in paths_tab.paths) {
                let path_tab = paths_tab.paths[index02];
                if (path === path_tab) {
                    return paths_tab.pestana;
                }
            }
        }
    }

    manejadorClickTab = e => {
        // Se deja el tab en null para obligar a refrescar el componente hijo
        this.setSumaState({ tab: null });
        // Con el tab seleccionado tambien se cambia la URL, se deja dentro del setTimeout con el
        // fin de obligar a refrescar el componente hijo
        setTimeout(() => { this.historyLocal.push(e); }, 100);
    };

    clickAdministrarRol = (idRol) => {
        let query = '?id=' + idRol;
        this.historyLocal.push(this.PATHS.ADMINISTAR_ROL + query);
    }


    render() {

        const { componente, usuario } = this.state;

        return (
            <>

                <div className="seccion-principal contenedor-completo seccion-flex ">

                    <div className="division-principal">
                        <label>Usuarios</label>
                    </div>
                    <div className="division-contenido seccion-flex-grow-1 padding-principal bkg-white contenido-usuarios">

                        {(componente === this.ENUM_COMPONENTE.VER) ?
                            (
                                <>
                                    <Switch>
                                        <Route path={[
                                            this.PATHS.USUARIOS,
                                            this.PATHS.ROLES,
                                            this.PATHS.PERSONAS_JURIDICAS
                                        ]}>

                                            <Tabs activeKey={this.state.tab} onChange={this.manejadorClickTab}>
                                                <TabPane tab="Usuarios" key={this.PATHS_TABS.USUARIOS.pestana} />
                                                <TabPane tab="Roles" key={this.PATHS_TABS.ROLES.pestana} />
                                                <TabPane tab="Persona Jurídicas" key={this.PATHS_TABS.PERSONAS_JURIDICAS.pestana} />
                                            </Tabs>

                                            <Route path={this.PATHS.USUARIOS}>
                                                <div className="titulo-seccion">
                                                    <label><TeamOutlined /> USUARIOS</label>
                                                    <div className="separador-horizontal-completo margen-bottom-5"></div>
                                                </div>
                                                <div className="div-lista-botones">
                                                    {Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.AGREGAR_USUARIO.id) ?
                                                        <Button type="primary"
                                                            onClick={this.clickSeleccionarUsuario}
                                                            icon={<PlusOutlined />}>
                                                            Agregar usuario
                                                        </Button> : (<></>)
                                                    }
                                                </div>
                                                <ListarUsuarios clickVerUsuario={this.clickVerUsuario}
                                                    callbackActivarUsuario={this.clickActivarUsuario}
                                                    callbackEnviarCorreoActivacion={this.clickEnviarCorreoActivacion} />
                                            </Route>

                                            <Route path={this.PATHS.ROLES}>
                                                <div className="titulo-seccion">
                                                    <label><ContactsOutlined /> ROLES</label>
                                                    <div className="separador-horizontal-completo margen-bottom-5"></div>
                                                </div>
                                                <div className="div-lista-botones">
                                                    {Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.AGREGAR_ROL.id) ?
                                                        <Button type="primary"
                                                            onClick={this.clickAgregarRol}
                                                            icon={<PlusOutlined />}>
                                                            Agregar rol
                                                        </Button> : (<></>)
                                                    }
                                                </div>
                                                <ListarRoles callbackAdministrarRol={this.clickAdministrarRol} />
                                            </Route>

                                            <Route path={this.PATHS.PERSONAS_JURIDICAS}>
                                                <div className="titulo-seccion">
                                                    <label><BankOutlined /> PERSONAS JURÍDICAS</label>
                                                    <div className="separador-horizontal-completo margen-bottom-5"></div>
                                                </div>
                                                <ListarPersonasJuridicas callbackVerPersonaJuridica={this.clickVerPersonaJuridica}/>
                                            </Route>
                                        </Route>

                                        <Route path={this.PATHS.TIPO_USUARIO}>
                                            <TipoUsuario callbackAgregarUsuario={this.clickAgregarUsuario} />
                                        </Route>

                                        <Route path={[
                                            this.PATHS.AGREGAR_USUARIO_ADM_SUMA,
                                            this.PATHS.AGREGAR_USUARIO_DP
                                        ]}>
                                            <AgregarEditarUsuario paths={this.PATHS}
                                                onSuccess={(idUsuario, cancelar) => { this.usuarioGuardado(true, idUsuario, cancelar) }} />
                                        </Route>
                                        <Route path={this.PATHS.EDITAR_USUARIO}>
                                            <AgregarEditarUsuario paths={this.PATHS}
                                                onSuccess={(idUsuario, cancelar) => { this.usuarioGuardado(false, idUsuario, cancelar) }} />
                                        </Route>

                                        <Route path={this.PATHS.USUARIO}>
                                            <VerUsuario callbackEditarUsuario={this.clickEditarUsuario}
                                                callbackActivarUsuario={this.clickActivarUsuario}
                                                callbackEnviarCorreoActivacion={this.clickEnviarCorreoActivacion}
                                                callbackEnviarCorreoCambioContrasena={this.enviarCorreoCambioContrasena}
                                                callbackContrasenaTemporalSUMAv1={this.contrasenaTemporalSUMAv1}
                                                callbackDesbloquearIntentosFallidos={this.clickDesbloquearIntentosFallidos} />
                                        </Route>

                                        <Route path={this.PATHS.ADMINISTAR_ROL}>
                                            <AdministrarRol callbackValidarEliminarRol={this.validarEliminarRol} />
                                        </Route>

                                        <Route path={[
                                            this.PATHS.PERSONA_JURIDICA_EXTENDIDO,
                                            this.PATHS.PERSONA_JURIDICA
                                            ]}>
                                            <PersonaJuridica />
                                        </Route>

                                    </Switch>



                                </>
                            )
                            :
                            (
                                (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                                    (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                                    : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:USU]</label>) : (<></>))
                            )
                        }
                    </div>
                </div>
            </>
        );

    }

}

export default withRouter(withCookies(HookReactRouterV6Parametros(Usuarios)));