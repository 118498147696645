import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Spin, Input, Button, Popconfirm, Modal, Switch, Row, Col, Tooltip, InputNumber } from 'antd';
import { EditOutlined, CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ServicioAdministracion from '../servicios/ServicioAdministracion'
import { withCookies } from 'react-cookie';
import Constantes from '../utilidades/Constantes'
import Validadores from '../utilidades/Validadores'
import Utilidades from '../utilidades/Utilidades'

class SMTP extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        cargando: true,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        valorAnterior: undefined,
    };

    conjuntos = ['SMTP'];

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioAdministracion = new ServicioAdministracion(props);

        this.inputsDisabled = {};
        this.switchValues = {};
        this.digitValues = {};
        // Se procede a crear las referencias dinámicas para manejar los
        // input, y los botones
        if (props.parametros) {
            for (let conjunto of this.conjuntos) {
                for (let parametro of props.parametros[conjunto]) {
                    this["refInput_" + parametro.id] = React.createRef();
                    this["refBtnEdit_" + parametro.id] = React.createRef();
                    this["refBtnSave_" + parametro.id] = React.createRef();
                    this["refBtnCancel_" + parametro.id] = React.createRef();
                    this.inputsDisabled["refInput_" + parametro.id] = { disabled: true };
                    if (props.idBooleanos.includes(parametro.id)) {
                        this.switchValues["refInput_" + parametro.id] = { value: Utilidades.aBooleano(parametro.valor) };
                    }
                    if( props.idDigitos.includes(parametro.id) ){
                        this.digitValues["refInput_" + parametro.id] = { value: parseInt(parametro.valor) };
                    }

                }
            }
        }

        this.inputsDisabled["submit"] = { disabled: false };
        this.refInput_probar_smtpCorreo = React.createRef();
    }

    formRef = React.createRef();


    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        this.setSumaState({
            componente: this.ENUM_COMPONENTE.VER, cargando: false,
            inputsDisabled: this.inputsDisabled, switchValues: this.switchValues, digitValues:this.digitValues
        });
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    clicEditar = (id) => {
        let inputsDisabled = this.state.inputsDisabled;
        let switchValues = this.state.switchValues;
        let digitValues = this.state.digitValues;
        inputsDisabled["submit"].disabled = true;
        for (const conjunto in this.props.parametros) {
            for (let parametro of this.props.parametros[conjunto]) {
                if (parametro.id !== id) {
                    // Se deshabilitan los elementos que no se van a trabajar
                    if (this["refBtnEdit_" + parametro.id]) {
                        this["refBtnEdit_" + parametro.id].current.disabled = true;
                        this["refBtnEdit_" + parametro.id].current.hidden = false;
                    }
                    if (this["refBtnSave_" + parametro.id]) {
                        this["refBtnSave_" + parametro.id].current.hidden = true;
                    }
                    if (this["refBtnCancel_" + parametro.id]) {
                        this["refBtnCancel_" + parametro.id].current.hidden = true;
                    }
                    if (this["refInput_" + parametro.id]) {
                        inputsDisabled["refInput_" + parametro.id].disabled = true;
                    }
                } else {
                    // Se habilita el elemento que se desea trabajar
                    this["refBtnEdit_" + id].current.hidden = true;
                    this["refBtnSave_" + id].current.hidden = false;
                    this["refBtnCancel_" + id].current.hidden = false;
                    inputsDisabled["refInput_" + id].disabled = false;
                    let valor;

                    if (this.props.idBooleanos.includes(id)) {
                        valor = switchValues["refInput_" + id].value;
                    }else if( this.props.idDigitos.includes(id) ){
                        valor = digitValues["refInput_" + id].value;
                    } else {
                        valor = this["refInput_" + id].current.input.value;
                    }

                    if (this.props.idContrasenas.includes(id)) {
                        this["refInput_" + id].current.state.value = "";
                    }
                    this.setSumaState({ valorAnterior: valor });
                }
            }
        }
        this.setSumaState({ inputsDisabled, switchValues, digitValues });
    }

    clicCancelar = (id) => {
        let inputsDisabled = this.state.inputsDisabled;
        let switchValues = this.state.switchValues;
        let digitValues = this.state.digitValues;
        inputsDisabled["submit"].disabled = false;
        for (const conjunto in this.props.parametros) {
            for (let parametro of this.props.parametros[conjunto]) {
                if (parametro.id !== id) {
                    // Se deshabilitan los elementos que no se van a trabajar
                    if (this["refBtnEdit_" + parametro.id]) {
                        this["refBtnEdit_" + parametro.id].current.disabled = false;
                        this["refBtnEdit_" + parametro.id].current.hidden = false;
                    }
                    if (this["refBtnSave_" + parametro.id]) {
                        this["refBtnSave_" + parametro.id].current.hidden = true;
                    }
                    if (this["refBtnCancel_" + parametro.id]) {
                        this["refBtnCancel_" + parametro.id].current.hidden = true;
                    }
                    if (this["refInput_" + parametro.id]) {
                        inputsDisabled["refInput_" + parametro.id].disabled = true;
                    }
                } else {
                    // Se habilita el elemento que se desea trabajar
                    this["refBtnEdit_" + id].current.hidden = false;
                    this["refBtnSave_" + id].current.hidden = true;
                    this["refBtnCancel_" + id].current.hidden = true;
                    inputsDisabled["refInput_" + id].disabled = true;
                    if (this.props.idBooleanos.includes(id)) {
                        switchValues["refInput_" + id].value = this.state.valorAnterior;
                    }else if( this.props.idDigitos.includes(id) ){
                        digitValues["refInput_" + id].value = this.state.valorAnterior;
                    } else {
                        this["refInput_" + id].current.state.value = this.state.valorAnterior;
                    }
                    //this.setSumaState({ valorAnterior: undefined });
                }
            }
        }
        this.setSumaState({ inputsDisabled, switchValues, digitValues, valorAnterior: undefined  });
    }

    clicGuardar = async (id) => {

        let valor;

        if (this.props.idBooleanos.includes(id)) {
            valor = Utilidades.aBooleano(this.state.switchValues["refInput_" + id].value).toString();
        }else if( this.props.idDigitos.includes(id) ){
            valor = this.state.digitValues["refInput_" + id].value;
            const validar = Validadores.validarDigitos(valor)
            if( !validar.success ){
                Modal.error({ content: 'Debes ingresar un dígito válido.' });
                return;
            }
        } else {
            valor = this["refInput_" + id].current.input.value;
            if (!Validadores.stringValido(valor)) {
                Modal.error({ content: 'Debes ingresar un valor.' });
                return;
            }
        }

        if( !Validadores.validarTamano(valor,0,1000) ){
            Modal.error({ content: 'Debes ingresar un valor que contenga menos de 1.000 caracteres.' });
            return;
        }

        let parametroGeneral = { id, valor };
        let respuestaApi = await this.servicioAdministracion.actualizarParametroGeneral(parametroGeneral);
        if (respuestaApi) {
            switch (respuestaApi.codigo) {
                case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id:
                    let inputsDisabled = this.state.inputsDisabled;
                    inputsDisabled["submit"].disabled = false;
                    for (const conjunto in this.props.parametros) {
                        for (let parametro of this.props.parametros[conjunto]) {
                            if (parametro.id !== id) {
                                // Se deshabilitan los elementos que no se van a trabajar
                                if (this["refBtnEdit_" + parametro.id]) {
                                    this["refBtnEdit_" + parametro.id].current.disabled = false;
                                }
                            } else {
                                // Se habilita el elemento que se desea trabajar
                                this["refBtnEdit_" + id].current.hidden = false;
                                this["refBtnSave_" + id].current.hidden = true;
                                this["refBtnCancel_" + id].current.hidden = true;
                                inputsDisabled["refInput_" + id].disabled = true;
                            }
                        }
                    }
                    this.setSumaState({ inputsDisabled, valorAnterior: undefined });
                    Modal.success({ content: 'Parámetro general actualizado satisfactoriamente.' });
                    break;
                default:
                    Modal.error({ content: 'No fue posible actualizar el parámetro general. Por favor intenta mas tarde.' });
                    break;
            }
        }
    }

    onChangeSwitch = (checked, id) => {
        let switchValues = this.state.switchValues;
        switchValues["refInput_" + id].value = checked;
        this.setSumaState({ switchValues });
    }

    onChangeInputNumber = (value, id) => {
        let digitValues = this.state.digitValues;
        digitValues["refInput_" + id].value = value;
        this.setSumaState({ digitValues });
    }

    probarSMTP = async () => {
        
        let correoElectronico = this.refInput_probar_smtpCorreo.current.input.value;
        if (!Validadores.stringValido(correoElectronico)) {
            Modal.error({ content: 'Debes ingresar un correo electrónico al cual será enviado el correo de prueba.' });
            return;
        }
        if(!Validadores.validarCorreoElectronico(correoElectronico)){
            Modal.error({ content: 'Ingresa un correo electrónico válido.' });
            return;
        }

        this.setSumaState({ cargando: true });
        let correoElectronicoDto = { correoElectronico };
        let respuestaApi = await this.servicioAdministracion.smtpProbar(correoElectronicoDto);
        if (respuestaApi) {
            switch (respuestaApi.codigo) {
                case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id:
                    Modal.success({ content: respuestaApi.mensaje });
                    break;
                default:
                    Modal.error({ content: respuestaApi.mensaje });
                    break;
            }
        }

        this.setSumaState({ cargando: false });
    };

    render() {
        const { componente, inputsDisabled, switchValues, digitValues } = this.state;

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>

                                {this.props.parametros ?
                                    <>
                                        {this.conjuntos.map((conjunto, index) => (
                                            <div key={index}>
                                                {/*<div className="sub-titulo-seccion">
                                            <label>Conjunto - {conjunto}</label>
                                            <div className="separador-horizontal-completo02 margen-bottom-5"></div>
                                        </div>*/}
                                                {this.props.parametros[conjunto].map(parametro => (
                                                    <div key={parametro.id}>
                                                        <Row className="row-parametro-general">
                                                            <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                                                                <Tooltip title={parametro.descripcion ? parametro.descripcion : parametro.etiqueta}>
                                                                    <ExclamationCircleOutlined style={{ color: '#777777', marginRight: '5px' }} />
                                                                </Tooltip>
                                                                <label>{parametro.etiqueta ? parametro.etiqueta : parametro.parametro}:</label>
                                                            </Col>
                                                            <Col>
                                                                <Input.Group compact>

                                                                    {this.props.idContrasenas.includes(parametro.id) ?
                                                                        <Input.Password ref={this["refInput_" + parametro.id]} className="input-parametro-general" defaultValue={parametro.valor} disabled={inputsDisabled["refInput_" + parametro.id].disabled} />
                                                                        :
                                                                        this.props.idBooleanos.includes(parametro.id) ?
                                                                            <div className="switch-parametro-general">
                                                                                <Switch ref={this["refInput_" + parametro.id]} checked={switchValues["refInput_" + parametro.id].value} onChange={(checked, e) => { this.onChangeSwitch(checked, parametro.id); }} disabled={inputsDisabled["refInput_" + parametro.id].disabled} />
                                                                            </div>
                                                                            :
                                                                            this.props.idDigitos.includes(parametro.id) ?
                                                                                <InputNumber ref={this["refInput_" + parametro.id]} value={digitValues["refInput_" + parametro.id].value} onChange={(value)=>{ this.onChangeInputNumber(value,parametro.id); }} className="input-parametro-general" defaultValue={parametro.valor} disabled={inputsDisabled["refInput_" + parametro.id].disabled} min="0" />
                                                                                :
                                                                                <Input ref={this["refInput_" + parametro.id]} className="input-parametro-general" defaultValue={parametro.valor} disabled={inputsDisabled["refInput_" + parametro.id].disabled} />
                                                                    }


                                                                    {Utilidades.accionPermitida(this.props.usuario, Constantes.ENUMS_MODULOS.CONFIGURACION.ACCIONES.EDITAR_PARAMETRO_GENERAL.id) ?
                                                                        <Button ref={this["refBtnEdit_" + parametro.id]} onClick={(e) => { this.clicEditar(parametro.id); }} icon={<EditOutlined />} />
                                                                        : null}

                                                                    <Popconfirm title={'Confirma guardar el nuevo valor?'}
                                                                        okText="Si"
                                                                        cancelText="No"
                                                                        onConfirm={(e) => { this.clicGuardar(parametro.id) }}>
                                                                        <Button ref={this["refBtnSave_" + parametro.id]} type="primary" icon={<CheckOutlined />} hidden={true} />
                                                                    </Popconfirm>

                                                                    <Button ref={this["refBtnCancel_" + parametro.id]} onClick={(e) => { this.clicCancelar(parametro.id); }} icon={<CloseOutlined />} hidden={true} />
                                                                </Input.Group>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}


                                        <Row className="row-parametro-general">
                                            <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                                                <Tooltip title="Ingresa un correo electrónico al cual será enviado el mensaje de prueba">
                                                    <ExclamationCircleOutlined style={{ color: '#777777', marginRight: '5px' }} />
                                                </Tooltip>
                                                <label>Correo electrónico:</label>
                                            </Col>
                                            <Col>
                                                <Input.Group compact>

                                                    <Input  className="input-parametro-general prueba-smtp-correo" 
                                                            disabled={inputsDisabled["submit"].disabled}
                                                            ref={this.refInput_probar_smtpCorreo}/>

                                                    <Button htmlType="submit"
                                                            disabled={inputsDisabled["submit"].disabled}
                                                            onClick={(e) => { this.probarSMTP(); }}>
                                                        Probar
                                                    </Button>



                                                </Input.Group>
                                            </Col>
                                        </Row>


                                    </>
                                    : null}

                            </Spin>

                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:SMTP]</label>) : (<></>))
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(SMTP));