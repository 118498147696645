import axios from 'axios';
import Constantes from '../utilidades/Constantes'
import { Modal } from 'antd';

class ServicioPais {

    constructor( props ){ 
        const { cookies } = props;
        this.cookies = cookies;
    }

    obtenerPaises ( callbackComplete, callbalkError ) {
        const config= { headers:{ }};
        axios.get( Constantes.URL_API_SUMA + '/pais',config)
        .then(res => {
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            console.error('[Error.ServicioPais.obtenerPaises]',err);
            callbalkError( {err} );
        });
    };

    obtenerPaisesPromesa () {
        const config= { headers:{ }};
        return new Promise( resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/pais',config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;    
                }else{
                    throw new Error("Error obtener paises. " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioPais.obtenerPaisesPromesa]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SP01]" }); 
                resolve();
            });
        });
    };

    obtenerPais ( idPais, callbackComplete, callbalkError ) {
        const config= { headers:{ }};
        axios.get( Constantes.URL_API_SUMA + '/pais/' + idPais ,config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            console.error('[Error.ServicioPais.obtenerPais]',err);
            callbalkError( {err} );
        });
    };

    obtenerPaisPromesa ( idPais ) {
        const config= { headers:{ }};
        return new Promise( resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/pais/' + idPais ,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;    
                }else{
                    throw new Error("Error obtener país. idPais: " + idPais + " - " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioPais.obtenerPaisPromesa]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SP03]" }); 
                resolve();
            });
        });
    };


    obtenerDepartamentosDelPais ( idPais, callbackComplete, callbalkError ) {
        const config= { headers:{ }};
        axios.get( Constantes.URL_API_SUMA + '/pais/'+idPais+'/departamentos',config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            console.error('[Error.ServicioPais.obtenerDepartamentosDelPais]',err);
            callbalkError( {err} );
        });
    };

    obtenerDepartamentosDelPaisPromesa ( idPais ) {
        const config= { headers:{ }};
        return new Promise( resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/pais/'+idPais+'/departamentos',config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener departamentos del país. idPais: " + idPais + " - " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioPais.obtenerDepartamentosDelPaisPromesa]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SP04]" }); 
                resolve();
            });
        });
    };

    obtenerCiudadesDelDepartamento ( idDepartamento, callbackComplete, callbalkError ) {
        const config= { headers:{ }};
        axios.get( Constantes.URL_API_SUMA + '/pais/departamento/'+idDepartamento+'/ciudades',config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            console.error('[Error.ServicioPais.obtenerCiudadesDelDepartamento]',err);
            callbalkError( {err} );
        });
    };

    obtenerCiudadesDelDepartamentoPromesa ( idDepartamento ) {
        const config= { headers:{ }};
        return new Promise( resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/pais/departamento/'+idDepartamento+'/ciudades',config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error obtener ciudades del departamentos. idDepartamento: " + idDepartamento + " - " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioPais.obtenerCiudadesDelDepartamentoPromesa]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SP04]" }); 
                resolve();
            });
        });
    };

    obtenerDepartamento ( idDepartamento, callbackComplete, callbalkError ) {
        const config= { headers:{ }};
        axios.get( Constantes.URL_API_SUMA + '/pais/departamento/'+idDepartamento ,config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            console.error('[Error.ServicioPais.obtenerDepartamento]',err);
            callbalkError( {err} );
        });
    };


    obtenerDepartamentoPromesa ( idDepartamento ) {
        const config= { headers:{ }};
        return new Promise( resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/pais/departamento/'+idDepartamento ,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;    
                }else{
                    throw new Error("Error obtener departamentos. " + res.status );
                }
            }).catch( err => {
                console.error('[Error.ServicioPais.obtenerDepartamentoPromesa]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SP02]" }); 
                resolve();
            });
        });
    };

}

export default ServicioPais;