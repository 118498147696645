import Constantes from "./Constantes";

const Validadores = {

    validarDigitos: ( text ) =>{
        if( !(/^\d+$/.test( text )) ){
            return {success:false,msg:'Solo dígitos'};
        }
        return {success:true,msg:''};
    },

    validarAlfa: ( text ) => {
        if( !(/^[a-zA-ZñÑÀ-úä-ü]+$/.test( text )) ){
            return {success:false,msg:'Solo caracteres alfabéticos'};
        }
        return {success:true,msg:''};
    },

    validarAlfaConEspacio: ( text ) => {
        if( !(/^[a-zA-ZñÑÀ-úä-ü\s]+$/.test( text )) ){
            return {success:false,msg:'Solo caracteres alfabéticos'};
        }
        return {success:true,msg:''};
    },

    validarEspecial01Alfa: ( text ) => {
        if( !(/^[a-zA-ZñÑÀ-úä-ü .,;\-_]+$/.test( text )) ){
            return {success:false,msg:'Solo caracteres alfabéticos'};
        }
        return {success:true,msg:''};
    },

    validarAlfaNumerico: ( text ) => {
        if( !(/^[a-zA-Z0-9]+$/.test( text )) ){
            return {success:false,msg:'Solo caracteres alfanuméricos'};
        }
        return {success:true,msg:''};
    },
    
    validarAlfaNumercioConEspacio: ( text ) => {
        if( !(/^[a-zA-ZñÑÀ-úä-ü0-9\s]+$/.test( text )) ){
            return {success:false,msg:'Solo caracteres alfanumericos'};
        }
        return {success:true,msg:''};
    },

    validarContrasena: ( text ) => {
        if( !(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test( text )) ){
            return {success:false,msg:'Contraseña no cumple patrón'};
        }
        return {success:true,msg:''};
    },

    stringValido: ( valor ) => {
        if( typeof valor === 'string' || valor instanceof String ){
            return /\S/.test(valor);
        }
        return false;
    },

    validarTamano: ( texto, min, max ) => {
        if( texto ) texto = texto.toString();
        if( texto &&  texto.length >= min && texto.length <= max ){
            
            return true
        }
        return false;
    },

    validarCorreoElectronico: ( valor ) => {
        if( (/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test( valor )) ){
            return true;
        }
        return false;
    },

    objetoVacio: ( objeto ) => {
        if( objeto === null)
            return true;
        if( objeto === undefined )
            return true;     
        return Object.keys( objeto ).length === 0;
    },

    usuarioSUMAv1: ( usuario ) => {
        if( usuario && usuario.estadoUsuario ){
            let idEstadoUsuario = usuario.estadoUsuario.id;
            if( 
                idEstadoUsuario === Constantes.ENUMS.ESTADO_USUARIO.ACTIVO_SUMAV1.id ||
                idEstadoUsuario === Constantes.ENUMS.ESTADO_USUARIO.INACTIVO_SUMAV1.id ||
                idEstadoUsuario === Constantes.ENUMS.ESTADO_USUARIO.SUSPENDIDO_SUMAV1.id ||
                idEstadoUsuario === Constantes.ENUMS.ESTADO_USUARIO.BLOQUEADO_SUMAV1.id 
            ){
                return true;
            }
        }
            
        return false;
    }

}

export default Validadores;