import axios from 'axios';
import Constantes from '../../utilidades/Constantes'
import Utilidades from '../../utilidades/Utilidades'
import ServicioUsuarios from '../usuarios/ServicioUsuarios'
import { Modal } from 'antd';

class ServicioPersonaJuridica {

    constructor( props ){ 
        const { cookies } = props;
        this.cookies = cookies;
        this.servicioUsuarios = new ServicioUsuarios( props );
    }

    // Método privado
    usuarioValido() {
        return new Promise(resolve => {
            this.servicioUsuarios.obtenerUsuarioLogeado(
              ( dataAPI )    => { 
                    if( dataAPI.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.PERSONA_JURIDICA.id ){
                        resolve( dataAPI );
                    }else{
                        Modal.error({ content: "Contenido no disponible. [Cod:SPJ01]" });
                        resolve( null );
                    }
                },
              ( errorAPI )   => { 
                  Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SPJ02]" });
                  resolve( null );},
            );
        });
    }

    // Método privado
    usuarioPersonaJuridicaOAdministradorValido() {
        return new Promise(resolve => {
            this.servicioUsuarios.obtenerUsuarioLogeado(
              ( dataAPI )    => { 
                    if( 
                        dataAPI.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.PERSONA_JURIDICA.id ||
                        dataAPI.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.ADMINISTRADOR_SUMA.id
                    ){
                        resolve( dataAPI );
                    }else{
                        Modal.error({ content: "Contenido no disponible. [Cod:SPJ12]" });
                        resolve( null );
                    }
                },
              ( errorAPI )   => { 
                  Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SPJ13]" });
                  resolve( null );},
            );
        });
    }

    // Método público
    crearUsuario ( callbackComplete, callbalkError, usr ) {
        //const config= {};
        axios.post( Constantes.URL_API_SUMA + '/persona-juridica/crear-usuario', usr)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoSinConexion(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.crearUsuario]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    guardarDatosEntidad ( idPersonaJuridica, callbackComplete, callbalkError, datosEntidad ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        // /persona-juridica/datos-entidad (Funciona sin indicar el id de la persona jurídica, aquí se extrae la persona jurídica del usuario que está en sesión.)
        // /persona-juridica/3/datos-entidad (Funciona si se incluye el id de la persona jurídica)
        axios.put( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}datos-entidad`, datosEntidad, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.guardarDatosEntidad]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    obtenerDatosEntidad ( idPersonaJuridica, callbackComplete, callbalkError  ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        // /persona-juridica/datos-entidad (Funciona sin indicar el id de la persona jurídica, aquí se extrae la persona jurídica del usuario que está en sesión.)
        // /persona-juridica/3/datos-entidad (Funciona si se incluye el id de la persona jurídica)
        axios.get( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}datos-entidad`,config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.obtenerDatosEntidad]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    obtenerRepresentantesLegales ( filtroApi,  idPersonaJuridica) {
        let token = this.cookies.get('sumajwt');
        filtroApi = filtroApi ? filtroApi : {};
        const config= { headers:{ 'Authorization': `Bearer ${token}`}, params: filtroApi };

        return new Promise(resolve =>{
            // /persona-juridica/representante-legal (Funciona sin indicar el id de la persona jurídica, aquí se extrae la persona jurídica del usuario que está en sesión.)
            // /persona-juridica/3/representante-legal (Funciona si se incluye el id de la persona jurídica)
            axios.get( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}representante-legal`,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener Representantes Legales. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioPersonaJuridica.obtenerRepresentantesLegales]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SPJ03]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerTotalRepresentantesLegales () {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/persona-juridica/total-representantes-legales',config)
            .then(res => {
                if(res.status === 200){
                    let respuestaApi = res.data;
                    if( respuestaApi && respuestaApi.codigo === Constantes.ENUMS_API.PERSONA_JURIDICA.DATOS_RETORNADOS.id ){
                        resolve( respuestaApi.resultado );
                        return;
                    }else{
                        resolve();
                        return;
                    }
                }else{
                    throw new Error("Error Obtener total representantes legales Persona Jurídica. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioPersonaJuridica.obtenerTotalRepresentantesLegales]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SPJ11]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerSubUsuarios ( filtroApi, idPersonaJuridica ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }, params: filtroApi};

        return new Promise(resolve =>{
            // /persona-juridica/sub-usuario    (Funciona sin indicar el id de la persona jurídica, aquí se extrae la persona jurídica del usuario que está en sesión.)
            // /persona-juridica/3/sub-usuario  (Funciona si se incluye el id de la persona jurídica)
            axios.get( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}sub-usuario`,config)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener Sub usuarios. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioPersonaJuridica.obtenerSubUsuarios]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SPJ04]" }); 
                resolve();
            });
        });
    }


    // Método privado
    activarRepresentanteLegal ( callbackComplete, callbalkError, idRepresentanteLegal, activar, idPersonaJuridica ) {
        let datos = {};
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.put( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}representante-legal/` + ( activar ? 'activar' : 'desactivar') + '/' + idRepresentanteLegal, datos, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.activarRepresentanteLegal]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    eliminarRepresentanteLegal ( idRepresentanteLegal, idPersonaJuridica ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}`} };

        return new Promise(resolve =>{
            axios.delete( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}representante-legal/`+idRepresentanteLegal,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Eliminar Representante Legal. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioPersonaJuridica.eliminarRepresentanteLegal]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SPJ08]" }); 
                resolve();
            });
        });
    }

    // Método privado
    activarUsuario ( callbackComplete, callbalkError, idUsuario, activar, idPersonaJuridica ) {
        let datos = {};
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        axios.put( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}sub-usuario/` + ( activar ? 'activar' : 'desactivar') + '/' + idUsuario, datos, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.activarUsuario]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    crearRepresentanteLegal ( callbackComplete, callbalkError, representanteLegal, idPersonaJuridica ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.post( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}representante-legal`, representanteLegal, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.crearRepresentanteLegal]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    actualizarRepresentanteLegal ( callbackComplete, callbalkError, representanteLegal, idPersonaJuridica ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.put( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}representante-legal`, representanteLegal, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.actualizarRepresentanteLegal]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    obtenerRepresentanteLegal ( callbackComplete, callbalkError, idRepresentanteLegal, idPersonaJuridica  ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        axios.get( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}representante-legal/` + idRepresentanteLegal, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.obtenerRepresentanteLegal]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    obtenerUsuario ( callbackComplete, callbalkError, idUsuario, idPersonaJuridica ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        axios.get( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}sub-usuario/` + idUsuario, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.obtenerUsuario]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    agregarRepresentanteLegalArchivo ( callbackComplete, callbalkError, representanteLegalArchivo, idPersonaJuridica ) {
        //console.log('representanteLegalArchivo',representanteLegalArchivo);
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.post( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}representante-legal-archivo/`, representanteLegalArchivo, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.agregarRepresentanteLegalArchivo]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    agregarPersonaJuridicaArchivo ( callbackComplete, callbalkError, personaJuridicaArchivo, idPersonaJuridica ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.post( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}archivo`, personaJuridicaArchivo, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.agregarPersonaJuridicaArchivo]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    actualizarRepresentanteLegalArchivo ( callbackComplete, callbalkError, representanteLegalArchivo, idPersonaJuridica ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.put( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}representante-legal-archivo/`, representanteLegalArchivo, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.actualizarRepresentanteLegalArchivo]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    actualizarPersonaJuridicaArchivo ( callbackComplete, callbalkError, personaJuridicaArchivo, idPersonaJuridica ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.put( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}archivo`, personaJuridicaArchivo, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.actualizarPersonaJuridicaArchivo]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    crearSubUsuario ( callbackComplete, callbalkError, usr, idPersonaJuridica ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.post( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}sub-usuario`, usr, config)
        .then(res => {
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.crearSubUsuario]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    actualizarSubUsuario ( callbackComplete, callbalkError, usr, idPersonaJuridica ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.put( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}sub-usuario`, usr, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.actualizarSubUsuario]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    enviarCorreoActivacion ( callbackComplete, callbalkError, idUsuario, idPersonaJuridica ) {
        let datos = {};
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        axios.put( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}sub-usuario/correo-activacion/` + idUsuario, datos, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.activarRepresentanteLegal]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    enviarCorreoCambioContrasena ( callbackComplete, callbalkError, idUsuario, idPersonaJuridica ) {
        let datos = {};
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        axios.put( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}sub-usuario/correo-cambio-contrasena/` + idUsuario, datos, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.enviarCorreoCambioContrasena]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    obtenerArchivos ( callbackComplete, callbalkError, idPersonaJuridica ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        axios.get( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}archivo`, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.obtenerArchivos]',err);
            callbalkError( {err} );
        });
    }
    
    
    // Método privado
    obtenerPersonaJuridica ( idPersonaJuridica ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.get( Constantes.URL_API_EXTERNO_SUMA + '/persona-juridica/' + idPersonaJuridica, config)
            .then(res => {
                if(res.status === 200){
                    if (res.data.codigo === Constantes.ENUMS_API.PERSONA_JURIDICA.DATOS_RETORNADOS.id) {
                        resolve( res.data.resultado );
                        return;    
                    }else{
                        Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SPJ05]" });
                        resolve();
                        return;    
                    }
                }else{
                    throw new Error("Error obtener persona juridicad. idPersonaJuridica: " + idPersonaJuridica + " - " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioPersonaJuridica.obtenerPersonaJuridica]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SPJ06]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerApoderados ( filtroApi, idPersonaJuridica ) {
        let token = this.cookies.get('sumajwt');
        filtroApi = filtroApi ? filtroApi : {};
        const config= { headers:{ 'Authorization': `Bearer ${token}` }, params: filtroApi};

        return new Promise(resolve =>{
            // /persona-juridica/apoderado (Funciona sin indicar el id de la persona jurídica, aquí se extrae la persona jurídica del usuario que está en sesión.)
            // /persona-juridica/3/apoderado (Funciona si se incluye el id de la persona jurídica)
            axios.get( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}apoderado`,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener Apoderados Persona Jurídica. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioPersonaJuridica.obtenerApoderados]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SPJ07]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerTotalApoderados () {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/persona-juridica/total-apoderados',config)
            .then(res => {
                if(res.status === 200){
                    let respuestaApi = res.data;
                    if( respuestaApi && respuestaApi.codigo === Constantes.ENUMS_API.PERSONA_JURIDICA.DATOS_RETORNADOS.id ){
                        resolve( respuestaApi.resultado );
                        return;
                    }else{
                        resolve();
                        return;
                    }
                }else{
                    throw new Error("Error Obtener total apoderados Persona Jurídica. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioPersonaJuridica.obtenerTotalApoderados]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SPJ10]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerApoderado ( callbackComplete, callbalkError, idApoderado, idPersonaJuridica ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        axios.get( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}apoderado/` + idApoderado, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.obtenerApoderado]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    crearApoderado ( callbackComplete, callbalkError, apoderado, idPersonaJuridica ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.post( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}apoderado`, apoderado, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.crearApoderado]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    actualizarApoderado ( callbackComplete, callbalkError, apoderado, idPersonaJuridica ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        axios.put( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}apoderado`, apoderado, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.actualizarApoderado]',err);
            callbalkError( {err} );
        });
    }


    // Método privado
    actualizarApoderadoJuridicaArchivo ( callbackComplete, callbalkError, apoderadoArchivo, idPersonaJuridica ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        axios.put( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}apoderado-archivo/`, apoderadoArchivo, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.actualizarApoderadoArchivo]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    agregarApoderadoJuridicaArchivo ( callbackComplete, callbalkError, apoderadoArchivo, idPersonaJuridica ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.post( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}apoderado-archivo/`, apoderadoArchivo, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.agregarApoderadoArchivo]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    activarApoderado ( callbackComplete, callbalkError, idApoderado, activar, idPersonaJuridica ) {
        let datos = {};
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        axios.put( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}apoderado/` + ( activar ? 'activar' : 'desactivar') + '/' + idApoderado, datos, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaJuridica.activarApoderado]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    eliminarApoderado ( idApoderado, idPersonaJuridica ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}`} };

        return new Promise(resolve =>{
            axios.delete( Constantes.URL_API_SUMA + `/persona-juridica/${idPersonaJuridica ? idPersonaJuridica+'/' : ''}apoderado/`+idApoderado,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Eliminar Apoderado. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioPersonaJuridica.eliminarApoderado]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SPJ09]" }); 
                resolve();
            });
        });
    }

}

export default ServicioPersonaJuridica;