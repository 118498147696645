import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Popconfirm, Button, Spin, Table, Tag, Tooltip } from 'antd';
import { UserOutlined, StopOutlined, CheckOutlined, SearchOutlined } from '@ant-design/icons';
import Constantes from '../../utilidades/Constantes'
import ServicioPersonaJuridica from '../../servicios/usuarios/ServicioPersonaJuridica'
import ServicioDatosConstantes from '../../servicios/ServicioDatosConstantes'
import { withCookies } from 'react-cookie';
import Utilidades from '../../utilidades/Utilidades';
import FiltroColumna from '../../general/tabla/FiltroColumna'
import FiltroEtiqueta from '../../general/tabla/FiltroEtiqueta'

class ListarRepresentantesLegales extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        representantesLegales: [],
        cargando: false,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        usuario: null,

        filtro: {},
        filtroEtiquetas: [],
    };


    constructor(props) {
        super(props);

        this.servicioPersonaJuridica = new ServicioPersonaJuridica(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.callbackVerRepresentanteLegal = props.callbackVerRepresentanteLegal;
        this.callbackActivarRepresentanteLegal = props.callbackActivarRepresentanteLegal;

        let contexto = this;
        this.filtroColumna = new FiltroColumna(props, (valores) => { contexto.setSumaState(valores, contexto) });
    }


    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        // Validando que el usuario sea de tipo Persona Jurídica o Adminsitrador
        let usr = await this.servicioPersonaJuridica.usuarioPersonaJuridicaOAdministradorValido();
        this.setSumaState({ componente: (usr !== null ? this.ENUM_COMPONENTE.VER : this.ENUM_COMPONENTE.NO_DISPONIBLE) });
        if (this.state.componente === this.ENUM_COMPONENTE.VER) {
            this.setSumaState({ cargando: true, });
            const estados = await this.servicioDatosConstantes.obtenerEstadosRepresentanteLegal();
            let estadosRepresentanteLegal = [];
            if( estados ){
                for( let estado of estados ){
                    estadosRepresentanteLegal.push({ label: estado.estadoRepresentanteLegal, value: estado.id });
                }
            }
            await this.cargarDatosTabla(this);
            this.setSumaState({ cargando: false, usuario: usr, estadosRepresentanteLegal: estadosRepresentanteLegal });
        }
    }

    // 'limpiarFiltro': Método (clearFilters) que es propio de la tabla antDesign para limpiar los filtros
    // 'confirmarFiltro': Método (confirm) que es própio de la tabla antDesign para aplicar el filtro
    // 'dataIndex': Retorna el campo que se está filtrando
    // 'selectedKeys': Retorna el valor a filtrar;
    async cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) {
        let filtro = contexto.state.filtro;
        if (dataIndex !== undefined) { // Si el filtro es aplicado, se agrega la columna filtrada
            filtro[dataIndex] = selectedKeys;
        }
        
        contexto.setSumaState({ cargando: true, filtro: filtro });

        // Se construye el filtroApi a partir de lo que fue filtrado en la tabla
        let filtroApi = {
            ...(filtro.nombre && filtro.nombre.length > 0 && {nombre:filtro.nombre[0]} ),
            ...(filtro.identificacion && filtro.identificacion.length > 0 && {identificacion:filtro.identificacion[0]} ),
            ...(filtro.correoElectronico && filtro.correoElectronico.length > 0 && {correoElectronico:filtro.correoElectronico[0]} ),
            ...(filtro.estado && filtro.estado.length > 0 && filtro.estado[0].opc && {estado:filtro.estado[0].opc.join()} ),
        };

        await this.servicioPersonaJuridica.obtenerRepresentantesLegales(
            filtroApi, 
            // El idPersonaJuridica se puede tomar por el componente padre como atributo, si no llega por aquí, se toma la perona jurídica del usuario que está logueado.
            this.props.idPersonaJuridica
            ).then((respuestaApi) => {
                if (respuestaApi) {
                    let representantesLegales = [];
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.PERSONA_JURIDICA.DATOS_RETORNADOS.id:
                            representantesLegales = respuestaApi.resultado;
                            break;
                        default: break;
                    }
                    contexto.setSumaState({
                        representantesLegales: representantesLegales,
                        cargando: false
                    });
                }

            }).then(() => {
                if (limpiarFiltro !== undefined) { limpiarFiltro(); }
                if (confirmarFiltro !== undefined) { confirmarFiltro(); }
            });
    }



    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    render() {

        const { cargando, representantesLegales, componente, usuario, estadosRepresentanteLegal } = this.state;

        let { filtro } = this.state;
        filtro = filtro || {};
        let contexto = this;

        const columns = [
            {
                title: 'Nombre',
                dataIndex: 'nombre',
                key: 'nombre',
                sorter: (a, b) => a.nombre ? a.nombre.localeCompare(b.nombre) : 1,


                filteredValue: filtro.nombre || null,
                ...this.filtroColumna.busquedaTexto(
                    'Nombre',
                    'nombre',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            {
                title: 'Identificación',
                dataIndex: 'identificacion',
                key: 'identificacion',
                sorter: (a, b) => a.identificacion ? a.identificacion.localeCompare(b.identificacion) : 1,

                filteredValue: filtro.identificacion || null,
                ...this.filtroColumna.busquedaTexto(
                    'Identificación',
                    'identificacion',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                responsive: ['md'], /* visible en pantalla con ancho ≥ 768px*/
            },
            {
                title: 'Correo electrónico',
                dataIndex: 'correoElectronico',
                key: 'correoElectronico',
                sorter: (a, b) => a.correoElectronico ? a.correoElectronico.localeCompare(b.correoElectronico) : 1,

                filteredValue: filtro.correoElectronico || null,
                ...this.filtroColumna.busquedaTexto(
                    'Correo electrónico',
                    'correoElectronico',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                responsive: ['md'], /* visible en pantalla con ancho ≥ 768px*/
            },
            {
                title: 'Estado',
                key: 'estado',
                align: 'center',
                dataIndex: 'estado',
                sorter: (a, b) => a.estado ? a.estado.localeCompare(b.estado) : 1,

                filteredValue: filtro.estado || null,
                ...this.filtroColumna.busquedaOpciones(
                    'Estado',
                    'estado',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) },
                    estadosRepresentanteLegal
                ),
                render: (text, record) => (
                    <Tag color={record.idEstado === Constantes.ENUMS.ESTADO_REPRESENTANTE_LEGAL.ACTIVO.id ? 'green' : 'volcano'} key={text}>
                        {text.toUpperCase()}
                    </Tag>
                ),
                responsive: ['sm'], /* visible en pantalla con ancho ≥ 576px*/
            },
            {
                title: 'Opciones',
                key: 'opciones',
                align: 'center',
                className: 'columna-opciones',
                width: 112,
                render: (text, record) => (
                    <>
                        <Tooltip title="Ver representante legal">
                            <Button shape="circle" disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_REPRESENTANTES_LEGALES.ACCIONES.VER_DETALLE_REPRESENTANTE_LEGAL.id)}
                                icon={<UserOutlined style={{ 'color': '#c90a00' }} />}
                                onClick={(e) => { this.callbackVerRepresentanteLegal(e, record.id) }} />
                        </Tooltip>

                        { record.idEstado === Constantes.ENUMS.ESTADO_REPRESENTANTE_LEGAL.ACTIVO.id
                            ?
                            (
                                <Popconfirm title="Confirma desactivar el representante legal?"
                                    okText="Si"
                                    cancelText="No"
                                    onConfirm={(e) => {
                                        this.setSumaState({ cargando: true });
                                        this.callbackActivarRepresentanteLegal(
                                            e,
                                            record.id,
                                            record.nombre,
                                            false,
                                            () => {
                                                this.cargarDatosIniciales();
                                                this.setSumaState({ cargando: false });
                                            });
                                    }}
                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_REPRESENTANTES_LEGALES.ACCIONES.DESACTIVAR_REPRESENTANTE_LEGAL.id)}
                                >
                                    <Tooltip title="Desactivar representante legal">
                                        <Button shape="circle"
                                            icon={<StopOutlined style={{ 'color': '#c90a00' }} />}
                                            disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_REPRESENTANTES_LEGALES.ACCIONES.DESACTIVAR_REPRESENTANTE_LEGAL.id)} />
                                    </Tooltip>
                                </Popconfirm>
                            )
                            :
                            (
                                <Popconfirm title="Confirma activar el representante legal?"
                                    okText="Si"
                                    cancelText="No"
                                    onConfirm={(e) => {
                                        this.setSumaState({ cargando: true });
                                        this.callbackActivarRepresentanteLegal(
                                            e,
                                            record.id,
                                            record.nombre,
                                            true,
                                            () => {
                                                this.cargarDatosIniciales();
                                                this.setSumaState({ cargando: false });
                                            });
                                    }}
                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_REPRESENTANTES_LEGALES.ACCIONES.ACTIVAR_REPRESENTANTE_LEGAL.id)}
                                >
                                    <Tooltip title="Activar representante legal">
                                        <Button shape="circle"
                                            icon={<CheckOutlined style={{ 'color': '#c90a00' }} />}
                                            disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_REPRESENTANTES_LEGALES.ACCIONES.ACTIVAR_REPRESENTANTE_LEGAL.id)} />
                                    </Tooltip>
                                </Popconfirm>
                            )
                        }
                    </>
                ),
            },
        ];

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <Spin tip="Por favor espera..." size="large" spinning={cargando}>

                                {
                                    this.state.filtroEtiquetas.length > 0 ?
                                        (<div className="seccion-filtros-etiquetas">
                                            <div className="titulo-filtro-etiquetas">
                                                <label>Filtros <SearchOutlined /> :</label>
                                            </div>

                                            {
                                                this.state.filtroEtiquetas.map((filtroEtiqueta, i) => {
                                                    return (
                                                        <FiltroEtiqueta key={i}
                                                            dataIndex={filtroEtiqueta.dataIndex}
                                                            texto={filtroEtiqueta.texto}
                                                            onClose={filtroEtiqueta.borrarFiltro} />
                                                    )
                                                })
                                            }
                                        </div>)
                                        :
                                        (<></>)
                                }

                                <Table  columns={columns} 
                                        dataSource={representantesLegales} 
                                        rowKey="id"/>

                            </Spin>
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:LRL]</label>) : (<></>))
                    )
                }
            </div>
        );
    }

}


export default withRouter(withCookies(ListarRepresentantesLegales));