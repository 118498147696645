import React, { Component } from 'react';
import { Table, Button, Spin } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import Constantes from '../utilidades/Constantes';

class Normatividad extends Component {

  constructor(props) {
    super(props);
    this.historyLocal = props.history;
  }

  state = {
    cargando: true,
  };

  // -----------------------------------------------------------------------
  // --- CARGA DATOS INICIALES ---------------------------------------------
  // -----------------------------------------------------------------------
  async cargarDatosIniciales() {
    this.setSumaState({ cargando: true });
    if ( Constantes.NORMATIVIDADES ) {
      this.setSumaState({ cargando: false, normatividades: Constantes.NORMATIVIDADES });
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.cargarDatosIniciales();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setSumaState(valores) {
    if (this._isMounted) {
      this.setState(valores);
    }
  }

  render() {

    const { cargando, normatividades } = this.state;

    const columns = [
      {
        title: 'Normatividad',
        dataIndex: 'normatividad',
        key: 'normatividad',
      },
      {
        title: '',
        dataIndex: 'descripcion',
        key: 'descripcion',
        render: (text) => <div style={{textAlign:"justify"}}><span>{text}</span></div>,
      },
      {
        title: 'Ver',
        dataIndex: 'url',
        key: 'url',
        render: (text) => <Button type="primary" onClick={() => { window.open(text, '_blank'); }} icon={<EyeOutlined />} />,
      },
    ];

    return (
      <div className="contenido-principal">
        <div className="seccion-titulo">
          <div className="titulo-contenido">
            <span>NORMATIVIDAD</span>
            <div className="separador-horizontal"></div>
          </div>
        </div>
        <Spin tip="Cargando..." size="large" spinning={cargando}>
          <Table className="table-suma" 
                dataSource={normatividades} 
                columns={columns} 
                pagination={{ pageSize: 4 }} 
                rowKey={"id"}/>
        </Spin>
      </div>
    );
  }
}
export default Normatividad;
/*
scroll={{y:'200px'}}
*/