import { Modal, Button } from 'antd';
import Constantes from '../../utilidades/Constantes';
import moment from 'moment';
import React, { useState } from 'react';

export const HookNotificacion = (props) => {


    let fechaFinal = undefined;
    let fechaInicial = undefined;
    let titulo = undefined;
    let mensaje = undefined;

    if (Constantes.PARAMETROS_GENERALES_NOTIFICACION) {
        for (let i = 0; i < Constantes.PARAMETROS_GENERALES_NOTIFICACION.length; i++) {
            let parametroGeneral = Constantes.PARAMETROS_GENERALES_NOTIFICACION[i];
            switch (parametroGeneral.parametro) {
                case 'FECHA_FINAL':
                    fechaFinal = moment(parametroGeneral.valor, Constantes.FORMATO_FECHA_TIEMPO_ESTANDAR_A);
                    break;
                case 'FECHA_INICIAL':
                    fechaInicial = moment(parametroGeneral.valor, Constantes.FORMATO_FECHA_TIEMPO_ESTANDAR_A);
                    break;
                case 'TITULO':
                    titulo = parametroGeneral.valor;
                    break;
                case 'MENSAJE':
                    mensaje = parametroGeneral.valor;
                    break;
                default: break;
            }
            //console.log(i, Constantes.PARAMETROS_GENERALES_NOTIFICACION[i]);
        }
    }
    
    let mostrarNotificacion = moment(new Date()).isBetween(fechaInicial, fechaFinal);
    
    const [isModalVisible, setIsModalVisible] = useState(mostrarNotificacion);

    const handleOk = () => {
        setIsModalVisible(false);
    };
    /*const handleCancel = () => {
        setIsModalVisible(false);
    };*/

    return (
        <Modal title={titulo}
            visible={isModalVisible}
            closable={false}
            /*onCancel={handleCancel}]*/
            footer={[
                <Button type="primary" key="aceptar" onClick={handleOk}>
                    Aceptar
                </Button>
            ]}>
            <p>{mensaje}</p>
        </Modal>
    );

};