import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Spin, Input, Button, Popconfirm, Modal, Tooltip, Upload, Select, Switch } from 'antd';
import { EditOutlined, CheckOutlined, CloseOutlined, UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ServicioAdministracion from '../servicios/ServicioAdministracion'
import ServicioArchivos from '../servicios/archivos/ServicioArchivos'
import ServicioDatosConstantes from '../servicios/ServicioDatosConstantes'
import { withCookies } from 'react-cookie';
import Constantes from '../utilidades/Constantes'
import Validadores from '../utilidades/Validadores'
import Utilidades from '../utilidades/Utilidades'
import { ChromePicker } from 'react-color';

class Inicio extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        cargando: true,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        contenidosVisuales: []
    };

    tamanoFuentes = [6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36];
    

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioAdministracion = new ServicioAdministracion(props);
        this.servicioArchivos = new ServicioArchivos(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.inputsDisabled = {};
        this.imagesBase64 = {};
        this.selects = {};
        this.colors = {};
        this.activos = {};
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let contenidosVisuales = await this.servicioAdministracion.obtenerContenidosVisuales();
        if( contenidosVisuales ){
            contenidosVisuales.sort((a, b) => a.id > b.id ? 1 : -1);
            //console.log('contenidosVisuales',contenidosVisuales);
            // Se procede a crear las referencias dinámicas para manejar los input, y los botones
            for( let i = 0; i < contenidosVisuales.length; i++ ){
                let contenidoVisual = contenidosVisuales[i];
                if( contenidoVisual.id <= 4 ){
                    this.eliminarReferencia( contenidoVisual.id );
                    this.crearReferencias( contenidoVisual.id, contenidoVisual );
                }else{
                    contenidosVisuales.splice(i,1);
                    i--;
                }
            }

            let tipoArchivo = await this.servicioDatosConstantes.obtenerTipoArchivo( Constantes.ENUMS.TIPO_ARCHIVO.IMAGEN_CONTENIDO_VISUAL.id );
            
            this.setSumaState({ componente: this.ENUM_COMPONENTE.VER, cargando: false, 
                                contenidosVisuales,
                                inputsDisabled:this.inputsDisabled,
                                imagesBase64: this.imagesBase64,
                                selects: this.selects,
                                colors: this.colors,
                                activos: this.activos,
                                tipoArchivo
                                 });
        }

        /*this.crearReferencias(1);
        this.crearReferencias(2);
        this.crearReferencias(3);
        this.crearReferencias(4);
        this.setSumaState({ 
            componente: this.ENUM_COMPONENTE.VER, 
            cargando: false, 
            inputsDisabled:this.inputsDisabled,
            imagesBase64: this.imagesBase64,
            selects: this.selects,
            colors: this.colors
        });*/
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    crearReferencias = (index, contenidoVisual) => {
        this["refBtnEdit_" + index] = React.createRef();
        this["refInput_pre_" + index] = React.createRef();
        this["refInput_url_" + index] = React.createRef();
        this.selects["select_" + index] = {};
        this.inputsDisabled["edit_" + index] = { edit: false };
        this.imagesBase64["img_"+index] = {};
        this.colors["color_"+index] = undefined;
        this.activos["activo_"+index] = contenidoVisual.activo;
    }

    eliminarReferencia = (id) => {

        if( this["refBtnEdit_" + id] ){
            this["refBtnEdit_" + id].current.disabled = false;                
            this["refBtnEdit_" + id].current.hidden = false;
        }
        if( this.inputsDisabled["edit_" + id] ){
            this.inputsDisabled["edit_" + id].edit = false;
        }
        this.imagesBase64["img_"+ id] = {};
        this.selects["select_" + id] = {};
        this.colors["color_"+ id] = undefined;
        this.activos["activo_"+id] = undefined;
    }

    clicEditar = (id) => {
        let inputsDisabled = this.state.inputsDisabled;
        for (let contenidoVisual of this.state.contenidosVisuales) {
            if (contenidoVisual.id !== id) {
                // Se deshabilitan los elementos que no se van a trabajar  
                this["refBtnEdit_" + contenidoVisual.id].current.disabled = true;           
                this["refBtnEdit_" + contenidoVisual.id].current.hidden = false;
                inputsDisabled["edit_" + contenidoVisual.id].edit = false;
            } else {
                // Se habilita el elemento que se desea trabajar
                this["refBtnEdit_" + id].current.hidden = true;
                inputsDisabled["edit_" + id].edit = true;
            }
        }
        this.setSumaState({ inputsDisabled });
    }

    clicCancelar = (id) => {
        let inputsDisabled = this.state.inputsDisabled;
        let imagesBase64 = this.state.imagesBase64;
        let selects = this.state.selects;
        let colors = this.state.colors;
        let activos = this.state.activos;
        let contenidosVisuales = this.state.contenidosVisuales;
        for (let contenidoVisual of contenidosVisuales) {
                this["refBtnEdit_" + contenidoVisual.id].current.disabled = false;                
                this["refBtnEdit_" + contenidoVisual.id].current.hidden = false;
                // Se deshabilitan los elementos que no se van a trabajar
                inputsDisabled["edit_" + contenidoVisual.id].edit = false;
                imagesBase64["img_"+contenidoVisual.id] = {};
                selects["select_" + contenidoVisual.id] = {};
                colors["color_"+contenidoVisual.id] = undefined;
                activos["activo_"+contenidoVisual.id] = contenidoVisual.activo;
            if (contenidoVisual.id === id) {
                // Se habilita el elemento que se desea trabajar
            }
        }

        this.setSumaState({ inputsDisabled, imagesBase64, selects, colors, activos });
    }

    clicGuardar = async (id) => {

        let titulo = this["refInput_pre_" + id].current.resizableTextArea.props.value;
        let url = this["refInput_url_" + id].current.resizableTextArea.props.value;
        let file = this.state.imagesBase64["img_"+id].file;
        let tamano = this.state.selects["select_" + id].id;
        let color = this.state.colors['color_'+id];
        let activo = this.state.activos["activo_"+id];
        
        if (!Validadores.stringValido(titulo)) {
            //Modal.error({ content: 'Debes ingresar el título.' });
            //return;
            titulo = ' ';
        } else if (!Validadores.validarTamano(titulo, 1, 500)) {
            Modal.error({ content: 'El título no puede contener más de 500 caracteres.' });
            return;
        }

        /*if (!Validadores.stringValido(url)) {
            Modal.error({ content: 'Debes ingresar la url del enlace.' });
            return;
        } else*/ if (url && !Validadores.validarTamano(url, 1, 2000)) {
            Modal.error({ content: 'La url no puede contener más de 2000 caracteres.' });
            return;
        }

        this.setSumaState({ cargando: true });
        let contenidoVisual = { 
            id, 
            titulo, 
            url,
            ...(tamano && {tamanoFuente: tamano}),
            ...(color && {color: Utilidades.colorToRgb(color)}),
            activo,
        };
        
        if( file ){
            let respuestaApiArchivo = await this.servicioArchivos.cargarArchivoPromise(
                file,
                Constantes.ENUMS.TIPO_ARCHIVO.IMAGEN_CONTENIDO_VISUAL.id,
                this.progressAPISubirArchivo,
            );
            //console.log('respuestaApiArchivo',respuestaApiArchivo);
            if (respuestaApiArchivo && respuestaApiArchivo.codigo === Constantes.ENUMS_API.ARCHIVOS.ARCHIVO_GUARDADO.id) {
                contenidoVisual.archivo = {id:respuestaApiArchivo.resultado};
            }else{
                Modal.error({ content: 'No fue posible actualizar la información del contenido visual.' });
                return;
            }
        }
        
        //console.log('contenidoVisual',contenidoVisual);

        
        let respuestaApi = await this.servicioAdministracion.actualizarContenidoVisual( contenidoVisual );

        if (respuestaApi) {
            switch (respuestaApi.codigo) {
                case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id:
                    this.cargarDatosIniciales();
                    Modal.success({ content: 'Contenido visual actualizado satisfactoriamente.' });
                    break;
                default:
                    Modal.error({ content: 'No fue posible actualizar el contenido visual. Por favor intenta mas tarde.' });
                    break;
            }
        }
    }

    progressAPISubirArchivo = ( porcentaje ) => {
        //console.log('porcentaje',porcentaje);
    }

    customRequest = async ({ onSuccess, onError, file, onProgress }, idContenidoVisual) => {
        // Se crea un objeto (reader) de tipo FileReader para cargar a nivel local el documento
        const reader = new FileReader();
        // Se agrega el evento 'loadend' con el fin de saber cuando termina de cargar el documento
        reader.addEventListener('loadend', async (event) => {
            // Indica que hubo un error
            if (reader.error !== null) {
                console.error('Error lectura archivo local', reader.error);
                // Lanza un evento error al componente <Upload>
                onError();
            } else {
                // Se asume que aqui el archivo local fue leido exitosamente
                // Se extrae el archivo en base 64
                if( file ){

                    if( file.size > this.state.tipoArchivo.tamano ){
                        Modal.error({ content: 'La imagen no puede ser mayor a '+Utilidades.obtenerTamanoEnString(this.state.tipoArchivo.tamano) });
                        return;    
                    }

                    if( !this.state.tipoArchivo.extensiones.includes( file.type ) ){
                        Modal.error({ content: 'Tipo de archivo no permitido, debes cargar uno de los siguientes tipos de archivo: '+this.state.tipoArchivo.extensiones });
                        return;    
                    }

                    let base64 = await Utilidades.archivoABase64( file );
                    this.state.imagesBase64["img_"+idContenidoVisual] = { file, base64 };
                    this.setSumaState({ imagesBase64: this.state.imagesBase64 });
                }else{
                    Modal.error({ content: 'No fue posible cargar la imagen.' });
                }
            }
        });
        // Se carga en el objeto (reader) el documento a procesar
        reader.readAsDataURL(file);
    };

    render() {
        const { componente, inputsDisabled, imagesBase64, 
                loading, contenidosVisuales, selects, colors, activos } = this.state;
        const { TextArea } = Input;
        const { Option } = Select;

        const props = {
            name: 'file',
            accept: 'image/jpeg,image/jpg,image/png',
            showUploadList: false,
            className: 'subir-foto',
            multiple: false,
            beforeUpload: this.beforeUpload,
            onChange: this.handleChange
        };

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                (
                <>
                <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>
                {contenidosVisuales ?
                    <>
                        {contenidosVisuales.map((contenidoVisual, index) => (
                            <div key={index} className="comunicaciones slider-carrusel">
                                <div className="seccion-imagen">
                                    <div className="imagen"
                                         style={{
                                             backgroundImage:'url('+(imagesBase64["img_"+contenidoVisual.id].base64 ? imagesBase64["img_"+contenidoVisual.id].base64 : (contenidoVisual.archivo ? contenidoVisual.archivo.urlPublica : ''))+')'
                                        }}>
                                    </div>
                                </div>
                                <div className="seccion-text">

                                    <div className="botones-edicion">

                                        <Upload {...props} className="upload" customRequest={(e) => {this.customRequest(e, contenidoVisual.id )}} fileList={this.state.fileList}>
                                            <Button loading={loading} disabled={!inputsDisabled["edit_" + contenidoVisual.id].edit}>
                                                <UploadOutlined /> Cambiar imagen
                                            </Button>
                                        </Upload>

                                        Activo&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Switch disabled={!inputsDisabled["edit_" + contenidoVisual.id].edit} 
                                        checked ={activos['activo_'+contenidoVisual.id]}
                                        onChange={(checked)=>{
                                            let activos = this.state.activos;
                                            activos['activo_'+contenidoVisual.id] = checked;
                                            this.setSumaState( activos );
                                        }}/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;

                                        
                                        {Utilidades.accionPermitida(this.props.usuario, Constantes.ENUMS_MODULOS.COMUNICACIONES.ACCIONES.EDITAR_CONTENIDO_VISUAL.id) ?
                                            <Button ref={this["refBtnEdit_" + contenidoVisual.id]} onClick={(e) => {this.clicEditar(contenidoVisual.id);}}  icon={<EditOutlined />}>Editar</Button> : null
                                        }

                                        <Popconfirm title={'Confirma editar el contenido?'} 
                                                    okText="Si"
                                                    cancelText="No" 
                                                    onConfirm={(e) => {this.clicGuardar( contenidoVisual.id )}}>
                                            <Button  type="primary" icon={<CheckOutlined />}  hidden={!inputsDisabled["edit_" + contenidoVisual.id].edit}/>
                                        </Popconfirm>
                                        <Button className={"btn-rigth"} onClick={(e) => {this.clicCancelar(contenidoVisual.id);}} icon={<CloseOutlined />} hidden={!inputsDisabled["edit_" + contenidoVisual.id].edit}/>
                                    </div>
                                    <div className="pregunta titulo-imagen">
                                        {!inputsDisabled["edit_" + contenidoVisual.id].edit ?
                                            <div className="texto-titulo">
                                                <span>Titulo:&nbsp;</span>{contenidoVisual.titulo}
                                            </div> :
                                            <div className="texto-entrada titulo">
                                                <label>Titulo:</label>
                                                <TextArea ref={this["refInput_pre_" + contenidoVisual.id]} rows={2} defaultValue={contenidoVisual.titulo} />
                                            </div>
                                        }
                                    </div>

                                    <div className="opciones">    
                                        <label>Fuente:&nbsp;</label>    
                                        {!inputsDisabled["edit_" + contenidoVisual.id].edit ?
                                            <>{contenidoVisual.tamanoFuente} px</>
                                        :
                                            <Select onChange={(e)=>{ selects["select_" + contenidoVisual.id] = {id:e}; }} defaultValue={contenidoVisual.tamanoFuente} >
                                                {this.tamanoFuentes.map((tamano, index) => (
                                                    <Option key={index} value={tamano}>{tamano} px</Option>
                                                ))}
                                            </Select>
                                        }
                                        
                                        <label>&nbsp;&nbsp;&nbsp;&nbsp;Color:&nbsp;</label>            
                                        <div    className={'div-color'}
                                                style={{ 
                                                    background: !inputsDisabled["edit_" + contenidoVisual.id].edit || !colors['color_'+contenidoVisual.id] ? contenidoVisual.color : Utilidades.colorToRgb(colors['color_'+contenidoVisual.id]),
                                                    cursor: !inputsDisabled["edit_" + contenidoVisual.id].edit ? 'not-allowed' : ''
                                                }} 
                                                onClick={()=>{ 
                                                    if(inputsDisabled["edit_" + contenidoVisual.id].edit){
                                                        const obj={}; obj['selectorColor_'+contenidoVisual.id]=!this.state['selectorColor_'+contenidoVisual.id]; this.setSumaState(obj);
                                                    }
                                                }}>
                                        </div>

                                        { this.state['selectorColor_'+contenidoVisual.id] ? 
                                            <div className={'div-selector-color'}>
                                                <div className={'div-selector-color-cover'} onClick={()=>{ const obj={}; obj['selectorColor_'+contenidoVisual.id]=false; this.setSumaState(obj);}}/>
                                                <ChromePicker   color={ colors['color_'+contenidoVisual.id] } 
                                                                onChange={ (color) => { 
                                                                        let colors = this.state.colors;
                                                                        colors['color_'+contenidoVisual.id] = color.rgb;
                                                                        this.setSumaState( colors );
                                                                    } } />
                                            </div> : null
                                        } 
                                    </div>

                                    <div className="enlace">
                                        {!inputsDisabled["edit_" + contenidoVisual.id].edit ?
                                            <div>
                                                {Validadores.stringValido(contenidoVisual.url) ?
                                                    <Tooltip title={contenidoVisual.url}>
                                                        <Button type="link" href={contenidoVisual.url} target="_blank">Ir a enlace url</Button> 
                                                    </Tooltip>
                                                    : 
                                                    <>
                                                        <Tooltip title={'El enlace se encuentra deshabilito, para habilitarlo debes ingresar una url del mismo'}>
                                                            <ExclamationCircleOutlined style={{color:'#777777', marginRight:'5px'}}/>
                                                        </Tooltip>
                                                        <label>Enlace deshabilitado</label>
                                                    </>
                                                }
                                            </div>:
                                            <div className="texto-entrada">
                                                <Tooltip title={'Para una redirección externa, el enlace debe incluir el protocolo (https://). Para deshabilitar el enlace basta con solo dejar en blanco la url del mismo'}>
                                                    <ExclamationCircleOutlined style={{color:'#777777', marginRight:'5px'}}/>
                                                </Tooltip>
                                                <label>Enlace:</label>
                                                <TextArea ref={this["refInput_url_" + contenidoVisual.id]} rows={1} defaultValue={contenidoVisual.url}/>
                                            </div>
                                        }
                                    </div>   

                                    
                                </div>
                            </div>
                        ))}
                    </>
                    : null}


                </Spin>

                </>
                )
                :
                (
                    (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                        (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                        : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:INICIO]</label>) : (<></>))
                )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(Inicio));