import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter, Switch, Route  } from "react-router-dom";
import { Tabs, Spin, Button } from 'antd';
import { BankOutlined, PlusOutlined } from '@ant-design/icons';
import ServicioDatosConstantes from '../../servicios/ServicioDatosConstantes'
import ServicioUsuarios from '../../servicios/usuarios/ServicioUsuarios'
import ServicioEntidad from '../../servicios/ServicioEntidad'
import ServicioAdministracion from '../../servicios/ServicioAdministracion'
import Constantes from '../../utilidades/Constantes'
import Utilidades from '../../utilidades/Utilidades';
import queryString from 'query-string';
import { withCookies } from 'react-cookie';
import DatosDependencia from '../dependencia/DatosDependencia'
import ListaOpcionDependencia from './opcion/ListaOpcionDependencia'
import ListaRolesDependencia from './roles/ListaRolesDependencia'
import AdministrarAplicacionConectada from './aplicacion-conectada/AdministrarAplicacionConectada'

const { TabPane } = Tabs;

class VerDependencia extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    PATHS_TABS = {
        DEPENDENCIA:{
            pestana: '/entidad-dependencia',
            paths: ['/entidad-dependencia']
        },
        OPCIONES: {
            pestana: '/dependencia-opciones',
            paths: [
                '/dependencia-opciones',
            ]
        },
        ROLES: {
            pestana: '/dependencia-roles',
            paths: [
                '/dependencia-roles',
            ]
        },
        APLICACION_CONECTADA: {
            pestana: '/dependencia-aplicacion-conectada',
            paths: [
                '/dependencia-aplicacion-conectada',
            ]
        }
    };

    state = {
        cargando: true,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        entidad: undefined,
        usuario: undefined,
        tab: this.PATHS_TABS.DEPENDENCIA.pestana,
    };

    


    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioEntidad = new ServicioEntidad(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
        this.callbackEditarEntidad = props.callbackEditarEntidad;

        // Se extrae el PATH inicial de la URL
        this.pathInicial = props.history.location.pathname;
        this.props.history.listen(this.manejadorCambioPath);
    }


    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        let idDependencia = undefined;

        if (urlparametros.id !== undefined && !isNaN(parseInt(urlparametros.id))) {
            idDependencia = parseInt(urlparametros.id);
        }
        this.setSumaState({ idDependencia: idDependencia });
        
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr !== null) {
            this.setSumaState({ usuario: usr, componente: this.ENUM_COMPONENTE.VER, cargando: true });
            // Valida que tenga la accion habilitada VER
            let habilitado = await this.servicioUsuarios.accionHabilitadaPromesa(Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.ACCEDER_MODULO_DEPENDENCIA.id);
            if (habilitado && habilitado.resultado) {
                let respuestaApi = await this.servicioEntidad.obtenerDependenciaPromise( idDependencia );
                let dependencia = respuestaApi.resultado;
                if ( dependencia ) {
                    this.setSumaState({ cargando: false, usuario: usr, dependencia });
                }
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }

    }

    componentDidMount() {
        this._isMounted = true;
        this.setSumaState({ tab: this.obtenerPestanaPorPath(this.pathInicial) });
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }


    manejadorCambioPath = (location, action) => {
        this.setSumaState({ tab: this.obtenerPestanaPorPath(location.pathname) });
    }


    obtenerPestanaPorPath = (path) => {
        let pestanas = Object.keys(this.PATHS_TABS);
        for (let index in pestanas) {
            let paths_tab = this.PATHS_TABS[pestanas[index]];
            for (let index02 in paths_tab.paths) {
                let path_tab = paths_tab.paths[index02];
                if (path === path_tab ) {
                    return paths_tab.pestana;
                }
            }
        }
    }

    manejadorClickTab = e => {
        // Se deja el tab en null para obligar a refrescar el componente hijo
        this.setSumaState({ tab: null });
        // Con el tab seleccionado tambien se cambia la URL, se deja dentro del setTimeout con el
        // fin de obligar a refrescar el componente hijo
        setTimeout(() => { this.historyLocal.push(e + '?id=' + this.state.idDependencia ); }, 100);
    };


    render() {
        const { componente, tab, dependencia, usuario } = this.state;

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>

                            <div className="titulo-seccion">
                                    <label> <BankOutlined /> DEPENDENCIA {dependencia ? (' - ' + dependencia.dependencia) : ''}</label>
                                <div className="separador-horizontal-completo"></div>
                            </div>

                            <div>
                                <Tabs activeKey={this.state.tab} onChange={this.manejadorClickTab}>
                                    <TabPane tab="Dependencia" key={this.PATHS_TABS.DEPENDENCIA.pestana} />
                                    <TabPane tab="Roles" key={this.PATHS_TABS.ROLES.pestana} />
                                    <TabPane tab="Opciones" key={this.PATHS_TABS.OPCIONES.pestana} />
                                    <TabPane tab="Aplicación conectada" key={this.PATHS_TABS.APLICACION_CONECTADA.pestana} />
                                </Tabs>

                                <Switch>
                                    <Route path={this.PATHS_TABS.DEPENDENCIA.paths}>
                                        {tab !== null ? <DatosDependencia callbackEditarDependencia={this.props.callbackEditarDependencia}/> : <></>}
                                    </Route>

                                    <Route path={this.PATHS_TABS.OPCIONES.paths}>
                                        {tab !== null ? 
                                        <>
                                            {Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.ADMINISTRAR_OPCIONES_DEPENDENCIA.id) ?
                                            <>
                                                <div className="div-lista-botones margen-bottom-5">
                                                    <Button type="primary"
                                                        onClick={() => this.props.callbackSeleccionTipoOpcionDependencia( this.state.idDependencia )}
                                                        icon={<PlusOutlined />}>
                                                        Agregar opción
                                                    </Button>
                                                </div>
                                                <ListaOpcionDependencia callbackAdministrarOpcionDependencia={this.props.callbackAdministrarOpcionDependencia} /> 
                                            </> : 
                                            <>
                                                <label>Administración de los roles de la dependencia no disponible. {Constantes.MENSAJE_USUARIO_SIN_PERMISOS}</label>
                                            </>}
                                        </>
                                        : <></>}
                                    </Route>

                                    <Route path={this.PATHS_TABS.ROLES.paths}>
                                        {tab !== null ? 
                                        <>
                                            <div className="div-lista-botones margen-bottom-5">
                                                <Button type="primary"
                                                    onClick={() => this.props.callbackAdministrarRol( this.state.idDependencia, undefined )}
                                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.AGREGAR_ROL.id)}
                                                    icon={<PlusOutlined />}>
                                                    Agregar rol
                                                </Button>
                                            </div>
                                            <ListaRolesDependencia callbackAdministrarRol={this.props.callbackAdministrarRol} /> 
                                        </>
                                        : <></>}
                                    </Route>

                                    <Route path={this.PATHS_TABS.APLICACION_CONECTADA.paths}>
                                        {tab !== null ? <AdministrarAplicacionConectada /> : null}
                                    </Route>

                                </Switch>
                            </div>

                            </Spin>

                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:VERDEP]</label>) : (<></>))
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(VerDependencia));