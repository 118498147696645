import React, { Component } from 'react';
import SumaContexto  from '../../contexto/SumaContexto';
import { withRouter, Switch , Route } from "react-router-dom";
import {  Button, Spin,  Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Constantes from '../../utilidades/Constantes'
import ServicioPersonaNatural from '../../servicios/usuarios/ServicioPersonaNatural'
import { withCookies } from 'react-cookie';
import AgregarEditarApoderado from './AgregarEditarApoderado'
import VerApoderado from './VerApoderado'
import ListarApoderados from './ListarApoderados'
import Utilidades from '../../utilidades/Utilidades';

class Apoderados extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE ={
        CARGANDO:1,
        VER:2,
        NO_DISPONIBLE:3
    }

    PATHS = {
        APODERADOS:         '/persona-natural-apoderados',
        AGREGAR_APODERADO : '/persona-natural-agregar-apoderado',
        EDITAR_APODERADO :  '/persona-natural-editar-apoderado',
        VER_APODERADO :     '/persona-natural-ver-apoderado',
    }
    
    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        idApoderado: null,
        usuario: null,
    };

    constructor(props){
        super(props);
        this.historyLocal = props.history;
        this.servicioPersonaNatural = new ServicioPersonaNatural ( props );
    }
    //const [form] = Form.useForm();
    formRef = React.createRef();

    componentDidMount() {
        //console.log('componentDidMount RepresentantesLegales',this.context);
        this.cargarDatosIniciales();
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales(){
        // Validando que el usuario es PERSONA NATURAL
        let usr = await this.servicioPersonaNatural.usuarioValido();
        this.setState({ usuario:usr, componente:(usr !== null ? this.ENUM_COMPONENTE.VER : this.ENUM_COMPONENTE.NO_DISPONIBLE)});
    }

    apoderadoGuardado( nuevoApoderado, idApoderado, cancelar ){
        if( typeof cancelar === 'boolean' && cancelar ){
            this.setState({ 
                idApoderado: (nuevoApoderado ? 0 : idApoderado) 
            });    
            this.historyLocal.push( nuevoApoderado ? this.PATHS.APODERADOS : (this.PATHS.VER_APODERADO + '?id=' + idApoderado) );
            return;
        }
        this.setState({ idApoderado: idApoderado });
        this.historyLocal.push( this.PATHS.VER_APODERADO + '?id=' + idApoderado );
        Modal.success({ content: "El apoderado ha sido " + (nuevoApoderado ? "creado" : "actualizado") });
    }

    clickVerApoderado = ( e, idApoderado ) => {
        this.setState({ idApoderado: idApoderado });
        this.historyLocal.push( this.PATHS.VER_APODERADO + '?id=' + idApoderado );
    }

    clickActivarApoderado = (e,idUsuario, usuarioNombre, activar, callback ) => {
        this.setState({ cargando: true, });
        this.servicioPersonaNatural.activarApoderado(
            ( respuestaApi )    => { 
                switch(respuestaApi.codigo){
                    case Constantes.ENUMS_API.PERSONA_NATURAL.OPERACION_REALIZADA.id:
                        this.setState({ cargando: false });
                        Modal.success({ content:'Apoderado ' + usuarioNombre + ' ' + ( activar ? 'Activado' : 'Desactivado' ) });
                        if( callback !== undefined ){
                            callback();
                        }
                        break;
                    default:
                        Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:A02]" });
                        break;    
                }
            },
            ( errorAPI ) => { 
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:A03]" });
            },
            idUsuario,
            activar
        );
    };

    eliminarApoderado = async (idApoderado, apoderadoNombre) => {
        this.setState({ cargando: true });

        let respuestaApi = await this.servicioPersonaNatural.eliminarApoderado(idApoderado);
        if (respuestaApi) {
            
            switch (respuestaApi.codigo) {
                case Constantes.ENUMS_API.PERSONA_NATURAL.OPERACION_REALIZADA.id:
                    this.setState({ cargando: false });
                    Modal.success({ content: 'Apoderado "' + apoderadoNombre + '" eliminado' });
                    this.historyLocal.push(this.PATHS.APODERADOS);
                    break;
                default:
                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:A04]" });
                    break;
            }
        }
    };

    agregarApoderado = e => {
        this.setState({ idApoderado: 0 });
        this.historyLocal.push( this.PATHS.AGREGAR_APODERADO );
    }

    clickEditarApoderado = ( e, idApoderado ) => {
        this.setState({ idApoderado: idApoderado });
        this.historyLocal.push( this.PATHS.EDITAR_APODERADO + '?id=' + idApoderado );
    }

    
    render() {
      //let  {form} = this.props.form;
      //const { usuario } = this.context
      const { componente, usuario } = this.state;

        return (
          <div>    
            {(componente === this.ENUM_COMPONENTE.VER) ?
                (
                    <>
                        <Switch>

                            <Route path={this.PATHS.VER_APODERADO}>
                                <VerApoderado   callbackEditarApoderado={ this.clickEditarApoderado }
                                                callbackActivarApoderado={ this.clickActivarApoderado }
                                                callbackEliminarApoderado={ this.eliminarApoderado }/>
                            </Route>

                            <Route path={this.PATHS.AGREGAR_APODERADO}>
                                <AgregarEditarApoderado onSuccess=  {( idApoderado, cancelar ) => {
                                                                        this.apoderadoGuardado( true, idApoderado, cancelar );
                                                                    }} />
                            </Route>

                            <Route path={this.PATHS.EDITAR_APODERADO}>
                                <AgregarEditarApoderado onSuccess=  {( idApoderado, cancelar ) => {
                                                                        this.apoderadoGuardado( false, idApoderado, cancelar );
                                                                    }} />
                            </Route>

                            <Route path={this.PATHS.APODERADOS}>
                                <>
                                    <div className="div-lista-botones">
                                        <Button type="primary" 
                                                onClick={this.agregarApoderado} 
                                                icon={<PlusOutlined />}
                                                disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_NATURAL_APODERADOS.ACCIONES.AGREGAR_APODERADO.id)}>
                                            Agregar apoderado
                                        </Button>
                                    </div>
                                    < ListarApoderados  callbackVerApoderado={this.clickVerApoderado} 
                                                        callbackActivarApoderado={this.clickActivarApoderado}/>
                                </>
                            </Route>

                        </Switch>

                    </>
                )
                :
                (
                    (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                    (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                    :((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:APDRS]</label>) : (<></>))
                )
            }

          </div>
        );
    }
}
          
export default withRouter(withCookies(Apoderados));