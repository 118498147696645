import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Modal, Spin, Row, Col, Form, Input, Select, Button, Popover, Popconfirm } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Constantes from '../utilidades/Constantes'
import Validadores from '../utilidades/Validadores'
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import ServicioDatosConstantes from '../servicios/ServicioDatosConstantes'
import ServicioAdministracion from '../servicios/ServicioAdministracion'
import ServicioPais from '../servicios/ServicioPais'
import queryString from 'query-string';



class AgregarEditarEntidad extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        idEntidad: undefined,
        usuario: undefined,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        estados: [],
        paises: [],
        departamentos: [],
        ciudades: [],
    };

    constructor(props) {
        super(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
        this.servicioPais = new ServicioPais(props);
        this.onSuccess = props.onSuccess;
    }

    formRef = React.createRef();

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        let idEntidad;

        if (urlparametros.id !== undefined && !isNaN(parseInt(urlparametros.id))) {
            idEntidad = parseInt(urlparametros.id);
        }
        this.setSumaState({ idEntidad: idEntidad });

        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr !== null) {
            // Valida que tenga la accion habilitada EDITAR o AGREGAR
            let habilitado = await this.servicioUsuarios.accionHabilitadaPromesa(idEntidad ? Constantes.ENUMS_MODULOS.ENTIDAD.ACCIONES.EDITAR_ENTIDAD.id : Constantes.ENUMS_MODULOS.ENTIDAD.ACCIONES.AGREGAR_ENTIDAD.id);
            if (habilitado && habilitado.resultado) {
                
                let estados = await this.servicioDatosConstantes.obtenerEstadosEntidad();
                this.setSumaState({ estados: estados ? estados : [] });

                let tipoSectores = await this.servicioDatosConstantes.obtenerTipoSectores();
                this.setSumaState({ tipoSectores: tipoSectores ? tipoSectores : [] });

                let idPais = Constantes.ENUMS.PAIS.COLOMBIA.id;
                let entidad;
                if (idEntidad) {
                    // Se consulta la entidad y se carga (Esto es en modo Edición de entidad)
                    entidad = await this.servicioAdministracion.obtenerEntidad( idEntidad );
                    if ( entidad ) {
                        if( entidad.ciudad ){
                            idPais = entidad.ciudad.idPais;
                        }
                        this.setSumaState({componente: this.ENUM_COMPONENTE.VER, entidad:entidad});
                    } else {
                        this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
                    }
                } else {
                    this.setSumaState({ componente: this.ENUM_COMPONENTE.VER });
                }

                let paises = await this.servicioPais.obtenerPaisesPromesa();
                await this.obtenerDepartamentos( idPais );
                this.setSumaState({ paises: paises, cargando: false }); 

                if( entidad ){
                    if(entidad.ciudad){
                        await this.obtenerCiudades(entidad.ciudad.idDepartamento);
                    }
                    this.formRef.current.setFieldsValue({
                        'razonSocial' :         entidad.razonSocial,
                        'sigla' :               entidad.sigla,
                        'nit' :                 entidad.nit,
                        'digitoVerificacion' :  entidad.digitoVerificacion,
                        ...(entidad.tipoSector && {'tipoSector':entidad.tipoSector.id.toString()} ),
                        //'tipoSector' :          entidad.tipoSector.id.toString(),

                        ...(entidad.ciudad && {'pais':entidad.ciudad.idPais.toString()} ),
                        ...(entidad.ciudad && {'region':entidad.ciudad.idDepartamento.toString()} ),
                        ...(entidad.ciudad && {'ciudad':entidad.ciudad.id.toString()} ),

                        //'pais':                 entidad.ciudad.idPais.toString(), 
                        //'region':               entidad.ciudad.idDepartamento.toString(),  
                        //'ciudad':               entidad.ciudad.id.toString(),  

                        'direccion' :           entidad.direccion,
                        'movil' :               entidad.celular,
                        'telefono' :            entidad.telefono, 
                        'correoElectronico':    entidad.correoElectronico,
                        'descripcion' :         entidad.descripcion,
                        ...(entidad.estadoEntidad && {'estado':entidad.estadoEntidad.id.toString()} ),
                        //'estado':               entidad.estadoEntidad.id.toString(),

                    });
                    if( entidad.tipoSector ){
                        this.onChangeTipoSector( entidad.tipoSector.id, undefined, true );
                    }
                }

            } else {
                this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }


    obtenerDepartamentos = async (idPais) =>{
        let form = this.formRef.current;
        this.setSumaState({ cargandoPais: true });

        await this.servicioPais.obtenerDepartamentosDelPaisPromesa( idPais )
        .then(async ( departamentos ) => {
            this.setSumaState({ cargandoPais: false, departamentos: departamentos ? departamentos : [], });
            if( form !== null ){
                form.setFieldsValue({'region':'', 'ciudad':''});
            }
        });
        
    }

    obtenerCiudades = async (idDepartamento) =>{
        let form = this.formRef.current;
        this.setSumaState({ cargandoPais: true });

        await this.servicioPais.obtenerCiudadesDelDepartamentoPromesa( idDepartamento )
        .then(async ( ciudades ) => {
            this.setSumaState({ cargandoPais: false, ciudades: ciudades ? ciudades : [] });
            if( form !== null ){
                form.setFieldsValue({'ciudad':''});
            }
        });
        
    }
    


    errorFormularioIncompleto = errorInfo => {
        // Si no existe la entidad está en modo 'Agregar nuevo entidad', de lo contrario
        // está en modo 'Edición Entidad'
        Modal.warn({ content: "Por favor diligencia la información solicitada para la entidad" });
    };

    formularioCompleto = (values) => {
        return new Promise(resolve => {
            let entidad = {
                correoElectronico: values.correoElectronico.toLowerCase().trim(),
                direccion: values.direccion.trim(),
                celular: values.movil,
                telefono: values.telefono,
                descripcion: values.descripcion.trim(),
                digitoVerificacion: values.digitoVerificacion.trim(),
                nit: values.nit.trim(),
                razonSocial: values.razonSocial.trim(),
                sigla: values.sigla,
                tipoSector: {
                    id: parseInt(values.tipoSector)
                },
                ciudad: {
                    id: parseInt(values.ciudad)
                },
            };
            //console.log('entidad',entidad);
            resolve(entidad);

        }).then(async ( entidad ) => {
            let resultado = { cargando: false, limpiarFormulario: false };
            
            if (!this.state.idEntidad) {
                // Entidad Nueva
                let respuestaApi = await this.servicioAdministracion.crearEntidadAdministracion( entidad );
                if (respuestaApi) {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.ENTIDAD.OPERACION_REALIZADA.id:
                            resultado.satisfactorio = true;
                            resultado.limpiarFormulario = true;
                            this.onSuccess(respuestaApi.resultado);
                            break;
                        case Constantes.ENUMS_API.ENTIDAD.RAZON_SOCIAL_YA_REGISTRADA.id:
                        case Constantes.ENUMS_API.ENTIDAD.NIT_YA_REGISTRADO.id:
                            Modal.error({ content: respuestaApi.mensaje });
                            break;
                        default:
                            Modal.error({ content: 'No fue posible crear la entidad. Por favor intenta mas tarde.' });
                            break;
                    }
                }
            } else {
                // Editar Usuario 
                entidad.id = this.state.idEntidad;
                entidad.estadoEntidad = { id: parseInt(values.estado) };
                
                let respuestaApi = await this.servicioAdministracion.actualizarEntidadAdministracion( entidad );
                if (respuestaApi) {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.ENTIDAD.OPERACION_REALIZADA.id:
                            this.onSuccess(respuestaApi.resultado);
                            break;
                        case Constantes.ENUMS_API.ENTIDAD.RAZON_SOCIAL_YA_REGISTRADA.id:
                        case Constantes.ENUMS_API.ENTIDAD.NIT_YA_REGISTRADO.id:
                            Modal.error({ content: respuestaApi.mensaje });
                            break;    
                        default:
                            Modal.error({ content: 'No fue posible actualizar la entidad. Por favor intenta mas tarde.' });
                            break;
                    }
                }

            }
            return resultado;

        });

    };

    cancelarFormularioEntidad = () => {
        this.onSuccess(this.state.idEntidad, true);
    }

    handlePaisChange = value => {
        this.obtenerDepartamentos( value );
    };
    handleDepartamentoChange = value => {
        this.obtenerCiudades( value );
    }

    onChangeTipoSector = (valor, opcion, noLimpiar ) => {
        let nit, digitoVerificacion;
        for(const tipoSector of this.state.tipoSectores ){
            //console.log('tipoSector',tipoSector);    
            if( tipoSector.id === parseInt(valor) ){
                nit = tipoSector.nit;
                digitoVerificacion = tipoSector.digitoVerificacion;
            }
        }
        this.setSumaState({nit:nit,digitoVerificacion:digitoVerificacion});
        let form = this.formRef.current;
        if( nit ){
            form.setFieldsValue({'nit': nit });
        }else if( !noLimpiar ){
            form.setFieldsValue({'nit': '' });
        }
        if( digitoVerificacion ){
            form.setFieldsValue({'digitoVerificacion':digitoVerificacion});
        }else if( !noLimpiar ){
            form.setFieldsValue({'digitoVerificacion':''});
        }
    }

    render() {
        const { entidad, idEntidad, componente, estados, tipoSectores, cargando,
            paises, departamentos, ciudades, cargandoPais, nit, digitoVerificacion
         } = this.state;
        const { Option } = Select;

        const layout = {
            labelCol: { // Este maneja el grid de los label
                sm: { span: 23, offset: 1 },
                lg: { span: 7, offset: 1 },
            },
            wrapperCol: { // Este maneja el grid de los componentes
                sm: { span: 23, offset: 1 },
                lg: { span: 15, offset: 1 },
            },
        };

        const labelPrimerCol ={ // Este maneja de forma especial el grid de las primeras columnas
                sm: { span: 23, offset: 1 },
                lg: { span: 8, offset: 0 },
        }

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>

                            <div className="titulo-seccion">
                                <label>
                                    { !idEntidad ? 'AGREGAR ' : 'EDITAR '} ENTIDAD
                                </label>
                                <div className="separador-horizontal-completo margen-bottom-10"></div>
                            </div>

                            <div className="sub-titulo-seccion">
                                <label>Datos entidad</label>
                                <div className="separador-horizontal-completo02 margen-bottom-20"></div>
                            </div>
                            <Spin tip={'por favor espera...'} size="large" spinning={cargando}>

                                <Form
                                    {...layout}
                                    ref={this.formRef}
                                    name="datos-dependencia"
                                    className="formulario-suma"
                                    scrollToFirstError
                                    onFinish={ this.formularioCompleto }
                                    onFinishFailed={ this.errorFormularioIncompleto }
                                >

                                    <Form.Item label="id" name="id" noStyle>
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Razón social" name="razonSocial"
                                                rules={[
                                                    { required: true, message: 'Ingresa la razón social de la entidad', },
                                                    { min: 4, message: 'No puede ser menor a 4 caracteres', },
                                                    { max: 500, message: 'No puede ser mayor a 500 caracteres', },
                                                ]}
                                                labelCol={labelPrimerCol}
                                            >
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Sigla" name="sigla"
                                                rules={[
                                                    { max: 100, message: 'No puede ser mayor a 100 caracteres', },
                                                ]}
                                            >
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item  label="Tipo sector" name="tipoSector" 
                                                        rules=  {[{ required: true, message: 'Selecciona el tipo de sector',},]}
                                                        labelCol={labelPrimerCol}>
                                                <Select onChange={this.onChangeTipoSector}>
                                                    {tipoSectores.map( tipoSector => (
                                                        <Option key={ tipoSector.id }>{ tipoSector.tipoSector }</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} sm={12}>
                                            <Form.Item label="NIT" style={{ marginBottom: 0 }}>
                                                <Form.Item name="nit" 
                                                    style={{ display: 'inline-block', width: 'calc(80% - 8px)' }}
                                                    rules=  {[
                                                                { required: true, message: 'Ingresa el NIT',},
                                                                { min:5, message: 'No puede ser menor a 5 caracteres',},
                                                                { max:50, message: 'No puede ser mayor a 50 caracteres',},
                                                                ({ getFieldValue }) => ({
                                                                    validator(rule, value) {
                                                                        if ( value && !Validadores.validarDigitos( value ).success ) {
                                                                            return Promise.reject('Ingresa solo dígitos');
                                                                        }
                                                                        return Promise.resolve();
                                                                    },
                                                                }),
                                                            ]} 
                                                >
                                                    <Input autoComplete="off" disabled={nit} />
                                                </Form.Item>
                                                <Popover content={(<div>Ingresa sólo un dígito</div>)} title="Digito Verificación" trigger="focus">
                                                <Form.Item name="digitoVerificacion" 
                                                    style={{ display: 'inline-block', width: 'calc(20% - 0px)', margin: '0 0 0 8px' }}
                                                    rules=  {[
                                                                { required: true, message: 'Dígito',},
                                                                { max:5, message: '*',},
                                                                () => ({
                                                                    validator(rule, value) {
                                                                        if ( value && !Validadores.validarDigitos( value ).success ) {
                                                                            return Promise.reject('*');
                                                                        }
                                                                        return Promise.resolve();
                                                                    },
                                                                }),
                                                            ]} 
                                                >       
                                                    <Input autoComplete="off" disabled={digitoVerificacion} />
                                                </Form.Item>
                                                </Popover>
                                            </Form.Item>
                                        </Col>          

                                    </Row>

                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item  label="Pais" name="pais" initialValue={`${Constantes.ENUMS.PAIS.COLOMBIA.id}`} 
                                                        rules={[{ required: true, message:'Selecciona tu pais'},]}
                                                        {...(cargandoPais ? {validateStatus:'validating', hasFeedback:true } : {})}
                                                        labelCol={labelPrimerCol}>
                                                <Select {...(cargandoPais ? {disabled:true } : {})} onChange={this.handlePaisChange} >
                                                    {paises.map( pais => (
                                                        <Option key={ pais.id }>{ pais.pais }</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item  label="Región" name="region" 
                                                        rules={[{ required: true, message:'Selecciona tu región'},]}
                                                        {...(cargandoPais ? {validateStatus:'validating', hasFeedback:true } : {})}>
                                                <Select {...(cargandoPais ? {disabled:true } : {})} onChange={this.handleDepartamentoChange}>
                                                    {departamentos.map( departamento => (
                                                        <Option key={ departamento.id }>{ departamento.departamento }</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item  label="Ciudad" name="ciudad" 
                                                        rules={[{ required: true, message: 'Selecciona tu ciudad',},]}
                                                        {...(cargandoPais ? {validateStatus:'validating', hasFeedback:true } : {})}
                                                        labelCol={labelPrimerCol}>
                                                <Select {...(cargandoPais ? {disabled:true } : {})} >
                                                    {ciudades.map( ciudad => (
                                                        <Option key={ ciudad.id }>{ ciudad.ciudad }</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Dirección entidad" name="direccion"
                                                rules={[
                                                    { required: true, message: 'Ingresa la dirección', },
                                                    { max: 500, message: 'No puede ser mayor a 500 caracteres', },
                                                ]}>
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Teléfono" name="telefono"
                                                rules={[
                                                    { required: false },
                                                    { min: 7, message: 'No puede ser menor a 7 caracteres', },
                                                    { max: 30, message: 'No puede ser mayor a 30 caracteres', },
                                                ]}
                                                labelCol={labelPrimerCol}>
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Móvil" name="movil"
                                                rules={[
                                                    { min: 7, message: 'No puede ser menor a 7 caracteres', },
                                                    { max: 20, message: 'No puede ser mayor a 20 caracteres', },
                                                    { pattern: new RegExp('^[0-9\\+\\s]*$'), message: 'Solo dígitos y puede contener el simbolo +', },
                                                ]} 
                                            >
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24} sm={12}>
                                            <Form.Item label="Correo electrónico" name="correoElectronico"
                                                rules={[
                                                    { required: true, message: 'Ingresa el correo electrónico de la entidad', },
                                                    { type: 'email', message: 'Ingresa un correo electrónico válido', },
                                                    { max: 255, message: 'No puede ser mayor a 255 caracteres', },
                                                ]}
                                                labelCol={labelPrimerCol}
                                            >
                                                <Input autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        { entidad ? 
                                        <Col xs={24} sm={12}>
                                            <Form.Item  label="Estado" name="estado" rules=  {[{ required: true, message: 'Selecciona el estado',},]}>
                                                <Select>
                                                    {estados.map( estado => (
                                                        <Option key={ estado.id }>{ estado.estadoEntidad }</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        : (<></>)}
                                    </Row>
                                    <Row>
                                        <Col xs={24}>
                                            <Form.Item label="Descripcion" name="descripcion"
                                                rules={[
                                                    { required: true, message: 'Ingresa una descripción de la entidad', },
                                                    { min: 7, message: 'No puede ser menor a 10 caracteres', },
                                                    { max: 4000, message: 'No puede ser mayor a 4000 caracteres', },
                                                ]}
                                                labelCol={{ span: 24, offset: 0 }}
                                                wrapperCol={{ span: 24, offset: 0 }}

                                            >
                                                <Input.TextArea rows={4} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col xs={24}>
                                            <Form.Item wrapperCol={{ span: 24, offset: 0 }}>
                                                <Button type="primary"
                                                    htmlType="submit"
                                                    loading={cargando}>
                                                    Guardar
                                                </Button>
                                                &nbsp;&nbsp;
                                                <Popconfirm title={'Confirma cancelar la ' + (entidad ? 'edición' : 'creación') + ' de la entidad?'} 
                                                            okText="Si"
                                                            cancelText="No" 
                                                            onConfirm={this.cancelarFormularioEntidad}>
                                                    <Button icon={<CloseOutlined/>}>Cancelar</Button>
                                                </Popconfirm>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Spin>
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:AEENT]</label>) : (<></>))
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(AgregarEditarEntidad));