import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Spin, Modal, Table, Button, Tooltip, Tag } from 'antd';
import { withCookies } from 'react-cookie';
import { ProfileOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import ServicioEntidad from '../servicios/ServicioEntidad'
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios';
import ServicioSolicitud from '../servicios/ServicioSolicitud'
import ServicioDatosConstantes from '../servicios/ServicioDatosConstantes'
import Constantes from '../utilidades/Constantes'
import Utilidades from '../utilidades/Utilidades'
import FiltroColumna from '../general/tabla/FiltroColumna'
import FiltroEtiqueta from '../general/tabla/FiltroEtiqueta'
//import moment from 'moment';
import _ from 'lodash';

class ListarSolicitudesDependencia extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        vistaSolicitudesDependencia: null,
        cargando: false,        

        filtro: {},
        filtroEtiquetas: [],
        filtroApi : {},
        paginacion: {
            current: 1,
            pageSize: 10,
            ordenarColumna: 'codigoSolicitud', 
            orden: 'descend'
        },
        cargandoTabla: true
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.callbackVerSolicitud = props.callbackVerSolicitud;

        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioEntidad = new ServicioEntidad(props);
        this.servicioSolicitud = new ServicioSolicitud(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        let contexto = this;
        this.filtroColumna = new FiltroColumna(props,( valores ) => {contexto.setSumaState(valores, contexto)});
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores, contexto) {
        if( !contexto )
            contexto = this;
            
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        this.setSumaState({ cargando: true });

        // Consultando el usuario en sesión
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr !== null) {
            // Si es usuario de tipo ADMINISTRADOR valida que tenga la acción habilitada de acceder a las SOLICITUDES
            if( usr.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.ADMINISTRADOR_SUMA.id ){
                let habilitado = await this.servicioUsuarios.accionHabilitadaPromesa(Constantes.ENUMS_MODULOS.SOLICITUDES.ACCIONES.ACCEDER_MODULO_SOLICITUDES.id);
                if (!habilitado || !habilitado.resultado) {
                    // Si no está habilitado, se anula el usuario
                    usr = null;
                }
            }else{
                // Validando que el usuario sea de tipo ENTIDAD
                if(await this.servicioEntidad.usuarioValido() === null){
                    // Si no es de tipo entidad, se anula el usuario
                    usr = null;
                }
            }
        }


        if (usr !== null) {
            const estados = await this.servicioDatosConstantes.obtenerEstadosSolicitud();
            let estadosSolicitudes = [];
            if( estados ){
                for( let estado of estados ){
                    estadosSolicitudes.push({ label: estado.estadoSolicitud, value: estado.id });
                }
            }
            await this.cargarDatosTabla(this);
            this.setSumaState({
                componente: this.ENUM_COMPONENTE.VER,cargando: false,
                estadosSolicitudes: estadosSolicitudes,usuario: usr
            });
        } else {
            this.setSumaState({componente:this.ENUM_COMPONENTE.NO_DISPONIBLE,cargando: false,usuario: null});
        }
    }

    // 'limpiarFiltro': Método (clearFilters) que es propio de la tabla antDesign para limpiar los filtros
    // 'confirmarFiltro': Método (confirm) que es própio de la tabla antDesign para aplicar el filtro
    // 'dataIndex': Retorna el campo que se está filtrando
    // 'selectedKeys': Retorna el valor a filtrar;
    async cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) {
        let filtro = contexto.state.filtro;
        if (dataIndex !== undefined) { // Si el filtro es aplicado, se agrega la columna filtrada
            filtro[dataIndex] = selectedKeys;
        }
        contexto.setSumaState({ filtro: filtro });

        // Se construye el filtroApi a partir de lo que fue filtrado en la tabla
        let filtroApi = {
            ...(filtro.codigoSolicitud && filtro.codigoSolicitud.length > 0 && {codigo:filtro.codigoSolicitud[0]} ),
            ...(filtro.codigoSolicitudDependencia && filtro.codigoSolicitudDependencia.length > 0  && {solDependencia:filtro.codigoSolicitudDependencia[0]} ),
            ...(filtro.fechaRegistro && filtro.fechaRegistro.length > 0 && {fechasRegistro:filtro.fechaRegistro[0] + '-' +filtro.fechaRegistro[1]} ),
            ...(filtro.fechasOcupacion && filtro.fechasOcupacion.length > 0 && {fechasOcupacion:filtro.fechasOcupacion[0] + '-' +filtro.fechasOcupacion[1]} ),
            ...(filtro.estadoSolicitud && filtro.estadoSolicitud.length > 0 && filtro.estadoSolicitud[0].opc && {estado:filtro.estadoSolicitud[0].opc.join()} ),
            ...(filtro.estadoSolicitud && filtro.estadoSolicitud.length > 0 && filtro.estadoSolicitud[0].texto && {estadoDependencia:filtro.estadoSolicitud[0].texto} ),
            ...(filtro.usuario && filtro.usuario.length > 0 && {usuario:filtro.usuario[0]} ),
            ...(filtro.datosAdicionales && filtro.datosAdicionales.length > 0 && {datos:filtro.datosAdicionales[0]} ),
            ...(filtro.localidad && filtro.localidad.length > 0 && {localidad:filtro.localidad[0]} ),
            ...(filtro.tipoSolicitud && filtro.tipoSolicitud.length > 0 && {tipoSolicitud:filtro.tipoSolicitud[0]} ),
        };

        const paginacion = this.state.paginacion;
        let paginacionApi = {
            paginaActual:this.state.paginacion.current,
            paginaTamano:this.state.paginacion.pageSize,
            ordenarColumna:this.state.paginacion.ordenarColumna,
            orden: this.state.paginacion.orden
        }

        let cambio = false;
        // Primero valida si hubo cambio a nivel de filtro
        if( !_.isEqual(this.state.filtroApi, filtroApi) ){
            //console.log("Cambio filtro");
            paginacion.current = 1;
            paginacionApi.paginaActual = 1;
            cambio = true;
        }

        // Segundo valida si hubo cambio a nivel de paginación
        if( !cambio && !_.isEqual(this.state.paginacionApi, paginacionApi) ){
            //console.log("Cambio paginación");
            //console.log("paginacionApi",paginacionApi);
            cambio = true;
        }
        
        if( !cambio ){
            // Si no hubo cambio no se hace nada
            return;
        }

        contexto.setSumaState({  
            cargandoTabla: true, 
            filtro, 
            filtroApi, 
            paginacionApi, 
            paginacion
        });

        await this.obtenerSolicitudesDependencia( 
                {   
                    ...filtroApi,
                    ...paginacionApi
                } 
            )
            .then(( tablaPaginada ) => {
                const paginacion = this.state.paginacion;
                paginacion.total = tablaPaginada.total;
                console.log( 'tablaPaginada',tablaPaginada );
                contexto.setSumaState({
                    vistaSolicitudesDependencia: tablaPaginada.registros,
                    /*cargando: false,*/
                    cargandoTabla: false,
                    paginacion
                });
            }).then(() => {
                if (limpiarFiltro !== undefined) { limpiarFiltro(); }
                if (confirmarFiltro !== undefined) { confirmarFiltro(); }
            });
    }

    obtenerSolicitudesDependencia( filtroApi ) {
        return new Promise(resolve => {
            this.servicioSolicitud.obtenerSolicitudesDependencia(
                filtroApi,
                (dataAPI) => {
                    if (dataAPI.codigo === Constantes.ENUMS_API.SOLICITUDES.DATOS_RETORNADOS.id) {
                        resolve(dataAPI.resultado);
                    } else {
                        resolve(null);
                        Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:LSD03]" });
                    }
                },
                (errorAPI) => {
                    resolve(null);
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:LSD02]" });
                },
            );
        });
    }

    handleTableChange = async (pagination, filters, sorter) => {
        const paginacion = this.state.paginacion;
        paginacion.current = pagination.current;
        paginacion.pageSize = pagination.pageSize;
        paginacion.ordenarColumna = sorter.field;
        paginacion.orden = sorter.order;
        this.setSumaState({  paginacion });
        await this.cargarDatosTabla(this);
    };

    render() {

        const { componente, vistaSolicitudesDependencia, cargando, estadosSolicitudes, usuario } = this.state;
        let { filtro } = this.state;
        filtro = filtro || {};
        let contexto = this;
        const columns = [
            {
                title: 'Código',
                align: 'center',
                dataIndex: 'codigoSolicitud',
                key: 'codigoSolicitud',
                width: 80,
                defaultSortOrder: 'descend',
                sorter: (a, b) => 0, //a.codigoSolicitud - b.codigoSolicitud,
                filteredValue: filtro.codigoSolicitud || null,
                ...this.filtroColumna.busquedaTexto(
                    'Código',
                    'codigoSolicitud',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                onCell:(record)=> ({ style:{background: record.color}}),
                render(text, record) { return <>{text}</>}
            },
            {
                title: 'Solicitud',
                align: 'center',
                dataIndex: 'codigoSolicitudDependencia',
                key: 'codigoSolicitudDependencia',
                width: 60,
                sorter: (a, b) => 0, //a.codigoSolicitudDependencia ? a.codigoSolicitudDependencia.localeCompare(b.codigoSolicitudDependencia) : 1,
                filteredValue: filtro.codigoSolicitudDependencia || null,
                ...this.filtroColumna.busquedaTexto(
                    'Solicitud',
                    'codigoSolicitudDependencia',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                onCell:(record)=> ({ style:{background: record.color}}),
                render(text, record) { return <>{text}</>}
            },
            {
                title: 'Fecha Registro',
                align: 'center',
                dataIndex: 'fechaRegistro',
                key: 'fechaRegistro',
                width: 100, 
                sorter: (a, b) => 0, //moment(a.fechaTiempoRegistroEstandar, Constantes.FORMATO_FECHA_TIEMPO_ESTANDAR) - moment(b.fechaTiempoRegistroEstandar, Constantes.FORMATO_FECHA_TIEMPO_ESTANDAR),
                filteredValue: filtro.fechaRegistro || null,
                ...this.filtroColumna.busquedaFecha(
                    'Fecha Registro',
                    'fechaRegistro',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                onCell:(record)=> ({ style:{background: record.color}}),
                render(text, record) { return <>{text}</>},
                responsive: ['sm'], /* visible en pantalla con ancho ≥ 576px*/
            },
            {
                title: 'Fechas ocupación',
                dataIndex: 'fechasOcupacion',
                align: 'center',
                key: 'fechasOcupacion',
                width: 100,
                filteredValue: filtro.fechasOcupacion || null,
                ...this.filtroColumna.busquedaFecha(
                    'Fechas ocupación',
                    'fechasOcupacion',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                onCell:(record)=> ({ style:{background: record.color}}),
                render(text, record) {
                    return (   
                        <>
                            {record.fechasOcupacion.map((fechaOcupacion, index) =>
                                <span key={index} className="span-bloque lineh16">
                                    {fechaOcupacion}
                                </span>
                            )}

                            {record.fechasOcupacionOpcional.map((fechaOcupacion, index) =>
                                <span key={index} className="span-bloque lineh16 fecha-opcional ">
                                    {fechaOcupacion}
                                </span>
                            )}
                        </>
                    )
                },
                responsive: ['md'], /* visible en pantalla con ancho ≥ 768px*/
            },
            {
                title: 'Estado',
                dataIndex: 'estadoSolicitud',
                key: 'estadoSolicitud',
                sorter: (a, b) => 0, //a.estadoSolicitud ? a.estadoSolicitud.localeCompare(b.estadoSolicitud) : 1,
                filteredValue: filtro.estadoSolicitud || null,
                ...this.filtroColumna.busquedaOpcionesConTexto(
                    'Estado',
                    'estadoSolicitud',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) },
                    estadosSolicitudes
                ),
                onCell:(record)=> ({ style:{background: record.color}}),
                render(text, record) {
                    return (
                        <>
                            <span className="span-bloque"><strong>{text}</strong></span>
                            <span className="span-bloque">{record.estadoDependencia}</span>

                            {(record.etiquetas !== undefined) ?
                                record.etiquetas.map((etiqueta, index) =>
                                    <Tag key={index} color={etiqueta.color}>
                                        {etiqueta.etiqueta}
                                    </Tag>
                                ) : (<></>)
                            }

                        </>
                    )
                },
                responsive: ['sm'], /* visible en pantalla con ancho ≥ 576px*/
            },
            {
                title: 'Usuario',
                dataIndex: 'usuario',
                key: 'usuario',
                sorter: (a, b) => 0, //a.nombreUsuario ? a.nombreUsuario.localeCompare(b.nombreUsuario) : 1,
                filteredValue: filtro.usuario || null,
                ...this.filtroColumna.busquedaTexto(
                    'Usuario',
                    'usuario',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                onCell:(record)=> ({ style:{background: record.color}}),
                render(text, record) {
                    return (
                        <>
                            {(record.idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.PERSONA_JURIDICA.id)
                                ? (
                                    <>
                                        <span className="span-bloque lineh16 texto-info02">{record.razonSocialUsuario}</span>
                                    </>
                                )
                                : (<></>)
                            }
                            <span className="span-bloque lineh16">{record.nombreUsuario}</span>
                            <span className="span-bloque lineh16 texto-info01">{record.tipoUsuario}</span>
                        </>
                    )
                },
                responsive: ['sm'], /* visible en pantalla con ancho ≥ 576px*/
            },
            {
                title: 'Datos Adicionales',
                align: 'center',
                dataIndex: 'datosAdicionales',
                key: 'datosAdicionales',
                sorter: (a, b) => 0, 
                filteredValue: filtro.datosAdicionales || null,
                ...this.filtroColumna.busquedaTexto(
                    'Datos Adicionales',
                    'datosAdicionales',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                onCell:(record)=> ({ style:{background: record.color}}),
                render(text, record) {
                    return (
                        <>
                            {Utilidades.interpretadorHTML(text)}
                        </>
                    )
                },
                responsive: ['md'], /* visible en pantalla con ancho ≥ 768px*/
            },
            {
                title: 'Localidad',
                align: 'center',
                dataIndex: 'localidad',
                key: 'localidad',
                sorter: (a, b) => 0, 
                filteredValue: filtro.localidad || null,
                ...this.filtroColumna.busquedaTexto(
                    'Localidad',
                    'localidad',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                onCell:(record)=> ({ style:{background: record.color}}),
                render(text, record) { return <>{text}</>},
                responsive: ['xl'], /* visible en pantalla con ancho ≥ 1200px*/
            },
            {
                title: 'Tipo',
                align: 'center',
                dataIndex: 'tipoSolicitud',
                key: 'tipoSolicitud',
                sorter: (a, b) => 0, 
                filteredValue: filtro.tipoSolicitud || null,
                ...this.filtroColumna.busquedaTexto(
                    'Tipo solicitud',
                    'tipoSolicitud',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                onCell:(record)=> ({ style:{background: record.color}}),
                render(text, record) { return <>{text}</>},
                responsive: ['xl'], /* visible en pantalla con ancho ≥ 1200px*/
            },
            {
                title: 'Opciones',
                key: 'opciones',
                align: 'center',
                className: 'columna-opciones',
                width: 112,
                onCell:(record)=> ({ style:{background: record.color}}),
                render(text, record) {
                    return (
                        <>
                            <Tooltip title="Ver solictud">
                                <Button className="boton-opcion-list" shape="circle"
                                    icon={<EyeOutlined style={{ 'color': '#c90a00' }} />}
                                    onClick={(e) => {
                                        contexto.callbackVerSolicitud(e, record.codigoSolicitud);
                                    }} />
                            </Tooltip>
                                    
                            { // Si el usuario es de tipo ENTIDAD se cargan las opciones de la dependencia
                            usuario.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id && 
                                Utilidades.opcionesDependenciaSolicitudPorRol(record.opcionesRoles,usuario.rol.id,usuario.usuarioDependencia.dependencia.id).map((opcionDependenciaSolicitud, index) =>
                                    <Tooltip title={opcionDependenciaSolicitud.opcionDependencia} key={index}>
                                        <Button className="boton-opcion-list btn-dependencia"
                                            shape="circle"
                                            icon={<Utilidades.IconoAntDesign type={opcionDependenciaSolicitud.icono} />}
                                            style={{ 'color': opcionDependenciaSolicitud.color }}
                                            onMouseOver={(e) => { e.target.style.borderColor = opcionDependenciaSolicitud.color; }}
                                            onMouseLeave={(e) => { e.target.style.borderColor = ""; }}
                                            onClick={(e) => {
                                                Utilidades.onClickRedireccionOpcionDependencia( contexto.servicioSolicitud,record.idSolicitud,opcionDependenciaSolicitud.id );
                                            }}
                                        />
                                    </Tooltip>
                                )
                            }
                        </>
                    )
                },
            },
        ];

        return (
            <>

                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>

                            <div className="titulo-seccion">
                                <label> <ProfileOutlined />SOLICITUDES</label>
                                <div className="separador-horizontal-completo"></div>
                            </div>

                            <Spin tip="Por favor espera..." size="large" spinning={cargando}>

                                {this.state.filtroEtiquetas.length > 0 ?
                                (<div className="seccion-filtros-etiquetas">
                                    <div className="titulo-filtro-etiquetas">
                                        <label>Filtros <SearchOutlined /> :</label>
                                    </div>
                                    {this.state.filtroEtiquetas.map((filtroEtiqueta, i) => {
                                        return (
                                            <FiltroEtiqueta key={i} 
                                                            dataIndex={filtroEtiqueta.dataIndex}
                                                            texto={filtroEtiqueta.texto}
                                                            onClose={filtroEtiqueta.borrarFiltro}/>
                                        )})}
                                </div>):(<></>)}
                                

                                <Table
                                    columns={columns}
                                    dataSource={vistaSolicitudesDependencia}
                                    className="tabla-comprimida tabla-solicitudes"
                                    onChange={this.handleTableChange}
                                    pagination={this.state.paginacion}
                                    loading={this.state.cargandoTabla}
                                    sortDirections={['ascend', 'descend', 'ascend']}
                                />
                            </Spin>

                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:LSD]</label>) : (<></>))
                    )
                }
            </>
        );
    }
}

export default withRouter(withCookies(ListarSolicitudesDependencia));