import React, { Component } from 'react';

const SumaContexto = React.createContext();

//export const SumaProvider = SumaContexto.Provider
export const SumaConsumer = SumaContexto.Consumer

class SumaProvider extends Component {
  // Context state
  state = {
    usuario: {},
    imagenPerfil_sc: null,
  }

  // Method to update state
  setFrontUsuario = (usuario) => {
    this.setState((prevState) => ({ usuario }))
  }

  // Method to update state
  setImagenPerfil_sc = (imagenPerfil_sc) => {
    this.setState((prevState) => ({ imagenPerfil_sc }))
  }

  limpiarSumaContexto = () =>{
    this.setState({
      usuario: {},
      imagenPerfil_sc: null,
    })
  }

  render() {
    const { children } = this.props
    const { usuario, imagenPerfil_sc } = this.state
    const { setFrontUsuario, setImagenPerfil_sc, limpiarSumaContexto } = this

    return (
      <SumaContexto.Provider value={{ usuario, imagenPerfil_sc, setFrontUsuario, setImagenPerfil_sc, limpiarSumaContexto}} >
        {children}
      </SumaContexto.Provider>
    )
  }
}


export default SumaContexto;

export { SumaProvider }