import Constantes from './Constantes'
import React from 'react';
import axios from 'axios';

const Utilidades = {

    servicioHTTPErrorControladoGeneral: (error) => {
        if (error !== undefined && error.response !== undefined &&
            (
                error.response.status === Constantes.CODIGOS_HTTP.NO_AUTORIZADO ||
                error.response.status === Constantes.CODIGOS_HTTP.SIN_CONEXION ||
                error.response.status === Constantes.CODIGOS_HTTP.USUARIO_CERRO_SESION
            )
        ) {
            return true;
        }
        return false;
    },

    servicioHTTPErrorControladoSinConexion: (error) => {
        if (error !== undefined && error.response !== undefined &&
            (
                error.response.status === Constantes.CODIGOS_HTTP.SIN_CONEXION
            )
        ) {
            return true;
        }
        return false;
    },

    IconoAntDesign: ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        let Component = icons[type];
        // Si el icono no se encuentra dentro de la librería de 
        // iconos de AntDesign, se deja como icóno por defecto
        // el interrogante dentro de un círculo
        if (Component === undefined) {
            Component = icons["QuestionCircleOutlined"];
        }
        return <Component {...rest} />
    },

    esString: (valor) => {
        return typeof valor === 'string' || valor instanceof String;
    },

    aBooleano: (o) => {
        if (null !== o) {
            let t = typeof o;
            if ("undefined" !== typeof o) {
                if ("string" !== t) return !!o;
                o = o.toLowerCase().trim();
                return "true" === o || "1" === o;
            }
        }
        return false;
    },

    obtenerTamanoEnString: (size) => {
        if (size <= 1000) {
            return size + " Bytes";
        } else if (size > 1000 && size < 1000000) {
            return (size / 1000).toFixed(2) + " KB";
        } else if (size >= 1000000) {
            return (size / 1000000).toFixed(2) + " MB";
        }
    },

    obtenerTipoDeArchivoPermitido: (extensiones) => {
        if (extensiones.includes('image')) {
            return 'Imagen';
        } else if (extensiones.includes('pdf')) {
            return 'PDF';
        } else {
            return 'N.N.';
        }
    },

    opcionesDependenciaSolicitudPorRol: (opcionesRoles, idRol, idDependencia) => {
        let opcionesDependenciaSolicitud = [];
        let patron = new RegExp(`^((\\d+?)¬(.+?)¬(.+?)¬(.+?)¬(.+?))~(?:|.+?[^\\d]{1})${idRol}(?:[^\\d]{1}.+?|)$`, 'i');
        if (opcionesRoles) {
            for (let opcionRoles of opcionesRoles.split('^')) {
                let match = opcionRoles.match(patron);
                if (match !== null) {
                    let idDep = parseInt(match[3]);
                    if (idDependencia === undefined ||
                        idDependencia === idDep) {
                        //console.log('idDependencia: ' + idDependencia + ' idDep:'+idDep);
                        //console.log(match[2] + ', ' + match[3] + ', ' + match[4] + ', ' + match[5] + ', ' + match[6]);
                        opcionesDependenciaSolicitud.push(
                            {
                                id: parseInt(match[2]),
                                opcionDependencia: match[4].trim(),
                                ...(!/^\s*$/.test(match[5]) && { color: match[5] }),
                                ...(!/^\s*$/.test(match[6]) && { icono: match[6] }),
                            }
                        );
                    }

                }
            }
        }
        return opcionesDependenciaSolicitud;
    },

    onClickRedireccionOpcionDependencia: async (servicioSolicitud, idSolicitud, idOpcionDependencia) => {
        Utilidades.bloquearPantalla();
        let enlaceAcceso = await servicioSolicitud.obtenerUrlOpcionDependencia(idSolicitud, idOpcionDependencia);
        if (enlaceAcceso) {
            window.location = enlaceAcceso;
        } else {
            Utilidades.desbloquearPantalla();
        }
    },

    accionPermitida: (usuario, idAccion) => {
        if (usuario != null && usuario !== undefined && usuario.rol !== undefined && usuario.rol.rolAcciones !== undefined) {
            for (let i = 0; i < usuario.rol.rolAcciones.length; i++) {
                if (usuario.rol.rolAcciones[i].accion.id === idAccion) {
                    return true;
                }
            }
        }
        return false;
    },

    bloquearPantalla: () => {
        let bloqueoUi = document.getElementById("bloqueo-ui");
        bloqueoUi.style.display = "block";
        setTimeout(() => { 
            let bloqueoUi = document.getElementById("bloqueo-ui");
            bloqueoUi.style.display = "none";
        }, 12000);
    },

    desbloquearPantalla: () => {
        let bloqueoUi = document.getElementById("bloqueo-ui");
        bloqueoUi.style.display = "none";
    },

    saltoDeLinea: (texto) => {
        if (!texto)
            return '';

        return texto.split("\n").map(function (item, idx) {
            return (
                <span key={idx}>
                    {item}
                    <br />
                </span>
            )
        });
    },

    interpretadorHTML: (texto) => {
        if (!texto)
            return '';
        //Primero se interpreta el salto de linea para trabajar por linea
        return texto.split("\n").map(function (item, idx) {
            return (
                <span key={idx} className="span-bloque">
                    {
                        //Sobre cada línea se evalua si tiene patrón de negrilla
                        Utilidades.interpretadorNegrillaHTML(item)
                    }
                </span>
            )
        });
    },

    dividirTexto: (texto, patron) => {
        if (!texto)
            return '';
        //Primero se interpreta el salto de linea para trabajar por linea
        return texto.split( patron ).map(function (item, idx) {
            return (
                <span key={idx} className="span-bloque">
                    {item}
                </span>
            )
        });
    },

    interpretadorNegrillaHTML: (texto) => {
        if (texto === undefined || texto === null) {
            return (<></>);
        }

        texto = texto.split('**');
        if (texto.length === 3) {
            return (
                <>
                    {texto[0]}
                    <strong>{texto[1]}</strong>
                    {texto[2]}
                </>
            )
        }

        /*let patronNegrilla = /^(.*){0,}?\*\*(.*?)\*\*(.*)$/;
        let grupo = texto.match(patronNegrilla);
        if (grupo !== null) {
            return (
                <>
                    {grupo[1] ? grupo[1] : ''}
                    <strong>{grupo[2]}</strong>
                    {grupo[3] ? grupo[3] : ''}
                </>
            )
        }*/


        return (<>{texto}</>);
    },

    obtenerBase64DesdeUrl: async (url) => {
        try {
            const data = await fetch(url);
            const blob = await data.blob();
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    const base64data = reader.result;
                    resolve(base64data);
                }
            });
        } catch (e) {
            console.log('Error controlado', e);
        }
    },

    obtenerBase64DesdeUrlConAxios: (url, callbackProgreso) => {
        return new Promise(resolve => {
            axios.get(url, {
                responseType: 'arraybuffer',
                onDownloadProgress(progressEvent) {
                    if (callbackProgreso) {
                        let total = progressEvent.total;
                        let loaded = progressEvent.loaded;
                        if( total === 0 ){ total = loaded };
                        let percentCompleted = parseInt((loaded / total) * 100);
                        callbackProgreso(percentCompleted);
                    }
                }
            })
                .then(response => {
                    let data = Buffer.from(response.data, 'binary').toString('base64');
                    resolve(data);
                }).catch(err => {
                    console.error('Error al descargar archivo en la url: ' + url, err);
                    resolve();
                });
        });
    },

    //Convierte el objeto 'color' de la librería 'React Color' a representación
    // rgb en string
    colorToRgb: (color) => {
        return color ? `rgb(${color.r},${color.g},${color.b},${color.a})` : '';
    },

    // Convierte un string en formato 'rgb(255,255,255,1)' en un objeto de tipo
    // 'color' de la librería 'React Color'
    rgbToColor: (rgb) => {

        // Verifica si la variable rgb es de tipo hexadecimal
        let hexToRgb = Utilidades.hexToRgb(rgb);
        if (hexToRgb !== null) {
            hexToRgb.a = 1;
            return hexToRgb;
        }

        let patron = new RegExp(`^rgb\\(([\\d]*?),([\\d]*?),([\\d]*?),([\\d]*?)\\)$`, 'i');
        if (rgb) {
            let match = rgb.match(patron);
            if (match !== null) {
                return { r: parseInt(match[1]), g: parseInt(match[2]), b: parseInt(match[3]), a: parseInt(match[4]) };
            }
        }
        return {};
    },

    hexToRgb: (hex) => {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    },

    generarAlfanumerico: (tamano) => {
        let t = tamano ? tamano : 5;
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < t; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    },

    guionEspacio: (texto) => {
        if (texto) {
            return texto.replaceAll("_", " ")
        }
        return texto;
    },

    archivoABase64: (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    }),

    extraerEnlace: ( idEnlace ) => {
        if(Constantes.PARAMETROS_GENERALES_ENLACES){
            for( let i = 0; i < Constantes.PARAMETROS_GENERALES_ENLACES.length; i++){
                if( Constantes.PARAMETROS_GENERALES_ENLACES[i].id === idEnlace ){
                    return Constantes.PARAMETROS_GENERALES_ENLACES[i].valor;
                }
            }
        }
        return "";
    },

    convertirCaracteresHexUrl: (url) => {

        let relaciones = [
            {caracter: 'á',  hex:'E1'},
            {caracter: 'Á',  hex:'C1'},
            {caracter: 'é',  hex:'E9'},
            {caracter: 'É',  hex:'C9'},
            {caracter: 'í',  hex:'ED'},
            {caracter: 'Í',  hex:'CD'},
            {caracter: 'ó',  hex:'F3'},
            {caracter: 'Ó',  hex:'D3'},
            {caracter: 'ú',  hex:'FA'},
            {caracter: 'Ú',  hex:'DA'},
            {caracter: 'ü',  hex:'FC'},
            {caracter: 'Ü',  hex:'DC'},
            {caracter: 'ñ',  hex:'F1'},
            {caracter: 'Ñ',  hex:'D1'},
            {caracter: '¿',  hex:'BF'},
            {caracter: '¡',  hex:'A1'},
        ];

        relaciones.forEach(relacion => {
            
            url = url.replaceAll('%'+relacion.hex, relacion.caracter);
            
        });

        return url;
    }


}

export default Utilidades;