import axios from 'axios';
import Constantes from '../../utilidades/Constantes'
import ServicioUsuarios from '../usuarios/ServicioUsuarios'
import Utilidades from '../../utilidades/Utilidades'
import { Modal } from 'antd';

class ServicioPersonaNatural {

    constructor( props ){ 
        const { cookies } = props;
        this.cookies = cookies;
        this.servicioUsuarios = new ServicioUsuarios( props );
    }

    // Método privado
    usuarioValido() {
        return new Promise(resolve => {
            this.servicioUsuarios.obtenerUsuarioLogeado(
              ( dataAPI )    => { 
                    if( dataAPI.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.PERSONA_NATURAL.id ){
                        resolve( dataAPI );
                    }else{
                        Modal.error({ content: "Contenido no disponible. [Cod:SPN01]" });
                        resolve( null );
                    }
                },
              ( errorAPI )   => { 
                  Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SPN02]" });
                  resolve( null );},
            );
        });
    }

    // Método público
    crearUsuario ( callbackComplete, callbalkError, usr ) {
        //const config= {};
        axios.post( Constantes.URL_API_SUMA + '/persona-natural/crear-usuario', usr)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoSinConexion(err)){
                return;
            }
            console.error('[Error.ServicioPersonaNatural.crearUsuario]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    obtenerDatos ( callbackComplete, callbalkError ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        axios.get( Constantes.URL_API_SUMA + '/persona-natural/datos',config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaNatural.obtenerDatos]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    guardarDatos ( callbackComplete, callbalkError, usrPersonaNatural ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.put( Constantes.URL_API_SUMA + '/persona-natural/datos', usrPersonaNatural, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaNatural.guardarDatos]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    crearApoderado ( callbackComplete, callbalkError, apoderado ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.post( Constantes.URL_API_SUMA + '/persona-natural/apoderado', apoderado, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaNatural.crearApoderado]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    actualizarApoderado ( callbackComplete, callbalkError, apoderado ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.put( Constantes.URL_API_SUMA + '/persona-natural/apoderado', apoderado, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaNatural.actualizarApoderado]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    obtenerApoderado ( callbackComplete, callbalkError, idApoderado ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        axios.get( Constantes.URL_API_SUMA + '/persona-natural/apoderado/' + idApoderado, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaNatural.obtenerApoderado]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    obtenerApoderados ( filtroApi ) {
        let token = this.cookies.get('sumajwt');
        filtroApi = filtroApi ? filtroApi : {};
        const config= { headers:{ 'Authorization': `Bearer ${token}` }, params: filtroApi};

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/persona-natural/apoderado',config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Obtener Apoderados. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioPersonaNatural.obtenerApoderados]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SPN03]" }); 
                resolve();
            });
        });
    }




    // Método privado
    actualizarApoderadoArchivo ( callbackComplete, callbalkError, apoderadoArchivo ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.put( Constantes.URL_API_SUMA + '/persona-natural/apoderado-archivo/', apoderadoArchivo, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaNatural.actualizarApoderadoArchivo]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    agregarApoderadoArchivo ( callbackComplete, callbalkError, apoderadoArchivo ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.post( Constantes.URL_API_SUMA + '/persona-natural/apoderado-archivo/', apoderadoArchivo, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaNatural.agregarApoderadoArchivo]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    activarApoderado ( callbackComplete, callbalkError, idApoderado, activar ) {
        let datos = {};
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.put( Constantes.URL_API_SUMA + '/persona-natural/apoderado/' + ( activar ? 'activar' : 'desactivar') + '/' + idApoderado, datos, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaNatural.activarApoderado]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    obtenerArchivos ( callbackComplete, callbalkError ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        axios.get( Constantes.URL_API_SUMA + '/persona-natural/archivo',config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaNatural.obtenerArchivos]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    actualizarPersonaNaturalArchivo ( callbackComplete, callbalkError, personaNaturalArchivo ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.put( Constantes.URL_API_SUMA + '/persona-natural/archivo/', personaNaturalArchivo, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaNatural.actualizarPersonaNaturalArchivo]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    agregarPersonaNaturalArchivo ( callbackComplete, callbalkError, personaNaturalArchivo ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.post( Constantes.URL_API_SUMA + '/persona-natural/archivo/', personaNaturalArchivo, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioPersonaNatural.agregarPersonaNaturalArchivo]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    eliminarApoderado ( idApoderado ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}`} };

        return new Promise(resolve =>{
            axios.delete( Constantes.URL_API_SUMA + '/persona-natural/apoderado/'+idApoderado,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error Eliminar Apoderado. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioPersonaNatural.eliminarApoderado]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SPN04]" }); 
                resolve();
            });
        });
    }

    // Método privado
    obtenerTotalApoderados () {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise(resolve =>{
            axios.get( Constantes.URL_API_SUMA + '/persona-natural/total-apoderados',config)
            .then(res => {
                if(res.status === 200){
                    let respuestaApi = res.data;
                    if( respuestaApi && respuestaApi.codigo === Constantes.ENUMS_API.PERSONA_NATURAL.DATOS_RETORNADOS.id ){
                        resolve( respuestaApi.resultado );
                        return;
                    }else{
                        resolve();
                        return;
                    }
                }else{
                    throw new Error("Error Obtener total apoderados. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioPersonaNatural.obtenerTotalApoderados]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SPN05]" }); 
                resolve();
            });
        });
    }

}

export default ServicioPersonaNatural;