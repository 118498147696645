import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Tabs, Spin } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import ServicioAdministracion from '../servicios/ServicioAdministracion'
import Constantes from '../utilidades/Constantes'
import Utilidades from '../utilidades/Utilidades'
import { withCookies } from 'react-cookie';
import RelacionTipoSolicitudDocumento from './RelacionTipoSolicitudDocumento';


const { TabPane } = Tabs;

class Documentos extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3,
        NO_HABILITADO: 4,
    }

    PATHS_TABS = {
        RELACION_TIPO_SOLICITUD: {
            pestana: '/relacion-tipo-solicitud'
        },
    };

    state = {
        cargando: true,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        tab: this.PATHS_TABS.RELACION_TIPO_SOLICITUD.pestana,
    };


    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
    }
    


    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        
        let usuario = await this.servicioUsuarios.usuarioEnSesion();
        if ( usuario !== null) {
            let habilitado = Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.COMUNICACIONES.ACCIONES.ACCEDER_MODULO_COMUNICACIONES.id);
            if( habilitado ){
                this.setSumaState({ componente: this.ENUM_COMPONENTE.VER, cargando: true });
                this.setSumaState({ cargando: false, usuario });
            }else{
                this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_HABILITADO }); 
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }

    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    manejadorClickTab = e => {
        // Aqui se maneja de forma distintita, dado que no se procede a cambiar la 
        // url por pestaña consultada
        this.setSumaState({ tab: e });
    };


    render() {
        const { componente, tab, usuario } = this.state;

        return (
            <div className="seccion-principal contenedor-completo seccion-flex ">
                <div className="division-principal">
                    <label>Documentos</label>
                </div>
                <div className="division-contenido seccion-flex-grow-1 padding-principal bkg-white contenido-usuarios">
                    {(componente === this.ENUM_COMPONENTE.VER) ?
                        (
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>

                                <div className="titulo-seccion">
                                        <label> <CopyOutlined /> DOCUMENTOS</label>
                                    <div className="separador-horizontal-completo"></div>
                                </div>

                                <div>
                                    <Tabs activeKey={this.state.tab} onChange={this.manejadorClickTab}>
                                        <TabPane tab="Relación tipo solicitud" key={this.PATHS_TABS.RELACION_TIPO_SOLICITUD.pestana} />
                                    </Tabs>

                                    {tab === this.PATHS_TABS.RELACION_TIPO_SOLICITUD.pestana ? 
                                    <>
                                        <RelacionTipoSolicitudDocumento usuario={usuario} />
                                    </>
                                    : 
                                    <></>}
                                    
                                </div>

                            </Spin>
                        )
                        :
                        (
                            (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : (
                                (componente === this.ENUM_COMPONENTE.NO_HABILITADO ? 
                                <label>Configuración no disponible. {Constantes.MENSAJE_USUARIO_SIN_PERMISOS}</label> :
                                (componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:DOCUMENTOS]</label>) : (<></>))
                            )
                        )
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(withCookies(Documentos));