import React, { Component } from 'react';
import SumaContexto from '../../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Spin, Button } from 'antd';
import queryString from 'query-string';
import { withCookies } from 'react-cookie';
import { ProfileOutlined , FormOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import Constantes from '../../../utilidades/Constantes'
import ServicioAdministracion from '../../../servicios/ServicioAdministracion'
import ServicioUsuarios from '../../../servicios/usuarios/ServicioUsuarios';

class SeleccionTipoOpcion extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        vistaUsuarios: null,
        cargando: false,
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores, contexto) {
        if( !contexto )
            contexto = this;
            
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        let idDependencia = undefined;

        if (urlparametros.id !== undefined && !isNaN(parseInt(urlparametros.id))) {
            idDependencia = parseInt(urlparametros.id);
        }
        
        this.setSumaState({ cargando: true, });
        // Validando que el usuario esté en sesion
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        //usr = await this.servicioAdministracion.usuarioValido();
        if (usr !== null ) {
            this.setSumaState({componente: this.ENUM_COMPONENTE.VER,cargando: false, 
                    usuario: usr, idDependencia: idDependencia});
        } else {
            this.setSumaState({componente:this.ENUM_COMPONENTE.NO_DISPONIBLE,cargando: false});
        }
    }

    
    render() {

        const { componente, } = this.state;
        
        return (
            <>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>            
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>

                                <div className="titulo-seccion">
                                    <label>TIPO DE OPCIÓN</label>
                                    <div className="separador-horizontal-completo"></div>
                                    <p className="descripcion-seccion">Por favor selecciona el tipo de opción a crear:</p>
                                </div>

                                <div className="seleccion-tipo-usuario">
                                    <div className="opcion-tipo-usuario persona-natural">
                                        <Button type="primary" onClick={()=>{this.props.callbackAdministrarOpcionDependencia( Constantes.ENUMS.TIPO_OPCION.SOLICITUD.id, this.state.idDependencia, undefined )}} icon={<ProfileOutlined  />}/>
                                        <label>SOLICITUD</label>
                                    </div>
                                    <div className="opcion-tipo-usuario persona-natural">
                                        <Button type="primary" onClick={()=>{this.props.callbackAdministrarOpcionDependencia( Constantes.ENUMS.TIPO_OPCION.OPCION_SUMA.id, this.state.idDependencia, undefined )}} icon={<AppstoreAddOutlined />}/>
                                        <label>OPCION SUMA</label>
                                    </div>    
                                    <div className="opcion-tipo-usuario persona-natural">
                                        <Button type="primary" onClick={()=>{this.props.callbackAdministrarOpcionDependencia( Constantes.ENUMS.TIPO_OPCION.REGISTRAR_SOLICITUD.id, this.state.idDependencia, undefined )}} icon={<FormOutlined />}/>
                                        <label>REGISTRAR SOLICITUD</label>
                                    </div>    
                                </div>

                            </Spin>
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:SETIOPC]</label>) : (<></>))
                    )
                }
            </>
        );
    }
}

export default withRouter(withCookies(SeleccionTipoOpcion));