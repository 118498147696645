import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter, Switch, Route } from "react-router-dom";
import { Button, Spin, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Constantes from '../../utilidades/Constantes'
import ServicioPersonaJuridica from '../../servicios/usuarios/ServicioPersonaJuridica'
import { withCookies } from 'react-cookie';
import AgregarEditarApoderadoJuridica from './AgregarEditarApoderadoJuridica'
import VerApoderadoJuridica from './VerApoderadoJuridica'
import ListarApoderadosJuridica from './ListarApoderadosJuridica'
import Utilidades from '../../utilidades/Utilidades';
import { HookReactRouterV6Parametros } from '../../general/hooks/HookReactRouterV6Parametros';

class ApoderadosJuridica extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3,
        DILIGENCIAR_DATOS_BASICOS:4
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        idApoderado: null,
        usuario: null,
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.administracion = props.administracion;
        //console.log('ApoderadosJuridica.props',props);

        this.servicioPersonaJuridica = new ServicioPersonaJuridica(props);

        this.PATHS = {
            APODERADOS:         this.administracion ? 
                                `/persona-juridica/:idPersonaJuridica/apoderados` :
                                '/persona-juridica-apoderados',

            AGREGAR_APODERADO:  this.administracion ? 
                                `/persona-juridica/:idPersonaJuridica/agregar/apoderado` : 
                                '/persona-juridica-agregar-apoderado',

            EDITAR_APODERADO:   this.administracion ? 
                                `/persona-juridica/:idPersonaJuridica/editar/apoderado/:idApoderado` : 
                                '/persona-juridica-editar-apoderado',

            VER_APODERADO:      this.administracion ? 
                                `/persona-juridica/:idPersonaJuridica/apoderado/:idApoderado` : 
                                '/persona-juridica-ver-apoderado',
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        // Validando que el usuario es PERSONA JURIDICA
        let usr = await this.servicioPersonaJuridica.usuarioPersonaJuridicaOAdministradorValido();
        if( usr && usr !== null ){
            if( usr.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.PERSONA_JURIDICA.id ){
                if( usr.usuarioPersonaJuridica ){
                    this.setState({ componente: this.ENUM_COMPONENTE.VER });    
                }else{
                    this.setState({ componente: this.ENUM_COMPONENTE.DILIGENCIAR_DATOS_BASICOS });
                }
            }else if( usr.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.ADMINISTRADOR_SUMA.id ){
                this.setState({ componente: this.ENUM_COMPONENTE.VER });    
            }else{
                this.setState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
            }
        }else{
            this.setState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
        
        this.setSumaState({ usuario: usr });
    }

    apoderadoGuardado(nuevoApoderado, idApoderado, cancelar) {
        if (typeof cancelar === 'boolean' && cancelar) {
            this.setSumaState({
                idApoderado: (nuevoApoderado ? 0 : idApoderado)
            });
            if( nuevoApoderado ){
                this.historyLocal.push( this.administracion ?
                    this.reemplazarValoresEnElPath( this.PATHS.APODERADOS ) 
                    :
                    this.PATHS.APODERADOS);    
            }else{
                this.historyLocal.push( this.administracion ?
                    this.reemplazarValoresEnElPath( this.PATHS.VER_APODERADO, idApoderado ) 
                    :
                    (this.PATHS.VER_APODERADO + '?id=' + idApoderado));
            }
            return;
        }
        this.setSumaState({ idApoderado: idApoderado });
        this.historyLocal.push( this.administracion ?
            this.reemplazarValoresEnElPath( this.PATHS.VER_APODERADO, idApoderado ) 
            :
            this.PATHS.VER_APODERADO + '?id=' + idApoderado);
        Modal.success({ content: "El apoderado ha sido " + (nuevoApoderado ? "creado" : "actualizado") });
    }

    clickVerApoderado = (e, idApoderado) => {
        this.setSumaState({ idApoderado: idApoderado });
        this.historyLocal.push( this.administracion ?
            this.reemplazarValoresEnElPath( this.PATHS.VER_APODERADO, idApoderado ) 
            :
            this.PATHS.VER_APODERADO + '?id=' + idApoderado );
    }

    clickActivarApoderado = (e, idUsuario, usuarioNombre, activar, callback) => {
        this.setSumaState({ cargando: true, });
        this.servicioPersonaJuridica.activarApoderado(
            (respuestaApi) => {
                switch (respuestaApi.codigo) {
                    case Constantes.ENUMS_API.PERSONA_JURIDICA.OPERACION_REALIZADA.id:
                        this.setSumaState({ cargando: false });
                        Modal.success({ content: 'Apoderado ' + usuarioNombre + ' ' + (activar ? 'Activado' : 'Desactivado') });
                        if (callback !== undefined) {
                            callback();
                        }
                        break;
                    default:
                        Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:APJ02]" });
                        break;
                }
            },
            (errorAPI) => {
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:APJ03]" });
            },
            idUsuario,
            activar,
            // Si está en modo administración del SUMA, este dato se captura de la url por medio de HookReactRouterV6Parametros
            this.props.idPersonaJuridica
        );
    };

    eliminarApoderado = async (idApoderado, apoderadoNombre) => {
        this.setSumaState({ cargando: true });

        let respuestaApi = await this.servicioPersonaJuridica.eliminarApoderado(
            idApoderado,
            // Si está en modo administración del SUMA, este dato se captura de la url por medio de HookReactRouterV6Parametros
            this.props.idPersonaJuridica 
        );
        if (respuestaApi) {
            
            switch (respuestaApi.codigo) {
                case Constantes.ENUMS_API.PERSONA_JURIDICA.OPERACION_REALIZADA.id:
                    this.setSumaState({ cargando: false });
                    Modal.success({ content: 'Apoderado "' + apoderadoNombre + '" eliminado' });
                    this.historyLocal.push( this.reemplazarValoresEnElPath(this.PATHS.APODERADOS) );
                    break;
                default:
                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:APJ04]" });
                    break;
            }
        }
    };

    agregarApoderado = e => {
        this.setSumaState({ idApoderado: 0 });
        this.historyLocal.push( this.reemplazarValoresEnElPath(this.PATHS.AGREGAR_APODERADO,undefined) );
    }

    clickEditarApoderado = (e, idApoderado) => {
        this.setSumaState({ idApoderado: idApoderado });
        this.historyLocal.push( this.administracion ?
            this.reemplazarValoresEnElPath( this.PATHS.EDITAR_APODERADO, idApoderado ) 
            :
            this.PATHS.EDITAR_APODERADO + '?id=' + idApoderado);
    }

    reemplazarValoresEnElPath  = (path, idApoderado) => {
        return path
        // Se toma el idPersonaJuridica de la url por medio del HookReactRouterV6Parametros
        .replace(':idPersonaJuridica',this.props.idPersonaJuridica)
        .replace(':idApoderado',idApoderado);
    }

    render() {
        //let  {form} = this.props.form;
        //const { usuario } = this.context
        const { componente, usuario } = this.state;

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <Switch>

                                <Route path={this.PATHS.VER_APODERADO}>
                                    <VerApoderadoJuridica   callbackEditarApoderado={this.clickEditarApoderado}
                                                            callbackActivarApoderado={this.clickActivarApoderado}
                                                            callbackEliminarApoderado={this.eliminarApoderado} 
                                                            // Indica que es el administrador del SUMA quien está accediendo al representante legal
                                                            administracion={this.administracion} 
                                                            // Se toma el idPersonaJuridica de la url por medio del HookReactRouterV6Parametros
                                                            idPersonaJuridica={this.props.idPersonaJuridica}
                                                            // Se toma el idApoderado de la url por medio del HookReactRouterV6Parametros
                                                            idApoderado={this.props.idApoderado}/>
                                </Route>

                                <Route path={this.PATHS.AGREGAR_APODERADO}>
                                    <AgregarEditarApoderadoJuridica onSuccess={(idApoderado, cancelar) => { this.apoderadoGuardado(true, idApoderado, cancelar); }} 
                                                                    // Indica que es el administrador del SUMA quien está accediendo al representante legal
                                                                    administracion={this.administracion} 
                                                                    // Se toma el idPersonaJuridica de la url por medio del HookReactRouterV6Parametros
                                                                    idPersonaJuridica={this.props.idPersonaJuridica}/>
                                </Route>

                                <Route path={this.PATHS.EDITAR_APODERADO}>
                                    <AgregarEditarApoderadoJuridica onSuccess={(idApoderado, cancelar) => { this.apoderadoGuardado(false, idApoderado, cancelar); }} 
                                                                    // Indica que es el administrador del SUMA quien está accediendo al representante legal
                                                                    administracion={this.administracion} 
                                                                    // Se toma el idPersonaJuridica de la url por medio del HookReactRouterV6Parametros
                                                                    idPersonaJuridica={this.props.idPersonaJuridica}
                                                                    // Se toma el idApoderado de la url por medio del HookReactRouterV6Parametros
                                                                    idApoderado={this.props.idApoderado}/>
                                </Route>

                                <Route path={this.PATHS.APODERADOS}>
                                    <>
                                        <div className="div-lista-botones">
                                            <Button type="primary"
                                                onClick={this.agregarApoderado}
                                                icon={<PlusOutlined />}
                                                disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_APODERADOS.ACCIONES.AGREGAR_APODERADO.id)}>
                                                Agregar apoderado
                                            </Button>
                                        </div>
                                        < ListarApoderadosJuridica  callbackVerApoderado={this.clickVerApoderado}
                                                                    callbackActivarApoderado={this.clickActivarApoderado} 
                                                                    // El idPersonaJuridica se puede tomar por el componente padre como atributo, si no llega por aquí, se toma la perona jurídica del usuario que está logueado.
                                                                    idPersonaJuridica={this.props.idPersonaJuridica}/>
                                    </>
                                </Route>

                            </Switch>

                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            :(componente === this.ENUM_COMPONENTE.DILIGENCIAR_DATOS_BASICOS) ?
                            (<label>Para habilitar esta sección debes diligenciar y guardar primero los datos de la entidad persona jurídica.</label>)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:APDRSJUR]</label>) : (<></>))
                    )
                }

            </div>
        );
    }
}

export default withRouter(withCookies(HookReactRouterV6Parametros(ApoderadosJuridica)));