import React, { Component } from 'react';
import SumaContexto from '../../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Spin, Tooltip, Button, Table } from 'antd';
import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import ServicioDatosConstantes from '../../../servicios/ServicioDatosConstantes'
import ServicioUsuarios from '../../../servicios/usuarios/ServicioUsuarios'
import ServicioAdministracion from '../../../servicios/ServicioAdministracion'
import Constantes from '../../../utilidades/Constantes'
import Utilidades from '../../../utilidades/Utilidades'
import queryString from 'query-string';
import { withCookies } from 'react-cookie';
import FiltroColumna from '../../../general/tabla/FiltroColumna'
import FiltroEtiqueta from '../../../general/tabla/FiltroEtiqueta'

class ListaRolesDependencia extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3,
        NO_HABILITADO: 4
    }

    state = {
        cargando: true,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        usuario: undefined,

        filtro: {},
        filtroEtiquetas: [],
    };


    constructor(props) {
        super(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioAdministracion = new ServicioAdministracion(props);

        let contexto = this;
        this.filtroColumna = new FiltroColumna(props,( valores ) => {contexto.setSumaState(valores, contexto)});
    }


    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        let idDependencia = undefined;

        if (urlparametros.id !== undefined && !isNaN(parseInt(urlparametros.id))) {
            idDependencia = parseInt(urlparametros.id);
        }
        this.setSumaState({ idDependencia });
        
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr !== null) {

            let habilitado = Utilidades.accionPermitida(usr, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.LISTAR_ROLES.id);
            if (habilitado) {
                this.setSumaState({ usuario: usr, componente: this.ENUM_COMPONENTE.VER, cargando: true });
                await this.cargarDatosTabla(this);
            }else{
                this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_HABILITADO });
            }
            this.setSumaState({ cargando: false });
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }

    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }


    // 'limpiarFiltro': Método (clearFilters) que es propio de la tabla antDesign para limpiar los filtros
    // 'confirmarFiltro': Método (confirm) que es própio de la tabla antDesign para aplicar el filtro
    // 'dataIndex': Retorna el campo que se está filtrando
    // 'selectedKeys': Retorna el valor a filtrar;
    async cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) {
        let filtro = contexto.state.filtro;
        if (dataIndex !== undefined) { // Si el filtro es aplicado, se agrega la columna filtrada
            filtro[dataIndex] = selectedKeys;
        }        
        contexto.setSumaState({ cargando: true, filtro: filtro });

        // Se construye el filtroApi a partir de lo que fue filtrado en la tabla
        let filtroApi = {
            ...(filtro.id && filtro.id.length > 0 && {id:filtro.id[0]} ),
            ...(filtro.rol && filtro.rol.length > 0 && {rol:filtro.rol[0]} ),
            ...(filtro.descripcion && filtro.descripcion.length > 0 && {descripcion:filtro.descripcion[0]} ),
        };
        
        await this.servicioAdministracion.obtenerRolesDependencia( this.state.idDependencia, filtroApi )
            .then(( vistaRoles ) => {
                contexto.setSumaState({ vistaRoles, cargando: false });
                //console.log('vistaRoles',vistaRoles);
            }).then(() => {
                if (limpiarFiltro !== undefined) { limpiarFiltro(); }
                if (confirmarFiltro !== undefined) { confirmarFiltro(); }
            });
    }


    render() {
        const { componente, usuario, vistaRoles } = this.state;
        let { filtro } = this.state;
        filtro = filtro || {};
        let contexto = this;

        const columns = [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                with:100,
                sorter: (a, b) => a.id - b.id,
                filteredValue: filtro.id || null,
                ...this.filtroColumna.busquedaTexto(
                    'Identificador',
                    'id',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            {
                title: 'Rol',
                dataIndex: 'rol',
                key: 'rol',
                sorter: (a, b) => a.rol ? a.rol.localeCompare(b.rol) : 1,
                filteredValue: filtro.rol || null,
                ...this.filtroColumna.busquedaTexto(
                    'Rol',
                    'rol',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            {
                title: 'Descripción',
                dataIndex: 'descripcion',
                key: 'descripcion',
                sorter: (a, b) => a.descripcion ? a.descripcion.localeCompare(b.descripcion) : 1,
                filteredValue: filtro.descripcion || null,
                ...this.filtroColumna.busquedaTexto(
                    'Descripción',
                    'descripcion',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                responsive: ['sm'], /* visible en pantalla con ancho ≥ 576px*/
            },
            
            {
                title: 'Opciones',
                key: 'opciones',
                align: 'center',
                className: 'columna-opciones',
                width: 112,
                render: (text, record) => (
                    <>
                        <Tooltip title="Ver opción">
                            <Button shape="circle" disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.VER_ROL.id)}
                                icon={<EyeOutlined style={{ 'color': '#c90a00' }} />}
                                onClick={(e) => { this.props.callbackAdministrarRol( undefined, record.id) }} />
                        </Tooltip>
                    </>
                ),
            },
        ];

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>

                                <h3 className="sub-titulo-separador">Roles de la dependencia</h3>
                                <div className="seccion-datos seccion-final">

                                    {
                                        this.state.filtroEtiquetas.length > 0 ?
                                            (<div className="seccion-filtros-etiquetas">
                                                <div className="titulo-filtro-etiquetas">
                                                    <label>Filtros <SearchOutlined /> :</label>
                                                </div>

                                                {
                                                    this.state.filtroEtiquetas.map((filtroEtiqueta, i) => {
                                                        return (
                                                            <FiltroEtiqueta key={i}
                                                                dataIndex={filtroEtiqueta.dataIndex}
                                                                texto={filtroEtiqueta.texto}
                                                                onClose={filtroEtiqueta.borrarFiltro} />
                                                        )
                                                    })
                                                }
                                            </div>)
                                            :
                                            (<></>)
                                    }

                                    <Table  columns={columns}
                                            dataSource={ vistaRoles ? vistaRoles : []  }
                                            rowKey={"id"} />
                                </div>

                            </Spin>

                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : (
                                (componente === this.ENUM_COMPONENTE.NO_HABILITADO ?
                                    <label>Listar Roles dependencia no disponible. {Constantes.MENSAJE_USUARIO_SIN_PERMISOS}</label> :
                                (componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:LSTROLDEP]</label>) : (<></>))
                            )
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(ListaRolesDependencia));