import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Spin, Modal, Select, Col, Row, Divider, Empty, Button, Progress } from 'antd';
import { VerticalAlignBottomOutlined, FileSearchOutlined } from '@ant-design/icons';
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import ServicioAdministracion from '../servicios/ServicioAdministracion'
import Constantes from '../utilidades/Constantes'
import Utilidades from '../utilidades/Utilidades'
import { withCookies } from 'react-cookie';

class Registros extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3,
        NO_HABILITADO: 4,
    }


    state = {
        cargando: true,
        componente: this.ENUM_COMPONENTE.CARGANDO,
    };


    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
    }



    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let usuario = await this.servicioUsuarios.usuarioEnSesion();
        if (usuario !== null) {
            let habilitado = Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.CONFIGURACION.ACCIONES.ACCEDER_REGISTROS.id);
            if (habilitado) {
                this.setSumaState({ componente: this.ENUM_COMPONENTE.VER, cargando: true });


                let respuestaApi = await this.servicioAdministracion.obtenerListaDeRegistros();
                if (respuestaApi.codigo === Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id) {
                    let registros = [];
                    for (let i = 0; i < respuestaApi.resultado.length; i++) {
                        let archivoDto = respuestaApi.resultado[i];
                        archivoDto.id = i;
                        registros.push(
                            this.renderItemArchivo(archivoDto)
                        );
                    }
                    this.setSumaState({ registros, archivosDto: respuestaApi.resultado });
                } else {
                    Modal.error({ content: `Error al consultar la lista de registros. CodigoApi: ${respuestaApi.codigo} MensajeApi: ${respuestaApi.mensaje}` });
                }

                this.setSumaState({ cargando: false, usuario });
            } else {
                this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_HABILITADO });
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }

    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    renderItemArchivo = (archivoDto) => ({
        key: archivoDto.id,
        value: archivoDto.id,
        label: (
            <div className={"item-personalizado"}>
                <span className={"titulo-item"}>{`${archivoDto.nombre}    [${Utilidades.obtenerTamanoEnString(archivoDto.tamano)}]`}</span><br />
                <span className={"sub-titulo-item"}>Modificación: {archivoDto.fechaModificacionFormato}</span>
            </div>
        ),
    });

    handleChange = async (value) => {
        this.setSumaState({ cargando: true, contenidoRegistro: undefined });
        for (let archivoDto of this.state.archivosDto) {
            if (value === archivoDto.id) {
                this.setSumaState({ archivoDto });
            }
        }
        this.setSumaState({ cargando: false });
    };

    verContenidoRegistro = async () => {
        this.setSumaState({ cargando: true });
        let respuestaApi = await this.servicioAdministracion.obtenerRegistro(this.state.archivoDto.nombre);
        if (respuestaApi.codigo === Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id) {
            this.setSumaState({ contenidoRegistro: respuestaApi.resultado });
        } else {
            Modal.error({ content: `Error al consultar el registro. CodigoApi: ${respuestaApi.codigo} MensajeApi: ${respuestaApi.mensaje}` });
        }
        this.setSumaState({ cargando: false });
    }

    async descargarReporte() {
        let contexto = this;
        contexto.setSumaState({ cargando: true, descargando: true, porcentaje: 0 });
        await this.servicioAdministracion.descargarRegistro(
            this.state.archivoDto.nombre,
            (percentCompleted) => {
                contexto.setSumaState({ porcentaje: percentCompleted });
            }
        )
            .then((respuesta) => {
                if (respuesta) {
                    switch (respuesta.status) {
                        case 200:
                            const type = respuesta.headers['content-type'];
                            const nombre = respuesta.headers['filename'];
                            const blob = new Blob([respuesta.data], { type: type });
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = url;
                            link.download = nombre;
                            link.click();
                            link.remove();
                            this.setSumaState({ cargando: false, descargando: false });
                            break;
                        default:
                            this.setSumaState({ descargando: false });
                            Modal.error({ content: "No fue posible descargar el registro. [Cod:REG01]" });
                            break;
                    }
                }
            });
    }

    render() {
        const { componente, registros, archivoDto, contenidoRegistro, descargando, porcentaje } = this.state;

        const wrapperCol = {
            xs: { span: 21, offset: 1 },
            sm: { span: 11, offset: 1 },
        };

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>

                                <Modal
                                    visible={descargando}
                                    closable={false}
                                    footer={null}
                                    width={320}
                                    style={{ textAlign: 'center' }}
                                >
                                    <label style={{ marginBottom: 13, display: 'block' }}>
                                        Generando reporte...
                                    </label>
                                    <Progress type="circle" percent={porcentaje} width={80} />
                                </Modal>

                                <div>
                                    <label className='titulo'>Selecciona el registro: </label>
                                    <Select
                                        onChange={this.handleChange}
                                        style={{ width: '100%', }}
                                        options={registros}
                                    />
                                </div>
                                <Divider />
                                <div className="seccion-datos">
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Nombre:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{archivoDto ? archivoDto.nombre : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Tamaño:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{archivoDto ? Utilidades.obtenerTamanoEnString(archivoDto.tamano) : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Fecha creación:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{archivoDto ? archivoDto.fechaCreacionFormato : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Fecha modificación:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{archivoDto ? archivoDto.fechaModificacionFormato : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className="fila" style={{ marginTop: 15 }}>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <Button icon={<FileSearchOutlined />} onClick={this.verContenidoRegistro} disabled={!archivoDto}>Ver Archivo</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <Button icon={<VerticalAlignBottomOutlined />} onClick={()=>{this.descargarReporte()}} disabled={!archivoDto}>Descargar Archivo</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </div>

                                <Divider />

                                {contenidoRegistro ?
                                    <div className='div-contenedor-registro'>
                                        {contenidoRegistro}
                                    </div>
                                    :
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                }

                            </Spin>

                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : (
                                (componente === this.ENUM_COMPONENTE.NO_HABILITADO ?
                                    <label>Configuración no disponible. {Constantes.MENSAJE_USUARIO_SIN_PERMISOS}</label> :
                                    (componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:Registros]</label>) : (<></>))
                            )
                    )
                }
            </div>

        );
    }
}

export default withRouter(withCookies(Registros));