import React, { Component } from 'react';
import SumaContexto  from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import {  BankOutlined } from '@ant-design/icons';
import CrearUsuario from '../zona-usuario/crear-cuenta/CrearUsuario.js'

class CrearUsuarioPersonaJuridica extends Component {

    static contextType = SumaContexto;
  
    render() {
      
      //const { usuario } = this.context
      
        return (
          <div className="seccion-principal contenedor-completo seccion-flex ">

            <div className="division-principal">
              <label>Crear cuenta / Persona jurídica</label>
            </div>
            <div className="division-contenido seccion-flex-grow-1 padding-principal bkg-white">
                <div className="titulo-seccion">
                    <label> <BankOutlined className="icon-background-yellow" /> CREAR NUEVA CUENTA PERSONA JURÍDICA</label>
                    <div className="separador-horizontal-completo"></div>
                    <p className="descripcion-seccion">Diligencie los siguientes campos:</p>
                </div>

                <div className="seccion-formulario">
                    
                    <CrearUsuario />

                </div>
              </div>
          </div>
        );
    }
}
          
export default withRouter(CrearUsuarioPersonaJuridica);