import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter, Switch , Route } from "react-router-dom";
import { Spin } from 'antd';
import { withCookies } from 'react-cookie';
import ServicioEntidad from '../servicios/ServicioEntidad';
import ServicioSolicitud from '../servicios/ServicioSolicitud';
import ListarSolicitudesAsociadas from './ListarSolicitudesAsociadas';
import VerSolicitud from '../mis-solicitudes/VerSolicitud';
import EstilosGlobales from '../utilidades/EstilosGlobales';

class SolicitudesAsociadas extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    PATHS = {
        SOLICITUDES_ASOCIADAS:         '/solicitudes-asociadas',
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        codigoSolicitud: null,
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioEntidad = new ServicioEntidad( props );
        this.servicioSolicitud = new ServicioSolicitud( props );

    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        // Validando que el usuario sea de tipo ENTIDAD
        let usr = await this.servicioEntidad.usuarioValido();
        if (usr !== null) {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.VER });
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }


    clickVerSolicitud = ( e, codigoSolicitud ) => {
        this.setSumaState({ codigoSolicitud: codigoSolicitud });
    }

    render() {

        const { componente, codigoSolicitud } = this.state;

        return (
            <>
                <EstilosGlobales.SOLICITUDES />
                <div className="seccion-principal contenedor-completo seccion-flex ">

                    <div className="division-principal">
                        <label>Solicitudes asociadas</label>
                    </div>
                    <div className="division-contenido seccion-flex-grow-1 padding-principal bkg-white contenido-solicitudes">

                        {(componente === this.ENUM_COMPONENTE.VER) ?
                            (
                                <>
                                    <Switch>
                                        <Route path={this.PATHS.SOLICITUDES_ASOCIADAS}>
                                            <ListarSolicitudesAsociadas callbackVerSolicitud={this.clickVerSolicitud}/>
                                        </Route>
                                    </Switch>
                                    
                                    {( codigoSolicitud !== null ) ?
                                        <VerSolicitud   codigoSolicitud={codigoSolicitud} 
                                                        callbackVerSolicitud={this.clickVerSolicitud}/>
                                        :
                                        <></>
                                    }

                                </>
                            )
                            :
                            (
                                (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                                    (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                                    : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:SOLASO]</label>) : (<></>))
                            )
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(withCookies(SolicitudesAsociadas));