import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Spin, Tooltip, Button, Table } from 'antd';
import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import ServicioDatosConstantes from '../../servicios/ServicioDatosConstantes'
import ServicioUsuarios from '../../servicios/usuarios/ServicioUsuarios'
import ServicioAdministracion from '../../servicios/ServicioAdministracion'
import Constantes from '../../utilidades/Constantes'
import Utilidades from '../../utilidades/Utilidades'
import { withCookies } from 'react-cookie';
import FiltroColumna from '../../general/tabla/FiltroColumna'
import FiltroEtiqueta from '../../general/tabla/FiltroEtiqueta'
import EstilosGlobales from '../../utilidades/EstilosGlobales';

class ListarRoles extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3,
        NO_HABILITADO: 4
    }

    state = {
        cargando: true,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        usuario: undefined,

        filtro: {},
        filtroEtiquetas: [],
    };


    constructor(props) {
        super(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioAdministracion = new ServicioAdministracion(props);

        let contexto = this;
        this.filtroColumna = new FiltroColumna(props,( valores ) => {contexto.setSumaState(valores, contexto)});
    }


    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {        
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr !== null) {
            let habilitado = Utilidades.accionPermitida(usr, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.LISTAR_ROLES.id);
            if( habilitado ){

                this.setSumaState({ usuario: usr, componente: this.ENUM_COMPONENTE.VER, cargando: true });
                const tipos = await this.servicioDatosConstantes.obtenerTiposUsuario();
                let tiposUsuario = [];
                if( tipos ){
                    for( let tipo of tipos ){
                        tiposUsuario.push({ label: tipo.tipoUsuario, value: tipo.id });
                    }
                }
                await this.cargarDatosTabla(this);
                this.setSumaState({ cargando: false, tiposUsuario });
                
            }else{
                this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_HABILITADO }); 
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }


    // 'limpiarFiltro': Método (clearFilters) que es propio de la tabla antDesign para limpiar los filtros
    // 'confirmarFiltro': Método (confirm) que es própio de la tabla antDesign para aplicar el filtro
    // 'dataIndex': Retorna el campo que se está filtrando
    // 'selectedKeys': Retorna el valor a filtrar;
    async cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) {
        let filtro = contexto.state.filtro;
        if (dataIndex !== undefined) { // Si el filtro es aplicado, se agrega la columna filtrada
            filtro[dataIndex] = selectedKeys;
        }        
        contexto.setSumaState({ cargando: true, filtro: filtro });

        // Se construye el filtroApi a partir de lo que fue filtrado en la tabla
        let filtroApi = {
            ...(filtro.id && filtro.id.length > 0 && {id:filtro.id[0]} ),
            ...(filtro.rol && filtro.rol.length > 0 && {rol:filtro.rol[0]} ),
            ...(filtro.tipoUsuario && filtro.tipoUsuario.length > 0 && filtro.tipoUsuario[0].opc && {tipoUsuario:filtro.tipoUsuario[0].opc.join()} ),
            ...(filtro.dependencia && filtro.dependencia.length > 0 && {dependencia:filtro.dependencia[0]} ),
        };
        
        await this.servicioAdministracion.obtenerRoles( filtroApi )
            .then(( vistaRoles ) => {
                contexto.setSumaState({ vistaRoles, cargando: false });
            }).then(() => {
                if (limpiarFiltro !== undefined) { limpiarFiltro(); }
                if (confirmarFiltro !== undefined) { confirmarFiltro(); }
            });
    }

    render() {
        const { componente, usuario, vistaRoles, tiposUsuario } = this.state;
        let { filtro } = this.state;
        filtro = filtro || {};
        let contexto = this;

        const columns = [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                with:100,
                sorter: (a, b) => a.id - b.id,
                filteredValue: filtro.id || null,
                ...this.filtroColumna.busquedaTexto(
                    'Identificador',
                    'id',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            {
                title: 'Rol',
                dataIndex: 'rol',
                key: 'rol',
                sorter: (a, b) => a.rol ? a.rol.localeCompare(b.rol) : 1,
                filteredValue: filtro.rol || null,
                ...this.filtroColumna.busquedaTexto(
                    'Rol',
                    'rol',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            {
                title: 'Tipo',
                dataIndex: 'tipoUsuario',
                key: 'tipoUsuario',
                sorter: (a, b) => a.tipoUsuario ? a.tipoUsuario.localeCompare(b.tipoUsuario) : 1,
                filteredValue: filtro.tipoUsuario || null,
                ...this.filtroColumna.busquedaOpciones(
                    'Tipo',
                    'tipoUsuario',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) },
                    tiposUsuario
                ),
                render: (text, record) => (
                    <>
                        <span key={text} className={ 'bold ' + (
                            (record.idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.ADMINISTRADOR_SUMA.id)
                                ?
                                'fuente-green'
                                :
                                (record.idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.PERSONA_NATURAL.id
                                    ?
                                    'fuente-volcano'
                                    :
                                    (record.idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.PERSONA_JURIDICA.id
                                        ?
                                        'fuente-geekblue'
                                        :
                                        (record.idTipoUsuario === Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id 
                                            ?
                                            'fuente-purple'
                                            :
                                            ''
                                        )
                                    )
                                )
                        )}>
                            {text.toUpperCase()}
                        </span>
                    </>
                ),
                responsive: ['sm'], /* visible en pantalla con ancho ≥ 576px*/
            },
            {
                title: 'Dependencia',
                dataIndex: 'dependencia',
                key: 'dependencia',
                sorter: (a, b) => a.dependencia ? a.dependencia.localeCompare(b.dependencia) : 1,
                filteredValue: filtro.dependencia || null,
                ...this.filtroColumna.busquedaTexto(
                    'Dependencia',
                    'dependencia',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                responsive: ['md'], /* visible en pantalla con ancho ≥ 768px*/
            },
            
            {
                title: 'Opciones',
                key: 'opciones',
                align: 'center',
                className: 'columna-opciones',
                width: 112,
                render: (text, record) => (
                    <>
                        <Tooltip title="Ver opción">
                            <Button shape="circle" disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.VER_ROL.id)}
                                icon={<EyeOutlined style={{ 'color': '#c90a00' }} />}
                                onClick={(e) => { this.props.callbackAdministrarRol( record.id) }} />
                        </Tooltip>
                    </>
                ),
            },
        ];

        return (
            <div>
                <EstilosGlobales.CONTENEDOR_COMPLETO />
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>

                                <div className="seccion-datos seccion-final">

                                    {
                                        this.state.filtroEtiquetas.length > 0 ?
                                            (<div className="seccion-filtros-etiquetas">
                                                <div className="titulo-filtro-etiquetas">
                                                    <label>Filtros <SearchOutlined /> :</label>
                                                </div>

                                                {
                                                    this.state.filtroEtiquetas.map((filtroEtiqueta, i) => {
                                                        return (
                                                            <FiltroEtiqueta key={i}
                                                                dataIndex={filtroEtiqueta.dataIndex}
                                                                texto={filtroEtiqueta.texto}
                                                                onClose={filtroEtiqueta.borrarFiltro} />
                                                        )
                                                    })
                                                }
                                            </div>)
                                            :
                                            (<></>)
                                    }

                                    <Table  columns={columns}
                                            dataSource={ vistaRoles ? vistaRoles : []  }
                                            rowKey={"id"} />
                                </div>

                            </Spin>

                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                        (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                        : (
                            (componente === this.ENUM_COMPONENTE.NO_HABILITADO ? 
                            <label>Listar roles no disponible. {Constantes.MENSAJE_USUARIO_SIN_PERMISOS}</label> :
                            (componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:LISROL]</label>) : (<></>))
                        )
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(ListarRoles));