import React, { Component } from 'react';
import SumaContexto  from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Modal, Spin } from 'antd';
import Constantes from '../../utilidades/Constantes'
import ServicioPersonaJuridica from '../../servicios/usuarios/ServicioPersonaJuridica'
import FormularioUsuarioDatoPersonal from '../../zona-usuario/usuario-general/FormularioUsuarioDatoPersonal'
import queryString from 'query-string';

class AgregarEditarSubUsuario extends Component {

    static contextType = SumaContexto;
    
    ENUM_COMPONENTE ={
        CARGANDO:1,
        VER:2,
        NO_DISPONIBLE:3
    }

    state = {
        idUsuario: 0,
        usuario:null,
        componente: this.ENUM_COMPONENTE.CARGANDO,
    };

    constructor(props){
        super(props);
        //console.log('AgregarEditarSubUsuario.props',this.props);        
        // Indica que es el administrador del SUMA quien está accediendo al apoderado
        this.administracion = props.administracion;

        this.servicioPersonaJuridica = new ServicioPersonaJuridica ( props );
        this.onSuccess = props.onSuccess;
    }
    
    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales(){

        let params = this.props.location.search;
        let urlparametros = queryString.parse( params );
        let idUsuario = 0;

        if(  !this.administracion && urlparametros.id !== undefined && !isNaN(parseInt( urlparametros.id ))){
            // Se toma el idUsuario como un parámetro de consulta en la url '?id=idUsuario'
            idUsuario = parseInt(urlparametros.id);
            this.setSumaState({ idUsuario: idUsuario });
        }else if( this.administracion && this.props.idUsuario ){
            idUsuario = this.props.idUsuario;
            // Se toma el idUsuario si este viene en la url por medio del HookReactRouterV6Parametros,
            // esto funciona cuando es en modo administración y la url es: /persona-juridica/:idPersonaJuridica/editar/usuario/:idUsuario
            this.setSumaState({ idUsuario: idUsuario });
        }

        let usr = await this.servicioPersonaJuridica.usuarioPersonaJuridicaOAdministradorValido();
        this.setSumaState({ componente:(usr !== null ? this.ENUM_COMPONENTE.VER : this.ENUM_COMPONENTE.NO_DISPONIBLE)});
        if( usr !== null ){
            if( idUsuario !== 0 ){
                let usuario = await this.obtenerUsuario();
                this.setSumaState({ usuario: usuario });
            }
        }
    }

    obtenerUsuario() {
        return new Promise(resolve => {
            this.servicioPersonaJuridica.obtenerUsuario(
                ( respuestaApi ) => { 
                    switch(respuestaApi.codigo){
                        case Constantes.ENUMS_API.PERSONA_JURIDICA.DATOS_RETORNADOS.id:
                            resolve( respuestaApi.resultado );
                            break;
                        default:
                            Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:AESU02]" });
                            resolve( null );
                            break;    
                    }
                },
                ( errorAPI )        => { 
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:AESU03]" });
                    resolve(null);
                },
                this.state.idUsuario,
                // Si está en modo administración del SUMA, este dato se captura de la url por medio de HookReactRouterV6Parametros
                this.props.idPersonaJuridica 
            );
        });
    }

    

    formularioCompleto = (values) => {
        return new Promise(resolve => {
            
            let resultado = { cargando: false, limpiarFormulario: false };

            let usr = {
                usuario: values.correoElectronico,
                datoPersonal:{
                    primerNombre:               values.primerNombre,
                    segundoNombre:              values.segundoNombre,
                    primerApellido:             values.primerApellido,
                    segundoApellido:            values.segundoApellido,
                    correoElectronico:          values.correoElectronico.toLowerCase(),
                    numeroDocumento:            values.numeroDocumento,
                    lugarExpedicion:            values.lugarExpedicion,
                    fechaExpedicionDocumento:   values.fechaExpedicion.format('DD/MM/YYYY'),
                    direccion:                  values.direccion,
                    celular:                    values.movil,
                    telefono:                   values.telefono,
                    ciudad:{
                            id: parseInt(values.ciudad)
                    },
                    tipoDocumento:{
                            id: parseInt(values.tipoDocumento)
                    },
                }
            };

            if( values.rol !== undefined ){
                usr.rol = { id: parseInt(values.rol) };
            }
            
            if( this.state.idUsuario === 0 ){
                // Usuario Nuevo
                this.servicioPersonaJuridica.crearSubUsuario(
                    ( respuestaApi )   =>  {  
                        switch(respuestaApi.codigo){
                            case Constantes.ENUMS_API.USUARIO.USUARIO_CREADO.id:
                                this.onSuccess( respuestaApi.resultado );
                                resolve( null );
                                break;
                            case Constantes.ENUMS_API.USUARIO.CORREO_NO_DISPONIBLE.id:
                                resultado.satisfactorio = true;
                                Modal.error({ content: "El correo electrónico ya se encuentra registrado. Por favor intenta con uno diferente." });
                                break;
                            case Constantes.ENUMS_API.USUARIO.DOCUMENTO_IDENTIDAD_NO_DISPONIBLE.id:
                                resultado.satisfactorio = true;
                                Modal.error({content:'No fue posible crear el usuario. Ya se encuentra registrado el número de documento de identidad que fue ingresado.'});
                                break;
                            case Constantes.ENUMS_API.USUARIO.ERROR_SERVICIO_CORREO_ELECTRONICO.id:
                            case Constantes.ENUMS_API.USUARIO.DOMINIO_DEL_DADEP.id:
                                resultado.satisfactorio = true;
                                Modal.error({content:respuestaApi.mensaje});
                                break;
                            case Constantes.ENUMS_API.USUARIO.USUARIO_NO_CREADO.id:
                            default:
                                Modal.error({ content: 'No fue posible crear el usuario persona jurídica. Por favor intenta mas tarde.' });
                                break;
                        }
                        resolve(resultado);
                    },
                    ( errorAPI ) => { 
                        Modal.error({ content: 'Error de conexión. Por favor intenta mas tarde. [Cod:AESU01]' });
                        resolve(resultado);
                    },
                    usr,
                    // Si está en modo administración del SUMA, este dato se captura de la url por medio de HookReactRouterV6Parametros
                    this.props.idPersonaJuridica 
                );
            }else{
                // Editar Usuario 
                usr.id = this.state.idUsuario;
                this.servicioPersonaJuridica.actualizarSubUsuario(
                    ( respuestaApi )   =>  {  
                        switch(respuestaApi.codigo){
                            case Constantes.ENUMS_API.PERSONA_JURIDICA.OPERACION_REALIZADA.id:
                                this.onSuccess( respuestaApi.resultado );
                                resolve( null );
                                break;
                            case Constantes.ENUMS_API.USUARIO.DOCUMENTO_IDENTIDAD_NO_DISPONIBLE.id:
                                Modal.error({content:'No fue posible actualizar los datos. Ya se encuentra registrado el número de documento de identidad que fue ingresado.'});
                                resolve(resultado);
                                break;
                            default:
                                Modal.error({ content: 'No fue posible actualizar el usuario persona jurídica. Por favor intenta mas tarde.' });
                                resolve(resultado);
                                break;
                        }
                        resolve(resultado);
                    },
                    ( errorAPI ) => { 
                        Modal.error({ content: 'Error de conexión. Por favor intenta mas tarde. [Cod:AESU04]' });
                        resolve(resultado);
                    },
                    usr,
                    // Si está en modo administración del SUMA, este dato se captura de la url por medio de HookReactRouterV6Parametros
                    this.props.idPersonaJuridica 
                );
            }

        });
    };

    errorFormularioIncompleto = errorInfo => {
        Modal.warn({ content: "Por favor diligencia la información solicitada para el usuario" });
    };

    cancelarFormularioUsuario = () => {
        this.onSuccess(this.state.idUsuario, true);
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    render() {
        
        const {  usuario, idUsuario, componente } = this.state;

      
        return (
          <div>  
              {(componente === this.ENUM_COMPONENTE.VER) ?
                (
                    <> 
                        { ( (idUsuario === 0 ) || (idUsuario !== 0 && usuario !== null) ) 
                        ?
                            <FormularioUsuarioDatoPersonal 
                                                formularioIncompleto={ this.errorFormularioIncompleto } 
                                                formularioCompleto={this.formularioCompleto}
                                                nombreBotonSubmit = {idUsuario === 0 ? 'Crear usuario' : 'Actualizar datos'}
                                                textoCargando = {idUsuario === 0 ? 'Creando usuario ...' : 'Actualizando datos ...'}
                                                rol = { true }
                                                usuario = {usuario} 
                                                opcionCancelar = { true }
                                                cancelarCallback = { this.cancelarFormularioUsuario }
                                                />
                        : 
                            <Spin tip="Por favor espera ..." size="large" spinning={true}>
                                <div style={{width:'100%',height:'200px'}}></div>
                            </Spin>
                        }
                    </>
                )
                :
                (
                    (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                    (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                    :((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:AESU]</label>) : (<></>))
                )
            }
          </div>
        );
    }
}
          
export default withRouter(withCookies(AgregarEditarSubUsuario));