import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import ZonaUsuarioSelector from './ZonaUsuarioSelector'
import SumaContexto  from '../contexto/SumaContexto';

class ZonaUsuarioPopUp extends Component {

    static contextType = SumaContexto;

    state = { visible: false };


    componentDidMount() {
      this._isMounted = true;
    }
  
    componentWillUnmount() {
      this._isMounted = false;
    }
  
    setSumaState(valores) {
      if (this._isMounted) {
        this.setState(valores);
      }
    }

    showModal = () => {
      this.setSumaState({ visible: true });
    };
  
    hideModal = e => {
      this.setSumaState({ visible: false });
    };

    render() {

        //const { usuario } = this.context

        return (
            <>
            <Modal
              visible={this.state.visible}
              onCancel={this.hideModal}
              footer={[
                <Button key="cancel" onClick={this.hideModal}>
                  Cancelar
                </Button>
              ]}
            >
              <ZonaUsuarioSelector  usuario={this.props.usuario} 
                                    callbackUsuarioLogueado={this.props.callbackUsuarioLogueado} 
                                    cerrarModal={() => {this.hideModal()}}/>
            </Modal>
          </>
    );}
}
export default ZonaUsuarioPopUp;