import React, { Component } from 'react';
import SumaContexto  from '../contexto/SumaContexto';
import { withRouter, Switch , Route } from "react-router-dom";
import {  UserOutlined } from '@ant-design/icons';
import { Tabs, Spin } from 'antd';
import InformacionBasicaPersonaNatural from '../persona-natural/InformacionBasicaPersonaNatural'
import Apoderados from '../persona-natural/apoderados/Apoderados'
import ServicioPersonaNatural from '../servicios/usuarios/ServicioPersonaNatural'
import PersonaNaturalDocumentos from './PersonaNaturalDocumentos'
import { withCookies } from 'react-cookie';


const { TabPane } = Tabs;

class DatosPersonaNatural extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE ={
        CARGANDO:1,
        VER:2,
        NO_DISPONIBLE:3
    }

    PATHS_TABS = {
        DATOS:{ 
            pestana: '/persona-natural-datos',
            paths: ['/persona-natural-datos']
        },
        APODERADOS:{ 
            pestana: '/persona-natural-apoderados',
            paths:  [   
                        '/persona-natural-apoderados',
                        '/persona-natural-ver-apoderado',
                        '/persona-natural-agregar-apoderado',
                        '/persona-natural-editar-apoderado'
                    ]
        },
        DOCUMENTOS:{ 
            pestana: '/persona-natural-documentos',
            paths: ['/persona-natural-documentos']
        },
    };

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        tab:this.PATHS_TABS.DATOS.pestana,
    };

    constructor(props){
        super(props);
        this.historyLocal = props.history;
        this.servicioPersonaNatural = new ServicioPersonaNatural( props );
        // Se extrae el PATH inicial de la URL
        this.pathInicial = props.history.location.pathname;
        this.props.history.listen( this.manejadorCambioPath );
    }

    componentDidMount() {
        this._isMounted = true;
        this.setSumaState({ tab:this.obtenerPestanaPorPath(this.pathInicial) });
        this.cargarDatosIniciales();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales(){
        // Validando que el usuario es PERSONA NATURAL
        let usr = await this.servicioPersonaNatural.usuarioValido();
        this.setSumaState({ componente:(usr !== null ? this.ENUM_COMPONENTE.VER : this.ENUM_COMPONENTE.NO_DISPONIBLE)});
    }

    manejadorCambioPath = (location, action) =>{
        this.setSumaState({ tab: this.obtenerPestanaPorPath(location.pathname) });
    }

    
    obtenerPestanaPorPath = (path) => {
        let pestanas = Object.keys(this.PATHS_TABS);
        for( let index in pestanas){
            let paths_tab = this.PATHS_TABS[ pestanas[index] ];

            for( let index02 in paths_tab.paths){
                let path_tab = paths_tab.paths[index02];
                if(path.includes( path_tab )){
                    return paths_tab.pestana;
                }
            }
        }
    }

    manejadorClickTab = e => {
        // Se deja el tab en null para obligar a refrescar el componente hijo
        this.setSumaState({ tab: null });

        // Con el tab seleccionado tambien se cambia la URL, se deja dentro del setTimeout con el
        // fin de obligar a refrescar el componente hijo
        setTimeout(()=>{this.historyLocal.push( e );},100);
    };

    render() {
      
      const { componente, tab } = this.state;
      
        return (
          <div className="seccion-principal contenedor-completo seccion-flex ">
            <div className="division-principal">
              <label>Mis sitios / Datos persona natural</label>
            </div>
            <div className="division-contenido seccion-flex-grow-1 padding-principal bkg-white">
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <div className="titulo-seccion">
                                <label> <UserOutlined /> DATOS PERSONA NATURAL</label>
                                <div className="separador-horizontal-completo"></div>
                            </div>
                            <div>
                                <Tabs activeKey={this.state.tab} onChange={this.manejadorClickTab}>
                                    <TabPane tab="Datos"        key={this.PATHS_TABS.DATOS.pestana} />
                                    <TabPane tab="Apoderados"   key={this.PATHS_TABS.APODERADOS.pestana} />
                                    <TabPane tab="Documentos"   key={this.PATHS_TABS.DOCUMENTOS.pestana} />
                                </Tabs>

                                <Switch>
                                    <Route path={this.PATHS_TABS.DATOS.paths}>
                                        { tab !== null  ? <InformacionBasicaPersonaNatural /> : <></> }
                                    </Route>
                                    <Route path={this.PATHS_TABS.APODERADOS.paths}>
                                        { tab !== null  ? <Apoderados /> : <></> }
                                    </Route>
                                    <Route path={this.PATHS_TABS.DOCUMENTOS.paths}>
                                        { tab !== null  ? <PersonaNaturalDocumentos /> : <></> }
                                    </Route>
                                </Switch>
                            </div>
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                        (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                        :((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:DPN]</label>) : (<></>))
                    )
                }


              </div>
          </div>
        );
    }
}
          
export default withRouter(withCookies(DatosPersonaNatural));