import React, { Component } from 'react';
import SumaContexto  from '../../contexto/SumaContexto';
import { withRouter, Switch , Route } from "react-router-dom";
import { Button, Spin,  Modal } from 'antd';
import {  PlusOutlined } from '@ant-design/icons';
import Constantes from '../../utilidades/Constantes'
import ServicioPersonaJuridica from '../../servicios/usuarios/ServicioPersonaJuridica'
import { withCookies } from 'react-cookie';
import AgregarEditarSubUsuario from './AgregarEditarSubUsuario'
import VerSubUsuario from './VerSubUsuario'
import ListarSubUsuarios from './ListarSubUsuarios'
import Utilidades from '../../utilidades/Utilidades';
import { HookReactRouterV6Parametros } from '../../general/hooks/HookReactRouterV6Parametros';


class SubUsuarios extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE ={
        CARGANDO:1,
        VER:2,
        NO_DISPONIBLE:3,
        DILIGENCIAR_DATOS_BASICOS:4
    }

    state = {
        idUsuario: null,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        usuario: null,
    };

    constructor(props){
        super(props);
        this.historyLocal = props.history;
        this.administracion = props.administracion;
        //console.log('ApoderadosJuridica.props',props);

        this.servicioPersonaJuridica = new ServicioPersonaJuridica ( props );
        
        this.PATHS = {
            USUARIOS:           this.administracion ? 
                                `/persona-juridica/:idPersonaJuridica/usuarios` :  
                                '/persona-juridica-usuarios',

            AGREGAR_USUARIO :   this.administracion ? 
                                `/persona-juridica/:idPersonaJuridica/agregar/usuario` :
                                '/persona-juridica-agregar-usuario',

            EDITAR_USUARIO :    this.administracion ? 
                                `/persona-juridica/:idPersonaJuridica/editar/usuario/:idUsuario` :
                                '/persona-juridica-editar-usuario',

            VER_USUARIO :       this.administracion ? 
                                `/persona-juridica/:idPersonaJuridica/usuario/:idUsuario` : 
                                '/persona-juridica-ver-usuario',
        }
    }
    
    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales(){
        // Validando que el usuario es PERSONA JURIDICA
        let usr = await this.servicioPersonaJuridica.usuarioPersonaJuridicaOAdministradorValido();
        if( usr && usr !== null ){
            if( usr.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.PERSONA_JURIDICA.id ){
                if( usr.usuarioPersonaJuridica ){
                    this.setState({ componente: this.ENUM_COMPONENTE.VER });    
                }else{
                    this.setState({ componente: this.ENUM_COMPONENTE.DILIGENCIAR_DATOS_BASICOS });
                }
            }else if( usr.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.ADMINISTRADOR_SUMA.id ){
                this.setState({ componente: this.ENUM_COMPONENTE.VER });    
            }else{
                this.setState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
            }
        }else{
            this.setState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }

        this.setState({ usuario:usr });
    }

    subUsuarioGuardado( nuevoUsuario, idUsuario, cancelar ){
        if( typeof cancelar === 'boolean' && cancelar ){
            this.setSumaState({ 
                idUsuario: (nuevoUsuario ? 0 : idUsuario) 
            });    
            if( nuevoUsuario ){
                this.historyLocal.push( this.administracion ?
                    this.reemplazarValoresEnElPath( this.PATHS.USUARIOS )
                    :
                    this.PATHS.APODERADOS);
            }else{
                this.historyLocal.push( this.administracion ?
                    this.reemplazarValoresEnElPath( this.PATHS.VER_USUARIO, idUsuario ) 
                    :
                    (this.PATHS.VER_USUARIO + '?id=' + idUsuario) );
            }
            return;
        }
        this.setSumaState({ idUsuario: idUsuario });
        Modal.success({ content: "El usuario ha sido " + (nuevoUsuario ? "creado. Se ha enviado un correo electrónico al usuario para activar su cuenta." : "actualizado") });
        this.historyLocal.push( this.administracion ?
            this.reemplazarValoresEnElPath( this.PATHS.VER_USUARIO, idUsuario ) 
            :
            this.PATHS.VER_USUARIO + '?id=' + idUsuario );
    }

    clickVerUsuario = ( e, idUsuario ) => {
        this.setSumaState({ idUsuario: idUsuario });
        this.historyLocal.push( this.administracion ?
            this.reemplazarValoresEnElPath( this.PATHS.VER_USUARIO, idUsuario ) 
            :
            this.PATHS.VER_USUARIO + '?id=' + idUsuario );
    }

    clickActivarUsuario = (e,idUsuario, usuarioNombre, activar, callback ) => {
        this.servicioPersonaJuridica.activarUsuario(
            ( respuestaApi )    => { 
                switch(respuestaApi.codigo){
                    case Constantes.ENUMS_API.PERSONA_JURIDICA.OPERACION_REALIZADA.id:
                        Modal.success({ content:'Usuario ' + usuarioNombre + ' ' + ( activar ? 'Activado' : 'Desactivado' ) });
                        if( callback !== undefined ){
                            callback();
                        }
                        break;
                    default:
                        Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SU02]" });
                        break;    
                }
            },
            ( errorAPI ) => { 
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SU03]" });
            },
            idUsuario,
            activar,
            // Si está en modo administración del SUMA, este dato se captura de la url por medio de HookReactRouterV6Parametros
            this.props.idPersonaJuridica
        );
    };

    clickEnviarCorreoActivacion = (e,idUsuario, usuarioNombre, callback ) => {
        this.servicioPersonaJuridica.enviarCorreoActivacion(
            ( respuestaApi )    => { 
                switch(respuestaApi.codigo){
                    case Constantes.ENUMS_API.PERSONA_JURIDICA.OPERACION_REALIZADA.id:
                        //console.log(' activarUsuario respuestaApi',respuestaApi);
                        Modal.success({ content:'Correo de activación de cuenta enviado al usuario ' + usuarioNombre });
                        if( callback !== undefined ){
                            callback();
                        }
                        break;
                    case Constantes.ENUMS_API.PERSONA_JURIDICA.SUB_USUARIO_CON_CUENTA_ACTIVA.id:
                        Modal.warning({ content: respuestaApi.mensaje });
                        if( callback !== undefined ){
                            callback();
                        }
                        break;
                    case Constantes.ENUMS_API.USUARIO.ERROR_SERVICIO_CORREO_ELECTRONICO.id:
                        Modal.error({content:respuestaApi.mensaje});
                        break;          
                    default:
                        Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SU04]" });
                        break;    
                }
            },
            ( errorAPI ) => { 
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SU05]" });
            },
            idUsuario,
            // Si está en modo administración del SUMA, este dato se captura de la url por medio de HookReactRouterV6Parametros
            this.props.idPersonaJuridica
        );
    }


    enviarCorreoCambioContrasena = (e,idUsuario, usuarioNombre, callback ) => {
        this.servicioPersonaJuridica.enviarCorreoCambioContrasena(
            ( respuestaApi )    => { 
                switch(respuestaApi.codigo){
                    case Constantes.ENUMS_API.PERSONA_JURIDICA.OPERACION_REALIZADA.id:
                        //console.log(' activarUsuario respuestaApi',respuestaApi);
                        Modal.success({ content:'Correo de cambio de contraseña enviado al usuario ' + usuarioNombre });
                        if( callback !== undefined ){
                            callback();
                        }
                        break;
                    case Constantes.ENUMS_API.USUARIO.ERROR_SERVICIO_CORREO_ELECTRONICO.id:
                        Modal.error({content:respuestaApi.mensaje});
                        break;     
                    default:
                        Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SU06]" });
                        break;    
                }
            },
            ( errorAPI ) => { 
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SU07]" });
            },
            idUsuario,
            // Si está en modo administración del SUMA, este dato se captura de la url por medio de HookReactRouterV6Parametros
            this.props.idPersonaJuridica
        );
    }

    clickAgregarUsuario = e => {
        this.setSumaState({  idUsuario: 0 });
        this.historyLocal.push( this.reemplazarValoresEnElPath(this.PATHS.AGREGAR_USUARIO,undefined) );
    }

    clickEditarUsuario = ( e, idUsuario ) => {
        this.setSumaState({ idUsuario: idUsuario });
        this.historyLocal.push( this.administracion ?
            this.reemplazarValoresEnElPath( this.PATHS.EDITAR_USUARIO, idUsuario ) 
            :
            this.PATHS.EDITAR_USUARIO + '?id=' + idUsuario );
    }

    reemplazarValoresEnElPath  = (path, idUsuario) => {
        return path
        // Se toma el idPersonaJuridica de la url por medio del HookReactRouterV6Parametros
        .replace(':idPersonaJuridica',this.props.idPersonaJuridica)
        .replace(':idUsuario',idUsuario);
    }
    
    render() {
      //let  {form} = this.props.form;
      //const { usuario } = this.context
      const { componente, usuario  } = this.state;


        return (
          <div>
              {(componente === this.ENUM_COMPONENTE.VER) ?
                (
                    <>
                        <Switch>
                            <Route path={this.PATHS.VER_USUARIO}>
                                <VerSubUsuario  callbackEditarUsuario={ this.clickEditarUsuario }
                                                callbackActivarUsuario={ this.clickActivarUsuario }
                                                callbackEnviarCorreoActivacion={this.clickEnviarCorreoActivacion}
                                                callbackEnviarCorreoCambioContrasena={this.enviarCorreoCambioContrasena}
                                                // Indica que es el administrador del SUMA quien está accediendo al representante legal
                                                administracion={this.administracion} 
                                                // Se toma el idPersonaJuridica de la url por medio del HookReactRouterV6Parametros
                                                idPersonaJuridica={this.props.idPersonaJuridica}
                                                // Se toma el idUsuario de la url por medio del HookReactRouterV6Parametros
                                                idUsuario={this.props.idUsuario}/>
                            </Route>
                            <Route path={this.PATHS.AGREGAR_USUARIO}>
                                <AgregarEditarSubUsuario    onSuccess={( idUsuario, cancelar ) => {this.subUsuarioGuardado( true, idUsuario, cancelar )}} 
                                                            // Indica que es el administrador del SUMA quien está accediendo al representante legal
                                                            administracion={this.administracion} 
                                                            // Se toma el idPersonaJuridica de la url por medio del HookReactRouterV6Parametros
                                                            idPersonaJuridica={this.props.idPersonaJuridica}/>
                            </Route>
                            <Route path={this.PATHS.EDITAR_USUARIO}>
                                <AgregarEditarSubUsuario    onSuccess={( idUsuario, cancelar ) => {this.subUsuarioGuardado( false, idUsuario, cancelar )}} 
                                                            // Indica que es el administrador del SUMA quien está accediendo al representante legal
                                                            administracion={this.administracion} 
                                                            // Se toma el idPersonaJuridica de la url por medio del HookReactRouterV6Parametros
                                                            idPersonaJuridica={this.props.idPersonaJuridica}
                                                            // Se toma el idUsuario de la url por medio del HookReactRouterV6Parametros
                                                            idUsuario={this.props.idUsuario}/>
                            </Route>
                            <Route path={this.PATHS.USUARIOS}>
                                <>
                                    <div className="div-lista-botones">
                                        <Button type="primary" 
                                                onClick={this.clickAgregarUsuario} 
                                                icon={<PlusOutlined />}
                                                disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_USUARIOS.ACCIONES.AGREGAR_SUB_USUARIO.id)}>
                                            Agregar usuario
                                        </Button>
                                    </div>
                                    < ListarSubUsuarios clickVerUsuario={this.clickVerUsuario} 
                                                        callbackActivarUsuario={this.clickActivarUsuario}
                                                        callbackEnviarCorreoActivacion={this.clickEnviarCorreoActivacion}
                                                        // El idPersonaJuridica se puede tomar por el componente padre como atributo, si no llega por aquí, se toma la perona jurídica del usuario que está logueado.
                                                        idPersonaJuridica={this.props.idPersonaJuridica}/>
                                </>
                            </Route>
                        </Switch>
                    </>
                )
                :
                (
                    (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                    (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                    :(componente === this.ENUM_COMPONENTE.DILIGENCIAR_DATOS_BASICOS) ?
                    (<label>Para habilitar esta sección debes diligenciar y guardar primero los datos de la entidad persona jurídica.</label>)
                    :((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:SU]</label>) : (<></>))
                )
            }
          </div>
        );
    }
}
          
export default withRouter(withCookies(HookReactRouterV6Parametros(SubUsuarios)));