import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Spin, Progress } from 'antd';
import ServicioUsuarios from '../../servicios/usuarios/ServicioUsuarios'
import ServicioAdministracion from '../../servicios/ServicioAdministracion'
import { withCookies } from 'react-cookie';
import Constantes from '../../utilidades/Constantes'
import { Doughnut, Bar } from 'react-chartjs-2';



class EstadisticasSolicitudes extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3,
        NO_HABILITADO: 4,
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        porcentaje: 0,
    };

    constructor(props) {
        super(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        // Validando que el usuario esté en sesion
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr != null) {
            // Valida que tenga la accion habilitada de acceder 
            let habilitado = await this.servicioUsuarios.accionHabilitadaPromesa(Constantes.ENUMS_MODULOS.REPORTES.ACCIONES.VER_ESTADISTICAS_SOLICITUDES.id);
            if (habilitado && habilitado.resultado) {
                let respuestaAPI = await this.servicioAdministracion.obtenerEstadisticasSolicitudes();
                if (respuestaAPI && respuestaAPI.codigo === Constantes.ENUMS_API.ADMINISTRACION.DATOS_RETORNADOS.id) {
                    let vistaEstadisticasSolicitudes = respuestaAPI.resultado;
                    this.setSumaState({ vistaEstadisticasSolicitudes });
                    
                    let colores = [...Constantes.COLORES].reverse();
                    let datosEstados = { labels: [], datasets: [{label: 'Estados solicitudes',data: [],backgroundColor: Object.values( colores )}] };
                    let datosTipo = { labels: [], datasets: [{label: 'Tipo solicitudes',data: [],backgroundColor: Object.values( colores )}] };
                    let datosLocalidad = { labels: [], datasets: [{label: 'Localidad solicitudes',data: [],backgroundColor: Object.values( colores )}] };

                    for( let ves of vistaEstadisticasSolicitudes ){
                        if( ves.conjunto === 'TOTAL_SOLICITUDES' ){
                            this.setSumaState({ totalSolicitudes: ves.total });
                        }
                        if( ves.conjunto === 'ESTADO' ){
                            datosEstados.labels.push(ves.etiqueta);
                            datosEstados.datasets[0].data.push( ves.total ? ves.total : 0 );
                        }
                        if( ves.conjunto === 'TIPO' ){
                            datosTipo.labels.push(ves.etiqueta);
                            datosTipo.datasets[0].data.push( ves.total ? ves.total : 0 );
                        }
                        if( ves.conjunto === 'LOCALIDAD' ){
                            datosLocalidad.labels.push(ves.etiqueta);
                            datosLocalidad.datasets[0].data.push( ves.total ? ves.total : 0 );
                        }
                    }
                    
                    this.setSumaState({ datosEstados, datosTipo, datosLocalidad });
                    


                    var contador = setInterval(
                        (function (self) {
                            return function () {
                                if (self && self.state && self.state.totalSolicitudes) {
                                    let porcentaje = self.state.porcentaje + 1;
                                    let total = self.state.totalSolicitudes;
                                    self.setSumaState({ porcentaje, total: parseInt((total / 100) * porcentaje) });
                                    if (porcentaje >= 100) {
                                        clearInterval(contador);
                                        self.setSumaState({ total });
                                    }
                                }
                            }
                        })(this),
                        1);
                }
                this.setSumaState({ usuario: usr, componente: this.ENUM_COMPONENTE.VER });
            } else {
                this.setSumaState({ usuario: usr, componente: this.ENUM_COMPONENTE.NO_HABILITADO });
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }


    render() {

        const { componente, porcentaje, total, datosEstados, datosTipo, datosLocalidad } = this.state;

        const opcionesPie = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    //position: 'right',
                    labels: {
                        boxWidth: 10,
                        font: { size: 11}
                    }
                }
            }
        };

        const opcionesBar = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                    labels: {
                        boxWidth: 10,
                    }
                }
            }
        };


        return (
            <>
                <div className="seccion-principal contenedor-completo seccion-flex ">

                    <div className="division-contenido seccion-flex-grow-1 bkg-white contenido-usuarios">

                        {(componente === this.ENUM_COMPONENTE.VER) ?
                            (
                                <div className="graficas-antd">

                                    <div className="widget-grafica">
                                        <label>Total Solicitudes</label>
                                        <div className="div-grafica">
                                            <Progress type="circle" percent={porcentaje}
                                                format={percent => total}
                                                width={200} />
                                        </div>
                                    </div>

                                    <div className="widget-grafica">
                                        <label>Solicitudes por estado</label>
                                        <div className="div-grafica">
                                            <Bar data={datosEstados} options={opcionesBar} />
                                        </div>
                                    </div>

                                    <div className="widget-grafica">
                                        <label>Solicitudes por tipo</label>
                                        <div className="div-grafica">
                                            <Doughnut data={datosTipo} options={opcionesPie} />
                                        </div>
                                    </div>

                                    <div className="widget-grafica">
                                        <label>Solicitudes por localidad</label>
                                        <div className="div-grafica">
                                            <Doughnut data={datosLocalidad} options={opcionesPie} />
                                        </div>
                                    </div>

                                </div>
                            )
                            :
                            (componente === this.ENUM_COMPONENTE.NO_HABILITADO) ?
                                <>
                                    Estadísticas de solicitudes no disponibles. {Constantes.MENSAJE_USUARIO_SIN_PERMISOS}
                                </> :
                                (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                                    (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                                    : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:REPOESTSOL]</label>) : (<></>))

                        }
                    </div>
                </div>
            </>
        );

    }

}

export default withRouter(withCookies(EstadisticasSolicitudes));