import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter, Switch, Route } from "react-router-dom";
import { TeamOutlined, ProfileOutlined, DotChartOutlined, BarsOutlined } from '@ant-design/icons';
import { Spin, Tabs } from 'antd';
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import ServicioAdministracion from '../servicios/ServicioAdministracion'
import { withCookies } from 'react-cookie';
import Constantes from '../utilidades/Constantes'
import ReportesUsuarios from './reportes-usuarios/ReportesUsuarios';
import EstadisticasUsuarios from './reportes-usuarios/EstadisticasUsuarios'
import EstilosGlobales from '../utilidades/EstilosGlobales';
import EstadisticasSolicitudes from './reportes-solicitudes/EstadisticasSolicitudes';
import ReportesSolicitudes from './reportes-solicitudes/ReportesSolicitudes'

const { TabPane } = Tabs;

class Reportes extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3,
        NO_HABILITADO: 4,
    }

    PATHS = {
        REPORTES: '/reportes',
        REPORTE_USUARIOS: '/reporte_usuarios',
        REPORTE_SOLICITUDES: '/reporte_solicitudes',
    };

    PATHS_TABS = {
        REPORTE_SOLICITUDES: {
            pestana: '/reporte_solicitudes',
            paths: ['/reporte_solicitudes'],
            estadisticas: 'ESTADISTICAS_SOLICITUDES',
            listado: 'LISTADO_SOLICITUDES'
        },
        REPORTE_USUARIOS: {
            pestana: '/reporte_usuarios',
            paths: ['/reportes', '/reporte_usuarios'],
            estadisticas: 'ESTADISTICAS_USUARIOS',
            listado: 'LISTADO_USUARIOS'
        }
    };

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioAdministracion = new ServicioAdministracion(props);

        this.pathInicial = props.history.location.pathname;
        this.props.history.listen(this.manejadorCambioPath);
    }

    componentDidMount() {
        this._isMounted = true;
        this.setSumaState({ tab: this.obtenerPestanaPorPath(this.pathInicial) });
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        // Validando que el usuario esté en sesion
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr != null) {
            // Valida que tenga la accion habilitada de acceder 
            let habilitado = await this.servicioUsuarios.accionHabilitadaPromesa(Constantes.ENUMS_MODULOS.REPORTES.ACCIONES.ACCEDER_MODULO_REPORTES.id);
            if (habilitado && habilitado.resultado) {
                this.setState({ usuario: usr, componente: this.ENUM_COMPONENTE.VER });
            } else {
                this.setState({ usuario: usr, componente: this.ENUM_COMPONENTE.NO_HABILITADO });
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }

    manejadorCambioPath = (location, action) => {
        this.setSumaState({ tab: this.obtenerPestanaPorPath(location.pathname) });
    }

    obtenerPestanaPorPath = (path) => {
        let pestanas = Object.keys(this.PATHS_TABS);
        for (let index in pestanas) {
            let paths_tab = this.PATHS_TABS[pestanas[index]];
            for (let index02 in paths_tab.paths) {
                let path_tab = paths_tab.paths[index02];
                if (path === path_tab) {
                    return paths_tab.pestana;
                }
            }
        }
    }

    manejadorClickTab = e => {
        // Se deja el tab en null para obligar a refrescar el componente hijo
        this.setSumaState({ tab: null });
        // Con el tab seleccionado tambien se cambia la URL, se deja dentro del setTimeout con el
        // fin de obligar a refrescar el componente hijo
        setTimeout(() => { this.historyLocal.push(e); }, 100);
    };


    manejadorClickSubTab = e => {
        this.setSumaState({ subTab: e });
    };


    render() {

        const { componente, subTab } = this.state;

        return (
            <>
                <EstilosGlobales.CONTENEDOR_COMPLETO />
                <div className="seccion-principal contenedor-completo seccion-flex ">

                    <div className="division-principal">
                        <label>Reportes</label>
                    </div>
                    <div className="division-contenido seccion-flex-grow-1 padding-principal bkg-white contenido-usuarios">

                        {(componente === this.ENUM_COMPONENTE.VER) ?
                            (
                                <>
                                    <Switch>
                                        <Route path={[
                                            this.PATHS.REPORTES,
                                            this.PATHS.REPORTE_SOLICITUDES,
                                            this.PATHS.REPORTE_USUARIOS
                                        ]}>

                                            <Tabs activeKey={this.state.tab} onChange={this.manejadorClickTab}>
                                                <TabPane tab="Usuarios" key={this.PATHS_TABS.REPORTE_USUARIOS.pestana} />
                                                <TabPane tab="Solicitudes" key={this.PATHS_TABS.REPORTE_SOLICITUDES.pestana} />
                                            </Tabs>

                                            <Route path={[this.PATHS.REPORTES, this.PATHS.REPORTE_USUARIOS]}>
                                                <div className="titulo-seccion">
                                                    <label><TeamOutlined /> Reporte Usuarios</label>
                                                    <div className="separador-horizontal-completo margen-bottom-5"></div>
                                                </div>
                                                <Tabs onChange={this.manejadorClickSubTab}>
                                                    <TabPane tab={<span><DotChartOutlined /> Estadísticas</span>} key={this.PATHS_TABS.REPORTE_USUARIOS.estadisticas} />
                                                    <TabPane tab={<span><BarsOutlined /> Listado</span>} key={this.PATHS_TABS.REPORTE_USUARIOS.listado} />
                                                </Tabs>

                                                {subTab === this.PATHS_TABS.REPORTE_USUARIOS.listado ?
                                                    <ReportesUsuarios />
                                                    :
                                                    <EstadisticasUsuarios />
                                                }

                                            </Route>

                                            <Route path={this.PATHS.REPORTE_SOLICITUDES}>
                                                <div className="titulo-seccion">
                                                    <label><ProfileOutlined /> Reporte Solicitudes</label>
                                                    <div className="separador-horizontal-completo margen-bottom-5"></div>
                                                </div>

                                                <Tabs onChange={this.manejadorClickSubTab}>
                                                    <TabPane tab={<span><DotChartOutlined />Estadísticas</span>} key={this.PATHS_TABS.REPORTE_SOLICITUDES.estadisticas} />
                                                    <TabPane tab={<span><BarsOutlined />Listado</span>} key={this.PATHS_TABS.REPORTE_SOLICITUDES.listado} />
                                                </Tabs>

                                                {subTab === this.PATHS_TABS.REPORTE_SOLICITUDES.listado ?
                                                    <ReportesSolicitudes />
                                                    :
                                                    <EstadisticasSolicitudes />
                                                }

                                            </Route>
                                        </Route>



                                    </Switch>



                                </>
                            )
                            :
                            (componente === this.ENUM_COMPONENTE.NO_HABILITADO) ?
                                <>
                                    Reportes no disponibles. {Constantes.MENSAJE_USUARIO_SIN_PERMISOS}
                                </> :
                                (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                                    (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                                    : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:REP]</label>) : (<></>))

                        }
                    </div>
                </div>
            </>
        );

    }

}

export default withRouter(withCookies(Reportes));