import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Button, Row, Col, Modal, Spin, Tooltip, Tag, Popconfirm } from 'antd';
import { FileOutlined, EditOutlined, StopOutlined, CheckOutlined, ExclamationCircleOutlined, CloseOutlined } from '@ant-design/icons';
import Constantes from '../../utilidades/Constantes'
import ServicioDatosConstantes from '../../servicios/ServicioDatosConstantes'
import ServicioPersonaNatural from '../../servicios/usuarios/ServicioPersonaNatural'
import ActualizarArchivo from '../../carga-archivos/ActualizarArchivo'
import queryString from 'query-string';
import Utilidades from '../../utilidades/Utilidades'


class VerApoderado extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        cargando: false,
        idApoderado: 0,
        apoderado: null,
        tipoArhivosApoderado: null,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        usuario: null,
    };

    constructor(props) {
        super(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.servicioPersonaNatural = new ServicioPersonaNatural(props);
        this.callbackEditarApoderado = props.callbackEditarApoderado;
        this.callbackActivarApoderado = props.callbackActivarApoderado;
        this.callbackEliminarApoderado = props.callbackEliminarApoderado;
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        let idApoderado = 0;

        if (urlparametros.id !== undefined && !isNaN(parseInt(urlparametros.id))) {
            idApoderado = parseInt(urlparametros.id);
        }

        if (idApoderado !== 0) {
            // Validando que el usuario es PERSONA NATURAL
            let usr = await this.servicioPersonaNatural.usuarioValido();
            this.setSumaState({ componente: (usr !== null ? this.ENUM_COMPONENTE.VER : this.ENUM_COMPONENTE.NO_DISPONIBLE) });
            if (this.state.componente === this.ENUM_COMPONENTE.VER) {
                this.setSumaState({ cargando: true, idApoderado: idApoderado });
                let tipoArhivosApoderado = await this.obtenerTipoArchivosApoderado();
                let apoderado = await this.obtenerApoderado();

                if (apoderado !== null && tipoArhivosApoderado != null) {
                    // Se quitan los tipos de archivos que ya tiene el apoderado
                    for (let i = 0; i < apoderado.apoderadoArchivos.length; i++) {
                        for (let m = 0; m < tipoArhivosApoderado.length; m++) {
                            if (apoderado.apoderadoArchivos[i].archivo.tipoArchivo.id === tipoArhivosApoderado[m].id) {
                                tipoArhivosApoderado.splice(m, 1);
                                break;
                            }
                        }
                    }
                    this.setSumaState({
                        usuario: usr,
                        cargando: false,
                        apoderado: apoderado,
                        tipoArhivosApoderado: tipoArhivosApoderado,
                    });
                }

            }
        }
    }

    obtenerApoderado() {
        return new Promise(resolve => {
            this.servicioPersonaNatural.obtenerApoderado(
                (respuestaApi) => {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.PERSONA_NATURAL.DATOS_RETORNADOS.id:
                            resolve(respuestaApi.resultado);
                            break;
                        default:
                            Modal.error({ title: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VA01]" });
                            resolve(null);
                            break;
                    }
                },
                (errorAPI) => {
                    Modal.error({ title: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VA02]" });
                    resolve(null);
                },
                this.state.idApoderado
            );
        });
    }

    obtenerTipoArchivosApoderado() {
        return new Promise(resolve => {
            this.servicioDatosConstantes.obtenerTipoArchivosApoderado(
                (tipoArhivosApoderado) => {
                    resolve(tipoArhivosApoderado);
                },
                (errorAPI) => {
                    Modal.error({ title: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VA03]" });
                    resolve(null);
                },
            );
        });
    }

    async archivoApoderadoActualizado(idNuevoArchivo, apoderadoArchivo, tipoArchivo) {
        this.setSumaState({ cargando: true });
        //console.log('apoderadoArchivo',apoderadoArchivo);
        let resultado = null;
        if (apoderadoArchivo !== null) {
            apoderadoArchivo.archivo = { id: idNuevoArchivo };
            resultado = await this.actualizarApoderadoArchivo(apoderadoArchivo);
        } else {
            resultado = await this.agregarApoderadoArchivo(this.state.idApoderado, idNuevoArchivo);
        }

        if (resultado !== null) {
            this.setSumaState({ apoderado: null, tipoArhivosApoderado: null });
            this.cargarDatosIniciales();
            Modal.success({
                content: "El documento " +
                    tipoArchivo.nombreArchivo +
                    " fue " + (apoderadoArchivo === null ? 'agregado' : 'actualizado') + " satisfactoriamente."
            });
        }
    }


    actualizarApoderadoArchivo(apoderadoArchivo) {
        return new Promise(resolve => {
            this.servicioPersonaNatural.actualizarApoderadoArchivo(
                (respuestaApi) => {
                    //console.log('respuestaApi:::::',respuestaApi);
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.PERSONA_NATURAL.OPERACION_REALIZADA.id:
                            resolve(respuestaApi.resultado);
                            break;
                        default:
                            Modal.error({ message: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VA04]" });
                            resolve(null);
                            break;
                    }
                },
                (errorAPI) => {
                    Modal.error({ message: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VA05]" });
                    resolve(null);
                },
                apoderadoArchivo
            );
        });
    }


    agregarApoderadoArchivo(idApoderado, idArchivo) {
        let apoderadoArchivo = {
            archivo: { id: idArchivo },
            idApoderado: idApoderado,
        };
        return new Promise(resolve => {
            this.servicioPersonaNatural.agregarApoderadoArchivo(
                (respuestaApi) => {
                    //console.log('respuestaApi:::::',respuestaApi);
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.PERSONA_NATURAL.OPERACION_REALIZADA.id:
                            resolve(respuestaApi.resultado);
                            break;
                        default:
                            Modal.error({ message: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VA06]" });
                            resolve(null);
                            break;
                    }
                },
                (errorAPI) => {
                    Modal.error({ message: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:VA07]" });
                    resolve(null);
                },
                apoderadoArchivo
            );
        });
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    render() {

        const { apoderado, componente, cargando, idApoderado, tipoArhivosApoderado, usuario } = this.state;

        const wrapperCol = {
            xs: { span: 21, offset: 1 },
            sm: { span: 11, offset: 1 },
        };

        const wrapperColDocs01 = {
            xs: { span: 21, offset: 1 },
            sm: { span: 7, offset: 1 },
        };
        const wrapperColDocs02 = {
            xs: { span: 21, offset: 1 },
            sm: { span: 15, offset: 1 },
        };

        return (
            <div>

                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <Spin tip="Por favor espera..." size="large" spinning={cargando}>

                                <Row>
                                    <Col>
                                        <h3 className="titulo inline-block">Apoderado:</h3> &nbsp;&nbsp;&nbsp;
                                        <h3 className="inline-block">{apoderado !== null ? apoderado.datoPersonal.nombreCompleto : ""}</h3>
                                    </Col>
                                </Row>
                                <h3 className="sub-titulo-separador margen-bottom">Información básica</h3>
                                <div className="seccion-datos">
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Tipo de documento:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{apoderado !== null ? apoderado.datoPersonal.tipoDocumento.tipoDocumento : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Número de documento:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{apoderado !== null ? apoderado.datoPersonal.numeroDocumento : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Lugar de expedición:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{apoderado !== null ? apoderado.datoPersonal.lugarExpedicion : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Fecha de expedición:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{apoderado !== null ? apoderado.datoPersonal.fechaExpedicionFormatoEspanol : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Correo electrónico:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{apoderado !== null ? apoderado.datoPersonal.correoElectronico : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Dirección residencia:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{apoderado !== null ? apoderado.datoPersonal.direccion : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Móvil:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{apoderado !== null ? apoderado.datoPersonal.celular : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Teléfono:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <span>{apoderado !== null ? apoderado.datoPersonal.telefono : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label className="titulo-etiqueta">Estado:</label>
                                                </Col>
                                                <Col sm={12}>
                                                    <Tag color={
                                                        (apoderado !== null && apoderado.estadoApoderado.id === Constantes.ENUMS.ESTADO_APODERADO.ACTIVO.id)
                                                            ?
                                                            'green'
                                                            :
                                                            (apoderado !== null && apoderado.estadoApoderado.id === Constantes.ENUMS.ESTADO_APODERADO.INACTIVO.id
                                                                ?
                                                                'volcano'
                                                                :
                                                                ''
                                                            )
                                                    } key={apoderado !== null ? apoderado.estadoApoderado.estadoApoderado : ""}>
                                                        {apoderado !== null ? apoderado.estadoApoderado.estadoApoderado.toUpperCase() : ""}
                                                    </Tag>

                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </div>



                                <h3 className="sub-titulo-separador margen-bottom margen-top">Documentos</h3>
                                <div className="seccion-datos">

                                    {
                                        (apoderado !== null)
                                            ?
                                            apoderado.apoderadoArchivos.map((apoderadoArchivo, index) =>
                                                <Row className="fila documento" key={index} >
                                                    <Col  {...wrapperColDocs01}>
                                                        <a className="enlace-documento tamano-25" href={apoderadoArchivo.archivo.url} target="_blank" rel="noopener noreferrer">
                                                            <div>
                                                                <FileOutlined />
                                                                <label>{apoderadoArchivo.archivo.tipoArchivo.nombreArchivo}</label>
                                                            </div>
                                                            {apoderadoArchivo.archivo.actualizarArchivo
                                                                ?
                                                                <Tooltip title="El documento debe ser actualizado!">
                                                                    <Tag icon={<ExclamationCircleOutlined />} color="magenta" key="Cambio Documentos">ACTUALIZAR DOCUMENTO</Tag>
                                                                </Tooltip>
                                                                :
                                                                <></>
                                                            }
                                                        </a>
                                                    </Col>
                                                    <Col  {...wrapperColDocs02}>
                                                        <ActualizarArchivo key={index}
                                                            tipoArchivo={apoderadoArchivo.archivo.tipoArchivo}
                                                            agregar={false}
                                                            callbackArchivoActualizado={(idNuevoArchivo) => {
                                                                this.archivoApoderadoActualizado(
                                                                    idNuevoArchivo,
                                                                    apoderadoArchivo,
                                                                    apoderadoArchivo.archivo.tipoArchivo
                                                                )
                                                            }}
                                                            deshabilitar={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_NATURAL_APODERADOS.ACCIONES.ACTUALIZAR_DOCUMENTOS_APODERADO.id)}
                                                        />
                                                    </Col>
                                                </Row>
                                            )
                                            :
                                            (<></>)
                                    }

                                    {
                                        (tipoArhivosApoderado !== null)
                                            ?
                                            tipoArhivosApoderado.map((tipoArchivoApoderado, index) =>
                                                <Row className="fila documento" key={index} >
                                                    <Col  {...wrapperColDocs01}>
                                                        <Tooltip title="No tiene asociado el documento">
                                                            <div className="enlace-documento tamano-25" >
                                                                <div>
                                                                    <FileOutlined />
                                                                    <label>{tipoArchivoApoderado.nombreArchivo}</label>
                                                                </div>
                                                            </div>
                                                        </Tooltip>
                                                    </Col>
                                                    <Col  {...wrapperColDocs02}>
                                                        <ActualizarArchivo key={index}
                                                            tipoArchivo={tipoArchivoApoderado}
                                                            agregar={true}
                                                            callbackArchivoActualizado={(idNuevoArchivo) => {
                                                                this.archivoApoderadoActualizado(
                                                                    idNuevoArchivo,
                                                                    null,
                                                                    tipoArchivoApoderado
                                                                )
                                                            }}
                                                            deshabilitar={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_NATURAL_APODERADOS.ACCIONES.AGREGAR_DOCUMENTOS_APODERADO.id)}
                                                        />
                                                    </Col>
                                                </Row>
                                            )
                                            :
                                            (<></>)
                                    }

                                </div>


                                <h3 className="sub-titulo-separador margen-bottom margen-top">Opciones</h3>
                                <div className="seccion-datos seccion-final">

                                    {(apoderado !== null)
                                        ?
                                        (<>
                                            <Button className="boton-opcion"
                                                icon={<EditOutlined style={{ color: '#c90a00' }} />}
                                                onClick={(e) => { this.callbackEditarApoderado(e, idApoderado) }}
                                                disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_NATURAL_APODERADOS.ACCIONES.EDITAR_INFORMACION_APODERADO.id)}>
                                                Editar
                                            </Button>

                                            {(apoderado.estadoApoderado.id === Constantes.ENUMS.ESTADO_APODERADO.ACTIVO.id)
                                                ?
                                                <Popconfirm title="Confirma desactivar el apoderado?"
                                                    okText="Si"
                                                    cancelText="No"
                                                    onConfirm={(e) => {
                                                        this.setSumaState({ cargando: true });
                                                        this.callbackActivarApoderado(
                                                            e,
                                                            apoderado.id,
                                                            apoderado.datoPersonal.nombreCompleto,
                                                            false,
                                                            () => {
                                                                this.setSumaState({ cargando: false });
                                                                this.cargarDatosIniciales();
                                                            });

                                                    }}
                                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_NATURAL_APODERADOS.ACCIONES.DESACTIVAR_APODERADO.id)}
                                                >
                                                    <Button className="boton-opcion"
                                                        icon={<StopOutlined style={{ color: '#c90a00' }} />}
                                                        disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_NATURAL_APODERADOS.ACCIONES.DESACTIVAR_APODERADO.id)}>
                                                        Desactivar
                                                    </Button>
                                                </Popconfirm>
                                                :
                                                <></>
                                            }

                                            {(apoderado.estadoApoderado.id === Constantes.ENUMS.ESTADO_APODERADO.INACTIVO.id)
                                                ?
                                                <Popconfirm title="Confirma activar el apoderado?"
                                                    okText="Si"
                                                    cancelText="No"
                                                    onConfirm={(e) => {
                                                        this.setSumaState({ cargando: true });
                                                        this.callbackActivarApoderado(
                                                            e,
                                                            apoderado.id,
                                                            apoderado.datoPersonal.nombreCompleto,
                                                            true,
                                                            () => {
                                                                this.setSumaState({ cargando: false });
                                                                this.cargarDatosIniciales();
                                                            });

                                                    }}
                                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_NATURAL_APODERADOS.ACCIONES.ACTIVAR_APODERADO.id)}
                                                >
                                                    <Button className="boton-opcion"
                                                        icon={<CheckOutlined style={{ color: '#c90a00' }} />}
                                                        disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_NATURAL_APODERADOS.ACCIONES.ACTIVAR_APODERADO.id)}>
                                                        Activar
                                                    </Button>
                                                </Popconfirm>
                                                :
                                                <></>
                                            }

                                            {(apoderado.estadoApoderado.id)
                                                ?
                                                <Popconfirm title="Confirma eliminar el apoderado?"
                                                    okText="Si"
                                                    cancelText="No"
                                                    onConfirm={(e) => {
                                                        this.setSumaState({ cargando: true });
                                                        this.callbackEliminarApoderado(
                                                            apoderado.id,
                                                            apoderado.datoPersonal.nombreCompleto);

                                                    }}
                                                    disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_NATURAL_APODERADOS.ACCIONES.ELIMINAR_APODERADO.id)}
                                                >
                                                    <Button className="boton-opcion"
                                                        icon={<CloseOutlined style={{ color: '#c90a00' }} />}
                                                        disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_NATURAL_APODERADOS.ACCIONES.ELIMINAR_APODERADO.id)}>
                                                        Eliminar
                                                    </Button>
                                                </Popconfirm>
                                                :
                                                <></>
                                            }

                                        </>)
                                        :
                                        (<></>)
                                    }
                                </div>

                            </Spin>
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:VA]</label>) : (<></>))
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(VerApoderado));