import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Spin, Button } from 'antd';
import { withCookies } from 'react-cookie';
import { UserOutlined, IdcardOutlined } from '@ant-design/icons';
import ServicioDatosConstantes from '../servicios/ServicioDatosConstantes'
import Constantes from '../utilidades/Constantes'
import ServicioAdministracion from '../servicios/ServicioAdministracion'
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'

class TipoUsuario extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        vistaUsuarios: null,
        cargando: false,        

        filtro: {},
        filtroEtiquetas: [],
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioAdministracion = new ServicioAdministracion(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores, contexto) {
        if( !contexto )
            contexto = this;
            
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        this.setSumaState({ cargando: true, });
        // Validando que el usuario esté en sesion
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr !== null) {
            // Valida que tenga la accion habilitada de listar
            let habilitado = await this.servicioUsuarios.accionHabilitadaPromesa(Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.AGREGAR_USUARIO.id);
            if( habilitado && habilitado.resultado ){
                const tipos = await this.servicioDatosConstantes.obtenerTiposUsuario();
                let tiposUsuario = [];
                if( tipos ){
                    for( let tipo of tipos ){
                        tiposUsuario.push({ label: tipo.tipoUsuario, value: tipo.id });
                    }
                }
                this.setSumaState({
                    componente: this.ENUM_COMPONENTE.VER,cargando: false, usuario: usr, tiposUsuario:tiposUsuario
                });
            }
        } else {
            this.setSumaState({componente:this.ENUM_COMPONENTE.NO_DISPONIBLE,cargando: false,usuario: null});
        }
    }

    
    render() {

        const { componente, } = this.state;
        
        return (
            <>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>            
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>

                                <div className="titulo-seccion">
                                    <label>TIPO DE USUARIO</label>
                                    <div className="separador-horizontal-completo"></div>
                                    <p className="descripcion-seccion">Por favor selecciona el tipo de usuario a crear:</p>
                                </div>

                                <div className="seleccion-tipo-usuario">
                                    <div className="opcion-tipo-usuario persona-natural">
                                        <Button type="primary" onClick={()=>{this.props.callbackAgregarUsuario(Constantes.ENUMS.TIPO_USUARIO.ADMINISTRADOR_SUMA.id)}} icon={<UserOutlined />}/>
                                        <label>ADMINISTRADOR SUMA</label>
                                    </div>
                                    <div className="opcion-tipo-usuario persona-natural">
                                        <Button type="primary" onClick={()=>{this.props.callbackAgregarUsuario(Constantes.ENUMS.TIPO_USUARIO.ENTIDAD.id)}} icon={<IdcardOutlined />}/>
                                        <label>USUARIO DEPENDENCIA</label>
                                    </div>    
                                </div>

                            </Spin>
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:SETIUS]</label>) : (<></>))
                    )
                }
            </>
        );
    }
}

export default withRouter(withCookies(TipoUsuario));