import React, { Component } from 'react';
import SumaContexto from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Spin, Table, Button, Tooltip } from 'antd';
import { withCookies } from 'react-cookie';
import { BankOutlined, SearchOutlined } from '@ant-design/icons';
import ServicioDatosConstantes from '../../servicios/ServicioDatosConstantes'
import Constantes from '../../utilidades/Constantes'
import Utilidades from '../../utilidades/Utilidades'
import FiltroColumna from '../../general/tabla/FiltroColumna'
import FiltroEtiqueta from '../../general/tabla/FiltroEtiqueta'
//import moment from 'moment';
import ServicioAdministracion from '../../servicios/ServicioAdministracion'
import ServicioUsuarios from '../../servicios/usuarios/ServicioUsuarios'
import EstilosGlobales from '../../utilidades/EstilosGlobales';
import _ from 'lodash';

class ListarPersonasJuridicas extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        cargando: false,

        personasJuridicas: [],
        filtro: {},
        filtroEtiquetas: [],
        filtroApi: {},
        paginacion: {
            current: 1,
            pageSize: 10,
        },
        cargandoTabla: true
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.callbackVerPersonaJuridica = props.callbackVerPersonaJuridica;
        this.callbackActivarUsuario = props.callbackActivarUsuario;
        this.callbackEnviarCorreoActivacion = props.callbackEnviarCorreoActivacion;


        this.servicioAdministracion = new ServicioAdministracion(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        let contexto = this;
        this.filtroColumna = new FiltroColumna(props, (valores) => { contexto.setSumaState(valores, contexto) });
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores, contexto) {
        if (!contexto)
            contexto = this;

        if (this._isMounted) {
            this.setState(valores);
        }
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        this.setSumaState({ cargando: true, });
        // Validando que el usuario esté en sesion
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr !== null) {
            // Valida que tenga la accion habilitada de listar
            let habilitado = await this.servicioUsuarios.accionHabilitadaPromesa(Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.LISTAR_USUARIOS.id);
            if (habilitado && habilitado.resultado) {
                const estados = await this.servicioDatosConstantes.obtenerEstadosUsuario();
                const tipos = await this.servicioDatosConstantes.obtenerTiposUsuario();
                let estadosUsuario = [];
                let tiposUsuario = [];
                if (estados) {
                    for (let estado of estados) {
                        estadosUsuario.push({ label: estado.estadoUsuario, value: estado.id });
                    }
                }
                if (tipos) {
                    for (let tipo of tipos) {
                        tiposUsuario.push({ label: tipo.tipoUsuario, value: tipo.id });
                    }
                }
                await this.cargarDatosTabla(this);
                this.setSumaState({
                    componente: this.ENUM_COMPONENTE.VER, cargando: false,
                    estadosUsuario: estadosUsuario, usuario: usr, tiposUsuario: tiposUsuario
                });
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE, cargando: false, usuario: null });
        }
    }



    // 'limpiarFiltro': Método (clearFilters) que es propio de la tabla antDesign para limpiar los filtros
    // 'confirmarFiltro': Método (confirm) que es própio de la tabla antDesign para aplicar el filtro
    // 'dataIndex': Retorna el campo que se está filtrando
    // 'selectedKeys': Retorna el valor a filtrar;
    async cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) {
        let filtro = contexto.state.filtro;
        if (dataIndex !== undefined) { // Si el filtro es aplicado, se agrega la columna filtrada
            filtro[dataIndex] = selectedKeys;
        }
        contexto.setSumaState({ filtro: filtro });

        // Se construye el filtroApi a partir de lo que fue filtrado en la tabla
        let filtroApi = {
            ...(filtro.id && filtro.id.length > 0 && { id: filtro.id[0] }),
            ...(filtro.razonSocial && filtro.razonSocial.length > 0 && { razonSocial: filtro.razonSocial[0] }),
            ...(filtro.nit && filtro.nit.length > 0 && { nit: filtro.nit[0] }),
            ...(filtro.camaraComercio && filtro.camaraComercio.length > 0 && { camaraComercio: filtro.camaraComercio[0] }),
            ...(filtro.sector && filtro.sector.length > 0 && { sector: filtro.sector[0] }),
            ...(filtro.ciudad && filtro.ciudad.length > 0 && { ciudad: filtro.ciudad[0] }),
            ...(filtro.fechaCreacionFormato && filtro.fechaCreacionFormato.length > 0 && { fechasCreacion: filtro.fechaCreacionFormato[0] + '-' + filtro.fechaCreacionFormato[1] }),
        };

        const paginacion = this.state.paginacion;
        let paginacionApi = {
            paginaActual: this.state.paginacion.current,
            paginaTamano: this.state.paginacion.pageSize,
            ordenarColumna: this.state.paginacion.ordenarColumna,
            orden: this.state.paginacion.orden
        }

        let cambio = false;
        // Primero valida si hubo cambio a nivel de filtro
        if (!_.isEqual(this.state.filtroApi, filtroApi)) {
            paginacion.current = 1;
            paginacionApi.paginaActual = 1;
            cambio = true;
        }

        // Segundo valida si hubo cambio a nivel de paginación
        if (!cambio && !_.isEqual(this.state.paginacionApi, paginacionApi)) {
            cambio = true;
        }

        if (!cambio) {
            return; // Si no hubo cambio no se hace nada
        }

        contexto.setSumaState({
            cargandoTabla: true,
            filtro,
            filtroApi,
            paginacionApi,
            paginacion
        });

        await this.servicioAdministracion.obtenerPersonasJuridicas(
            {
                ...filtroApi,
                ...paginacionApi
            }
        )
            .then((respuestaApi) => {
                if (respuestaApi) {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.ADMINISTRACION.DATOS_RETORNADOS.id:
                            let tablaPaginada = respuestaApi.resultado;
                            const paginacion = this.state.paginacion;
                            paginacion.total = tablaPaginada.total;

                            contexto.setSumaState({
                                personasJuridicas: tablaPaginada.registros,
                                cargandoTabla: false,
                                paginacion
                            });

                            break;
                        default: break;
                    }
                }
            }).then(() => {
                if (limpiarFiltro !== undefined) { limpiarFiltro(); }
                if (confirmarFiltro !== undefined) { confirmarFiltro(); }
            });
    }

    handleTableChange = async (pagination, filters, sorter) => {
        const paginacion = this.state.paginacion;
        paginacion.current = pagination.current;
        paginacion.pageSize = pagination.pageSize;
        paginacion.ordenarColumna = sorter.field;
        paginacion.orden = sorter.order;
        this.setSumaState({ paginacion });
        await this.cargarDatosTabla(this);
    };


    render() {

        const { personasJuridicas, componente, usuario } = this.state;
        let { filtro } = this.state;
        filtro = filtro || {};
        let contexto = this;

        const columns = [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                with: 100,
                sorter: (a, b) => 0, 
                filteredValue: filtro.id || null,
                ...this.filtroColumna.busquedaTexto(
                    'Identificador',
                    'id',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            {
                title: 'Razón social',
                dataIndex: 'razonSocial',
                key: 'razonSocial',
                sorter: (a, b) => 0, 
                filteredValue: filtro.razonSocial || null,
                ...this.filtroColumna.busquedaTexto(
                    'Razón social',
                    'razonSocial',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            {
                title: 'Nit',
                dataIndex: 'nit',
                key: 'nit',
                sorter: (a, b) => 0, 
                filteredValue: filtro.nit || null,
                ...this.filtroColumna.busquedaTexto(
                    'Nit',
                    'nit',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            {
                title: 'Cámara comercio',
                dataIndex: 'camaraComercio',
                key: 'camaraComercio',
                sorter: (a, b) => 0, 
                filteredValue: filtro.camaraComercio || null,
                ...this.filtroColumna.busquedaTexto(
                    'Cámara comercio',
                    'camaraComercio',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            {
                title: 'Sector',
                dataIndex: 'sector',
                key: 'sector',
                sorter: (a, b) => 0, 
                filteredValue: filtro.sector || null,
                ...this.filtroColumna.busquedaTexto(
                    'Sector',
                    'sector',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            {
                title: 'Ciudad',
                dataIndex: 'ciudad',
                key: 'ciudad',
                sorter: (a, b) => 0, 
                filteredValue: filtro.ciudad || null,
                ...this.filtroColumna.busquedaTexto(
                    'Ciudad',
                    'ciudad',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            {
                title: 'Fecha Creación',
                align: 'center',
                dataIndex: 'fechaCreacionFormato',
                key: 'fechaCreacionFormato',
                width: 100,
                sorter: (a, b) => 0, 
                filteredValue: filtro.fechaCreacionFormato || null,
                ...this.filtroColumna.busquedaFecha(
                    'Fecha Creación',
                    'fechaCreacionFormato',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                )
            },
            {
                title: 'Opciones',
                key: 'opciones',
                align: 'center',
                className: 'columna-opciones',
                width: 112,
                render: (text, record) => (
                    <>
                        <Tooltip title="Ver persona jurídica">
                            <Button shape="circle" disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.USUARIOS.ACCIONES.VER_USUARIO.id)}
                                icon={<BankOutlined style={{ 'color': '#c90a00' }} />}
                                onClick={(e) => { this.callbackVerPersonaJuridica(e, record.id) }} />
                        </Tooltip>


                    </>
                ),
            },
        ];

        return (
            <>
                <EstilosGlobales.CONTENEDOR_COMPLETO />
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>

                                {
                                    this.state.filtroEtiquetas.length > 0 ?
                                        (<div className="seccion-filtros-etiquetas">
                                            <div className="titulo-filtro-etiquetas">
                                                <label>Filtros <SearchOutlined /> :</label>
                                            </div>

                                            {
                                                this.state.filtroEtiquetas.map((filtroEtiqueta, i) => {
                                                    return (
                                                        <FiltroEtiqueta key={i}
                                                            dataIndex={filtroEtiqueta.dataIndex}
                                                            texto={filtroEtiqueta.texto}
                                                            onClose={filtroEtiqueta.borrarFiltro} />
                                                    )
                                                })
                                            }
                                        </div>)
                                        :
                                        (<></>)
                                }

                                <Table columns={columns}
                                    dataSource={personasJuridicas}
                                    rowKey={"id"}
                                    onChange={this.handleTableChange}
                                    pagination={this.state.paginacion}
                                    loading={this.state.cargandoTabla}
                                />
                            </Spin>
                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:LISPJ]</label>) : (<></>))
                    )
                }
            </>
        );
    }
}

export default withRouter(withCookies(ListarPersonasJuridicas));