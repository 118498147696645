import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import ServicioUsuarios from '../../servicios/usuarios/ServicioUsuarios'
import Constantes from '../../utilidades/Constantes'
import { Form, Input, Button, Row, Col, Modal, Spin } from 'antd';

class EnviarCorreoActivacionCuenta extends Component {

    state = {
        cargando: false,
    };

    constructor(props) {
        super(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }


    formRef = React.createRef();

    render() {

        const { cargando } = this.state;

        const layout = {
            labelCol: {
                sm: { span: 22, offset: 2 },
                md: { span: 12, offset: 2 },
                lg: { span: 12, offset: 2 },
                xl: { span: 10, offset: 2 }
            },
            wrapperCol: {
                sm: { span: 22, offset: 2 },
                md: { span: 10 },
                lg: { span: 10 },
                xl: { span: 12 },
            },
        };

        const onFinish = values => {
            this.setSumaState({ cargando: true });
            let correoElectronico = values.correoElectronico;
            this.servicioUsuarios.enviarCorreoActivacionCuenta(
                { correoElectronico:correoElectronico.toLowerCase() },
                (respuestaApi) => {
                    let form = null;
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.USUARIO.OPERACION_REALIZADA.id:
                            Modal.success({ content: 'Hemos enviado un correo a ' + correoElectronico + ' para iniciar el proceso de activación de la cuenta' });
                            if (this._isMounted) {
                                form = this.formRef.current;
                                if (form != null) {
                                    form.resetFields();
                                }
                            }
                            break;
                        case Constantes.ENUMS_API.USUARIO.USUARIO_NO_REGISTRADO.id:
                            Modal.error({ content: 'El usuario ' + correoElectronico + ' no se encuentra registrado dentro del portal SUMA.' });
                            break;
                        case Constantes.ENUMS_API.USUARIO.ERROR_SERVICIO_CORREO_ELECTRONICO.id:
                            Modal.error({content:respuestaApi.mensaje});
                            break; 
                        case Constantes.ENUMS_API.USUARIO.USUARIO_CON_ESTADO_NO_PERMITIDO.id:
                            Modal.error({content:respuestaApi.mensaje});
                            break;        
                        default:
                            Modal.error({ content: 'No fue posible enviar el correo para activar la cuenta, verifica tu conexión a internet o comunícate con la mesa de ayuda del DADEP (mesadeayuda@dadep.gov.co) para atender tu caso.' });
                            break;
                    }

                    //console.log('respuestaApi', respuestaApi);
                    setTimeout(() => { this.setSumaState({ cargando: false }); }, 500);
                },
                (errorAPI) => {
                    this.setSumaState({ cargando: false });
                    Modal.error({ content: 'Error de conexión. Por favor intenta mas tarde. [Cod:ECAC01]' })
                }
            );

        };

        const onFinishFailed = errorInfo => {
            Modal.warning({ content: "Por favor completa los campos faltantes" });
        };

        return (
            <div className="seccion-principal seccion-flex">
                <div className="division-principal">
                    <label>Enviar correo para actiar cuenta</label>
                </div>
                <div className="division-contenido seccion-flex-grow-1 padding-principal bkg-white">

                    <Spin tip="Cargando..." size="large" spinning={cargando}>
                        <Form
                            {...layout}
                            ref={this.formRef}
                            name="recuperar-contrasena"
                            className="formulario-suma"
                            scrollToFirstError
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >

                            <Row>
                                <span>
                                    <br />
                                    Usa esta opción si has creado tu usuario dentro del portal SUMA+ y no te ha llegado el correo 
                                    para iniciar el proceso de activación de tu cuenta. 
                                    <br /><br />
                                    Nota: No se te olvide revisar también la bandeja de entrada de correo no deseado (spam) para 
                                    verificar si te está llegando allí el correo. 
                                    <br /><br />
                                </span>
                                <Col xs={24} sm={12}>
                                    <Form.Item label="Correo electrónico" name="correoElectronico"
                                        rules={[
                                            { required: true, message: 'Ingresa tu correo electrónico', },
                                            { type: 'email', message: 'Ingresa un correo electrónico válido', },
                                            { max: 255, message: 'No puede ser mayor a 255 caracteres', },
                                        ]}
                                    >
                                        <Input autoComplete="off" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={24} sm={12}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" loading={cargando}>
                                            Enviar correo para activar cuenta
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </div>
            </div>
        );
    }
}

export default withRouter(EnviarCorreoActivacionCuenta);