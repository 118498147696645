import React, { Component } from 'react';
import logo_suma from '../recursos/imagenes/logo_suma.svg'
import logo_suma_eslogan from '../recursos/imagenes/logo_suma_eslogan.svg'

import logo_alcaldia_mayor_bogota from '../recursos/imagenes/logo_alcaldia_mayor_bogota.svg'
import logo_dadep_eslogan from '../recursos/imagenes/logo_dadep_eslogan.svg'
import logo_bogota from '../recursos/imagenes/logo_bogota.svg'

import MenuSuma from './MenuSuma'
 
class Encabezado extends Component {

  constructor(props){
    super(props);
    this.callbackCerrarSesion = props.callbackCerrarSesion;
  }

  render() {
    return (
      <header>
          <div className="banner-superior padding-laterales">
              <div className="imagenes-suma seccion-imagenes">
                <img src={logo_suma} className="logo-suma img-banner" alt="SUMA" />
                <img src={logo_suma_eslogan} className="logo-suma-eslogan img-banner" alt="SUMA" />
              </div>
              <div className="imagenes-dadep seccion-imagenes">
                <img src={logo_alcaldia_mayor_bogota} className="logo-alcaldia-mayor-bogota img-banner" alt="SUMA" />
                <div className="separador-vertical separador-alcaldia-mayor-bogota"></div>
                <img src={logo_dadep_eslogan} className="logo-dadep-eslogan img-banner" alt="SUMA" />
                <div className="separador-vertical separador-dadep-eslogan"></div>
                <img src={logo_bogota} className="logo-bogota img-banner" alt="SUMA" />
              </div>
          </div>
          <div className="banner-menu padding-laterales">
            <MenuSuma usuario={this.props.usuario} 
                      callbackUsuarioLogueado={this.props.callbackUsuarioLogueado}
                      callbackCerrarSesion={this.callbackCerrarSesion}/>
          </div>
      </header>
    );
  }
}
 
export default Encabezado;