import React, { Component } from 'react'
import SumaContexto  from '../contexto/SumaContexto'
import { withRouter } from "react-router-dom"
import { Form, Input, Button, Row, Col, Select, DatePicker, Modal, Spin, Popconfirm } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Validadores from '../utilidades/Validadores'
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import Constantes from '../utilidades/Constantes'
import ServicioDatosConstantes from '../servicios/ServicioDatosConstantes'
import ServicioPais from '../servicios/ServicioPais'
import { withCookies } from 'react-cookie';
import moment from 'moment';

const dateFormat = 'DD/MM/YYYY';

class EditarInformacionBasica extends Component {

    static contextType = SumaContexto;
    
    ENUM_COMPONENTE ={
        CARGANDO:1,
        VER:2,
        NO_DISPONIBLE:3
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        cargando: true,
        cargandoPais: false,
        paises:[],
        departamentos:[],
        ciudades:[],
        tiposDocumentos:[],
        cargandoTipoDocumentos: false,
    };

    constructor(props){
        super(props);
        this.servicioUsuarios = new ServicioUsuarios( props );
        this.servicioDatosConstantes = new ServicioDatosConstantes( props );
        this.servicioPais = new ServicioPais( props );
        this.onSuccess = props.onSuccess;
    }
    //const [form] = Form.useForm();
    formRef = React.createRef();

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales(){
        this.setSumaState({ cargando:true, cargandoTipoDocumentos:true });
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if( usr !== null ){
            this.setSumaState({ componente: this.ENUM_COMPONENTE.VER });
            let form = this.formRef.current;
            if( form != null ){
                //console.log('usr',usr);
                await this.obtenerPaises();
                await this.obtenerDepartamentos( usr.datoPersonal.ciudad.idPais );
                await this.obtenerCiudades( usr.datoPersonal.ciudad.idDepartamento );
                await this.obtenerTipoDocumentos();
                form.setFieldsValue({
                    'primerNombre' : usr.datoPersonal.primerNombre,
                    'segundoNombre' : usr.datoPersonal.segundoNombre,
                    'primerApellido' : usr.datoPersonal.primerApellido,
                    'segundoApellido' : usr.datoPersonal.segundoApellido,
                    'tipoDocumento': usr.datoPersonal.tipoDocumento.id.toString(),
                    'numeroDocumento': usr.datoPersonal.numeroDocumento,
                    'lugarExpedicion': usr.datoPersonal.lugarExpedicion,
                    'correoElectronico' : usr.datoPersonal.correoElectronico,
                    'movil' : usr.datoPersonal.celular,
                    'telefono' : usr.datoPersonal.telefono,
                    'pais': usr.datoPersonal.ciudad.idPais.toString(), 
                    'region': usr.datoPersonal.ciudad.idDepartamento.toString(),  
                    'ciudad': usr.datoPersonal.ciudad.id.toString(),  
                    'direccion' : usr.datoPersonal.direccion,
                    ...(usr.datoPersonal.fechaExpedicionFormatoEstandar && {'fechaExpedicion': moment(usr.datoPersonal.fechaExpedicionFormatoEstandar, dateFormat)} ),
                });
                this.setSumaState({ cargando: false });
            }
        }
    }

    obtenerTipoDocumentos () {
        return new Promise(resolve => {
            this.servicioDatosConstantes.obtenerTiposDocumentos(
                ( tiposDocumentos ) => { 
                    this.setSumaState({ tiposDocumentos: tiposDocumentos, }); 
                    setTimeout(() => {
                        this.setSumaState({ cargandoTipoDocumentos: false });
                        resolve(true);
                    },500); 
                },
                ( errorAPI )        => { 
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:MCEIB01]" });
                    resolve(true);
                },
            );
        });
    }


    obtenerPaises () {
        return new Promise(resolve => {
            this.servicioPais.obtenerPaises(
                ( paises )      => { 
                    this.setSumaState({ paises: paises, }); 
                    resolve(true);
                },
                ( errorAPI )    => { 
                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:MCEIB04]" }); 
                    resolve(true);
                },
            );
        });
    }

    obtenerDepartamentos = idPais =>{
        let form = this.formRef.current;
        this.setSumaState({ cargandoPais: true });
        return new Promise(resolve => {
            this.servicioPais.obtenerDepartamentosDelPais(
                idPais,
                ( departamentos )   => { 
                                            setTimeout(() => {
                                                if( form != null ){
                                                    this.setSumaState({ cargandoPais: false, departamentos: departamentos, });
                                                    form.setFieldsValue({'region':'', 'ciudad':''});
                                                }
                                                resolve(true);
                                            }, 500); 
                                        },
                ( errorAPI )        => { 
                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:MCEIB05]" }); 
                    resolve(true);
                },
            );
        });
    }

    obtenerCiudades = idDepartamento =>{
        let form = this.formRef.current;
        this.setSumaState({ cargandoPais: true });
        return new Promise(resolve => {
            this.servicioPais.obtenerCiudadesDelDepartamento(
                idDepartamento,
                ( ciudades )   =>   { 
                                        setTimeout(() => {
                                            if( form != null ){
                                                this.setSumaState({ cargandoPais: false, ciudades: ciudades, });
                                                form.setFieldsValue({'ciudad':''});
                                            }
                                            resolve(true);
                                        },500);
                                    },
                ( errorAPI )   => { 
                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:MCEIB06]" }); 
                    resolve(true);
                },
            );
        });
    }
  
    handlePaisChange = value => {
        //console.log('PaisCambio ', value );
        this.obtenerDepartamentos( value );
    };
    handleDepartamentoChange = value => {
        //console.log('DepartamentoCambio ', value );
        this.obtenerCiudades( value );
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }
  
    cancelarFormulario = () => {
        this.onSuccess(true);
    }
    

    render() {
      //let  {form} = this.props.form;
      const { Option } = Select;
      const { cargando, componente, cargandoTipoDocumentos, tiposDocumentos,
        cargandoPais, paises, departamentos, ciudades } = this.state;
      const dateFormatList = ['DD/MM/YYYY'];

      const layout = {
        labelCol: {
          /*span: 10, offset: 2,*/
          sm: {span: 22, offset: 2},
          md: {span: 12, offset: 2},
          lg: {span: 12, offset: 2},
          xl: {span: 10, offset: 2}
        },
        wrapperCol: {
          sm: {span: 22, offset: 2},
          md: {span: 10},
          lg: {span: 10},  
          xl: {span: 12},
        },
      };

      const onFinish = values => {
        this.setSumaState({ cargando: true });
        let usuario = {
            datoPersonal:{
                primerNombre : values.primerNombre,
                segundoNombre : values.segundoNombre,
                primerApellido : values.primerApellido,
                segundoApellido : values.segundoApellido,
                numeroDocumento : values.numeroDocumento,
                lugarExpedicion : values.lugarExpedicion,
                fechaExpedicionDocumento: values.fechaExpedicion.format('DD/MM/YYYY'),
                celular : values.movil,
                telefono : values.telefono,
                direccion: values.direccion,
                ciudad:{
                    id: parseInt(values.ciudad)
                },
                tipoDocumento : {
                    id: parseInt(values.tipoDocumento)
                }, 
            }
        };

        this.servicioUsuarios.editarUsuarioActual(
            ( respuestaApi )   =>  {  
                switch(respuestaApi.codigo){
                    case Constantes.ENUMS_API.USUARIO.OPERACION_REALIZADA.id:
                        this.onSuccess();
                        return;
                    case Constantes.ENUMS_API.USUARIO.DOCUMENTO_IDENTIDAD_NO_DISPONIBLE.id:
                        Modal.error({content:'No fue posible actualizar los datos. Ya se encuentra registrado el número de documento de identidad que fue ingresado.'});
                        break;
                    default:
                        Modal.error({content:'No fue posible actualizar los datos. Por favor intenta mas tarde. [Cod:MCEIB02]'});
                        break;
                }

                console.log('respuestaApi',respuestaApi); 
                setTimeout(() => {this.setSumaState({ cargando: false });},500); 
            },
            ( errorAPI )        => { 
                this.setSumaState({ cargando: false });
                Modal.error({content:'Error de conexión. Por favor intenta mas tarde. [Cod:MCEIB03]'});
            },
            usuario
        );
      };
    
      const onFinishFailed = errorInfo => {
        Modal.warning({content:'Por favor diligencia la información solicitada.'});
      };
  
        return (
          <div>    
            {(componente === this.ENUM_COMPONENTE.VER) ?
                (
                    <>
                        <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>
                            <Form
                                {...layout}
                                ref={this.formRef}
                                name="formulario-persona-natural"
                                className="formulario-suma"
                                scrollToFirstError
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                >
                                <Row>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Primer nombre" name="primerNombre" 
                                            rules=  {[
                                                        { required: true, message: 'Ingresa tu primer nombre',},
                                                        { max:100, message: 'No puede ser mayor a 100 caracteres',},
                                                    ]} 
                                        >
                                            <Input autoComplete="off" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Segundo nombre" name="segundoNombre" 
                                            rules=  {[
                                                        { required: false, },
                                                        { max:100, message: 'No puede ser mayor a 100 caracteres',},
                                                    ]} 
                                        >
                                            <Input autoComplete="off" />
                                        </Form.Item>
                                    </Col>    
                                </Row>
                                <Row>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Primer apellido" name="primerApellido" 
                                            rules=  {[
                                                        { required: true, message: 'Ingresa tu primer apellido',},
                                                        { max:100, message: 'No puede ser mayor a 100 caracteres',},
                                                    ]} 
                                        >
                                            <Input autoComplete="off" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Segundo apellido" name="segundoApellido" 
                                            rules=  {[
                                                        { required: false, },
                                                        { max:100, message: 'No puede ser mayor a 100 caracteres',},
                                                    ]} 
                                        >
                                            <Input autoComplete="off" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={24} sm={12}>
                                        <Form.Item  label="Tipo de documento" name="tipoDocumento" 
                                                    rules=  {[{ required: true, message: 'Selecciona tu tipo de documento',},]}
                                                    {...(cargandoTipoDocumentos ? {validateStatus:'validating', hasFeedback:true } : {})}>
                                            <Select {...(cargandoTipoDocumentos ? {disabled:true } : {})}>
                                                {tiposDocumentos.map( tipoDocumento => (
                                                    <Option key={ tipoDocumento.id }>{ tipoDocumento.tipoDocumento }</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Número de documento" name="numeroDocumento" dependencies={['tipoDocumento']}
                                            rules=  {[
                                                        { required: true, message: 'Ingresa tu número de documento',},
                                                        { min:5, message: 'No puede ser menor a 5 caracteres',},
                                                        { max:50, message: 'No puede ser mayor a 50 caracteres',},
                                                        ({ getFieldValue }) => ({
                                                            validator(rule, value) {
                                                                if (value && parseInt(getFieldValue('tipoDocumento')) === Constantes.ENUMS.TIPO_DOCUMENTO.CEDULA_CIUDADANIA.id && !Validadores.validarDigitos( value ).success ) {
                                                                    return Promise.reject('Ingresa solo dígitos');
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        }),
                                                    ]} 
                                        >
                                            <Input autoComplete="off" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Lugar de expedición" name="lugarExpedicion" 
                                            rules=  {[
                                                        { required: true, message: 'Ingresa el lugar de expedición del documento',},
                                                        { min:4, message: 'No puede ser menor a 4 caracteres',},
                                                        { max:250, message: 'No puede ser mayor a 250 caracteres',},
                                                    ]} 
                                        >
                                            <Input autoComplete="off" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Fecha expedición" name="fechaExpedicion" rules={[{ required: true, message: 'Ingresa la fecha de expedición del documento',},]} >
                                            <DatePicker autoComplete="off" format={dateFormatList} style={{ width: '100%' }}/>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={24} sm={12}>
                                        <Form.Item  label="Móvil" name="movil" 
                                                    rules=  {[
                                                        { required: true, message: 'Ingresa tu móvil',},
                                                        { min:7, message: 'No puede ser menor a 7 caracteres',},
                                                        { max:20, message: 'No puede ser mayor a 20 caracteres',},
                                                        { pattern: new RegExp('^[0-9\\+\\s]*$'), message: 'Solo dígitos y puede contener el simbolo +',},
                                                    ]} >
                                            <Input autoComplete="off" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Teléfono" name="telefono" 
                                                        rules=  {[
                                                            { required: false},
                                                            { min:7, message: 'No puede ser menor a 7 caracteres',},
                                                            { max:30, message: 'No puede ser mayor a 30 caracteres',},
                                                        ]}>
                                            <Input autoComplete="off" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={24} sm={12}>
                                        <Form.Item  label="Pais" name="pais" initialValue={`${Constantes.ENUMS.PAIS.COLOMBIA.id}`} 
                                                    rules={[{ required: true, message:'Selecciona tu pais'},]}
                                                    {...(cargandoPais ? {validateStatus:'validating', hasFeedback:true } : {})}>
                                            <Select {...(cargandoPais ? {disabled:true } : {})} onChange={this.handlePaisChange} >
                                                {paises.map( pais => (
                                                    <Option key={ pais.id }>{ pais.pais }</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item  label="Región" name="region" 
                                                    rules={[{ required: true, message:'Selecciona tu región'},]}
                                                    {...(cargandoPais ? {validateStatus:'validating', hasFeedback:true } : {})}>
                                            <Select {...(cargandoPais ? {disabled:true } : {})} onChange={this.handleDepartamentoChange}>
                                                {departamentos.map( departamento => (
                                                    <Option key={ departamento.id }>{ departamento.departamento }</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={24} sm={12}>
                                        <Form.Item  label="Ciudad" name="ciudad" 
                                                    rules={[{ required: true, message: 'Selecciona tu ciudad',},]}
                                                    {...(cargandoPais ? {validateStatus:'validating', hasFeedback:true } : {})}>
                                            <Select {...(cargandoPais ? {disabled:true } : {})} >
                                                {ciudades.map( ciudad => (
                                                    <Option key={ ciudad.id }>{ ciudad.ciudad }</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item  label="Dirección residencia" name="direccion" 
                                                    rules=  {[
                                                        { required: true, message: 'Ingresa tu dirección de residencia',},
                                                        { min:5, message: 'No puede ser menor a 5 caracteres',},
                                                        { max:250, message: 'No puede ser mayor a 250 caracteres',},
                                                    ]} >
                                            <Input autoComplete="off" />
                                        </Form.Item>
                                    </Col>
                                </Row>


                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={ cargando || cargandoTipoDocumentos }>
                                        Guardar
                                    </Button>
                                    &nbsp;&nbsp;
                                    <Popconfirm title="Confirma cancelar la edición"
                                                okText="Si"
                                                cancelText="No" 
                                                onConfirm={this.cancelarFormulario}>
                                        <Button icon={<CloseOutlined/>}
                                                loading={ cargando || cargandoTipoDocumentos }>
                                                    Cancelar
                                        </Button>
                                    </Popconfirm>
                                </Form.Item>

                            </Form>
                        </Spin>
                    </>
                )
                :
                (
                    (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                    (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                    :((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:MCEIB]</label>) : (<></>))
                )
            }

          </div>
        );
    }
}
          
export default withRouter(withCookies(EditarInformacionBasica));