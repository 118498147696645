import React, { Component } from 'react';
import SumaContexto  from '../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Button } from 'antd';
import { UserOutlined, BankOutlined } from '@ant-design/icons';
import Constantes from '../../utilidades/Constantes';

class SeleccionTipoUsuario extends Component {

    static contextType = SumaContexto;
  
    handleClickPersonaNatural = e => {
        this.historyLocal.push( Constantes.URL_PUBLICAS.CREAR_CUENTA_PERSONA_NATURAL );  
    };

    handleClickPersonaJuridica = e => {
        this.historyLocal.push( Constantes.URL_PUBLICAS.CREAR_CUENTA_PERSONA_JURIDICA );
    };

    render() {
      
      //const { usuario } = this.context;
      const { history } = this.props;
      this.historyLocal = history;

  
        return (
          <div className="seccion-principal seccion-flex">
            <div className="division-principal">
              <label>Crear cuenta</label>
            </div>
            <div className="division-contenido seccion-flex-grow-1 padding-principal bkg-white">
                <div className="titulo-seccion">
                  <label>CREAR NUEVA CUENTA</label>
                  <div className="separador-horizontal-completo"></div>
                  <p className="descripcion-seccion">Seleccione alguna de las siguientes opciones, según sea el caso:</p>
                </div>

                <div className="seleccion-tipo-usuario">
                  <div className="opcion-tipo-usuario persona-natural">
                      <Button type="primary" onClick={this.handleClickPersonaNatural} icon={<UserOutlined />}/>
                      <label>PERSONA NATURAL</label>
                  </div>
                  <div className="opcion-tipo-usuario persona-juridica">
                      <Button type="primary" onClick={this.handleClickPersonaJuridica} icon={<BankOutlined />}/>
                      <label>PERSONA JURÍDICA</label>
                  </div>    
                </div>
              </div>
          </div>
        );
    }
}
          
export default withRouter(SeleccionTipoUsuario);