import React, { Component } from 'react';
import SumaContexto  from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Row, Col, Modal, Spin, Tooltip, Tag } from 'antd';
import { FileOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import Constantes from '../utilidades/Constantes'
import ServicioDatosConstantes from '../servicios/ServicioDatosConstantes'
import ServicioPersonaJuridica from '../servicios/usuarios/ServicioPersonaJuridica'
import ActualizarArchivo from '../carga-archivos/ActualizarArchivo'
import Utilidades from '../utilidades/Utilidades'


class PersonaJuridicaDocumentos extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE ={
        CARGANDO:1,
        VER:2,
        NO_DISPONIBLE:3,
        DILIGENCIAR_DATOS_BASICOS:4
    }
    
    state = {
        cargando: true,
        tipoArhivosPersonaJuridica: null,
        personaJuridicaArchivos: null,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        usuario:null,
    };

    constructor(props){
        super(props);
        //console.log('PersonaJuridicaDocumentos.props',props);

        // Indica que es el administrador del SUMA quien está accediendo al apoderado
        this.administracion = props.administracion;

        this.servicioDatosConstantes = new ServicioDatosConstantes( props );
        this.servicioPersonaJuridica = new ServicioPersonaJuridica ( props );
    }
   
    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales(){
        let usr = await this.servicioPersonaJuridica.usuarioPersonaJuridicaOAdministradorValido();
        //this.setState({ componente:(usr !== null ? this.ENUM_COMPONENTE.VER : this.ENUM_COMPONENTE.NO_DISPONIBLE)});
        if( usr !== null ){
            if( usr.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.PERSONA_JURIDICA.id ){
                if( usr.usuarioPersonaJuridica ){
                    this.setState({ componente: this.ENUM_COMPONENTE.VER });    
                }
                else{
                    this.setState({ componente: this.ENUM_COMPONENTE.DILIGENCIAR_DATOS_BASICOS });
                }
            }else if( usr.tipoUsuario.id === Constantes.ENUMS.TIPO_USUARIO.ADMINISTRADOR_SUMA.id ){
                this.setState({ componente: this.ENUM_COMPONENTE.VER });    
            }else{
                this.setState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
            }
        }else{
            this.setState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }

        if(this.state.componente === this.ENUM_COMPONENTE.VER ){
            this.setState({ cargando: true });
            let tipoArhivosPersonaJuridica = await this.obtenerTipoArchivoPersonaJuridica();
            let personaJuridicaArchivos = await this.obtenerArchivos();

            if( personaJuridicaArchivos !== null && tipoArhivosPersonaJuridica != null ){
                // Se quitan los tipos de archivos que ya tiene el representante registrados
                for( let i = 0; i < personaJuridicaArchivos.length; i++ ){
                    for( let m = 0; m < tipoArhivosPersonaJuridica.length; m++ ){
                        if( personaJuridicaArchivos[i].archivo.tipoArchivo.id === tipoArhivosPersonaJuridica[m].id ){
                            tipoArhivosPersonaJuridica.splice( m, 1 );
                            break;
                        }
                    }
                }
                this.setState({ 
                                usuario: usr,
                                cargando: false, 
                                personaJuridicaArchivos:personaJuridicaArchivos,
                                tipoArhivosPersonaJuridica: tipoArhivosPersonaJuridica,
                            });
            }
        }
        
    }

    obtenerArchivos() {
        return new Promise(resolve => {
            this.servicioPersonaJuridica.obtenerArchivos(
                ( respuestaApi ) => { 
                    switch(respuestaApi.codigo){
                        case Constantes.ENUMS_API.PERSONA_JURIDICA.DATOS_RETORNADOS.id:
                            resolve( respuestaApi.resultado );
                            break;
                        default:
                            Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:PJD02]" });
                            resolve( null );
                            break;    
                    }
                },
                ( errorAPI )        => { 
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:PJD03]" });
                    resolve(null);
                },
                // Si está en modo administración del SUMA, este dato se captura de la url por medio de HookReactRouterV6Parametros
                this.props.idPersonaJuridica
            );
        });
    }

    obtenerTipoArchivoPersonaJuridica () {
        return new Promise(resolve => {
            this.servicioDatosConstantes.obtenerTipoArchivoPersonaJuridica(
                ( tipoArhivosPersonaJuridica ) => { 
                    resolve( tipoArhivosPersonaJuridica );
                },
                ( errorAPI )        => { 
                    Modal.error({content: "Error del sistema, por favor intenta más tarde. [Cod:PJD01]" });
                    resolve(null);
                },
            );
        });
    }

    actualizarPersonaJuridicaArchivo( personaJuridicaArchivo ) {
        return new Promise(resolve => {
            this.servicioPersonaJuridica.actualizarPersonaJuridicaArchivo(
                ( respuestaApi ) => { 
                    //console.log('respuestaApi:::::',respuestaApi);
                    switch(respuestaApi.codigo){
                        case Constantes.ENUMS_API.PERSONA_JURIDICA.OPERACION_REALIZADA.id:
                            resolve( respuestaApi.resultado );
                            break;
                        default:
                            Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:PJD06]" });
                            resolve( null );
                            break;    
                    }
                },
                ( errorAPI )        => { 
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:PJD07]" });
                    resolve(null);
                },
                personaJuridicaArchivo,
                // Si está en modo administración del SUMA, este dato se captura de la url por medio de HookReactRouterV6Parametros
                this.props.idPersonaJuridica
            );
        });
    }


    agregarPersonaJuridicaArchivo( idArchivo ) {
        let personaJuridicaArchivo = {
            archivo:{ id:idArchivo },
        };
        return new Promise(resolve => {
            this.servicioPersonaJuridica.agregarPersonaJuridicaArchivo(
                ( respuestaApi ) => { 
                    //console.log('respuestaApi:::::',respuestaApi);
                    switch(respuestaApi.codigo){
                        case Constantes.ENUMS_API.PERSONA_JURIDICA.OPERACION_REALIZADA.id:
                            resolve( respuestaApi.resultado );
                            break;
                        default:
                            Modal.error({ message: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:PJD04]" });
                            resolve( null );
                            break;    
                    }
                },
                ( errorAPI )        => { 
                    Modal.error({ message: 'Error', content: "Error del sistema, por favor intenta más tarde. [Cod:PJD05]" });
                    resolve(null);
                },
                personaJuridicaArchivo,
                // Si está en modo administración del SUMA, este dato se captura de la url por medio de HookReactRouterV6Parametros
                this.props.idPersonaJuridica
            );
        });
    }

   
    componentDidMount() {
        this.cargarDatosIniciales();
    }


    async archivoPersonaJuridicaActualizado( idNuevoArchivo, personaJuridicaArchivo, tipoArchivo ){
        this.setState({ cargando: true });
        //console.log('personaJuridicaArchivo',personaJuridicaArchivo);
        let resultado = null;
        if( personaJuridicaArchivo !== null ){
            personaJuridicaArchivo.archivo = { id:idNuevoArchivo };
            resultado = await this.actualizarPersonaJuridicaArchivo( personaJuridicaArchivo );
        }else{
            resultado = await this.agregarPersonaJuridicaArchivo( idNuevoArchivo );
        }

        if( resultado !== null ){
            this.setState({ personaJuridicaArchivos: null, tipoArhivosPersonaJuridica:null });
            this.cargarDatosIniciales();
            Modal.success({ 
                content: "El documento " + 
                tipoArchivo.nombreArchivo + 
                " fue " + ( personaJuridicaArchivo === null ? 'agregado' : 'actualizado') + " satisfactoriamente." 
            });
        }
    }


    render() {
      //const { usuario } = this.context;
      const { componente, personaJuridicaArchivos, tipoArhivosPersonaJuridica, usuario } = this.state;
      
      const wrapperColDocs01 = {
        xs: {span:21, offset: 1},  
        sm: {span:7, offset: 1},
      };
      const wrapperColDocs02 = {
        xs: {span:21, offset: 1},  
        sm: {span:15, offset: 1},
      };

        return (
          <div>    
              {(componente === this.ENUM_COMPONENTE.VER) ?
                (
                    <>
                        <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>
                            
                            <h3 className="sub-titulo-separador margen-bottom margen-top">Documentos persona jurídica</h3>
                            <div className="seccion-datos">

                                {
                                    (personaJuridicaArchivos !== null)
                                    ?
                                    personaJuridicaArchivos.map((personaJuridicaArchivo, index) => 
                                        <Row className="fila documento" key={index} >
                                            <Col  {...wrapperColDocs01}>
                                                <a className="enlace-documento tamano-25" href={personaJuridicaArchivo.archivo.url} target="_blank" rel="noopener noreferrer">
                                                    <div>
                                                        <FileOutlined/> 
                                                        <div>
                                                            <label className="bold">{personaJuridicaArchivo.archivo.tipoArchivo.nombreArchivo}</label>
                                                            <br/>
                                                            <label>Ver documento</label>
                                                        </div>
                                                    </div>
                                                    { personaJuridicaArchivo.archivo.actualizarArchivo
                                                    ? 
                                                        <Tooltip title="El documento debe ser actualizado!">
                                                            <Tag icon={<ExclamationCircleOutlined />} color="magenta" key="Cambio Contraseña">ACTUALIZAR DOCUMENTO</Tag>
                                                        </Tooltip>
                                                    : 
                                                        <></>
                                                    }
                                                    
                                                </a>
                                            </Col>
                                            <Col  {...wrapperColDocs02}>
                                                <ActualizarArchivo key={index}
                                                    tipoArchivo={personaJuridicaArchivo.archivo.tipoArchivo} 
                                                    agregar={false}
                                                    callbackArchivoActualizado={(idNuevoArchivo) => { 
                                                                                            this.archivoPersonaJuridicaActualizado(
                                                                                                                                        idNuevoArchivo,
                                                                                                                                        personaJuridicaArchivo,
                                                                                                                                        personaJuridicaArchivo.archivo.tipoArchivo
                                                                                                                                    ) 
                                                                                                    }}
                                                    deshabilitar={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_DOCUMENTOS.ACCIONES.ACTUALIZAR_DOCUMENTOS_PERSONA_JURIDICA.id)}
                                                />
                                            </Col>    
                                        </Row>
                                    )
                                    :
                                    (<></>)
                                }

                                {
                                    (tipoArhivosPersonaJuridica !== null)
                                    ?
                                    tipoArhivosPersonaJuridica.map((tipoArhivoPersonaJuridica, index) => 
                                        <Row className="fila documento" key={index} >
                                            <Col  {...wrapperColDocs01}>
                                                <Tooltip title="No tiene asociado el documento">
                                                    <div className="enlace-documento tamano-25" >
                                                        <div>
                                                            <FileOutlined/> 
                                                            <div>    
                                                                <label className="bold">{tipoArhivoPersonaJuridica.nombreArchivo}</label>
                                                                <br/>
                                                                <label>(El documento no ha sido cargado)</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            </Col>
                                            <Col  {...wrapperColDocs02}>
                                                <ActualizarArchivo key={index}
                                                    tipoArchivo={tipoArhivoPersonaJuridica} 
                                                    agregar={true}
                                                    callbackArchivoActualizado={(idNuevoArchivo) => { 
                                                                                            this.archivoPersonaJuridicaActualizado(
                                                                                                                                        idNuevoArchivo,
                                                                                                                                        null/*personaJuridicaArchivo*/,
                                                                                                                                        tipoArhivoPersonaJuridica
                                                                                                                                    ) 
                                                                                                    }}
                                                    deshabilitar={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.PERSONA_JURIDICA_DOCUMENTOS.ACCIONES.AGREGAR_DOCUMENTOS_PERSONA_JURIDICA.id)}
                                                />
                                            </Col>    
                                        </Row>
                                    )
                                    :
                                    (<></>)
                                }

                            </div>

                        </Spin>
                    </>
                )
                :
                (
                    (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                    (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                    :(componente === this.ENUM_COMPONENTE.DILIGENCIAR_DATOS_BASICOS) ?
                    (<label>Para habilitar esta sección debes diligenciar y guardar primero los datos de la entidad persona jurídica.</label>)
                    :((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:PJD]</label>) : (<></>))
                )
            }
          </div>
        );
    }
}
          
export default withRouter(withCookies(PersonaJuridicaDocumentos));