import React, { Component } from 'react';
import { Table, Spin } from 'antd';
import Constantes from '../utilidades/Constantes';

class PreguntasFrecuentes extends Component {

  constructor(props) {
    super(props);
    this.historyLocal = props.history;
  }

  state = {
    cargando: true,
  };

  // -----------------------------------------------------------------------
  // --- CARGA DATOS INICIALES ---------------------------------------------
  // -----------------------------------------------------------------------
  async cargarDatosIniciales() {
    this.setSumaState({ cargando: true });
    if ( Constantes.PREGUNTAS_FRECUENTES ) {
      for( let pregunta of Constantes.PREGUNTAS_FRECUENTES ){
        pregunta.expansible = true;
      }
      this.setSumaState({ cargando: false, preguntasFrecuentes:Constantes.PREGUNTAS_FRECUENTES });
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.cargarDatosIniciales();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setSumaState(valores) {
    if (this._isMounted) {
      this.setState(valores);
    }
  }

  render() {

    const { cargando, preguntasFrecuentes } = this.state;
    const columns = [
      {
        dataIndex: 'pregunta',
        key: 'pregunta',
        render: (text) => <div style={{textAlign:"justify"}}><span>{text}</span></div>,
      },
    ];

    return (
      <div className="contenido-principal">
        <div className="seccion-titulo">
          <div className="titulo-contenido">
            <span>PREGUNTAS FRECUENTES</span>
            <div className="separador-horizontal"></div>
          </div>
        </div>
        <Spin tip="Cargando..." size="large" spinning={cargando}>
          <Table
            className="table-suma td-font-bold"
            dataSource={preguntasFrecuentes}
            columns={columns}
            showHeader={false}
            expandable={{
              expandedRowRender: record => <p style={{ margin: 0 }}>{record.respuesta}</p>,
              rowExpandable: record => record.expansible === true,
            }}
            rowKey={"id"}
          />
        </Spin>
      </div>
    );
  }
}
export default PreguntasFrecuentes;