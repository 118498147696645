import axios from 'axios';
import Constantes from '../../utilidades/Constantes'
import Utilidades from '../../utilidades/Utilidades'
import { Modal } from 'antd';

class ServicioUsuarios {

    constructor( props ){ 
        const { cookies } = props;
        this.cookies = cookies;
    }

    // Método público
    autenticarUsuario( usuario, contrasena, callbackComplete, callbalkError ){
        let autenticacion ={
            usuario:usuario.toLowerCase(),
            contrasena:contrasena,
            servicio:"SUMA"
        }
        axios.post( Constantes.URL_API_SUMA + '/usuario/autenticar', autenticacion)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                if( res.data.codigo === Constantes.ENUMS_API.USUARIO.OPERACION_REALIZADA.id ){
                    this.cookies.set('sumajwt', res.data.access_token, { path: '/' })
                }
                callbackComplete( {res} );
            }else{
                console.log('USUARIO NO AUTENTICADO');
                callbalkError();    
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoSinConexion(err)){
                return;
            }
            console.error('[Error.ServicioUsuarios.autenticarUsuario]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    cerrarSesion ( callbackComplete, callbalkError ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.post( Constantes.URL_API_SUMA + '/usuario/cerrar-sesion',{},config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioUsuarios.cerrarSesion]',err);
            callbalkError( {err} );
        });
    }

    // Valida que el usuario se encuentre en sesión, por medio de una promesa
    usuarioEnSesion() {
        return new Promise(resolve => {
            this.obtenerUsuarioLogeado(
              ( dataAPI )    => { 
                    if( dataAPI !== null ){
                        resolve( dataAPI );
                    }else{
                        Modal.error({ content: "Contenido no disponible. [Cod:SU01]" });
                        resolve( null );
                    }
                },
              ( errorAPI )   => { 
                  Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SU02]" });
                  resolve( null );},
            );
        });
    }

    // Método privado (Retorna el usuario que está en sesión)
    obtenerUsuarioLogeado ( callbackComplete, callbalkError ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.get( Constantes.URL_API_EXTERNO_SUMA + '/usuario/',config)
        .then(res => {
            //console.log('obtenerUsuarioLogeado.res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioUsuarios.obtenerUsuarioLogeado]',err);
            callbalkError( {err} );
        });
    }

    // Método privado (Indica si la accion está habilitada)
    accionHabilitada ( idAccion, callbackComplete, callbalkError ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.get( Constantes.URL_API_SUMA + '/usuario/accion-habilitada/' + idAccion, config)
        .then(res => {
            //console.log('obtenerUsuarioLogeado.res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioUsuarios.accionHabilitada]',err);
            callbalkError( {err} );
        });
    }

    // Método privado Por medio de una promesa
    accionHabilitadaPromesa ( idAccion ) {
        return new Promise(resolve => {
            this.accionHabilitada(
                idAccion,
              ( dataAPI )    => { 
                    if( dataAPI !== null ){
                        resolve( dataAPI );
                    }else{
                        Modal.error({ content: "Contenido no disponible. [Cod:SU03]" });
                        resolve();
                    }
                },
              ( errorAPI )   => { 
                  Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:SU04]" });
                  resolve();},
            );
        });
    }

    // Método privado
    editarUsuarioActual ( callbackComplete, callbalkError, usuario ) {
        //console.log('usuEdit',usuario);
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.put( Constantes.URL_API_SUMA + '/usuario', usuario, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioUsuarios.editarUsuarioActual]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    cambiarContrasenaUsuarioActual ( callbackComplete, callbalkError, cambioContrasenaDto ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};
        axios.put( Constantes.URL_API_SUMA + '/usuario/cambiar-contrasena-usuario-actual', cambioContrasenaDto , config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                return;
            }
            console.error('[Error.ServicioUsuarios.cambiarContrasenaUsuarioActual]',err);
            callbalkError( {err} );
        });
    }

    // Método público
    usuarioNuevo ( tokenJWT, callbackComplete, callbalkError ) {
        const config= {};
        axios.get( Constantes.URL_API_SUMA + '/usuario/usuario-nuevo/' + tokenJWT, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoSinConexion(err)){
                return;
            }
            console.error('[Error.ServicioUsuarios.usuarioNuevo]',err);
            callbalkError( {err} );
        });
    }

    // Método público
    usuarioParaActivarNuevoSUMA ( tokenJWT, callbackComplete, callbalkError ) {
        const config= {};
        axios.get( Constantes.URL_API_SUMA + '/usuario/usuario-valido-activar-suma/' + tokenJWT, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoSinConexion(err)){
                return;
            }
            console.error('[Error.ServicioUsuarios.usuarioNuevo]',err);
            callbalkError( {err} );
        });
    }

    // Método público
    usuarioMigracion ( tokenJWT, callbackComplete, callbalkError ) {
        const config= {};
        axios.get( Constantes.URL_API_SUMA + '/usuario/usuario-migracion/' + tokenJWT, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoSinConexion(err)){
                return;
            }
            console.error('[Error.ServicioUsuarios.usuarioMigracion]',err);
            callbalkError( {err} );
        });
    }

    // Método público
    usuarioConCambioDeContrasena ( tokenJWT , callbackComplete, callbalkError ) {
        const config= {};
        axios.get( Constantes.URL_API_SUMA + '/usuario/usuario-cambio-contrasena/' + tokenJWT, config)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoSinConexion(err)){
                return;
            }
            console.error('[Error.ServicioUsuarios.usuarioConCambioDeContrasena]',err);
            callbalkError( {err} );
        });
    }

    // Método público
    asignarContrasena ( contrasena, token, callbackComplete, callbalkError ) {
        //const config= {};
        contrasena.token = token;
        axios.post( Constantes.URL_API_SUMA + '/usuario/contrasena/', contrasena)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoSinConexion(err)){
                return;
            }
            console.error('[Error.ServicioUsuarios.asignarContrasena]',err);
            callbalkError( {err} );
        });
    }

    // Método público
    cambioContrasena ( contrasena, token, callbackComplete, callbalkError ) {
        contrasena.token = token;
        axios.post( Constantes.URL_API_SUMA + '/usuario/cambio-contrasena/', contrasena)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoSinConexion(err)){
                return;
            }
            console.error('[Error.ServicioUsuarios.cambioContrasena]',err);
            callbalkError( {err} );
        });
    }

    // Método público
    recuperarContrasena ( correoElectronico, callbackComplete, callbalkError ) {
        //const config= {};
        axios.post( Constantes.URL_API_SUMA + '/usuario/recuperar-contrasena', correoElectronico)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoSinConexion(err)){
                return;
            }
            console.error('[Error.ServicioUsuarios.recuperarContrasena]',err);
            callbalkError( {err} );
        });
    }

    // Método público
    enviarCorreoActivacionCuenta ( correoElectronico, callbackComplete, callbalkError ) {
        //const config= {};
        axios.post( Constantes.URL_API_SUMA + '/usuario/enviar-correo-activacion-cuenta', correoElectronico)
        .then(res => {
            //console.log('res',res);
            if(res.status === 200){
                callbackComplete( res.data );
            }else{
                callbalkError();
            }
        }).catch( err => {
            if(Utilidades.servicioHTTPErrorControladoSinConexion(err)){
                return;
            }
            console.error('[Error.ServicioUsuarios.enviarCorreoActivacionCuenta]',err);
            callbalkError( {err} );
        });
    }

    // Método privado
    asignarCorreoElectronicoUsuario ( correoElectronicoDto ) {
        let token = this.cookies.get('sumajwt');
        const config= { headers:{ 'Authorization': `Bearer ${token}` }};

        return new Promise( resolve =>{
            axios.put( Constantes.URL_API_SUMA + '/usuario/correo-electrónico-usuario',correoElectronicoDto,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error asignar correo electrónico usuario. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioUsuarios.asignarCorreoElectronicoUsuario]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SU05]" }); 
                resolve();
            });
        });
    }

    // Método privado
    desbloquearUsuario ( tokenJWT ) {
        const config= {};
        return new Promise( resolve =>{
            axios.put( Constantes.URL_API_SUMA + '/usuario/desbloquear-usuario/'+ tokenJWT,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error desbloquear usuario. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioUsuarios.desbloquearUsuario]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SU06]" }); 
                resolve();
            });
        });
    }

    // Método privado
    cuentaValidaParaActivar ( tokenJWT ) {
        const config= {};
        return new Promise( resolve =>{
            axios.put( Constantes.URL_API_SUMA + '/usuario/cuenta-valida-para-activar/'+ tokenJWT,config)
            .then(res => {
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error validar cuenta para activar. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioUsuarios.cuentaValidaParaActivar]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SU07]" }); 
                resolve();
            });
        });
    }

    // Método público
    activarCuenta ( contrasena, token ) {
        //const config= {};
        contrasena.token = token;
        return new Promise( resolve =>{
            axios.post( Constantes.URL_API_SUMA + '/usuario/activar-cuenta/', contrasena)
            .then(res => {
                //console.log('res',res);
                if(res.status === 200){
                    resolve( res.data );
                    return;
                }else{
                    throw new Error("Error activar cuenta. " + res.status );
                }
            }).catch( err => {
                if(Utilidades.servicioHTTPErrorControladoGeneral(err)){
                    resolve();
                    return;
                }
                console.error('[Error.ServicioUsuarios.activarCuenta]',err);
                Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:SU08]" }); 
                resolve();
            });
        });
    }

}

export default ServicioUsuarios;