import React, { Component } from 'react';
import { Spin  } from 'antd';
import Constantes from '../utilidades/Constantes';

class QueEsSuma extends Component {

    state = {};

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        let contenidoVisual;
        if (Constantes.CONTENIDOS_VISUALES) {
            for (let cv of Constantes.CONTENIDOS_VISUALES) {
                if (cv.id === Constantes.ENUMS.CONTENIDO_VISUAL.QUE_ES_SUMA.id) {
                    contenidoVisual = cv;
                    break;
                }
            }
            this.setSumaState({ contenidoVisual });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    render() {

        const { contenidoVisual } = this.state;

        return (
            <div className="contenido-principal">

                {contenidoVisual ?
                    <>
                        <div className="seccion-titulo">
                            <div className="titulo-contenido">
                                <span style={{
                                    ...(contenidoVisual.tamanoFuente && {fontSize: contenidoVisual.tamanoFuente+'px'}),
                                }}>
                                    {contenidoVisual.titulo}
                                </span>
                                <div className="separador-horizontal"></div>
                            </div>
                        </div>
                        <div className="seccion-imagen-texto">
                            <div className="div-imagen"
                            style={{
                                backgroundImage:'url('+ (contenidoVisual.archivo ? contenidoVisual.archivo.urlPublica : '')+')'
                            }}></div>
                            <p className="texto">
                                {contenidoVisual.descripcion}
                            </p>
                        </div>
                    </>
                    :
                    <div className="contenedor-spin">
                        <Spin tip="Cargando..." size="large" />
                    </div>
                }


                
            </div>
        );
    }
}
export default QueEsSuma;