import React, { Component } from 'react';
import SumaContexto  from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { Button, Row, Col, Modal, Spin, Tooltip, Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import Constantes from '../utilidades/Constantes'
import ServicioDatosConstantes from '../servicios/ServicioDatosConstantes'
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import ServicioPais from '../servicios/ServicioPais'

class VerInformacionBasica extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE ={
        CARGANDO:1,
        VER:2,
        NO_DISPONIBLE:3
    }
    
    state = {
        cargando: true,
        usuario: null,
        componente: this.ENUM_COMPONENTE.CARGANDO,
    };

    constructor(props){
        super(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes( props );
        this.servicioUsuarios = new ServicioUsuarios ( props );
        this.servicioPais = new ServicioPais( props );
        this.callbackEditarInformacionBasica = props.callbackEditarInformacionBasica;
    }
   
    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales(){
        // Validando que el usuario es PERSONA JURIDICA
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        this.setSumaState({ componente:(usr !== null ? this.ENUM_COMPONENTE.VER : this.ENUM_COMPONENTE.NO_DISPONIBLE)});
        if( usr !== null ){
            this.setSumaState({ cargando: true });
            //console.log('usr',usr);
            let pais = await this.obtenerPais( usr.datoPersonal.ciudad.idPais );
            let departamento = await this.obtenerDepartamento( usr.datoPersonal.ciudad.idDepartamento );
            if( pais !== null && departamento !== null ){
                usr.pais = pais;
                usr.departamento = departamento;
                this.setSumaState({ cargando: false, usuario:usr, });
            }
        }
    }

    obtenerPais( idPais ) {
        return new Promise(resolve => {
            this.servicioPais.obtenerPais(
                idPais,
                ( respuestaApi ) => { 
                    resolve( respuestaApi.pais );
                },
                ( errorAPI )        => { 
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:MCVIB01]" });
                    resolve(null);
                }
            );
        });
    }

    obtenerDepartamento( idDepartamento ) {
        return new Promise(resolve => {
            this.servicioPais.obtenerDepartamento(
                idDepartamento,
                ( respuestaApi ) => { 
                    resolve( respuestaApi.departamento );
                },
                ( errorAPI )        => { 
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:MCVIB02]" });
                    resolve(null);
                }
            );
        });
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    render() {
      //const { usuario } = this.context;
      const { usuario, componente } = this.state;
      
      const wrapperCol = {
        xs: {span:21, offset: 1},  
        sm: {span:11, offset: 1},
      };

        return (
          <div>  
              {(componente === this.ENUM_COMPONENTE.VER) ?
                (
                    <>
                        <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>
                            <Row>
                                <Col>
                                    <h3 className="titulo inline-block">Usuario:</h3> &nbsp;&nbsp;&nbsp;
                                    <h3 className="inline-block">{usuario !== null ? usuario.datoPersonal.nombreCompleto : ""}</h3>
                                </Col>  
                            </Row>
                            <h3 className="sub-titulo-separador margen-bottom">Información básica</h3>
                            <div className="seccion-datos">
                                <Row className="fila">
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">Tipo de documento:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <span>{usuario !== null ? usuario.datoPersonal.tipoDocumento.tipoDocumento : ""}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">Número de documento:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <span>{usuario !== null ? usuario.datoPersonal.numeroDocumento : ""}</span>
                                            </Col>
                                        </Row>
                                    </Col>    
                                </Row>
                                <Row className="fila">
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">Lugar de expedición:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <span>{usuario !== null ? usuario.datoPersonal.lugarExpedicion : ""}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">Fecha de expedición:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <span>{usuario !== null ? usuario.datoPersonal.fechaExpedicionFormatoEspanol : ""}</span>
                                            </Col>
                                        </Row>
                                    </Col>    
                                </Row>
                                <Row className="fila">
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">Correo electrónico:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <span>{usuario !== null ? usuario.datoPersonal.correoElectronico : ""}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">Dirección residencia:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <span>{usuario !== null ? usuario.datoPersonal.direccion : ""}</span>
                                            </Col>
                                        </Row>
                                    </Col>    
                                </Row>
                                <Row className="fila">
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">Móvil:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <span>{usuario !== null ? usuario.datoPersonal.celular : ""}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">Teléfono:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <span>{usuario !== null ? usuario.datoPersonal.telefono : ""}</span>
                                            </Col>
                                        </Row>
                                    </Col>    
                                </Row>
                                <Row className="fila">
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">Rol:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <span>{usuario !== null ? usuario.rol.rol : ""}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">Estado:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <Tag color={
                                                        (usuario !== null && usuario.estadoUsuario.id === Constantes.ENUMS.ESTADO_USUARIO.ACTIVO.id)  
                                                        ? 
                                                            'green' 
                                                        : 
                                                            (usuario !== null && usuario.estadoUsuario.id === Constantes.ENUMS.ESTADO_USUARIO.INACTIVO.id 
                                                            ?
                                                                'volcano'
                                                            :
                                                                (usuario !== null && usuario.estadoUsuario.id === Constantes.ENUMS.ESTADO_USUARIO.CREADO.id
                                                                ?
                                                                    'geekblue'
                                                                :
                                                                    ''
                                                                )
                                                            )
                                                    } key={usuario !== null ? usuario.estadoUsuario.estadoUsuario : ""}>
                                                    {usuario !== null ? usuario.estadoUsuario.estadoUsuario.toUpperCase() : ""}
                                                </Tag>

                                                { usuario !== null && usuario.cambioContrasena 
                                                ? 
                                                    <Tooltip title="El usuario tiene pendiente un cambio de contraseña">
                                                        <Tag color="purple" key="Cambio Contraseña">CONTRASEÑA</Tag>
                                                    </Tooltip>
                                                : 
                                                    <></>
                                                }

                                            </Col>
                                        </Row>
                                    </Col>  
                                </Row>

                                <Row className="fila">
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">País:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <span>{usuario !== null ? usuario.pais : ""}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">Región:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <span>{usuario !== null ? usuario.departamento : ""}</span>
                                            </Col>
                                        </Row>
                                    </Col>    
                                </Row>

                                <Row className="fila">
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">Ciudad:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <span>{usuario !== null ? usuario.datoPersonal.ciudad.ciudad : ""}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col  {...wrapperCol}>
                                        <Row>
                                            <Col sm={12}>
                                                <label className="titulo-etiqueta">Dirección:</label>
                                            </Col>
                                            <Col sm={12}>
                                                <span>{usuario !== null ? usuario.datoPersonal.direccion : ""}</span>
                                            </Col>
                                        </Row>
                                    </Col>    
                                </Row>

                            </div>


                            <h3 className="sub-titulo-separador margen-bottom margen-top">Opciones</h3>
                            <div className="seccion-datos seccion-final">

                                {(usuario !== null) 
                                ?
                                (<>
                                    <Button className="boton-opcion" 
                                            icon={<EditOutlined style={{ color: '#c90a00' }}/>}
                                            onClick={(e) => {this.callbackEditarInformacionBasica(e,this.state.idUsuario)}}>
                                                Editar
                                    </Button>
                                </>)
                                : 
                                (<></>)
                                }
                            </div>

                        </Spin>
                    </>
                )
                :
                (
                    (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                    (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                    :((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:MCVIB]</label>) : (<></>))
                )
            }
          
          </div>
        );
    }
}
          
export default withRouter(withCookies(VerInformacionBasica));