import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter, Switch, Route } from "react-router-dom";
import { PlusOutlined, BankOutlined } from '@ant-design/icons';
import { Spin, Button, Modal } from 'antd';
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import ServicioAdministracion from '../servicios/ServicioAdministracion'
import { withCookies } from 'react-cookie';
import Constantes from '../utilidades/Constantes'
import Utilidades from '../utilidades/Utilidades';
import ListarEntidades from './ListarEntidades'
import VerEntidad from './VerEntidad'
import AgregarEditarEntidad from './AgregarEditarEntidad'
import AgregarEditarDependencia from './dependencia/AgregarEditarDependencia'
import VerDependencia from './dependencia/VerDependencia'
import SeleccionTipoOpcion from './dependencia/opcion/SeleccionTipoOpcion'
import AdministrarOpcionDependenciaSolicitud from './dependencia/opcion/AdministrarOpcionDependenciaSolicitud'
import AdministrarOpcionDependenciaSUMA from './dependencia/opcion/AdministrarOpcionDependenciaSUMA';
import AdministrarOpcionDependenciaRegistrarSolicitud from './dependencia/opcion/AdministrarOpcionDependenciaRegistrarSolicitud';
import AdministrarRolDependencia from './dependencia/roles/AdministrarRolDependencia'

class Entidades extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    PATHS = {
        ENTIDADES: '/entidades',
        AGREGAR_ENTIDAD: '/agregar-entidad',
        AGREGAR_DEPENDENCIA: '/agregar-dependencia',
        EDITAR_ENTIDAD: '/editar-entidad',
        EDITAR_DEPENDENCIA: '/editar-dependencia',
        ENTIDAD: '/entidad',
        ENTIDAD_DEPENDENCIAS: '/entidad-dependencias',
        ENTIDAD_DEPENDENCIA: '/entidad-dependencia',
        ENTIDAD_DEPENDENCIA_OPCIONES: '/dependencia-opciones',
        ENTIDAD_DEPENDENCIA_ROLES: '/dependencia-roles',
        ENTIDAD_DEPENDENCIA_ROL: '/dependencia-rol',
        ENTIDAD_DEPENDENCIA_APLICACION_CONECTADA: '/dependencia-aplicacion-conectada',
        SELECCION_TIPO_OPCION_DEPENDENCIA: '/seleccion-tipo-opcion-dependecia',
        OPCION_DEPENDENCIA_SOLICITUD: '/opcion-dependencia-solicitud',
        OPCION_DEPENDENCIA_OPCION_SUMA: '/opcion-dependencia-suma',
        OPCION_DEPENDENCIA_REGISTRAR_SOLICITUD: '/opcion-dependencia-registrar-solicitud',
        
    };

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        // Validando que el usuario esté en sesion
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr != null) {
            // Valida que tenga la accion habilitada de acceder 
            let habilitado = await this.servicioUsuarios.accionHabilitadaPromesa(Constantes.ENUMS_MODULOS.ENTIDAD.ACCIONES.ACCEDER_MODULO_ENTIDAD.id);
            if (habilitado && habilitado.resultado) {
                this.setState({ usuario: usr, componente: this.ENUM_COMPONENTE.VER });
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }

    clickVerEntidad = ( e, idEntidad ) => {
        this.historyLocal.push(this.PATHS.ENTIDAD + '?id=' + idEntidad );
    }

    clickVerDependencia = ( e, idDependencia ) => {
        this.historyLocal.push(this.PATHS.ENTIDAD_DEPENDENCIA + '?id=' + idDependencia );
    }

    clickAgregarEntidad = () => {
        this.historyLocal.push(this.PATHS.AGREGAR_ENTIDAD);        
    }

    clickAgregarDependencia = ( idEntidad ) => {
        this.historyLocal.push(this.PATHS.AGREGAR_DEPENDENCIA + '?idEnt=' + idEntidad);        
    }

    clickSeleccionTipoOpcionDependencia = ( idDependencia ) => {
        this.historyLocal.push(this.PATHS.SELECCION_TIPO_OPCION_DEPENDENCIA + '?id=' + idDependencia)
    }

    clickAdministrarOpcionDependencia = (tipoOpcion, idDependencia, idOpcDependencia) => {
        let query = '?' + ( idDependencia ? ('idDep=' + idDependencia) : ('id=' + idOpcDependencia));

        if( tipoOpcion === Constantes.ENUMS.TIPO_OPCION.SOLICITUD.id )
            this.historyLocal.push(this.PATHS.OPCION_DEPENDENCIA_SOLICITUD + query );
        if( tipoOpcion === Constantes.ENUMS.TIPO_OPCION.OPCION_SUMA.id )
            this.historyLocal.push(this.PATHS.OPCION_DEPENDENCIA_OPCION_SUMA + query );    
        if( tipoOpcion === Constantes.ENUMS.TIPO_OPCION.REGISTRAR_SOLICITUD.id )
            this.historyLocal.push(this.PATHS.OPCION_DEPENDENCIA_REGISTRAR_SOLICITUD + query );    
    }

    clickAdministrarRol = ( idDependencia, idRol) => {
        let query = '?' + ( idDependencia ? ('idDep=' + idDependencia) : ('id=' + idRol));
        this.historyLocal.push(this.PATHS.ENTIDAD_DEPENDENCIA_ROL + query );    
    }

    clickEditarEntidad = ( e, idEntidad ) => {
        this.historyLocal.push(this.PATHS.EDITAR_ENTIDAD + '?id=' + idEntidad);
    }

    clickEditarDependencia = ( e, idDependencia ) => {
        this.historyLocal.push(this.PATHS.EDITAR_DEPENDENCIA + '?id=' + idDependencia);
    }

    entidadGuardada(nuevoEntidad, idEntidad, cancelar) {
        if (typeof cancelar === 'boolean' && cancelar) {
            if ( nuevoEntidad )
                this.historyLocal.push(this.PATHS.ENTIDADES);
            else
                this.historyLocal.push(this.PATHS.ENTIDAD + '?id=' + idEntidad);
            return;
        }
        Modal.success({ content: "La entidad ha sido " + ( nuevoEntidad ? "creada." : "actualizada") });
        this.historyLocal.push(this.PATHS.ENTIDAD + '?id=' + idEntidad);
    }

    dependenciaGuardada(nuevoEntidad, idDependencia, cancelar, idEntidad ) {
        if (typeof cancelar === 'boolean' && cancelar) {
            if ( nuevoEntidad )
                this.historyLocal.push( this.PATHS.ENTIDAD_DEPENDENCIAS + '?id=' + idEntidad );
            else
                this.historyLocal.push(this.PATHS.ENTIDAD_DEPENDENCIA + '?id=' + idDependencia);
            return;
        }
        Modal.success({ content: "La dependencia ha sido " + ( nuevoEntidad ? "creada." : "actualizada") });
        this.historyLocal.push(this.PATHS.ENTIDAD_DEPENDENCIA + '?id=' + idDependencia);
    }

    validarEliminarOpcionDependencia = async ( idOpcionDependencia, idDependencia, callback ) => {
        let respuestaApi = await this.servicioAdministracion.validarEliminarOpcionDependencia( idOpcionDependencia );
        if(respuestaApi){
            switch(respuestaApi.codigo){
                case Constantes.ENUMS_API.ADMINISTRACION.DATO_VALIDO_PARA_ELIMINAR.id:
                    Modal.confirm({
                        content:respuestaApi.mensaje,
                        cancelText: 'Cancelar',
                        okText: 'Confirmar',
                        onOk:( async(close) =>{
                            respuestaApi = await this.servicioAdministracion.eliminarOpcionDependencia( idOpcionDependencia );
                            switch(respuestaApi.codigo){
                                case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_REALIZADA.id:
                                    Modal.success({ content: respuestaApi.mensaje});
                                    this.historyLocal.push(this.PATHS.ENTIDAD_DEPENDENCIA_OPCIONES + '?id=' + idDependencia);
                                    break;
                                case Constantes.ENUMS_API.ADMINISTRACION.OPERACION_NO_REALIZADA.id:
                                    Modal.error({ content: respuestaApi.mensaje});
                                    break;
                                default:
                                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:ENT01]" });
                                    break;
                            }
                            close();
                        })
                    });
                    break;
                case Constantes.ENUMS_API.ADMINISTRACION.DATO_NO_VALIDO_PARA_ELIMINAR.id:
                    Modal.error({content:respuestaApi.mensaje});
                    break;
                default:
                    Modal.error({ content: "Error de conexión. Por favor intenta mas tarde. [Cod:ENT02]" });
                    break;    
            }
        }
        callback();
    }

    render() {

        const { componente, usuario } = this.state;

        return (
            <>

                <div className="seccion-principal contenedor-completo seccion-flex ">

                    <div className="division-principal">
                        <label>Entidades</label>
                    </div>
                    <div className="division-contenido seccion-flex-grow-1 padding-principal bkg-white contenido-usuarios">

                        {(componente === this.ENUM_COMPONENTE.VER) ?
                            (
                                <>
                                    <Switch>
                                        <Route path={this.PATHS.ENTIDADES}>
                                            <div className="titulo-seccion">
                                                <label><BankOutlined /> ENTIDADES</label>
                                                <div className="separador-horizontal-completo margen-bottom-5"></div>
                                            </div>
                                            <div className="div-lista-botones margen-bottom-5">
                                                {Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.ENTIDAD.ACCIONES.AGREGAR_ENTIDAD.id) ?
                                                    <Button type="primary" icon={<PlusOutlined />} onClick={this.clickAgregarEntidad} >
                                                        Agregar entidad
                                                    </Button> : null
                                                }
                                            </div>
                                            <ListarEntidades callbackVerEntidad={this.clickVerEntidad} />
                                        </Route>

                                        <Route path={this.PATHS.AGREGAR_ENTIDAD}>
                                            <AgregarEditarEntidad   paths={this.PATHS}
                                                                    onSuccess={(idEntidad, cancelar) => { this.entidadGuardada(true, idEntidad, cancelar) }}/>
                                        </Route>
                                        <Route path={this.PATHS.EDITAR_ENTIDAD}>
                                            <AgregarEditarEntidad   paths={this.PATHS}
                                                                    onSuccess={(idEntidad, cancelar) => { this.entidadGuardada(false, idEntidad, cancelar) }}/>
                                        </Route>
                                        
                                        <Route path={[  this.PATHS.ENTIDAD,
                                                        this.PATHS.ENTIDAD_DEPENDENCIAS]}>
                                            <VerEntidad callbackEditarEntidad={ this.clickEditarEntidad }
                                                        callbackAgregarDependencia={ this.clickAgregarDependencia } 
                                                        callbackVerDependencia={ this.clickVerDependencia }/>
                                        </Route>            

                                        <Route path={this.PATHS.AGREGAR_DEPENDENCIA}>
                                            <AgregarEditarDependencia onSuccess={(idDependencia, idEntidad, cancelar) => { this.dependenciaGuardada(true, idDependencia, cancelar, idEntidad) }}/>
                                        </Route>

                                        <Route path={this.PATHS.EDITAR_DEPENDENCIA}>
                                            <AgregarEditarDependencia onSuccess={(idDependencia, idEntidad, cancelar) => { this.dependenciaGuardada(false, idDependencia, cancelar, idEntidad) }}/>
                                        </Route>    

                                        <Route path={[  this.PATHS.ENTIDAD_DEPENDENCIA,
                                                        this.PATHS.ENTIDAD_DEPENDENCIA_OPCIONES,
                                                        this.PATHS.ENTIDAD_DEPENDENCIA_ROLES,
                                                        this.PATHS.ENTIDAD_DEPENDENCIA_APLICACION_CONECTADA]}>
                                            <VerDependencia callbackEditarDependencia={ this.clickEditarDependencia }
                                                            callbackSeleccionTipoOpcionDependencia={ this.clickSeleccionTipoOpcionDependencia }
                                                            callbackAdministrarOpcionDependencia={this.clickAdministrarOpcionDependencia}
                                                            callbackAdministrarRol={this.clickAdministrarRol}/>
                                        </Route>

                                        <Route path={this.PATHS.SELECCION_TIPO_OPCION_DEPENDENCIA}>
                                            <SeleccionTipoOpcion callbackAdministrarOpcionDependencia={this.clickAdministrarOpcionDependencia}/>
                                        </Route>

                                        <Route path={this.PATHS.OPCION_DEPENDENCIA_SOLICITUD}>
                                            <AdministrarOpcionDependenciaSolicitud callbackValidarEliminarOpcionDependencia={this.validarEliminarOpcionDependencia}/>
                                        </Route>

                                        <Route path={this.PATHS.OPCION_DEPENDENCIA_OPCION_SUMA}>
                                            <AdministrarOpcionDependenciaSUMA callbackValidarEliminarOpcionDependencia={this.validarEliminarOpcionDependencia} />
                                        </Route>
                                        
                                        <Route path={this.PATHS.OPCION_DEPENDENCIA_REGISTRAR_SOLICITUD}>
                                            <AdministrarOpcionDependenciaRegistrarSolicitud callbackValidarEliminarOpcionDependencia={this.validarEliminarOpcionDependencia} />
                                        </Route>

                                        <Route path={this.PATHS.ENTIDAD_DEPENDENCIA_ROL}>
                                            <AdministrarRolDependencia />
                                        </Route>

                                    </Switch>

                                    

                                </>
                            )
                            :
                            (
                                (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                                    (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                                    : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:ENT]</label>) : (<></>))
                            )
                        }
                    </div>
                </div>
            </>
        );

    }

}

export default withRouter(withCookies(Entidades));