import React, { Component } from 'react';
import SumaContexto from '../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Row, Col, Tag, Spin, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import ServicioDatosConstantes from '../servicios/ServicioDatosConstantes'
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import ServicioPais from '../servicios/ServicioPais'
import ServicioAdministracion from '../servicios/ServicioAdministracion'
import Constantes from '../utilidades/Constantes'
import Utilidades from '../utilidades/Utilidades'
import queryString from 'query-string';
import { withCookies } from 'react-cookie';


class DatosEntidad extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        cargando: true,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        entidad: undefined,
        usuario: undefined,
    };


    constructor(props) {
        super(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioPais = new ServicioPais(props);
        this.servicioAdministracion = new ServicioAdministracion(props);
        this.callbackEditarEntidad = props.callbackEditarEntidad;
    }


    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        let idEntidad = undefined;

        if (urlparametros.id !== undefined && !isNaN(parseInt(urlparametros.id))) {
            idEntidad = parseInt(urlparametros.id);
        }
        this.setSumaState({ idEntidad: idEntidad });
        
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr !== null) {
            this.setSumaState({ usuario: usr, componente: this.ENUM_COMPONENTE.VER, cargando: true });
            // Valida que tenga la accion habilitada VER
            let habilitado = await this.servicioUsuarios.accionHabilitadaPromesa(Constantes.ENUMS_MODULOS.ENTIDAD.ACCIONES.VER_ENTIDAD.id);
            if (habilitado && habilitado.resultado) {
                let entidad = await this.servicioAdministracion.obtenerEntidad( idEntidad );
                if ( entidad ) {
                    this.setSumaState({ cargando: false, usuario: usr, entidad: entidad });
                }
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }

    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }


    render() {
        const { componente, usuario, entidad } = this.state;

        const wrapperCol = {
            xs: { span: 21, offset: 1 },
            sm: { span: 11, offset: 1 },
        };

        const wrapperCol02 = {
            xs: { span: 22, offset: 1 },
        };

        const colLabel = { sm: { span: 7 } };
        const colTexto = { sm: { span: 17 } };

        

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>

                                <h3 className="sub-titulo-separador margen-bottom ">Entidad</h3>
                                <div className="seccion-datos">
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col {...colLabel}>
                                                    <label className="titulo-etiqueta">Razón social:</label>
                                                </Col>
                                                <Col {...colTexto}>
                                                    <span>{entidad ? entidad.razonSocial : ''}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col {...colLabel}>
                                                    <label className="titulo-etiqueta">Id entidad:</label>
                                                </Col>
                                                <Col {...colTexto}>
                                                    <span>{entidad ? entidad.id : ''}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col {...colLabel}>
                                                    <label className="titulo-etiqueta">Tipo sector:</label>
                                                </Col>
                                                <Col {...colTexto}>
                                                    <span>{entidad && entidad.tipoSector ? entidad.tipoSector.tipoSector : ''}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col {...colLabel}>
                                                    <label className="titulo-etiqueta">Id tipo sector:</label>
                                                </Col>
                                                <Col {...colTexto}>
                                                    <span>{entidad && entidad.tipoSector ? entidad.tipoSector.id : ''}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col {...colLabel}>
                                                    <label className="titulo-etiqueta">Sigla:</label>
                                                </Col>
                                                <Col {...colTexto}>
                                                    <span>{entidad ? entidad.sigla : ''}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col {...colLabel}>
                                                    <label className="titulo-etiqueta">Nit:</label>
                                                </Col>
                                                <Col {...colTexto}>
                                                    <span>{entidad ? (entidad.nit + ' - ' + entidad.digitoVerificacion) : ''}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col {...colLabel}>
                                                    <label className="titulo-etiqueta">Ciudad:</label>
                                                </Col>
                                                <Col {...colTexto}>
                                                    <span>{entidad && entidad.ciudad ? entidad.ciudad.ciudad : ''}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col {...colLabel}>
                                                    <label className="titulo-etiqueta">Dirección entidad:</label>
                                                </Col>
                                                <Col {...colTexto}>
                                                    <span>{entidad ? entidad.direccion : ''}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col {...colLabel}>
                                                    <label className="titulo-etiqueta">Teléfono:</label>
                                                </Col>
                                                <Col {...colTexto}>
                                                    <span>{entidad ? entidad.telefono : ''}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col {...colLabel}>
                                                    <label className="titulo-etiqueta">Móvil:</label>
                                                </Col>
                                                <Col {...colTexto}>
                                                    <span>{entidad ? entidad.celular : ''}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col {...colLabel}>
                                                    <label className="titulo-etiqueta">Correo electrónico:</label>
                                                </Col>
                                                <Col {...colTexto}>
                                                    <span>{entidad ? entidad.correoElectronico : ''}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col {...colLabel}>
                                                    <label className="titulo-etiqueta">Estado:</label>
                                                </Col>
                                                <Col {...colTexto}>
                                                    <Tag color={
                                                        (entidad && entidad.estadoEntidad.id === Constantes.ENUMS.ESTADO_ENTIDAD.ACTIVO.id)
                                                            ?
                                                            'green'
                                                            :
                                                            (entidad && entidad.estadoEntidad.id === Constantes.ENUMS.ESTADO_ENTIDAD.INACTIVO.id
                                                                ?
                                                                'volcano'
                                                                :

                                                                ''

                                                            )
                                                    } key={entidad ? entidad.estadoEntidad.estadoEntidad : ""}>
                                                        {entidad ? entidad.estadoEntidad.estadoEntidad.toUpperCase() : ""}
                                                    </Tag>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col {...colLabel}>
                                                    <label className="titulo-etiqueta">Fecha modificación:</label>
                                                </Col>
                                                <Col {...colTexto}>
                                                    <span>{entidad ? entidad.fechaModificoFormato : ''}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row className="fila">
                                        <Col  {...wrapperCol}>
                                            <Row>
                                                <Col {...colLabel}>
                                                    <label className="titulo-etiqueta">Descripción:</label>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="row_col_span_texto">
                                        <Col {...wrapperCol02}>
                                            {entidad ? Utilidades.saltoDeLinea(entidad.descripcion) : ''}
                                        </Col>
                                    </Row>
                                    <br/>
                                </div>

                                <h3 className="sub-titulo-separador margen-bottom margen-top">Opciones</h3>
                                <div className="seccion-datos seccion-final">
                                    {(entidad)
                                        ?
                                        (<>
                                            {Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.ENTIDAD.ACCIONES.EDITAR_ENTIDAD.id) ?
                                                <Button className="boton-opcion"
                                                    icon={<EditOutlined style={{ color: '#c90a00' }} />}
                                                    onClick={(e) => { this.callbackEditarEntidad(e, this.state.idEntidad) }}>
                                                    Editar
                                                </Button>
                                            :<></>}
                                        </>)
                                        :
                                        (<></>)
                                    }
                                </div>

                            </Spin>

                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:DATENT]</label>) : (<></>))
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(DatosEntidad));