import React, { Component } from 'react';
import { Carousel, Button, Spin  } from 'antd';
import Constantes from '../utilidades/Constantes';
import Validadores from '../utilidades/Validadores';

import logo_suma_circular from '../recursos/imagenes/logo_suma+_circular.webp'
import logo_un_lugar_como_el_hogar from '../recursos/imagenes/logo_un_lugar_como_el_hogar.webp'

class Carrusel extends Component {

    state = {}

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        let contenidoVisual01,contenidoVisual02,contenidoVisual03,contenidoVisual04;
        if( Constantes.CONTENIDOS_VISUALES ){
            for( let contenidoVisual of Constantes.CONTENIDOS_VISUALES ){
                if( contenidoVisual.id === Constantes.ENUMS.CONTENIDO_VISUAL.SLIDER01.id ){
                    contenidoVisual01 = contenidoVisual;
                }
                if( contenidoVisual.id === Constantes.ENUMS.CONTENIDO_VISUAL.SLIDER02.id ){
                    contenidoVisual02 = contenidoVisual;
                }
                if( contenidoVisual.id === Constantes.ENUMS.CONTENIDO_VISUAL.SLIDER03.id ){
                    contenidoVisual03 = contenidoVisual;
                }
                if( contenidoVisual.id === Constantes.ENUMS.CONTENIDO_VISUAL.SLIDER04.id ){
                    contenidoVisual04 = contenidoVisual;
                }
            }
            this.setSumaState({ contenidosVisuales:Constantes.CONTENIDOS_VISUALES, contenidoVisual01, contenidoVisual02, contenidoVisual03, contenidoVisual04 });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    render() {

        const { contenidoVisual01, contenidoVisual02,contenidoVisual03,contenidoVisual04 } = this.state;

        function onChange(a, b, c) {
            //console.log(a, b, c);
         }
        return (
        <Carousel afterChange={onChange} className="carrusel" autoplay>

            {/* SLIDER 01 */}
            { !contenidoVisual01 || (contenidoVisual01 && contenidoVisual01.activo) ?
                <div>
                    { contenidoVisual01 ?
                        <div className="pagina-carrusel"
                            style={{
                                backgroundImage:'url('+ (contenidoVisual01.archivo ? contenidoVisual01.archivo.urlPublica : '')+')'
                            }}
                        >
                            <div className='logos'>
                                <img src={logo_suma_circular} className="logo_suma_circular" alt="SUMA" />
                                <img src={logo_un_lugar_como_el_hogar} className="logo_un_lugar_como_el_hogar" alt="SUMA" />
                            </div>

                            {Validadores.stringValido(contenidoVisual01.titulo) ?
                                <>
                                    <span style={{
                                        ...(contenidoVisual01.tamanoFuente && {fontSize: contenidoVisual01.tamanoFuente+'px'}),
                                        ...(contenidoVisual01.tamanoFuente && {lineHeight: contenidoVisual01.tamanoFuente+'px'}),
                                        ...(contenidoVisual01.color && {color: contenidoVisual01.color }),
                                    }}>
                                        {contenidoVisual01.titulo}
                                    </span>
                                    <div className="separador-horizontal" style={{ 
                                        ...(contenidoVisual01.color && {backgroundColor: contenidoVisual01.color }),
                                    }}></div>
                                </> : null }

                            {Validadores.stringValido(contenidoVisual01.url) ?
                                <Button type="primary" onClick={() => { window.open(contenidoVisual01.url, '_blank'); }}>CONOCE MÁS</Button>
                                : null }
                        </div>
                        :
                        <div className="contenedor-spin">
                            <Spin tip="Cargando..." size="large" />
                        </div>
                    }
                </div> : null }

            {/* SLIDER 02 */}
            { !contenidoVisual02 || (contenidoVisual02 && contenidoVisual02.activo) ?
                <div>
                    { contenidoVisual02 ?
                        <div className="pagina-carrusel"
                            style={{
                                backgroundImage:'url('+ (contenidoVisual02.archivo ? contenidoVisual02.archivo.urlPublica : '')+')'
                            }}
                        >
                            <div className='logos'>
                                <img src={logo_suma_circular} className="logo_suma_circular" alt="SUMA" />
                                <img src={logo_un_lugar_como_el_hogar} className="logo_un_lugar_como_el_hogar" alt="SUMA" />
                            </div>

                            {Validadores.stringValido(contenidoVisual02.titulo) ?
                                <>
                                    <span style={{
                                        ...(contenidoVisual02.tamanoFuente && {fontSize: contenidoVisual02.tamanoFuente+'px'}),
                                        ...(contenidoVisual02.tamanoFuente && {lineHeight: contenidoVisual02.tamanoFuente+'px'}),
                                        ...(contenidoVisual02.color && {color: contenidoVisual02.color }),
                                    }}>
                                        {contenidoVisual02.titulo}
                                    </span>
                                    <div className="separador-horizontal" style={{ 
                                        ...(contenidoVisual02.color && {backgroundColor: contenidoVisual02.color }),
                                    }}></div>
                                </> : null }

                            {Validadores.stringValido(contenidoVisual02.url) ?
                                <Button type="primary" onClick={() => { window.open(contenidoVisual02.url, '_blank'); }}>CONOCE MÁS</Button>
                                : null }
                        </div>
                        :
                        <div className="contenedor-spin">
                            <Spin tip="Cargando..." size="large" />
                        </div>
                    }
                </div> : null }

            {/* SLIDER 03 */}
            { !contenidoVisual03 || (contenidoVisual03 && contenidoVisual03.activo) ?
                <div>
                    { contenidoVisual03 ?
                        <div className="pagina-carrusel"
                            style={{
                                backgroundImage:'url('+ (contenidoVisual03.archivo ? contenidoVisual03.archivo.urlPublica : '')+')'
                            }}
                        >
                            <div className='logos'>
                                <img src={logo_suma_circular} className="logo_suma_circular" alt="SUMA" />
                                <img src={logo_un_lugar_como_el_hogar} className="logo_un_lugar_como_el_hogar" alt="SUMA" />
                            </div>

                            {Validadores.stringValido(contenidoVisual03.titulo) ?
                                <>
                                    <span style={{
                                        ...(contenidoVisual03.tamanoFuente && {fontSize: contenidoVisual03.tamanoFuente+'px'}),
                                        ...(contenidoVisual03.tamanoFuente && {lineHeight: contenidoVisual03.tamanoFuente+'px'}),
                                        ...(contenidoVisual03.color && {color: contenidoVisual03.color }),
                                    }}>
                                        {contenidoVisual03.titulo}
                                    </span>
                                    <div className="separador-horizontal" style={{ 
                                        ...(contenidoVisual03.color && {backgroundColor: contenidoVisual03.color }),
                                    }}></div>
                                </> : null }

                            {Validadores.stringValido(contenidoVisual03.url) ?
                                <Button type="primary" onClick={() => { window.open(contenidoVisual03.url, '_blank'); }}>CONOCE MÁS</Button>
                                : null }
                        </div>
                        :
                        <div className="contenedor-spin">
                            <Spin tip="Cargando..." size="large" />
                        </div>
                    }
                </div> : null }

            {/* SLIDER 04 */}
            { !contenidoVisual04 || (contenidoVisual04 && contenidoVisual04.activo) ?
                <div>
                    { contenidoVisual04 ?
                        <div className="pagina-carrusel"
                            style={{
                                backgroundImage:'url('+ (contenidoVisual04.archivo ? contenidoVisual04.archivo.urlPublica : '')+')'
                            }}
                        >
                            <div className='logos'>
                                <img src={logo_suma_circular} className="logo_suma_circular" alt="SUMA" />
                                <img src={logo_un_lugar_como_el_hogar} className="logo_un_lugar_como_el_hogar" alt="SUMA" />
                            </div>

                            {Validadores.stringValido(contenidoVisual04.titulo) ?
                                <>
                                    <span style={{
                                        ...(contenidoVisual04.tamanoFuente && {fontSize: contenidoVisual04.tamanoFuente+'px'}),
                                        ...(contenidoVisual04.tamanoFuente && {lineHeight: contenidoVisual04.tamanoFuente+'px'}),
                                        ...(contenidoVisual04.color && {color: contenidoVisual04.color }),
                                    }}>
                                        {contenidoVisual04.titulo}
                                    </span>
                                    <div className="separador-horizontal" style={{ 
                                        ...(contenidoVisual04.color && {backgroundColor: contenidoVisual04.color }),
                                    }}></div>
                                </> : null }

                            {Validadores.stringValido(contenidoVisual03.url) ?
                                <Button type="primary" onClick={() => { window.open(contenidoVisual04.url, '_blank'); }}>CONOCE MÁS</Button>
                                : null }
                        </div>
                        :
                        <div className="contenedor-spin">
                            <Spin tip="Cargando..." size="large" />
                        </div>
                    }
                </div> : null }

        </Carousel>
    )}
}
export default Carrusel;








