import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { UserOutlined } from '@ant-design/icons';
import { Spin, Modal, Popover } from 'antd';
import ServicioUsuarios from '../servicios/usuarios/ServicioUsuarios'
import ServicioSolicitud from '../servicios/ServicioSolicitud'
import { withCookies } from 'react-cookie';
import Utilidades from '../utilidades/Utilidades'
import Constantes from '../utilidades/Constantes'

/*const Item = ({ iconString }) => {
    const Imagen = import(`./${iconString}.svg`).then(
      module => module.default,
    );
    return (
      <div>
        <Imagen height="60px" width="60px" />
      </div>
    );
  };

  

  const Icon = ({ name, ...rest }) => {
    const ImportedIconRef = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
  
    React.useEffect(() => {
      setLoading(true);
      const importIcon = async () => {
        try {
            console.log("jaja",jaja);

            const imp = await import(`./${name}.svg`);
            console.log("imp",imp);


            const { default: namedImport } = await import(`./${name}.svg`)
            console.log("namedImport",namedImport);
            
            ImportedIconRef.current = (jaja).ReactComponent;

        } catch (err) {
          // Your own error handling logic, throwing error for the sake of
          // simplicity
          throw err;
        } finally {
          setLoading(false);
        }
      };
      importIcon();
    }, [name]);
  
    if (!loading && ImportedIconRef.current) {
      const { current: ImportedIcon } = ImportedIconRef;
      return <ImportedIcon {...rest} />;
    }
  
    return null;
  };*/



class RegistrarSolicitud extends Component {

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3,
        CONDICIONAL: 4,
    }

    state = {
        componente: this.ENUM_COMPONENTE.CARGANDO,
        usuario: null,
        tiposSolicitud: null,
        registrarSolicitudHabilitado: null,
    };

    constructor(props) {
        super(props);
        this.historyLocal = props.history;
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioSolicitud = new ServicioSolicitud(props);
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
        Utilidades.desbloquearPantalla();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }

    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {
        // Validando que el usuario es PERSONA NATURAL
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr != null && Utilidades.accionPermitida(usr, Constantes.ENUMS_MODULOS.REGISTRAR_SOLICITUD.ACCIONES.ACCEDER_MODULO_REGISTRAR_SOLICITUD.id)) {
            let registrarSolicitudHabilitado = await this.servicioUsuarios.accionHabilitadaPromesa(Constantes.ENUMS_MODULOS.REGISTRAR_SOLICITUD.ACCIONES.ACCEDER_MODULO_REGISTRAR_SOLICITUD.id);
            if (registrarSolicitudHabilitado && registrarSolicitudHabilitado.resultado ) {
                this.setSumaState({ usuario: usr, componente: this.ENUM_COMPONENTE.VER });
                let tiposSolicitud = await this.obtenerTiposDeSolicitud();
                //console.log('tiposSolicitud',tiposSolicitud);
                this.setSumaState({ tiposSolicitud: tiposSolicitud });
            } else {
                this.setSumaState({ componente: this.ENUM_COMPONENTE.CONDICIONAL, registrarSolicitudHabilitado: registrarSolicitudHabilitado });
            }
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }
    }

    obtenerTiposDeSolicitud() {
        return new Promise(resolve => {
            this.servicioSolicitud.obtenerTipoDeSolicitudes(
                (dataAPI) => {
                    resolve(dataAPI);
                },
                (errorAPI) => {
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:RS01]" });
                    resolve(null);
                },
            );
        });
    };

    obtenerAccesoRegistroSolicitud(idTipoSolicitud) {
        return new Promise(resolve => {
            this.servicioSolicitud.obtenerAccesoRegistroSolicitud(
                (respuestaApi) => {
                    switch (respuestaApi.codigo) {
                        case Constantes.ENUMS_API.SOLICITUDES.DATOS_RETORNADOS.id:
                            resolve(respuestaApi.resultado);
                            break;
                        case Constantes.ENUMS_API.SOLICITUDES.OPERACION_NO_REALIZADA.id:
                            Modal.warn({ className:'modal-suma', title: 'Atención', content: respuestaApi.mensaje });
                            break;
                        default:
                            Modal.error({ title: 'Error', content: `Error del sistema, por favor intenta más tarde. [Cod:RS03]. ${respuestaApi.mensaje}` });
                            resolve(null);
                            break;
                    }
                },
                (errorAPI) => {
                    Modal.error({ content: "Error del sistema, por favor intenta más tarde. [Cod:RS02]" });
                    resolve(null);
                },
                idTipoSolicitud
            );
        });
    };

    async onClickAccederRegistroSolicitud(idTipoSolicitud) {
        let enlaceAcceso = await this.obtenerAccesoRegistroSolicitud(idTipoSolicitud);
        if (enlaceAcceso != null) {
            Utilidades.bloquearPantalla();
            window.location = enlaceAcceso;
        }
    }


    render() {

        const { componente, tiposSolicitud, registrarSolicitudHabilitado } = this.state;

        return (
            <div className="seccion-principal contenedor-completo seccion-flex ">
                <div className="division-principal">
                    <label>Registrar solicitud</label>
                </div>
                <div className="division-contenido seccion-flex-grow-1 padding-principal bkg-white">
                    {(componente === this.ENUM_COMPONENTE.VER) ?
                        (
                            <>
                                <div className="titulo-seccion">
                                    <label> <UserOutlined /> REGISTRAR SOLICITUD</label>
                                    <div className="separador-horizontal-completo"></div>
                                </div>
                                <div>
                                    <span>Por favor seleccione el tipo de actividad que desea realizar</span>
                                    <div className="tipos-de-solicitudes">

                                        {
                                            (tiposSolicitud == null) ? <></> :
                                                Object.keys(tiposSolicitud).map((oneKey, i) => {
                                                    return (

                                                        tiposSolicitud[oneKey].activo ?
                                                            <div key={i} className={"tipo-solicitud " + (tiposSolicitud[oneKey].activo ? '' : 'disabled')}>

                                                                {
                                                                    tiposSolicitud[oneKey].activo 
                                                                    ?
                                                                        <Popover    title={tiposSolicitud[oneKey].solicitud} 
                                                                                    content={
                                                                                        <div className="popover-tipo-solicitud">
                                                                                            <p>{tiposSolicitud[oneKey].descripcion}</p>
                                                                                            <label><strong>ENTIDAD:</strong>&nbsp;</label><span>{tiposSolicitud[oneKey].dependencia.entidad.razonSocial}</span>
                                                                                            <br/>
                                                                                            <label><strong>DEPENDENCIA:</strong>&nbsp;</label><span>{tiposSolicitud[oneKey].dependencia.dependencia}</span>
                                                                                        </div>
                                                                                    }
                                                                        >
                                                                            <button onClick={() => { this.onClickAccederRegistroSolicitud(tiposSolicitud[oneKey].id) }}>
                                                                                <img src={`../imagenes/iconos/tipo-solicitud/${tiposSolicitud[oneKey].icono}`} width="60" height="60" alt="" />
                                                                                <span className="separador-vertical"></span>
                                                                                <label>{tiposSolicitud[oneKey].solicitud}</label>
                                                                            </button>
                                                                        </Popover>
                                                                    :
                                                                        <button disabled={true}>
                                                                            <img src={`../imagenes/iconos/tipo-solicitud/${tiposSolicitud[oneKey].icono}`} width="60" height="60" alt="" />
                                                                            <span className="separador-vertical"></span>
                                                                            <label>{tiposSolicitud[oneKey].solicitud}</label>
                                                                        </button>
                                                            }
                                                        </div> : null
                                                    )
                                                })
                                        }


                                    </div>
                                </div>
                            </>
                        )
                        :
                        (
                            (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                                (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                                : (
                                    (componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ?
                                        (<label>Contenido no disponible. [Cod:RS]</label>)
                                        : (
                                            (componente === this.ENUM_COMPONENTE.CONDICIONAL) ?
                                                (
                                                    Utilidades.saltoDeLinea(registrarSolicitudHabilitado.mensaje)
                                                )
                                                : (<></>)
                                        )
                                )
                        )
                    }


                </div>
            </div>
        );
    }
}

export default withRouter(withCookies(RegistrarSolicitud));
