import React from 'react';
import ReporteSolicitudes from './ReporteSolicitudes';
import ReporteDirecciones from './ReporteDirecciones';

export const ReporteSolicitudesDetallado = (props) => {
    return (
        <ReporteSolicitudes {...props} detallado={true}/>
    );
};

export const ReporteDireccionesDetallado = (props) => {
    return (
        <ReporteDirecciones {...props} detallado={true}/>
    );
};