import React, { Component } from 'react';
import SumaContexto from '../../../contexto/SumaContexto';
import { withRouter } from "react-router-dom";
import { Spin, Tooltip, Button, Table } from 'antd';
import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import ServicioDatosConstantes from '../../../servicios/ServicioDatosConstantes'
import ServicioUsuarios from '../../../servicios/usuarios/ServicioUsuarios'
import ServicioEntidad from '../../../servicios/ServicioEntidad'
import ServicioSolicitud from '../../../servicios/ServicioSolicitud';
import Constantes from '../../../utilidades/Constantes'
import Utilidades from '../../../utilidades/Utilidades'
import queryString from 'query-string';
import { withCookies } from 'react-cookie';
import FiltroColumna from '../../../general/tabla/FiltroColumna'
import FiltroEtiqueta from '../../../general/tabla/FiltroEtiqueta'
import EstilosGlobales from '../../../utilidades/EstilosGlobales';

class ListaOpcionDependencia extends Component {

    static contextType = SumaContexto;

    ENUM_COMPONENTE = {
        CARGANDO: 1,
        VER: 2,
        NO_DISPONIBLE: 3
    }

    state = {
        cargando: true,
        componente: this.ENUM_COMPONENTE.CARGANDO,
        usuario: undefined,

        filtro: {},
        filtroEtiquetas: [],
    };


    constructor(props) {
        super(props);
        this.servicioDatosConstantes = new ServicioDatosConstantes(props);
        this.servicioUsuarios = new ServicioUsuarios(props);
        this.servicioEntidad = new ServicioEntidad(props);
        this.servicioSolicitud = new ServicioSolicitud(props);

        let contexto = this;
        this.filtroColumna = new FiltroColumna(props,( valores ) => {contexto.setSumaState(valores, contexto)});
    }


    // -----------------------------------------------------------------------
    // --- CARGA DATOS INICIALES ---------------------------------------------
    // -----------------------------------------------------------------------
    async cargarDatosIniciales() {

        let params = this.props.location.search;
        let urlparametros = queryString.parse(params);
        let idDependencia = undefined;

        if (urlparametros.id !== undefined && !isNaN(parseInt(urlparametros.id))) {
            idDependencia = parseInt(urlparametros.id);
        }
        this.setSumaState({ idDependencia: idDependencia });
        
        let usr = await this.servicioUsuarios.usuarioEnSesion();
        if (usr !== null) {
            this.setSumaState({ usuario: usr, componente: this.ENUM_COMPONENTE.VER, cargando: true });

            const tipos = await this.servicioDatosConstantes.obtenerTiposOpcion();
            let tiposOpcion = [];
            if( tipos ){
                for( let tipo of tipos ){
                    tiposOpcion.push({ label: tipo.tipoOpcion, value: tipo.id });
                }
            }

            let tiposSol = await this.servicioSolicitud.obtenerTipoDeSolicitudesDependencia( idDependencia );
            let tiposSolicitud = [];
            if( tiposSol ){
                for( let tipo of tiposSol ){
                    tiposSolicitud.push({ label: tipo.solicitud, value: tipo.id });
                }
            }
            //console.log('tiposSolicitud',tiposSolicitud);

            await this.cargarDatosTabla(this);
            this.setSumaState({ cargando: false, tiposOpcion, tiposSolicitud });
        } else {
            this.setSumaState({ componente: this.ENUM_COMPONENTE.NO_DISPONIBLE });
        }

    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarDatosIniciales();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSumaState(valores) {
        if (this._isMounted) {
            this.setState(valores);
        }
    }


    // 'limpiarFiltro': Método (clearFilters) que es propio de la tabla antDesign para limpiar los filtros
    // 'confirmarFiltro': Método (confirm) que es própio de la tabla antDesign para aplicar el filtro
    // 'dataIndex': Retorna el campo que se está filtrando
    // 'selectedKeys': Retorna el valor a filtrar;
    async cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) {
        let filtro = contexto.state.filtro;
        if (dataIndex !== undefined) { // Si el filtro es aplicado, se agrega la columna filtrada
            filtro[dataIndex] = selectedKeys;
        }        
        contexto.setSumaState({ cargando: true, filtro: filtro });

        // Se construye el filtroApi a partir de lo que fue filtrado en la tabla
        let filtroApi = {
            ...(filtro.id && filtro.id.length > 0 && {id:filtro.id[0]} ),
            ...(filtro.opcionDependencia && filtro.opcionDependencia.length > 0 && {opcion:filtro.opcionDependencia[0]} ),
            ...(filtro.tipoOpcion && filtro.tipoOpcion.length > 0 && filtro.tipoOpcion[0].opc && {tipo:filtro.tipoOpcion[0].opc.join()} ),
            ...(filtro.tipoSolicitud && filtro.tipoSolicitud.length > 0 && filtro.tipoSolicitud[0].opc && {tipoSolicitud:filtro.tipoSolicitud[0].opc.join()} ),
            ...(filtro.dependencia && filtro.dependencia.length > 0 && {dependencia:filtro.dependencia[0]} ),
        };

        //console.log('filtroApi',filtroApi);
        
        await this.servicioEntidad.obtenerVistaOpcionesDependencia( this.state.idDependencia, filtroApi )
            .then(( vistaOpcionesDependencia ) => {
                contexto.setSumaState({ vistaOpcionesDependencia: vistaOpcionesDependencia, cargando: false });
                //console.log('vistaOpcionesDependencia',vistaOpcionesDependencia);
            }).then(() => {
                if (limpiarFiltro !== undefined) { limpiarFiltro(); }
                if (confirmarFiltro !== undefined) { confirmarFiltro(); }
            });
    }


    render() {
        const { componente, usuario, tiposOpcion, tiposSolicitud, vistaOpcionesDependencia } = this.state;
        let { filtro } = this.state;
        filtro = filtro || {};
        let contexto = this;

        const columns = [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                with:100,
                sorter: (a, b) => a.id - b.id,
                filteredValue: filtro.id || null,
                ...this.filtroColumna.busquedaTexto(
                    'Identificador',
                    'id',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            {
                title: 'Opcion',
                dataIndex: 'opcionDependencia',
                key: 'opcionDependencia',
                sorter: (a, b) => a.opcionDependencia ? a.opcionDependencia.localeCompare(b.opcionDependencia) : 1,
                filteredValue: filtro.opcionDependencia || null,
                ...this.filtroColumna.busquedaTexto(
                    'Opcion',
                    'opcionDependencia',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
            },
            {
                title: 'Tipo Opción',
                key: 'tipoOpcion',
                align: 'tipoOpcion',
                dataIndex: 'tipoOpcion',
                sorter: (a, b) => a.tipoOpcion ? a.tipoOpcion.localeCompare(b.tipoOpcion) : 1,
                filteredValue: filtro.tipoOpcion || null,
                ...this.filtroColumna.busquedaOpciones(
                    'Tipo Opción',
                    'tipoOpcion',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) },
                    tiposOpcion
                ),
                render: (text, record) => (
                    <>
                        {record.tipoOpcion ? record.tipoOpcion.toUpperCase() : ''}    
                    </>
                ),
                responsive: ['sm'], /* visible en pantalla con ancho ≥ 576px*/
            },
            {
                title: 'Tipo Solicitud',
                key: 'tipoSolicitud',
                align: 'tipoSolicitud',
                dataIndex: 'tipoSolicitud',
                sorter: (a, b) => a.solicitud ? a.solicitud.localeCompare(b.solicitud) : 1,
                filteredValue: filtro.tipoSolicitud || null,
                ...this.filtroColumna.busquedaOpciones(
                    'Tipo Solicitud',
                    'tipoSolicitud',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) },
                    tiposSolicitud
                ),
                render: (text, record) => (
                    <>
                        {record.solicitud ? record.solicitud.toUpperCase() : ''}    
                    </>
                ),
                responsive: ['lg'], /* visible en pantalla con ancho ≥ 992px*/
            },
            {
                title: 'Dependencia',
                dataIndex: 'dependencia',
                key: 'dependencia',
                filteredValue: filtro.dependencia || null,
                ...this.filtroColumna.busquedaTexto(
                    'Dependencia',
                    'dependencia',
                    (limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) => { contexto.cargarDatosTabla(contexto, limpiarFiltro, confirmarFiltro, dataIndex, selectedKeys) }
                ),
                render: (text, record) => (
                    <>
                        { record.dependencia ? 
                            <span className="span-bloque lineh16">{record.dependencia}</span>
                            :<></>    
                        }
                        { record.entidad ?
                            <span className="span-bloque lineh16 texto-gris-claro">{record.entidad}</span>            
                            :<></>    
                        }
                        { record.siglaEntidad ?     
                            <span className="span-bloque lineh16">{record.siglaEntidad}</span>
                            :<></>    
                        }
                    </>
                ),
                responsive: ['xl'], /* visible en pantalla con ancho ≥ 1200px*/
            },
            {
                title: 'Opciones',
                key: 'opciones',
                align: 'center',
                className: 'columna-opciones',
                width: 112,
                render: (text, record) => (
                    <>
                        <Tooltip title="Ver opción">
                            <Button shape="circle" disabled={!Utilidades.accionPermitida(usuario, Constantes.ENUMS_MODULOS.DEPENDENCIA.ACCIONES.ADMINISTRAR_OPCIONES_DEPENDENCIA.id)}
                                icon={<EyeOutlined style={{ 'color': '#c90a00' }} />}
                                onClick={(e) => { this.props.callbackAdministrarOpcionDependencia( record.idTipoOpcion, undefined, record.id) }} />
                        </Tooltip>
                    </>
                ),
            },
        ];

        return (
            <div>
                {(componente === this.ENUM_COMPONENTE.VER) ?
                    (
                        <>
                            <EstilosGlobales.CONTENEDOR_MEDIO />
                            <Spin tip="Por favor espera..." size="large" spinning={this.state.cargando}>

                                <h3 className="sub-titulo-separador">Opciones de la dependencia</h3>
                                <div className="seccion-datos seccion-final">

                                    {
                                        this.state.filtroEtiquetas.length > 0 ?
                                            (<div className="seccion-filtros-etiquetas">
                                                <div className="titulo-filtro-etiquetas">
                                                    <label>Filtros <SearchOutlined /> :</label>
                                                </div>

                                                {
                                                    this.state.filtroEtiquetas.map((filtroEtiqueta, i) => {
                                                        return (
                                                            <FiltroEtiqueta key={i}
                                                                dataIndex={filtroEtiqueta.dataIndex}
                                                                texto={filtroEtiqueta.texto}
                                                                onClose={filtroEtiqueta.borrarFiltro} />
                                                        )
                                                    })
                                                }
                                            </div>)
                                            :
                                            (<></>)
                                    }

                                    <Table  columns={columns}
                                            dataSource={ vistaOpcionesDependencia ? vistaOpcionesDependencia : []  }
                                            rowKey={"id"} />
                                </div>

                            </Spin>

                        </>
                    )
                    :
                    (
                        (componente === this.ENUM_COMPONENTE.CARGANDO) ?
                            (<Spin tip="Por favor espera..." className="solo-spin-cargando" size="large" />)
                            : ((componente === this.ENUM_COMPONENTE.NO_DISPONIBLE) ? (<label>Contenido no disponible. [Cod:LSTOPCDEP]</label>) : (<></>))
                    )
                }
            </div>
        );
    }
}

export default withRouter(withCookies(ListaOpcionDependencia));